import BookingWidgetService from '../../../service/booking/booking-widget/BookingWidgetService';
import { searchFlightAction } from '../../actions/booking/searchFlightAction';

const initialState = {
    recentSearchList: [],
}

var BookingWidgetServiceTemp = new BookingWidgetService();

const recentSearchReducer = (state = initialState, action) => {
        switch (action.type) {
          case searchFlightAction.SAVERECENTSEARCH:
            const newState = BookingWidgetServiceTemp.updateRecentSearch(state,action);
            return {
              recentSearchList:newState
            };
          default:
            return state;
        }
}

export default recentSearchReducer;