import BaggageService from "../../../service/booking/ancillary/BaggageService";
import { ancillaryAction } from "../../actions/booking/ancillaryAction";
const initialState = {
  ancillaryList: {},
  baggageModel: [],
  overAllTotal: 0,
  finaloverAllTotal:0,
  bagIsAdded: false,
  ancillaryListForShoppingCart:[],
  baggageRequest:{}
};
var baggageService = new BaggageService();
const ancillaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ancillaryAction.SAVEANCILLARYDATA:
      var intialBaggageModal = baggageService.intializeState(action.data);
      return {
        ancillaryList: action.data,
        baggageModel: intialBaggageModal.passengerList,
        overAllTotal: 0,
        finaloverAllTotal: 0,
      };
    case ancillaryAction.ADDBAG:
      var newBaggageModal = new baggageService.addBag(
        state,
        action.passengerId,
        action.ancillaryIndex,
        action.ancillaryCode,
        action.price,
        action.itinerayIndex,
        action.itineryId,
        state.ancillaryList.itinerary.itineraryParts
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case ancillaryAction.MINUSBAG:
      var newBaggageModal = new baggageService.minusBag(
        state,
        action.passengerId,
        action.ancillaryIndex,
        action.price,
        action.itinerayIndex
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case ancillaryAction.ADDTOCART:
      
      var ancillaryListForShoppingCart = baggageService.addToCart(
        action.ancillaryOperationResults,
        state.ancillaryList
      );
      var bagIsAdded=true;
      if(state.overAllTotal===0){
        bagIsAdded=false;
      }      return {
        ...state,
        bagIsAdded: bagIsAdded,
        ancillaryListForShoppingCart: ancillaryListForShoppingCart,
        finaloverAllTotal: state.overAllTotal,
        baggageRequest: action.baggageRequest,
      };
    case ancillaryAction.REMOVEFROMCART:
      var ancillaryListForShoppingCart = baggageService.addToCart(
        action.ancillaryOperationResults,
        state.ancillaryList
      );
      var tempNewBaggageModel =baggageService.removeBaggage(action.id,state.baggageModel,action.price,action.assignedQuantity);
      var tempFinalAllOverTotal = state.finaloverAllTotal - action.price;
      var tempoverAllTotal = state.overAllTotal - action.price;
      var bagIsAdded=true;
      if(tempFinalAllOverTotal===0){
        bagIsAdded=false;
      }
      return {
        ...state,
        bagIsAdded: bagIsAdded,
        ancillaryListForShoppingCart: ancillaryListForShoppingCart,
        finaloverAllTotal: tempFinalAllOverTotal,
        overAllTotal:tempoverAllTotal,
        baggageRequest: action.baggageRequest,
        baggageModel:tempNewBaggageModel
      };
    default:
      return state;
  }
};

export default ancillaryReducer;
