import React from "react";
import { Accordion, Card, Row, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCheck,
  faEdit,
  faInfoCircle,
  faPlaneArrival,
  faPlaneDeparture,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import Translation from "../../../service/shared/multi-lingual/Translation";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import ManageBaggageService from "../../../service/manage-my-booking/account/ManageBaggageService";
import { checkInAction } from "../../../redux/actions/check-in/checkInAction";
import CheckInAncillaryService from "../../../service/check-in/CheckInAncilllaryService";
class CheckInBaggage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
    };
    this.minimumPriceFinder();
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  minimumPriceFinder = () => {
    var minmumPrice = 0;
    var currency = null;
    this.props.baggageModels.forEach((baggageModel) => {
      baggageModel.bags.forEach((bag) => {
        if (minmumPrice === 0) {
          minmumPrice = bag.price;
          currency = bag.currency;
        } else if (minmumPrice > bag.price) {
          minmumPrice = bag.price;
        }
      });
    });

    this.minimumPrice = this.numberWithCommas(minmumPrice);
    this.currency = currency;
  };
  addBaggage = (
    ancillaryCode,
    price,
    passengerKey,
    maxQuantityPerPax,
    quantity
  ) => {
    if (quantity < maxQuantityPerPax) {
      this.props.addBag(ancillaryCode, passengerKey, price);
    }
  };

  minusBaggage = (
    ancillaryCode,
    price,
    passengerKey,
    assignedQuantity,
    quantity
  ) => {
    if (quantity > assignedQuantity) {
      this.props.minusBag(ancillaryCode, passengerKey, price);
    }
  };
  addToCart = () => {
    if (this.props.totalPrice > this.props.oldValue) {
      this.setState({ showModal: false, isLoading: true });
      var baggageService = new CheckInAncillaryService();
      var baggageRequest = baggageService.buildBaggageRequest(
        this.props.itineraryPart,
        this.props.baggageModels,
        this.props.cookieSabreDataRequest
      );
      
      if (baggageRequest.ancillaryOffers.length !== 0) {
        HttpService.checkInPostService(
          baggageRequest,
          "/etibe-checkinapi/V1.0/Ancillary/Book",
          this.props.token
        )
          .then((ancillaryResponse) => {
            this.setState({ isLoading: false });

            if (ancillaryResponse.data.statusAPI === "SUCCESS") {
              this.props.addedToCart(ancillaryResponse.data.reservation);
              this.updateActivePassengersDataAfterSubmit(ancillaryResponse.data.reservation.passengers.passenger);
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
          });
      }
    }
  };
  getClassNameForButton = (
    quantity = 0,
    maxQuantityPerPax = 0,
    assignedQuantity = 0,
    type = null
  ) => {
    if (type === "plus") {
      if (quantity === maxQuantityPerPax) {
        return "bk-submit-btn btn-ethiopian bag-button btn-not-allowd disabled";
      } else {
        return " bk-submit-btn btn-ethiopian bag-button ";
      }
    } else if (type === "minus") {
      if (quantity === assignedQuantity) {
        return "bk-submit-btn btn-ethiopian bag-button btn-not-allowd disabled";
      } else {
        return " bk-submit-btn btn-ethiopian bag-button ";
      }
    }
  };
  
  updateActivePassengersDataAfterSubmit = (passengers) => {
    var activepassengers = {};
    passengers
      .filter((passenger) => passenger.id in this.props.activePassengers)
      .map((passenger) => {
        activepassengers[passenger.id] = passenger;
      });
    this.props.initActivePassengers(activepassengers);
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        <h4>
          <Translation Key="ancillary_header" />
        </h4>
        <h6>
          <Translation Key="ancillary_paragraph" />
        </h6>
        <div className="card bag-card">
          <div className="bag-header m-3">
            <h3 className="my-4">Extra Bags</h3>
            <img
              alt="Image for Excess Baggage"
              className="bag-img"
              src="https://www.ethiopianairlines.com/images/default-source/default-album/mobileapp/baggage_ancillary.jpg"
            />
            <p className="bag-intro">Let us transport your bags for you.</p>
          </div>
          <div className="bag-content mx-3">
            {this.props.bagIsAdded ? (
              <div>
                <h3 className="added-to-cart">
                  <FontAwesomeIcon icon={faCheck} />
                  <Translation Key="added_to_cart" />
                </h3>
              </div>
            ) : null}
            <p>
              {" "}
              <Translation Key="bags" />
            </p>
            <p>
              <b>
                {this.props.bagIsAdded
                  ? this.numberWithCommas(this.props.finaloverAllTotal)
                  : this.minimumPrice}{" "}
                {this.currency}
              </b>
            </p>
            <p className="bag-text">
              {" "}
              <Translation Key="per_bag" />
              / <Translation Key="per_passenger" />
            </p>
            <p className="bag-axillary">
              <Translation Key="free_bags_included" />
            </p>
          </div>
          <div className="bag-footer">
            <Button
              className="bk-submit-btn btn-ethiopian m-3"
              onClick={() => this.setState({ showModal: true })}
            >
              {this.props.bagIsAdded ? (
                <span>
                  <FontAwesomeIcon icon={faEdit} />
                  <Translation Key="change" />
                </span>
              ) : (
                <Translation Key="add_bags" />
              )}
            </Button>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <Row>
              <div className="col-12 col-lg-4 d-none d-lg-block">
                <h3 className="my-4">Extra Bags</h3>

                <img
                  alt="Image for Excess Baggage"
                  className="bag-img"
                  src="https://www.ethiopianairlines.com/images/default-source/default-album/mobileapp/baggage_ancillary.jpg"
                />
              </div>
              <div className="col-12 col-lg-5 mt-lg-5">
                <h6 className="mt-4">
                  Please note that amount paid for the additional checked
                  luggage is non-refundable.
                </h6>
              </div>
              <div className="col-12 col-lg-3 mt-lg-5 d-none d-lg-block">
                <div className="bag-content mt-4">
                  {this.props.bagIsAdded ? (
                    <div>
                      <h5 className="added-to-cart">
                        <FontAwesomeIcon icon={faCheck} />
                        <Translation Key="added_to_cart" />
                      </h5>
                    </div>
                  ) : null}
                  <p>
                    {" "}
                    <Translation Key="bags" />
                  </p>
                  <p>
                    <b>
                      {this.minimumPrice} {this.currency}
                    </b>
                  </p>
                  <p className="bag-text">
                    {" "}
                    <Translation Key="per_bag" />
                    / <Translation Key="per_passenger" />
                  </p>
                </div>
              </div>
            </Row>
          </Modal.Header>

          <Modal.Body>
            <Card>
              <Card.Body className="m-3">
                <div>
                  <h5 className="text-left">
                    {AirportFullName.getFullName(
                      this.props.itineraryPart.segment[0].flightDetail[0]
                        .departureAirport
                    )}{" "}
                    <FontAwesomeIcon icon={faAngleRight} />{" "}
                    {AirportFullName.getFullName(
                      this.props.itineraryPart.segment[
                        this.props.itineraryPart.segment.length - 1
                      ].flightDetail[0].arrivalAirport
                    )}
                  </h5>
                  <p className="text-left">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Select the number of bags, and their respective weight, that
                    you will be carrying.
                  </p>
                </div>
                <div>
                  {Object.keys(this.props.activePassengers).map(
                    (passengerKey, passengerIndex) => {
                      var ancillaryForPassenger =
                        this.props.baggageModels.filter(
                          (p) => p.passengerId === passengerKey
                        )[0];
                      if (
                        ancillaryForPassenger !== null &&
                        ancillaryForPassenger !== undefined &&
                        ancillaryForPassenger.bags.length !== 0
                      ) {
                        return (
                          <Row key={passengerIndex} className="mt-lg-5 ml-lg-5">
                            <div className="col-12 col-lg-4 text-center">
                              <Row className="text-center">
                                <div className="col-12 text-center">
                                  <h5 className="mb-0 pr-5">
                                    {" "}
                                    {
                                      this.props.activePassengers[passengerKey]
                                        .personName.first
                                    }{" "}
                                    {
                                      this.props.activePassengers[passengerKey]
                                        .personName.last
                                    }
                                  </h5>
                                </div>
                                <div className="col-12 mt-0 text-center ">
                                  <Row className="">
                                    <div className="col-md-2 m-0 text-right d-none d-md-block">
                                      <FontAwesomeIcon
                                        icon={faSuitcase}
                                        size="2x"
                                      />
                                    </div>
                                    <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                      {"  "}
                                      {"  "}
                                      <Translation Key="number_of_bags" />:{" "}
                                      <span className="mx-2">
                                        {
                                          ancillaryForPassenger.amount[0]
                                            .totalQuantity
                                        }
                                      </span>
                                      <b>
                                        <span className="mx-1">
                                          {this.numberWithCommas(
                                            ancillaryForPassenger.amount[0]
                                              .totalQuantity !== 0
                                              ? ancillaryForPassenger.amount[0]
                                                  .totalPrice
                                              : 0
                                          )}
                                        </span>{" "}
                                        {this.currency}
                                      </b>
                                    </div>
                                    <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                      <span className="text-center">
                                        <Translation Key="free_bags" />
                                      </span>
                                    </div>
                                  </Row>
                                </div>
                              </Row>
                            </div>
                            <div className="col-12 col-lg-8 text-center">
                              <Row>
                                {ancillaryForPassenger.bags.map(
                                  (bag, bagIndex) => (
                                    <div
                                      className="col-12 mt-2 mt-lg-5 mt-md-0 col-lg-6 col-xl-6 text-center"
                                      key={bagIndex}
                                    >
                                      {bag.name}
                                      <p>
                                        <b>
                                          {this.numberWithCommas(bag.price)}
                                        </b>
                                        {"  "} <b>{bag.currency}</b>
                                      </p>

                                      <div className="quantity text-xl-left">
                                        <span className="bag-quantity-input pt-2">
                                          {" "}
                                          {bag.quantity}
                                        </span>
                                        <span className="float-xl-right">
                                          <Button
                                            className={this.getClassNameForButton(
                                              bag.quantity,
                                              bag.maxQuantityPerPax,
                                              bag.assignedQuantity,
                                              "minus"
                                            )}
                                            onClick={this.minusBaggage.bind(
                                              this,
                                              bag.code,
                                              bag.price,
                                              passengerKey,
                                              bag.assignedQuantity,
                                              bag.quantity
                                            )}
                                          >
                                            -
                                          </Button>

                                          <Button
                                            className={this.getClassNameForButton(
                                              bag.quantity,
                                              bag.maxQuantityPerPax,
                                              bag.assignedQuantity,
                                              "plus"
                                            )}
                                            onClick={this.addBaggage.bind(
                                              this,
                                              bag.code,
                                              bag.price,
                                              passengerKey,
                                              bag.maxQuantityPerPax,
                                              bag.quantity
                                            )}
                                          >
                                            +
                                          </Button>
                                        </span>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Row>
                            </div>
                          </Row>
                        );
                      } else {
                        return (
                          <Row>
                            <div className="col-6">
                              <h5 className="mb-0 pr-5">
                                {" "}
                                {
                                  this.props.activePassengers[passengerKey]
                                    .personName.first
                                }{" "}
                                {
                                  this.props.activePassengers[passengerKey]
                                    .personName.last
                                }
                              </h5>
                            </div>
                            <div className="col-6">
                              <h6 className="float-right">
                                Unavailable for{" "}
                                {
                                  this.props.activePassengers[passengerKey].type
                                    .value
                                }
                              </h6>
                            </div>
                          </Row>
                        );
                      }
                    }
                  )}
                </div>
              </Card.Body>
            </Card>

            <div className="text-right m-3">
              <h5>
                <b>
                  <Translation Key="total" />:{" "}
                  <span id="baggageTotal">
                    {this.numberWithCommas(this.props.totalPrice)}
                  </span>{" "}
                  {this.currency}
                </b>
              </h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="float-right">
              <Button
                variant="danger"
                className="bk-submit-btn btn-white btn-ethiopian-danger m-2"
                style={{ borderColor: "red" }}
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                <Translation Key="cancel" />
              </Button>
              <Button
                variant="danger"
                className={
                  this.props.totalPrice > this.props.oldValue
                    ? "bk-submit-btn btn-ethiopian m-2"
                    : "bk-submit-btn btn-ethiopian btn-not-allowd m-2 disabled"
                }
                onClick={this.addToCart}
              >
                {this.props.bagIsAdded ? (
                  <Translation Key="update_cart" />
                ) : (
                  <Translation Key="add_to_cart" />
                )}
              </Button>
            </span>{" "}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baggageModels: state.checkIn.baggageModel,
    totalPrice: state.checkIn.overAllTotal,
    finaloverAllTotal: state.checkIn.finaloverAllTotal,
    bagIsAdded: state.checkIn.bagIsAdded,
    oldValue: state.checkIn.oldValue,
    cookieSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    token: state.searchFlight.token,
    activePassengers: state.checkIn.activePassengers,
    itineraryPart: state.checkIn.reservation.itinerary.itineraryPart[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBag: (ancillaryCode, passengerKey, price) =>
      dispatch({
        type: checkInAction.CHECKIN_ADDBAG,
        ancillaryCode: ancillaryCode,
        passengerKey: passengerKey,
        price: price,
      }),
    minusBag: (ancillaryCode, passengerKey, price) =>
      dispatch({
        type: checkInAction.CHECKIN_MINUSBAG,
        passengerKey: passengerKey,
        ancillaryCode: ancillaryCode,
        price: price,
      }),
    addedToCart: (reservation) =>
      dispatch({
        type: checkInAction.CHECKIN_ADDTOCART,
        reservation: reservation,
      }),
      initActivePassengers: (value) =>
        dispatch({
          type: checkInAction.INITACTIVEPASSENGER,
          activePassengers: value,
        }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckInBaggage);
