export default class AirportSelectStyle {
  static mobileStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
      borderColor: "hsl(0deg 0% 80%)",
      borderStyle: "solid",
      borderWidth: "0px 0px 1px 0px",
      padding: "15px 10px 15px 10px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#2E7D32 " : "white",
      maxWidth: "100% !important",
      "&:hover": {
        backgroundColor: "#2E7D32 ",
        color: "white",
      },
    }),
    control: (base, state) => ({
      ...base,
      minHeight: "55px",
      borderColor: state.selectProps.menuIsOpen
        ? "#2E7D32  !important"
        : "hsl(0deg 0% 80%)",
      boxShadow: state.selectProps.menuIsOpen && "none !important",
      position: state.selectProps.menuIsOpen && "fixed",
      top: state.selectProps.menuIsOpen && "0px",
      width: state.selectProps.menuIsOpen && "100%",
      left: state.selectProps.menuIsOpen && "0%",
      right: state.selectProps.menuIsOpen && "0%",
      "&:hover": {
        borderColor: "#2E7D32  !important",
        boxShadow: "none !important",
      },
      zIndex: 4,
      marginBottom: "0px !important",
      paddingLeft: state.selectProps.menuIsOpen && "15px !important",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      // transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      display: state.selectProps.menuIsOpen && "none !important",
    }),
    menu: (base) => ({
      ...base,
      height: "100% !important",
      position: "fixed",
      width: "100%",
      left: "0%",
      right: "0%",
      top: "53px",
      zIndex: 1000,
      marginTop: "0px !important",
    }),
    menuList: (base) => ({
      ...base,
      minHeight: "100%",
      zIndex: 1000,
      width: "94%",
      left: "3%",
      right: "3%",
    }),
  };
  static desktopStyle = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
      borderColor: "hsl(0deg 0% 80%)",
      borderStyle: "solid",
      borderWidth: "1px 0px 0px 0px",
      padding: "15px 10px 15px 10px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#2E7D32 " : "white",
      maxWidth: "100% !important",
      "&:hover": {
        backgroundColor: "#2E7D32 ",
        color: "white",
      },
    }),
    control: (base, state) => ({
      ...base,
      minHeight: "55px",
      borderColor: state.selectProps.menuIsOpen
        ? "#2E7D32  !important"
        : "hsl(0deg 0% 80%)",
      boxShadow: state.selectProps.menuIsOpen && "none !important",
      "&:hover": {
        borderColor: "#2E7D32  !important",
        boxShadow: "none !important",
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
    }),
    menuList: (base) => ({
      ...base,
      zIndex: 1000,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1000,
      marginTop: "0px",
      minWidth: "120px",
    }),
    indicatorSeparator: () => null,
    // placeholder: (provided, state) => ({
    //   ...provided,
    //   position: "absolute",
    //   top: state.hasValue || state.selectProps.inputValue ? -15 : "50%",
    //   transition: "top 0.1s, font-size 0.1s",
    //   fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    // })
  };
  static breadCrumSelect = {
    option: (provided, state) => ({
      ...provided,
      textAlign: "left",
      borderColor: "hsl(0deg 0% 80%)",
      borderStyle: "solid",
      borderWidth: "1px 0px 0px 0px",
      padding: "15px 10px 15px 10px",
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected ? "#2E7D32 " : "#eee",
      cursor: "not-allowed",
    }),
  };

  static disabledSelect = {
    control: (base) => ({
      ...base,
      backgroundColor: "#eee !important",
      minHeight: "55px",
      borderColor:"#eee  !important",
      boxShadow: "none !important",
      "&:hover": {
        cursor: "not-allowed",
        borderColor: "#eee  !important",
        boxShadow: "none !important",
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "hsl(0, 0%, 80%)",
    }),
  };
}