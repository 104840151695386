import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Select from "react-select";
import AirportSelectStyle from "../../../../master-data/booking-widget/airpot-select-style";
import Translation from "i18next";
import { connect } from "react-redux";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import CountryList from "../../../../master-data/shared/countryList.json";

const selectStyleDesktop = AirportSelectStyle.desktopStyle;

const NewCreditCardInput = (props) => {
  return (
    <div className="py-3 px-2">
      <Row>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isCardNumberValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.cardNumber}
            placeholder={
              props.isCardNumberValid
                ? Translation.t("credit_card_number")
                : Translation.t("credit_card_number_required")
            }
            id="biiling-info-card-number"
            onChange={() => {
              var value = document.getElementById(
                "biiling-info-card-number"
              ).value;
              props.updateBillingInfo(value, "cardNumber");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "cardNumber",
                "isCardNumberValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "cardNumber",
                "isCardNumberValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isFullNameValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.fullName}
            placeholder={
              props.isFullNameValid
                ? Translation.t("full_name_credit_card")
                : Translation.t("full_name_credit_card_required")
            }
            id="biiling-info-full-name"
            onChange={() => {
              var value = document.getElementById(
                "biiling-info-full-name"
              ).value;
              props.updateBillingInfo(value, "fullName");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "fullName",
                "isFullNameValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "fullName",
                "isFullNameValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <div
            className={
              props.isExpirationDateValid
                ? "SingleDatePicker SingleDatePicker_1 my-2"
                : "SingleDatePicker SingleDatePicker_1 my-2 invalid_input"
            }
          >
            <div className="SingleDatePickerInput SingleDatePickerInput_1 SingleDatePickerInput__withBorder SingleDatePickerInput__withBorder_2">
              <button
                className="SingleDatePickerInput_calendarIcon SingleDatePickerInput_calendarIcon_1"
                type="button"
              >
                <svg
                  className="SingleDatePickerInput_calendarIcon_svg SingleDatePickerInput_calendarIcon_svg_1"
                  focusable="false"
                  viewBox="0 0 1393.1 1500"
                >
                  <path d="m107 1393h241v-241h-241zm295 0h268v-241h-268zm-295-295h241v-268h-241zm295 0h268v-268h-268zm-295-321h241v-241h-241zm616 616h268v-241h-268zm-321-616h268v-241h-268zm643 616h241v-241h-241zm-322-295h268v-268h-268zm-294-723v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm616 723h241v-268h-241zm-322-321h268v-241h-268zm322 0h241v-241h-241zm27-402v-241c0-7-3-14-8-19-6-5-12-8-19-8h-54c-7 0-13 3-19 8-5 5-8 12-8 19v241c0 7 3 14 8 19 6 5 12 8 19 8h54c7 0 13-3 19-8 5-5 8-12 8-19zm321-54v1072c0 29-11 54-32 75s-46 32-75 32h-1179c-29 0-54-11-75-32s-32-46-32-75v-1072c0-29 11-54 32-75s46-32 75-32h107v-80c0-37 13-68 40-95s57-39 94-39h54c37 0 68 13 95 39 26 26 39 58 39 95v80h321v-80c0-37 13-69 40-95 26-26 57-39 94-39h54c37 0 68 13 94 39s40 58 40 95v80h107c29 0 54 11 75 32s32 46 32 75z"></path>
                </svg>
              </button>
              <div className="DateInput DateInput_1">
                <input
                  className="DateInput_input DateInput_input_1 DateInput_input__readOnly DateInput_input__readOnly_2"
                  defaultValue={props.expirationDate}
                  id="frequent-flyer-date"
                  name="frequent-flyer-date"
                  placeholder={
                    props.isExpirationDateValid
                      ? Translation.t("expiry_date_credit_card")
                      : Translation.t("expiry_date_credit_card_required")
                  }
                  id="biiling-info-expiry-date"
                  onChange={() => {
                    var value = document.getElementById(
                      "biiling-info-expiry-date"
                    ).value;
                    props.updateBillingInfo(value, "expirationDate");
                  }}
                  onFocus={() =>
                    props.changeBillingInfoValidation(
                      "expirationDate",
                      "isExpirationDateValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeBillingInfoValidation(
                      "expirationDate",
                      "isExpirationDateValid",
                      "onBlur"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <h5 className="text-left py-2">{Translation.t("payment_country")}</h5>
      <Row>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isBillingAddressValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.billingAddress}
            placeholder={
              props.isBillingAddressValid
                ? Translation.t("biiling_address")
                : Translation.t("biiling_address_required")
            }
            id="biiling-info-biiling-address"
            onChange={() => {
              var value = document.getElementById(
                "biiling-info-biiling-address"
              ).value;
              props.updateBillingInfo(value, "billingAddress");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "billingAddress",
                "isBillingAddressValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "billingAddress",
                "isBillingAddressValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isAddressValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.address}
            placeholder={
              props.isAddressValid
                ? Translation.t("address")
                : Translation.t("address_required")
            }
            id="biiling-info-address"
            onChange={() => {
              var value = document.getElementById("biiling-info-address").value;
              props.updateBillingInfo(value, "address");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "address",
                "isAddressValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "address",
                "isAddressValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <Select
            className={props.isCountryValid ? "my-2" : "my-2 is-invalid-select"}
            value={props.country}
            styles={selectStyleDesktop}
            isSearchable={false}
            options={CountryList}
            placeholder={
              props.isCountryValid ? (
                <span>{Translation.t("country")}</span>
              ) : (
                <span style={{ color: "rgb(147 0 0)" }}>
                  {Translation.t("country_required")}
                </span>
              )
            }
            id="biiling-info-country"
            onChange={(value) => props.updateBillingInfo(value, "country")}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "country",
                "isCountryValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "country",
                "isCountryValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isCityValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.city}
            placeholder={
              props.isCityValid
                ? Translation.t("city")
                : Translation.t("city_required")
            }
            id="biiling-info-city"
            onChange={() => {
              var value = document.getElementById("biiling-info-city").value;
              props.updateBillingInfo(value, "city");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "city",
                "isCityValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation("city", "isCityValid", "onBlur")
            }
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={4}>
          <input
            className={
              props.isZipCodeValid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            defaultValue={props.zipCode}
            placeholder={
              props.isZipCodeValid
                ? Translation.t("zip_code")
                : Translation.t("zip_code_required")
            }
            id="biiling-info-zip-code"
            onChange={() => {
              var value = document.getElementById(
                "biiling-info-zip-code"
              ).value;
              props.updateBillingInfo(value, "zipCode");
            }}
            onFocus={() =>
              props.changeBillingInfoValidation(
                "zipCode",
                "isZipCodeValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changeBillingInfoValidation(
                "zipCode",
                "isZipCodeValid",
                "onBlur"
              )
            }
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12} md={6}></Col>
        <Col sm={12} md={6}>
          <span className="float-right">
            <Button
              variant="danger"
              className="bk-submit-btn btn-ethiopian m-2 "
              onClick={() => props.setShowCardForm(false)}
            >
              {Translation.t("cancel")}
            </Button>
            <Button
              variant="danger"
              className="bk-submit-btn btn-ethiopian m-2"
            >
              {Translation.t("use_this_card")}
            </Button>
          </span>
        </Col>
      </Row>
    </div>
  );
};
const mapStateTopProps = (state) => {
  return {
    cardNumber: state.account.billingInfo.cardNumber,
    fullName: state.account.billingInfo.fullName,
    expirationDate: state.account.billingInfo.expirationDate,
    billingAddress: state.account.billingInfo.billingAddress,
    address: state.account.billingInfo.address,
    country: state.account.billingInfo.country,
    city: state.account.billingInfo.city,
    zipCode: state.account.billingInfo.zipCode,
    isCardNumberValid: state.account.billingInfo.validation.isCardNumberValid,
    isFullNameValid: state.account.billingInfo.validation.isFullNameValid,
    isExpirationDateValid:
      state.account.billingInfo.validation.isExpirationDateValid,
    isBillingAddressValid:
      state.account.billingInfo.validation.isBillingAddressValid,
    isAddressValid: state.account.billingInfo.validation.isAddressValid,
    isCountryValid: state.account.billingInfo.validation.isCountryValid,
    isCityValid: state.account.billingInfo.validation.isCityValid,
    isZipCodeValid: state.account.billingInfo.validation.isZipCodeValid,
  };
};
const mapDispatchToProps = (dispacth) => {
  return {
    updateBillingInfo: (value, valueParameter) =>
      dispacth({
        type: accountAction.UPDATEBILLINGINFO,
        value: value,
        valueParameter: valueParameter,
      }),
    changeBillingInfoValidation: (
      valueParameter,
      validationParameter,
      domain
    ) =>
      dispacth({
        type: accountAction.UPDATEBILLINGINFOVALIDATION,
        valueParameter: valueParameter,
        validationParameter: validationParameter,
        domain: domain,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(NewCreditCardInput);
