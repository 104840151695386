import React from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import HttpService from "../../../service/shared/HttpService";
class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: null,
    };
  }

  
   validateEmail =(inputValue) =>
{
  var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // var mailformat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;  
  if(inputValue.value.trim().match(mailformat)){
    this.props.updatePassengerMethod(0, inputValue.value.trim(), "contact", "email");
    inputValue.classList.remove("invalid_input");
    inputValue.placeholder = Translation.t("email_address");
  }
  else{
    this.props.updatePassengerMethod(0, null, "contact", "email");
    inputValue.classList.add("invalid_input");
    inputValue.placeholder = Translation.t("email_address_required");
  }

}
  // validateEmail = (inputValue) => {
  //   var mailformat =
  //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //   if (mailformat.test(inputValue.value)) {
  //   } else {

  //   }
  // };
  componentDidMount = async() => {
    if (
      this.props.passengerList[0].contact.phone === null ||
      this.props.passengerList[0].contact.phone === undefined
    ) {
      const publicIp = require("public-ip");
      var IpData = await publicIp.v4();

      HttpService.getCountryCode(IpData, this.props.token)
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            this.setState({
              countryCode: response.data.country_code.toLowerCase(),
            });
          }
        })
        .catch((error) => console.log(error));
    }

  };
  render() {
    return (
      <>
        {/* <Row>
          <div className="col-12">
            <label
              className="checkbox-lable  mr-3"
              htmlFor="create-account-checkbox"
            >
              <span className="mx-3">
                {Translation.t("create_account_info")}
              </span>
              <input
                type="checkbox"
                className="checkbox-input"
                id="create-account-checkbox"
              />
              <span className="geekmark"></span>
            </label>
          </div>
          <div className="col-12">
            <label className="login-label">
              {Translation.t("add_passanger_info")}
            </label>
          </div>
        </Row> */}
        <Row>
          <div className="col-12 mt-3">
            <h6>{Translation.t("remember_me_message")}</h6>
          </div>
          <div className="et-checkbox">
            <input
              type="checkbox"
              className="checkbox-input"
              id="remember-me"
              checked={this.props.rememberMe}
              onChange={() => {
                this.props.changeRememberMe();
              }}
            />
            <label htmlFor="remember-me" className="d-inline">
              {Translation.t("remember_me")}
            </label>
          </div>
        </Row>
        <h4 className="my-3">{Translation.t("contact_info")}</h4>
        <Row>
          <Col xs={12} sm={12} md={6} className="my-2">
            <PhoneInput
              country={this.state.countryCode}
              inputProps={{
                className: this.props.passengerList[0].validation.isPhoneValid
                  ? "form-control  widget-input my-2"
                  : "form-control  widget-input invalid_input my-2",
                id: "adult-0-phone",
                placeholder: this.props.passengerList[0].validation.isPhoneValid
                  ? Translation.t("phone_number")
                  : Translation.t("phone_number_required"),
              }}
              inputExtraProps={{
                name: "adult-0-phone",
                required: true,
                autoFocus: true,
              }}
              enableAreaCodes={true}
              enableLongNumbers={true}
              enableSearch={true}
              value={this.props.passengerList[0].contact.phone}
              onChange={(value, country, e, formattedValue) => {
                this.props.updatePassengerMethod(
                  0,
                  formattedValue,
                  "contact",
                  "phone"
                );
              }}
              onBlur={() => {
                var inputValue = document.getElementById("adult-0-phone");
                if (
                  inputValue.value === null ||
                  inputValue.value === "" ||
                  inputValue.value === "+"
                ) {
                  inputValue.classList.add("invalid_input");
                  inputValue.placeholder = Translation.t(
                    "phone_number_required"
                  );
                }
              }}
              onFocus={() => {
                var inputField = document.getElementById("adult-0-phone");
                inputField.classList.remove("invalid_input");
                inputField.placeholder = Translation.t("phone_number");
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={6} className="my-2">
            <input
              className={
                this.props.passengerList[0].validation.isEmailValid
                  ? "form-control  widget-input"
                  : "form-control  widget-input invalid_input"
              }
              placeholder={
                this.props.passengerList[0].validation.isEmailValid
                  ? Translation.t("email_address")
                  : Translation.t("email_address_required")
              }
              defaultValue={this.props.passengerList[0].contact.email}
              id="adult-0-email"
              onBlur={() =>
                this.validateEmail(document.getElementById("adult-0-email"))
              }
              onChange={() =>
                this.validateEmail(document.getElementById("adult-0-email"))
              }
              onFocus={() => {
                var inputField = document.getElementById("adult-0-email");
                inputField.classList.remove("invalid_input");
                inputField.placeholder = Translation.t("email_address");
              }}
            />
          </Col>
        </Row>
        <Row className="my-4">
          <div className="col-12">
            <div className="et-checkbox">
              <input
                type="checkbox"
                className="checkbox-input"
                id="receive-emial"
                // checked={this.props.rememberMe}
                onChange={() => {
                  var receiveEmail = document.getElementById("receive-emial");
                  if(receiveEmail.value==="on"){
                    this.props.updatePassengerMethod(0, true, "contact", "recieveEmail");
                  }
                  else{
                    this.props.updatePassengerMethod(0, false, "contact", "recieveEmail");
                  }
                }}
              />
              <label htmlFor="receive-emial" className="d-inline">
                {Translation.t("receive_email")}
              </label>
            </div>
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    passengerList: state.passenger.passengerList,
    rememberMe: state.passenger.rememberMe,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeRememberMe: () =>
      dispatch({
        type: passengerAction.CHANGEREMEMBERME,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
