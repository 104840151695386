export const accountAction = {
  VALIDATE: "VALIDATE",
  BLURUSERNAME: "BLURUSERNAME",
  BLURPASSWORD: "BLURPASSWORD",
  FOCUSUSERNAME: "FOCUSUSERNAME",
  FOCUSPASSWORD: "FOCUSPASSWORD",
  CHANGEVALIDATION: "CHANGEVALIDATION",
  UPADTE: "UPADTE",
  VALIDATECREATEACCOUNT: "VALIDATECREATEACCOUNT",
  UPDATEPASSWORD: "UPDATEPASSWORD",
  UPDATEPASSWORDVALIDATION: "UPDATEPASSWORDVALIDATION",
  UPDATEBILLINGINFO: "UPDATEBILLINGINFO",
  UPDATEBILLINGINFOVALIDATION: "UPDATEBILLINGINFOVALIDATION",
  UPDATETRAVELDOCUMENT: "UPDATETRAVELDOCUMENT",
  UPDATETRAVELDOCUMENTVALIDATION: "UPDATETRAVELDOCUMENTVALIDATION",
  UPDATEFREQUENTFLYER: "UPDATEFREQUENTFLYER",
  INTIALZEPROFILE: "INTIALZEPROFILE",
  SIGNEDIN: "SIGNEDIN",
  UPDATEGENDER: "UPDATEGENDER",
  ADDFREQUENTFLYER: "ADDFREQUENTFLYER",
  REMOVEFREQUENTFLYER: "REMOVEFREQUENTFLYER",
  GETMYTRIPS:"GETMYTRIPS",
  SIGNOUT:"SIGNOUT"
};
