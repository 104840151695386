import React from "react";

import moment from "moment";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./FlightSelectionRibbon.css";
import { connect } from "react-redux";
import { searchFlightAction } from "../../../../redux/actions/booking/searchFlightAction";
import HttpService from "../../../../service/shared/HttpService";
import { globalActionType } from "../../../../redux/actions/shared/globalAction";
import { FlightSelectionService } from "../../../../service/booking/flight-selection/FlightSelectionService";
import { ApiResponseStatus } from "../../../../master-data/shared/APIResponseStatus";

class FlightSelectionRibbon extends React.Component {
  state = {
    selected: false,
    isLoading : false
  };
  thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  }
  componentDidMount() {
    if(this.props.alternateDayResponse !== null && this.props.alternateDayResponse.length === 7)
      this.scrollcenter();
    window.addEventListener("resize", this.scrollcenter);
  }
  scrollcenter = () => {
    try{
      const element = document.getElementsByClassName(
        "horizontal_flex_container"
      )[0];
      var maxScrollLeft = element.scrollWidth - element.clientWidth;
      element.scrollLeft = maxScrollLeft/2;
    }
    catch(e){
        console.log(e);
    }
  };

  triggerNewSearch = async (date) => {
    var searchRequest = JSON.parse(JSON.stringify(this.props.searchFlight.searchRequest));
    searchRequest.itineraryParts[this.props.flightSelection.flightIndex].when.date = date;
    let flightSelectionService = new FlightSelectionService();
    
    this.props.updateFlightRequest(searchRequest);
    this.props.updateIsLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (this.props.flightSelection.flightIndex === 0) {
      HttpService.postService(
        searchRequest,
        "/Availability/Search",
        this.props.searchFlight.token
      )
        .then((response) => {
          this.props.saveSearchResult(response);
          this.props.updateIsLoading(false);

          this.setState({ isLoading: false, redirect: true });
        })
        .catch((error) => {
          this.setState({ isLoading: false });

          console.log(error);
        });
    }
    else {
      await flightSelectionService
      .searchContext(
          searchRequest,
          this.props.flightSearchRequest.itineraryParts[0].selectedOfferRef,
          0,
          this.props.searchFlight.searchResult.data.cookieSabreDataRequest,
          this.props.searchFlight.token,
          this.props.searchFlight.searchResult
        )
        .then((response) => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          if(response.data.statusAPI === ApiResponseStatus.SUCCESS)
          {
              this.props.updateFlightResult(response);
          }
          else{
            this.setState({isException:true});
          }
          this.props.updateIsLoading(false);
        });
    }
  };

  backButton = () => {
    var isDisabled = true;

    var buttonValue = "";

    if (moment().toDate() >= moment(this.props.alternateDayResponse[0].date))
      isDisabled = true;
    else if (
      moment().add(3, "days").format("YYYY MM DD") >=
      moment(this.props.alternateDayResponse[0].date).format("YYYY MM DD")
    ) {
      buttonValue = moment().format("YYYY-MM-DD");
      isDisabled = false;
    } else if (
      this.props.journyType === "oneWay" &&
      moment().add(3, "days").format("YYYY MM DD") <
        moment(this.props.alternateDayResponse[0].date).format("YYYY MM DD")
    ) {
      buttonValue = moment(this.props.alternateDayResponse[3].date)
        .subtract(7, "days")
        .format("YYYY-MM-DD")
      isDisabled = false;
    } else {
    }
    if (this.props.journyType === "roundTrip" && this.props.flightSelection.flightIndex === 1) {
      if (
        moment(this.props.itineraryParts[0].when.date).toDate() >=
        moment(this.props.alternateDayResponse[0].date)
      )
        isDisabled = true;
      else if (
        moment(this.props.itineraryParts[0].when.date)
          .add(3, "days")
          .format("YYYY MM DD") >=
        moment(this.props.alternateDayResponse[0].date).format("YYYY MM DD")
      ) {
        buttonValue = moment(this.props.itineraryParts[0].when.date).format(
          "YYYY-MM-DD"
        );
        isDisabled = false;
      } else if (
        moment(this.props.itineraryParts[0].when.date)
          .add(3, "days")
          .format("YYYY MM DD") >=
        moment(this.props.alternateDayResponse[0].date).format("YYYY MM DD")
      ) {
        buttonValue = moment(this.props.alternateDayResponse[3].date)
          .subtract(7, "days")
          .format("YYYY-MM-DD");
        isDisabled = false;
      } else {
      }
    }

    var classLists = "back-alternate-btn alternate-btn back-alternate-shadow d-flex align-items-center justify-content-center";
    if (isDisabled) {
      classLists = classLists + " disable-alternate-btn";
    }
    return (
      <button
        className={classLists}
        id="back-alternate-btn"
        onClick={isDisabled ? null : () => this.triggerNewSearch(buttonValue)}
      >
       <FontAwesomeIcon style={{fontSize: "20px", color: "#022504d4"}} icon={faAngleLeft}/>
      </button>
    );
  };

  nextButton = () => {
    var isDisabled = true;

    var buttonValue = "";

    if (this.props.journyType === "oneWay") {
      isDisabled = false;
      buttonValue = moment(this.props.alternateDayResponse[3].date)
        .add(7, "days")
        .format("YYYY-MM-DD");
    } else {
      // if it is select first flight
      if (this.props.flightSelection.flightIndex === 1) {
        isDisabled = false;
        buttonValue = moment(this.props.alternateDayResponse[3].date)
          .add(7, "days")
          .format("YYYY-MM-DD");
      } else {
        if (
          moment(this.props.itineraryParts[1].when.date).format("YYYY-MM-DD") <=
          moment(this.props.alternateDayResponse[6].date).format("YYYY-MM-DD")
        ) {
          isDisabled = true;
        } else if (
          moment(this.props.itineraryParts[1].when.date)
            .add(3, "days")
            .format("YYYY-MM-DD") <=
          moment(this.props.alternateDayResponse[6].date).format("YYYY-MM-DD")
        ) {
          isDisabled = true;
        } else if (
          moment(this.props.itineraryParts[1].when.date).format("YYYY-MM-DD") <=
          moment(this.props.alternateDayResponse[6].date)
            .add(4, "days")
            .format("YYYY-MM-DD")
        ) {
          buttonValue = moment(this.props.itineraryParts[1].when.date)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
          isDisabled = false;
        } else {
          buttonValue = moment(this.props.alternateDayResponse[3].date)
            .add(7, "days")
            .format("YYYY-MM-DD");
          isDisabled = false;
        }
      }
    }

    var classLists = "next-alternate-btn alternate-btn next-alternate-shadow d-flex align-items-center justify-content-center";
    if (isDisabled) {
      classLists = classLists + " disable-alternate-btn";
    }
    return (
      <button
        className={classLists}
        id="next-alternate-btn"
        onClick={isDisabled ? null : () => this.triggerNewSearch(buttonValue)}
      >
        <FontAwesomeIcon style={{fontSize: "20px", color: "#022504d4"}} icon={faAngleRight}/>
      </button>
    );
  };

  singleFlightRibbon = (alternate, index) => {
    var classLists = "col px-0 fareoption-container";
    var isDisabled = false;
    if (index === 3) classLists = classLists + " fareoption-container-active";
    if (alternate.currency === "N/A") {
      isDisabled = true;
    } else if (this.props.journyType === "roundTrip") {
      // first flight selected
      if (this.props.flightSelection.flightIndex === 1) {
        isDisabled =
          moment(alternate.date).format("YYYY-MM-DD") <=
          moment(this.props.itineraryParts[0].when.date).format("YYYY-MM-DD");
      } else {
        isDisabled =
          moment(alternate.date).format("YYYY-MM-DD") >
          moment(this.props.itineraryParts[1].when.date).format("YYYY-MM-DD");
      }
    }
    if (isDisabled) {
      classLists = classLists + " disabled-ribbon";
    }
    if(index === 3) {
      isDisabled = true;
      if(this.props.flightSelection.flightIndex === 0){
        classLists = "col px-0 fareoption-container fareoption-container-active";
      }
    }
    
    
    return (
      <div
        role="button"
        key={index}
        className={classLists}
        onClick={
          isDisabled ? null : () => this.triggerNewSearch(moment(alternate.date).format("YYYY-MM-DD"))
        }
      >
        <div className="py-3 px-2">
          <span className="date">
            {moment(alternate.date).locale("en").format("MMM DD")}
          </span>
          <h5>
            {alternate.currency === "N/A" ? (
              <span className="price"></span>
            ) : (
              <span className="price">
                {this.thousands_separators(alternate.amount)}{" "}
              </span>
            )}
            <span className="currency">{alternate.currency}</span>
          </h5>
        </div>
      </div>
    );
  };

  listAllRibons = () => {
    return this.props.alternateDayResponse.map((alternate, index) => {
      return (
        <div
          key={index}
          className="flight-ribbon-top-wrapper align-items-center"
        >
          {" "}
          {this.singleFlightRibbon(alternate, index)}{" "}
        </div>
      );
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.scrollcenter);
    this.setState = (state,callback)=>{
        return;
    };
}

  render() {
    return (
      <>
        {this.props.alternateDayResponse !== null && this.props.alternateDayResponse.length === 7 ? 
        <div className="p-0 d-flex justify-content-between flight-ribbon-container">
          <div className="justify-content-center mob-indicator d-flex align-items-center">
            {this.backButton()}
          </div>
          <div className="col px-0 d-flex horizontal_flex_container">
            {this.listAllRibons()}
          </div>
          <div className="justify-content-center mob-indicator d-flex align-items-center">
            {this.nextButton()}
          </div>
        </div>
        : null}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    itineraryParts: state.searchFlight.searchRequest.itineraryParts,
    journyType: state.searchFlight.searchRequest.journyType,
    searchResponse: state.searchFlight.searchResult.data.searchResponse,
    alternateDayResponse: state.searchFlight.searchResult.data.alternateDayResponse,
    flightSelection: state.flightSelection,
    searchFlight : state.searchFlight,
    flightSearchRequest: state.searchFlight.searchRequest,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFlightResult: (result) => {
      dispatch({
        type: searchFlightAction.SAVESEARCHRESULT,
        searchResult: result,
      });
    },
    saveSearchResult: (result) =>
      dispatch({
        type: searchFlightAction.SAVESEARCHRESULT,
        searchResult: result,
      }),
      updateIsLoading: (value) => {
        dispatch({
          type: globalActionType.UPDATEISLOADING,
          value: value,
        })
      },
      updateFlightRequest: (value) => {
        dispatch({
          type: searchFlightAction.UPDATEFLIGHTREQUEST,
          searchRequest: value,
        })
      },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(FlightSelectionRibbon);
