import { stat } from "@mrmlnc/readdir-enhanced";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import { passengerAction } from "../../actions/booking/passengerAction";
import { manageMyBookingAction } from "../../actions/manage-my-booking/manageMyBookingAction";

const initialState = {
  passengerList: [],
  isFormValid: true,
  isRememberedTrue: false,
  rememberMe: false,
  passengers: []
};
var passengerService = new PassengerService();
const passengerhReducer = (state = initialState, action) => {
  switch (action.type) {
    case passengerAction.ADDPASSENGER:
      var passengerList = passengerService.addPassenger(
        action.adultNo,
        action.childNo,
        action.infantNo,
        state.passengerList
      );
      return {
        passengerList: passengerList,
        isFormValid: true,
        isRememberedTrue: false,
        rememberMe: false,
      };
    case passengerAction.UPDATEPASSENGERINFO:
      var updatedPassengerList = passengerService.updatePassenger(
        state,
        action.index,
        action.value,
        action.objName,
        action.inputName,
        action.updateGender
      );
      return {
        ...state,
        passengerList: updatedPassengerList,
      };
    case passengerAction.SAVEPASSENGER:
      var checkedPassenger = passengerService.checkPassengeList(state);
      return {
        ...state,
        passengerList: checkedPassenger.passengerList,
        isFormValid: checkedPassenger.isFormValid,
      };
    case passengerAction.CHANGEVALIDATION:
      var validatedPassenger = passengerService.validatePassenger(
        state,
        action.index,
        action.domain,
        action.className
      );
      return {
        ...state,
        passengerList: validatedPassenger,
      };
    case passengerAction.REMOVEVALIDATION:
      var validationRemovePassenger = passengerService.removeValidation(state);
      return {
        ...state,
        validationRemovePassenger,
      };
    case passengerAction.UPDATESAVEDPROFILE:
      var passengers = passengerService.updateSavedProfile(
        state,
        action.savedProfile
      );
      return {
        ...state,
        passengerList: passengers,
        isRememberedTrue: true,
        rememberMe: true,
      };
    case passengerAction.CHANGEREMEMBERME:
      
      var oldValue = state.rememberMe;
      return {
        ...state,
        rememberMe: !oldValue,
      };
    case passengerAction.FILLPASSENGERFORM:
      var updatePassegerFrom = passengerService.fillPassengerFrom(
        state,
        action.userProfile
      );
      return {
        ...state,
        passengerList: updatePassegerFrom,
      };
    case passengerAction.REMOVEFILLEDPASSENGERFORM:
      var forAnotherPasseger = passengerService.removeFillPassengerFrom(state);
      return {
        ...state,
        passengerList: forAnotherPasseger,
      };
    case passengerAction.UPDATEPASSENGERCONTACT:
      var updatedPassengerContact = passengerService.updatePassengerContact(
        state,
        action.value,
        action.updateType
      );
      return {
        ...state,
        passengers: updatedPassengerContact,
      };
    case passengerAction.UPDATEPASSENGERADDITIONALINFO:
      var updatedPassengerAdditionalInfo =
        passengerService.updatePassengerAdditionalInfo(
          state,
          action.value,
          action.updateType,
          action.passengerId
        );
      return {
        ...state,
        passengers: updatedPassengerAdditionalInfo,
      };
      case manageMyBookingAction.SAVEDATA:
        return{
          ...state,
          passengers:action.data.pnr.passengers
        }
      
      default:
      return state;
  }
};

export default passengerhReducer;
