import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import AirportSelection from "../../booking/booking-widget/airport-selection/AirportSelection";
import DatePicker from "../../booking/booking-widget/booking-calendar/DatePicker";
import { connect } from "react-redux";
import moment from "moment";
import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { globalActionType } from "../../../redux/actions/shared/globalAction";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction"; 
import FlightInfoService from "../../../service/manage-my-booking/FlightInfoService";
import HttpService from "../../../service/shared/HttpService";

import Translation from "i18next";

import { AirportFullName } from "../../../service/shared/AirportFullName";


const EditFlightSchedule = (props) => {
  // const [itineraryPart, setItineraryPart] = useState(() => {
  //   const initialState = JSON.parse(JSON.stringify(props.itineraryPart));
  //   return initialState;
  // });
  
  const [showEditForm, setShowEditForm] = useState(false);

  const flightInfoService = new FlightInfoService();

  const retrieveFlightSchedule = () => {
    if (
      props.itineraryPart[0].params.from !== null &&
      props.itineraryPart[0].params.to !== null &&
      props.itineraryPart[0].params.when.departureDate !== null
    ) {
      props.updateIsLoading(true);
      setShowEditForm(!showEditForm)
      var payload = {
        Origin: props.itineraryPart[0].params.from.value,
        Destination: props.itineraryPart[0].params.to.value,
        requestType: 2,
        departureDate: props.itineraryPart[0].params.when.departureDate,
      };
      flightInfoService
        .getFlightScheduleByRoute([payload], props.token)
        .then((response) => {
          console.log(response);
          if (response.data.statusAPI === "ERROR") {
            props.updateIsFlightSchedulePresent(false);
          } else {
            props.updateBackedupSchedules(
              response.data.flightSchedules[0].flightSchedules
            );
          }
          props.updateIsLoading(false);
        }).catch((error) => {
          HttpService.getTokenServive()
            .then((response) => {
              props.saveToken(response.data.tokenDetail.access_token);
              flightInfoService
              .getFlightScheduleByRoute([payload], response.data.tokenDetail.access_token)
              .then((response) => {
                console.log(response);
                if (response.data.statusAPI === "ERROR") {
                  props.updateIsFlightSchedulePresent(false);
                } else {
                  props.updateBackedupSchedules(
                    response.data.flightSchedules[0].flightSchedules
                  );
                }
                props.updateIsLoading(false);
            });
          })
    });
  }
  };

  return (
    <>
      <div className="edit-schedule-wrapper">
        <div className="flight-selection-container">
          <Row className="mx-0 w-100">
            <Col xs={3}>{props.isMedium ? props.flightSchedules[0].flights[0].origin : AirportFullName.getFullName(props.flightSchedules[0].flights[0].origin)}</Col>
            <Col xs={3}>{props.isMedium ? props.flightSchedules[0].flights[props.flightSchedules[0].flights.length - 1].destination : AirportFullName.getFullName(props.flightSchedules[0].flights[props.flightSchedules[0].flights.length - 1].destination)}</Col>
            <Col xs={3}>
              <span className="edit-flight-date d-flex">
                {moment(props.itineraryPart[0].params.when.departureDate)
                  .locale("en")
                  .format("DD MMM")}
              </span>
              {props.isMedium ? null : <span className="edit-flight-day-of-the-week d-flex">
                {moment(props.itineraryPart[0].params.when.departureDate)
                  .locale("en")
                  .format("dddd")}
              </span>}
            </Col>
            <Col xs={3} className="d-flex justify-content-end px-0">
              <button
                className="btn btn-md btn-success"
                onClick={() => setShowEditForm(!showEditForm)}
              >
                <FontAwesomeIcon icon={faPen} className="mx-2" />
              </button>
            </Col>
          </Row>

          {showEditForm ? (
            <div className="mx-0 w-100 py-4 edit-schedule-insider">
              <h2>{Translation.t("enter_flight_information")}</h2>
              <p>{Translation.t("please_note")}
              </p>
              <Row>
                <AirportSelection flightIndex={0} />
              </Row>
              <Row>
                <DatePicker flightIndex={0} name="departure_date_0" />
              </Row>
              <Row className="mt-3 justify-content-end">
                <Col xs={12} sm={12} md={6} lg={2}>
                  <Button
                    variant="danger"
                    className="btn-block bk-submit-btn btn-ethiopian mt-3"
                    onClick={() => setShowEditForm(!showEditForm)}
                  >
                    {Translation.t("cancel")}
                  </Button>
                </Col>
                <Col xs={12} sm={12} md={6} lg={2}>
                  <Button
                    variant="danger"
                    className="btn-block bk-submit-btn btn-ethiopian my-3"
                    id="start-booking-btn"
                    onClick={() => retrieveFlightSchedule()}
                  >
                    {Translation.t("save")}
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
    token: state.searchFlight.token,
    isLoading: state.global.isLoading,
    isMedium : state.global.isMedium
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBackedupSchedules: (value) =>
      dispatch({
        type: flightScheduleAction.SCHEDULEINITFLIGHTS,
        payload: value,
      }),
    updateIsLoading: (value) =>
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value : value
      }),
      saveToken: (token) =>
        dispatch({
          type: searchFlightAction.SAVETOKEN,
          token: token,
        }),
        updateIsFlightSchedulePresent: (value) =>
          dispatch({
            type: flightScheduleAction.ISFLIGHTSCHEDULEPRESENT,
            payload: value,
          }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFlightSchedule);
