import { React, Component } from "react";
import { Col, Modal } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { seatSelectionAction } from "../../../../redux/actions/booking/seatSelectionAction";
// import { SeatSelectionService } from "../../../../service/booking/seat-selection/SeatSelectionService";
import Translation from "i18next";
import { connect } from "react-redux";
import Notification from "../../../booking/booking-widget/Notification";
import { Redirect } from "react-router";
import CheckInService from "../../../../service/check-in/CheckInService";

import { checkInAction } from "../../../../redux/actions/check-in/checkInAction";

class Seat extends Component {
  state = {
    focus: false,
    clicked: false,
    seatName: "",
    width: "0",
    left: 0,
    isSeatPopUpModalOpen: false,
    isExceptionModalOpened: false,
    redirect: false,
  };
  seatName = "";
  width = "0";
  left = "";
  markerLeft = "";
  seatMapIdentifier = "";

  seatMapKeys = {
    9: "Center Seat",
    W: "Window Seat",
    CH: "",
    A: "Aisle Seat",
  };
  classListForTopWrapper = "";
  classListForInsider = "";

  errorHeader = "";
  errorBody = "";

  constructor(props) {
    super(props);
    this.state.seatName = this.props.row + this.props.column;

    this.seatMapIdentifier = this.props.seatMapIdentifier;
    this.classListForTopWrapper =
      // !(this.props.facility === null || this.props.facility === undefined) &&
      // this.props.facility[0].type ===  "EXTRA_LEG_SPACE"
      // ? "seat-top-wrapper mb-3 extra-leg-seat-insider-wrapper":
      "seat-top-wrapper mb-3";
    if (
      !(this.props.facility === null || this.props.facility === undefined) &&
      this.props.facility[0].type === "EXTRA_LEG_SPACE"
    ) {
      this.classListForTopWrapper =
        this.classListForTopWrapper +
        " extra-leg-seat-insider-wrapper extra-leg-for-checkin";
      console.log(this.props.facility);
    }
    this.classListForInsider = this.props.seat.chargeable
      ? "seat-insider-wrapper justify-content-center d-flex premium-seat "
      : "seat-insider-wrapper justify-content-center d-flex ";
  }
  componentDidMount() {
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    this.width = ClientRect.width - 25;
    this.left = ClientRect.left - ClientRect.left;
    this.seatName = this.props.row + this.props.column;
    this.seatMapIdentifier = this.props.seatMapIdentifier;
    // console.log(this.props.seat)
    // console.log("mounted")
  }

  handleSeatClick = (event) => {
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    var width = ClientRect.width - 15;
    this.setState({ width: width, left: this.left });
    if (window.innerWidth <= 767) {
      try {
        const element = event.target;
        const elementRect = element.getBoundingClientRect();
        var customClientRect = document.getElementById("custom-seat-modal-id");
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle =
          absoluteElementTop -
          (window.innerHeight - customClientRect.offsetHeight - 60); //(window.innerHeight / 2);
        window.scrollTo({ top: middle, behavior: "smooth" });
      } catch {}
    }
  };

  handleFocus = (event) => {
    var ClientRectevent =
      event.target.parentNode.parentNode.parentNode.getBoundingClientRect(); // event.target.getBoundingClientRect();
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    this.width = ClientRect.width - 15;
    this.markerLeft = "42%";
    this.left = ClientRect.left - ClientRectevent.left + 8;
    this.setState({ focus: true, isSeatPopUpModalOpen: true });
  };
  handleBlur = () => {
    this.setState({ focus: false });
  };

  getPrice = (seatCode) => {
    // this.props.SeatMapId;
    var currency = "";
    var priceFound = "";
    this.props.seatMap.map((seatMap) => {
      if (seatMap.id === this.props.SeatMapId) {
        var passengerSeatDetail =
          seatMap.passengerSeatDetails.passengerSeatDetail[0];
        passengerSeatDetail.seatPrice.map((seatPrice) => {
          if (seatPrice.value.includes(seatCode)) {
            seatMap.pricing.price.map((price) => {
              if (price.id === seatPrice.priceRef) {
                priceFound = price.ancillaryFee.totalFee[0].total.amount.value;
                currency = price.ancillaryFee.totalFee[0].total.amount.currency;
              }
              return null;
            });
          }
          return null;
        });
      }
      return null;
    });

    return priceFound + " " + currency;
  };

  modal = () => {
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ isSeatPopUpModalOpen: false })}
      >
        <div style={{ left: this.markerLeft }} className="seat-pointer"></div>
        <div
          style={{ width: this.width, left: this.left }}
          id="custom-seat-modal-id"
          className={
            this.state.isSeatPopUpModalOpen
              ? "custom-modal show row"
              : "custom-modal"
          }
        >
          <div className="col-12 custom-modal-header">
            <div className="d-flex modal-title h4 d-inline-flex"></div>
            {/* <span role="button" className="close-custom-modal float-right d-inline-flex" onClick={() => this.setState({isSeatPopUpModalOpen : false})}>
            <FontAwesomeIcon icon={faTimes} />
            </span> */}
            <button
              onClick={() => this.setState({ isSeatPopUpModalOpen: false })}
              type="button"
              className="close-custom-modal d-inline-flex close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">{Translation.t("close")}</span>
            </button>
          </div>
          <div className="col-12 custom-modal-body d-flex">
            {this.props.seat.number + " - " + this.props.position}
          </div>

          <div className="col-12">
            {this.props.seat.seatCharacteristics !== undefined &&
            this.props.seat.seatCharacteristics !== null ? (
              <>
                {this.props.seat.seatCharacteristics.map((character) => {
                  return this.seatMapKeys[character];
                })}
              </>
            ) : null}
            <div className="col-12 seat-selection-popup-modal-body pl-0">
              {this.props.seat.limitations !== undefined &&
              this.props.seat.limitations !== null ? (
                <ul className="col-12 custom-modal-body pl-5 mb-0">
                  {this.props.seat.limitations.map((val) => {
                    return <li className="">{val}</li>;
                  })}
                </ul>
              ) : null}
              <div>{this.props.seat.designations}</div>
              <div>{this.props.seat.slotCharacteristics}</div>
              {/* {!this.props.seat.freeOfCharge && this.props.seat.total.alternatives.length !== 0
                ? this.props.seat.total.alternatives[0][0].amount +
                  " " +
                  this.props.seat.total.alternatives[0][0].currency
                : null}
              <br /> */}
              {!this.props.seat.chargeable
                ? "Cost : This is a free Seat"
                : "Cost : " + this.getPrice(this.props.seat.number)}
              {this.props.seat.chargeable ? <div>Premium Seats</div> : null}{" "}
              <div>
                {!(
                  this.props.facility === null ||
                  this.props.facility === undefined
                ) && this.props.facility[0].type === "EXTRA_LEG_SPACE" ? (
                  <span className="pl-2">(Extra Leg Room)</span>
                ) : null}
              </div>
              <div>
                {this.props.characteristic !== null &&
                this.props.characteristic !== undefined &&
                this.props.characteristic.includes("EXIT") ? (
                  <div className="mt-2">
                    <h4>Terms and Conditions</h4>
                    <p>
                      By selecting this seat, you confirm that you agree with
                      the{" "}
                      <a
                        href="https://www.ethiopianairlines.com/et/information/special-needs/exit-row-disclaimer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        emergency exit row seating conditions
                      </a>
                      <br /> Ethiopian may reassign your seat before or during
                      the journey without any refund if you don't meet any of
                      the listed safety criteria’s mentioned in the link.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-12 mb-3 py-2">
            <button
              onClick={() => this.handleSelectSeat()}
              className="select-seat-btn btn"
            >
              {Translation.t("select")}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    );
  };
  handleSelectSeat = () => {
    this.setState({ isSeatPopUpModalOpen: false });

    this.props.seatSelectingUpdate(true);

    let seatSelectionServiceForChecking = new CheckInService();

    var checkinPayload = {
      seatNumber: this.props.seat.number,
      passengerFlightId:
        this.props.passengerIndex +
        "." +
        this.props.seatMapIdentifier +
        ".f" +
        this.props.seatMapIdentifier[1],
    };

    seatSelectionServiceForChecking
      .selectSeat(
        checkinPayload,
        this.props.token,
        this.props.checkInSabreDataRequest
      )
      .then((response) => {
        this.props.seatSelectingUpdate(false);

        if (
          response.data.errorCode === "" ||
          response.data.errorCode === null
        ) {
          this.props.selectSeat({
            seatMapIdentifier: this.props.seatMapIdentifier,
            passengerIndex: this.props.passengerIndex,
            seatCode: this.props.seat.number,
            seat: this.props.seat,
          });
          this.props.updateReservation(response.data.reservation);
          this.updateActivePassengersDataAfterSubmit(
            response.data.reservation.passengers.passenger
          );
        }
      })
      .catch((error) => {
        seatSelectionServiceForChecking
          .selectSeat(
            checkinPayload,
            this.props.token,
            this.props.checkInSabreDataRequest
          )
          .then((response) => {
            this.props.seatSelectingUpdate(false);
            if (
              response.data.errorCode !== "" ||
              response.data.errorCode !== null
            ) {
              this.props.selectSeat({
                seatMapIdentifier: this.props.seatMapIdentifier,
                passengerIndex: this.props.passengerIndex,
                seatCode: this.props.seat.number,
                seat: this.props.seat,
              });
              this.props.updateReservation(response.data.reservation);
              this.updateActivePassengersDataAfterSubmit(
                response.data.reservation.passengers.passenger
              );
            }

            // if (this.props.passangers.length !== this.props.passengerIndex) {
            //   var nextPassenger =
            //     this.props.passangers[this.props.passengerIndex];
            //     this.props.changeActivePassangerCode(
            //       response.data.passengers.seatMapPassenger[0].passenger.type.value[0],
            //       response.data.passengers.seatMapPassenger[0].passenger.id
            //     );
            //   if("INFANT" !== nextPassenger.passenger.type.value){
            //     this.props.changeActivePassangerCode(
            //     nextPassenger.passenger.passengerInfo.type[0] +
            //       nextPassenger.passenger.passengerIndex,
            //     nextPassenger.passenger.passengerIndex
            //   );
            //   }
            // }
          })
          .catch((error) => {
            this.props.seatSelectingUpdate(false);
            console.log(error);
            if (error.response !== undefined) {
              this.errorHeader = "Session Expired";
              this.errorBody = "Session is expired. Start booking from scratch";
            } else {
              this.errorHeader = "Offline";
              this.errorBody =
                "can not comunicate to the server. try connecting to the internet and try again";
            }
            this.setState({ isExceptionModalOpened: true });
          });
      });
  };

  getModal() {
    return (
      <Modal
        show={this.state.isExceptionModalOpened}
        onHide={() => {
          this.setState({
            isExceptionModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <Notification header={this.errorHeader} body={this.errorBody} />
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <a
              href="/"
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
            >
              {Translation.t("start_over")}
            </a>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  updateActivePassengersDataAfterSubmit = (passengers) => {
    var activepassengers = {};
    passengers
      .filter((passenger) => passenger.id in this.props.activePassengers)
      .map((passenger) => {
        activepassengers[passenger.id] = passenger;
        return null;
      });
    this.props.initActivePassengers(activepassengers);
  };
  render() {
    var disabled =
      this.seatMapIdentifier in this.props.selectedSeats &&
      this.props.selectedSeats[this.seatMapIdentifier] !== undefined &&
      this.props.seat.number in
        this.props.selectedSeats[this.seatMapIdentifier];

    var available_seat =
      this.props.seat.status === "AVAILABLE" ||
      (this.seatMapIdentifier in this.props.selectedSeatHistory &&
        this.props.selectedSeatHistory[this.seatMapIdentifier] !== undefined &&
        this.props.seat.number in
          this.props.selectedSeatHistory[this.seatMapIdentifier]);

    var gotcha =
      this.seatMapIdentifier in this.props.selectedSeats &&
      this.props.selectedSeats[this.seatMapIdentifier] !== undefined &&
      this.props.seat.number in
        this.props.selectedSeats[this.seatMapIdentifier];

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <>
        {this.getModal()}
        {
          <Col
            key={this.props.keyType + "-insider"}
            className={this.classListForTopWrapper + " justify-content-center"}
          >
            <>
              <div
                role="button"
                className={
                  this.state.focus
                    ? this.classListForInsider + " active"
                    : this.classListForInsider
                }
                onFocus={
                  available_seat && !disabled
                    ? (event) => this.handleFocus(event)
                    : null
                }
                onBlur={
                  available_seat && !disabled ? () => this.handleBlur() : null
                }
                onClick={
                  available_seat && !disabled
                    ? (event) => this.handleSeatClick(event)
                    : null
                }
              >
                <span role="button" className="seat-frame d-flex"></span>
                <div
                  role="button"
                  className={
                    this.state.focus
                      ? "seat-container justify-content-center d-flex active"
                      : "seat-container justify-content-center d-flex"
                  }
                >
                  <span
                    disabled={available_seat || disabled ? "" : "disabled"}
                    tabIndex={available_seat && !disabled ? "1" : null}
                    role="button"
                    // className={
                    //   "seat d-flex align-items-center justify-content-center"
                    // }
                    className={
                      !(
                        this.props.facility === null ||
                        this.props.facility === undefined
                      ) && this.props.facility[0].type === "EXTRA_LEG_SPACE"
                        ? "seat d-flex align-items-center justify-content-center extra-leg-seat"
                        : "seat d-flex align-items-center justify-content-center"
                    }
                  >
                    {available_seat || gotcha ? (
                      <>
                        {" "}
                        {this.seatMapIdentifier in this.props.selectedSeats ? (
                          <>
                            {gotcha ? (
                              <>
                                <span className="selected-seat">
                                  {
                                    this.props
                                      .activePassangerIdentifierCodeMapper[
                                      this.props.selectedSeats[
                                        this.seatMapIdentifier
                                      ][this.props.seat.number]
                                        .activePassangerIdentifierCode
                                    ]
                                  }
                                </span>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>×</>
                    )}
                  </span>
                </div>
              </div>
              {this.state.isSeatPopUpModalOpen ? this.modal() : null}
            </>
          </Col>
        }
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    passengers: state.checkIn.reservation.passengers.passenger,
    selectedSeats: state.checkIn.selectedSeats,
    passengerIndex: state.seatSelection.passengerIndex,
    seatSelecting: state.checkIn.seatSelecting,
    selectedSeatHistory: state.checkIn.selectedSeatHistory,
    activePassangerIdentifierCode: state.checkIn.activePassangerIdentifierCode,
    activePassangerIdentifierCodeMapper:
      state.checkIn.activePassangerIdentifierCodeMapper,
    checkInSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    token: state.searchFlight.token,
    activePassengers: state.checkIn.activePassengers,
    seatMap: state.checkIn.seatMap,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSeat: (result) => {
      dispatch({
        type: checkInAction.CHECKINCHANGESEAT,
        searchResult: result,
      });
    },
    selectSeat: (payload) =>
      dispatch({
        type: checkInAction.CHECKINSELECTSEAT,
        payload: payload,
      }),
    seatSelectingUpdate: (value) =>
      dispatch({
        type: checkInAction.CHECKINSEATSELECTING,
        value: value,
      }),
    updateReservation: (value) =>
      dispatch({
        type: checkInAction.UPDATERESERVATION,
        reservation: value,
      }),
    initActivePassengers: (value) =>
      dispatch({
        type: checkInAction.INITACTIVEPASSENGER,
        activePassengers: value,
      }),
    changeActivePassangerCode: (value, passengerIndex) => {
      dispatch({
        type: seatSelectionAction.CHANGEACTIVEPASSANGERCODE,
        activePassangerIdentifierCode: value,
        passengerIndex: passengerIndex,
      });
    },
  };
};

// export default Seat;
export default connect(mapStateTopProps, mapDispatchToProps)(Seat);
