import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import PaymentSummaryHeader from "./PaymentSummaryHeader";
import PaymentSummaryTripDetail from "./PaymentSummaryTripDetail";
import Translation from "i18next";
import PaymentSummaryPassenger from "./PaymentSummaryPassenger";
import PaymentSummaryAction from "./PaymentSummaryAction";

class PaymentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerList: [],
      tripDetail: [],
      pnr: null,
      contactInfo: null,
      passengerTicketInfo: [],
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" }); 
    this.setState({
      pnr: this.props.location.state.pnr.reloc,
      tripDetail: this.props.location.state.pnr.itinerary.itineraryParts,
      passengerList: this.props.location.state.pnr.passengers,
      contactInfo: this.props.location.state.pnr.contact,
      passengerTicketInfo:
        this.props.location.state.pnr.travelPartsAdditionalDetails.length > 0
          ? this.props.location.state.pnr.travelPartsAdditionalDetails[0]
              .passengers
          : [],
    });
  }
  getCityName(airport) {
    // let cityName = airport.substring(0,airport.indexOf("("));
    return AirportFullName.getCityName(airport);
  }
  render() {
    
    return (
      <section className="container">
        <PaymentSummaryHeader
          pnr={this.state.pnr}
          type={
            this.props.location.type === null ? null : this.props.location.type
          }
        />
        <section className="payment-summary-confirmation-header">
          <Row>
            <Col xs={12} sm={12} md={4} lg={3}>
              <div>
                <div> {Translation.t("confirmation_code")}</div>
                <h3>
                  <mark>{this.state.pnr}</mark>
                </h3>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <div className="payment-summary-itinerary pt-3">
                <span>
                  {this.getCityName(
                    this.props.location.state.pnr.itinerary.itineraryParts[0]
                      .segments[0].origin
                  )}
                </span>
                <span className="px-3"></span>
                <span>
                  {this.getCityName(
                    this.props.location.state.pnr.itinerary.itineraryParts[0]
                      .segments[
                      this.props.location.state.pnr.itinerary.itineraryParts[0]
                        .segments.length - 1
                    ].destination
                  )}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={12}>
              {/* <div className="pt-3">
                <span>{Translation.t("print")}</span>
              </div> */}
              <PaymentSummaryAction
                itineraryParts={
                  this.props.location.state.pnr.itinerary.itineraryParts
                }
                state={this.props.location.state}
              />
            </Col>
          </Row>
        </section>
        <PaymentSummaryTripDetail tripDetail={this.state.tripDetail} />
        <PaymentSummaryPassenger
          passengerList={this.state.passengerList}
          contactInfo={this.state.contactInfo}
          passengerTicketInfo={this.state.passengerTicketInfo}
          tripDetail={this.state.tripDetail}
        />
      </section>
    );
  }
}
export default PaymentSummary;
