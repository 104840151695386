import HttpService from "../../shared/HttpService";

export class FlightSelectionService{


   searchContext=(searchRequest,offerRef,index,cookieSabreData,token,flightSearchResult)=>
  {
      let relativeUrl ="/Availability/SearchContext";
      

      if(index === 0)
      {
      searchRequest.itineraryParts[index].selectedOfferRef=offerRef;
      }
      else
      {
        for(let i=0;i<index;i++)
        {
          searchRequest.itineraryParts[i].selectedOfferRef=flightSearchResult.data.searchResponse.brandedResults.itineraryPartBrands[i][0].brandOffers[0].shoppingBasketHashCode;
        }
        searchRequest.itineraryParts[index].selectedOfferRef=offerRef;
      }
      searchRequest["cookieSabreDataRequest"]=cookieSabreData;
      

      return HttpService.postService(searchRequest,relativeUrl,token);
 
  }
  selectFlight=(searchRequest,offerRef,index,cookieSabreData,token,flightSearchResult)=>
  {
    let relativeUrl ="/Availability/SelectFlight";
    if(index === 0)
    {
    searchRequest.itineraryParts[index].selectedOfferRef=offerRef;
    }
    else
    {
      for(let i=0;i<index;i++)
      {
        searchRequest.itineraryParts[i].selectedOfferRef=flightSearchResult.data.searchResponse.brandedResults.itineraryPartBrands[i][0].brandOffers[0].shoppingBasketHashCode;
      }
      searchRequest.itineraryParts[index].selectedOfferRef=offerRef;
    }
    searchRequest["cookieSabreDataRequest"]=cookieSabreData;
    

    return HttpService.postService(searchRequest,relativeUrl,token);
  }

static changeFlight(flightIndex,journeyType)
{
}

}