import ManageBaggageService from "../../../service/manage-my-booking/account/ManageBaggageService";
import { manageAncillaryAction } from "../../actions/manage-my-booking/manageAncillaryAction";
const initialState = {
  ancillaryList: null,
  baggageModel: [],
  overAllTotal: 0,
  finaloverAllTotal: 0,
  bagIsAdded: false,
  ancillaryListForShoppingCart: [],
  baggageRequest: {},
  finalTempPassengerList: [],
  isNewBagAdded: false,
  currency:null,
  oldValue:0
};
var baggageService = new ManageBaggageService();
const manageAncillaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case manageAncillaryAction.MANAGE_SAVEANCILLARYDATA:
      var intialBaggageModal = baggageService.intializeState(action.data);
      return {
        ancillaryList: action.data,
        baggageModel: intialBaggageModal.passengerList,
        overAllTotal: intialBaggageModal.overAllTotal,
        bagIsAdded: intialBaggageModal.isBaggageAddedBefore,
        finaloverAllTotal: intialBaggageModal.overAllTotal,
        currency:intialBaggageModal.currency,
        oldValue: intialBaggageModal.overAllTotal,
      };
    case manageAncillaryAction.MANAGE_ADDBAG:
      var newBaggageModal = new baggageService.addBag(
        state,
        action.ancillaryCode,
        action.passengerIdItinerary,
        action.price
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case manageAncillaryAction.MANAGE_MINUSBAG:
      var newBaggageModal = new baggageService.minusBag(
        state,
        action.passengerId,
        action.ancillaryIndex,
        action.price,
        action.itinerayIndex
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case manageAncillaryAction.MANAGE_ADDTOCART:
      
      var ancillaryListForShoppingCart = baggageService.addToCart(
        action.baggageRequest,
        state.ancillaryList,
        state.baggageModel
      );
      var bagIsAdded = true;
      if (state.overAllTotal === state.finaloverAllTotal) {
        bagIsAdded = false;
      }
      return {
        ...state,
        bagIsAdded: bagIsAdded,
        ancillaryListForShoppingCart: ancillaryListForShoppingCart,
        finaloverAllTotal: state.overAllTotal,
        baggageRequest: action.baggageRequest,
        isNewBagAdded: bagIsAdded,
      };
    default:
      return state;
  }
};

export default manageAncillaryReducer;
