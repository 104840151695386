import React, { useState } from "react";
import "./Login.css";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faTag,
  faTrophy,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { accountAction } from "../../../redux/actions/manage-my-booking/accountAction";
import Translation from "i18next";
import { useHistory } from "react-router-dom";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import { globalActionType } from "../../../redux/actions/shared/globalAction";

const Login = (props) => {
  let history = useHistory();
  const [signUpOption, updateSignUpOption] = useState(0);
  const [rediretToCreateAccount, setRediretToCreateAccount] = useState(false);
  const [redirectToManageMyTrip, setRedirectToManageMyTrip] = useState(false);
  const [rediretToManageAccount, setRediretToManageAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const signIn = async () => {
    var userName = document.getElementById("login-user-name").value;
    var password = document.getElementById("login-password").value;
    var requestDate = {
      userId: userName,
      password: password,
      captcha: null,
    };
    if (userName !== null && password !== null &&userName !== "" && password !== "") {
      setIsLoading(true);

      HttpService.postAccount(requestDate, "/Login", props.token)
        .then((loginResponse) => {
          if (loginResponse.data.statusAPI === "SUCCESS") {
            setIsLoading(false);
            props.signIn(
              loginResponse.data.result.user.personalDetails.firstName,
              loginResponse.data.result.user.personalDetails.lastName,
              loginResponse.data.cookieSabreDataRequest,
              loginResponse.data.shebaMilesDetails.currentAwardPoints,
              userName,
              password
            );
          } else {
            setIsLoading(false);
            setErrorMessage(loginResponse.data.messageAPI);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
    else{
      props.blurUserName(userName);
      props.blurPassword(password);

    }
  };


  const signOut = () => {
    HttpService.postAccount(props.profileCookie, "/LogOut", props.token);
    props.signOut();
    var url = window.location.pathname;
    if (url.includes("manage-profile")) {
      history.push("/");
    }
  };

  if (rediretToCreateAccount) {
    setRediretToCreateAccount(false);
    props.showLoginPageHandler();
    history.push("/create-account");
  }
  if (redirectToManageMyTrip) {
    setRedirectToManageMyTrip(false);
    props.showLoginPageHandler();
    history.push("/");
  }
  if (rediretToManageAccount) {
    setRediretToManageAccount(false);
    props.showLoginPageHandler();
    history.push("/manage-profile");
  }
  if (props.showLoginPage || props.showLoginPageForManageTrip) {
    if (props.isUserSignedIn) {
      return (
        <div className="login-page mt-5 mt-md-0 w-100">
          {isLoading && <Loading />}

          <Row>
            <Col xs={12}>
              <div className="login-header">
                <h6 className="float-left">
                  {props.firstName} {props.lastName}
                </h6>
                <a className="float-right" onClick={() => signOut()}>
                  {Translation.t("sign_out")}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3> {Translation.t("balance")}</h3>
              <p>
                <b>
                  {props.currentAwardPoints} {Translation.t("miles")}
                </b>
              </p>
            </Col>
          </Row>
          <hr style={{ backgroundColor: "#1c7430" }} />
          <hr style={{ backgroundColor: "#1c7430" }} />
          <Row>
            <Col xs={12}>
              <a
                href={() => false}
                onClick={() => {
                  setRedirectToManageMyTrip(true);
                  props.changeWidgetType("manageTrip");
                }}
              >
                {Translation.t("manage_your_trips")}
              </a>
            </Col>
            <Col xs={12}>
              <a
                href={() => false}
                onClick={() => setRediretToManageAccount(true)}
              >
                {Translation.t("manage_your_account")}
              </a>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <>
          {isLoading && <Loading />}
          <div className={props.showLoginPageForManageTrip?"mt-3 float-right mt-5 mt-md-0 w-100":"mt-3 float-right mt-5 mt-md-0 w-100 login-page"}>
            <div className="login-header">
              <h5>{Translation.t("sign_in_to_view_account")}</h5>
              {!props.showLoginPageForManageTrip &&
                Translation.t("all_fields_required")}
              <p></p>
            </div>
            <div className="login-body">
              <input
                className={
                  props.isUserNameValid
                    ? "form-control widget-input"
                    : "form-control widget-input invalid_input"
                }
                defaultValue={props.userName}
                type="text"
                id="login-user-name"
                name="userName"
                placeholder={
                  props.isUserNameValid
                    ? Translation.t("membership_number")
                    : Translation.t("membership_number_required")
                }
                onFocus={() => {
                  if (!props.isUserNameValid) {
                    props.focusUserName();
                  }
                }}
                onBlur={() => {
                  var userName =
                    document.getElementById("login-user-name").value;
                  props.blurUserName(userName);
                }}
              />
              <input
                className={
                  props.isPasswordValid
                    ? "form-control widget-input my-3"
                    : "form-control widget-input invalid_input my-3"
                }
                type="password"
                id="login-password"
                name="password"
                placeholder={
                  props.isPasswordValid
                    ? Translation.t("password")
                    : Translation.t("password_required")
                }
                defaultValue={props.password}
                onFocus={() => {
                  if (!props.isPasswordValid) {
                    props.focusPassword();
                  }
                }}
                onBlur={() => {
                  var password =
                    document.getElementById("login-password").value;
                  props.blurPassword(password);
                }}
              />
              <u>
                <a
                  href={() => false}
                  className="login-links"
                  onClick={() => props.modalHandler()}
                >
                  {Translation.t("forgot_password")}
                </a>
              </u>
              <p className="text-danger">{errorMessage}</p>
              <div className="d-flex justify-content-center">
                <Button
                  variant="danger"
                  className="bk-submit-btn btn-ethiopian m-2"
                  onClick={signIn}
                >
                  {Translation.t("sign_in")}
                </Button>
              </div>
              <div className="et-checkbox">
                <input
                  type="checkbox"
                  id="signed-in"
                  value={props.keepMeSignedIn}
                  onChange={() => {
                    props.keepMeSignedIn();
                  }}
                />
                <label htmlFor="signed-in" className="d-inline">
                  {Translation.t("keep_me_sign_in")}
                </label>
              </div>
              <hr style={{ backgroundColor: "#1c7430" }} />
            </div>
            {!props.showLoginPageForManageTrip && (
              <div className="login-footer">
                <h5>{Translation.t("sign_up_web_profile")}</h5>
                <ul className="login-list">
                  <li>
                    <a
                      href={() => false}
                      className={
                        signUpOption === 1
                          ? "login-links login-links-active"
                          : "login-links"
                      }
                      onClick={() => updateSignUpOption(1)}
                    >
                      <FontAwesomeIcon icon={faStar} className="mr-2" />
                      <span>{Translation.t("save_your_datail")}</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={() => false}
                      className={
                        signUpOption === 2
                          ? "login-links login-links-active"
                          : "login-links"
                      }
                      onClick={() => updateSignUpOption(2)}
                    >
                      <FontAwesomeIcon icon={faUserFriends} className="mr-2" />
                      <span>
                        {Translation.t("manage_your_profile_and_preferences")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={() => false}
                      className={
                        signUpOption === 3
                          ? "login-links login-links-active"
                          : "login-links"
                      }
                      onClick={() => updateSignUpOption(3)}
                    >
                      <FontAwesomeIcon
                        icon={faTag}
                        className="mr-2  promo-code-icon"
                        color="#2E7D32"
                      />
                      <span>
                        {Translation.t("personalize_offers_just_for_you")}
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={() => false}
                      className={
                        signUpOption === 4
                          ? "login-links login-links-active"
                          : "login-links"
                      }
                      onClick={() => updateSignUpOption(4)}
                    >
                      <FontAwesomeIcon icon={faTrophy} className="mr-2" />
                      <span>
                        {Translation.t(
                          "receive_our_latest_news_and_specila_offers"
                        )}
                      </span>
                    </a>
                  </li>
                </ul>
                <div className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    className="bk-submit-btn btn-ethiopian m-2"
                    onClick={() => setRediretToCreateAccount(true)}
                  >
                    {Translation.t("create_profile")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
  } else {
    return <></>;
  }
};

const mapStateToProps = (state) => {
  return {
    userName: state.account.loginModel.userName,
    isUserNameValid: state.account.loginModel.isUserNameValid,
    password: state.account.loginModel.password,
    isPasswordValid: state.account.loginModel.isPasswordValid,
    isUserSignedIn: state.global.isUserSignedIn,
    firstName: state.account.createAccountModle.firstName,
    lastName: state.account.createAccountModle.lastName,
    isLoginFormValid: state.account.isLoginFormValid,
    token: state.searchFlight.token,
    profileCookie: state.account.profileCookie,
    currentAwardPoints: state.account.currentAwardPoints,
    keepMeSignedIn: state.global.keepMeSignedIn,
    isMobile: state.global.isMobile,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    validate: () =>
      dispatch({
        type: accountAction.VALIDATE,
      }),
    focusUserName: () =>
      dispatch({
        type: accountAction.FOCUSUSERNAME,
      }),
    focusPassword: () =>
      dispatch({
        type: accountAction.FOCUSPASSWORD,
      }),
    blurUserName: (userName) =>
      dispatch({
        type: accountAction.BLURUSERNAME,
        userName: userName,
      }),
    blurPassword: (password) =>
      dispatch({
        type: accountAction.BLURPASSWORD,
        password: password,
      }),
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
    signIn: (
      firstName,
      lastName,
      profileCookie,
      currentAwardPoints,
      userName,
      password
    ) =>
      dispatch({
        type: accountAction.SIGNEDIN,
        firstName: firstName,
        lastName: lastName,
        profileCookie: profileCookie,
        currentAwardPoints: currentAwardPoints,
        userName: userName,
        password: password,
      }),
    signOut: () =>
      dispatch({
        type: accountAction.SIGNOUT,
      }),
    keepMeSignedIn: () => {
      dispatch({
        type: globalActionType.KEEPMESIGNEDIN,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
