import { globalActionType } from "../../actions/shared/globalAction";

const initialState = {
  locale: "en",
  isLoading: false,
  isMobile: false,
  isSessionExpired: false,
  currentLocation: "home",
  isMedium: false,
  keepMeSignedIn: false,
  isUserSignedIn: false,
  userName: null,
  password: null,
  isGlobalSessionExpired: false,
  isTeleBirrPayment: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case globalActionType.UPDATELOCALE:
      return {
        ...state,
        locale: action.locale,
      };
    case globalActionType.UPDATEISMOBILE:
      return {
        ...state,
        isMobile: action.value,
      };
    case globalActionType.UPDATEISMEDIUM:
      return {
        ...state,
        isMedium: action.value,
      };
    case globalActionType.TOGGLEISLOADING:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case globalActionType.UPDATEISLOADING:
      return {
        ...state,
        isLoading: action.value,
      };
    case globalActionType.KEEPMESIGNEDIN:
      var temKeepMeSignedIn = !state.keepMeSignedIn;
      return {
        ...state,
        keepMeSignedIn: temKeepMeSignedIn,
      };
    case globalActionType.SIGNEDIN:
      if (state.keepMeSignedIn) {
        return {
          ...state,
          isUserSignedIn: true,
          userName: action.userName,
          password: action.password,
        };
      } else {
        return {
          ...state,
          isUserSignedIn: true,
          userName: null,
          password: null,
        };
      }
    case globalActionType.SIGNOUT:
      return {
        ...state,
        isUserSignedIn: false,
        keepMeSignedIn: false,
      };

    case globalActionType.UPDATEISGLOBALSESSIONEXPIRED:
      return {
        ...state,
        isGlobalSessionExpired: action.value,
      };
    case globalActionType.TELEBIRR:
      return {
        ...state,
        isTeleBirrPayment: action.isTeleBirrPayment,
      };

    default:
      return state;
  }
};

export default globalReducer;
