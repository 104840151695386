import React, { Component } from "react";
import HttpService from "../../../service/shared/HttpService";
import { connect } from "react-redux";
import CheckInCreditCard from "./credit-card/CheckInCreditCard";
import Loading from "../../booking/seat-selection/Loading";
class CheckInPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      creditCardOptions: [],
    };
  }
  componentDidMount() {
    let token = this.props.searchFlight.token;
    this.setState({ isLoading: true });
    HttpService.getCheckInService(
      "/etibe-checkinapi/V1.0/Payment/Option",
      "",
      token
    ).then(
      (response) => {
        this.setState({
          isLoading: false,
          creditCardOptions: response.data.payment.paymentProducts,
        });
        
      },
      (error) => {
        this.setState({ isLoading: false });

        
      }
    );
  }
  render() {
    return (
      <section>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className="mt-4">
            {this.state.creditCardOptions.length > 0 && (
              <CheckInCreditCard
                creditCardOptions={this.state.creditCardOptions}
              />
            )}
          </div>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInPaymentOption);
