import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretDown,
  faCheckCircle,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import { Redirect } from "react-router";

import FlightSelectionSummaryDetail from "./FlightSelectionSummaryDetail";
import { FlightSelectionService } from "../../../service/booking/flight-selection/FlightSelectionService";
import { flightSelectionAction } from "../../../redux/actions/booking/flightSelectionAction";
import HttpService from "../../../service/shared/HttpService";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";
import ShoppingCart from "../../shared/shoppingCart/ShoppingCart";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import Loading from "../seat-selection/Loading";

import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";

class FlightSelectionSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false,
      isLoading: false,
    };
  }
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let time = moment(dateString)
      .locale(localStorage.getItem("i18nextLng"))
      .format("LT");

    let newDate = moment(dateString)
      .locale(localStorage.getItem("i18nextLng"))
      .format("DD MMMM YYYY");
    return (
      <span>
        {newDate} {time}{" "}
      </span>
    );
  };
  renderStops(segments, stops, key) {
    if (stops > 0) {
      if (segments.length > 1) {
        return (
          <span>
            <span>{stops > 1 ? stops + " Stops " : stops + " Stop "}</span>
            {/* {segments.map(
              (segment, index) =>
                index != 0 && <span key={index}>{segment.origin}</span>
            )} */}
          </span>
        );
      } else {
        return (
          <span>
            <span>{stops > 1 ? stops + " Stops " : stops + " Stop "}</span>
            {/* {segments.map((segment, index) => (
              <span key={index}>
                {segment.flight.stopAirports.map((airport, index2) => (
                  <span key={index2}>{airport.airport} </span>
                ))}
              </span>
            ))} */}
          </span>
        );
      }
    } else {
      return <span>Non-stop</span>;
    }
  }
  renderAirportName(airportCode) {
    let airportName = AirportFullName.getFullName(airportCode);
    return <span>{airportName}</span>;
  }
  redirectToPassenger() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.props.history.push("/passenger");
    this.props.removeSeatData();
    this.setState({ redirectToPassenger: true, redirectToHome: false });
  }
  redirectToHome() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ redirectToHome: true });
  }
  changeFlight = (index) => {
    let searchRequest = this.props.searchFlight.searchRequest;

    if (index === 0) {
      this.props.updateFlightIndex(index);
      searchRequest.itineraryParts.forEach((itineraryPart) => {
        itineraryPart.selectedOfferRef = null;
      });

      this.setState({ isLoading: true });
      HttpService.postService(
        searchRequest,
        "/Availability/Search",
        this.props.searchFlight.token
      )
        .then((response) => {
          this.props.saveSearchResult(response);
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.props.history.push("/flightSelect");
        })
        .catch((error) => {
          this.setState({ isLoading: false });

          console.log(error);
        });
    } else {
      if (index + 1 === this.props.flightSearchResult.length) {
        this.props.updateFlightIndex(index);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.props.history.push("/flightSelect");
      } else {
        this.props.updateFlightIndex(index);
        this.setState({ isLoading: true });
        for (let i = index; i < this.props.flightSearchResult.length; i++) {
          searchRequest.itineraryParts[i].selectedOfferRef = null;
        }
        HttpService.postService(
          searchRequest,
          "/Availability/SearchContext",
          this.props.searchFlight.token
        )
          .then((response) => {
            this.props.saveSearchResult(response);
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.props.history.push("/flightSelect");
          })
          .catch((error) => {
            this.setState({ isLoading: false });

            console.log(error);
          });
      }
    }
  };
  render() {
    if (this.state.redirectToHome) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    return (
      <>
        <BreadCrumb history={this.props.history} />
        <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
          <div className="col-12 col-xl-7 mx-auto mx-xl-0">
            <div className="mt-4 mx-auto mr-xl-0">
              {this.state.isLoading && <Loading />}
              <div>
                <h4 className="py-3 selected-flight-review-txt">
                  Review Your Flight Selections
                </h4>
              </div>
              {this.props.flightSearchResult.map((itineraryPart, key) => (
                <div
                  className="flight-selection-summary selected-itinerary-part-offer"
                  key={key}
                >
                  <div className="bg-white  mb-3">
                    <Row className="selected-itinerary-part-offer-head-info">
                      <Col sm={12} md={7} lg={6}>
                        <div className="selected-itinerary-part-offer-date">
                          <span className="pr-2 selected-itinerary-part-mark-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          <span>
                            {moment(itineraryPart.segments[0].departure)
                              .locale(localStorage.getItem("i18nextLng"))
                              .format("dddd, MMMM Do")}
                          </span>
                        </div>
                      </Col>
                      <Col sm={12} md={5} lg={6}>
                        <div className="float-right">
                          <span>
                            <Button
                              className="bk-submit-btn btn-ethiopian ml-2"
                              onClick={() => this.changeFlight(key)}
                            >
                              Change Flight
                            </Button>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="m-0 selected-itinerary-part-offer-info">
                      <Col>
                        <Row>
                          <Col xs={6} sm={6} md={4}>
                            <div>
                              <span>
                                {this.parseDate(
                                  itineraryPart.segments[0].departure
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col xs={6} sm={6} md={4}>
                            <div>
                              <span>
                                {this.parseDate(
                                  itineraryPart.segments[
                                    itineraryPart.segments.length - 1
                                  ].arrival
                                )}
                              </span>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} md={4}>
                            <div>
                              <span>
                                {this.parseTime(itineraryPart.totalDuration)}
                              </span>
                            </div>
                          </Col>
                        </Row>
                        <Row className="py-2">
                          <Col xs={3} sm={3}>
                            <div>
                              <span className="segment-info-airportcode">
                                {itineraryPart.segments[0].origin}
                              </span>
                            </div>
                          </Col>
                          <Col xs={2} sm={2}>
                            <div>
                              <span>
                                <FontAwesomeIcon icon={faPlaneDeparture} />
                              </span>
                            </div>
                          </Col>
                          <Col xs={3} sm={3}>
                            <div>
                              <span className="segment-info-airportcode">
                                {
                                  itineraryPart.segments[
                                    itineraryPart.segments.length - 1
                                  ].destination
                                }
                              </span>
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <span>
                                {this.renderStops(
                                  itineraryPart.segments,
                                  itineraryPart.stops,
                                  key
                                )}
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="float-right">
                          {/* <span>Economy Semi Flex</span> */}
                        </div>
                      </Col>
                      <Col xs={12} className="py-2">
                        {itineraryPart.segments.map((segment, index) => (
                          <div className="py-1" key={index}>
                            <span className="px-1">
                              {segment.flight.airlineCode}
                            </span>
                            <span className="pr-2">
                              {segment.flight.flightNumber}
                            </span>
                            <span className="pr-2">
                              {segment.manufacturerName}
                            </span>
                            <span>{segment.equipmentDescription}</span>
                          </div>
                        ))}
                      </Col>
                      <Col xs={12}>
                        <FlightSelectionSummaryDetail
                          itineraryPart={itineraryPart}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
              <Row>
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <div className="float-right">
                    <Button
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => this.redirectToHome()}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Button
                      className="bk-submit-btn btn-ethiopian ml-2"
                      onClick={() => this.redirectToPassenger()}
                    >
                      CONTINUE
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="col-12 col-xl-3">
            {!this.props.isMedium && <ShoppingCart />}
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    flightSearchResult:
      state.searchFlight.selectionResult.productBreakdowns[0]
        .breakdownElements[0].breakdownElementAssignment.travelPart
        .itineraryParts,
    journeyType: state.bookingWiget.journyType,
    searchFlight: state.searchFlight,
    isMedium: state.global.isMedium,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFlightIndex: (index) => {
      dispatch({
        type: flightSelectionAction.UPDATEFLIGHTINDEX,
        flightIndex: index,
      });
    },
    removeSeatData: () => {
      dispatch({
        type: seatSelectionAction.CLEARSEATSELECTIONENTRY,
      });
    },
    saveSearchResult: (result) =>
      dispatch({
        type: searchFlightAction.SAVESEARCHRESULT,
        searchResult: result,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightSelectionSummary);
