import {
  Amount,
  AncillaryOperation,
  BaggageModel,
  BaggageRequest,
  Bags,
} from "../../../model/API/AddBaggage";

export default class ManageBaggageService {
  addBag = (state, ancillaryCode, passengerIdItinerary, price) => {
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal + price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerIdItinerary === passengerIdItinerary
    )[0];
    var bag = currentPassenger.bags.filter((b) => b.code === ancillaryCode)[0];
    bag.quantity++;

    currentPassenger.amount[0].totalQuantity++;
    currentPassenger.amount[0].totalPrice += price;
    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };
  minusBag = (state, ancillaryCode, passengerIdItinerary, price) => {
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal - price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerIdItinerary === passengerIdItinerary
    )[0];
    var bag = currentPassenger.bags.filter((b) => b.code === ancillaryCode)[0];
    bag.quantity--;
    currentPassenger.amount[0].totalQuantity--;
    currentPassenger.amount[0].totalPrice -= price;
    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };
  intializeState = (data) => {
    var ancillaries = data.ancillaryGroups.filter((g) => g.code === "BG")[0]
      .ancillaries;
    var currency = null;
    var tempPassengerList = [];
    var passengerIdItineraryList = [];
    var overAllTotal = 0;
    ancillaries.forEach((ancillary) => {
      ancillary.travelPartOffers.forEach((travelPartOffer) => {
        travelPartOffer.passengerOffers.forEach((passengerOffer) => {
          var tempPassenger = new BaggageModel();
          var tempBag = new Bags();
          tempPassenger.passengerId = passengerOffer.ancillaryPassenger["@ref"];
          tempPassenger.passengerIdItinerary =
            "id_" +
            passengerOffer.ancillaryPassenger["@ref"] +
            "_itinerary_" +
            travelPartOffer.travelPart["@ref"];
          passengerIdItineraryList.push(tempPassenger.passengerIdItinerary);
          tempBag.code = ancillary.code;
          tempBag.name = ancillary.name;
          tempBag.maxQuantityPerPax =
            ancillary.ancillaryConfiguration.maxQuantityPerPax;
          tempBag.itineryId = travelPartOffer.travelPart["@ref"];
          tempBag.assignedQuantity = passengerOffer.assignedQuantity;
          tempBag.quantity = passengerOffer.assignedQuantity;
          tempBag.price =
            passengerOffer.ancillaryPrice.totalPrice.alternatives[0][0].amount;
          if (currency === null) {
            currency =
              passengerOffer.ancillaryPrice.totalPrice.alternatives[0][0]
                .currency;
          }
          overAllTotal += tempBag.assignedQuantity * tempBag.price;
          tempPassenger.bags.push(tempBag);
          tempPassengerList.push(tempPassenger);
        });
      });
    });
    passengerIdItineraryList = [...new Set(passengerIdItineraryList)];
    var passengerList = [];
    passengerIdItineraryList.forEach((id) => {
      var testPassengers = tempPassengerList.filter(
        (p) => p.passengerIdItinerary === id
      );
      var finaltempPassenger = new BaggageModel();
      var finaltempAmount = new Amount();

      testPassengers.forEach((testPassenger) => {
        finaltempPassenger.passengerId = testPassenger.passengerId;
        finaltempPassenger.passengerIdItinerary = id;
        testPassenger.bags.forEach((bag) => {
          finaltempPassenger.bags.push(bag);
          finaltempAmount.totalQuantity += bag.assignedQuantity;
          finaltempAmount.totalPrice += bag.assignedQuantity * bag.price;
        });
      });
      finaltempPassenger.amount.push(finaltempAmount);
      passengerList.push(finaltempPassenger);
    });
    var isBaggageAddedBefore = false;
    if (overAllTotal > 0) {
      isBaggageAddedBefore = true;
    }
    return {
      passengerList: passengerList,
      overAllTotal: overAllTotal,
      isBaggageAddedBefore: isBaggageAddedBefore,
      currency: currency,
    };
  };
  buildBaggageRequest = (
    itineraryList,
    baggaModel,
    cookieSabreDataRequest,
    flow = 1
  ) => {
    var newBaggageRequest = new BaggageRequest();
    newBaggageRequest.Flow = flow;
    newBaggageRequest.cookieSabreDataRequest = cookieSabreDataRequest;
    baggaModel.forEach((passenger) => {
      passenger.bags.forEach((bag) => {
        if (bag.quantity > bag.assignedQuantity) {
          var tempItineraryList = itineraryList.filter(
            (i) => i["@id"] === bag.itineryId
          )[0];
          var tempAncillaryOperations = new AncillaryOperation();
          tempAncillaryOperations.ancillaryCode = bag.code;
          tempAncillaryOperations.selectedTravelPart.origin =
            tempItineraryList.segments[0].origin;
          tempAncillaryOperations.selectedTravelPart.destination =
            tempItineraryList.segments[
              tempItineraryList.segments.length - 1
            ].destination;
          tempAncillaryOperations.selectedTravelPart.departure =
            tempItineraryList.segments[0].departure;
          tempAncillaryOperations.selectedTravelPart.id =
            tempItineraryList["@id"];
          tempAncillaryOperations.selectedPassengers.push({
            index: parseInt(passenger.passengerId),
            quantity: bag.quantity,
          });
          tempAncillaryOperations.id =
            tempAncillaryOperations.selectedTravelPart.origin +
            "-" +
            tempAncillaryOperations.selectedTravelPart.destination +
            "-" +
            bag.code +
            "-" +
            passenger.passengerId;
          newBaggageRequest.ancillaryOperations.push(tempAncillaryOperations);
        }
      });
    });
    return newBaggageRequest;
  };
  removeBaggage = (id, baggaModel, price, assignedQuantity) => {
    baggaModel.forEach((passenger) => {
      passenger.bags.forEach((bags) => {
        bags.forEach((bag) => {
          if (bag.id === id) {
            bag.quantity = 0;
            var idForAmountTemp = id.split("-");
            var idForAmount =
              idForAmountTemp[0] +
              "-" +
              idForAmountTemp[1] +
              "-" +
              idForAmountTemp[3];
            var passengerAmount = passenger.amount.filter(
              (a) => a.id === idForAmount
            )[0];
            passengerAmount.totalQuantity -= assignedQuantity;
            passengerAmount.totalPrice -= price;
          }
        });
      });
    });
    return baggaModel;
  };
  groupArrayOfObjects = (list, key) => {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  addToCart = (baggageRequests, ancillaryData, baggaModels) => {
    var ancillaryForShoppingCart = [];
    var ancillaryList = ancillaryData.ancillaryGroups.filter(
      (a) => a.code === "BG"
    )[0];

    baggageRequests.ancillaryOperations.forEach((baggageRequest) => {
      if (baggageRequest.assignedQuantity !== 0) {
        var tempPassenger = ancillaryData.ancillaryPassengers.filter(
          (p) => p.index === baggageRequest.selectedPassengers[0].index
        )[0];

        var tempAncillary = ancillaryList.ancillaries.filter(
          (x) => x.code === baggageRequest.ancillaryCode
        )[0];
        var passengerIdItinerary =
          "id_" +
          baggageRequest.selectedPassengers[0].index +
          "_itinerary_" +
          baggageRequest.selectedTravelPart["id"];
        var baggaModel = baggaModels.filter(
          (x) => x.passengerIdItinerary === passengerIdItinerary
        )[0];
        var bag = baggaModel.bags.filter(
          (b) => b.code === baggageRequest.ancillaryCode
        )[0];
        var assignedQuantity = baggageRequest.selectedPassengers[0].quantity;
        if (bag.assignedQuantity !== 0) {
          assignedQuantity -= bag.assignedQuantity;
        }
        var tempAncillary = {
          origin: baggageRequest.selectedTravelPart.origin,
          destination: baggageRequest.selectedTravelPart.destination,
          firstName: tempPassenger.firstName,
          lastName: tempPassenger.lastName,
          type: tempPassenger.type,
          ancillaryName: tempAncillary.name,
          price: tempAncillary.price * assignedQuantity,
          passengerIndex: baggageRequest.selectedPassengers[0].index,
          key:
            baggageRequest.selectedTravelPart.origin +
            "-" +
            baggageRequest.selectedTravelPart.destination,
          departure: baggageRequest.selectedTravelPart.departure,
          ancillaryCode: baggageRequest.ancillaryCode,
          selectedTravelPartID: baggageRequest.selectedTravelPart["id"],
          id:
            baggageRequest.selectedTravelPart.origin +
            "-" +
            baggageRequest.selectedTravelPart.destination +
            "-" +
            baggageRequest.ancillaryCode +
            "-" +
            baggageRequest.selectedPassengers[0].index,
          assignedQuantity: assignedQuantity,
        };
        ancillaryForShoppingCart.push(tempAncillary);
      }
    });

    var groupedResult = this.groupArrayOfObjects(
      ancillaryForShoppingCart,
      "key"
    );
    return groupedResult;
  };

}
