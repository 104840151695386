import React, { Component } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { connect } from 'react-redux';
import CabinClassService from "../../../service/booking/cabin-class/CabinClassService";

class CabinClass extends Component {
  constructor(props){
    super(props);
    this.customStylesSelect = AirportSelectStyle.desktopStyle;
    this.classList = new CabinClassService();
  }
  render() {
    return (
          <Col xs={12} sm={12} md={6} className="my-2">
                    <h5>{this.props.labelMessage}</h5>
          <Select
           value={this.props.cabinClass}
          styles={this.customStylesSelect}
          onChange={(selectedClass) => {
            this.props.cabinClassSelection(selectedClass)
          }}
          isSearchable={false}
          options={this.classList.getCabinClassList()}
          placeholder={<Translation Key="select_class"/>}
        />
          </Col>
       
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cabinClass: state.bookingWiget.cabinClass,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    cabinClassSelection:(cabinClass)=>dispatch({type:BookingWidgetAction.UPDATECABINCLASS,cabinClass:cabinClass})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CabinClass);
