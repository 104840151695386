export const flightStatusAction={
    STATUSUPADTESEARCHCRITRION:"STATUSUPADTESEARCHCRITRION",
    STATUSFILTERUPDATE : "STATUSFILTERUPDATE",
    STATUSUPDATESORTOPTIONS : "STATUSUPDATESORTOPTIONS",
    STATUSUPDATECOUNTSORTUPDATE : "STATUSUPDATECOUNTSORTUPDATE",
    INITFLIGHTSTATUS :"INITFLIGHTSTATUS",
    UPDATEFLIGHTSTATUS :"UPDATEFLIGHTSTATUS",
    UPDATESTATUSSERACHTYPE  :"UPDATESTATUSSERACHTYPE",
    UPDATEFLIGHTNUMBER :"UPDATEFLIGHTNUMBER",
    ISFLIGHTSTATUSPRESENT :"isFlightStatusPresent",
}