import React from "react";
import Translation from "i18next";


const FlightStatusHeader = (props) => {
  return (
    <>
      <div className="title-wrapper">
        <h2 className="flight-schedule-view-title">
          <span>
            {Translation.t("flight_status")}
          </span>
        </h2>
      </div>
      <h3 className="flight-schedule-title-info">
        <span>
        {Translation.t("review_the_status_of_the_flights_that_match_your_search")}
        </span>
      </h3>
    </>
  );
};

export default FlightStatusHeader;
