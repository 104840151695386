import { React, Component } from "react";
import { Col, Modal } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { seatSelectionAction } from "../../../../redux/actions/booking/seatSelectionAction";
import { SeatSelectionService } from "../../../../service/booking/seat-selection/SeatSelectionService";
import Translation from "i18next";
import { connect } from "react-redux";
import Notification from "../../../booking/booking-widget/Notification";
import { Redirect } from "react-router";
import ExchangeSeatSelectionService from "../../../../service/exchange/ExchangeSeatSelectionService";

class Seat extends Component {
  state = {
    focus: false,
    clicked: false,
    seatName: "",
    width: "0",
    left: 0,
    isSeatPopUpModalOpen: false,
    isExceptionModalOpened: false,
    redirect: false,
  };
  seatName = "";
  width = "0";
  left = "";
  markerLeft = "";
  seatMapIdentifier = "";

  seatMapKeys = {
    9: "Center Seat",
    W: "Window Seat",
    CH: "",
    A: "Aisle Seat",
  };
  classListForTopWrapper = "";
  classListForInsider = "";

  errorHeader = "";
  errorBody = "";

  constructor(props) {
    super(props);
    this.state.seatName = this.props.row + this.props.column;

    this.seatMapIdentifier =
      this.props.seatMapIdentifier.split("-")[0] +
      this.props.seatMapIdentifier.split("-")[1];

    this.classListForTopWrapper =
      this.props.seat.slotCharacteristics !== null &&
      this.props.seat.slotCharacteristics.includes("EXIT")
        ? "seat-top-wrapper mb-3 extra-leg-seat-insider-wrapper"
        : "seat-top-wrapper mb-3";

    this.classListForInsider =
      this.props.seat.designations !== null &&
      this.props.seat.designations.includes("FRONT_OF_CABIN")
        ? "seat-insider-wrapper justify-content-center d-flex premium-seat "
        : "seat-insider-wrapper justify-content-center d-flex ";
  }
  componentDidMount() {
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    this.width = ClientRect.width - 25;
    this.left = ClientRect.left - ClientRect.left;
    this.seatName = this.props.row + this.props.column;
    this.seatMapIdentifier =
      this.props.seatMapIdentifier.split("-")[0] +
      this.props.seatMapIdentifier.split("-")[1];
    // console.log(this.props.seat)
    // console.log("mounted")
  }

  // componentDidUpdate(){
  //   var ClientRect = document
  //   .getElementById("seats-container-"+this.props.SeatMapId)
  //   .getBoundingClientRect();
  // this.width = ClientRect.width - 15;
  // this.left = ClientRect.left - ClientRect.left;
  // this.seatName = this.props.row + this.props.column;
  // console.log( document
  //   .getElementById("seats-container-"+this.props.SeatMapId));
  // }

  handleSeatClick = (event) => {
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    var width = ClientRect.width - 15;
    this.setState({ width: width, left: this.left });
    if (window.innerWidth <= 767) {
      try {
        const element = event.target;
        const elementRect = element.getBoundingClientRect();
        var customClientRect = document.getElementById("custom-seat-modal-id");
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle =
          absoluteElementTop -
          (window.innerHeight - customClientRect.offsetHeight - 60); //(window.innerHeight / 2);
        window.scrollTo({ top: middle, behavior: "smooth" });
      } catch {}
    }
  };

  handleFocus = (event) => {
    var ClientRectevent =
      event.target.parentNode.parentNode.parentNode.getBoundingClientRect(); // event.target.getBoundingClientRect();
    var ClientRect = document
      .getElementById("seats-container-" + this.props.SeatMapId)
      .getBoundingClientRect();
    this.width = ClientRect.width - 15;
    this.markerLeft = "42%";
    this.left = ClientRect.left - ClientRectevent.left + 8;
    this.setState({ focus: true, isSeatPopUpModalOpen: true });
  };
  handleBlur = () => {
    this.setState({ focus: false });
  };
  modal = () => {
    return (
      <OutsideClickHandler
        onOutsideClick={() => this.setState({ isSeatPopUpModalOpen: false })}
      >
        <div style={{ left: this.markerLeft }} className="seat-pointer"></div>
        <div
          style={{ width: this.width, left: this.left }}
          id="custom-seat-modal-id"
          className={
            this.state.isSeatPopUpModalOpen
              ? "custom-modal show row"
              : "custom-modal"
          }
        >
          <div className="col-12 custom-modal-header">
            <div className="d-flex modal-title h4 d-inline-flex"></div>
            {/* <span role="button" className="close-custom-modal float-right d-inline-flex" onClick={() => this.setState({isSeatPopUpModalOpen : false})}>
            <FontAwesomeIcon icon={faTimes} />
            </span> */}
            <button
              onClick={() => this.setState({ isSeatPopUpModalOpen: false })}
              type="button"
              className="close-custom-modal d-inline-flex close"
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">{Translation.t("close")}</span>
            </button>
          </div>
          <div className="col-12 custom-modal-body d-flex">
            {this.props.seat.code +
              " - " +
              this.props.seat.seatCharacteristics.map((character) => {
                return this.seatMapKeys[character];
              })}
          </div>
          <div className="col-12">
            <div className="col-12 seat-selection-popup-modal-body pl-0">
              {this.props.seat.freeOfCharge
                ? "Cost : This is a free Seat"
                : !this.props.seat.freeOfCharge &&
                  this.props.seat.total.alternatives.length !== 0
                ? "Cost : " +
                  this.props.seat.total.alternatives[0][0].amount +
                  " " +
                  this.props.seat.total.alternatives[0][0].currency
                : null}

              {!this.props.seat.freeOfCharge ? <div>Premium Seats</div> : null}

              {this.props.seat.slotCharacteristics !== undefined ? (
                <>
                  {" "}
                  <div>
                    {this.props.seat.slotCharacteristics}
                    {this.props.seat.slotCharacteristics !== undefined &&
                    this.props.seat.slotCharacteristics !== null &&
                    this.props.seat.slotCharacteristics.includes("EXIT") &&
                    !this.props.seat.freeOfCharge ? (
                      <span className="pl-2">(Extra Leg Room)</span>
                    ) : null}
                  </div>
                </>
              ) : null}
              <div>
                {this.props.seat.limitations !== null &&
                this.props.seat.limitations !== undefined &&
                this.props.seat.limitations.length !== 0 ? (
                  <div className="mt-2">
                    <h4>Terms and Conditions</h4>
                    <p>
                      By selecting this seat, you confirm that you agree with
                      the{" "}
                      <a
                        href="https://www.ethiopianairlines.com/et/information/special-needs/exit-row-disclaimer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        emergency exit row seating conditions
                      </a>
                      <br /> Ethiopian may reassign your seat before or during
                      the journey without any refund if you don't meet any of
                      the listed safety criteria’s mentioned in the link.
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-12 mb-3 py-2">
            <button
              onClick={() => this.handleSelectSeat()}
              className="select-seat-btn btn"
            >
              {Translation.t("select")}
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    );
  };
  handleSelectSeat = () => {
    this.setState({ isSeatPopUpModalOpen: false });

    this.props.seatSelectingUpdate(true);

    var seatSelectionService = new SeatSelectionService();
    var payload = {
      departure: this.props.DepartureDate,
      seatMapIdentifier: this.props.seatMapIdentifier,
      seatCode: this.props.seat.code,
      origin: this.props.seatMapIdentifier.split("-")[0],
      destination: this.props.seatMapIdentifier.split("-")[1],
      passengerIndex: this.props.passengerIndex,
    };

    let exchangeSeatSelectionService = new ExchangeSeatSelectionService();

    exchangeSeatSelectionService
      .selectSeat(payload, this.props.token, this.props.cookieSabreDataRequest)
      .then((response) => {
        this.props.seatSelectingUpdate(false);
        this.props.selectSeat({
          seatMapIdentifier: this.props.seatMapIdentifier,
          passengerIndex: this.props.passengerIndex,
          seatCode: this.props.seat.code,
          seat: this.props.seat,
        });

        this.props.checkAllPassengersSelectSeat(
          this.props.selectedSeats,
          this.props.passangers
        );
        if (this.props.passangers.length !== this.props.passengerIndex) {
          var nextPassenger = this.props.passangers[this.props.passengerIndex];
          if("INF" !== nextPassenger.passenger.passengerInfo.type){
            this.props.changeActivePassangerCode(
              
            nextPassenger.passenger.passengerInfo.type[0] +
              nextPassenger.passenger.passengerIndex,
            nextPassenger.passenger.passengerIndex
          );
          }
        }
      })
      .catch((error) => {
        seatSelectionService
          .selectSeat(
            this.props.cookieSabreDataRequest,
            this.props.token,
            payload
          )
          .then((response) => {
            this.props.seatSelectingUpdate(false);
            this.props.selectSeat({
              seatMapIdentifier: this.props.seatMapIdentifier,
              passengerIndex: this.props.passengerIndex,
              seatCode: this.props.seat.code,
              seat: this.props.seat,
            });

            this.props.checkAllPassengersSelectSeat(
              this.props.selectedSeats,
              this.props.passangers
            );
            if (this.props.passangers.length !== this.props.passengerIndex) {
              var nextPassenger =
                this.props.passangers[this.props.passengerIndex];
              this.props.changeActivePassangerCode(
                nextPassenger.passenger.passengerInfo.type[0] +
                  nextPassenger.passenger.passengerIndex,
                nextPassenger.passenger.passengerIndex
              );
            }
          })
          .catch((error) => {
            this.props.seatSelectingUpdate(false);
            console.log(error);
            if (error.response !== undefined) {
              this.errorHeader = "Session Expired";
              this.errorBody = "Session is expired. Start booking from scratch";
            } else {
              this.errorHeader = "Offline";
              this.errorBody =
                "can not comunicate to the server. try connecting to the internet and try again";
            }
            this.setState({ isExceptionModalOpened: true });
          });
      });
  };

  getModal() {
    return (
      <Modal
        show={this.state.isExceptionModalOpened}
        onHide={() => {
          this.setState({
            isExceptionModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <Notification header={this.errorHeader} body={this.errorBody} />
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <a
              href="/"
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
            >
              {Translation.t("start_over")}
            </a>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    var disabled =
      this.seatMapIdentifier in this.props.selectedSeats &&
      this.props.seat.code in this.props.selectedSeats[this.seatMapIdentifier];

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    return (
      <>
        {this.getModal()}
        {this.props.column === "LEFT_SIDE" ||
        this.props.column === "RIGHT_SIDE" ? (
          <div className={"right-or-left-side " + this.props.column}></div>
        ) : this.props.column === "AISLE" ? (
          <Col
            key={this.props.keyType + "-insider"}
            className="AISLE text-center mb-3 d-flex align-items-center justify-content-center"
          >
            {this.props.seat.storefrontSlotCode === "BLANK"
              ? null
              : this.props.row}
          </Col>
        ) : (
          <Col
            key={this.props.keyType + "-insider"}
            className={
              this.props.seat.storefrontSlotCode === "BULKHEAD"
                ? (this.classListForTopWrapper = " BULKHEAD")
                : this.classListForTopWrapper + " justify-content-center"
            }
          >
            {this.props.seat.storefrontSlotCode === "BULKHEAD" ||
            this.props.seat.storefrontSlotCode === "BLANK" ? (
              <>{/* {console.log(this.props.seat)} */}</>
            ) : (
              <>
                <div
                  role="button"
                  className={
                    this.state.focus
                      ? this.classListForInsider + " active"
                      : this.classListForInsider
                  }
                  onFocus={
                    this.props.seat.available && !disabled
                      ? (event) => this.handleFocus(event)
                      : null
                  }
                  onBlur={
                    this.props.seat.available && !disabled
                      ? () => this.handleBlur()
                      : null
                  }
                  onClick={
                    this.props.seat.available && !disabled
                      ? (event) => this.handleSeatClick(event)
                      : null
                  }
                >
                  <span role="button" className="seat-frame d-flex"></span>
                  <div
                    role="button"
                    className={
                      this.state.focus
                        ? "seat-container justify-content-center d-flex active"
                        : "seat-container justify-content-center d-flex"
                    }
                  >
                    <span
                      disabled={this.props.seat.available ? "" : "disabled"}
                      tabIndex={
                        this.props.seat.available && !disabled ? "1" : null
                      }
                      role="button"
                      className={
                        this.props.seat.slotCharacteristics !== undefined &&
                        this.props.seat.slotCharacteristics !== null &&
                        this.props.seat.slotCharacteristics.includes("EXIT") &&
                        !this.props.seat.freeOfCharge
                          ? "seat d-flex align-items-center justify-content-center extra-leg-seat"
                          : "seat d-flex align-items-center justify-content-center"
                      }
                    >
                      {/* {console.log(this.props.seat)} */}
                      {this.props.seat.available ? (
                        <>
                          {" "}
                          {this.seatMapIdentifier in
                          this.props.selectedSeats ? (
                            <>
                              {this.props.seat.code in
                              this.props.selectedSeats[
                                this.seatMapIdentifier
                              ] ? (
                                <>
                                  <span className="selected-seat">
                                    {
                                      this.props.selectedSeats[
                                        this.seatMapIdentifier
                                      ][this.props.seat.code]
                                        .activePassangerIdentifierCode
                                    }
                                  </span>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>×</>
                      )}
                    </span>
                  </div>
                </div>
                {this.state.isSeatPopUpModalOpen ? this.modal() : null}
              </>
            )}
          </Col>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    passengerIndex: state.seatSelection.passengerIndex,
    selectedSeats: state.seatSelection.selectedSeats,
    passangers: state.seatSelection.passangers,
    cookieSabreDataRequest:
      state.exchange.flightSearchExtraInfo.cookieSabreDataRequest,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSeat: (result) => {
      dispatch({
        type: seatSelectionAction.CHANGESEAT,
        searchResult: result,
      });
    },
    selectSeat: (payload) =>
      dispatch({
        type: seatSelectionAction.SELECTSEAT,
        payload: payload,
      }),
    seatSelectingUpdate: (value) =>
      dispatch({
        type: seatSelectionAction.SEATSELECTING,
        value: value,
      }),
    changeActivePassangerCode: (value, passengerIndex) => {
      dispatch({
        type: seatSelectionAction.CHANGEACTIVEPASSANGERCODE,
        activePassangerIdentifierCode: value,
        passengerIndex: passengerIndex,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Seat);
