import React from "react";
import { Accordion, Card, Row, Modal, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faPlane,
  faAngleUp,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import "./ShoppingCart.css";
import { connect } from "react-redux";
import Translation from "../../../service/shared/multi-lingual/Translation";
class BaggageInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedIn: 1,
      carryOn: -1,
    };
  }
  carryOnBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCarryOn.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "black",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>
                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              {passengerBaggageDisclosure.baggageRestrictions.map(
                                (baggageRestrictions, key4) => (
                                  <>
                                    <li>
                                      <span key={key4}>
                                        <Translation Key="dimension" /> :{" "}
                                        {
                                          baggageRestrictions.dimensionDescription
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </li>
                                  </>
                                )
                              )}
                            </ul>
                            <Translation Key="additional_info" /> :
                            <h6 className="text-center">
                              {passengerBaggageDisclosure.additionalInfo}
                            </h6>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  checkedInBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCheckeId.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "black",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>

                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              <li>
                                {passengerBaggageDisclosure.baggageRestrictions.map(
                                  (baggageRestrictions, key4) => (
                                    <span key={key4}>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </span>
                                  )
                                )}
                              </li>
                              <li>
                                <Translation Key="total_weight" /> :{" "}
                                {passengerBaggageDisclosure.totalWeight}
                              </li>
                            </ul>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  retunBaggageInfo = () => {
    try {
      return (
        <Accordion defaultActiveKey="Checked-in">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Checked-in"
              className="baggage-info active-shopping-cart-header"
              onClick={() => {
                if (this.state.checkedIn === 1) {
                  this.setState({ checkedIn: -1 });
                } else {
                  this.setState({ checkedIn: 1 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.checkedIn === 1 ? faAngleUp : faAngleDown}
                  size="2x"
                  color="#555"
                  className="float-right"
                />
              </span>

              <h4 className="float-rigth">
                {" "}
                <Translation Key="checked_in_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Checked-in">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="checked_in_baggage_body" />
                </h5>
                {this.checkedInBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className=" ">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Carry-on"
              className="baggage-info"
              onClick={() => {
                if (this.state.carryOn === 2) {
                  this.setState({ carryOn: -1 });
                } else {
                  this.setState({ carryOn: 2 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.carryOn === 2 ? faAngleUp : faAngleDown}
                  size="2x"
                  className="float-right"
                />
              </span>

              <h4>
                {" "}
                <Translation Key="carry_on_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Carry-on">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="Carry_on_baggage_body" />
                </h5>
                {this.carryOnBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };
  render() {
    return (
      <>
        <Modal
          show={this.props.showBaggageInformation}
          onHide={() => this.props.hideBaggageInfo()}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <Translation Key="baggage_info" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Translation Key="baggage_info_body" />
            </p>
            {this.props.baggageInfo !== null ? (
              this.retunBaggageInfo()
            ) : (
              <p className="loading"></p>
            )}{" "}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() => this.props.hideBaggageInfo()}
            >
              <Translation Key="close" />
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    baggageInfo: state.shoppingCart.baggageInfo,
  };
};
export default connect(mapStateTopProps, null)(BaggageInfo);
