import { CreateAccountModle } from "../../../model/account/Account";

export default class AccountService {
  validateLoginModel = (state) => {
    var isLoginFormValid = true;
    var tempLoginModel = state.loginModel;
    if (tempLoginModel.userName === "" || tempLoginModel.userName === null) {
      tempLoginModel.isUserNameValid = false;
      isLoginFormValid = false;
    }
    if (tempLoginModel.password === "" || tempLoginModel.password === null) {
      tempLoginModel.isPasswordValid = false;
      isLoginFormValid = false;
    }

    return { loginModel: tempLoginModel, isLoginFormValid: isLoginFormValid };
  };
  changeValidationCreateAccountModel = (
    state,
    domain,
    valueParameter,
    validationParameter
  ) => {
    var temp = state.createAccountModle;
    if (domain === "onFocus") {
      temp.validation[validationParameter] = true;
    } else {
      if (temp[valueParameter] === "" || temp[valueParameter] === null) {
        temp.validation[validationParameter] = false;
      } else {
        temp.validation[validationParameter] = true;
      }
    }
    return temp;
  };
  changeValidationPasswordUpdate = (
    state,
    domain,
    valueParameter,
    validationParameter
  ) => {
    var temp = state.updatePassword;
    if (domain === "onFocus") {
      temp.validation[validationParameter] = true;
    } else if (domain === "confirmPassword") {
      temp.validation.passwordIsConfirmed = valueParameter;
    } else {
      if (temp[valueParameter] === "" || temp[valueParameter] === null) {
        temp.validation[validationParameter] = false;
      } else {
        temp.validation[validationParameter] = true;
      }
    }
    return temp;
  };
  changeValidationBillingInfo = (
    state,
    domain,
    valueParameter,
    validationParameter
  ) => {
    var temp = state.billingInfo;
    if (domain === "onFocus") {
      temp.validation[validationParameter] = true;
    } else {
      if (temp[valueParameter] === "" || temp[valueParameter] === null) {
        temp.validation[validationParameter] = false;
      } else {
        temp.validation[validationParameter] = true;
      }
    }
    return temp;
  };
  changeValidationTravelDocument = (state, domain, validationParameter) => {
    var temp = state.profile;
    var validationResult = true;
    if (domain === "onFocus") {
      return {
        validationResult: validationResult,
        profile: temp,
      };
    } else {
      if (
        temp.travelDocument[validationParameter] === "" ||
        temp.travelDocument[validationParameter] === null
      ) {
        validationResult = false;
      } else {
        validationResult = true;
      }
    }
    return {
      validationResult: validationResult,
      profile: temp,
    };
  };

  updateFrequentFlyer = (state, value, parameter, index) => {
    var updatedFrequentFlyer = state.profile;
    updatedFrequentFlyer.frequentFlyers[index][parameter] = value;
    return updatedFrequentFlyer;
  };
  updateCreateAccount = (state, domain, value) => {
    var updatedModle = state.createAccountModle;
    updatedModle[domain] = value;
    return updatedModle;
  };
  updatePassword = (state, domain, value) => {
    var updatedModle = state.updatePassword;
    updatedModle[domain] = value;
    return updatedModle;
  };
  updateBillingInfo = (state, domain, value) => {
    var updatedModle = state.billingInfo;
    updatedModle[domain] = value;
    return updatedModle;
  };
  updateTravelDocument = (state, parameter, value) => {
    var updatedTravelDocument = state.profile;
    updatedTravelDocument.travelDocument[parameter] = value;
    return updatedTravelDocument;
  };
  initialzeProfile = (data) => {
    var createAccountModel = new CreateAccountModle();
    if (
      data.userInfo.phones[0].countryCode !== null ||
      data.userInfo.phones[0].countryCode !== ""
    ) {
      createAccountModel.phone1 =
        data.userInfo.phones[0].areaCode +
        "(" +
        data.userInfo.phones[0].countryCode +
        ")" +
        data.userInfo.phones[0].number;
    } else {
      createAccountModel.phone1 =
        data.userInfo.phones[0].areaCode + data.userInfo.phones[0].number;
    }
    createAccountModel.phoneType1 = data.userInfo.phones[0].type;

    if (data.userInfo.phones.length >= 2) {
      if (
        data.userInfo.phones[1].countryCode !== null ||
        data.userInfo.phones[1].countryCode !== ""
      ) {
        createAccountModel.phone2 =
          data.userInfo.phones[1].areaCode +
          "(" +
          data.userInfo.phones[1].countryCode +
          ")" +
          data.userInfo.phones[1].number;
      } else {
        createAccountModel.phone2 =
          data.userInfo.phones[1].areaCode + data.userInfo.phones[0].number;
      }
      createAccountModel.phoneType2 = data.userInfo.phones[1].type;
    }

    if (data.frequentFlyers.length === 0) {
      data.frequentFlyers.push({
        airline: null,
        membershipID: null,
        membershipLevelTypeCode: null,
        membershipLevelValue: "0",
        accountBalance: 0,
        membershipStartDate: null,
      });
    }
    if (data.travelDocument === null) {
      data.travelDocument = {
        issuingCountry: null,
        countryOfBirth: null,
        issueDate: null,
        documentNumber: null,
        documentType: null,
        nationality: null,
        expirationDate: null,
        dateOfBirth: null,
      };
    }
    return { profile: data, createAccountModel: createAccountModel };
  };
  validateCreateAccount = (state) => {
    var validateAccountModel = state.createAccountModle;
    validateAccountModel.isFormValid = true;
    if (
      validateAccountModel.title === "" ||
      validateAccountModel.title === null
    ) {
      validateAccountModel.validation.isTitleValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.firstName === "" ||
      validateAccountModel.firstName === null
    ) {
      validateAccountModel.validation.isFirstNameValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.lastName === "" ||
      validateAccountModel.lastName === null
    ) {
      validateAccountModel.validation.isLastNameValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.year === "" ||
      validateAccountModel.year === null
    ) {
      validateAccountModel.validation.isYearValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.month === "" ||
      validateAccountModel.month === null
    ) {
      validateAccountModel.validation.isMonthValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (validateAccountModel.day === "" || validateAccountModel.day === null) {
      validateAccountModel.validation.isDayValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.gender === "" ||
      validateAccountModel.gender === null
    ) {
      validateAccountModel.validation.isGenderValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.phone1 === "" ||
      validateAccountModel.phone1 === null
    ) {
      validateAccountModel.validation.isPhone1Valid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.phoneType1 === "" ||
      validateAccountModel.phoneType1 === null
    ) {
      validateAccountModel.validation.isPhoneType1Valid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.email === "" ||
      validateAccountModel.email === null
    ) {
      validateAccountModel.validation.isEmailValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.userName === "" ||
      validateAccountModel.userName === null
    ) {
      validateAccountModel.validation.isUserNameValid = false;
      validateAccountModel.isFormValid = false;
    }
    if (
      validateAccountModel.password === "" ||
      validateAccountModel.password === null
    ) {
      validateAccountModel.validation.isPasswordValid = false;
      validateAccountModel.isFormValid = false;
    }
    return validateAccountModel;
  };
  buildRequestObjectForApi = (profile, createAccountModel, profileCookie) => {
    profile.userInfo.phones[0].type = createAccountModel.phoneType1;
    if (createAccountModel.phone1.indexOf(" ") >= 0) {
      let phone1 = createAccountModel.phone1.split(" ");
      var areaCodePhone1 = createAccountModel.phone1.substring(
        createAccountModel.phone1.indexOf("(") + 1,
        createAccountModel.phone1.indexOf(")")
      );
      profile.userInfo.phones[0].countryCode = phone1[0].replace("+", "");
      profile.userInfo.phones[0].areaCode = areaCodePhone1;
      phone1.shift();
      profile.userInfo.phones[0].number = phone1
        .join("")
        .replace("(" + areaCodePhone1 + ")", "")
        .replace("-", "");
    }
    if (
      createAccountModel.phoneType2 !== null &&
      createAccountModel.phone2 !== null &&
      createAccountModel.phoneType2 !== "" &&
      createAccountModel.phone2 !== ""
    ) {
      if (createAccountModel.phone2.indexOf(" ") >= 0) {
        let phone2 = createAccountModel.phone2.split(" ");
        var areaCodePhone2 = createAccountModel.phone2.substring(
          createAccountModel.phone2.indexOf("(") + 1,
          createAccountModel.phone2.indexOf(")")
        );
        var countryCodePhone2 = phone2[0].replace("+", "");
        phone2.shift();
        var phoneNumber2 = phone2
          .join("")
          .replace("(" + areaCodePhone2 + ")", "")
          .replace("-", "");
        if (profile.userInfo.phones.length === 1) {
          profile.userInfo.phones.push({
            type: createAccountModel.phoneType2,
            countryCode: countryCodePhone2,
            areaCode: areaCodePhone2,
            number: phoneNumber2,
            extension: null,
          });
        } else {
          profile.userInfo.phones[1].type = createAccountModel.phoneType2;
          profile.userInfo.phones[1].countryCode = phone2[0].replace("+", "");
          profile.userInfo.phones[1].areaCode = areaCodePhone2;
          phone2.shift();
          profile.userInfo.phones[1].number = phone2
            .join("")
            .replace("(" + areaCodePhone1 + ")", "")
            .replace("-", "");
        }
      } else if (profile.userInfo.phones.length === 2) {
        profile.userInfo.phones[1].type = createAccountModel.phoneType2;
      }
    }
    if (profile.travelDocument.expirationDate === null) {
      profile.travelDocument = {};
    }
    profile.frequentFlyers.forEach((frequentFlyer, index) => {
      if (
        frequentFlyer.airline === null ||
        frequentFlyer.airline === "" ||
        frequentFlyer.membershipID === null ||
        frequentFlyer.membershipID === ""
      )
        profile.frequentFlyers.splice(index, 1);
    });

    var requestObjectForApi = {
      updateWebProfile: {
        profile: profile,
      },
      cookieSabreDataRequest: profileCookie,
    };

    return requestObjectForApi;
  };
  createAccountRequestObJ = (createAccountModel) => {
    let phone1 = createAccountModel.phone1.split(" ");
    var countryCodePhone1 = phone1[0].replace("+", "");

    var areaCodePhone1 = createAccountModel.phone1.substring(
      createAccountModel.phone1.indexOf("(") + 1,
      createAccountModel.phone1.indexOf(")")
    );

    phone1.shift();
    var numberPhone1 = phone1
      .join("")
      .replace("(" + areaCodePhone1 + ")", "")
      .replace("-", "");
    var PhoneList = [
      {
        type: createAccountModel.phoneType1.value,
        countryCode: countryCodePhone1,
        areaCode: areaCodePhone1,
        number: numberPhone1,
        extension: null,
      },
    ];
    if (
      createAccountModel.phoneType2 !== null &&
      createAccountModel != null &&
      createAccountModel.phoneType2 !== "" &&
      createAccountModel != ""
    ) {
      let phone2 = createAccountModel.phone2.split(" ");
      var countryCodePhone2 = phone2[0].replace("+", "");

      var areaCodePhone2 = createAccountModel.phone2.substring(
        createAccountModel.phone2.indexOf("(") + 1,
        createAccountModel.phone2.indexOf(")")
      );

      phone2.shift();
      var numberPhone2 = phone2
        .join("")
        .replace("(" + areaCodePhone2 + ")", "")
        .replace("-", "");
      PhoneList.push({
        type: createAccountModel.phoneType2.value,
        countryCode: countryCodePhone2,
        areaCode: areaCodePhone2,
        number: numberPhone2,
        extension: null,
      });
    }

    var createAccountRequestModel = {
      profile: {
        userDetails: {
          firstName: createAccountModel.firstName,
          lastName: createAccountModel.lastName,
          prefix: createAccountModel.title.value,
          middleName: createAccountModel.middleName,
          maidenName: null,
          suffix: null,
        },
        userInfo: {
          dateOfBirth:
            createAccountModel.year.value +
            "-" +
            createAccountModel.month.value +
            "-" +
            createAccountModel.day.value,
          gender: createAccountModel.gender.value,
          knownTravelerNumber: null,
          emails: [createAccountModel.email],
          phones: PhoneList,
          addresses: [],
        },
        frequentFlyers: [],
        username: createAccountModel.userName,
        password: createAccountModel.password,
        userID: null,
        operationalUpdatesEnabled: createAccountModel.operationalUpdatesEnabled,
        marketingConsentEnabled: createAccountModel.marketingConsentEnabled,
        consents: [],
        hobby: null,
        maritalStatus: null,
        hasChildren: false,
        corporateUser: false,
        travelPreferences: {
          mealPreference: null,
          specialRequests: [],
        },
        securityQuestion: null,
        preferredLanguage: localStorage.getItem("i18nextLng"),
        profileStatus: false,
        userCreditCards: [],
        travelDocument: null,
        occupation: null,
        homeCountryCode: null,
        homeAirportCode: null,
      },
    };
    return createAccountRequestModel;
  };
}
