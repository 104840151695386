import React from "react";
import { Container, Row,Button, Col } from "react-bootstrap";
import Translation from "i18next";
import { connect } from "react-redux";
import { matrixAction } from "../../../../redux/actions/booking/matrixAction";
import "./Matrix.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faArrowLeft, faArrowRight, faPlaneArrival, faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import HttpService from "../../../../service/shared/HttpService";
import moment from "moment";
import Loading from "../../seat-selection/Loading";
import { searchFlightAction } from "../../../../redux/actions/booking/searchFlightAction";
import { Redirect } from "react-router";

class Matrix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      isLoading:false,
      redirect:false,
    };
    // this.initializeState();
  }
  addClass = (columnNumber, rowNumber) => {
    var columnList = document.querySelectorAll(".matrix-col-" + columnNumber);
    var columnCounter = 0;
    columnList.forEach((column) => {
      if (columnCounter <= rowNumber) {
        column.className += " high-light";
        columnCounter++;
      } else {
        return true;
      }
    });
    var headerList = document.getElementsByClassName(
      "matrix-header-col-" + columnNumber
    );
    headerList[0].className += " active-header";
  };
  removeClass = (columnNumber) => {
    var columnList = document.querySelectorAll(".matrix-col-" + columnNumber);
    columnList.forEach((column) => {
      column.classList.remove("high-light");
    });
    var headerList = document.getElementsByClassName(
      "matrix-header-col-" + columnNumber
    );
    headerList[0].classList.remove("active-header");
  };
  dateConverter = (date) => {
    return new Date(date.toString());
  };
  selectedMatrix = (columnNumber, rowNumber, startDate, endDate) => {
    this.setState({ startDate: startDate, endDate: endDate });
    //remove last selected element
    var oldSeletedList = document.getElementById("selected");
    if (oldSeletedList !== null) {
      oldSeletedList.setAttribute("id", "");
    }
    var newSelectedList = document.getElementsByClassName(
      "matrix-col-" + columnNumber + "-row-" + rowNumber
    );
    newSelectedList[0].setAttribute("id", "selected");
  };
  returnNextButton=()=>{
    this.setState({ isLoading: true });
    var tempSerachRequest = this.props.flightSearchRequest;
    tempSerachRequest.itineraryParts[1].when.date =moment(
      this.props.tableBody[6][7]
    ).add(2, "days").format("YYYY-MM-DD");
    tempSerachRequest.searchType="MATRIX";

    HttpService.postService(
      tempSerachRequest,
      "/Availability/AdvancedSearch",
      this.props.token
    )
      .then((response) => {
         this.props.saveMatrixResult(response.data);
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  departureNextButton=()=>{
    var deparureLastDate = moment(this.props.topHeader[6]).format("YYYY-MM-DD");
    var returnFirstDate=moment(this.props.tableBody[0][7]).add(2, "days").format("YYYY-MM-DD");
    if(deparureLastDate<returnFirstDate){
      this.setState({ isLoading: true });
      var tempSerachRequest = this.props.flightSearchRequest;
      tempSerachRequest.itineraryParts[0].when.date =moment(
        deparureLastDate ).add(2, "days").format("YYYY-MM-DD");
      tempSerachRequest.searchType="MATRIX";
      HttpService.postService(
        tempSerachRequest,
        "/Availability/AdvancedSearch",
        this.props.token
      )
        .then((response) => {
          this.props.saveMatrixResult(response.data);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }

  }
  returnPreviousButton=()=>{
    var deparureLastDate = moment(this.props.topHeader[0]).format("YYYY-MM-DD");
    var returnFirstDate=moment(this.props.tableBody[0][7]).add(-4, "days").format("YYYY-MM-DD");
    if(deparureLastDate<returnFirstDate){
      this.setState({ isLoading: true });
      var tempSerachRequest = this.props.flightSearchRequest;
      tempSerachRequest.itineraryParts[1].when.date = returnFirstDate;
            tempSerachRequest.searchType = "MATRIX";
      HttpService.postService(
        tempSerachRequest,
        "/Availability/AdvancedSearch",
        this.props.token
      )
        .then((response) => {
           this.props.saveMatrixResult(response.data);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  departurePreviousButton=()=>{
    var toDay = moment().format("YYYY-MM-DD");
    var departureFirstDate = moment(this.props.topHeader[0]).format("YYYY-MM-DD");
    if(departureFirstDate>=toDay){
      this.setState({ isLoading: true });
      var tempSerachRequest = this.props.flightSearchRequest;
      tempSerachRequest.itineraryParts[0].when.date =moment(
        departureFirstDate
      ).add(-4, "days").format("YYYY-MM-DD");
      tempSerachRequest.searchType="MATRIX";
      HttpService.postService(
        tempSerachRequest,
        "/Availability/AdvancedSearch",
        this.props.token
      )
        .then((response) => {
           this.props.saveMatrixResult(response.data);
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  disabledDepartureNextButton=()=>{
    var deparureLastDate = moment(this.props.topHeader[6]).format("YYYY-MM-DD");
    var returnFirstDate=moment(this.props.tableBody[0][7]).add(2, "days").format("YYYY-MM-DD");
    if (deparureLastDate < returnFirstDate) {
      return false;
    } else {
      return true;
    }
  }
  disabledReturnPreviousButton=()=>{
    var deparureLastDate = moment(this.props.topHeader[0]).format("YYYY-MM-DD");
    var returnFirstDate=moment(this.props.tableBody[0][7]).add(-4, "days").format("YYYY-MM-DD");
    if(deparureLastDate<returnFirstDate){
      return false

    }
    else{
      return true;

    }
  }
  disabledDeparturePreviousButton=()=>{
    var toDay = moment().format("YYYY-MM-DD");
    var departureFirstDate = moment(this.props.topHeader[0]).format("YYYY-MM-DD");
    if(departureFirstDate>=toDay){
      return false

    }
    else{
      return true;

    }
  }
  numberWithCommas(number) {
    return parseInt(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  continue=()=>{
    if(this.state.startDate !== null &&this.state.endDate !== null){
      this.setState({ isLoading: true });
      var tempSerachRequest = this.props.flightSearchRequest;
      tempSerachRequest.itineraryParts[0].when.date =this.state.startDate;
      tempSerachRequest.itineraryParts[1].when.date =this.state.endDate;
      tempSerachRequest.searchType=1;
  
      HttpService.postService(
        tempSerachRequest,
        "/Availability/Search",
        this.props.token
      )
        .then((response) => {
          this.props.saveSearchResult(response);
          this.setState({ isLoading: false,redirect:true});
        })
        .catch((error) => {
          console.log(error);
        });

    }
  }
  render() {
    if(this.state.redirect){
      return (
        <Redirect
          to={{
            pathname: "/flightSelect",
          }}
        />
      );
    }
    return (
      <div className="mt-5 flight-selection-container">
        {this.state.isLoading ? <Loading /> : null}
        <Row className="w-100 m-0">
          <div className="col-11 m-0 p-0">
            <table className="float-right">
              <thead>
                <tr>
                  <td colspan="8">
                    <div className="matrix-label-top box-shadow">
                      <FontAwesomeIcon
                        icon={faPlaneDeparture}
                        className="mx-2"
                      />
                      {Translation.t("departure_city_or_airport")}
                      {AirportFullName.getFullName(
                        this.props.searchFlightRequest.itineraryParts[0].to.code
                      )}
                      <span className="ml-4">
                        <Button
                          className={
                            this.disabledDeparturePreviousButton()
                              ? "bk-submit-btn btn-ethiopian matrix-button mx-2 btn-not-allowd disabled"
                              : "bk-submit-btn btn-ethiopian matrix-button mx-2 "
                          }
                          onClick={() => this.departurePreviousButton()}
                        >
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </Button>
                        <Button
                          className={
                            this.disabledDepartureNextButton()
                              ? "bk-submit-btn btn-ethiopian matrix-button mx-2 btn-not-allowd disabled"
                              : "bk-submit-btn btn-ethiopian matrix-button mx-2 "
                          }
                          onClick={() => this.departureNextButton()}
                        >
                          <FontAwesomeIcon icon={faAngleRight} />
                        </Button>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  {this.props.topHeader.map((topHeader, index) => (
                    <td
                      className={
                        "cell matrix-header matrix-header-top matrix-header-row-0 matrix-header-col-" +
                        index
                      }
                    >
                        <div className="date-text">
                          {Translation.t(
                            "day_" + this.dateConverter(topHeader).getDay()
                          )}
                        </div>
                        <div className="date-number">
                          {Translation.t(
                            "month_" + this.dateConverter(topHeader).getMonth()
                          )}{" "}
                          {this.dateConverter(topHeader).getDate()}
                        </div>
                    </td>
                  ))}
                  <td className="matrix-header">
                    <div className="align-text-top top-header-text">
                      <FontAwesomeIcon icon={faArrowLeft} /> Departure
                    </div>
                    <br />
                    <div className="rotate align-text-bottom top-header-text">
                      Return <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.props.tableBody.map((tableBodyRows, rowIndex) => (
                  <tr>
                    {tableBodyRows.map((cell, columnIndex) => {
                      if (columnIndex === 7) {
                        return (
                          <td
                            className={
                              "cell matrix-header matrix-header-left  matrix-header-col-" +
                              columnIndex
                            }
                          >
                              <div className="date-text">
                                {Translation.t(
                                  "day_" + this.dateConverter(cell).getDay()
                                )}
                              </div>
                              <div className="date-number">
                                {Translation.t(
                                  "month_" + this.dateConverter(cell).getMonth()
                                )}{" "}
                                {this.dateConverter(cell).getDate()}
                              </div>
                          </td>
                        );
                      } else {
                        var classNames =
                          "matrix-body align-text-top  matrix-col-" +
                          columnIndex +
                          " matrix-col-" +
                          columnIndex +
                          "-row-" +
                          rowIndex +
                          " matrix-row-" +
                          rowIndex;
                        return (
                          <td
                            className={
                              cell.soldout || cell.status !== "AVAILABLE"
                                ? classNames + " disabled cell"
                                : classNames + " active cell"
                            }
                            onMouseEnter={() => {
                              if (cell.status === "AVAILABLE") {
                                this.addClass(columnIndex, rowIndex);
                              }
                            }}
                            onMouseLeave={() => {
                              if (cell.status === "AVAILABLE") {
                                this.removeClass(columnIndex);
                              }
                            }}
                            onClick={() => {
                              if (cell.status === "AVAILABLE") {
                                this.selectedMatrix(
                                  columnIndex,
                                  rowIndex,
                                  cell.startDate,
                                  cell.endDate
                                );
                              }
                            }}
                          >
                              <div className="cell-text">
                                {cell.status === "AVAILABLE"
                                  ? this.numberWithCommas(
                                      cell.total.alternatives[0][0].amount
                                    ) +
                                    " " +
                                    cell.total.alternatives[0][0].currency
                                  : cell.status === "UNAVAILABLE"
                                  ? "N/A"
                                  : cell.status}
                              </div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-1 m-0 p-0 ">
            <div className="matrix-label-left my-0 float-left box-shadow">
              <span className="matrix-label-text ">
                {" "}
                <FontAwesomeIcon icon={faPlaneArrival} className="mx-2" />
                {Translation.t("returning_city_or_airport")}
                {AirportFullName.getFullName(
                  this.props.searchFlightRequest.itineraryParts[1].to.code
                )}
                <span className="ml-4 float-right">
                  <Button
                    className={
                      this.disabledReturnPreviousButton()
                        ? "bk-submit-btn btn-ethiopian matrix-button mx-2 btn-not-allowd disabled"
                        : "bk-submit-btn btn-ethiopian matrix-button mx-2 "
                    }
                    onClick={() => this.returnPreviousButton()}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </Button>
                  <Button
                    className="bk-submit-btn btn-ethiopian matrix-button mx-2"
                    onClick={() => this.returnNextButton()}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Button>
                </span>
              </span>
            </div>
          </div>
        </Row>
        <Row className="w-100 m-0 mt-4">
          <div className="col-11 m-0 p-0">
            <span className="float-right">
              <Button
                variant="danger"
                className="bk-submit-btn btn-ethiopian mx-2"
                onClick={() => this.continue()}
              >
                {Translation.t("continue")}
              </Button>
            </span>
          </div>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlightRequest: state.searchFlight.searchRequest,
    topHeader: state.matrix.topHeader,
    tableBody: state.matrix.tableBody,
    flightSearchRequest: state.searchFlight.searchRequest,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    initializeState: (startDate, endDate) => {
      dispatch({
        type: matrixAction.INITIALIZESATE,
        startDate: startDate,
        endDate: endDate,
      });
    },
    saveMatrixResult:(responseData)=>{
      dispatch({
        type:matrixAction.INITIALIZESATE,
        responseData:responseData,
      })
    },
    saveSearchResult: (result) =>
    dispatch({
      type: searchFlightAction.SAVESEARCHRESULT,
      searchResult: result,
    }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
