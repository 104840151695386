import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Translation from "i18next";
import FareRule from "../../shared/shoppingCart/FareRule";
import BaggageInfo from "../../shared/shoppingCart/BaggageInfo";
class SubmitPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAgreedToTerms: false,
      showFareRule: false,
      showBaggageInformation: false,
    };
  }
  onAgreedToTermsClicked = () => {
    this.setState({ isAgreedToTerms: !this.state.isAgreedToTerms });
  };
  hideBaggageInfo = () => {
    this.setState({ showBaggageInformation: false });
  };
  hideFareRule = () => {
    this.setState({ showFareRule: false });
  };
  render() {
    return (
      <>
        <FareRule
          showFareRule={this.state.showFareRule}
          hideFareRule={this.hideFareRule}
        />
        <BaggageInfo
          showBaggageInformation={this.state.showBaggageInformation}
          hideBaggageInfo={this.hideBaggageInfo}
        />
        <div className="payment-submit-terms-condition">
          <div>
            <h3>{Translation.t("complete_payment")}</h3>
          </div>
          <div>
            <span>{Translation.t("payment_terms_and_condition")}</span>
          </div>
          <div class="form-check py-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="agreedToTerms"
              onClick={this.onAgreedToTermsClicked}
            />
            <label
              class="form-check-label agreedToTerms-label pl-2"
              for="agreedToTerms"
            >
              {Translation.t("payment_agreed_to_terms")}
              <a onClick={() => this.setState({ showFareRule: true })} href="#">
                {" "}
                Fare rules
              </a>{" "}
              and{" "}
              <a
                onClick={() => this.setState({ showBaggageInformation: true })}
                href="#"
              >
                Baggage
              </a>
            </label>
          </div>
          <div className="float-right py-5">
            <Button
              type="submit"
              className="payment-submit-btn"
              disabled={this.state.isAgreedToTerms ? "" : "disabled"}
            >
              {Translation.t("continue")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}
export default SubmitPayment;
