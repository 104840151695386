export default class ItineraryPart{
    params={
        from: null,
        to:null,
        when:{
            departureDate:null,
            arrivalDate:null,
        },
        stopDays : "init"
    }
}