import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { TeleBirrCreateOrder } from "../../../../model/payment/TeleBirrCreateOrder";
import {
    payment,
    HoldReservationRequestObjTwoHours,
} from "../../../../model/payment/HoldReservationRequestObj";
import HttpService from "../../../../service/shared/HttpService";
import Notification from "../../../booking/booking-widget/Notification";
import Loading from "../../../booking/seat-selection/Loading";
import { paymentAction } from "../../../../redux/actions/booking/paymentAction";
import { Redirect } from "react-router-dom";

const TeleBirrPayment = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [redirectToSummaryPage, setRedirectToSummaryPage] = useState(false);
    useEffect(() => {
        let countryCode = "ET";
        let airportCode =
            props.searchFlight.searchResult.data.searchResponse.brandedResults
                .itineraryPartBrands[0][0].itineraryPart.segments[0].origin;
        let departureDate =
            props.searchFlight.searchResult.data.searchResponse.brandedResults
                .itineraryPartBrands[0][0].departure;
        let cookieSabreDataRequest =
            props.searchFlight.searchResult.data.cookieSabreDataRequest;
        let token = props.searchFlight.token;
        let requestObject = {
            IsToday: false,
            IsFlightWithIn48Hours: false,
            CountryCode: countryCode,
            AirportCode: airportCode,
            DepartureDateTime: departureDate,
            paymentType: 0,
            cookieSabreDataRequest: cookieSabreDataRequest,
        };
        HttpService.postService(
            requestObject,
            "/PaymentOption/GetPaymentOption",
            token
        )
            .then((paymentResponse) => {
                window.handleinitDataCallback = function () {
                    window.location.href =
                        "https://ibe.ethiopianairlines.com/tele-birr-payment-summary-success";
                };
                var holdReservationRequestObj = new HoldReservationRequestObjTwoHours();

                holdReservationRequestObj.requestObj.cookieSabreDataRequest =
                    props.searchFlight.searchResult.data.cookieSabreDataRequest;

                var twoHoursPayment = new payment();

                twoHoursPayment.requestObj.amount.amount = parseFloat(
                    paymentResponse.data.payment.paymentProducts[0].paymentInfo.amount
                        .amount
                );

                twoHoursPayment.requestObj.amount.currency =
                    paymentResponse.data.payment.paymentProducts[0].paymentInfo.amount.currency;

                holdReservationRequestObj.requestObj.payment.push(
                    twoHoursPayment.requestObj
                );
                holdReservationRequestObj.requestObj.DepartureDate = departureDate;
                HttpService.postService(
                    holdReservationRequestObj.requestObj,
                    "/Purchase/Purchase",
                    props.searchFlight.token
                )
                    .then((holdPNRresponse) => {
                        if (holdPNRresponse.data.statusAPI === "SUCCESS") {
                            props.saveReservationDetail(holdPNRresponse.data);
                            var teleBirrCreateOrder = new TeleBirrCreateOrder();
                            teleBirrCreateOrder.PNR = holdPNRresponse.data.pnr.reloc;
                            teleBirrCreateOrder.Title =
                                holdPNRresponse.data.pnr.reloc;
                            teleBirrCreateOrder.Currency =
                                paymentResponse.data.payment.paymentProducts[0].paymentInfo.amount.currency;
                            teleBirrCreateOrder.Amount =
                                paymentResponse.data.payment.paymentProducts[0].paymentInfo.amount.amount;

                             if(holdPNRresponse.data.pnr.passengers[0].passengerInfo.emails[0].trim().toLowerCase()
                               ==="yonasbe@ethiopianairlines.com"){
                               teleBirrCreateOrder.Amount =1;
                             }
                            teleBirrCreateOrder.NotifyUrl =
                                "https://m.ethiopianairlines.com/flygate-flygateapi/v1.0/api/TeleBirrNotification/ReceivePaymentStatus";
                            teleBirrCreateOrder.RedirectUrl =
                                "https://ibe.ethiopianairlines.com/tele-birr-payment-summary-success";
                            HttpService.teleBirrPayment(
                                teleBirrCreateOrder,
                                props.searchFlight.token
                            )
                                .then((tellBirrApiResponse) => {
                                    if (tellBirrApiResponse.data.statusAPI === "SUCCESS") {
                                        props.saveRawRequest(
                                            tellBirrApiResponse.data.rawRequest.trim()
                                        );
                                        if (!window.consumerapp) {
                                            setMessage(
                                                "Unable to open the payment page, Please try again"
                                            );
                                        } else {
                                            try {
                                                window.consumerapp.evaluate(
                                                    JSON.stringify({
                                                        functionName: "js_fun_start_pay",
                                                        params: {
                                                            rawRequest:
                                                                tellBirrApiResponse.data.rawRequest.trim(),
                                                            functionCallBackName: "handleinitDataCallback",
                                                        },
                                                    })
                                                );
                                                setMessage(tellBirrApiResponse.data.messageAPI);
                                                setRedirectToSummaryPage(true);
                                            } catch (error) {
                                                setMessage(
                                                    "Sorry, we couldn't process your request at the moment. Please try again later."
                                                );
                                            }
                                        }
                                    } else {
                                        setMessage(
                                            "Sorry, we couldn't process your request at the moment. Please try again later."
                                        );
                                    }
                                })
                                .catch((error) => {
                                    setMessage(
                                        "Sorry, we couldn't process your request at the moment. Please try again later."
                                    );
                                })
                                .finally(() => setIsLoading(false));
                        } else {
                            setIsLoading(false);
                            setMessage(holdPNRresponse.data.messageAPI);
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setMessage(
                            "Sorry, we couldn't process your request at the moment. Please try again later."
                        );
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                setMessage(
                    "Sorry, we couldn't process your request at the moment. Please try again later."
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (redirectToSummaryPage) {
        return (
            <Redirect
                to={{
                    pathname: "/tele-birr-payment-summary",
                }}
            />
        );
    }
    return (
        <>
            <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
                <div className="col-12 col-xl-7 mx-0 mt-5">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Notification info={false} header={message} body="" />
                    )}
                </div>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        searchFlight: state.searchFlight,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveReservationDetail: (reservationDetail) =>
            dispatch({
                type: paymentAction.SAVERESERVATIONDETAIL,
                reservationDetail: reservationDetail,
            }),
        saveRawRequest: (rawRequest) =>
            dispatch({
                type: paymentAction.SAVERAWREQUEST,
                rawRequest: rawRequest,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeleBirrPayment);
