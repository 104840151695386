import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import FLightScheduleResult from "./FlightScheduleResult";
import "./flightSchedule.css";

import moment from "moment";
import FlightScheduleHeader from "./FlightScheduleHeader";
import EditFlightSchedule from "./EditFlightSchedule";
import { connect } from "react-redux";
import Loading from "../../booking/seat-selection/Loading";
import FlightInfoService from "../../../service/manage-my-booking/FlightInfoService";
import HttpService from "../../../service/shared/HttpService";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";

import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import Notification from "../../booking/booking-widget/Notification";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { globalActionType } from "../../../redux/actions/shared/globalAction";

import { useHistory } from "react-router-dom";

import Translation from "i18next";


const AllFlightStatusResult = (props) => {
  const flightInfoService = new FlightInfoService();
  let history = useHistory();

  const retriveFlightSchedule = () => {
    if (
      props.itineraryPart[0].params.from !== null &&
      props.itineraryPart[0].params.to !== null &&
      props.itineraryPart[0].params.when.departureDate !== null
    ) {
      var payload = {
        Origin: props.itineraryPart[0].params.from.value,
        Destination: props.itineraryPart[0].params.to.value,
        requestType: 2,
        departureDate: props.itineraryPart[0].params.when.departureDate,
      };
      flightInfoService
        .getFlightScheduleByRoute([payload], props.token)
        .then((response) => {
          console.log(response);
          if (response.data.statusAPI === "ERROR") {
            props.updateIsFlightSchedulePresent(false);
          } else {
            props.updateBackedupSchedules(
              response.data.flightSchedules[0].flightSchedules
            );
          }
          setLoading(false);
          props.updateIsLoading(false);
        })
        .catch((error) => {
          HttpService.getTokenServive().then((response) => {
            props.saveToken(response.data.tokenDetail.access_token);
            flightInfoService
              .getFlightScheduleByRoute(
                [payload],
                response.data.tokenDetail.access_token
              )
              .then((response) => {
                console.log(response);
                if (response.data.statusAPI === "ERROR") {
                  props.updateIsFlightSchedulePresent(false);
                } else {
                  props.updateBackedupSchedules(
                    response.data.flightSchedules[0].flightSchedules
                  );
                }
                setLoading(false);
                props.updateIsLoading(false);
              });
          });
        });
    }
  };

  const [loading, setLoading] = useState(() => {
    retriveFlightSchedule();
    return true;
  });

  return (
    <>
      {!props.isFlightSchedulePresent ? (
        <div className="container mt-5">
          <Notification header={""} body={"Flight Schedule Not Found"} />
          <button
            className="bk-submit-btn btn-ethiopian btn ml-auto d-flex align-items-center"
            onClick={() => {
              history.push("/");
              props.updateIsFlightSchedulePresent(true);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="pl-3">{Translation.t("go_back")}</span>
          </button>
        </div>
      ) : (
        <>
          {props.isLoading || loading ? (
            <Loading />
          ) : (
            <>
              {/* {console.log()} */}
              {props.isLoading ? <Loading /> : null}
              <EditFlightSchedule flightSchedules={props.backedUpFlights} />
              <div className="mt-4 flight-selection-container">
                <FlightScheduleHeader flightSchedules={props.backedUpFlights} />

                <div className="my-3">
                  <div className="flight-status-result-header mx-0 d-none d-md-flex">
                    <Row className="mx-0 w-100">
                      <Col md={3} className="d-flex">
                        <div className="d-flex justify-content-start align-self-center">
                          <b className="ml-3">{Translation.t("departure_time")}</b>
                        </div>
                      </Col>
                      <Col md={1} className="d-flex">
                        <div className="d-flex justify-content-start align-self-center">
                          <b>{Translation.t("stops")}</b>
                        </div>
                      </Col>
                      {props.backedUpFlights[0].operatingDays.map(
                        (headerDate) => {
                          return (
                            <div className="schedule-date-column">
                              <div className="d-flex justify-content-center row">
                                <b className="d-flex col-12 justify-content-center">
                                  {moment(headerDate.date)
                                    .locale("en")
                                    .format("ddd")}
                                </b>
                                <b className="d-flex col-12 justify-content-center">
                                  {moment(headerDate.date)
                                    .locale("en")
                                    .format("MM/DD")}
                                </b>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Row>
                  </div>
                </div>
                {props.flightSchedules.map((flightSchedule) => {
                  return (
                    <FLightScheduleResult
                      flightSchedule={flightSchedule}
                      departureDate={
                        props.itineraryPart[0].params.when.departureDate
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.searchFlight.token,
    itineraryPart: state.bookingWiget.itineraryPart,
    isLoading: state.global.isLoading,
    flightSchedules: state.flightSchedule.flightSchedules,
    flightSchedules0: state.flightSchedule.flightSchedules[0],
    backedUpFlights: state.flightSchedule.backedUpFlights,
    isFlightSchedulePresent: state.flightSchedule.isFlightSchedulePresent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBackedupSchedules: (value) =>
      dispatch({
        type: flightScheduleAction.SCHEDULEINITFLIGHTS,
        payload: value,
      }),
    saveToken: (token) =>
      dispatch({
        type: searchFlightAction.SAVETOKEN,
        token: token,
      }),
    updateIsFlightSchedulePresent: (value) =>
      dispatch({
        type: flightScheduleAction.ISFLIGHTSCHEDULEPRESENT,
        payload: value,
      }),
    updateIsLoading: (value) =>
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFlightStatusResult);
