import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import PassengerService from "../../../../service/booking/passenger/PassengerService";
import Select from "react-select";
import AirportSelectStyle from "../../../../master-data/booking-widget/airpot-select-style";
import Translation from "i18next";
import PhoneType from "../../../../master-data/shared/phoneType.json";
import { connect } from "react-redux";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import UserName from "../../shared/UserName";

const selectStyleDisabled = AirportSelectStyle.disabledSelect;
const selectStyleDesktop = AirportSelectStyle.desktopStyle;
const passengerService = new PassengerService();

const PersonalDetail = (props) => {
  const getSplitBirthDate = (type) => {
    var splitedBirthDate = props.birthDate.split("-");
    if (type === "year") {
      return splitedBirthDate[0];
    } else if (type === "month") {
      return splitedBirthDate[1];
    } else {
      return splitedBirthDate[2];
    }
  };
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="media">
            <UserName />

            <div className="media-body mx-2">
              <p>{Translation.t("manage_profile_tip")}</p>
              {Translation.t("all_fields_required")}
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <p className="my-3">
          <b>{Translation.t("basic_info")}</b>
        </p>
      </div>
      <Row>
        <Col xs="12" sm={12} md={6} lg={2} className="px-1 my-2">
          <Select
            styles={selectStyleDisabled}
            menuIsOpen={false}
            isSearchable={false}
            placeholder={<span style={{ color: "black" }}>{props.title}</span>}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("title")}
          </label>
        </Col>
        <Col xs="12" sm={12} md={6} lg={4} className="px-1 my-2">
          <input
            className="form-control widget-input disabled-input"
            disabled
            placeholder="First Name"
            defaultValue={props.firstName}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("first_name")}
          </label>
        </Col>
        <Col xs="12" sm={12} md={6} lg={3} className="px-1 my-2">
          <input
            className="form-control widget-input disabled-input"
            disabled
            defaultValue={props.middleName}
            placeholder={Translation.t("middle_name")}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("middle_name")}
          </label>
        </Col>
        <Col xs="12" sm={12} md={6} lg={3} className="px-1 my-2">
          <input
            className="form-control widget-input disabled-input"
            disabled
            defaultValue={props.lastName}
            placeholder="Last Name"
          />
          <label className="form-sub-label mx-2">
            {Translation.t("last_name")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <Row className="mr-0">
            <div className="col-4 pr-0">
              <Select
                styles={selectStyleDisabled}
                isSearchable={false}
                menuIsOpen={false}
                placeholder={
                  <span style={{ color: "black" }}>
                    {getSplitBirthDate("year")}
                  </span>
                }
              ></Select>
              <label className="form-sub-label mx-2">
                {Translation.t("birth_year")}
              </label>
            </div>
            <div className="col-4 px-0">
              <Select
                styles={selectStyleDisabled}
                isSearchable={false}
                menuIsOpen={false}
                placeholder={
                  <span style={{ color: "black" }}>
                    {getSplitBirthDate("month")}
                  </span>
                }
              ></Select>
              <label className="form-sub-label mx-2">
                {Translation.t("birth_month")}
              </label>
            </div>
            <div className="col-4 px-0">
              <Select
                styles={selectStyleDisabled}
                isSearchable={false}
                menuIsOpen={false}
                placeholder={
                  <span style={{ color: "black" }}>
                    {getSplitBirthDate("day")}
                  </span>
                }
              ></Select>
              <label className="form-sub-label mx-2">
                {Translation.t("birth_day")}
              </label>
            </div>
          </Row>
        </Col>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <Select
            value={passengerService
              .getGenderList()
              .filter((g) => g.value === props.gender)}
            styles={selectStyleDesktop}
            options={passengerService.getGenderList()}
            isSearchable={false}
            placeholder={Translation.t("gender")}
            onChange={(genderValue) =>
              props.updateGender(genderValue.value, "gender")
            }
          />
          <label className="form-sub-label mx-2">
            {Translation.t("gender")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <Select
            value={PhoneType.filter((p) => p.value === props.phoneType1)}
            styles={selectStyleDesktop}
            options={PhoneType}
            isSearchable={false}
            placeholder={<span>{Translation.t("phone_type")} *</span>}
            onChange={(phoneValue) =>
              props.updateAccountModel(phoneValue.value, "phoneType1")
            }
          ></Select>
          <label className="form-sub-label mx-2">
            {Translation.t("phone_type")}
          </label>
        </Col>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <PhoneInput
            inputProps={{
              className: props.isPhone1Valid
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input",
              placeholder: Translation.t("phone_number"),
              id: "create-account-phone-1",
            }}
            inputExtraProps={{
              required: true,
              autoFocus: true,
            }}
            enableAreaCodes={true}
            enableSearch={true}
            value={props.phone1}
            onChange={(value, country, e, formattedValue) => {
              props.updateAccountModel(formattedValue, "phone1");
            }}
            onBlur={() => {
              var inputValue = document.getElementById(
                "create-account-phone-1"
              );
              if (
                inputValue.value === null ||
                inputValue.value === "" ||
                inputValue.value === "+"
              ) {
                inputValue.classList.add("invalid_input");
                inputValue.placeholder = Translation.t("phone_number_required");
              }
            }}
            onFocus={() => {
              var inputField = document.getElementById(
                "create-account-phone-1"
              );
              inputField.classList.remove("invalid_input");
              inputField.placeholder = Translation.t("phone_number");
            }}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("phone_number")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <Select
            // className={props.isPhoneType1Valid ? "" : "is-invalid-select"}
            value={PhoneType.filter((p) => p.value === props.phoneType2)}
            styles={selectStyleDesktop}
            options={PhoneType}
            isSearchable={false}
            placeholder={<span>{Translation.t("phone_type")} </span>}
            onChange={(phoneValue) =>
              props.updateAccountModel(phoneValue.value, "phoneType2")
            }
          ></Select>
          <label className="form-sub-label mx-2">
            {Translation.t("phone_type")}
          </label>
        </Col>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <PhoneInput
            inputProps={{
              className: "form-control  widget-input my-2",
              placeholder: Translation.t("phone_number_2"),
            }}
            inputExtraProps={{
              required: true,
              autoFocus: true,
            }}
            enableAreaCodes={true}
            enableSearch={true}
            value={props.phone2}
            onChange={(value, country, e, formattedValue) => {
              props.updateAccountModel(formattedValue, "phone2");
            }}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("phone_number_2")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} className="px-1 my-2">
          <input
            className="form-control widget-input disabled-input"
            disabled
            defaultValue={props.email}
            placeholder={Translation.t("email_address")}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("email_address")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{Translation.t("consent_info")}</b>
          <Row>
            <div className="col-12">
              <p>
                <b>{Translation.t("operational_updates")}</b>
              </p>
              <div className="et-checkbox">
                <input
                  type="checkbox"
                  id="operational-update"
                  checked={props.operationalUpdatesEnabled}
                  onChange={() => {
                    props.updateAccountModel(
                      !props.operationalUpdatesEnabled,
                      "operationalUpdatesEnabled"
                    );
                  }}
                />
                <label htmlFor="operational-update" className="d-inline">
                  {Translation.t("operational_updates_description")}
                </label>
              </div>
            </div>
          </Row>
          <Row>
            <div className="col-12">
              <p>
                <b>{Translation.t("marketing_consent")}</b>
              </p>
              <div className="et-checkbox">
                <input
                  type="checkbox"
                  id="marketing-consent"
                  checked={props.marketingConsentEnabled}
                  onChange={() => {
                    props.updateAccountModel(
                      !props.marketingConsentEnabled,
                      "marketingConsentEnabled"
                    );
                  }}
                />
                <label htmlFor="marketing-consent" className="d-inline">
                  {Translation.t("marketing_consent_description")}
                </label>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    title: state.account.profile.userDetails.prefix,
    firstName: state.account.profile.userDetails.firstName,
    middleName: state.account.profile.userDetails.middleName,
    lastName: state.account.profile.userDetails.lastName,
    email: state.account.profile.userInfo.emails[0],
    birthDate: state.account.profile.userInfo.dateOfBirth,
    gender: state.account.profile.userInfo.gender,
    operationalUpdatesEnabled: state.account.createAccountModle.operationalUpdatesEnabled,
    marketingConsentEnabled: state.account.createAccountModle.marketingConsentEnabled,
    agree: state.account.createAccountModle.agree,
    phone1: state.account.createAccountModle.phone1,
    phoneType1: state.account.createAccountModle.phoneType1,
    phone2: state.account.createAccountModle.phone2,
    phoneType2: state.account.createAccountModle.phoneType2,
    isPhone1Valid: state.account.createAccountModle.validation.isPhone1Valid,
    isPhone2Valid: state.account.createAccountModle.validation.isPhone2Valid,
    isUserSignedIn: state.global.isUserSignedIn,
  };
};
const mapDispatchToProps = (dispacth) => {
  return {
    changeSelectValidation: (valueParameter, validationParameter, domain) =>
      dispacth({
        type: accountAction.CHANGEVALIDATION,
        domain: domain,
        valueParameter: valueParameter,
        validationParameter: validationParameter,
      }),
    updateAccountModel: (value, valueParameter) =>
      dispacth({
        type: accountAction.UPADTE,
        value: value,
        valueParameter: valueParameter,
      }),
    validateAccountModel: () => {
      dispacth({
        type: accountAction.VALIDATECREATEACCOUNT,
      });
    },
    updateGender: (value) => {
      dispacth({
        type: accountAction.UPDATEGENDER,
        value: value,
      });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(PersonalDetail);
