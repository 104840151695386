

export class UnionPayValidatorService
{
    constructor()
    {
        this.isEmailValid=true;
        this.isCountryValid=true;
        this.unionPayValidLists =[];
    }

  validateUnionPayInfo(unionPayInfo)
    {
        this.unionPayValidLists =[];
        for(let index =0;index<2;index++)
        {
            this.unionPayValidLists.push(true);
        }
        
        this.isEmailValid=this.validateUnionPayType(unionPayInfo.Email,0);
        this.isCountryValid=this.validateUnionPayType(unionPayInfo.Country,1);

         return {"unionPayValidation":this,"unionPayValidLists":  this.unionPayValidLists};
    }
    validateUnionPayType(type,index)
    {
        if(type === "" || type == null)
        {
           this.unionPayValidLists[index]=false;
           return false;
        }
        else
        {
            this.unionPayValidLists[index]=true;
            return  true;
        }
    }

}