import React from "react";
import {
  Accordion,
  Card,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faExchangeAlt,
  faPlane,
  faAngleUp,
  faChair,
  faLuggageCart,
  faLongArrowAltRight,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { shoppingCartAction } from "../../../redux/actions/booking/shoopingCartAction";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import moment from "moment";
import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";
import { ancillaryAction } from "../../../redux/actions/booking/ancillaryAction";
import ManageTripFareRule from "./ManageTripFareRule";
import ManageBaggageService from "../../../service/manage-my-booking/account/ManageBaggageService";
import TexDetails from "../../../master-data/shared/TaxDetails.json";
class GlobalTripShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flightAccordion: 0,
      seatAccordion: -1,
      extraAccordion: -1,
      sucharchgesAccordion: -1,
      taxesAccordion: -1,
      showFareRule: false,
      showBaggageInformation: false,
      checkedIn: 1,
      carryOn: -1,
    };
  }

  currencyForSeat = "";
  currentPageTracker = 1;
  hideBaggageInfo = () => {
    this.setState({ showBaggageInformation: false });
  };
  hideFareRule = () => {
    this.setState({ showFareRule: false });
  };
  getHoursFromMins = (mins) => {
    if (mins <= 60) {
      return (
        <span>
          {"  "}
          {mins} {" mins"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {parseInt(mins / 60)}
          {" hr(s) "} {mins % 60} {" mins"}
        </span>
      );
    }
  };
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getFlights = () => {
    var shoppingCartFlight = [];
    var operatedBy = [];
    var priceList = [];
    var passengerList = [];
    var numberOfAdult = 0;
    var numberOfChild = 0;
    var numberOfInfant = 0;
    var origin =
      this.props.responseData.itinerary.itineraryParts[0].segments[0].origin;
    var totalItenraryLength =
      this.props.responseData.itinerary.itineraryParts.length - 1;
    var lastItenerarySegmentLength =
      this.props.responseData.itinerary.itineraryParts[totalItenraryLength]
        .segments.length - 1;
    var destination =
      this.props.responseData.itinerary.itineraryParts[totalItenraryLength]
        .segments[lastItenerarySegmentLength].destination;

    console.log(this.props.responseData.priceBreakdown.subElements);
    console.log(
      this.props.responseData.priceBreakdown.subElements.filter(
        (s) => s.label === "farePrice"
      )[0]
    );
    var flightTotal = this.props.responseData.priceBreakdown.subElements.filter(
      (s) => s.label === "farePrice"
    )[0].price.alternatives[0][0].amount;
    var currency = this.props.responseData.priceBreakdown.subElements.filter(
      (s) => s.label === "farePrice"
    )[0].price.alternatives[0][0].currency;

    this.props.responseData.itinerary.itineraryParts.forEach(
      (itineraryPart, index) => {
        var time = null;
        var date = null;
        var stopAirport = [];
        if (index === 1 && totalItenraryLength === 1) {
          time = moment(itineraryPart.segments[0].departure)
            .locale(localStorage.getItem("i18nextLng"))
            .format("LT");
          date = moment(itineraryPart.segments[0].departure)
            .locale(localStorage.getItem("i18nextLng"))
            .format("DD MMMM YYYY");
          stopAirport = [];
          operatedBy = [];
          shoppingCartFlight.push(
            <div key={index}>
              <div className="shopping-cart-internal-header">
                <span className="shopping-cart-disapled">
                  <Translation Key="returning" />
                </span>{" "}
                <span className="shopping-cart-date">
                  {time}, on {date}
                </span>
              </div>
              <Row className="shopping-cart-internal-title">
                <div className="d-inline-flex px-3">
                  {AirportFullName.getCityName(
                    itineraryPart.segments[0].origin
                  )}
                </div>
                <div className="d-inline-flex">
                  <span>
                    {AirportFullName.getCityName(
                      itineraryPart.segments[itineraryPart.segments.length - 1]
                        .destination
                    )}
                  </span>
                </div>
              </Row>
              <Row className="shopping-cart-disapled">
                <div className="d-inline-flex pl-3">
                  <Translation Key="duration" />:{" "}
                  {this.getHoursFromMins(itineraryPart.totalDuration)}
                  {itineraryPart.stops !== 0 ? (
                    <span style={{ color: "#b21111" }}>
                      /{itineraryPart.stops} <Translation Key="stop" />
                      {itineraryPart.segments.map((segment, index) => {
                        segment.flight.stopAirports.forEach(
                          (stopAirporTemp) => {
                            stopAirport.push(stopAirporTemp.airport);
                          }
                        );
                        if (itineraryPart.segments.length > 0 && index != 0) {
                          stopAirport.push(segment.origin);
                        }
                      })}
                      ({stopAirport.join(",")})
                    </span>
                  ) : (
                    <span style={{ color: "#b21111" }}>
                      {" /"}
                      <Translation Key="non-stop" />
                    </span>
                  )}
                  {itineraryPart.segments.map((segment, index) => {
                    operatedBy.push(
                      <Row className="shopping-cart-disapled" key={index}>
                        <div className="col-12 d-flex">
                          <div>
                            <img
                              className="shopping-cart-airline-logo"
                              alt=""
                              src="https://dxbooking-cert.ethiopianairlines.com/dx/ETDX/4.7.14-278.ET_offers_VT/images/airlines/ET.png"
                            />
                            {segment.flight.airlineCode}
                            {"  "}
                            {segment.flight.flightNumber}
                          </div>
                          <p className="mx-3">
                            Operated by {segment.flight.operatingAirlineCode}
                          </p>
                        </div>
                      </Row>
                    );
                  })}
                </div>
              </Row>
              {operatedBy}
            </div>
          );
        } else {
          time = moment(itineraryPart.segments[0].departure)
            .locale(localStorage.getItem("i18nextLng"))
            .format("LT");
          date = moment(itineraryPart.segments[0].departure)
            .locale(localStorage.getItem("i18nextLng"))
            .format("DD MMMM YYYY");
          stopAirport = [];
          operatedBy = [];
          shoppingCartFlight.push(
            <div key={index}>
              <div className="shopping-cart-internal-header">
                <span className="shopping-cart-disapled">
                  {totalItenraryLength >= 2 ? <span>{index + 1}</span> : null}{" "}
                  <Translation Key="departing" />
                </span>{" "}
                <span className="shopping-cart-date">
                  {time}, on {date}
                </span>
              </div>
              <Row className="shopping-cart-internal-title">
                <div className="d-inline-flex px-3">
                  {AirportFullName.getCityName(
                    itineraryPart.segments[0].origin
                  )}
                </div>
                <div className="d-inline-flex">
                  <span>
                    {AirportFullName.getCityName(
                      itineraryPart.segments[itineraryPart.segments.length - 1]
                        .destination
                    )}
                  </span>
                </div>
              </Row>
              <Row className="shopping-cart-disapled">
                <div className="d-inline-flex pl-3">
                  <Translation Key="duration" />:
                  {this.getHoursFromMins(itineraryPart.totalDuration)}
                  {itineraryPart.stops !== 0 ? (
                    <span style={{ color: "#b21111" }}>
                      /{itineraryPart.stops} <Translation Key="stop" />
                      {itineraryPart.segments.map((segment, index) => {
                        segment.flight.stopAirports.forEach(
                          (stopAirporTemp) => {
                            stopAirport.push(stopAirporTemp.airport);
                          }
                        );
                        if (
                          itineraryPart.segments.length !== 1 &&
                          index + 1 !== itineraryPart.segments.length
                        ) {
                          stopAirport.push(segment.destination);
                        }
                      })}
                      ({stopAirport.join(",")})
                    </span>
                  ) : (
                    <span style={{ color: "#b21111" }}>
                      {" /"}
                      <Translation Key="non-stop" />
                    </span>
                  )}
                  {itineraryPart.segments.map((segment, index) => {
                    operatedBy.push(
                      <Row className="shopping-cart-disapled" key={index}>
                        <div className="col-12 d-flex">
                          <div>
                            <img
                              className="shopping-cart-airline-logo"
                              alt=""
                              src="https://dxbooking-cert.ethiopianairlines.com/dx/ETDX/4.7.14-278.ET_offers_VT/images/airlines/ET.png"
                            />
                            {segment.flight.airlineCode}
                            {"  "}
                            {segment.flight.flightNumber}
                          </div>
                          <p className="mx-3">
                            Operated by {segment.flight.operatingAirlineCode}
                          </p>
                        </div>
                      </Row>
                    );
                  })}
                </div>
              </Row>
              {operatedBy}
            </div>
          );
        }
      }
    );
    this.props.responseData.priceBreakdown.subElements
      .filter((s) => s.label === "farePrice")[0]
      .subElements.forEach((subElement) => {
        priceList.push(
          <Row className="shopping-cart-header-border shopping-cart-disapled">
            <div className="col-6">
              <span className="float-left mt-2">
                {subElement.breakdownElementAssignment.passengerType ===
                "ADT" ? (
                  <Translation Key="adult" />
                ) : subElement.breakdownElementAssignment.passengerType ===
                  "CHD" ? (
                  <Translation Key="child" />
                ) : (
                  <Translation Key="infant" />
                )}{" "}
                x {subElement.breakdownElementAssignment.quantity}
              </span>
            </div>
            <div className="col-6">
              <span className="float-right mt-2">
                {this.numberWithCommas(
                  subElement.price.alternatives[0][0].amount
                )}{" "}
                {subElement.price.alternatives[0][0].currency}
              </span>
            </div>
          </Row>
        );
      });

    this.props.responseData.passengers.forEach((passenger) => {
      if (passenger.passengerInfo.type === "ADT") {
        numberOfAdult++;
        passengerList.push(
          <Row className="ml-5">
            <b>
              A{numberOfAdult} -{passenger.passengerDetails.firstName}
              {"  "} {passenger.passengerDetails.lastName}
            </b>
          </Row>
        );
      } else if (passenger.passengerInfo.type === "CHD") {
        numberOfChild++;
        passengerList.push(
          <Row className="ml-5">
            <b>
              C{numberOfChild} -{passenger.passengerDetails.firstName}
              {"  "} {passenger.passengerDetails.lastName}
            </b>
          </Row>
        );
      } else if (passenger.passengerInfo.type === "INF") {
        numberOfInfant++;
        passengerList.push(
          <Row className="ml-5">
            <b>
              I{numberOfInfant} -{passenger.passengerDetails.firstName}
              {"  "} {passenger.passengerDetails.lastName}
            </b>
          </Row>
        );
      }
    });
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="shopping-cart-header-border active-shopping-cart-header"
          onClick={() => {
            if (this.state.flightAccordion === 0) {
              this.setState({ flightAccordion: -1 });
            } else {
              this.setState({ flightAccordion: 0 });
            }
          }}
        >
          <span>
            <FontAwesomeIcon
              icon={this.state.flightAccordion === 0 ? faAngleUp : faAngleDown}
              className="shopping-cart-right-icon myb-shopping-cart-title ml-3"
            />

            <FontAwesomeIcon
              icon={faPlane}
              className={
                this.state.flightAccordion === 0
                  ? "shopping-cart-active shopping-cart-left-icon"
                  : "shopping-cart-disapled shopping-cart-left-icon"
              }
            />
          </span>
          <div className="row px-0 mx-0 align-items-center">
            <div className="d-flex pl-2 pl-md-3 pr-1 myb-shopping-cart-title shopping-cart-title">{origin}</div>
            <div className="d-flex px-1 myb-shopping-cart-title">
              <FontAwesomeIcon
                icon={
                  totalItenraryLength === 0
                    ? faLongArrowAltRight
                    : faExchangeAlt
                }
                className="shopping-cart-center-icon"
              />
            </div>
            <div className="d-flex px-1 shopping-cart-title myb-shopping-cart-title">
              {destination}
            </div>
            <div className="d-flex px-1 shopping-cart-price ml-auto myb-shopping-cart-title">
              {this.numberWithCommas(flightTotal)}
              {"  "} {currency}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {shoppingCartFlight}
            {priceList}
            {passengerList}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };
  getAncillaryTotal = () => {
    var ancillaryList =
      this.props.responseData.priceBreakdown.subElements.filter(
        (s) => s.label === "ancillariesPrice"
      )[0];
    if (ancillaryList.subElements === null) {
      return null;
    }

    return (
      <span>
        {this.numberWithCommas(ancillaryList.price.alternatives[0][0].amount)}{" "}
        {ancillaryList.price.alternatives[0][0].currency}{" "}
      </span>
    );
  };
  getAncillaryBody = () => {
    var ancillaryList =
      this.props.responseData.priceBreakdown.subElements.filter(
        (s) => s.label === "ancillariesPrice"
      )[0];
    if (ancillaryList.subElements === null) {
      return <div className="h4 text-center">No Extras Added Yet!</div>;
    }

    var ancillarySublement = [];
    ancillaryList.subElements.forEach((subElement) => {
      
      var itinerary = this.props.responseData.itinerary.itineraryParts.filter(
        (i) =>
          i["@id"] === subElement.breakdownElementAssignment.travelPart["@ref"]
      )[0];
      var passenger = this.props.responseData.passengers.filter(
        (p) =>
          p["@id"] === subElement.breakdownElementAssignment.passenger["@ref"]
      )[0];
      ancillarySublement.push({
        key:
          itinerary.segments[0].origin +
          "-" +
          itinerary.segments[itinerary.segments.length - 1].destination,
        firstName: passenger.passengerDetails.firstName,
        lastName: passenger.passengerDetails.lastName,
        ancillaryName: subElement.label,
        quantity: subElement.breakdownElementAssignment.quantity,
        type: subElement.breakdownElementAssignment.passengerType,
        price: subElement.price.alternatives[0][0].amount,
        passengerIndex: passenger.passengerIndex,
        id: itinerary["@id"],
      });
    });
    ancillarySublement.sort((a, b) => (a.id > b.id ? 1 : -1));
    var manageBaggageService = new ManageBaggageService();
    var finalJson = manageBaggageService.groupArrayOfObjects(
      ancillarySublement,
      "key"
    );

    if (finalJson.length !== 0) {
      return (
        <>
          {Object.keys(finalJson).map((key) => {
            var ancillaryListForShoppingCart = finalJson[key];
            return (
              <>
                <span className="shopping-cart-seat-header d-flex">
                  {"Flight : " + key}
                </span>
                {ancillaryListForShoppingCart.map(
                  (ancillaryListForShoppingCartSingle) => {
                    return (
                      <div className="passanger-lists">
                        <i class="fas fa-chair-office"></i>
                        <div className="single-passange row align-items-center py-1">
                          <OverlayTrigger
                            key={
                              ancillaryListForShoppingCartSingle.passengerIndex
                            }
                            placement="left"
                            overlay={
                              <Tooltip
                                id={
                                  "tooltip-" +
                                  ancillaryListForShoppingCartSingle.passengerIndex
                                }
                              >
                                <span className="text-uppercase p-2">
                                  {ancillaryListForShoppingCartSingle.firstName +
                                    " " +
                                    ancillaryListForShoppingCartSingle.lastName}
                                </span>
                              </Tooltip>
                            }
                          >
                            <span
                              className={
                                ancillaryListForShoppingCartSingle.type ===
                                "INF"
                                  ? "passanger-identifier-infant-shopping d-inline-flex"
                                  : "passanger-identifier-shopping d-inline-flex"
                              }
                            >
                              {ancillaryListForShoppingCartSingle.type[0] +
                                ancillaryListForShoppingCartSingle.passengerIndex}
                            </span>
                          </OverlayTrigger>
                          <>
                            <span className="shopping-cart-selected-seat-name d-inline-flex">
                              {ancillaryListForShoppingCartSingle.ancillaryName}{" "}
                              (x{ancillaryListForShoppingCartSingle.quantity})
                            </span>
                            <span className="shopping-cart-selected-seat-price d-inline-flex">
                              {this.numberWithCommas(
                                ancillaryListForShoppingCartSingle.price
                              )}{" "}
                              {ancillaryList.price.alternatives[0][0].currency}
                            </span>
                          </>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            );
          })}
        </>
      );
    } else {
      return <div className="h4 text-center">No Extras Added Yet!</div>;
    }
  };
  getTaxsDetail = (taxCode) => {
    var tax = TexDetails.filter((t) => t.taxCode === taxCode)[0];
    if (tax !== undefined) {
      return tax.taxDescription;
    } else {
      return taxCode;
    }
  };
  getTaxs = () => {
    var shoppingTax = [];
    var taxResponseJson =
      this.props.responseData.priceBreakdown.subElements.filter(
        (t) => t.label === "taxesPrice"
      )[0];
    var taxTotal = (
      <span className="float-right shopping-cart-price">
        {this.numberWithCommas(taxResponseJson.price.alternatives[0][0].amount)}{" "}
        {taxResponseJson.price.alternatives[0][0].currency}
      </span>
    );

    taxResponseJson.subElements.forEach((subElement) => {
      shoppingTax.push(
        <>
          <Row>
            <Col xs={6}>{this.getTaxsDetail(subElement.label)}</Col>
            <Col xs={6}>
              <div className="float-right">
                {subElement.price.alternatives[0][0].amount}
                {"  "}
                {subElement.price.alternatives[0][0].currency}
              </div>
            </Col>
          </Row>
        </>
      );
    });

    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="4"
          className="shopping-cart-header-border active-shopping-cart-header"
          onClick={() => {
            if (this.state.taxesAccordion === 4) {
              this.setState({ taxesAccordion: -1 });
            } else {
              this.setState({ taxesAccordion: 4 });
            }
          }}
        >
          {"   "}
          <FontAwesomeIcon
            icon={this.state.taxesAccordion === 4 ? faAngleUp : faAngleDown}
            className="shopping-cart-right-icon"
          />
          <FontAwesomeIcon
            icon={faFileInvoiceDollar}
            className={
              this.state.taxesAccordion === 4
                ? "shopping-cart-active shopping-cart-left-icon"
                : "shopping-cart-disapled shopping-cart-left-icon"
            }
          />
          <Row>
            <div className="col-6">
              <span className="shopping-cart-title float-left">
                <Translation Key="taxes_and_fee" />
              </span>
            </div>
            <div className="col-6">{taxTotal}</div>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>{shoppingTax}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };
  getPaymentDetail = () => {
    var paymentDetail = [];

    this.props.responseData.payments.forEach((payment, index) => {
      paymentDetail.push(
        <p key={index}>
          {payment.paymentCode} {payment.identifier}{" "}
          {this.numberWithCommas(payment.price.alternatives[0][0].amount)}{" "}
          {payment.price.alternatives[0][0].currency}
        </p>
      );
    });
    return paymentDetail;
  };
  shoppingCartBody = () => {
    return (
      <section className="payment-summary-trip-detail p-0">
        <ManageTripFareRule
          fareRule={this.props.responseData.fareRulesResult}
          showFareRule={this.state.showFareRule}
          hideFareRule={this.hideFareRule}
        />
        <Accordion defaultActiveKey="0" style={{ padding: 0, margin: 0 }}>
          <Card>
            <h1 className="ml-3 shopping-cart-header">
              <Translation Key="trip-cost" />{" "}
            </h1>
          </Card>
          {this.getFlights()}
        </Accordion>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="shopping-cart-header-border active-shopping-cart-header"
            >
              <span>
                <FontAwesomeIcon
                  icon={faChair}
                  className={
                    this.state.seatAccordion === 1
                      ? "shopping-cart-active shopping-cart-left-icon"
                      : "shopping-cart-disapled shopping-cart-left-icon"
                  }
                  className="shopping-cart-active shopping-cart-left-icon myb-shopping-cart-title"
                />
              </span>
              <FontAwesomeIcon
                icon={
                  this.state.seatAccordion === 1 && this.currentPageTracker >= 5
                    ? faAngleUp
                    : faAngleDown
                }
                className="shopping-cart-right-icon"
              />

              <span
                className={
                  this.currentPageTracker >= 5
                    ? "mx-3 shopping-cart-title myb-shopping-cart-title shopping-cart-header"
                    : "mx-3 shopping-cart-disapled myb-shopping-cart-title shopping-cart-header"
                }
              >
                {" "}
                <Translation Key="seats" />
                <span className="float-right shopping-cart-selected-total-seat-price myb-shopping-cart-title">
                  {this.getSeatTotal()}
                </span>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse className="shopping-cart-collapse" eventKey="0">
              <Card.Body className="px-0 shopping-cart-seat-section">
                {this.getSeatBody()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="shopping-cart-header-border active-shopping-cart-header"
            >
              <span
              className={
                this.state.extraAccordion === 1
                  ? "shopping-cart-active shopping-cart-left-icon"
                  : "shopping-cart-disapled shopping-cart-left-icon"
              }>
                <FontAwesomeIcon
                  icon={faLuggageCart}
                  className="shopping-cart-active shopping-cart-left-icon myb-shopping-cart-title"
                />
              </span>
              <FontAwesomeIcon
                icon={
                  this.state.extraAccordion === 1 &&
                  this.currentPageTracker >= 5
                    ? faAngleUp
                    : faAngleDown
                }
                className="shopping-cart-right-icon myb-shopping-cart-title"
              />

              <span
                className={
                  this.currentPageTracker >= 5
                    ? "mx-3 shopping-cart-title myb-shopping-cart-title shopping-cart-header"
                    : "mx-3 shopping-cart-disapled myb-shopping-cart-title shopping-cart-header"
                }
              >
                {" "}
                <Translation Key="extras" />
                <span className="float-right shopping-cart-selected-total-seat-price">
                  {this.getAncillaryTotal()}
                </span>
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse className="shopping-cart-collapse" eventKey="0">
              <Card.Body className="px-0 shopping-cart-seat-section">
                {this.getAncillaryBody()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <Accordion>{this.getTaxs()}</Accordion>
        <Card>
          <Row className="m-4 shopping-cart-total-price">
            <div className="col-6 col-sm-6 col-md-5 col-lg-5">
              <span className="float-left myb-shopping-cart-title shopping-cart-total-price">
                <Translation Key="trip_total" />
              </span>
            </div>
            <div className="col-6 col-sm-6 col-md-7 col-lg-7">
              <span className="float-right">
                {this.numberWithCommas(
                  this.props.responseData.priceBreakdown.price
                    .alternatives[0][0].amount
                )}{" "}
                <span style={{ fontSize: "1.4rem" }}>
                  {" "}
                  {
                    this.props.responseData.priceBreakdown.price
                      .alternatives[0][0].currency
                  }
                </span>
              </span>
            </div>
          </Row>
          <Row className="mx-4">
            <h4>Payment details </h4>
          </Row>
          <Row className="mx-4 shopping-cart-total-price">
            {this.getPaymentDetail()}
          </Row>

          <p className="col-12 shopping-cart-label myb-shopping-cart-title">
            <span>
              <Translation Key="see_applicable" />{" "}
            </span>{" "}
            <span
              className="shopping-cart-label-tag myb-shopping-cart-title"
              onClick={() => this.setState({ showFareRule: true })}
            >
              <Translation Key="fare_rules" />
            </span>{" "}
            <span>
              <Translation Key="and" />
            </span>{" "}
            <span
              className="shopping-cart-label-tag"
              onClick={() => this.setState({ showBaggageInformation: true })}
            >
              <Translation Key="baggage_info" />
            </span>
          </p>
        </Card>
      </section>
    );
  };

  getSeatTotal = () => {
    var totalSeatPrice =
      this.props.responseData.priceBreakdown.subElements.filter(
        (s) => s.label === "seatsPrice"
      )[0];
    if (totalSeatPrice.subElements === null) {
      return null;
    }
    return (
      <span>
        {this.numberWithCommas(totalSeatPrice.price.alternatives[0][0].amount)}{" "}
        {totalSeatPrice.price.alternatives[0][0].currency}
      </span>
    );
  };
  getSeatPrice = (passengerId, segmentId) => {
    var paidSeat = this.props.responseData.priceBreakdown.subElements.filter(
      (s) => s.label === "seatsPrice"
    )[0];
      console.log("paidSeat",paidSeat);
    var tempCurrentSeat = paidSeat.subElements.filter(subElement => subElement.breakdownElementAssignment.passenger["@ref"] === passengerId && subElement.breakdownElementAssignment.travelPart["@ref"] === segmentId)[0];
    console.log("tempCurrentSeat", tempCurrentSeat);
  
    return (
      <span>
        <span className="price-seat-indicator pr-1">
        {this.numberWithCommas(tempCurrentSeat.price.alternatives[0][0].amount)}
        </span>
        <span className="seat-currency-indicator">
        {tempCurrentSeat.price.alternatives[0][0].currency}
        </span>
      </span>
    );

  }
  getSeatForPassenger = (passengerId, segmentId) => {
    var currentPassenger = this.props.responseData.travelPartsAdditionalDetails
      .filter(
        (travelPartsAdditionalDetail) =>
          travelPartsAdditionalDetail.travelPart["@ref"] === segmentId
      )[0]
      .passengers.filter(
        (passenger) => passenger.passenger["@ref"] === passengerId
      )[0];
    return (
      <>
        <span className="pl-2 align-items-center d-flex">
          {currentPassenger.seat !== null && currentPassenger.seat !== undefined
            ? "Seat " + currentPassenger.seat.seatCode
            : " -- "}
            
        </span>
        {currentPassenger.seat !== null && currentPassenger.seat !== undefined ? 
        <>
        {currentPassenger.seat.description === null || currentPassenger.seat.description === undefined ? 
        <span className="d-flex price-free-seat-indicator ml-auto align-items-center">Free</span> : 
        <span className="d-flex seat-price-indicator ml-auto align-items-center">{this.getSeatPrice(passengerId, segmentId)}</span>
        }
        </>
         : null}
        
      </>
    );
  };
  getSeatBody = () => {
    return this.props.responseData.itinerary.itineraryParts.map(
      (itineraryPart) => {
        return itineraryPart.segments.map((segment) => {
          return (
            <>
              <div className="row py-2 shopping-cart-internal-header mx-0 px-3 mt-2 mb-1">
                <span>Flight : </span>
                <span>
                  {" " + segment.origin + " - " + segment.destination}
                </span>
              </div>
              <Row className="mx-0">
                {this.props.responseData.passengers.map((passenger) => {
                  return (
                    <>
                      {console.log(passenger)}
                      <Col xs="12" className="my-1 d-flex">
                        <OverlayTrigger
                          key={passenger["@id"]}
                          placement="left"
                          overlay={
                            <Tooltip id={"tooltip-" + passenger["@id"]}>
                              <span className="text-uppercase p-2">
                                {passenger.passengerDetails.firstName +
                                  " " +
                                  passenger.passengerDetails.lastName}
                              </span>
                            </Tooltip>
                          }
                        >
                          <span
                            className={
                              passenger.passengerInfo.type === "INF"
                                ? "passanger-identifier-infant-shopping d-inline-flex"
                                : "passanger-identifier-shopping d-inline-flex"
                            }
                          >
                            {/* {console.log(this.passengerSeatMaps)} */}
                            {passenger.passengerDetails.firstName[0] +
                              passenger.passengerDetails.lastName[0]}
                          </span>
                        </OverlayTrigger>
                        {this.getSeatForPassenger(
                          passenger["@id"],
                          segment["@id"]
                        )}
                      </Col>
                    </>
                  );
                })}
              </Row>
            </>
          );
        });
      }
    );
  };

  render() {
    if (this.props.isMedium) {
      return (
        <>{this.props.showShoppingCart && <>{this.shoppingCartBody()}</>}</>
      );
    } else {
      return <>{this.shoppingCartBody()}</>;
    }
  }
}
const mapStateTopProps = (state) => {
  return {
    responseData: state.manageMyBooking.responeData.pnr,
    fareRulesResult: state.manageMyBooking.responeData.fareRulesResult,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveFareRule: (response) =>
      dispatch({ type: shoppingCartAction.SAVEFARERULE, fareRule: response }),
    saveBaggageInfo: (response) =>
      dispatch({
        type: shoppingCartAction.SAVEBAGGAGEINFO,
        baggageInfo: response,
      }),
    seatSelectingUpdate: (value) =>
      dispatch({
        type: seatSelectionAction.SEATSELECTING,
        value: value,
      }),
    removeSelectedSeat: (identifier) => {
      dispatch({
        type: seatSelectionAction.REMOVESEATSELECTION,
        identifier: identifier,
      });
    },
    removeFromCart: (
      ancillaryOperationResults,
      baggageRequest,
      price,
      id,
      assignedQuantity
    ) =>
      dispatch({
        type: ancillaryAction.REMOVEFROMCART,
        ancillaryOperationResults: ancillaryOperationResults,
        baggageRequest: baggageRequest,
        price: price,
        id: id,
        assignedQuantity: assignedQuantity,
      }),
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(GlobalTripShoppingCart);
