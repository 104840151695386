// import { FlightSelectionService } from "../../../service/booking/flight-selection/FlightSelectionService"
import { flightSelectionAction } from "../../actions/booking/flightSelectionAction"

const intialState ={
    flightIndex:0,
    selectedItineraryParts:[]
}

const flightSelectionReducer=(state=intialState,action)=>{
    switch(action.type)
    {
        case flightSelectionAction.UPDATEFLIGHTINDEX:
            let flightIndex = action.flightIndex;
          return {flightIndex:flightIndex};
       
        case flightSelectionAction.SAVESELECTEDITINERARYPARTS:
            let selectedItineraryParts= action.selectedItineraryParts;  
            return {selectedItineraryParts:selectedItineraryParts};

          default:
              return state;
    }

}
export default flightSelectionReducer;
