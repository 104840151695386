import React, { Component } from "react";
import { ListGroup } from "react-bootstrap";
import Translation from "i18next";
import { globalActionType } from "../../../redux/actions/shared/globalAction";

import "./Header.css";
import { connect } from "react-redux";
class LanguageSelector extends Component {
  availableLangs = [
    { key: "en", dir: "ltr", displayName: "English (GB)" },
    {
      key: "zh",
      dir: "ltr",
      displayName: "한국어",
    },
    {
      key: "ar",
      dir: "rtl",
      displayName: "العربية",
    },
    {
      key: "fr",
      dir: "ltr",
      displayName: "Français (FR)",
    },
    {
      key: "es",
      dir: "ltr",
      displayName: "español (ES)",
    },
    {
      key: "pt",
      dir: "ltr",
      displayName: "Português (BR)",
    },
  ];
  hangleOnclick = (event) =>{
    // this.changeLang(event);
    this.props.onLangButtonClicked();
  }

  changeLang = (event) => {
    this.props.updateLocale(event.target.id);
    Translation.changeLanguage(event.target.id);
  };

  render() {
    return (
      <ListGroup className={"lang-selector " + this.props.langSelectorStatus}>
        {this.availableLangs.map((lang) => {
          return (
            <ListGroup.Item className="p-0 list-group-item-custom" key={lang.key}>
              <button
                className="px-4 header-link nav-link header-link-lang"
                id={lang.key}
                data-dir={lang.dir}
                onClick={this.hangleOnclick}  
              >
                {lang.displayName}
              </button>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    locale: state.global.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocale: (value) =>
      dispatch({ type: globalActionType.UPDATELOCALE, locale: value }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(LanguageSelector);
