export class UnionPayInfo{
    constructor()
    {
        this.Amount = null;
        this.Currency= null;
        this.FopCode= null;
        this.Country= null;
        this.FopSubCode=null;
        this.Email= null;
    }
}