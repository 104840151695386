import { CheckInCreditCardRequest } from "../../model/checkIn/CheckInCreditCardRequest";

export class CheckInPaymentService
{
    static mapToRequestObject(creditCardInfo)
    {
        
        let creditCardRequestObject = new CheckInCreditCardRequest();
        creditCardRequestObject.requestObj.CreditCard.NameOnCard =creditCardInfo.FullName;
        creditCardRequestObject.requestObj.CreditCard.TypeCode =creditCardInfo.CreditCardType;
        creditCardRequestObject.requestObj.CreditCard.Number =creditCardInfo.CreditCardNumber;
        creditCardRequestObject.requestObj.CreditCard.ExpirationDetails =creditCardInfo.ExpireMonth+"/"+creditCardInfo.ExpireYear;
        creditCardRequestObject.requestObj.AuthorizationDetails.SecurityCode =creditCardInfo._CVC;
        creditCardRequestObject.requestObj.AuthorizationDetails.Address.Street1 =creditCardInfo.BillingAddess;
        creditCardRequestObject.requestObj.AuthorizationDetails.Address.PostalCode =creditCardInfo.PostalCode;
        creditCardRequestObject.requestObj.AuthorizationDetails.Address.Country =creditCardInfo.Country;
        creditCardRequestObject.requestObj.AuthorizationDetails.Address.City =creditCardInfo.City;
        creditCardRequestObject.requestObj.AuthorizationDetails.Email =creditCardInfo.Email;
        creditCardRequestObject.requestObj.AuthorizationDetails.Phone.Number =creditCardInfo.PhoneNumber.substring(creditCardInfo.PhoneCountryCode.length);
         return  creditCardRequestObject.requestObj;
    }
    static constructAirExtrasId(passengersAirExtras)
    {
        
      let airExtrasId =[];
      passengersAirExtras.forEach(passenger=>{
      passenger.passengerSegments.passengerSegment.forEach((airExtras)=>{
        airExtras.airExtra.forEach(airExtraInfo=>{
            if(airExtraInfo.paymentStatus.value === "PENDING")
            {
                if(!airExtrasId.includes(airExtraInfo.paymentStatus.value))
                {
                    airExtrasId.push(airExtraInfo.id);
                }
            }
        })
      })
      })
      return airExtrasId;
    }
}