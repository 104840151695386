import React from "react";
import { connect } from "react-redux";
import Translation from "i18next";

const UserName =(props)=>{
    return (
      <div className="justify-content-center d-flex user-name-box ">
      <span className="user-name-text align-self-center d-flex">
        {props.firstName[0] + props.lastName[0]}
      </span>
    </div>
    );
}
const mapStateTopProps = (state) => {
    return {
      firstName: state.account.profile.userDetails.firstName,
      lastName: state.account.profile.userDetails.lastName,
    };
  };

  export default connect(mapStateTopProps, null)(UserName);