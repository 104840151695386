export class CheckInModel {
  pnr = "";
  firstName = "";
  lastName = "";
  searchType = {
    label: "Flight Number",
    value: "Flight Number",
  };
  flightNumber = "";
  DepartureAirport = "";
  arrivingCity = "";
  ticketNumber = "";
}