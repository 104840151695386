import ItineraryPart from "../../../model/itineraryPart/ItineraryPart";
import Validation from "../../../model/itineraryPart/validation";
import Passenger from "../../../model/passenger/passenger";
import RecentSearch from "../../../model/recentSearch/RecentSearch";
import CabinClassService from "../cabin-class/CabinClassService";
import { AirportFullName } from "../../shared/AirportFullName";

export default class BookingWidgetService {
    validateWidget = (state, domain, flightIndex) => {
        const newItineraryArray = [...state.itineraryPart];
        const newValidationArray = [...state.validation];
        var isFormValid = true;
        if (domain === "all") {
            newItineraryArray.forEach((itinerary, index) => {
                if (
                    (state.journyType === "oneWay" || state.journyType === "roundTrip") &&
                    index !== 0
                ) {
                    return true;
                } else {
                    if (itinerary.params.from === null) {
                        newValidationArray[index].params.isFromValid = false;
                        isFormValid = false;
                    }
                    if (itinerary.params.to === null) {
                        newValidationArray[index].params.isToValid = false;
                        isFormValid = false;
                    }
                    if (itinerary.params.when.departureDate === null) {
                        newValidationArray[index].params.isDeparutreDateValid = false;
                        isFormValid = false;
                    }
                    if (state.journyType === "roundTrip") {
                        if (itinerary.params.when.arrivalDate === null && index === 0) {
                            newValidationArray[index].params.isArrivalDate = false;
                            isFormValid = false;
                        }
                    }
                }
            });
        } else if (domain === "toFocus") {
            newValidationArray[flightIndex].params.isToValid = true;
        } else if (domain === "toBlur") {
            if (newItineraryArray[flightIndex].params.to === null) {
                newValidationArray[flightIndex].params.isToValid = false;
            }
        } else if (domain === "fromFocus") {
            newValidationArray[flightIndex].params.isFromValid = true;
        } else if (domain === "fromBlur") {
            if (newItineraryArray[flightIndex].params.from === null) {
                newValidationArray[flightIndex].params.isFromValid = false;
            }
        } else if (domain === "deparutreDate") {
            newValidationArray[flightIndex].params.isDeparutreDateValid = true;
        } else if (domain === "arrivalDate") {
            newValidationArray[flightIndex].params.isArrivalDate = true;
        } else if (domain === "deparutreDateInvalid") {
            newValidationArray[flightIndex].params.isDeparutreDateValid = false;
        } else if (domain === "arrivalDateInvalid") {
            newValidationArray[flightIndex].params.isArrivalDate = false;
        }

        return {
            itinerary: newItineraryArray,
            validation: newValidationArray,
            isFormValid: isFormValid,
        };
    };
    recentSearch = (recentSearch, journeyType) => {
        var itineraryList = [];
        var passengerInfo = new Passenger();
        var itinerary = new ItineraryPart();
        if (journeyType === "multicity") {
            recentSearch.itineraryParts.forEach((itineraryElement) => {
                itinerary = new ItineraryPart();
                itinerary.params.from = {
                    value: itineraryElement.from,
                    label: AirportFullName.getFullName(itineraryElement.from),
                };
                itinerary.params.to = {
                    value: itineraryElement.to,
                    label: AirportFullName.getFullName(itineraryElement.to),
                };
                itinerary.params.when.departureDate =
                    itineraryElement.when.departureDate;
                itineraryList.push(itinerary);
            });
        }
        if (journeyType === "stopover") {
            recentSearch.itineraryParts.forEach((itineraryElement) => {
                itinerary = new ItineraryPart();
                itinerary.params.from = {
                    value: itineraryElement.from,
                    label: AirportFullName.getFullName(itineraryElement.from),
                };
                itinerary.params.to = {
                    value: itineraryElement.to,
                    label: AirportFullName.getFullName(itineraryElement.to),
                };
                itinerary.params.when.departureDate =
                    itineraryElement.when.departureDate;
                itinerary.params.stopDays = itineraryElement.stopDays;
                itineraryList.push(itinerary);
            });
        } else if (journeyType === "oneWay") {
            itinerary = new ItineraryPart();
            itinerary.params.from = {
                value: recentSearch.itineraryParts[0].from,
                label: AirportFullName.getFullName(recentSearch.itineraryParts[0].from),
            };
            itinerary.params.to = {
                value: recentSearch.itineraryParts[0].to,
                label: AirportFullName.getFullName(recentSearch.itineraryParts[0].to),
            };
            itinerary.params.when.departureDate =
                recentSearch.itineraryParts[0].when.departureDate;
            itineraryList.push(itinerary);
        } else if (journeyType === "roundTrip") {
            itinerary = new ItineraryPart();
            itinerary.params.from = {
                value: recentSearch.itineraryParts[0].from,
                label: AirportFullName.getFullName(recentSearch.itineraryParts[0].from),
            };
            itinerary.params.to = {
                value: recentSearch.itineraryParts[0].to,
                label: AirportFullName.getFullName(recentSearch.itineraryParts[0].to),
            };
            itinerary.params.when.departureDate =
                recentSearch.itineraryParts[0].when.departureDate;
            itinerary.params.when.arrivalDate =
                recentSearch.itineraryParts[0].when.arrivalDate;

            itineraryList.push(itinerary);
        }
        passengerInfo.adult = recentSearch.passengers.adult;
        passengerInfo.child = recentSearch.passengers.child;
        passengerInfo.infant = recentSearch.passengers.infant;
        var promoCode = recentSearch.promoCodes;
        var cabinClassService = new CabinClassService();
        var cabinClass = {
            value: recentSearch.cabinClass,
            label: cabinClassService.getCabinClassByCode(recentSearch.cabinClass),
        };
        return {
            itinerary: itineraryList,
            passenger: passengerInfo,
            validation: [
                new Validation(),
                new Validation(),
                new Validation(),
                new Validation(),
                new Validation(),
                new Validation(),
            ],
            cabinClass: cabinClass,
            promoCode: promoCode,
        };
    };

    updateRecentSearch = (state, action) => {
        var currentRecentSearch = new RecentSearch().params;
        const oldRecentSearch = [...state.recentSearchList];

        var id =
            action.newValue.itineraryPart[0].params.from.value +
            "-" +
            action.newValue.itineraryPart[0].params.to.value +
            "-" +
            action.newValue.cabinClass.value +
            "-" +
            action.newValue.journyType +
            "ADT" +
            action.newValue.passengerInfo.adult +
            "CHD" +
            action.newValue.passengerInfo.child +
            "INF" +
            action.newValue.passengerInfo.infant;

        var isExist = oldRecentSearch.filter((i) => i.id === id);
        if (isExist.length === 0) {
            if (oldRecentSearch.length === 5) {
                oldRecentSearch.pop();
                currentRecentSearch.promoCodes = action.newValue.promoCode;
                currentRecentSearch.cabinClass = action.newValue.cabinClass.value;
                currentRecentSearch.passengers = action.newValue.passengerInfo;
                currentRecentSearch.journeyType = action.newValue.journyType;
                currentRecentSearch.id = id;
                currentRecentSearch.itineraryParts = [];
                action.newValue.itineraryPart.forEach((itinerary, index) => {
                    if (
                        (currentRecentSearch.journeyType === "oneWay" ||
                            currentRecentSearch.journeyType === "roundTrip") &&
                        index !== 0
                    ) {
                        return true;
                    } else {
                        var newItinerary = new ItineraryPart().params;
                        newItinerary.from = itinerary.params.from.value;
                        newItinerary.to = itinerary.params.to.value;
                        newItinerary.when.departureDate =
                            itinerary.params.when.departureDate;
                        newItinerary.when.arrivalDate = itinerary.params.when.arrivalDate;
                        newItinerary.stopDays = itinerary.params.stopDays;
                        currentRecentSearch.itineraryParts.push(newItinerary);
                    }
                });
                oldRecentSearch.unshift(currentRecentSearch);
            } else {
                currentRecentSearch.promoCodes = action.newValue.promoCode;
                currentRecentSearch.cabinClass = action.newValue.cabinClass.value;
                currentRecentSearch.passengers = action.newValue.passengerInfo;
                currentRecentSearch.journeyType = action.newValue.journyType;
                currentRecentSearch.id = id;
                currentRecentSearch.itineraryParts = [];
                action.newValue.itineraryPart.forEach((itinerary) => {
                    var newItinerary = new ItineraryPart().params;
                    newItinerary.from = itinerary.params.from.value;
                    newItinerary.to = itinerary.params.to.value;
                    newItinerary.when.departureDate = itinerary.params.when.departureDate;
                    newItinerary.when.arrivalDate = itinerary.params.when.arrivalDate;
                    newItinerary.stopDays = itinerary.params.stopDays;
                    currentRecentSearch.itineraryParts.push(newItinerary);
                });
                oldRecentSearch.unshift(currentRecentSearch);
            }
        }

        return oldRecentSearch;
    };
}
