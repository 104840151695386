import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import Translation from "i18next";
import { connect } from "react-redux";
import Select from "react-select";
import $ from 'jquery';
import SubmitPayment from '../../SubmitPayment';
import { FloCashInfo } from '../../../../../model/payment/FloCashInfo';
import HttpService from '../../../../../service/shared/HttpService';
import { paymentAction } from '../../../../../redux/actions/booking/paymentAction';
import { PaymentService } from '../../../../../service/booking/payment/PaymentService';
import Loading from '../../../seat-selection/Loading';
import { FloCashValidatorService } from '../../../../../service/booking/payment/FlocashValidatorService';
import AirportSelectStyle from "../../../../../master-data/booking-widget/airpot-select-style";
import { Redirect } from 'react-router-dom';

class FloCashPaymentOptions extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            redirect: false,
            floCashRedirect: null,
            floCashInfoState: new FloCashInfo(),
            floCashValidatorState:new FloCashValidatorService(),
            paymentId: "0",
            showPaymentForm:false
        }
        this.selectStyle = AirportSelectStyle.desktopStyle;
        this.floCashInfo = new FloCashInfo();
        this.floCashValidator = new FloCashValidatorService();
    }
    componentDidMount() {
            }
    onBankSelected = (index, fopSubCode) => {
        
            $(".flocash-payment-option-col").css("background", "#fff");
        $(".flocash-payment-option-col" + index).css("background", "#ddd");
        this.setState({ paymentId: fopSubCode, showPaymentForm: true });
        window.scrollTo({ top: 500, behavior: "smooth" });

    }
    handleSubmit = (event) => {
        event.preventDefault();
                this.setState({ floCashValidatorState: this.state.floCashValidatorState.validateFloCashInfo(this.floCashInfo).floCashValidation });
        this.floCashValidator = this.state.floCashValidatorState.validateFloCashInfo(this.floCashInfo).floCashValidation;
        let floCashValidLists = this.state.floCashValidatorState.validateFloCashInfo(this.floCashInfo).floCashValidLists;
        let isFloCashInfoValid = !floCashValidLists.includes(false);
        if (isFloCashInfoValid) {
            this.floCashInfo.Amount = this.props.amount.amount;
            this.floCashInfo.Currency = this.props.amount.currency;
           
            let floCashRequestObj = PaymentService.mapToFlocashRequestObject(
                this.floCashInfo
            );
            floCashRequestObj.PaymentID = parseInt(this.state.paymentId);
            floCashRequestObj.cookieSabreDataRequest = this.props.searchFlight.searchResult.data.cookieSabreDataRequest;
            this.setState({ isLoading: true });
            HttpService.postServiceFloCash(
                floCashRequestObj,
                "/flocash-etibe/V1.0/Payment/Purchase",
                this.props.searchFlight.token
            ).then((response) => {
                

                this.setState({ isLoading: false,redirect:true, floCashRedirect: response.data.order.instruction });

            });
        }
    }
    onInputKeyDown = (floCashValidator) => {
        
        this.setState({ floCashValidatorState: floCashValidator });
    }
render()
{
    if (this.state.redirect) {
        return (
            <Redirect
                to={{
                    pathname: "/flocash-redirect",
                    state: { "redirectToPath": this.state.floCashRedirect }
                    //type: "hold"
                }}
            />
        );
    }
    return (
        <div className="container bg-white pt-4 pb-4 flocash-payment">
            { this.state.isLoading ? <Loading /> : null}
            <div className="py-3">
                <h3>
                    {Translation.t("select_bank")}               
                    </h3>
      
            </div>
    <Row>
        {
            this.props.flocashOptions.map((afop,index)=>
                <Col xs={6} sm={6} md={4} lg={3}>
                    <div className={"flocash-payment-option-col " + "flocash-payment-option-col" + index} onClick={() => this.onBankSelected(index, afop.fopSubCode)}>
               <div className="payment-option-bg" style={{background:`url("${afop.logoURI}")`}}></div>
                <div className="pt-2">{afop.paymentName}</div>
            </div>
            </Col>)
        
        } 
            </Row>
            {this.state.showPaymentForm &&
                <div className="py-4">
                <div className="py-3">
                    <h3>
                        {Translation.t("flo_cash")}
                    </h3>
                    <div>
                        {Translation.t("flo_cash_required_fields_remark")}
                    </div>
                </div>
                <Form onSubmit={this.handleSubmit}>
                <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="my-2">
                            <Form.Control
                                value={this.state.floCashInfoState.FirstName}
                                onChange={(event) =>
                                    (this.floCashInfo.FirstName = event.target.value)
                                }
                                placeholder={this.state.floCashValidatorState.isFirstNameValid ? Translation.t("first_name") : Translation.t("first_name_required")}
                                className={this.state.floCashValidatorState.isFirstNameValid ? "input-field" : "invalid-input-field"}
                                onKeyDown={() => { this.floCashValidator.isFirstNameValid = true; this.onInputKeyDown(this.floCashValidator) }}
                            />
                    </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="my-2">
                            <Form.Control
                                value={this.state.floCashInfoState.LastName}
                                onChange={(event) =>
                                    (this.floCashInfo.LastName = event.target.value)
                                }
                                placeholder={this.state.floCashValidatorState.isLastNameValid ? Translation.t("last_name") : Translation.t("last_name_required")}
                                className={this.state.floCashValidatorState.isLastNameValid ? "input-field" : "invalid-input-field"}
                                onKeyDown={() => { this.floCashValidator.isLastNameValid = true; this.onInputKeyDown(this.floCashValidator) }}
                            />
                        
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="my-2">
                            <Form.Control
                                type="email"
                                value={this.state.floCashInfoState.Email}
                                onChange={(event) =>
                                    (this.floCashInfo.Email = event.target.value)
                                }
                                placeholder={this.state.floCashValidatorState.isEmailValid ? Translation.t("email_address") : Translation.t("email_address_required")}
                                className={this.state.floCashValidatorState.isEmailValid ? "input-field" : "invalid-input-field"}
                                onKeyDown={() => { this.floCashValidator.isEmailValid = true; this.onInputKeyDown(this.floCashValidator) }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="my-2">
                            <PhoneInput
                                country={"et"}
                                value=""
                                onChange={(event, country) => {
                                    this.floCashInfo.PhoneNumber = event;
                                    this.floCashInfo.PhoneCountryCode = country.dialCode == undefined ? this.floCashInfo.PhoneCountryCode : country.dialCode;
                                }}

                                inputProps={{ "class": this.state.floCashValidatorState.isPhoneNumberValid ? "input-field form-control" : "form-control invalid-input-field" } }
                                onKeyDown={() => { this.floCashValidator.isPhoneNumberValid = true; this.onInputKeyDown(this.floCashValidator) }}
                            />
                        </Col>
                        
                        <Col xs={12} sm={12} md={6} lg={6} className="my-2">
                            <Form.Label>{Translation.t("country")}</Form.Label>
                            <Select
                                options={PaymentService.getCountryList(this.props.countryList)}
                                styles={this.selectStyle}
                                placeholder={this.state.floCashValidatorState.isCountryValid ? Translation.t("select_country") : Translation.t("country_required")}
                                className={this.state.floCashValidatorState.isCountryValid ? "input-field" : "invalid-input-field"}
                                onKeyDown={() => { this.floCashValidator.isCountryValid = true; this.onInputKeyDown(this.floCashValidator) }}
                                onChange={(event) => { this.floCashInfo.Country = event.value; this.floCashValidator.isCountryValid = true; this.onInputKeyDown(this.floCashValidator) }}

                            />
                        </Col>
                        <Col xs={12} sm={12}>
                            <div className="py-4">
                                <SubmitPayment />
                            </div>
                        </Col>
                </Row>
                </Form>
                </div>
            }
    </div>);
}
}

const mapStateToProps = (state) => {
    return {
        searchFlight: state.searchFlight,
        countryList: state.payment.countryList,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        saveCountryList: (countryList) => {
            dispatch({
                action: paymentAction.SAVECOUNTRYLIST,
                data: countryList,
            });
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(FloCashPaymentOptions);