// import { filteredFlightsActions } from "../../../service/booking/flight-selection/FlightSelectionService"
import { filteredFlightsActions } from "../../actions/booking/filteredFlightsActions";

const intialState = {
  backedUpFlights: [],
  FilterCriteria : [],
  countSortUpdate : 0
};

const filteredFlightsReducer = (state = intialState, action) => {
  switch (action.type) {
    case filteredFlightsActions.FILTERUPDATE:
      return state;
    case filteredFlightsActions.UPDATESORTOPTIONS:
      return {...state,
        FilterCriteria : action.filterCriteria,
      };
    case filteredFlightsActions.UPDATECOUNTSORTUPDATE:
      return {
        ...state,
        countSortUpdate : state.countSortUpdate + 1  
      }
    default:
      return state;
  }
};
export default filteredFlightsReducer;
