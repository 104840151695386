import { manageMyBookingAction } from "../../actions/manage-my-booking/manageMyBookingAction";

const initialState = {
    formInput:{
      pnr:null,
      lastName:null,
    },
    responeData:null,

};
const manageMyBookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case manageMyBookingAction.INITIALIZE:
      var tempFormInput={
        pnr:action.pnr,
        lastName:action.lastName,
      }
      return {
        ...state,
        formInput: tempFormInput,
      };
      case manageMyBookingAction.SAVEDATA:
        return{
          ...state,
          responeData:action.data
        }
       
          
    default:
      return state;
  }
};
export default manageMyBookingReducer;
