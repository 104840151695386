import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faPlane } from "@fortawesome/free-solid-svg-icons";
import "./FlightStatus.css";
import { Button } from "react-bootstrap";

import { AirportFullName } from "../../../service/shared/AirportFullName";
import moment from "moment";

const FlightStatusResult = (props) => {
  const [showStatus, setShowStatus] = useState(false);
  return (
    <div className="search-flight-result mt-3" key={props.identifier}>
      <Row className="mx-0 mb-3">
        <Col md={5}>
          <div className="d-flex justify-content-start ml-3">
            <Row>
              <Col xs={12}>
                <p>
                  {moment(props.flights[0].departureInfo.scheduledDateTime)
                    .locale("en")
                    .format("DD MMMM hh:mm")}{" "}
                  <FontAwesomeIcon icon={faPlane} size="1x" className="mx-2" />{" "}
                  {moment(
                    props.flights[props.flights.length - 1].arrivalInfo
                      .scheduledDateTime
                  )
                    .locale("en")
                    .format("DD MMMM hh:mm")}
                </p>
              </Col>
              <Col xs={12}>
                <img
                  src="//www.gstatic.com/flights/airline_logos/35px/ET.png"
                  alt="Ethiopian Airlines"
                />
                <span className="mx-3 flight-number">
                  {props.airlineCode + " " + props.flightNumber}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className="d-flex justify-content-start">
            <b className="pt-3">{props.flights.length === 1 ? "Non-stop" : props.flights.length - 1 + " Stop" + (props.flights.length > 2 ?  "s" : "")}</b>
          </div>
        </Col>
          <div className="d-flex justify-content-center pr-3">
            <Button
              className="mt-3 bk-submit-btn btn-ethiopian"
              onClick={() => setShowStatus(!showStatus)}
              style={{ maxHeight: "12px"}}
            >
              {(showStatus ? "Hide " : "View ") + Translation.t("status")}
            </Button>
          </div>
      </Row>
      {showStatus && (
        <>
          {props.flights.map((flight, index) => {
            return (
              <>
                {index === 0 ? null : <hr className="my-4"/>}
                <Row className="mx-0">
                  <Col md={5}>
                    <div className="my-3">
                      <h6>
                        <b>{Translation.t("flight_#") + props.flightNumber}</b>
                      </h6>
                      <h6>
                        <b>
                          {AirportFullName.getFullName(
                            flight.departureInfo.airportCode
                          )}
                          <FontAwesomeIcon
                            icon={faPlane}
                            size="1x"
                            className="mx-2"
                            color="#2e7d32"
                          />{" "}
                          {AirportFullName.getFullName(
                            flight.arrivalInfo.airportCode
                          )}
                        </b>
                      </h6>
                    </div>
                  </Col>

                  <Col md={3} className="my-3 my-md-0">
                    <div className="mb-3 text-center">
                      <span className={"flight-result-status flight-status-" + flight.departureInfo.flightStatus}>
                        <FontAwesomeIcon icon={faClock} className="mx-2" />
                        {Translation.t(flight.departureInfo.flightStatus)}
                      </span>
                    </div>
                    <h5 className="text-center">
                      <b>{Translation.t("depart")}</b>
                    </h5>
                    <p className="text-center">
                    {Translation.t("scheduled")}{" "}
                      {moment(flight.departureInfo.scheduledDateTime)
                        .locale("en")
                        .format("hh:mm A")}
                    </p>
                    <p className="text-center">{Translation.t("terminal_gate")}</p>
                    <p className="text-center">
                      {flight.departureInfo.terminal === null
                        ? "-"
                        : flight.departureInfo.terminal}
                      /
                      {flight.departureInfo.gate === null
                        ? "-"
                        : flight.departureInfo.gate}
                    </p>
                  </Col>

                  <Col md={3} className="my-3 my-md-0">
                    <div className="mb-3 text-center">
                      <span className={"flight-result-status flight-status-" + flight.arrivalInfo.flightStatus}>
                        <FontAwesomeIcon icon={faClock} className="mx-2" />
                        {Translation.t(flight.arrivalInfo.flightStatus)}
                      </span>
                    </div>
                    <h5 className="text-center">
                      <b>{Translation.t("arrive")}</b>
                    </h5>
                    <p className="text-center">{Translation.t("scheduled")} {" "}
                      {moment(flight.arrivalInfo.scheduledDateTime)
                        .locale("en")
                        .format("hh:mm A")}</p>
                    <p className="text-center">{Translation.t("terminal_gate")}</p>
                    <p className="text-center">{flight.arrivalInfo.terminal === null
                      ? "-"
                      : flight.arrivalInfo.terminal}
                    /
                    {flight.arrivalInfo.gate === null
                      ? "-"
                      : flight.arrivalInfo.gate}</p>
                  </Col>
                </Row>
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default FlightStatusResult;
