import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import Translation from "i18next";
import PaymentSummaryAction from "../payment-summary/PaymentSummaryAction";
import PaymentSummaryHeader from "../payment-summary/PaymentSummaryHeader";
import PaymentSummaryPassenger from "../payment-summary/PaymentSummaryPassenger";
import PaymentSummaryTripDetail from "../payment-summary/PaymentSummaryTripDetail";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const TeleBirrPaymentSummary = (props) => {
  const getCityName = (airport) => {
    return AirportFullName.getCityName(airport);
  };
  return (
    <section className="container">
      <section>
        <div className="payment-summary-header">
          <h2>
            <span className="pr-3 check-icon">
              <FontAwesomeIcon icon={faCheckCircle} />
            </span>

            <span>
              {Translation.t("your_trip_is_booked_success_code")}{" "}
              <span>{props.reservationDetail.pnr.reloc}</span>.{" "}
              {Translation.t("trip_summary_information")}
            </span>
          </h2>
        </div>
      </section>
      <section className="payment-summary-confirmation-header">
        <Row>
          <Col xs={12} sm={12} md={4} lg={3}>
            <div>
              <div> {Translation.t("confirmation_code")}</div>
              <h3>
                <mark>{props.reservationDetail.pnr.reloc}</mark>
              </h3>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="payment-summary-itinerary pt-3">
              <span>
                {getCityName(
                  props.reservationDetail.pnr.itinerary.itineraryParts[0]
                    .segments[0].origin
                )}
              </span>
              <span className="px-3"></span>
              <span>
                {getCityName(
                  props.reservationDetail.pnr.itinerary.itineraryParts[0]
                    .segments[
                    props.reservationDetail.pnr.itinerary.itineraryParts[0]
                      .segments.length - 1
                  ].destination
                )}
              </span>
            </div>
          </Col>
          {/* <Col xs={12} sm={12} md={8} lg={12}>
            <PaymentSummaryAction
              itineraryParts={
                props.reservationDetail.pnr.itinerary.itineraryParts
              }
              state={props.reservationDetail}
            />
          </Col> */}
        </Row>
      </section>
      <PaymentSummaryTripDetail
        tripDetail={props.reservationDetail.pnr.itinerary.itineraryParts}
      />
      <PaymentSummaryPassenger
        passengerList={props.reservationDetail.pnr.passengers}
        contactInfo={props.reservationDetail.pnr.contact}
        passengerTicketInfo={
          props.reservationDetail.pnr.travelPartsAdditionalDetails.length > 0
            ? props.reservationDetail.pnr.travelPartsAdditionalDetails[0]
                .passengers
            : []
        }
        tripDetail={props.reservationDetail.pnr.itinerary.itineraryParts}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    reservationDetail: state.payment.reservationDetail,
  };
};
export default connect(mapStateToProps, null)(TeleBirrPaymentSummary);
