import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import Translation from "i18next";
import { connect } from "react-redux";
import Notification from "../../booking/booking-widget/Notification";
import ExchangePassenger from "./ExchangePassenger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ExchangeAllPassengers = (props) => {
  return (
      <>
      <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
        <div className="col-12 col-xl-7 mx-auto mx-xl-0">
          <div className="mt-1 mx-auto mr-xl-0">
            <div className="">
              <div className="mx-3">
                <h3>Passenger Information</h3>
                <Notification
                  header="Please Notice"
                  body="If you would like to change passengers details please finish exchange process and use Edit Pax details option"
                  info={true}
                />
              </div>
            </div>

            {props.exchangePassengers.map((passenger, index) => {
                try {
                  return (
                    <ExchangePassenger
                      key={index}
                      index={index}
                      passenger={passenger}
                    />
                  );
                } catch (error) {
                  console.log(error);
                  return <></>;
                }
              })}
            <Row className="mt-4">
              <Col sm={12} md={6}></Col>
              <Col sm={12} md={6}>
                <span className="float-right">
                  <Button
                    variant="danger"
                    className="bk-submit-btn btn-ethiopian mx-2"
                    onClick={() => this.setState({ backPage: true })}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <Button
                    variant="danger"
                    className="bk-submit-btn btn-ethiopian mx-2"
                    onClick={() => this.continue()}
                  >
                    {Translation.t("continue")}
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
        </div>
        <div className="col-12 col-xl-3"></div>
      </Row>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    exchangePassengers: state.exchange.exchangePassengers,
  };
};
// const mapDispatchToProps = (dispacth) => {
//   return {
//     changeSelectValidation: (valueParameter, validationParameter, domain) =>
//       dispacth({
//         type: accountAction.CHANGEVALIDATION,
//         domain: domain,
//         valueParameter: valueParameter,
//         validationParameter: validationParameter,
//       }),
//     updateAccountModel: (value, valueParameter) =>
//       dispacth({
//         type: accountAction.UPADTE,
//         value: value,
//         valueParameter: valueParameter,
//       }),
//     validateAccountModel: () => {
//       dispacth({
//         type: accountAction.VALIDATECREATEACCOUNT,
//       });
//     },
//     updateGender: (value) => {
//       dispacth({
//         type: accountAction.UPDATEGENDER,
//         value: value,
//       });
//     },
//   };
// };
export default connect(mapStateTopProps, null)(ExchangeAllPassengers);
