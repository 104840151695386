import { React, Component } from "react";
// import { Breadcrumb } from "react-bootstrap";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import { Row, Modal, Tabs, Tab } from "react-bootstrap";
import Seats from "./seats/Seats";
import ShoppingCart from "../../shared/shoppingCart/ShoppingCart";
import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";
import { connect } from "react-redux";
import Loading from "./Loading";
import Translation from "i18next";
import "./SeatSelection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { SeatSelectionService } from "../../../service/booking/seat-selection/SeatSelectionService";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import { Redirect } from "react-router";

class SeatSelection extends Component {
  passengerSeatMaps = "";
  state = {
    isLoading: true,
    redirect: false,
    IsContinueModalOpen: false,
    possibleSeatSelectionSegment: 0,
    countAdultOrChildPassenger: 0,
    returnToBack: false,
    legendToggler: true,
  };

  initSeatSeleciton = () => {
    let seatSelectionService = new SeatSelectionService();
    seatSelectionService
      .getSeatMap(this.props.cookieSabreDataRequest, this.props.token)
      .then((response) => {
        if (response.data.statusAPI === "SUCCESS") {
          this.passengerSeatMaps =
            response.data.segmentSeatMaps[0].passengerSeatMaps;

          this.props.storeSeatMaps(response.data.segmentSeatMaps);
          const counter = response.data.segmentSeatMaps.filter(
            (segmentSeatMap) =>
              segmentSeatMap.seatMap !== null &&
              segmentSeatMap.seatMap.cabins[0].seatRows.length !== 0
          ).length;
          const countAdultOrChildPassenger =
            response.data.segmentSeatMaps[0].passengerSeatMaps.filter(
              (passengerSeatMap) =>
                passengerSeatMap.passenger.passengerInfo.type === "ADT" ||
                passengerSeatMap.passenger.passengerInfo.type === "CHD"
            ).length;

          this.setState({
            isLoading: false,
            possibleSeatSelectionSegment: counter,
            countAdultOrChildPassenger: countAdultOrChildPassenger,
            activeTab: response.data.segmentSeatMaps[0].seatMap["@id"],
          });
        } else {
          debugger;
          this.props.storeSeatMaps([]);
          this.setState({
            isLoading: false,
            possibleSeatSelectionSegment: 0,
            countAdultOrChildPassenger: 0,
            activeTab: null,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  componentDidMount() {
    this.initSeatSeleciton();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  changeActivePassangerCode() {}

  handleContinueClick = () => {
    if (this.state.possibleSeatSelectionSegment === 0) {
      this.setState({ redirect: true });
    } else {
      const selectedSeatCount = Object.keys(this.props.selectedSeats).length;

      if (
        selectedSeatCount ===
        this.state.possibleSeatSelectionSegment +
          this.state.possibleSeatSelectionSegment *
            this.state.countAdultOrChildPassenger
      ) {
        this.setState({ redirect: true });
      } else {
        this.setState({ IsContinueModalOpen: true });
      }
    }
  };

  removeSeat = (
    flightIdentifier,
    passengerIndex,
    seatMapIdentifier,
    departure
  ) => {
    this.props.seatSelectingUpdate(true);
    var seatSelectionService = new SeatSelectionService();
    var payload = {
      departure: departure,
      seatMapIdentifier: seatMapIdentifier,
      seatCode: null,
      passengerIndex: this.props.passengerIndex,
    };

    seatSelectionService
      .selectSeat(this.props.cookieSabreDataRequest, this.props.token, payload)
      .then((response) => {
        this.props.seatSelectingUpdate(false);
        this.props.removeSelectedSeat(flightIdentifier, passengerIndex);
      });
  };

  getModal = () => {
    return (
      <Modal
        show={this.state.IsContinueModalOpen}
        onHide={() => {
          this.setState({
            IsContinueModalOpen: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h2>
            <span>{Translation.t("Proceed")}?</span>
          </h2>
          <p>
            <span data-translation="seatSelection.proceedWithoutSeatSelection.description">
              {Translation.t("message_when_seat_donnot_selected")}
            </span>
          </p>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button
              className="col-12 col-lg-2 btn btn-success mr-lg-2 mb-3 mb-lg-0"
              onClick={() => this.setState({ redirect: true })}
            >
              {Translation.t("continue")}
            </button>
            <button
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
              onClick={() => this.setState({ IsContinueModalOpen: false })}
            >
              {Translation.t("cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  nextFlight = (tabKey) => {
    this.setState({ activeTab: tabKey });

    var firstPassenger = this.props.passangers[0];
    this.props.changeActivePassangerCode(
      firstPassenger.passenger.passengerInfo.type[0] +
        firstPassenger.passenger.passengerIndex,
      firstPassenger.passenger.passengerIndex
    );
  };

  continueToBaggage = () => {
    this.setState({ redirect: true });
  };
  redirectToPassanger() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ returnToBack: true });
  }

  render() {
    if (this.state.redirect) {
      this.props.history.push("/ancillary");
    }
    if (this.state.returnToBack) {
      return (
        <Redirect
          to={{
            pathname: "/passenger",
          }}
        />
      );
    }

    var counter = 1;
    return (
      <>
        {this.props.seatSelecting ? <Loading /> : null}
        {this.state.IsContinueModalOpen ? this.getModal() : null}
        <BreadCrumb history={this.props.history} />
        <Row className="m-0 flex-column-reverse flex-md-row justify-content-center w-100">
          {this.state.isLoading ? (
            <div className="col-12 col-xl-7 mt-4 d-flex justify-content-center">
              {" "}
              <ProgressSimpler />
            </div>
          ) : (
            <div className="col-12 col-xl-7 mt-4">
              <h2>{Translation.t("select_seats")}</h2>
              <p>
                {Translation.t("please_select_the_seats_from_the_seat_map")}
              </p>
              <button
                className="float-right bk-submit-btn btn-ethiopian mx-2 btn seat_next_btn"
                onClick={() => this.handleContinueClick()}
              >
                {Translation.t("next_page")}
              </button>
              <Tabs
                defaultActiveKey={1}
                id="seat-selection-tab"
                activeKey={this.state.activeTab}
                onSelect={(eventKey) => this.setState({ activeTab: eventKey })}
              >
                {this.props.segmentSeatMaps.map((segmentSeatMap) => {
                  return (
                    <Tab
                      key={
                        "seat-map-" +
                        (segmentSeatMap.seatMap === null
                          ? counter
                          : segmentSeatMap.seatMap["@id"])
                      }
                      eventKey={counter}
                      title={
                        <>
                          <span className="flight-index">{counter++}</span>{" "}
                          <span className="flight-overview-on-seat">
                            {AirportFullName.getCityName(
                              segmentSeatMap.segment.origin
                            ) +
                              " " +
                              Translation.t("to") +
                              " " +
                              AirportFullName.getCityName(
                                segmentSeatMap.segment.destination
                              )}
                          </span>
                        </>
                      }
                    >
                      <div className="seat-selection-container">
                        <div className="seat-selection">
                          <div className="row mx-0">
                            <div className="seatmap-header col-12">
                              <h5 className="seatmap-header-title">
                                {AirportFullName.getFullName(
                                  segmentSeatMap.segment.origin
                                ) +
                                  " " +
                                  Translation.t("to") +
                                  " " +
                                  AirportFullName.getFullName(
                                    segmentSeatMap.segment.destination
                                  )}
                              </h5>
                            </div>
                            <div className="seatmap-header col-12">
                              <h6 className="flight-inline-massage">
                                {Translation.t("passanger_selection_message")}
                              </h6>
                            </div>
                          </div>
                          {segmentSeatMap.seatMap === null ||
                          segmentSeatMap.seatMap.cabins[0].seatRows.length ===
                            0 ? (
                            <>
                              <h5 className="seatmap-header col-12">
                                {Translation.t("seat_map_is_not_available")}
                              </h5>
                            </>
                          ) : (
                            <div className="row mx-0 seat-selection-container">
                              <div className="col-12 col-lg-4 left-container">
                                <div className="passangers-title">
                                  <h5>{Translation.t("passengers-label")}</h5>
                                </div>
                                <div className="passanger-lists">
                                  {this.props.segmentSeatMaps[0].passengerSeatMaps.map(
                                    (passengerSeatMap) => {
                                      return (
                                        <div
                                          onClick={() =>
                                            passengerSeatMap.passenger
                                              .passengerInfo.type === "INF"
                                              ? null
                                              : this.props.changeActivePassangerCode(
                                                  passengerSeatMap.passenger
                                                    .passengerInfo.type[0] +
                                                    passengerSeatMap.passenger
                                                      .passengerIndex,
                                                  passengerSeatMap.passenger
                                                    .passengerIndex
                                                )
                                          }
                                          className={
                                            passengerSeatMap.passenger
                                              .passengerIndex ===
                                            this.props.passengerIndex
                                              ? "single-passange row align-items-center active"
                                              : "single-passange row align-items-center"
                                          }
                                          role="button"
                                          tabIndex="1"
                                          disabled={
                                            passengerSeatMap.passenger
                                              .passengerInfo.type === "INF"
                                              ? "disabled"
                                              : ""
                                          }
                                        >
                                          <span
                                            className={
                                              passengerSeatMap.passenger
                                                .passengerInfo.type === "INF"
                                                ? "passanger-identifier-infant d-inline-flex"
                                                : "passanger-identifier d-inline-flex"
                                            }
                                          >
                                            {
                                              this.props
                                                .activePassangerIdentifierCodeMapper[
                                                passengerSeatMap.passenger
                                                  .passengerInfo.type[0] +
                                                  passengerSeatMap.passenger
                                                    .passengerIndex
                                              ]
                                            }
                                          </span>
                                          <span className="passanger-name d-inline-flex">
                                            {passengerSeatMap.passenger
                                              .passengerDetails.firstName +
                                              " " +
                                              passengerSeatMap.passenger
                                                .passengerDetails.lastName}
                                          </span>
                                          {segmentSeatMap.segment.origin +
                                            segmentSeatMap.segment.destination +
                                            passengerSeatMap.passenger
                                              .passengerIndex in
                                          this.props.selectedSeats ? (
                                            <>
                                              <span className="selected-seat-name d-inline-flex">
                                                {
                                                  this.props.selectedSeats[
                                                    segmentSeatMap.segment
                                                      .origin +
                                                      segmentSeatMap.segment
                                                        .destination +
                                                      passengerSeatMap.passenger
                                                        .passengerIndex
                                                  ].seatCode
                                                }
                                              </span>
                                              <button
                                                className="clear-seat-selection d-inline-flex"
                                                onClick={() =>
                                                  this.removeSeat(
                                                    segmentSeatMap.segment
                                                      .origin +
                                                      segmentSeatMap.segment
                                                        .destination +
                                                      passengerSeatMap.passenger
                                                        .passengerIndex,
                                                    segmentSeatMap.segment
                                                      .origin +
                                                      "-" +
                                                      segmentSeatMap.segment
                                                        .destination,
                                                    segmentSeatMap.segment
                                                      .departure
                                                  )
                                                }
                                              >
                                                x
                                              </button>
                                            </>
                                          ) : (
                                            <span>
                                              {passengerSeatMap.passenger
                                                .passengerInfo.type === "INF"
                                                ? "Infant"
                                                : Translation.t("select_seat")}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>

                                <div className="legend-container px-0">
                                  {this.props.isMedium ? (
                                    <span
                                      tabIndex="0"
                                      onClick={() =>
                                        this.props.isMedium
                                          ? this.setState({
                                              legendToggler:
                                                !this.state.legendToggler,
                                            })
                                          : null
                                      }
                                      className="legend-title legend-medium d-flex"
                                    >
                                      {this.state.legendToggler
                                        ? Translation.t("hide_legend")
                                        : Translation.t("show_legend")}
                                    </span>
                                  ) : (
                                    <h6 className="legend-title px-3">
                                      {Translation.t("legend")}
                                    </h6>
                                  )}

                                  {(this.props.isMedium &&
                                    this.state.legendToggler) ||
                                  (!this.props.isMedium &&
                                    this.state.legendToggler) ||
                                  (!this.props.isMedium &&
                                    !this.state.legendToggler) ? (
                                    <ul className="seats-list row mx-0 px-4">
                                      <li
                                        id="seat-type-seat0"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            ></span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("available_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat4"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              disabled="true"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            >
                                              ×
                                            </span>
                                          </div>
                                        </div>
                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("unavailable_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat1"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex premium-seat "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              tabindex="1"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            >
                                              {" "}
                                            </span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("premium_seat")}
                                        </span>
                                        <span className="seat-legend-price d-inline-flex">
                                          {/* <span className="amount">3372</span>{" "}
                                        <span className="currency">ETB</span> */}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat2"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              tabindex="1"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center extra-leg-seat-legend"
                                            ></span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("extra_leg_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat3"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("Exit")}
                                        </span>
                                      </li>
                                    </ul>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 col-lg-8 right-container pt-3">
                                <div className="row mx-0">
                                  <div className="col-12">
                                    <span className="seat-map-deck d-flex justify-content-center">
                                      {segmentSeatMap.seatMap.cabins[0].deck}{" "}
                                      {Translation.t("Deck")}
                                    </span>
                                  </div>
                                  <div className="col-12">
                                    <span className="aircraft-name d-flex justify-content-center">
                                      {segmentSeatMap.seatMap.aircraft}
                                    </span>
                                  </div>
                                  <div className="col-12 seat-map-instructions">
                                    <p className="text-center">
                                      To request your seat, please click on the
                                      seat you would like on the map. Your seat
                                      selection cannot be confirmed until you
                                      finish your purchase. And your seat
                                      reservation will be cancelled after
                                      24hours, if payment is not effected within
                                      this time frame. <br />
                                      Please note that amount paid for the
                                      selected seat is non-refundable,
                                      non-transferable, non-exchangeable
                                    </p>
                                  </div>
                                </div>
                                <Seats
                                  seatMapIdentifier={
                                    segmentSeatMap.segment.origin +
                                    "-" +
                                    segmentSeatMap.segment.destination
                                  }
                                  Cabin={segmentSeatMap.seatMap.cabins[0]}
                                  SeatMapId={segmentSeatMap.seatMap["@id"]}
                                  DepartureDate={
                                    segmentSeatMap.segment.departure
                                  }
                                  seatMapIndex={counter}
                                  TotalSeatMaps={
                                    this.props.segmentSeatMaps.length
                                  }
                                  nextFlight={this.nextFlight}
                                  continueToBaggage={this.continueToBaggage}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab>
                  );
                })}
              </Tabs>
              <div className="col-12 justify-content-end d-flex pr-0 pt-2">
                <button
                  className="bk-submit-btn btn-ethiopian mx-2 btn"
                  onClick={() => this.redirectToPassanger()}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="bk-submit-btn btn-ethiopian mx-2 btn"
                  onClick={() => this.handleContinueClick()}
                >
                  {Translation.t("continue")}
                </button>
              </div>
            </div>
          )}
          <div className="col-12 col-xl-3">
            {!this.props.isMedium && <ShoppingCart />}
          </div>
        </Row>
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    cookieSabreDataRequest:
      state.searchFlight.searchResult.data.cookieSabreDataRequest,
    token: state.searchFlight.token,
    segmentSeatMaps: state.seatSelection.segmentSeatMaps,
    selectedSeats: state.seatSelection.selectedSeats,
    passengerIndex: state.seatSelection.passengerIndex,
    seatSelecting: state.seatSelection.seatSelecting,
    passangers: state.seatSelection.passangers,
    isMedium: state.global.isMedium,
    activePassangerIdentifierCodeMapper:
      state.seatSelection.activePassangerIdentifierCodeMapper,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSeat: (result) => {
      dispatch({
        type: seatSelectionAction.CHANGESEAT,
        searchResult: result,
      });
    },
    storeSeatMaps: (payload) => {
      dispatch({
        type: seatSelectionAction.STORESEATRESULT,
        payload: payload,
      });
    },
    changeActivePassangerCode: (value, passengerIndex) => {
      dispatch({
        type: seatSelectionAction.CHANGEACTIVEPASSANGERCODE,
        activePassangerIdentifierCode: value,
        passengerIndex: passengerIndex,
      });
    },
    removeSelectedSeat: (identifier) => {
      dispatch({
        type: seatSelectionAction.REMOVESEATSELECTION,
        identifier: identifier,
      });
    },
    selectSeat: (payload) =>
      dispatch({
        type: seatSelectionAction.SELECTSEAT,
        payload: payload,
      }),
    seatSelectingUpdate: (value) =>
      dispatch({
        type: seatSelectionAction.SEATSELECTING,
        value: value,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SeatSelection);
