import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import PassengerService from "../../../../service/booking/passenger/PassengerService";
import Select from "react-select";
import AirportSelectStyle from "../../../../master-data/booking-widget/airpot-select-style";
import Translation from "i18next";
import { connect } from "react-redux";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import UserName from "../../shared/UserName";

const selectStyleDesktop = AirportSelectStyle.desktopStyle;
const passengerService = new PassengerService();

const FrequentFlyer = (props) => {
  const [addFrequentFlyer, setAddFrequentFlye] = useState(()=>{
    if(props.frequentFlyers.length >2){
      return true;
    }
    else{
      return false;
    }
  });
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="media">
            <UserName />
            <div className="media-body mx-2">
              <h3 className="frequent-flyer-h3">
                {Translation.t("frequent_flyer")}
              </h3>
              <p>{Translation.t("all_fields_required")}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="col-12 col-lg-6 my-2">
          <Select
            styles={selectStyleDesktop}
            isSearchable={false}
            defaultValue={passengerService
              .getFrequentFlyer()
              .filter((f) => f.value === props.frequentFlyers[0].airline)}
            options={passengerService.getFrequentFlyer()}
            placeholder={Translation.t("frequent_flyer_programme")}
            onChange={(frequentFlyer) =>
              props.updateFrequentFlyer(frequentFlyer.value, "airline", 0)
            }
          />
        </div>
        <div className="col-12 col-lg-6 my-2">
          <input
            className="form-control  widget-input"
            placeholder={Translation.t("frequent_flyer_numbers")}
            id="frequent-flyer-number-1"
            defaultValue={props.frequentFlyers[0].membershipID}
            onChange={() => {
              var value = document.getElementById(
                "frequent-flyer-number-1"
              ).value;
              props.updateFrequentFlyer(value, "membershipID", 0);
            }}
          />
        </div>
      </Row>
      {addFrequentFlyer && (
        <Row>
          <div className="col-12 col-lg-6 my-2">
            <Select
              styles={selectStyleDesktop}
              defaultValue={passengerService
                .getFrequentFlyer()
                .filter((f) => f.value === props.frequentFlyers[1].airline)}
              isSearchable={false}
              options={passengerService.getFrequentFlyer()}
              placeholder={Translation.t("frequent_flyer_programme_2")}
              onChange={(frequentFlyer) =>
                props.updateFrequentFlyer(frequentFlyer.value, "airline", 1)
              }
            ></Select>
          </div>
          <div className="col-12 col-lg-6 my-2">
            <input
              className="form-control  widget-input"
              placeholder={Translation.t("frequent_flyer_numbers_2")}
              defaultValue={props.frequentFlyers[1].membershipID}
              id="frequent-flyer-number-2"
              onChange={() => {
                var value = document.getElementById(
                  "frequent-flyer-number-2"
                ).value;
                props.updateFrequentFlyer(value, "membershipID", 1);
              }}
            />
          </div>
        </Row>
      )}
      <Row>
        <div className="col-12">
          <span
            className="add_flight_btn my-2"
            onClick={() => {
              if (!addFrequentFlyer) {
                props.addNewFrequentFlyer();
              } else {
                props.removeFrequentFlyer();
              }
              setAddFrequentFlye(!addFrequentFlyer);
            }}
          >
            {addFrequentFlyer ? (
              <>
                {" "}
                <Button className="btn-success remove-frequent-flyer  text-white btn mr-2 btn-sm frequent-flyer-btn">
                  -{" "}
                </Button>
                <label>{Translation.t("remove_one_frequent_flyer")}</label>
              </>
            ) : (
              <>
                {" "}
                <Button className="btn-success std-et-bg-color text-white btn mr-2 btn-sm frequent-flyer-btn">
                  +
                </Button>
                <label>{Translation.t("add_one_frequent_flyer")}</label>
              </>
            )}
          </span>
        </div>
      </Row>
    </>
  );
};


const mapStateTopProps = (state) => {
  return {
    frequentFlyers :state.account.profile.frequentFlyers,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
    
  };
};
const mapDispatchToProps = (dispacth) => {
  return {
    updateFrequentFlyer: (value, parameter,index) =>
      dispacth({
        type: accountAction.UPDATEFREQUENTFLYER,
        value: value,
        parameter: parameter,
        index:index
      }),
      addNewFrequentFlyer:()=>{
        dispacth({
          type: accountAction.ADDFREQUENTFLYER,
        });
      },
      removeFrequentFlyer:()=>{
        dispacth({
          type:accountAction.REMOVEFREQUENTFLYER
        })
      }
    }
};
export default connect(mapStateTopProps, mapDispatchToProps)(FrequentFlyer);
