import "./App.css";
import React from "react";
import { globalActionType } from "./redux/actions/shared/globalAction";
import { connect } from "react-redux";
import Header from "./component/shared/header/Header";
import IdleController from "./component/shared/idle-controller/IdleController";
import Footer from "./component/shared/footer/Footer";
import HttpService from "./service/shared/HttpService";
import { searchFlightAction } from "./redux/actions/booking/searchFlightAction";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import RouteList from "./component/shared/route/RouteList";
import ErrorBoundary from "./component/shared/exception/ErrorBoundary";

import arLocale from "moment/locale/ar";
import deLocale from "moment/locale/de";
import enLocale from "moment/locale/en-gb";
import esLocale from "moment/locale/es";
import frLocale from "moment/locale/fr";
import ptLocale from "moment/locale/pt";
import zhLocale from "moment/locale/zh-cn";

import moment from "moment";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props.updateMobile(window.innerWidth <= 767);
    this.props.updateMedium(window.innerWidth <= 1037);
  }

  async componentDidMount() {
    await this.generateToken();
    await this.autoLogin();

    window.addEventListener("resize", this.isMobileMethod);

    switch (localStorage.getItem("i18nextLng")) {
      case "en":
        moment.updateLocale("en", enLocale);
        break;
      case "ar":
        moment.updateLocale("ar", arLocale);
        break;
      case "zh":
        moment.updateLocale("zh", zhLocale);
        break;
      case "fr":
        moment.updateLocale("fr", frLocale);
        break;
      case "es":
        moment.updateLocale("es", esLocale);
        break;
      case "pt":
        moment.updateLocale("pt", ptLocale);
        break;
      case "de":
        moment.updateLocale("de", deLocale);
        break;
      default:
        moment.updateLocale("en", enLocale);
        break;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const isTeleBirrPayment = queryParams.get("isTeleBirrPayment");
    if (isTeleBirrPayment) {
      this.props.teleBirrPayment(isTeleBirrPayment);
    } else {
      this.props.teleBirrPayment(false);
    }
  }

  componentDidUpdate() {
    switch (this.props.locale) {
      case "en":
        moment.updateLocale("en", enLocale);
        break;
      case "ar":
        moment.updateLocale("ar", arLocale);
        break;
      case "zh":
        moment.updateLocale("zh", zhLocale);
        break;
      case "fr":
        moment.updateLocale("fr", frLocale);
        break;
      case "es":
        moment.updateLocale("es", esLocale);
        break;
      case "pt":
        moment.updateLocale("pt", ptLocale);
        break;
      case "de":
        moment.updateLocale("de", deLocale);
        break;
      default:
        moment.updateLocale("en", enLocale);
        break;
    }
  }

  isMobileMethod = () => {
    this.props.updateMobile(window.innerWidth <= 767);
    this.props.updateMedium(window.innerWidth <= 1037);
  };
  generateToken = () => {
    if (this.props.token === "") {
      return HttpService.getTokenServive()
        .then((response) => {
          this.props.saveToken(response.data.tokenDetail.access_token);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  autoLogin = async () => {
    if (this.props.keepMeSignedIn) {
      var requestDate = {
        userId: this.props.userName,
        password: this.props.password,
        captcha: null,
      };
      HttpService.postAccount(requestDate, "/Login", this.props.token)
        .then((loginResponse) => {
          if (loginResponse.data.statusAPI === "SUCCESS") {
            this.props.signIn(
              loginResponse.data.result.user.personalDetails.firstName,
              loginResponse.data.result.user.personalDetails.lastName,
              loginResponse.data.cookieSabreDataRequest,
              loginResponse.data.shebaMilesDetails.currentAwardPoints,
              this.props.userName,
              this.props.password
            );
          } else {
            this.props.signOut();
          }
        })
        .catch(() => {
          HttpService.getTokenServive().then((response) => {
            this.props.saveToken(response.data.tokenDetail.access_token);
            HttpService.postAccount(
              requestDate,
              "/Login",
              response.data.tokenDetail.access_token
            ).then((loginResponse) => {
              if (loginResponse.data.statusAPI === "SUCCESS") {
                this.props.signIn(
                  loginResponse.data.result.user.personalDetails.firstName,
                  loginResponse.data.result.user.personalDetails.lastName,
                  loginResponse.data.cookieSabreDataRequest,
                  loginResponse.data.shebaMilesDetails.currentAwardPoints,
                  this.props.userName,
                  this.props.password
                );
              } else {
                this.props.signOut();
              }
            });
          });
        });
    } else if (this.props.isUserSignedIn) {
      await HttpService.postAccount(
        this.props.profileCookie,
        "/ProfileRetrieve",
        this.props.token
      )
        .then((accountRetrieveResponse) => {
          if (accountRetrieveResponse.data.statusAPI === "SUCCESS") {
          } else {
            this.props.signOut();
          }
        })
        .catch(() => this.props.signOut());
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.isMobileMethod);
  }
  render() {
    return (
      <div className="App h-100">
        <IdleController />
        <Router>
          <Header />
          <div className="App-container">
            <Switch>
              {RouteList.map((route, i) => (
                <Route
                  path={route.path}
                  key={i}
                  exact
                  render={(props) => {
                    return (
                      <ErrorBoundary>
                        <route.component {...props} />
                      </ErrorBoundary>
                    );
                  }}
                />
              ))}
            </Switch>
          </div>
        </Router>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
    isMobile: state.global.isMobile,
    token: state.searchFlight.token,
    keepMeSignedIn: state.global.keepMeSignedIn,
    isUserSignedIn: state.global.isUserSignedIn,
    userName: state.global.userName,
    password: state.global.password,
    account: state.account,
    profileCookie: state.account.profileCookie,
    locale: state.global.locale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMobile: (value) =>
      dispatch({
        type: globalActionType.UPDATEISMOBILE,
        value: value,
      }),
    updateMedium: (value) =>
      dispatch({
        type: globalActionType.UPDATEISMEDIUM,
        value: value,
      }),

    saveToken: (token) =>
      dispatch({
        type: searchFlightAction.SAVETOKEN,
        token: token,
      }),
    signIn: (
      firstName,
      lastName,
      profileCookie,
      currentAwardPoints,
      userName,
      password
    ) =>
      dispatch({
        type: globalActionType.SIGNEDIN,
        firstName: firstName,
        lastName: lastName,
        profileCookie: profileCookie,
        currentAwardPoints: currentAwardPoints,
        userName: userName,
        password: password,
      }),
    signOut: () =>
      dispatch({
        type: globalActionType.SIGNOUT,
      }),
    teleBirrPayment: (isTeleBirrPayment) =>
      dispatch({
        type: globalActionType.TELEBIRR,
        isTeleBirrPayment: isTeleBirrPayment,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
