import Notification from "../booking/booking-widget/Notification";
import React from "react";

import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Translation from "i18next";



const CheckInError = (props) => {

    let history = useHistory();

  return (
    <>
    <Container className="py-4">
      <h3>{Translation.t("error_occurred")}</h3>
      <Notification
        error={true}
        header={"Check-in System Error"}
        body={
          "Online check-in is not possible for this booking. Please contact our office near to you or call on our Global Call Center office with +251 116 17 99 00"
        }
        extra={"1621518742233 / ckowye4bz002of0r7lmif5miz"}
      />

      <div className="row w-100 justify-content-end mx-0">
            <div className="col-12 col-md-6 col-lg-2 px-0">
                <button className="bk-submit-btn btn-ethiopian btn w-100" onClick={() => history.push("/check-in")}>
                {Translation.t("return_to_home")}
                </button>
            </div>
      </div>

    </Container>
      
    </>
  );
};

export default CheckInError;
