export class FloCashRequestObj {
    constructor() {
        this.requestObj = {
            "Amount": null,
            "Currency": null,
            "Email": null,
            "Country": null,
            "FirstName": null,
            "LastName": null,
            "PhoneNumber": {
                "CountryCode": null,
                "Number": null
            },
            "PaymentID": 0,
            "cookieSabreDataRequest": null
    }
}
    }
