import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import esLocale from "moment/locale/es";
import enLocale from "moment/locale/en-gb";
import frLocale from "moment/locale/fr";
import arLocale from "moment/locale/ar";
import deLocale from "moment/locale/de";
import zhLocale from "moment/locale/zh-cn";
import ptLocale from "moment/locale/pt";
import moment from "moment";
import { connect } from "react-redux";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
  OPEN_UP,
} from "react-dates/constants";

import "./DatePicker.css";

import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import Translation from "i18next";

class DatePicker extends React.PureComponent {
  state = {
    focused: false,
    value: null,
  };

  componentDidMount() {
    switch (this.props.locale) {
      case "en":
        moment.updateLocale("en", enLocale);
        break;
      case "ar":
        moment.updateLocale("ar", arLocale);
        break;
      case "zh":
        moment.updateLocale("zh", zhLocale);
        break;
      case "fr":
        moment.updateLocale("fr", frLocale);
        break;
      case "es":
        moment.updateLocale("es", esLocale);
        break;
      case "pt":
        moment.updateLocale("pt", ptLocale);
        break;
      case "de":
        moment.updateLocale("de", deLocale);
        break;
      default:
        moment.updateLocale("en", enLocale);
        break;
    }
  }

  componentDidUpdate() {
    switch (this.props.locale) {
      case "en":
        moment.updateLocale("en", enLocale);
        break;
      case "ar":
        moment.updateLocale("ar", arLocale);
        break;
      case "zh":
        moment.updateLocale("zh", zhLocale);
        break;
      case "fr":
        moment.updateLocale("fr", frLocale);
        break;
      case "es":
        moment.updateLocale("es", esLocale);
        break;
      case "pt":
        moment.updateLocale("pt", ptLocale);
        break;
      case "de":
        moment.updateLocale("de", deLocale);
        break;
      default:
        moment.updateLocale("en", enLocale);
        break;
    }
  }

  onFocusChange = (value) => {
    this.setState({ focused: !this.state.focused });
    if (!value.focused && this.getDate() === null) {
      this.props.changeValidation(
        this.props.isReturn ? "arrivalDateInvalid" : "deparutreDateInvalid",
        this.props.flightIndex
      );
    }
  };
  onChange = (value) => {
    this.setState({ focused: !this.state.focused, value: value });
    this.props.setDATE({
      date: value ? value.locale("en").format("YYYY-MM-DD") : null,
      flightIndex: this.props.flightIndex,
      isReturn: this.props.isReturn ? true : false,
    });
    this.props.changeValidation(
      this.props.isReturn ? "arrivalDate" : "deparutreDate",
      this.props.flightIndex
    );
  };

  updateDate = (value) => {
    this.props.setDATE({
      date: value ? value.locale("en").format("YYYY-MM-DD") : null,
      flightIndex: this.props.flightIndex,
      isReturn: this.props.isReturn ? true : false,
    });
    this.props.changeValidation(
      this.props.isReturn ? "arrivalDate" : "deparutreDate",
      this.props.flightIndex
    );
  };

  getDate = () => {
    var LocalDate;
    if (this.props.isReturn) {
      LocalDate =
        this.props.itineraryParts[this.props.flightIndex].params.when
          .arrivalDate != null
          ? moment(
              this.props.itineraryParts[this.props.flightIndex].params.when
                .arrivalDate
            )
          : null;
    } else {
      LocalDate =
        this.props.itineraryParts[this.props.flightIndex].params.when
          .departureDate != null
          ? moment(
              this.props.itineraryParts[this.props.flightIndex].params.when
                .departureDate
            )
          : null;
    }
    //if min is greater than selected date
    if (this.props.minDate !== null && LocalDate !== null) {
      var minDate = moment(this.props.minDate);
      if (minDate > LocalDate) {
        LocalDate = minDate;
      }
    }
    if (LocalDate !== null) {
      if (LocalDate.isBefore(moment().format("YYYY-MM-DD"))) {
        LocalDate = moment();
        this.updateDate(LocalDate);
      }
    }
    return LocalDate == null ? null : LocalDate.locale(this.props.locale);
  };

  getDateClasses = () => {
    if (this.state.focused) return "col-12 col-sm col-md-6 my-2";
    if (
      this.props.validations[this.props.flightIndex].params
        .isDeparutreDateValid &&
      !this.props.isReturn
    )
      return "col-12 col-sm col-md-6 my-2";
    if (
      this.props.validations[this.props.flightIndex].params.isArrivalDate &&
      this.props.isReturn
    )
      return "col-12 col-sm col-md-6 my-2";
    return "col-12 col-sm col-md-6 my-2 invalid-date-picker";
  };

  getDatePickerPlaceholderText = () => {
    if (this.state.focused)
      return Translation.t(
        this.props.isReturn ? "arrival_date" : "departure_date"
      ); //"col-12 col-sm col-md-6 my-2";
    if (
      this.props.validations[this.props.flightIndex].params
        .isDeparutreDateValid &&
      !this.props.isReturn
    )
      return Translation.t("departure_date"); //"col-12 col-sm col-md-6 my-2";
    if (
      this.props.validations[this.props.flightIndex].params.isArrivalDate &&
      this.props.isReturn
    )
      return Translation.t("arrival_date");
    return Translation.t(
      this.props.isReturn ? "arrival_date_invalid" : "departure_date_invalid"
    ); //"col-12 col-sm col-md-6 my-2 invalid-date-picker";
  };

  getVisibleMonth(minimumDate) {
    return this.getDate() !== null ? this.getDate() : moment(minimumDate);
  }

  isOutsideRange = (day) => {
    var maximumdate = this.props.maxDate
      ? moment(this.props.maxDate).format("YYYY-MM-DD")
      : moment().add(50, "years").format("YYYY-MM-DD");
    const minimumDate = this.props.minDate
      ? moment(this.props.minDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    return day.isAfter(maximumdate) || day.isBefore(minimumDate);
  };

  render() {
    const minimumDate = this.props.minDate
      ? moment(this.props.minDate).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
    return (
      <div className={this.getDateClasses()}>
        <SingleDatePicker
          showClearDate={false}
          displayFormat="YYYY-MM-DD"
          numberOfMonths={this.props.isMobile ? 3 : 1}
          orientation={
            this.props.isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
          }
          disabled={false}
          readOnly={true}
          initialVisibleMonth={() => this.getVisibleMonth(minimumDate)}
          placeholder={this.getDatePickerPlaceholderText()}
          date={this.getDate()}
          focused={this.state.focused}
          onDateChange={this.onChange}
          onFocusChange={this.onFocusChange}
          withFullScreenPortal={this.props.isMobile}
          hideKeyboardShortcutsPanel={true}
          showDefaultInputIcon={true}
          isOutsideRange={(day) => this.isOutsideRange(day)}
          id={this.props.name}
          openDirection={OPEN_UP}
        />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    locale: state.global.locale,
    itineraryParts: state.bookingWiget.itineraryPart,
    validations: state.bookingWiget.validation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDATE: (value) =>
      dispatch({
        type: BookingWidgetAction.DATESELECTION,
        payload: value,
      }),
    changeValidation: (domain, flightIndex) =>
      dispatch({
        type: BookingWidgetAction.VALIDATEWIDGET,
        domain: domain,
        flightIndex: flightIndex,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(DatePicker);
