import React from "react";
import PassengerInfo from "./PassengerInfo";
import { connect } from "react-redux";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import HttpService from "../../../service/shared/HttpService";
import Translation from "i18next";
import { Redirect } from "react-router-dom";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import ShoppingCart from "../../shared/shoppingCart/ShoppingCart";
import { ancillaryAction } from "../../../redux/actions/booking/ancillaryAction";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";

class AllPassengerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backPage: false,
      isLoading: false,
      redirect: false,
      openErrorModal: false,
      errorMessage: [],
    };
    this.props.removeValidation();
  }
  rememberMe = () => {
    var rememberMe = document.getElementById("remember-me");
    if (rememberMe.checked) {
      var savedPassengerProfiles = JSON.parse(
        localStorage.getItem("IBE_PassengerInfo")
      );
      var passengerKey = this.props.passengerList[0].passengerDetails.firstName;
      var passengerInfo = this.props.passengerList[0];
      if (
        savedPassengerProfiles === null ||
        savedPassengerProfiles.length === 0
      ) {
        var newProfile = {};
        newProfile[passengerKey] = passengerInfo;
        localStorage.setItem("IBE_PassengerInfo", JSON.stringify(newProfile));
      } else {
        savedPassengerProfiles[passengerKey] = passengerInfo;
        localStorage.setItem(
          "IBE_PassengerInfo",
          JSON.stringify(savedPassengerProfiles)
        );
      }
    } else {
      var savedPassengerProfiles = JSON.parse(
        localStorage.getItem("IBE_PassengerInfo")
      );
      var passengerKey = this.props.passengerList[0].passengerDetails.firstName;
      if (
        savedPassengerProfiles !== null &&
        savedPassengerProfiles.length !== 0
      ) {
        if (savedPassengerProfiles.hasOwnProperty(passengerKey)) {
          delete savedPassengerProfiles[passengerKey];
          localStorage.setItem(
            "IBE_PassengerInfo",
            JSON.stringify(savedPassengerProfiles)
          );
        }
      }
    }
  };
  async continue() {
    await this.props.validatePassengerForm();
    if (this.props.isPassengerFormValid) {
      this.setState({ isLoading: true });
      var passengerService = new PassengerService();
      var dataForServer = await passengerService.passengerDataForApi(
        this.props.passengerList,
        this.props.searchFlight.selectionResult.cookieSabreDataRequest
      );
      this.rememberMe();
      HttpService.postService(
        dataForServer,
        "/Passanger/AddPassangerInfo",
        this.props.searchFlight.token
      )
        .then((passengerResponse) => {
          if (passengerResponse.data.statusAPI === "SUCCESS") {
            var getAncillariesRequest = {
              Flow: 0,
              cookieSabreDataRequest:
                this.props.searchFlight.selectionResult.cookieSabreDataRequest,
            };
            HttpService.ancillaryPostService(
              getAncillariesRequest,
              "/Ancillary/GetAncillaries",
              this.props.searchFlight.token
            )
              .then((ancillaryResponse) => {
                this.props.saveAncillaryDate(ancillaryResponse.data);
              })
              .catch((error) => {
                console.log(error);
                this.setState({ isLoading: false });
              });
            this.setState({ redirect: true });
          } else {
            this.setState({
              openErrorModal: true,
              isLoading: false,
              errorMessage: passengerResponse.data.errorList,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
        });
    }
  }
  updatePassengerMethod = (
    index,
    value,
    objName,
    inputName,
    id,
    updateGender = false
  ) => {
    if (id !== null && id !== undefined && (value === null || value === "")) {
      var inputValue = document.getElementById(id);
      inputValue.classList.add("invalid_input");
      if (id.includes("first-name")) {
        inputValue.placeholder = Translation.t("first_name_required");
      } else if (id.includes("last-name")) {
        inputValue.placeholder = Translation.t("last_name_required");
      }
    } else {
      if (id === "passenger-0-first-name") {
        var savedPassengerProfiles = JSON.parse(
          localStorage.getItem("IBE_PassengerInfo")
        );
        if (savedPassengerProfiles !== null && !this.props.isRememberedTrue) {
          if (savedPassengerProfiles.hasOwnProperty(value)) {
            this.props.updateSavedProfile(savedPassengerProfiles[value]);
            document.getElementById("remember-me").checked = true;
            return true;
          }
        }
      }
      this.props.updatePassengerInfo(
        index,
        value,
        objName,
        inputName,
        updateGender
      );
    }
  };
  onFocus = (id) => {
    var inputField = document.getElementById(id);
    inputField.classList.remove("invalid_input");
    if (id.includes("first-name")) {
      inputField.placeholder = Translation.t("first_name");
    } else if (id.includes("last-name")) {
      inputField.placeholder = Translation.t("last_name");
    }
  };
  getHeaderText = (index) => {
    if (
      this.props.passengerList[index].passengerDetails.firstName !== null &&
      this.props.passengerList[index].passengerDetails.lastName !== null
    ) {
      return (
        this.props.passengerList[index].passengerDetails.firstName +
        "  " +
        this.props.passengerList[index].passengerDetails.lastName
      );
    } else {
      var passengerIndex = this.props.passengerList[index].passengerIndex;
      return (
        Translation.t(this.props.passengerList[index].passengerInfo.type) +
        " " +
        passengerIndex
      );
    }
  };
  onKeyDownFunction = (e) => {
    var keynum;
    if (window.event) {
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    }
    let isValid =
      (keynum <= 90 && keynum >= 65) ||
      keynum === 8 ||
      keynum === 9 ||
      keynum === 13 ||
      keynum === 32 ||
      keynum === 36 ||
      keynum === 37 ||
      keynum === 38 ||
      keynum === 39 ||
      keynum === 40;

    if (!isValid) {
      e.preventDefault();
    }
  };

  render() {
    if (this.state.backPage) {
      return (
        <Redirect
          to={{
            pathname: "/flightsummary",
          }}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/seatSelection",
          }}
        />
      );
    }
    return (
      <>
        <Modal
          show={this.state.openErrorModal}
          onHide={() => {
            this.setState({ openErrorModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Error Message List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <FontAwesomeIcon icon={faTimes} className="mx-2" />
                {this.state.errorMessage?.map((error) => error)}
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() => this.setState({ openErrorModal: false })}
            >
              {Translation.t("close")}
            </button>
          </Modal.Footer>
        </Modal>
        <BreadCrumb history={this.props.history} />
        <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
          <div className="col-12 col-xl-7 mx-auto mx-xl-0">
            <div className="mt-1 mx-auto mr-xl-0">
              <div className="">
                <div className="mx-3">
                  <h3>Passenger Information</h3>
                  <p>
                    Names must match the ones in your passport and can’t be
                    changed once booking is completed.
                  </p>
                </div>
                {this.props.isUserSignedIn && (
                  <div className="passanger-info-card-header p-2">
                    <div className="media">
                      <div className="justify-content-center d-flex user-name-box ">
                        <span className="user-name-text align-self-center d-flex">
                          {this.props.firstName[0] + this.props.lastName[0]}
                        </span>
                      </div>
                      <div className="media-body px-2 ">
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>ShebaMiles Account</h5>
                            <p>
                              {this.props.firstName} {this.props.lastName}
                            </p>
                            <p>{this.props.userName}</p>
                          </Col>
                          <Col xs={12} md={6}>
                            <div
                              style={{
                                borderLeftColor: "black",
                                borderLeftWidth: "2px",
                              }}
                            >
                              <p>Balance</p>{" "}
                              <p>Total {this.props.currentAwardPoints} Miles</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {this.props.passengerList.map((passenger, index) => {
                try {
                  return (
                    <PassengerInfo
                      key={index}
                      index={index}
                      updatePassengerMethod={this.updatePassengerMethod}
                      onFocus={this.onFocus}
                      getHeaderText={this.getHeaderText}
                      onKeyDownFunction={this.onKeyDownFunction}
                    />
                  );
                } catch (error) {
                  console.log(error);
                  return <></>;
                }
              })}
              <Row className="mt-4">
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <span className="float-right">
                    <Button
                      variant="danger"
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => this.setState({ backPage: true })}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Button
                      variant="danger"
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => this.continue()}
                    >
                      {this.state.isLoading ? (
                        <ProgressSimpler />
                      ) : (
                        Translation.t("continue")
                      )}
                    </Button>
                  </span>
                </Col>
              </Row>
              {/* <div className="col-12 col-md-4 col-lg-3">
          {this.props.isMobile && (
            <Button
              className="btn-success btn-sm"
              onClick={() =>
                this.setState({
                  showShoppingCart: !this.state.showShoppingCart,
                })
              }
            >
              <FontAwesomeIcon icon={faShoppingCart} />
            </Button>
          )}
        </div> */}
            </div>
          </div>
          <div className="col-12 col-xl-3">
            {!this.props.isMedium && <ShoppingCart />}
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    passengerInfo: state.bookingWiget.passengerInfo,
    passengerList: state.passenger.passengerList,
    isPassengerFormValid: state.passenger.isFormValid,
    isRememberedTrue: state.passenger.isRememberedTrue,
    searchFlight: state.searchFlight,
    isMobile: state.global.isMobile,
    isMedium: state.global.isMedium,
    isUserSignedIn: state.global.isUserSignedIn,
    userName: state.account.createAccountModle.userName,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
    currentAwardPoints: state.account.currentAwardPoints,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    validatePassengerForm: () =>
      dispatch({ type: passengerAction.SAVEPASSENGER }),
    removeValidation: () =>
      dispatch({ type: passengerAction.REMOVEVALIDATION }),
    updatePassengerInfo: (index, value, objName, inputName, updateGender) =>
      dispatch({
        type: passengerAction.UPDATEPASSENGERINFO,
        index: index,
        value: value,
        objName: objName,
        inputName: inputName,
        updateGender: updateGender,
      }),
    changeSelectValidation: (index, domain, className) =>
      dispatch({
        type: passengerAction.CHANGEVALIDATION,
        index: index,
        domain: domain,
        className: className,
      }),
    updateSavedProfile: (savedProfile) =>
      dispatch({
        type: passengerAction.UPDATESAVEDPROFILE,
        savedProfile: savedProfile,
      }),
    saveAncillaryDate: (responseData) =>
      dispatch({
        type: ancillaryAction.SAVEANCILLARYDATA,
        data: responseData,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AllPassengerForm);
