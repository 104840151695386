import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Container } from "react-bootstrap";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import Notification from "../../booking/booking-widget/Notification";
import { checkInAction } from "../../../redux/actions/check-in/checkInAction";
import CheckInBaggage from "./CheckInBaggage";
import CheckInBreadCrumb from "../shared/bread-crumb/CheckInBreadCrumb";
import ShoppingCart from "../shared/shoppingCart/ShoppingCart";

class CheckInAncillaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isDataLoaded: false,
      errorOccured: false,
      errorMessage: null,
    };
    this.intializeState();
  }

  intializeState = () => {
    var data = { passengerIds: [], segmentIds: [] };

    Object.keys(this.props.activePassengers).map((key) => {
      data.passengerIds.push({ Id: key });
    });

    this.props.itineraryPart.segment.map((segment) => {
      data.segmentIds.push(segment.id);
    });
    var initializeBaggageRequest = {
      ReturnSession: true,
      Passengers: data.passengerIds,
      SegmentIds: data.segmentIds,
      checkInSabreDataRequest: this.props.cookieSabreDataRequest,
    };
    HttpService.checkInPostService(
      initializeBaggageRequest,
      "/etibe-checkinapi/V1.0/Ancillary/Offer",
      this.props.token
    )
      .then((initializeBaggageResponse) => {
        if (initializeBaggageResponse.data.statusAPI === "SUCCESS") {
          this.props.intializeState(initializeBaggageResponse.data);
        } else {
          this.setState({
            errorOccured: true,
            errorMessage: initializeBaggageResponse.data.messageAPI,
            isDataLoaded: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorOccured: true,
          errorMessage:
            "Something went wrong. we are working on getting this fixed as soon as we can. You may able to try again.",
          isDataLoaded: false,
        });
      });
  };

  handleContinueClick = () => {
    var pendingPayment = false;
    this.props.passengers.map((passenger) => {
      if (passenger.id in this.props.activePassengers) {
        passenger.passengerSegments.passengerSegment.map((passengerSegment) => {
          if (passengerSegment.airExtra !== null)
            passengerSegment.airExtra.map((airExtra) => {
              pendingPayment =
                pendingPayment || airExtra.paymentStatus.value === "PENDING";
            });
        });
      }
    });
    if (pendingPayment) {
      this.props.history.push("/check-in/payment");
    } else {
      this.props.history.push("/check-in/confirmation");
    }
  };

  render() {
    if (this.state.isDataLoaded) {
      return <Loading />;
    } else if (this.state.errorOccured) {
      return (
        <>
          <Container>
            <Notification error={true} body={this.state.errorMessage} />
          </Container>
        </>
      );
    } else {
      return (
        <>
          <CheckInBreadCrumb />
          <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
            <div className="col-12 col-xl-7 mx-0">
              <CheckInBaggage />
              <Row className="mt-4">
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <span className="float-right ">
                    <Button
                      variant="danger"
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => this.props.history.push("/check-in/seats")}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Button
                      variant="danger"
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => {
                        this.handleContinueClick();
                      }}
                    >
                      {Translation.t("continue")}
                    </Button>
                  </span>
                </Col>
              </Row>
            </div>
            <div className="col-12 col-xl-3">
              {!this.props.isMedium && <ShoppingCart />}
            </div>
          </Row>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    bagIsAdded: state.checkIn.bagIsAdded,
    isNewBagAdded: state.checkIn.isNewBagAdded,
    isMedium: state.global.isMedium,
    token: state.searchFlight.token,
    cookieSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    activePassengers: state.checkIn.activePassengers,
    itineraryPart: state.checkIn.reservation.itinerary.itineraryPart[0],
    passengers: state.checkIn.reservation.passengers.passenger,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    intializeState: (data) =>
      dispatch({
        type: checkInAction.CHECKIN_SAVEANCILLARYDATA,
        data: data,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CheckInAncillaries);
