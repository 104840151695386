import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Translation from "i18next";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import FlightSelectionRibbon from "./flight selection ribbon/FlightSelectionRibbon";
class FlightSelectionHeader extends Component {
  render() {
    let itineraryParts = this.props.itineraryParts[this.props.flightIndex];
    let origin = AirportFullName.getFullName(itineraryParts.from.code);
    let destination = AirportFullName.getFullName(itineraryParts.to.code);
    return (
      <div className="py-3">
        <h3 className="text-white flight-selection-head-txt">
        {Translation.t("choose_your_flight")}
        </h3>
        <h5 className="text-white flight-selection-head-txt py-2">
          {Translation.t("select_your_flights_for_your")} <span>{origin}</span>{" "}
          <span className="px-3">
            <FontAwesomeIcon icon={faPlaneDeparture} />
          </span>{" "}
          <span>{destination}</span>{" "}
        </h5>
        <h4 className="text-white">
          {this.props.flightIndex === 0
            ? "Select Your Departure Flight"
            : "Select Your Return Flight"}
        </h4>
        {this.props.journyType === "roundTrip" ||
        this.props.journyType === "oneWay" ? (
          <FlightSelectionRibbon />
        ) : null}
      </div>
    );
  }
}
export default FlightSelectionHeader;
