export class CreditCardRequestObj
{
constructor()
{
    this.requestObj ={
        "paymentRequired": true,
      
        "payment": [
      
          {
            "@type": "CREDIT_CARD",
            "@id": "1",
            "amount": {
                "amount": 2162,
                "currency": "ETB"
            },
            "number": "4444333322221111",
            "cvc": "737",
            "pin": null,
            "holderName": "Fisha Javour",
            "expirationDate": "2030-03",
            "cardCode": "VI",
            "installment": null,
            "received3rdPartyData": null,
            "selectedDCCOffer": null,
            "redirectData": {
                "redirectUrlData": {
                    "defaultURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Default",
                    "pendingURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Pending",
                    "approvedURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Approved",
                    "declinedURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Declined",
                    "errorURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Error",
                    "cancelURL": "http://localhost:19081/FlightBooking/WebPayment/LandingPage/Cancel"
                },
                "paRes": null,
                "verificationResult": null,
                "transactionId": null
            },
            "redirectUrlData": null,
            "afopClientDetail": null,
            "fopCode": null,
            "fopSubcode": null,
            "cancelURL": null,
            "successURL": null,
            "payerId": null,
            "token": null,
            "status": null
        }
      
        ],
      
        "billingData": {
          "street1": "WASHINGTON",
          "street2": null,
          "city": "WASHINGTON DC",
          "province": null,
          "zipCode": "00098",
          "country": "GB",
          "phone": {
              "type": "WORK",
              "countryCode": "251",
              "areaCode": null,
              "number": "922215325"
          },
          "email": "accept@accept.com"
      },
      
        "languageForBooking": null,
      
        "fraudNetData": {
              "httpHeaders":null,
              "deviceFingerPrintId":"123456789",
              "timestamp": "2018-12-07",
              "ipAddress":"192.168.42.11"
        },
      
        "remarksAndSSRs": null,
      
        "profileInput": null,
      
        "queuePlacementData": null,
      
        "holdOptionId": null,
      
         "cookieSabreDataRequest": null,
      
        "TinNumber": null,
      
        "CompanyName": null,
      
        "Email": null
      }
}
}