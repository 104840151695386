import React, { Component } from "react";
import { connect } from "react-redux";
import { paymentAction } from "../../../redux/actions/booking/paymentAction";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import { CountryService } from "../../../service/shared/CountryService";
import HttpService from "../../../service/shared/HttpService";
import TravelBank from "../../booking/payment/payment-options/travel-bank/TravelBank";
import "../../booking/payment/Payment.css";
import Loading from "../../booking/seat-selection/Loading";
import ManageTripCreditCard from "./payment-options/credit-card/ManageTripCreditCard";
import ManageTripFloCashPaymentOptions from "./payment-options/flocash/ManageTripFloCashPaymentOptions";
import ManageTripHoldReservation from "./payment-options/hold-reservation/ManageTripHoldReservation";
import ManageTripPaymentOption from "./payment-options/ManageTripPaymentOption";
import ManageTripUnionPay from "./payment-options/union-pay/ManageTripUnionPay";

class ManageTripPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardOptions: [],
      flocashOptions: [],
      priceInfo: null,
      unionPay: null,
      travelBank: null,
      holdReservation: null,
      selectedPaymentOption: "",
      isLoading: false,
      isRedirect: false,
    };
    this.setSelectedPaymentOption = this.setSelectedPaymentOption.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (this.props.countryList.length === 0) {
      this.getCountryList(this.props.searchFlight.token);
    }
    this.getPaymentOptions();
  }
  getPaymentOptions = () => {
    
    let segmentInfo = null;
    let cookieSabreData = null;

    if (this.props.location.state.type === "seat") {
      segmentInfo =
        this.props.state.seatSelection.myb.segmentSeatMaps[0].segment;
      cookieSabreData = this.props.state.seatSelection.cookieSabreDataRequest;
    } else {
      segmentInfo =
        this.props.state.manageAncillary.ancillaryList.itinerary
          .itineraryParts[0].segments[0];
      cookieSabreData =
        this.props.state.manageAncillary.ancillaryList.cookieSabreDataRequest;
    }
    let country = AirportFullName.getCountryName(segmentInfo.origin);

    let countryCode = CountryService.getCountryCode(
      country,
      this.props.countryList
    );

    let requestObject = {
      IsToday: false,
      IsFlightWithIn48Hours: false,
      CountryCode: countryCode,
      AirportCode: segmentInfo.origin,
      DepartureDateTime: segmentInfo.departure,
      //"PromoCode": "DOM71",
      paymentType: 1,
      cookieSabreDataRequest: cookieSabreData,
    };
    this.setState({ isLoading: true });
    HttpService.postServiceManageTrip(
      requestObject,
      "/etibe-managetrip/V1.0/MTOPaymentOption/GetPaymentOption",
      this.props.searchFlight.token
    ).then(
      (response) => {
        
        if (response.data.statusAPI === "SUCCESS") {
          if (response.data.payment.paymentProducts.length === 0) {
            this.setState({ isLoading: false });
            this.props.history.push("/myb/nm/payment");
          } else {
            this.setState({ isLoading: false });

            this.constructPaymentOptioins(
              response.data.payment.paymentProducts
            );
          }
        } else {
          this.setState({ isLoading: false });
        }
      },
      (error) => {
        
        this.setState({ isLoading: false });
      }
    );
  };

  getCountryList(token) {
    HttpService.getCountryListService(
      "/_masterdatasvc-masterdata/api/Country/list?memoryCachRefresh=true",
      "",
      token
    ).then((response) => {
      this.props.saveCountryList(response.data.lstCountriesDetail);
    });
  }
  setSelectedPaymentOption(option) {
    this.setState({ selectedPaymentOption: option });
  }
  renderPaymentOption() {
    if (this.state.selectedPaymentOption === "AFOP") {
      return (
        <ManageTripFloCashPaymentOptions
          flocashOptions={this.state.flocashOptions}
          amount={this.state.priceInfo}
        />
      );
    }
    if (this.state.selectedPaymentOption === "TB") {
      // return(<TravelBank />);
      return <TravelBank />;
    }
    if (this.state.selectedPaymentOption === "CC") {
      return (
        <ManageTripCreditCard
          creditCardOptions={this.state.creditCardOptions}
          countryList={this.props.countryList}
          type={this.props.location.state.type}
        />
      );
    }
    if (this.state.selectedPaymentOption === "UP") {
      return <ManageTripUnionPay unionPay={this.state.unionPay} />;
    }
    if (this.state.selectedPaymentOption === "HR") {
      return <ManageTripHoldReservation amount={this.state.priceInfo} />;
    }
  }
  constructPaymentOptioins(paymentOptions) {
    
    this.setState({
      creditCardOptions: [],
      flocashOptions: [],
      unionPay: null,
    });
    let creditCardOptionList = [];
    let flocashOptionList = [];

    paymentOptions.forEach((paymentOption) => {
      if (paymentOption.paymentInfo.paymentType == "AFOP") {
        if (paymentOption.paymentInfo.afopDetails !== null) {
          paymentOption.paymentInfo.afopDetails.forEach((afop) => {
            if (afop.fopCode === "BR") {
              this.setState({ unionPay: afop });
            } else {
              flocashOptionList.push(afop);
            }
          });
          this.setState({
            flocashOptions: flocashOptionList,
            priceInfo: paymentOption.paymentInfo.amount,
          });
        }
      }
      if (paymentOption.paymentInfo.paymentType == "CREDIT_CARD") {
        creditCardOptionList.push(paymentOption);
      }
      if (paymentOption.paymentInfo.paymentType == "TRAVEL_BANK") {
        this.setState({ travelBank: paymentOption.paymentInfo });
      }
      if (
        paymentOption.paymentInfo.paymentType == "REMOTE" &&
        paymentOption.paymentInfo.paymentCode == "BA_remote"
      ) {
        this.setState({ holdReservation: paymentOption.paymentInfo });
      }
    });
    this.setState({ creditCardOptions: creditCardOptionList });
  }

  render() {
    //if(this.state.isLoading)
    //{
    //    return(<div className="container text-center"><ProgressSimpler /></div>);
    //}
    

    return (
      <div className="container mt-3">
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <ManageTripPaymentOption
            paymentInfo={this.state}
            setSelectedPaymentOption={this.setSelectedPaymentOption}
          />
        )}
        {this.renderPaymentOption()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    countryList: state.payment.countryList,
    exchangeFlightSearchResult: state.exchange.flightSearchResult,
    state: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCountryList: (countryList) => {
      dispatch({
        type: paymentAction.SAVECOUNTRYLIST,
        data: countryList,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageTripPayment);
