import { seatSelectionAction } from "../../actions/booking/seatSelectionAction";

const initialState = {
  segmentSeatMaps: [],
  passengerIndex: 1,
  passangers: [],
  selectedSeats: {},
  activePassangerIdentifierCode: "A1",
  seatSelecting: false,
  cookieSabreDataRequest: "",
  activePassangerIdentifierCodeMapper: {},
  myb: {
    segmentSeatMaps: [],
    passengerIndex: 1,
    passangers: [],
    selectedSeats: {},
    activePassangerIdentifierCode: "A1",
    seatSelecting: false,
  },
};

const seatSelectionReducer = (state = initialState, action) => {
  var AdultCounter = 1;
  var ChildCounter = 1;
  var InfantCounter = 1;
  var temp = {};
  var temp2 = {};
  var passIndex = "";
  var origin = "";
  var destination = "";
  var originDestinationIdentifier = "";
  var tempSelectedSeats = "";
  var tempActivePassangerIdentifierCodeMapper = {};
  var temporartyDictionary = {};
  switch (action.type) {
    case seatSelectionAction.SELECTSEAT:
      passIndex = action.payload.passengerIndex;
      origin = action.payload.seatMapIdentifier.split("-");
      destination = origin[1];
      origin = origin[0];
      originDestinationIdentifier = origin + destination; //action.payload.originDestinationIdentifier;
      tempSelectedSeats = { ...state.selectedSeats };
      tempSelectedSeats[originDestinationIdentifier + passIndex] = {
        origin: origin,
        destination: destination,
        seatCode: action.payload.seatCode,
        seat: action.payload.seat,
      };
      temporartyDictionary = {
        ...tempSelectedSeats[originDestinationIdentifier],
      };

      temp = Object.keys(temporartyDictionary).filter(
        (key) =>
          temporartyDictionary[key].passengerIndex === state.passengerIndex
      );
      if (temp.length !== 0) {
        delete temporartyDictionary[temp[0]];
      }
      temporartyDictionary[action.payload.seatCode] = {
        seatCode: action.payload.seatCode,
        passengerIndex: passIndex,
        activePassangerIdentifierCode: state.activePassangerIdentifierCode,
      };
      tempSelectedSeats[originDestinationIdentifier] = temporartyDictionary;
      return {
        ...state,
        selectedSeats: tempSelectedSeats,
      };
    case seatSelectionAction.CHANGESEAT:
      // var tempBaggageInfo = tempBaggageInfoService.jsonMap(action.baggageInfo);
      return {
        ...state,
        //   baggageInfo: tempBaggageInfo,
      };
    case seatSelectionAction.STORESEATRESULT:
      AdultCounter = 1;
      ChildCounter = 1;
      InfantCounter = 1;

      tempActivePassangerIdentifierCodeMapper = {};
      if (action.payload.length !== 0) {
        action.payload[0].passengerSeatMaps.map((passengerSeatMap) => {
          if (passengerSeatMap.passenger.passengerInfo.type[0] === "A") {
            tempActivePassangerIdentifierCodeMapper[
              passengerSeatMap.passenger.passengerInfo.type[0] +
                passengerSeatMap.passenger.passengerIndex
            ] = "A" + AdultCounter;
            AdultCounter++;
          }
          if (passengerSeatMap.passenger.passengerInfo.type[0] === "C") {
            tempActivePassangerIdentifierCodeMapper[
              passengerSeatMap.passenger.passengerInfo.type[0] +
                passengerSeatMap.passenger.passengerIndex
            ] = "C" + ChildCounter;
            ChildCounter++;
          }
          if (passengerSeatMap.passenger.passengerInfo.type[0] === "I") {
            tempActivePassangerIdentifierCodeMapper[
              passengerSeatMap.passenger.passengerInfo.type[0] +
                passengerSeatMap.passenger.passengerIndex
            ] = "I" + InfantCounter;
            InfantCounter++;
          }
          return null;
        });
        return {
          ...state,
          segmentSeatMaps: action.payload,
          passangers: action.payload[0].passengerSeatMaps,
          activePassangerIdentifierCodeMapper:
            tempActivePassangerIdentifierCodeMapper,
        };
      } else {
        return {
          ...state,
          segmentSeatMaps: [],
          passangers: [],
          activePassangerIdentifierCodeMapper:
            tempActivePassangerIdentifierCodeMapper,
        };
      }

    case seatSelectionAction.CHANGEACTIVEPASSANGERCODE:
      return {
        ...state,
        activePassangerIdentifierCode: action.activePassangerIdentifierCode,
        passengerIndex: action.passengerIndex,
      };

    case seatSelectionAction.REMOVESEATSELECTION:
      temp = { ...state.selectedSeats };

      temp2 = { ...temp[action.identifier] };
      delete temp[action.identifier];

      delete temp[temp2.origin + temp2.destination][temp2.seatCode];

      return {
        ...state,
        selectedSeats: temp,
      };

    case seatSelectionAction.SEATSELECTING: {
      return { ...state, seatSelecting: action.value };
    }

    case seatSelectionAction.CLEARSEATSELECTIONENTRY:
      temp = {
        segmentSeatMaps: [],
        passengerIndex: 1,
        passangers: [],
        selectedSeats: {},
        activePassangerIdentifierCode: "A1",
        seatSelecting: false,
      };

      return {
        ...state,
        selectedSeats: {},
        myb: temp,
      };

    case seatSelectionAction.MYBUPDATECOOKIESABREDATAREQUEST: {
      return {
        ...state,
        cookieSabreDataRequest: action.cookieSabreDataRequest,
      };
    }
    //myb
    case seatSelectionAction.MBYSTORESEATRESULT:
      // state.myb.segmentSeatMaps = action.payload.segmentSeatMaps

      var manageBooking = {
        segmentSeatMaps: [],
        passengerIndex: 1,
        passangers: [],
        selectedSeats: {},
        activePassangerIdentifierCode: "A1",
        seatSelecting: false,
      };

      AdultCounter = 1;
      ChildCounter = 1;
      InfantCounter = 1;

      tempActivePassangerIdentifierCodeMapper = {};

      action.payload[0].passengerSeatMaps.map((passengerSeatMap) => {
        if (passengerSeatMap.passenger.passengerInfo.type[0] === "A") {
          tempActivePassangerIdentifierCodeMapper[
            passengerSeatMap.passenger.passengerInfo.type[0] +
              passengerSeatMap.passenger.passengerIndex
          ] = "A" + AdultCounter;
          AdultCounter++;
        }
        if (passengerSeatMap.passenger.passengerInfo.type[0] === "C") {
          tempActivePassangerIdentifierCodeMapper[
            passengerSeatMap.passenger.passengerInfo.type[0] +
              passengerSeatMap.passenger.passengerIndex
          ] = "C" + ChildCounter;
          ChildCounter++;
        }
        if (passengerSeatMap.passenger.passengerInfo.type[0] === "I") {
          tempActivePassangerIdentifierCodeMapper[
            passengerSeatMap.passenger.passengerInfo.type[0] +
              passengerSeatMap.passenger.passengerIndex
          ] = "I" + InfantCounter;
          InfantCounter++;
        }
      });

      manageBooking.passangers = action.payload[0].passengerSeatMaps;
      manageBooking.segmentSeatMaps = action.payload;

      manageBooking.passengerIndex = 1;
      manageBooking.selectedSeats = action.selectedSeats;
      manageBooking.activePassangerIdentifierCode = "A1";
      manageBooking.seatSelecting = false;
      return {
        ...state,
        myb: manageBooking,
        activePassangerIdentifierCodeMapper:
          tempActivePassangerIdentifierCodeMapper,
      };
    case seatSelectionAction.MYBREMOVESEATSELECTION:
      // var temp = { ...state.myb.selectedSeats };
      temp = { ...state.myb };

      temp2 = { ...temp.selectedSeats[action.identifier] };
      delete temp.selectedSeats[action.identifier];

      delete temp.selectedSeats[temp2.origin + temp2.destination][
        temp2.seatCode
      ];

      return {
        ...state,
        myb: temp,
      };

    case seatSelectionAction.MYBSELECTSEAT:
      passIndex = action.payload.passengerIndex;
      origin = action.payload.seatMapIdentifier.split("-");
      destination = origin[1];
      origin = origin[0];
      originDestinationIdentifier = origin + destination; //action.payload.originDestinationIdentifier;WNANCA
      var tempMYB = { ...state.myb };
      tempSelectedSeats = { ...tempMYB.selectedSeats };
      tempSelectedSeats[originDestinationIdentifier + passIndex] = {
        origin: origin,
        destination: destination,
        seatCode: action.payload.seatCode,
        seat: action.payload.seat,
      };
      temporartyDictionary = {
        ...tempSelectedSeats[originDestinationIdentifier],
      };

      temp = Object.keys(temporartyDictionary).filter(
        (key) =>
          temporartyDictionary[key].passengerIndex === state.passengerIndex
      );
      if (temp.length !== 0) {
        delete temporartyDictionary[temp[0]];
      }
      temporartyDictionary[action.payload.seatCode] = {
        seatCode: action.payload.seatCode,
        passengerIndex: passIndex,
        activePassangerIdentifierCode: state.activePassangerIdentifierCode,
      };
      tempSelectedSeats[originDestinationIdentifier] = temporartyDictionary;
      tempMYB.selectedSeats = tempSelectedSeats;
      return {
        ...state,
        myb: tempMYB,
      };
    default:
      return state;
  }
};

export default seatSelectionReducer;
