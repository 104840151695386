import React, { Component } from 'react';
import parse from 'html-react-parser';

import $ from 'jquery';
import Loading from '../../../seat-selection/Loading';

class FloCashRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            htmlResponse :null
        }
    }
    componentDidMount() {
        
        let redirectPath = this.props.location.state.redirectToPath;
        redirectPath.replace("><", ">\n<");
        let firstIndex = redirectPath.indexOf("body");
        let lastIndex = redirectPath.indexOf("<script>");
        let temp = redirectPath.substring(firstIndex, lastIndex);
        temp = temp.replace("body>", "<div>");
        temp = temp + "</div>";
        this.setState({ htmlResponse: temp });
        //$('html').empty();
        //$('html').append(temp);
    }
    render() {
        
        return (<div className="container bg-white py-4">
            <div className="py-4">
                <div dangerouslySetInnerHTML={{ __html: this.state.htmlResponse }} />
            </div>
          
        </div>);
    }
}
export default FloCashRedirect;