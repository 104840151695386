import axios from "axios";

export default class HttpService {
  static getHeaderAndURL = (token) => {
    //  let baseURL = "https://aks-cluster-dev.ethiopianairlines.com/flightbooking-etibe/V1.0";
    
    let baseURL = "https://m.ethiopianairlines.com/flightbooking-etibe/V1.0";
    let headers = {
      Authorization: "Bearer " + token,
      IsDevelopment: false,
      ContentType: "application/json",
      Accept: "application/json",
      "Accept-Language": "en",
    };
    return {
      baseUrl: baseURL,
      token: token,
      headers: headers,
    };
  };
  static postService = (requestData, relativePath, token, queryString = "") => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postServiceFloCash = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl = "https://m.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;

    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static ancillaryPostService = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://m.ethiopianairlines.com/etibe-flightancillary/V1.0/api" +
      relativePath;

    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postServiceManageTrip = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl = "https://aks-cluster-dev.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;

    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postServiceExchange = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl = "https://aks-cluster-dev.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;

    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static getService = (relativePath, queryString = "") => {
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.get(baseUrl + relativePath + queryString, {
      headers: headerAndURL.headers,
    });
  };
  static getCountryListService = (relativePath, queryString = "", token) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl = "https://m.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.get(baseUrl + queryString, {
      headers: headerAndURL.headers,
    });
  };
  static getTokenServive = () => {
    let xsrfToken = this.getCookie("XSRF-TOKEN"); //*get AntiforgeryToken from Cookies
    return axios.get("Home/Index", {
      headers: {
        "IBE-X-CSRF-TOKEN": xsrfToken,
      },
    });
  };

  static mybPostService = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl =
      "https://aks-cluster-dev.ethiopianairlines.com/etibe-managetrip/V1.0";
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static getCheckInService = (relativePath, queryString = "", token) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.baseUrl = "https://aks-cluster-dev.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.get(baseUrl + queryString, {
      headers: headerAndURL.headers,
    });
  };
  static checkInPostService = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    headerAndURL.headers.isdevelopment = headerAndURL.headers.IsDevelopment;
    delete headerAndURL.headers.IsDevelopment;
    headerAndURL.baseUrl = "https://aks-cluster-dev.ethiopianairlines.com";
    let baseUrl = headerAndURL.baseUrl + relativePath;
    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postManageTrip = (
    requestData,
    relativePath,
    token,
    queryString = ""
  ) => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://aks-cluster-dev.ethiopianairlines.com/etibe-managetrip/V1.0" +
      relativePath;
    return axios.post(baseUrl + queryString, requestData, {
      headers: headerAndURL.headers,
    });
  };

  static postGetFareCalender = (requestData, relativePath, token) => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://m.ethiopianairlines.com/flightbooking-etibe/V1.0" + relativePath;

    return axios.post(baseUrl, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postAccount = (requestData, relativePath, token) => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://aks-cluster-dev.ethiopianairlines.com/etibe-profileapi/V1.0/ShebaMile" +
      relativePath;
    return axios.post(baseUrl, requestData, {
      headers: headerAndURL.headers,
    });
  };
  static postFlightInfo = (requestData, relativePath, token) => {
    // etibe-flightinfoapi/V1.0/FlightSchedule/ByRoute
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://m.ethiopianairlines.com/etibe-flightinfoapi/V1.0" + relativePath;
    return axios.post(baseUrl, requestData, {
      headers: headerAndURL.headers,
    });
  };

  static getCountryCode = (ipAddress, token) => {
    let headerAndURL = this.getHeaderAndURL(token);
    let baseUrl =
      "https://m.ethiopianairlines.com/masterdataapi-etholidays/api/IpDetail/Get?IpAddress=" +
      ipAddress;
    return axios.get(baseUrl, {
      headers: headerAndURL.headers,
    });
  };
  static getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  static teleBirrPayment = (paymentObj, token) => {
    let headerAndURL = this.getHeaderAndURL(token);
   //test
    // let baseUrl =
    //   "https://aks-cluster-dev.ethiopianairlines.com/flocash-etibe/V1.0/TeleBirrPayment/CreateOrder";

    //   //prod
      let baseUrl =
        "https://m.ethiopianairlines.com/flocash-etibe/V1.0/TeleBirrPayment/CreateOrder";
    return axios.post(baseUrl, paymentObj, {
      headers: headerAndURL.headers,
    });
  };
}
