import HttpService from "../../shared/HttpService";
import SelectionModel from "../../../model/seat-selection/SelectionModel.js";

export class SeatSelectionService {
  getSeatMap = (cookieSabreData, token) => {
    let relativeUrl = "/Seat/Map";
    var searchRequest = {};
    searchRequest = cookieSabreData;

    return HttpService.postService(cookieSabreData, relativeUrl, token);
  };

  selectSeat = (cookieSabreData, token, payload) => {
    let relativeUrl = "/Seat/Select";
    var searchRequest = {};

    var origin = payload.seatMapIdentifier.split("-");
    var destination = origin[1];
    origin = origin[0];
    var selectionPayload = new SelectionModel();

    selectionPayload.selectedSegment.origin = origin;
    selectionPayload.selectedSegment.destination = destination;
    selectionPayload.selectedSegment.departure = payload.departure;
    selectionPayload.passengerIndex = payload.passengerIndex;
    selectionPayload.seatCode = payload.seatCode;

    var seatOperations = Array(selectionPayload);
    searchRequest.cookieSabreDataRequest = cookieSabreData;
    searchRequest.seatOperations = seatOperations;
    // console.log(searchRequest);

    return HttpService.postService(searchRequest, relativeUrl, token);
  };

  GetPaymentOption = (cookieSabreData, token) => {
    let relativeUrl =
      "/etibe-managetrip/V1.0/MTOPaymentOption/GetPaymentOption";

    var searchRequest = {
      IsToday: false,
      IsFlightWithIn48Hours: false,
      CountryCode: "ET",
      AirportCode: "ADD",
      DepartureDateTime: "2021-05-29T21:45:00",
      paymentType: 1,
    };
    searchRequest.cookieSabreDataRequest = cookieSabreData;

    return HttpService.mybPostService(searchRequest, relativeUrl, token);
  };
}
