import { React, Component } from "react";
import { connect } from "react-redux";
// import moment from "moment";
// import "./FlightList.css";
import OutsideClickHandler from "react-outside-click-handler";
import Translation from "i18next";
import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortDown, faSortUp,
} from "@fortawesome/free-solid-svg-icons";

class FlightScheduleSort extends Component {
  state = {
    SortOption: "ByDepartureTime",
    orderBy: "ASC",
    isSortPossible: false,
    isClosed: true,
  };
  SortOptions = {
    ByDepartureTime: "ByDepartureTime",
    ByNoOfStops: "ByNoOfStops",
  };
  orderBy = {
    ASC: "ASC",
    DESC: "DESC",
  };
  IsByBusinessPricePossible = false;
  IsByEconomyPricePossible = false;

  constructor(props) {
    super(props);
    this.state.isSortPossible = (this.props.filterCriteria.Departure.min !==
    this.props.filterCriteria.Departure.max) || (this.props.filterCriteria.NoOfStops.length > 1);
  }

  cleanUpPrice= (price) => {
    if(price === "N/A")
      return 0;
    //remove currency
    price = price.split(" ")[0];
    //remove comma
    price = price.split(",");
    if(price.length === 1)
      price = price[0];
    else{
      var tempPrice = "";
      for(var i = 0; i < price.length; i ++){
        tempPrice = tempPrice + price[i];
      }
      price = tempPrice;
    }
    return parseInt(price);
  }

  sortFlights = (array, searchType, orderBy) => {
    var resultArray = array;
    switch (searchType) {
      case this.SortOptions.ByDepartureTime:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.flights[0].departure > secondElement.flights[0].departure) return -1;
          if (firstElement.flights[0].departure < secondElement.flights[0].departure) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByArrivalTime:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.flights[firstElement.flights.length-1].arrival > secondElement.flights[secondElement.flights.length-1].arrival) return -1;
          if (firstElement.flights[firstElement.flights.length-1].arrival < secondElement.flights[secondElement.flights.length-1].arrival) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByNoOfStops:
        resultArray = array.sort((firstElement, secondElement) => {
          if (
            firstElement.totalStops > secondElement.totalStops
          )
            return -1;
          if (
            firstElement.totalStops < secondElement.totalStops
          )
            return 1;
          return 0;
        });
        break;
      default:
        break;
    }
    return orderBy === this.orderBy.ASC ? resultArray.reverse() : resultArray;
  };

  handleSortChoice = (SortOption, orderBy) => {
    this.setState({
      SortOption: SortOption,
      orderBy: orderBy,
      isClosed: true,
    });
    var temp = this.props.flightSchedules;
    temp = this.sortFlights(temp, SortOption, orderBy);
    // console.log(temp)
    this.props.updateFlightSchedules(temp);
  };
  loading() {
    return (
      <div style={{ height: "48px" }} className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          this.loading()
        ) : (
          <OutsideClickHandler
            display={"inline-block"}
            onOutsideClick={() => this.setState({ isClosed: true })}
          >
            <button
              disabled={this.state.isSortPossible ? "" : "disabled"}
              className="itinerary-part-offer-header-label pl-2 pl-sm-3 pl-md-4 sort-by-button d-flex"
              onClick={() => this.setState({ isClosed: !this.state.isClosed })}
            >
              <span className="d-flex">{Translation.t("sort_by")}</span>
              <span className={this.state.isClosed ? "d-flex align-items-center" : "d-flex align-items-start"} style={{maxHeight : "16px"}}>
                <FontAwesomeIcon
                  icon={this.state.isClosed ? faSortDown : faSortUp}
                  className="pl-2"
                  size={"2x"}
                />
              </span>
            </button>

            <div
              className={
                this.state.isClosed
                  ? "d-none"
                  : "row col-10 col-sm-7 col-xl-5 px-0 filter-list-container"
              }
            >
              {this.props.filterCriteria.Departure.min !==
              this.props.filterCriteria.Departure.max ? (
                <>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByDepartureTime &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={
                      () =>
                        this.handleSortChoice(
                          this.SortOptions.ByDepartureTime,
                          this.orderBy.ASC
                        )
                    }
                  >
                    {Translation.t("by_departure_date_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByDepartureTime &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={
                      () =>
                        // this.setState({
                        //   SortOption: this.SortOptions.ByDepartureTime,
                        //   orderBy: this.orderBy.DESC,
                        //   isClosed: true,
                        // }),
                        this.handleSortChoice(
                          this.SortOptions.ByDepartureTime,
                          this.orderBy.DESC
                        )
                      // this.handleSortChoice)
                    }
                  >
                    {Translation.t("by_departure_date_descending")}
                  </div>
                </>
              ) : null}

              {this.props.filterCriteria.NoOfStops.length > 1 ? (
                <>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByNoOfStops &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByNoOfStops,
                        this.orderBy.ASC
                      )
                    }
                  >
                    {Translation.t("by_no_of_stops_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByNoOfStops &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByNoOfStops,
                        this.orderBy.DESC
                      )
                    }
                  >
                    {Translation.t("by_no_of_stops_descending")}
                  </div>
                </>
              ) : null}
            </div>
          </OutsideClickHandler>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    filterCriteria: state.flightSchedule.FilterCriteria,
    flightSchedules: state.flightSchedule.flightSchedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFlightSchedules: (value) => {
      dispatch({
        type: flightScheduleAction.UPDATEFLIGHTSCHEDULES,
        payload: value,
      });
    },
  };
};
export default connect(mapStateTopProps,mapDispatchToProps)(FlightScheduleSort);
