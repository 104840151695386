import { React, Component } from "react";
import AirportSelection from "../airport-selection/AirportSelection";
import DatePicker from "../booking-calendar/DatePicker";
// import Translation from "../../../../service/shared/multi-lingual/Translation";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import { Modal, Form, Row, Col } from "react-bootstrap";
import Translation from "i18next";
import "./StopOver.css";
import moment from "moment";

class StopOver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      stopOverIndex: 0,
      stopOverAdded : false
    };
  }

  addStopover = (event) => {
    this.setState({ stopOverIndex: event.target.value, stopOverAdded : true });
    this.handleShow();
    if (this.props.itineraryParts.length < 2) {
      this.props.ADDSTOPOVER({
        from: this.props.itineraryParts[event.target.value].params.to,
        to: this.props.itineraryParts[event.target.value].params.from,
      });
    }
  };

  handleSelectedStopover = (event) => {
    let element = event.target;
    this.props.updateStopDays({
      stopDays: element.options[element.selectedIndex].value,
      flightIndex: this.state.stopOverIndex,
    });
  };

  getStopOverPossibleDays = (index) => {
    return 7;
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  displayADDStopOverButton = (departureAirpot, arrival, index) => {
    if (
      (departureAirpot !== null &&
        (departureAirpot.value.indexOf("ADD") !== -1 ||
          departureAirpot.value.indexOf("add") !== -1)) ||
      (arrival !== null &&
        (arrival.value.indexOf("ADD") !== -1 ||
          arrival.value.indexOf("add") !== -1))
    ) {
      return;
    } else {
      return (
        <>
          <button
            className="std-et-bg-color text-white btn d-flex ml-3 btn-sm"
            value={index}
            onClick={(event) => this.addStopover(event)}
            disabled={index === 5}
          >
            +
          </button>
          <span className="pl-2 d-flex align-items-center">
            {this.props.itineraryParts[index].params.stopDays === "init"
              ? Translation.t("add_stopover")
              : Translation.t(
                  this.props.itineraryParts[index].params.stopDays +
                    "_nights_stopover_in_addis"
                )}
          </span>
          </>
      );
    }
  };

  calculateMinDate = (flightIndex) => {
    if (flightIndex === 0) {
      return null;
    } else {
      var lastDepartureDate = this.props.itineraryParts[flightIndex - 1].params
        .when.departureDate;
      if (lastDepartureDate == null) {
        return null;
      }
      if(this.props.itineraryParts[flightIndex - 1].params.stopDays !== "init"){
        lastDepartureDate = moment(lastDepartureDate).add(this.props.itineraryParts[flightIndex - 1].params.stopDays, "days").format("YYYY-MM-DD");
      }
      return lastDepartureDate;
    }
  };

  removeStopover = (event) => {
    this.setState({stopOverAdded : false });
    this.props.removeStopover({
      flightIndex: event.target.value,
    });
  };

  render() {
    return (
      <div className="">
        <h4 className="h4 mb-0 font-weight-bold">Stopover</h4>
        {this.props.itineraryParts.slice(0, 2).map((itineraryPart, index) =>
          this.state.stopOverAdded ||
          index === 0 ||
          itineraryPart.params.stopDays !== "init" ? (
            <div key={index} className="my-3">
              {this.props.displayLabel && (
                <Row>
                  <div className="col-12">
                    <h5>Airports</h5>
                  </div>
                </Row>
              )}
              <Row>
                <AirportSelection flightIndex={index} />
                {index !== 0 ? (
                  <button
                    value={index}
                    className="btn btn-danger btn-sm remove_stopover_btn"
                    onClick={this.removeStopover}
                  >
                    x
                  </button>
                ) : null}
              </Row>
              {this.props.displayLabel && (
                <Row>
                  <Col>
                    <h5 className="mt-3">Travel Date</h5>
                  </Col>
                </Row>
              )}
              <Row>
                <DatePicker
                  flightIndex={index}
                  minDate={this.calculateMinDate(index)}
                  name={"departureDate" + index}
                />
              </Row>
              <Row>
                {this.displayADDStopOverButton(
                  itineraryPart.params.from,
                  itineraryPart.params.to,
                  index
                )}
              </Row>
            </div>
          ) : null
        )}
        <Modal
          animation={false}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{Translation.t("Select Stopover")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              <Form.Control as="select" onChange={this.handleSelectedStopover}>
                <option value="0">
                  {Translation.t("Please Select Stopover")}
                </option>
                {[
                  ...Array(
                    this.getStopOverPossibleDays(this.state.stopOverIndex) + 1
                  ),
                ].map((x, i) => (
                  <option key={i} value={i}>
                    {Translation.t(i + "_nights_stopover_in_addis")}
                  </option>
                ))}
              </Form.Control>
            }
          </Modal.Body>
          <Modal.Footer>
            <button className="btn" onClick={this.handleClose}>
              {Translation.t("Ok")}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    itineraryParts: state.bookingWiget.itineraryPart,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ADDSTOPOVER: (value) =>
      dispatch({
        type: BookingWidgetAction.ADDSTOPOVER,
        payload: value,
      }),
    updateStopDays: (value) =>
      dispatch({
        type: BookingWidgetAction.UPDATESTOPDAYS,
        payload: value,
      }),
    removeStopover: (value) =>
      dispatch({
        type: BookingWidgetAction.REMOVESTOPOVER,
        payload: value,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(StopOver);
