import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
  faInstagram,
  faWeibo,
} from "@fortawesome/free-brands-svg-icons";
import { Col, Row } from "react-bootstrap";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer pt-0">
        <div className="w-100 ">
          <Row className="footer-container">
            <Col>
              <p
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "18px",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                &copy;Ethiopian Airlines
              </p>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} className="text-center">
              <a
                href="https://www.facebook.com/EthiopianAirlines/"
                className="social-links-container fa-facebook"
                title="Facebook"
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  className="social-links-fontawsome"
                />
              </a>
              <a
                href="https://twitter.com/flyethiopian"
                className="social-links-container fa-twitter"
                title="Twitter"
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  className="social-links-fontawsome"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/ethiopian-airlines/"
                className="social-links-container fa-linkedin"
                title="LinkedIn"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="social-links-fontawsome"
                />
              </a>
              <a
                href="https://www.youtube.com/user/flyethiopian"
                className="social-links-container fa-youtube"
                title="Youtube"
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  className="social-links-fontawsome"
                />
              </a>
              <a
                href="https://www.instagram.com/fly.ethiopian/"
                className="social-links-container fa-instagram"
                title="Instagram"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="social-links-fontawsome"
                />
              </a>
              <a
                href="http://weibo.com/ethiopianairlinesCN"
                className="social-links-container fa-weibo"
                title="Weibo"
              >
                <FontAwesomeIcon
                  icon={faWeibo}
                  className="social-links-fontawsome"
                />
              </a>
            </Col>
          </Row>
        </div>
      </footer>
      //   <footer className="footer mt-5">
      //     <div className="footer-text">
      //       <div className="row m-0 justify-content-between">
      //         <div className="col-12 col-sm-3 mt-4">
      //           <p style={{ textAlign: "center" }}>&copy;Ethiopian Airlines</p>
      //         </div>
      //         <div className="col-12 col-sm-9 col-md-6 mt-0 mt-sm-4 d-flex justify-content-center justify-content-sm-end justify-content-md-center">
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faFacebookF} className="social-links-fontawsome" />
      //           </a>
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faTwitter} className="social-links-fontawsome" />
      //           </a>
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faLinkedinIn} className="social-links-fontawsome" />
      //           </a>
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faYoutube} className="social-links-fontawsome" />
      //           </a>
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faInstagram} className="social-links-fontawsome" />
      //           </a>
      //           <a href="https://www.ethiopianholidays.com/" className="social-links-container">
      //             <FontAwesomeIcon icon={faWeibo} className="social-links-fontawsome" />
      //           </a>
      //         </div>
      //       </div>
      //     </div>
      //   </footer>
    );
  }
}
