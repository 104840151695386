import React from "react";
import {
  Accordion,
  Card,
  Row,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faPlane,
  faAngleUp,
  faChair,
  faLuggageCart,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import HttpService from "../../../service/shared/HttpService";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";

import MybSeatSelectionService from "../../../service/manage-my-booking/seat-selection/MybSeatSelectionService";

class ManageTripShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seatAccordion: -1,
      extraAccordion: -1,
      sucharchgesAccordion: -1,
      taxesAccordion: -1,
      showFareRule: false,
      showBaggageInformation: false,
      checkedIn: 1,
      carryOn: -1,
    };
  }
  hideBaggageInfo = () => {
    this.setState({ showBaggageInformation: false });
  };
  hideFareRule = () => {
    this.setState({ showFareRule: false });
  };
  currencyForSeat = "";
  currentPageTracker = 2;
  mybSeatSelectionService = null;

  totalFinder = () => {
    if (this.props.isNewBagAdded) {
      return (
        parseFloat(this.props.totalPriceBaggage) -
        parseFloat(this.props.oldPrice)
      );
    } else {
      return "";
    }
  };

  componentDidMount = () => {
    var sabreCookies = null;
    this.mybSeatSelectionService = new MybSeatSelectionService();
    if (this.props.searchFlight.selectionResult !== null) {
      sabreCookies =
        this.props.searchFlight.selectionResult.cookieSabreDataRequest;
    }

    if (this.props.fareRule === null && sabreCookies !== null) {
      HttpService.postService(
        sabreCookies,
        "/Booking/GetFareRule",
        this.props.searchFlight.token
      )
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            this.props.saveFareRule(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (this.props.baggageInfo === null && sabreCookies !== null) {
      HttpService.postService(
        sabreCookies,
        "/Bag/GetAllowance",
        this.props.searchFlight.token
      )
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            this.props.saveBaggageInfo(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  carryOnBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCarryOn.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>
                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              {passengerBaggageDisclosure.baggageRestrictions.map(
                                (baggageRestrictions, key4) => (
                                  <>
                                    <li>
                                      <span key={key4}>
                                        <Translation Key="dimension" /> :{" "}
                                        {
                                          baggageRestrictions.dimensionDescription
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </li>
                                  </>
                                )
                              )}
                            </ul>
                            <Translation Key="additional_info" /> :
                            <h6 className="text-center">
                              {passengerBaggageDisclosure.additionalInfo}
                            </h6>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  checkedInBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCheckeId.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>

                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              <li>
                                {passengerBaggageDisclosure.baggageRestrictions.map(
                                  (baggageRestrictions, key4) => (
                                    <span key={key4}>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </span>
                                  )
                                )}
                              </li>
                              <li>
                                <Translation Key="total_weight" /> :{" "}
                                {passengerBaggageDisclosure.totalWeight}
                              </li>
                            </ul>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  retunBaggageInfo = () => {
    try {
      return (
        <Accordion defaultActiveKey="Checked-in">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Checked-in"
              className="baggage-info active-shopping-cart-header"
              onClick={() => {
                if (this.state.checkedIn === 1) {
                  this.setState({ checkedIn: -1 });
                } else {
                  this.setState({ checkedIn: 1 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.checkedIn === 1 ? faAngleUp : faAngleDown}
                  size="2x"
                  color="#555"
                  className="float-right"
                />
              </span>

              <h4 className="float-rigth">
                {" "}
                <Translation Key="checked_in_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Checked-in">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="checked_in_baggage_body" />
                </h5>
                {this.checkedInBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className=" ">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Carry-on"
              className="baggage-info"
              onClick={() => {
                if (this.state.carryOn === 2) {
                  this.setState({ carryOn: -1 });
                } else {
                  this.setState({ carryOn: 2 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.carryOn === 2 ? faAngleUp : faAngleDown}
                  size="2x"
                  className="float-right"
                />
              </span>

              <h4>
                {" "}
                <Translation Key="carry_on_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Carry-on">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="Carry_on_baggage_body" />
                </h5>
                {this.carryOnBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };
  getHoursFromMins = (mins) => {
    if (mins <= 60) {
      return (
        <span>
          {"  "}
          {mins} {" mins"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {parseInt(mins / 60)}
          {" hr(s) "} {mins % 60} {" mins"}
        </span>
      );
    }
  };
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  removeSeat = (
    flightIdentifier,
    seatMapIdentifier,
    departure,
    passengerIndex
  ) => {
    this.props.seatSelectingUpdate(true);

    var payload = {
      departure: departure,
      seatCode: null,
      origin: seatMapIdentifier.split("-")[0],
      destination: seatMapIdentifier.split("-")[1],
      passengerIndex: passengerIndex,
    };

    this.mybSeatSelectionService
      .selectSeat(payload, this.props.token, this.props.cookieSabreDataRequest)
      .then((response) => {
        this.props.seatSelectingUpdate(false);
        this.props.removeSelectedSeat(flightIdentifier, passengerIndex);
      });
  };
  calculateSeatTotalPrice = () => {
    var temp = Object.keys(this.props.selectedSeats).filter(
      (key) => "seat" in this.props.selectedSeats[key]
    );
    var total = 0;
    for (var i = 0; i < temp.length; i++) {
      if (
        !this.props.selectedSeats[temp[i]].seat.freeOfCharge &&
        this.props.selectedSeats[temp[i]].seat.total.alternatives.length !== 0
      ) {
        total =
          total +
          this.props.selectedSeats[temp[i]].seat.total.alternatives[0][0]
            .amount;
        this.currencyForSeat =
          this.props.selectedSeats[
            temp[i]
          ].seat.total.alternatives[0][0].currency;
      }
    }
    return total;
  };
  getSelectedSeats = () => {
    return (
      <>
        {/* {console.log(this.props.segmentSeatMaps)} */}
        {/* {this.props.segmentSeatMaps.map((segmentSeatMap) => {
              <h4>{segmentSeatMap.segment.origin + "-" + segmentSeatMap.segment.destination}</h4>
              console.log(segmentSeatMap)
            })} */}
        {this.props.segmentSeatMaps.map((segmentSeatMap) => {
          return (
            <>
              <span className="shopping-cart-seat-header d-flex">
                {"Flight : " +
                  segmentSeatMap.segment.origin +
                  "-" +
                  segmentSeatMap.segment.destination}
              </span>
              <div className="passanger-lists">
                <i class="fas fa-chair-office"></i>
                {this.props.segmentSeatMaps[0].passengerSeatMaps.map(
                  (passengerSeatMap) => {
                    return (
                      <div className="single-passange row align-items-center py-1">
                        <OverlayTrigger
                          key={passengerSeatMap.passenger.passengerIndex}
                          placement="left"
                          overlay={
                            <Tooltip
                              id={
                                "tooltip-" +
                                passengerSeatMap.passenger.passengerIndex
                              }
                            >
                              <span className="text-uppercase p-2">
                                {passengerSeatMap.passenger.passengerDetails
                                  .firstName +
                                  " " +
                                  passengerSeatMap.passenger.passengerDetails
                                    .lastName}
                              </span>
                            </Tooltip>
                          }
                        >
                          <span
                            className={
                              passengerSeatMap.passenger.passengerInfo.type ===
                              "INF"
                                ? "passanger-identifier-infant-shopping d-inline-flex"
                                : "passanger-identifier-shopping d-inline-flex"
                            }
                          >
                            {/* {console.log(this.passengerSeatMaps)} */}
                            {this.props.activePassangerIdentifierCodeMapper[passengerSeatMap.passenger.passengerInfo.type[0] +
                              passengerSeatMap.passenger.passengerIndex]}
                          </span>
                        </OverlayTrigger>
                        {segmentSeatMap.segment.origin +
                          segmentSeatMap.segment.destination +
                          passengerSeatMap.passenger.passengerIndex in
                        this.props.selectedSeats ? (
                          <>
                            <span className="shopping-cart-selected-seat-name d-inline-flex">
                              {" Seat " +
                                this.props.selectedSeats[
                                  segmentSeatMap.segment.origin +
                                    segmentSeatMap.segment.destination +
                                    passengerSeatMap.passenger.passengerIndex
                                ].seatCode}
                            </span>
                            <span className="shopping-cart-selected-seat-price d-inline-flex">
                              {this.props.selectedSeats[
                                segmentSeatMap.segment.origin +
                                  segmentSeatMap.segment.destination +
                                  passengerSeatMap.passenger.passengerIndex
                              ].seat !== undefined ? this.props.selectedSeats[
                                segmentSeatMap.segment.origin +
                                  segmentSeatMap.segment.destination +
                                  passengerSeatMap.passenger.passengerIndex
                              ].seat.freeOfCharge
                                ? " Free "
                                : !this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.freeOfCharge &&
                                  this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.total.alternatives.length !== 0
                                ? this.numberWithCommas(
                                    this.props.selectedSeats[
                                      segmentSeatMap.segment.origin +
                                        segmentSeatMap.segment.destination +
                                        passengerSeatMap.passenger
                                          .passengerIndex
                                    ].seat.total.alternatives[0][0].amount
                                  ) +
                                  " " +
                                  this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.total.alternatives[0][0].currency
                                : null : null}
                            </span>
                            <button
                              className="clear-seat-selection d-inline-flex"
                              onClick={() =>
                                this.removeSeat(
                                  segmentSeatMap.segment.origin +
                                    segmentSeatMap.segment.destination +
                                    passengerSeatMap.passenger.passengerIndex,
                                  segmentSeatMap.segment.origin +
                                    "-" +
                                    segmentSeatMap.segment.destination,
                                  segmentSeatMap.segment.departure,
                                  passengerSeatMap.passenger.passengerIndex
                                )
                              }
                            >
                              x
                            </button>
                          </>
                        ) : (
                          <span className="passanger-name-shopping-cart d-inline-flex">
                            {passengerSeatMap.passenger.passengerInfo.type ===
                            "INF"
                              ? "n/a (Infant)"
                              : " -  - "}
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };
  updateLocalVariableCurrentPageTracker() {
    var url = window.location.pathname;
    if (url.includes("/myb/edit-ancillaries")) {
      this.currentPageTracker = 2;
    } else if (url.includes("/myb/payment")) {
      this.currentPageTracker = 2;
    }
    // console.log(this.currentPageTracker);
  }
  getExtras = () => {
    var totalPrice = null;
    if (this.props.isNewBagAdded) {
      return (
        <>
          {Object.keys(this.props.ancillaryListForShoppingCarts).map((key) => {
            var ancillaryListForShoppingCart =
              this.props.ancillaryListForShoppingCarts[key];
            return (
              <>
                <span className="shopping-cart-seat-header d-flex">
                  {"Flight : " + key}
                </span>
                {ancillaryListForShoppingCart.map(
                  (ancillaryListForShoppingCartSingle) => {
                    return (
                      <div className="passanger-lists">
                        <i class="fas fa-chair-office"></i>
                        <div className="single-passange row align-items-center py-1">
                          <OverlayTrigger
                            key={
                              ancillaryListForShoppingCartSingle.passengerIndex
                            }
                            placement="left"
                            overlay={
                              <Tooltip
                                id={
                                  "tooltip-" +
                                  ancillaryListForShoppingCartSingle.passengerIndex
                                }
                              >
                                <span className="text-uppercase p-2">
                                  {ancillaryListForShoppingCartSingle.firstName +
                                    " " +
                                    ancillaryListForShoppingCartSingle.lastName}
                                </span>
                              </Tooltip>
                            }
                          >
                            <span
                              className={
                                ancillaryListForShoppingCartSingle.type ===
                                "INF"
                                  ? "passanger-identifier-infant-shopping d-inline-flex"
                                  : "passanger-identifier-shopping d-inline-flex"
                              }
                            >
                              {ancillaryListForShoppingCartSingle.type[0] +
                                ancillaryListForShoppingCartSingle.passengerIndex}
                            </span>
                          </OverlayTrigger>
                          <>
                            <span className="shopping-cart-selected-seat-name d-inline-flex">
                              {ancillaryListForShoppingCartSingle.ancillaryName}
                            </span>
                            <span className="shopping-cart-selected-seat-price d-inline-flex">
                              {this.numberWithCommas(
                                ancillaryListForShoppingCartSingle.price
                              )}{" "}
                              {this.props.currency}
                            </span>
                          </>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            );
          })}
        </>
      );
    } else {
      return <div className="h4 text-center">No Extras Added Yet!</div>;
    }
  };
  shoppingCartBody = () => {
    return (
      <>
        {/* <FareRule
          showFareRule={this.state.showFareRule}
          hideFareRule={this.hideFareRule}
        />
        <BaggageInfo
          showBaggageInformation={this.state.showBaggageInformation}
          hideBaggageInfo={this.hideBaggageInfo}
        /> */}
        <div className="shopping-cart mt-4 flight-selection-container">
          <Accordion defaultActiveKey="0" style={{ padding: 0, margin: 0 }}>
            <Card>
              <h1 className="ml-3 shopping-cart-header">
                {" "}
                <Translation Key="trip-cost" />{" "}
              </h1>
            </Card>

            {this.props.fromSeat ? (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="1"
                  className="shopping-cart-header-border"
                  onClick={() => {
                    if (this.state.seatAccordion === 1) {
                      this.setState({ seatAccordion: -1 });
                    } else {
                      this.setState({ seatAccordion: 1 });
                    }
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faChair}
                      className={
                        this.state.seatAccordion === 1 &&
                        this.currentPageTracker >= 2
                          ? "shopping-cart-active shopping-cart-left-icon"
                          : "shopping-cart-disapled shopping-cart-left-icon"
                      }
                    />
                  </span>
                  <FontAwesomeIcon
                    icon={
                      this.state.seatAccordion === 1 &&
                      this.currentPageTracker >= 2
                        ? faAngleUp
                        : faAngleDown
                    }
                    className="shopping-cart-right-icon"
                  />

                  <span
                    className={
                      this.currentPageTracker >= 2
                        ? "mx-3 shopping-cart-title shopping-cart-header"
                        : "mx-3 shopping-cart-disapled shopping-cart-header"
                    }
                  >
                    {" "}
                    <Translation Key="seats" />{" "}
                  {this.calculateSeatTotalPrice() !== 0 ? (
                    <span className="float-right shopping-cart-selected-total-seat-price">
                      {this.numberWithCommas(this.calculateSeatTotalPrice()) +
                        " " +
                        this.currencyForSeat}
                    </span>
                  ) : null}
                </span>
                </Accordion.Toggle>
                {this.currentPageTracker >= 2 ? (
                  <Accordion.Collapse
                    className="shopping-cart-collapse"
                    eventKey="1"
                  >
                    <Card.Body className="px-0 shopping-cart-seat-section">
                      {this.getSelectedSeats()}
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}
              </Card>
            ) : (
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="1"
                  className="shopping-cart-header-border"
                  onClick={() => {
                    if (this.state.extraAccordion === 1) {
                      this.setState({ extraAccordion: -1 });
                    } else {
                      this.setState({ extraAccordion: 1 });
                    }
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faLuggageCart}
                      className={
                        this.state.extraAccordion === 1 &&
                        this.currentPageTracker >= 2
                          ? "shopping-cart-active shopping-cart-left-icon"
                          : "shopping-cart-disapled shopping-cart-left-icon"
                      }
                    />
                  </span>
                  <FontAwesomeIcon
                    icon={
                      this.state.extraAccordion === 1 &&
                      this.currentPageTracker >= 2
                        ? faAngleUp
                        : faAngleDown
                    }
                    className="shopping-cart-right-icon"
                  />

                  <span
                    className={
                      this.currentPageTracker >= 2
                        ? "mx-3 shopping-cart-title  shopping-cart-header"
                        : "mx-3 shopping-cart-disapled  shopping-cart-header"
                    }
                  >
                    {" "}
                    <Translation Key="extras" />
                    {this.props.isNewBagAdded ? (
                      <span className="float-right shopping-cart-selected-total-seat-price">
                        {this.numberWithCommas(
                          this.props.totalPriceBaggage - this.props.oldPrice
                        )}{" "}
                        {this.props.isNewBagAdded ? (
                          <span>{this.props.currency}</span>
                        ) : null}
                      </span>
                    ) : null}
                  </span>
                </Accordion.Toggle>
                {this.currentPageTracker >= 2 ? (
                  <Accordion.Collapse
                    className="shopping-cart-collapse"
                    eventKey="1"
                  >
                    <Card.Body className="px-0 shopping-cart-seat-section">
                      {this.getExtras()}
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}
              </Card>
            )}

            <Card>
              <Row className="m-4 shopping-cart-total-price">
                <div className="col-6 col-sm-6 col-md-5 col-lg-5">
                  <span className="float-left shopping-cart-total-price">
                    <Translation Key="trip_total" />
                  </span>
                </div>
                <div className="col-6 col-sm-6 col-md-7 col-lg-7">
                  <span className="float-right">
                    {this.numberWithCommas(this.totalFinder())}{" "}
                    <span style={{ fontSize: "1.4rem" }}>
                      {this.props.isNewBagAdded ? (
                        <span>{this.props.currency}</span>
                      ) : null}
                    </span>
                  </span>
                </div>
              </Row>
              <p className="col-12 shopping-cart-label">
                <span>
                  <Translation Key="see_applicable" />{" "}
                </span>{" "}
                <span
                  className="shopping-cart-label-tag"
                  onClick={() => this.setState({ showFareRule: true })}
                >
                  <Translation Key="fare_rules" />
                </span>{" "}
                <span>
                  <Translation Key="and" />
                </span>{" "}
                <span
                  className="shopping-cart-label-tag"
                  onClick={() =>
                    this.setState({ showBaggageInformation: true })
                  }
                >
                  <Translation Key="baggage_info" />
                </span>
              </p>
            </Card>
          </Accordion>
        </div>
      </>
    );
  };
  render() {
    this.updateLocalVariableCurrentPageTracker();

    if (this.props.isMedium) {
      return (
        <>{this.props.showShoppingCart && <>{this.shoppingCartBody()}</>}</>
      );
    } else {
      return <>{this.shoppingCartBody()}</>;
    }
  }
}
const mapStateTopProps = (state) => {
  return {
    fareRule: state.shoppingCart.fareRule,
    baggageInfo: state.shoppingCart.baggageInfo,
    searchFlight: state.searchFlight,
    isMedium: state.global.isMedium,
    currency: state.manageAncillary.currency,
    token: state.searchFlight.token,
    isNewBagAdded: state.manageAncillary.isNewBagAdded,
    ancillaryListForShoppingCarts:
      state.manageAncillary.ancillaryListForShoppingCart,
    totalPriceBaggage: state.manageAncillary.finaloverAllTotal,
    baggageRequest: state.manageAncillary.baggageRequest,
    oldPrice: state.manageAncillary.oldValue,
    segmentSeatMaps: state.seatSelection.myb.segmentSeatMaps,
    selectedSeats: state.seatSelection.myb.selectedSeats,
    myb: state.seatSelection.myb,
    cookieSabreDataRequest: state.seatSelection.cookieSabreDataRequest,
    activePassangerIdentifierCodeMapper: state.seatSelection.activePassangerIdentifierCodeMapper,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSelectedSeat: (identifier) => {
      dispatch({
        type: seatSelectionAction.MYBREMOVESEATSELECTION,
        identifier: identifier,
      });
    },
    seatSelectingUpdate: (value) =>
      dispatch({
        type: seatSelectionAction.SEATSELECTING,
        value: value,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(ManageTripShoppingCart);
