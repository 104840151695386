import React, { useState } from "react";

import { connect } from "react-redux";
// import FlightList from "../flight list temp/FlightList";

import { Row } from "react-bootstrap";
import AdultYear from "../../../master-data/shared/adultYear.json";
import ChildYear from "../../../master-data/shared/childYear.json";
import InfantYear from "../../../master-data/shared/infantYear.json";
import Month from "../../../master-data/shared/month.json";
import Day from "../../../master-data/shared/day.json";
import countryList from "../../../master-data/shared/countryList.json";
import Translation from "i18next";
import moment from "moment";
import CheckInBreadCrumb from "../shared/bread-crumb/CheckInBreadCrumb";
import "./passenger.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShoppingCart from "../shared/shoppingCart/ShoppingCart";

import {
  faExclamationTriangle,
  faArrowLeft,
  faChair,
  faUser,
  faArrowRight,
  faAngleDown,
  faAngleUp,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import { DocumentModel } from "../../../model/checkIn/DocumentModel";
import { AddressModel } from "../../../model/checkIn/AddressModel";

import { AirportFullName } from "../../../service/shared/AirportFullName";
import { checkInAction } from "../../../redux/actions/check-in/checkInAction";
import CheckInService from "../../../service/check-in/CheckInService";
import Loading from "../../booking/seat-selection/Loading";

const AllPassangers = (props) => {
  const selectStyle = AirportSelectStyle.desktopStyle;
  const passengerService = new PassengerService();
  let destinationCountry = "";

  const checkInPostService = new CheckInService();

  const [IssueYear, setIssueYear] = useState(() => {
    var temp = [];
    var currentYear = parseInt(moment().locale("en").format("YYYY"));

    [...Array(21)].map((element, index) => {
      var year = currentYear - index;
      var tempOption = { value: year, label: year };
      temp.push(tempOption);
      return null;
    });
    return temp;
  });
 
  const [ExpiaryYear, setExpiaryYear] = useState(() => {
    var temp = [];
    var currentYear = parseInt(moment().locale("en").format("YYYY"));

    [...Array(25)].map((element, index) => {
      var year = currentYear + index;
      var tempOption = { value: year, label: year };
      temp.push(tempOption);
      return null;
    });
    return temp;
  });

  const [ErrorMessage, setErrorMessage] = useState("");

  const [updatingDoc, setUpdatingDoc] = useState(false);
  const [frequentFlyer, setFrequentFlyer] = useState({
    memberAirline: "",
    memberId: "",
  });

  let history = useHistory();
  const handleChange = (event, passengerIdentifier) => {
    var payload = {
      status: event.target.checked,
      passengerIdentifier: passengerIdentifier,
    };

    props.updateActivePassengers(payload);
  };
  const redirectToIternary = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push("/check-in/itinerary");
  };
  const handleContinueClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push("/check-in/seats");
  };
  const [passengerDetailIndicator, setPassengerDetailIndicator] = useState(
    () => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      const initialState = temp;
      return initialState;
    }
  );

  const [documentTracker, setDocumentTracker] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = {
        passport: { status: true, isRequired: false },
        visa: { status: true, isRequired: false },
        residenceAddress: { status: true, isRequired: false },
        destinationAddress: { status: true, isRequired: false },
        passengerWeightCategory: { status: true, isRequired: false },
        timaticVerification: { status: true, isRequired: false },
      };

      passenger.eligibilities.eligibility.map((element) => {
        if (element.notEligible) {
          if (
            element.reason[0].category === "MISSING_PASSENGER_WEIGHT_CATEGORY"
          ) {
            temp[passenger.id].passengerWeightCategory = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_REQUIRED_IDENTITY_DOCUMENTS"
          ) {
            temp[passenger.id].passport = { status: false, isRequired: true };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_DESTINATION_ADDRESS_DATA"
          ) {
            temp[passenger.id].destinationAddress = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_RESIDENCE_ADDRESS_DATA"
          ) {
            temp[passenger.id].residenceAddress = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category === "ADDITIONAL_VERIFICATION_REQUIRED"
          ) {
            temp[passenger.id].timaticVerification = {
              status: false,
              isRequired: true,
            };
          }
          if (element.reason[0].category === "PASSENGER_DOES_NOT_HAVE_VISA") {
            temp[passenger.id].visa = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category === "SELF_SERVICE_CHECK_IN_NOT_AVAILABLE"
          ) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            history.push("/check-in/error");
          }
        }
        return null;
      });

      return {};
    });
    const initialState = temp;
    return initialState;
  });

  const [passengerFrequentFlyerIndicator, setPassengerFrequentFlyerIndicator] =
    useState(() => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      const initialState = temp;
      return initialState;
    });
  const [passengerDocumentIndicator, setPassengerDocumentIndicator] = useState(
    () => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      const initialState = temp;
      return initialState;
    }
  );

  const handleDetaileClick = (passengerId) => {
    var temp = { ...passengerDetailIndicator };
    var temp1 = { ...passengerFrequentFlyerIndicator };
    var temp2 = { ...passengerDocumentIndicator };

    var tempVal = !temp[passengerId];

    Object.keys(passengerFrequentFlyerIndicator).map((key) => {
      temp[key] = false;
      temp1[key] = false;
      temp2[key] = false;
      return null;
    });

    temp[passengerId] = tempVal;

    setPassengerFrequentFlyerIndicator(temp1);
    setPassengerDocumentIndicator(temp2);
    setPassengerDetailIndicator(temp);
    setErrorMessage("");
    setFrequentFlyer({ memberAirline: "", memberId: "" });
  };

  const handleDocumenteClick = (passenger, passengerId) => {
    var temp = { ...passengerDocumentIndicator };
    var temp1 = { ...passengerFrequentFlyerIndicator };
    var temp2 = { ...passengerDetailIndicator };

    var tempVal = !temp[passengerId];

    Object.keys(passengerFrequentFlyerIndicator).map((key) => {
      temp[key] = false;
      temp1[key] = false;
      temp2[key] = false;
      return null;
    });

    temp[passengerId] = tempVal;

    setPassengerFrequentFlyerIndicator(temp1);
    setPassengerDocumentIndicator(temp);
    setPassengerDetailIndicator(temp2);

    //reset
    var temporary = { ...documentModel };
    var tempDocumentModel = new DocumentModel();
    tempDocumentModel.document.personName.first = passenger.personName.first;
    tempDocumentModel.document.personName.middle = passenger.personName.middle;
    tempDocumentModel.document.personName.last = passenger.personName.last;

    tempDocumentModel.document.issuingCountry =
      props.reservation.itinerary.itineraryPart[0].segment[
        props.reservation.itinerary.itineraryPart[0].segment.length - 1
      ].flightDetail[0].arrivalCountry;

    temporary[passengerId] = tempDocumentModel;
    resetDocumentModelValidation(passengerId);

    setDocumentModel(temporary);
    resetAddressModelPlusValidation();
    setErrorMessage("");
    setFrequentFlyer({ memberAirline: "", memberId: "" });
  };

  const handleFrequentFlyerClick = (passengerId) => {
    var temp = { ...passengerFrequentFlyerIndicator };
    var temp1 = { ...passengerDetailIndicator };
    var temp2 = { ...passengerDocumentIndicator };

    var tempVal = !temp[passengerId];

    Object.keys(passengerFrequentFlyerIndicator).map((key) => {
      temp[key] = false;
      temp1[key] = false;
      temp2[key] = false;
      return null;
    });

    temp[passengerId] = tempVal;

    setPassengerFrequentFlyerIndicator(temp);
    setPassengerDocumentIndicator(temp2);
    setPassengerDetailIndicator(temp1);
    setErrorMessage("");
    setFrequentFlyer({ memberAirline: "", memberId: "" });
  };

  const isExtraDocumentNeeded = (passenger) => {
    var tempResult = false;
    passenger.eligibilities.eligibility.map((element) => {
      tempResult = tempResult || element.notEligible;
      return null;
    });
    return tempResult;
  };

  const getDocumentAddForm = (passenger) => {
    return <>{getRequiredDocument(passenger)}</>;
  };

  const updateDocumentTracker = (passengerId, key, value) => {
    var temp = { ...documentTracker };

    temp[passengerId][key] = value;
    setDocumentTracker(temp);
  };

  // FORM related codes
  // const documentModel = new DocumentModel();
  // const addressModel = new AddressModel();
  // "SELF_SERVICE_CHECK_IN_NOT_AVAILABLE"
  const [addressModel, setAddressModel] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = new AddressModel();
      return {};
    });
    const initialState = temp;
    return initialState;
  });
  const [addressModelValidation, setAddressModelValidation] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = new AddressModel();
      temp[passenger.id].street1 = " ";
      temp[passenger.id].postalCode = " ";
      temp[passenger.id].country = "";
      temp[passenger.id].city = " ";
      temp[passenger.id].stateProvince = " ";
      return {};
    });
    const initialState = temp;
    return initialState;
  });

  const updateAddressModelValidation = (passengerId, key, value) => {
    var temp = { ...addressModelValidation };
    temp[passengerId][key] = value;
    setAddressModelValidation(temp);
  };

  const updateAddressModel = (passengerId, key, value) => {
    var temp = { ...addressModel };
    temp[passengerId][key] = value;
    setAddressModel(temp);
  };

  const resetAddressModelPlusValidation = () => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = new AddressModel();
      return {};
    });
    setAddressModel(temp);
    temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = new AddressModel();
      temp[passenger.id].street1 = " ";
      temp[passenger.id].postalCode = " ";
      temp[passenger.id].country = " ";
      temp[passenger.id].city = " ";
      temp[passenger.id].stateProvince = " ";
      return {};
    });
    setAddressModelValidation(temp);
  };

  const [documentModel, setDocumentModel] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = new DocumentModel();
      temp[passenger.id].document.issuingCountry =
        props.reservation.itinerary.itineraryPart[0].segment[
          props.reservation.itinerary.itineraryPart[0].segment.length - 1
        ].flightDetail[0].arrivalCountry;
      return {};
    });

    const initialState = temp;
    return initialState;
  });

  const [documentModelValidation, setDocumentModelValidation] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = {
        number: " ",
        nationality: " ",
        personName: {
          prefix: " ",
          first: " ",
          middle: " ",
          last: " ",
          suffix: " ",
          raw: " ",
        },
        countryOfBirth: " ",
        placeOfBirth: " ",
        dayOfBirth: " ",
        monthOfBirth: " ",
        yearOfBirth: " ",
        issueDate: " ",
        issueYear: " ",
        issueMonth: " ",
        issueDay: " ",
        issuingCountry: " ",
        expiryDay: " ",
        expiryMonth: " ",
        expiryYear: " ",
        gender: " ",
        issuingPlace: " ",
      };
      temp.issuingCountry =
        props.reservation.itinerary.itineraryPart[0].segment[
          props.reservation.itinerary.itineraryPart[0].segment.length - 1
        ].flightDetail[0].arrivalCountry;
      return {};
    });
    const initialState = temp;
    return initialState;
  });
  const submitDocument = (
    passengerId,
    documentType,
    weightCategory,
    passenger
  ) => {
    var PayloadDocumentModel = { passengerId: passengerId };
    var document = { document: {} };
    setUpdatingDoc(true);
    if ("weightCategory" === documentType) {
      PayloadDocumentModel.weightCategory = weightCategory;
    }
    if ("PASSPORT" === documentType) {
      document.document = {
        id: "p01.01.d01",
        type: "PASSPORT",
        number: "",
        nationality: "",
        dateOfBirth: "",
        issuingCountry: "",
        expiryDate: "",
        gender: "",
        personName: "",
      };
      document.document.id = passengerId + ".d01";
      document.document.personName = passenger.personName;
      document.document.personName.first = passenger.personName.first;
      document.document.personName.middle = passenger.personName.middle;
      document.document.personName.last = passenger.personName.last;
      document.document.number = documentModel[passengerId].document.number;
      document.document.nationality =
        documentModel[passengerId].document.nationality;
      // document.document.countryOfBirth = documentModel[passengerId].document.nationality;
      // document.document.placeOfBirth = documentModel[passengerId].document.placeOfBirth;

      document.document.issuingCountry =
        documentModel[passengerId].document.issuingCountry;

      document.document.gender = documentModel[passengerId].document.gender;

      var tempDateEXpiry =
        documentModel[passengerId].document.expiryYear +
        "-" +
        documentModel[passengerId].document.expiryMonth +
        "-" +
        documentModel[passengerId].document.expiryDay;
      var tempDateBirthDate =
        documentModel[passengerId].document.yearOfBirth +
        "-" +
        documentModel[passengerId].document.monthOfBirth +
        "-" +
        documentModel[passengerId].document.dayOfBirth;
      document.document.expiryDate = tempDateEXpiry;
      document.document.dateOfBirth = tempDateBirthDate;
      PayloadDocumentModel.documents = [document];
    }
    if ("VISA" === documentType) {
      document.document = {
        id: "p01.01.v01",
        type: "VISA",
        number: "",
        issueDate: "",
        issuingPlace: "",
        issuingCountry: "",
        applicableCountry: "",
      };

      document.document.id = passengerId + ".v01";
      document.document.number = documentModel[passengerId].document.number;

      var tempissueDate =
        documentModel[passengerId].document.issueYear +
        "-" +
        documentModel[passengerId].document.issueMonth +
        "-" +
        documentModel[passengerId].document.issueDay;
      document.document.issuingPlace =
        documentModel[passengerId].document.issuingPlace;
      document.document.issuingCountry =
        documentModel[passengerId].document.issuingCountry;
      document.document.applicableCountry =
        documentModel[passengerId].document.issuingCountry;
      document.document.issueDate = tempissueDate;
      PayloadDocumentModel.documents = [document];
    }
    checkInPostService
      .updateDocument(
        [PayloadDocumentModel],
        props.token,
        props.checkInSabreDataRequest
      )
      .then((response) => {
        if (response.data.statusAPI === "SUCCESS") {
          props.updateReservation(response.data.reservation);
          updateDocumentTrackerAfterSubmit(response.data.reservation);
          // updateActivePassengersDataAfterSubmit(response.data.reservation.passengers.passenger);
          // if ("weightCategory" === documentType) {
          //   updateDocumentTracker(passengerId, "passengerWeightCategory", {
          //     status: true,
          //     isRequired: false,
          //   });
          // }
          // if ("PASSPORT" === documentType) {
          //   updateDocumentTracker(passengerId, "passport", {
          //     status: true,
          //     isRequired: false,
          //   });
          //   handleDocumenteClick(passenger, passengerId);
          // }
          // if ("VISA" === documentType) {
          //   updateDocumentTracker(passengerId, "visa", {
          //     status: true,
          //     isRequired: false,
          //   });
          // }
        }
        setUpdatingDoc(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdatingDoc(false);
      });
  };
  const submitAddress = (passengerId, addressType, passenger) => {
    var PayloadAddress = { passengerId: passengerId };
    var passengerDetailsPayLoad = {};
    setUpdatingDoc(true);
    // var addressModel = {};

    if ("RESIDENCE" === addressType) {
      PayloadAddress = {
        type: "RESIDENCE",
        street1: "",
        postalCode: "",
        country: "",
        city: "",
        stateProvince: "",
      };
      PayloadAddress.id =
        passengerId +
        ".a0" +
        (passenger.contactDetails.address !== null
          ? passenger.contactDetails.address.length
          : 1);
      PayloadAddress.street1 = addressModel[passengerId].street1;
      PayloadAddress.postalCode = addressModel[passengerId].postalCode;
      PayloadAddress.country = addressModel[passengerId].country;
      PayloadAddress.city = addressModel[passengerId].city;
      PayloadAddress.stateProvince = addressModel[passengerId].stateProvince;
    }
    if ("DESTINATION" === addressType) {
      PayloadAddress = {
        type: "DESTINATION",
        street1: "",
        postalCode: "",
        country: destinationCountry,
        city: "",
        stateProvince: "",
      };

      PayloadAddress.id =
        passengerId +
        ".a0" +
        (passenger.contactDetails.address !== null
          ? passenger.contactDetails.address.length
          : 1);
      PayloadAddress.street1 = addressModel[passengerId].street1;
      PayloadAddress.postalCode = addressModel[passengerId].postalCode;
      PayloadAddress.city = addressModel[passengerId].city;
      PayloadAddress.stateProvince = addressModel[passengerId].stateProvince;
    }

    passengerDetailsPayLoad.addresses = [PayloadAddress];
    passengerDetailsPayLoad.passengerId = passengerId;

    checkInPostService
      .updateDocument(
        [passengerDetailsPayLoad],
        props.token,
        props.checkInSabreDataRequest
      )
      .then((response) => {
        if (response.data.statusAPI === "SUCCESS") {
          props.updateReservation(response.data.reservation);
          // updateActivePassengersDataAfterSubmit(response.data.reservation.passengers.passenger);
          updateDocumentTrackerAfterSubmit(response.data.reservation);
          if ("RESIDENCE" === addressType) {
            updateDocumentTracker(passengerId, "residenceAddress", {
              status: true,
              isRequired: false,
            });
            resetAddressModelPlusValidation();
          }
          if ("DESTINATION" === addressType) {
            updateDocumentTracker(passengerId, "destinationAddress", {
              status: true,
              isRequired: false,
            });
          }
        }
        setUpdatingDoc(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdatingDoc(false);
      });
  };

  const submitFrequentFlyer = (passengerId, memberAirline, memberId) => {
    setUpdatingDoc(true);

    var PayloadFrequentFlyer = {
      passengerId: "p01.01",
      loyaltyAccounts: "",
    };
    var loyaltyAccounts = {
      memberAirline: "",
      memberId: "",
    };

    loyaltyAccounts.memberAirline = memberAirline;
    loyaltyAccounts.memberId = memberId;
    PayloadFrequentFlyer.passengerId = passengerId;
    PayloadFrequentFlyer.loyaltyAccounts = [loyaltyAccounts];

    checkInPostService
      .updateDocument(
        [PayloadFrequentFlyer],
        props.token,
        props.checkInSabreDataRequest
      )
      .then((response) => {
        console.log(response);
        if (response.data.statusAPI === "SUCCESS") {
          // updateActivePassengersDataAfterSubmit(response.data.reservation.passengers.passenger);
          props.updateReservation(response.data.reservation);
          updateDocumentTrackerAfterSubmit(response.data.reservation);
        }
        if (response.data.statusAPI === "ERROR") {
          setErrorMessage(
            "Unable to add frequent flyer number at this time. Please try again"
          );
        }
        setUpdatingDoc(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdatingDoc(false);
      });
  };

  const updateDocumentModelValidation = (passengerId, key, value) => {
    var temp = { ...documentModelValidation };
    var keys = key.split(".");
    if (keys.length > 1) {
      temp[passengerId][keys[0]][keys[1]] = value;
    } else {
      temp[passengerId][key] = value;
    }
    setDocumentModelValidation(temp);
  };

  const updateDocumentModel = (passengerId, key, value) => {
    var temp = { ...documentModel };
    var keys = key.split(".");
    if (keys.length > 1) {
      temp[passengerId].document[keys[0]][keys[1]] = value;
    } else {
      temp[passengerId].document[key] = value;
    }
    setDocumentModel(temp);
  };

  const resetDocumentModelValidation = (passengerId) => {
    var temp = { ...documentModelValidation };
    temp[passengerId] = {
      number: " ",
      nationality: " ",
      personName: {
        prefix: " ",
        first: " ",
        middle: " ",
        last: " ",
        suffix: " ",
        raw: " ",
      },
      countryOfBirth: " ",
      placeOfBirth: " ",
      dayOfBirth: " ",
      monthOfBirth: " ",
      yearOfBirth: " ",
      issueDate: " ",
      issueYear: " ",
      issueMonth: " ",
      issueDay: " ",
      issuingCountry: " ",
      expiryDay: " ",
      expiryMonth: " ",
      expiryYear: " ",
      gender: " ",
      issuingPlace: " ",
    };
    temp[passengerId].issuingCountry =
      props.reservation.itinerary.itineraryPart[0].segment[
        props.reservation.itinerary.itineraryPart[0].segment.length - 1
      ].flightDetail[0].arrivalCountry;

    setDocumentModelValidation(temp);
  };
  const onKeyDownFunction = (e) => {
    var keynum;
    if (window.event) {
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    }
    let isValid =
      (keynum <= 90 && keynum >= 65) ||
      keynum === 8 ||
      keynum === 9 ||
      keynum === 13 ||
      keynum === 32 ||
      keynum === 36 ||
      keynum === 37 ||
      keynum === 38 ||
      keynum === 39 ||
      keynum === 40;

    if (!isValid) {
      e.preventDefault();
    }
  };

  const timaticVerification = (passengerId) => {
    var payload = [];
    payload.push(passengerId);
    if (!updatingDoc) {
      setUpdatingDoc(true);
      checkInPostService
        .verifyTimatic(payload, props.token, props.checkInSabreDataRequest)
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            props.updateReservation(response.data.reservation);
            updateDocumentTrackerAfterSubmit(response.data.reservation);
          }
          setUpdatingDoc(false);
        })
        .catch((error) => {
          setUpdatingDoc(false);
          console.log(error);
        });
    }
  };
  const setDestinationCountry = () => {
    destinationCountry =
      props.reservation.itinerary.itineraryPart[0].segment[
        props.reservation.itinerary.itineraryPart[0].segment.length - 1
      ].flightDetail[0].arrivalCountry;
  };

  const getRequiredDocument = (passenger) => {
    return (
      <>
        {documentTracker[passenger.id].passport.isRequired &&
        !documentTracker[passenger.id].passport.status ? (
          <Row className="mx-0">
            <div className="col-12 px-md-5">
              <h5>{Translation.t("travel_documents")}</h5>
              <p>
                {Translation.t(
                  "please_provide_the_travel_document_information"
                )}
              </p>
              <p className="mb-0">
                {Translation.t("name_as_it_appears_on_passport")}
              </p>
              <span>{Translation.t("fields_marked_with_are_required")}</span>
            </div>
            <div className="flex-column col-12 col-md-8 col-lg-6 px-md-5">
              <input
                value={documentModel[passenger.id].document.personName.first}
                onChange={(e) =>
                  updateDocumentModel(
                    passenger.id,
                    "personName.first",
                    e.target.value
                  )
                }
                className={
                  documentModelValidation[passenger.id].personName.first !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  documentModelValidation[passenger.id].personName.first !== ""
                    ? Translation.t("first_name")
                    : Translation.t("first_name_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "personName.first",
                    documentModel[passenger.id].document.personName.first
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "personName.first",
                    "e.target.value"
                  )
                }
              />
              <input
                className="form-control  widget-input my-2"
                placeholder="Middle Name"
                value={documentModel[passenger.id].document.personName.middle}
                onChange={(e) =>
                  updateDocumentModel(
                    passenger.id,
                    "personName.middle",
                    e.target.value
                  )
                }
                // value={passenger.personName.middle}
              />
              <input
                value={documentModel[passenger.id].document.personName.last}
                onChange={(e) =>
                  updateDocumentModel(
                    passenger.id,
                    "personName.last",
                    e.target.value
                  )
                }
                className={
                  documentModelValidation[passenger.id].personName.last !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  documentModelValidation[passenger.id].personName.last !== ""
                    ? Translation.t("last_name")
                    : Translation.t("last_name_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "personName.last",
                    documentModel[passenger.id].document.personName.last
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "personName.last",
                    "e.target.value"
                  )
                }
              />
              <Select
                styles={selectStyle}
                options={passengerService.getGenderList()}
                isSearchable={false}
                className={
                  documentModelValidation[passenger.id].gender !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "gender",
                    documentModel[passenger.id].document.gender
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(passenger.id, "gender", " ")
                }
                // value={documentModel[passenger.id].document.gender}
                onChange={(e) => {
                  updateDocumentModel(passenger.id, "gender", e.value);
                  updateDocumentModelValidation(
                    passenger.id,
                    "gender",
                    e.value
                  );
                }}
                placeholder={
                  documentModelValidation[passenger.id].gender !== "" ? (
                    Translation.t("gender")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("gender_required")}
                    </span>
                  )
                }
              ></Select>

              <Row className="mr-0 pt-3">
                <div className="col-4 pr-0">
                  <Select
                    styles={selectStyle}
                    options={
                      passenger.type.value === "ADULT"
                        ? AdultYear
                        : passenger.type.value === "CHILD"
                        ? ChildYear
                        : InfantYear
                    }
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].yearOfBirth !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "yearOfBirth",
                        documentModel[passenger.id].document.yearOfBirth
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "yearOfBirth",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "yearOfBirth", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "yearOfBirth",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].yearOfBirth !==
                      "" ? (
                        Translation.t("birth_year")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("birth_year_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("birth_year")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Month}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].monthOfBirth !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "monthOfBirth",
                        documentModel[passenger.id].document.monthOfBirth
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "monthOfBirth",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(
                        passenger.id,
                        "monthOfBirth",
                        e.value
                      );
                      updateDocumentModelValidation(
                        passenger.id,
                        "monthOfBirth",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].monthOfBirth !==
                      "" ? (
                        Translation.t("birth_month")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("birth_month_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("birth_month")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Day}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].dayOfBirth !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "dayOfBirth",
                        documentModel[passenger.id].document.dayOfBirth
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "dayOfBirth",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "dayOfBirth", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "dayOfBirth",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].dayOfBirth !==
                      "" ? (
                        Translation.t("birth_day")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("birth_day_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("birth_day")}
                  </label>
                </div>
              </Row>
              <Select
                styles={selectStyle}
                options={countryList}
                isSearchable={true}
                className={
                  documentModelValidation[passenger.id].nationality !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "nationality",
                    documentModelValidation[passenger.id].nationality === " "
                      ? ""
                      : documentModelValidation[passenger.id].nationality
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "nationality",
                    " "
                  )
                }
                onChange={(e) => {
                  updateDocumentModel(passenger.id, "nationality", e.value);
                  updateDocumentModelValidation(
                    passenger.id,
                    "nationality",
                    e.value
                  );
                }}
                placeholder={
                  documentModelValidation[passenger.id].nationality !== "" ? (
                    Translation.t("nationality")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("nationality_required")}
                    </span>
                  )
                }
              ></Select>

              <input
                onChange={(e) =>
                  updateDocumentModel(passenger.id, "number", e.target.value)
                }
                className={
                  documentModelValidation[passenger.id].number !== ""
                    ? "" + documentModel[passenger.id].document.number !== ""
                      ? "form-control widget-input my-2 text-uppercase"
                      : "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  documentModelValidation[passenger.id].number !== ""
                    ? Translation.t("document_number")
                    : Translation.t("document_number_required")
                }
                // onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "number",
                    documentModel[passenger.id].document.number
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "number",
                    "e.target.value"
                  )
                }
              />
              <Select
                styles={selectStyle}
                options={countryList}
                isSearchable={true}
                className={
                  documentModelValidation[passenger.id].issuingCountry !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    documentModelValidation[passenger.id].issuingCountry === " "
                      ? ""
                      : documentModelValidation[passenger.id].issuingCountry
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    " "
                  )
                }
                onChange={(e) => {
                  updateDocumentModel(passenger.id, "issuingCountry", e.value);
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    e.value
                  );
                }}
                placeholder={
                  documentModelValidation[passenger.id].issuingCountry !==
                  "" ? (
                    Translation.t("issuing_country")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("issuing_country_required")}
                    </span>
                  )
                }
              ></Select>
              <Row className="mr-0 pt-3">
                <p className="col-12 mb-0">
                  {Translation.t("expiration_date")}
                </p>
                <div className="col-4 pr-0">
                  <Select
                    styles={selectStyle}
                    options={ExpiaryYear}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].expiryYear !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryYear",
                        documentModel[passenger.id].document.expiryYear
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryYear",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "expiryYear", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryYear",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].expiryYear !==
                      "" ? (
                        Translation.t("expire_year")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("expire_year_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("exp_year")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Month}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].expiryMonth !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryMonth",
                        documentModel[passenger.id].document.expiryMonth
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryMonth",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "expiryMonth", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryMonth",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].expiryMonth !==
                      "" ? (
                        Translation.t("expire_month")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("expire_month_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("exp_month")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Day}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].expiryDay !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryDay",
                        documentModel[passenger.id].document.expiryDay
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryDay",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "expiryDay", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "expiryDay",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].expiryDay !== "" ? (
                        Translation.t("expire_day")
                      ) : (
                        <span className="invalid-select-placeholder invalid-select-customized-placeholder">
                          {Translation.t("expire_day_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("exp_day")}
                  </label>
                </div>
              </Row>
              <p>
                {Translation.t("please_ensure_your_information_is_complete")}
              </p>
            </div>
            <div className="col-12 justify-content-end d-flex px-md-5 py-3">
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() => handleDocumenteClick(passenger, passenger.id)}
              >
                {Translation.t("Cancel")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() =>
                  submitDocument(passenger.id, "PASSPORT", "", passenger)
                }
                disabled={!isPassportFormValid(passenger.id)}
              >
                {Translation.t("Save")}
              </button>
            </div>
          </Row>
        ) : documentTracker[passenger.id].visa.isRequired &&
          !documentTracker[passenger.id].visa.status ? (
          <Row className="mx-0">
            <div className="col-12 px-md-5">
              <h5 className="text-uppercase">
                {Translation.t("VISA_DOCUMENTS_FOR")}{" "}
                {AirportFullName.getCountryName(
                  props.reservation.itinerary.itineraryPart[0].segment[
                    props.reservation.itinerary.itineraryPart[0].segment
                      .length - 1
                  ].flightDetail[0].arrivalAirport
                )}
              </h5>
              <p>
                {Translation.t(
                  "please_provide_the_travel_document_information"
                )}
              </p>
              <p className="mb-0">
                {Translation.t("name_as_it_appears_on_passport")}
              </p>
              <span>{Translation.t("fields_marked_with_are_required")}</span>
            </div>
            <div className="flex-column col-12 col-lg-6 px-md-5">
              <input
                onChange={(e) =>
                  updateDocumentModel(passenger.id, "number", e.target.value)
                }
                className={
                  documentModelValidation[passenger.id].number !== ""
                    ? "" + documentModel[passenger.id].document.number !== ""
                      ? "form-control widget-input my-2 text-uppercase"
                      : "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  documentModelValidation[passenger.id].number !== ""
                    ? Translation.t("visa_number")
                    : Translation.t("visa_number_required")
                }
                // onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "number",
                    documentModel[passenger.id].document.number
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "number",
                    "e.target.value"
                  )
                }
              />
              <Select
                styles={selectStyle}
                options={countryList}
                isSearchable={true}
                defaultValue={
                  props.reservation.itinerary.itineraryPart[0].segment[
                    props.reservation.itinerary.itineraryPart[0].segment
                      .length - 1
                  ].flightDetail[0].arrivalAirport
                }
                defaultInputValue={AirportFullName.getCountryName(
                  props.reservation.itinerary.itineraryPart[0].segment[
                    props.reservation.itinerary.itineraryPart[0].segment
                      .length - 1
                  ].flightDetail[0].arrivalAirport
                )}
                value={
                  countryList.filter(
                    (country) =>
                      country.value ===
                      documentModelValidation[passenger.id].issuingCountry
                  )[0]
                }
                className={
                  documentModelValidation[passenger.id].issuingCountry !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() => {
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    documentModel[passenger.id].document.issuingCountry
                  );
                }}
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    " "
                  )
                }
                onChange={(e) => {
                  updateDocumentModel(passenger.id, "issuingCountry", e.value);
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingCountry",
                    e.value
                  );
                  console.log(e);
                }}
                placeholder={
                  documentModelValidation[passenger.id].issuingCountry !==
                  "" ? (
                    Translation.t("issuing_country")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("issuing_country_required")}
                    </span>
                  )
                }
              ></Select>
              <input
                onChange={(e) =>
                  updateDocumentModel(
                    passenger.id,
                    "issuingPlace",
                    e.target.value
                  )
                }
                className={
                  documentModelValidation[passenger.id].issuingPlace !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  documentModelValidation[passenger.id].issuingPlace !== ""
                    ? Translation.t("Issued_place")
                    : Translation.t("Issued_place_required")
                }
                // onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() => {
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingPlace",
                    documentModel[passenger.id].document.issuingPlace
                  );
                }}
                onFocus={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "issuingPlace",
                    "e.target.value"
                  )
                }
              />
              <Row className="mr-0 pt-3">
                <div className="col-4 pr-0">
                  <Select
                    styles={selectStyle}
                    options={IssueYear}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].issueYear !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueYear",
                        documentModel[passenger.id].document.issueYear
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueYear",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "issueYear", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueYear",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].issueYear !== "" ? (
                        Translation.t("issue_year")
                      ) : (
                        <span className="invalid-select-placeholder">
                          {Translation.t("issue_year_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("issue_year")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Month}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].issueMonth !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueMonth",
                        documentModel[passenger.id].document.issueMonth
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueMonth",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "issueMonth", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueMonth",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].issueMonth !==
                      "" ? (
                        Translation.t("issue_month")
                      ) : (
                        <span className="invalid-select-placeholder">
                          {Translation.t("issue_month_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("issue_month")}
                  </label>
                </div>
                <div className="col-4 px-0">
                  <Select
                    styles={selectStyle}
                    options={Day}
                    isSearchable={false}
                    className={
                      documentModelValidation[passenger.id].issueDay !== ""
                        ? "my-2"
                        : "my-2 is-invalid-select"
                    }
                    onBlur={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueDay",
                        documentModel[passenger.id].document.issueDay
                      )
                    }
                    onFocus={() =>
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueDay",
                        " "
                      )
                    }
                    onChange={(e) => {
                      updateDocumentModel(passenger.id, "issueDay", e.value);
                      updateDocumentModelValidation(
                        passenger.id,
                        "issueDay",
                        e.value
                      );
                    }}
                    placeholder={
                      documentModelValidation[passenger.id].issueDay !== "" ? (
                        Translation.t("issue_day")
                      ) : (
                        <span className="invalid-select-placeholder">
                          {Translation.t("issue_day_required")}
                        </span>
                      )
                    }
                  ></Select>
                  <label className="form-sub-label mx-2">
                    {Translation.t("issue_day")}
                  </label>
                </div>
              </Row>
              <p>
                {Translation.t("please_ensure_your_information_is_complete")}
              </p>
            </div>
            <div className="col-12 justify-content-end d-flex px-md-5 py-3">
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() => handleDocumenteClick(passenger, passenger.id)}
              >
                {Translation.t("cancel")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                disabled={!isVisaFormValid(passenger.id)}
                onClick={() => submitDocument(passenger.id, "VISA", "")}
              >
                {Translation.t("save")}
              </button>
            </div>
          </Row>
        ) : documentTracker[passenger.id].residenceAddress.isRequired &&
          !documentTracker[passenger.id].residenceAddress.status ? (
          <Row className="mx-0">
            <div className="col-12 px-md-5">
              <h5>{Translation.t("RESIDENCE_ADDRESS")}</h5>
              <span>{Translation.t("fields_marked_with_are_required")}</span>
            </div>
            <div className="flex-column col-12 col-lg-6 px-md-5">
              <Select
                styles={selectStyle}
                options={countryList}
                isSearchable={true}
                className={
                  addressModelValidation[passenger.id].country !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "country",
                    addressModel[passenger.id].country
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(passenger.id, "country", " ")
                }
                onChange={(e) => {
                  updateAddressModel(passenger.id, "country", e.value);
                  updateAddressModelValidation(
                    passenger.id,
                    "country",
                    e.value
                  );
                }}
                placeholder={
                  addressModelValidation[passenger.id].country !== "" ? (
                    Translation.t("Country")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("Country_required")}
                    </span>
                  )
                }
              ></Select>
              <input
                value={addressModel[passenger.id].street1}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "street1", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].street1 !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].street1 !== ""
                    ? Translation.t("street_address")
                    : Translation.t("street_address_required")
                }
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "street1",
                    addressModel[passenger.id].street1
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "street1",
                    "e.target.value"
                  )
                }
              />
              <input
                value={addressModel[passenger.id].city}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "city", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].city !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].city !== ""
                    ? Translation.t("city")
                    : Translation.t("city_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "city",
                    addressModel[passenger.id].city
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "city",
                    "e.target.value"
                  )
                }
              />
              <input
                value={addressModel[passenger.id].stateProvince}
                onChange={(e) =>
                  updateAddressModel(
                    passenger.id,
                    "stateProvince",
                    e.target.value
                  )
                }
                className={
                  addressModelValidation[passenger.id].stateProvince !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].stateProvince !== ""
                    ? Translation.t("state")
                    : Translation.t("state_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "stateProvince",
                    addressModel[passenger.id].stateProvince
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "stateProvince",
                    "e.target.value"
                  )
                }
              />

              <input
                value={addressModel[passenger.id].postalCode}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "postalCode", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].postalCode !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].postalCode !== ""
                    ? Translation.t("zip_code")
                    : Translation.t("zip_code_required")
                }
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "postalCode",
                    addressModel[passenger.id].postalCode
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "postalCode",
                    "e.target.value"
                  )
                }
              />
              <p>
                {Translation.t("please_ensure_your_information_is_complete")}
              </p>
            </div>
            <div className="col-12 justify-content-end d-flex px-md-5 py-3">
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() => handleDocumenteClick(passenger, passenger.id)}
              >
                {Translation.t("cancel")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() =>
                  submitAddress(passenger.id, "RESIDENCE", passenger)
                }
                disabled={
                  isResidenceAddresValid(passenger.id) ? "" : "disabled"
                }
              >
                {Translation.t("save")}
              </button>
            </div>
          </Row>
        ) : documentTracker[passenger.id].destinationAddress.isRequired &&
          !documentTracker[passenger.id].destinationAddress.status ? (
          <Row className="mx-0">
            <div className="col-12 px-md-5">
              <h5>{Translation.t("DESTINATION_ADDRESS")}</h5>
              <span>{Translation.t("fields_marked_with_are_required")}</span>
            </div>
            {setDestinationCountry()}
            <div className="flex-column col-12 col-lg-6 px-md-5">
              <input
                value={addressModel[passenger.id].street1}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "street1", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].street1 !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].street1 !== ""
                    ? Translation.t("street_address")
                    : Translation.t("street_address_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "street1",
                    addressModel[passenger.id].street1
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "street1",
                    "e.target.value"
                  )
                }
              />
              <input
                value={addressModel[passenger.id].city}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "city", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].city !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].city !== ""
                    ? Translation.t("city")
                    : Translation.t("city_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "city",
                    addressModel[passenger.id].city
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "city",
                    "e.target.value"
                  )
                }
              />
              <input
                value={addressModel[passenger.id].stateProvince}
                onChange={(e) =>
                  updateAddressModel(
                    passenger.id,
                    "stateProvince",
                    e.target.value
                  )
                }
                className={
                  addressModelValidation[passenger.id].stateProvince !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].stateProvince !== ""
                    ? Translation.t("state")
                    : Translation.t("state_required")
                }
                onKeyDown={(e) => onKeyDownFunction(e)}
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "stateProvince",
                    addressModel[passenger.id].stateProvince
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "stateProvince",
                    "e.target.value"
                  )
                }
              />

              <input
                value={addressModel[passenger.id].postalCode}
                onChange={(e) =>
                  updateAddressModel(passenger.id, "postalCode", e.target.value)
                }
                className={
                  addressModelValidation[passenger.id].postalCode !== ""
                    ? "form-control widget-input my-2"
                    : "form-control widget-input my-2 invalid_input"
                }
                placeholder={
                  addressModelValidation[passenger.id].postalCode !== ""
                    ? Translation.t("zip_code")
                    : Translation.t("zip_code_required")
                }
                onBlur={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "postalCode",
                    addressModel[passenger.id].postalCode
                  )
                }
                onFocus={() =>
                  updateAddressModelValidation(
                    passenger.id,
                    "postalCode",
                    "e.target.value"
                  )
                }
              />
              <p>
                {Translation.t("please_ensure_your_information_is_complete")}
              </p>
            </div>
            <div className="col-12 justify-content-end d-flex px-md-5 py-3">
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() => handleDocumenteClick(passenger, passenger.id)}
              >
                {Translation.t("cancel")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() =>
                  submitAddress(passenger.id, "DESTINATION", passenger)
                }
                disabled={
                  isDestinationAddresValid(passenger.id) ? "" : "disabled"
                }
              >
                {Translation.t("save")}
              </button>
            </div>
          </Row>
        ) : documentTracker[passenger.id].passengerWeightCategory.isRequired &&
          !documentTracker[passenger.id].passengerWeightCategory.status ? (
          <Row className="mx-0">
            <div className="col-12 px-md-5">
              <span>{Translation.t("fields_marked_with_are_required")}</span>
            </div>
            <div className="flex-column col-12 col-md-8 col-lg-6 px-md-5">
              <Select
                styles={selectStyle}
                options={passengerService.getWeightCategoryForChildList()}
                isSearchable={false}
                className={
                  documentModelValidation[passenger.id].gender !== ""
                    ? "my-2"
                    : "my-2 is-invalid-select"
                }
                onBlur={() =>
                  updateDocumentModelValidation(
                    passenger.id,
                    "gender",
                    documentModel[passenger.id].document.gender
                  )
                }
                onFocus={() =>
                  updateDocumentModelValidation(passenger.id, "gender", " ")
                }
                // value={documentModel[passenger.id].document.gender}
                onChange={(e) => {
                  updateDocumentModel(passenger.id, "gender", e.value);
                  updateDocumentModelValidation(
                    passenger.id,
                    "gender",
                    e.value
                  );
                }}
                placeholder={
                  documentModelValidation[passenger.id].gender !== "" ? (
                    Translation.t("gender")
                  ) : (
                    <span className="invalid-select-placeholder">
                      {Translation.t("gender_required")}
                    </span>
                  )
                }
              ></Select>
              <p>{Translation.t("a_child_is_any_passenger_ages_2_to_12")}</p>
              <p>
                {Translation.t("please_ensure_your_information_is_complete")}
              </p>
            </div>
            <div className="col-12 justify-content-end d-flex px-md-5 py-3">
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                onClick={() => handleDocumenteClick(passenger, passenger.id)}
              >
                {Translation.t("cancel")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn m-2"
                disabled={
                  documentModelValidation[passenger.id].gender === "" ||
                  documentModelValidation[passenger.id].gender === " "
                }
                onClick={() =>
                  submitDocument(
                    passenger.id,
                    "weightCategory",
                    documentModel[passenger.id].document.gender
                  )
                }
              >
                {Translation.t("save")}
              </button>
            </div>
          </Row>
        ) : documentTracker[passenger.id].timaticVerification.isRequired &&
          !documentTracker[passenger.id].timaticVerification.status ? (
          <>
            {/* hit timatic verfication api */}
            {timaticVerification(passenger.id)}
          </>
        ) : null}
      </>
    );
  };

  const updateDocumentTrackerAfterSubmit = (reservation) => {
    var temp = {};
    reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = {
        passport: { status: true, isRequired: false },
        visa: { status: true, isRequired: false },
        residenceAddress: { status: true, isRequired: false },
        destinationAddress: { status: true, isRequired: false },
        passengerWeightCategory: { status: true, isRequired: false },
        timaticVerification: { status: true, isRequired: false },
      };

      passenger.eligibilities.eligibility.map((element) => {
        if (element.notEligible) {
          if (
            element.reason[0].category === "MISSING_PASSENGER_WEIGHT_CATEGORY"
          ) {
            temp[passenger.id].passengerWeightCategory = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_REQUIRED_IDENTITY_DOCUMENTS"
          ) {
            temp[passenger.id].passport = { status: false, isRequired: true };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_DESTINATION_ADDRESS_DATA"
          ) {
            temp[passenger.id].destinationAddress = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category ===
            "PASSENGER_DOES_NOT_HAVE_RESIDENCE_ADDRESS_DATA"
          ) {
            temp[passenger.id].residenceAddress = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category === "ADDITIONAL_VERIFICATION_REQUIRED"
          ) {
            temp[passenger.id].timaticVerification = {
              status: false,
              isRequired: true,
            };
          }
          if (element.reason[0].category === "PASSENGER_DOES_NOT_HAVE_VISA") {
            temp[passenger.id].visa = {
              status: false,
              isRequired: true,
            };
          }
          if (
            element.reason[0].category === "SELF_SERVICE_CHECK_IN_NOT_AVAILABLE"
          ) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            history.push("/check-in/error");
          }
        }
        return null;
      });

      return {};
    });
    setDocumentTracker(temp);
  };

  const isContinueImpossible = () => {
    var contImp = false;
    try {
      props.passengers
        .filter((passenger) => passenger.id in props.activePassengers)
        .map((passenger) => {
          contImp = contImp || isExtraDocumentNeeded(passenger);
       return null;
    });
      return contImp;
    } catch {
      return true;
    }
  };

  const isPassportFormValid = (passengerId) => {
    var temp = true;
    temp =
      documentModel[passengerId].document.number !== "" &&
      documentModel[passengerId].document.nationality !== "" && //&& documentModel[passengerId].document.placeOfBirth
      documentModel[passengerId].document.issuingCountry !== "" &&
      documentModel[passengerId].document.gender !== "" &&
      documentModel[passengerId].document.expiryDay !== "" &&
      documentModel[passengerId].document.expiryMonth !== "" &&
      documentModel[passengerId].document.expiryYear !== "" &&
      documentModel[passengerId].document.dayOfBirth !== "" &&
      documentModel[passengerId].document.monthOfBirth !== "" &&
      documentModel[passengerId].document.yearOfBirth !== "" &&
      documentModel[passengerId].document.personName.first !== "" &&
      documentModel[passengerId].document.personName.last !== "";

    return temp;
  };

  const isVisaFormValid = (passengerId) => {
    var temp = true;

    temp =
      documentModel[passengerId].document.number !== "" &&
      documentModel[passengerId].document.issuingCountry !== "" &&
      documentModel[passengerId].document.issuingPlace !== "" &&
      documentModel[passengerId].document.issueDay !== "" &&
      documentModel[passengerId].document.issueMonth !== "" &&
      documentModel[passengerId].document.issueYear !== "";

    return temp;
  };

  const isDestinationAddresValid = (passengerId) => {
    var temp = true;
    temp =
      addressModel[passengerId].street1 !== "" &&
      addressModel[passengerId].city !== "" &&
      addressModel[passengerId].postalCode !== "" &&
      addressModel[passengerId].stateProvince !== "";

    return temp;
  };
  const isResidenceAddresValid = (passengerId) => {
    var temp = true;
    temp =
      addressModel[passengerId].street1 !== "" &&
      addressModel[passengerId].city !== "" &&
      addressModel[passengerId].postalCode !== "" &&
      addressModel[passengerId].country !== "" &&
      addressModel[passengerId].stateProvince !== "";

    return temp;
  };

  // const Year = AdultYear;
  document.title = "Passengers | Check-in";
  return (
    <>
      <CheckInBreadCrumb />
      {updatingDoc ? <Loading /> : null}
      <div className="row mx-0 justify-content-center all-passangers-container w-100 wrapper-with-shopping-cart">
        <div className="left-content-container d-flex justify-content-cente row mx-auto mx-xl-0">
          <h2 className="my-3">{Translation.t("passengers_list")}</h2>
          <div className="all-passengers-container user-select-none w-100">
            {props.reservation.passengers.passenger.map((passenger, index) => {
              return (
                <>
                  {index === 0 ? null : (
                    <hr className="checkin-passenger-separator" />
                  )}
                  <div className="row check-in-passenger-container pt-3">
                    <div className="col-12 col-md-8">
                      <div className="row mx-0">
                        <div className="col-12 py-2">
                          {isExtraDocumentNeeded(passenger) ? (
                            <span className="document-needed-status">
                              {Translation.t("document_needed")}
                            </span>
                          ) : (
                            <span className="document-ready-status">
                              {Translation.t("ready")}
                            </span>
                          )}
                        </div>
                        <div className="col-12 row mx-0 d-flex align-self-center py-2">
                          <span className="d-flex px-2">
                            <input
                              type="checkbox"
                              id={"check-in-passenger-name-identifier-" + index}
                              className="et-ibe-checkbox align-self-center"
                              checked={passenger.id in props.activePassengers}
                              disabled={
                                passenger.type.value === "INFANT" ? true : false
                              }
                              onChange={(event) =>
                                passenger.type.value === "INFANT"
                                  ? null
                                  : handleChange(event, passenger.id)
                              }
                            />
                          </span>
                          <span
                            onClick={() =>
                              document
                                .getElementById(
                                  "check-in-passenger-name-identifier-" + index
                                )
                                .click()
                            }
                            className="check-in-passenger-name-identifier d-flex justify-content-center align-items-center"
                          >
                            {passenger.personName.first[0] +
                              passenger.personName.last[0]}
                          </span>
                          <span
                            onClick={() =>
                              document
                                .getElementById(
                                  "check-in-passenger-name-identifier-" + index
                                )
                                .click()
                            }
                            className="d-flex"
                          >
                            <span className="px-2 check-in-passenger-fullname">
                              {(passenger.personName.prefix !== null
                                ? passenger.personName.prefix
                                : "") +
                                " " +
                                passenger.personName.first +
                                " " +
                                passenger.personName.last}
                              <span className="d-flex check-in-passenger-type">
                                {passenger.type.value}
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="row mx-0">
                        {isExtraDocumentNeeded(passenger) ? (
                          <div className="col-12 justify-content-end d-flex">
                            <button
                              className="bk-submit-btn btn-ethiopian w-100 btn m-2"
                              onClick={() =>
                                handleDocumenteClick(passenger, passenger.id)
                              }
                            >
                              <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                              {Translation.t("ADD_DOCUMENTS")}
                            </button>
                          </div>
                        ) : null}
                        {passenger.type.value === "INFANT" ? null : (
                          <div className="col-12 justify-content-end d-flex">
                            <button
                              className="bk-submit-btn btn-ethiopian w-100 btn m-2"
                              onClick={() =>
                                handleFrequentFlyerClick(passenger.id)
                              }
                            >
                              {Translation.t("add_frequent_flyer")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      passengerDetailIndicator[passenger.id] ||
                      passengerFrequentFlyerIndicator[passenger.id] ||
                      passengerDocumentIndicator[passenger.id]
                        ? "row mx-0 checkin-passenger-detail-container checkin-passenger-detail-container-active"
                        : "row mx-0 checkin-passenger-detail-container"
                    }
                  >
                    <div className="col pb-4">
                      <span
                        onClick={() => handleDetaileClick(passenger.id)}
                        className="check-in-passenger-detail-buton"
                      >
                        {Translation.t("details_check_in")}{" "}
                        <FontAwesomeIcon
                          icon={
                            passengerDetailIndicator[passenger.id]
                              ? faAngleUp
                              : faAngleDown
                          }
                        />{" "}
                      </span>
                    </div>
                  </div>
                  {passengerDetailIndicator[passenger.id] ? (
                    <div className="row mx-0 pb-5">
                      <div className="col-12 px-4 px-lg-5">
                        <h5>
                          <FontAwesomeIcon icon={faChair} />{" "}
                          {Translation.t("seats")} &amp;{" "}
                          {Translation.t("extras")}
                        </h5>
                        <div className="row mx-0 px-3 py-2">
                          <div className="d-flex px-2 check-in-passenger-departure-name">
                            {AirportFullName.getCityName(
                              props.reservation.itinerary.itineraryPart[0]
                                .segment[0].flightDetail[0].departureAirport
                            )}
                          </div>
                          <div className="d-flex px-2 align-self-center">
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="check-in-right-arrow"
                            />
                          </div>
                          <div className="d-flex px-2 check-in-passenger-arrival-name">
                            {AirportFullName.getCityName(
                              props.reservation.itinerary.itineraryPart[0]
                                .segment[0].flightDetail[0].arrivalAirport
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 px-4 px-lg-5">
                        {props.reservation.itinerary.itineraryPart[0].segment.map(
                          (segment, segmentIndex) => {
                            return (
                              <>
                                <div className="row mx-0">
                                  <div className="col row mx-0 airport-code-container px-4">
                                    {segment.flightDetail[0].departureAirport}
                                    <span className="px-2">-</span>
                                    {segment.flightDetail[0].arrivalAirport}
                                  </div>
                                </div>
                                <div
                                  className={
                                    props.reservation.itinerary.itineraryPart[0]
                                      .segment.length ===
                                    segmentIndex + 1
                                      ? "row mx-0"
                                      : "row mx-0 pb-3"
                                  }
                                >
                                  <div className="d-flex pl-4 pr-3 col-3">
                                    {Translation.t("seat")}:
                                    {passenger.passengerSegments
                                      .passengerSegment[segmentIndex]
                                      .passengerFlight[0].seat === null
                                      ? "None"
                                      : passenger.passengerSegments
                                          .passengerSegment[segmentIndex]
                                          .passengerFlight[0].seat.value}
                                  </div>
                                  <div className="d-flex pr-l col-3">
                                    {Translation.t("extras")}:
                                  </div>
                                  {passenger.passengerSegments.passengerSegment[
                                    segmentIndex
                                  ].airExtra !== null ? (
                                    <div className="d-flex col row mx-0">
                                      <h5 className="col-12 px-0">
                                        {Translation.t("additional_extras")}
                                      </h5>
                                      <ul className="d-flex col-12 px-4">
                                        {passenger.passengerSegments.passengerSegment[
                                          segmentIndex
                                        ].airExtra.map((airExtraElement) => {
                                          return (
                                            <li>
                                              {airExtraElement.quantity +
                                                " " +
                                                airExtraElement.ancillary
                                                  .subCode}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                      <div className="col-12 px-4 px-lg-5">
                        <hr />
                      </div>
                      <div className="col-12 col-lg-6 px-4 px-lg-5">
                        <h5>
                          <FontAwesomeIcon icon={faUser} />{" "}
                          {Translation.t("passenger_details")}
                        </h5>
                        <h6 className="pl-4 passenger-detail-sub-title">
                          {Translation.t("basic_info")}
                        </h6>
                        <span className="d-flex pl-4">
                          {Translation.t("date_of_birth")}:{" "}
                          {passenger.dateOfBirth}
                        </span>
                        <span className="d-flex pl-4">
                          {Translation.t("gender_check_in")}:{passenger.gender}
                        </span>
                      </div>
                      {passenger.passengerDocument !== null &&
                      passenger.passengerDocument.length !== 0 ? (
                        <div className="col-12 col-lg-6 px-4 px-lg-5 mt-3 mt-lg-0">
                          <h5>
                            <FontAwesomeIcon icon={faFileAlt} />{" "}
                            {Translation.t("additional_information")}
                          </h5>
                          <h6 className="pl-4 passenger-detail-sub-title">
                            {Translation.t(
                              "additional_information_travel_documents"
                            )}
                          </h6>
                          {passenger.passengerDocument.map(
                            (passengerDocument) => {
                              return (
                                <span className="d-flex pl-4">
                                  <span className="travel-document-type-container">
                                    {passengerDocument.document.type + ": "}
                                  </span>
                                  <span className="travel-document-detail-container pl-2">
                                    {"#" + passengerDocument.document.number} |{" "}
                                    {passengerDocument.document.type === "VISA"
                                      ? "Issued: " +
                                        passengerDocument.document.issueDate
                                      : "Exp.: " +
                                        passengerDocument.document.expiryDate}
                                  </span>
                                </span>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {passengerFrequentFlyerIndicator[passenger.id] ? (
                    <>
                      <Row className="mx-0 mb-3">
                        <div className="col-12 px-4">
                          <h5>
                            {Translation.t("add_frequent_flyer_information")}
                          </h5>
                          {ErrorMessage !== "" ? (
                            <h6 className="text-danger">{ErrorMessage}</h6>
                          ) : null}
                        </div>
                        <div className="flex-column col-12 col-lg-6">
                          <div className="col-12 my-2">
                            <Select
                              styles={selectStyle}
                              isSearchable={false}
                              options={passengerService.getFrequentFlyer()}
                              placeholder={Translation.t(
                                "frequent_flyer_programme"
                              )}
                              onChange={(event) =>
                                setFrequentFlyer({
                                  memberAirline: event.value,
                                  memberId: frequentFlyer.memberId,
                                })
                              }
                            ></Select>
                          </div>
                          <div className="col-12 my-2">
                            <input
                              className="form-control  widget-input"
                              placeholder={Translation.t(
                                "frequent_flyer_numbers"
                              )}
                              onChange={(event) =>
                                setFrequentFlyer({
                                  memberId: event.target.value,
                                  memberAirline: frequentFlyer.memberAirline,
                                })
                              }
                            />
                          </div>
                        </div>
                      </Row>
                      <div className="row mx-0 justify-content-end px-4 pb-5">
                        <button
                          className="d-flex bk-submit-btn btn-ethiopian btn mr-2 align-items-center"
                          onClick={() => handleFrequentFlyerClick(passenger.id)}
                        >
                          {Translation.t("cancel")}
                        </button>
                        <button
                          disabled={
                            frequentFlyer.memberId === "" ||
                            frequentFlyer.memberAirline === ""
                              ? "disabled"
                              : ""
                          }
                          className="d-flex bk-submit-btn btn-ethiopian btn ml-2 align-items-center"
                          onClick={() =>
                            submitFrequentFlyer(
                              passenger.id,
                              frequentFlyer.memberAirline,
                              frequentFlyer.memberId
                            )
                          }
                        >
                          {Translation.t("save")}
                        </button>
                      </div>
                    </>
                  ) : null}

                  {passengerDocumentIndicator[passenger.id]
                    ? getDocumentAddForm(passenger)
                    : null}
                </>
              );
            })}
          </div>
          <div className="col-12 justify-content-end d-flex pr-0 px-0 py-3">
            <button
              className="bk-submit-btn btn-ethiopian mx-2 btn"
              onClick={() => redirectToIternary()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button
              className="bk-submit-btn btn-ethiopian ml-2 btn"
              onClick={() => handleContinueClick()}
              disabled={
                Object.keys(props.activePassengers).length === 0 ||
                isContinueImpossible()
                  ? "disabled"
                  : ""
              }
            >
              {Translation.t("continue")}
            </button>
          </div>
        </div>
        <div className="right-shopping-cart-container">
          {!props.isMedium && <ShoppingCart />}
        </div>
      </div>
    </>
  );
};

const mapStateTopProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    reservation: state.checkIn.reservation,
    passengers: state.checkIn.reservation.passengers.passenger,
    activePassengers: state.checkIn.activePassengers,
    passengerIndex: state.seatSelection.passengerIndex,
    isMedium: state.global.isMedium,
    checkInSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    token: state.searchFlight.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActivePassengers: (value) =>
      dispatch({
        type: checkInAction.UPDATEACTIVEPASSENGER,
        payload: value,
      }),
    updateReservation: (value) =>
      dispatch({
        type: checkInAction.UPDATERESERVATION,
        reservation: value,
      }),
    initActivePassengers: (value) =>
      dispatch({
        type: checkInAction.INITACTIVEPASSENGER,
        activePassengers: value,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(AllPassangers);
