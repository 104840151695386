import React, { Component } from "react";
import {
  faAddressCard,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import ExchangePaymentSummaryPassengerSeat from "./ExchangePaymentSummaryPassengerSeat";
class ExchangePaymentSummaryPassengerDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassengerDetail: false,
    };
  }

  render() {
    return (
      <section className="payment-summary-passenger-detail">
        <Row>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className="pym-passenger-detail-collapser"
            onClick={() =>
              this.setState({
                showPassengerDetail: !this.state.showPassengerDetail,
              })
            }
          >
            <h5 className="payment-summary-passenger-detail-title">
              <span>Details</span>
              <span>
                {" "}
                <FontAwesomeIcon
                  icon={
                    this.state.showPassengerDetail ? faAngleUp : faAngleDown
                  }
                />
              </span>
            </h5>
          </Col>
        </Row>
        {this.state.showPassengerDetail && (
          <div>
            <ExchangePaymentSummaryPassengerSeat
              tripDetail={this.props.tripDetail}
            />
            <div className="pym-passenger-list-detail">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h4 className="pym-passenger-detail-head">
                    <span className="pr-2">
                      <FontAwesomeIcon icon={faAddressCard} />
                    </span>
                    <span>Passenger Details</span>
                  </h4>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h6>
                    <b>Basic Info</b>
                  </h6>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h6>
                    Date of birth:{" "}
                    <span>{this.props.passengerInfo.dateOfBirth}</span>
                  </h6>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <h6>
                    Gender: <span>{this.props.passengerInfo.gender}</span>
                  </h6>
                </Col>
              </Row>
              {this.props.index === 0 && (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <h6 className="mt-3">
                      <b>Contact Info</b>
                    </h6>
                  </Col>
                  {this.props.contactInfo.phones.length > 0 && (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h6>
                        Home:{" "}
                        <span>
                          {this.props.contactInfo.phones[0].countryCode +
                            "" +
                            this.props.contactInfo.phones[0].number}
                        </span>
                      </h6>
                    </Col>
                  )}
                  {this.props.contactInfo.emails.length > 0 && (
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <h6>
                        Email: <span>{this.props.contactInfo.emails[0]}</span>
                      </h6>
                    </Col>
                  )}
                </Row>
              )}
            </div>
          </div>
        )}
      </section>
    );
  }
}
export default ExchangePaymentSummaryPassengerDetail;
