import { React, Component } from "react";
import "./ProgressSimpler.css"

class ProgressSimpler extends Component{

    render(){
        return <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    }
}

export default ProgressSimpler;
