import { Component } from "react";
import moment from "moment";
import "./FareCalendar.css";
import { FareCalendarService } from "../../../../service/booking/fare-calendar/FareCalendarService";
// import { FareCalendarService } from "../../../../service/fare-calendar/FareCalendarService";
import { connect } from "react-redux";
import HttpService from "../../../../service/shared/HttpService";
import { searchFlightAction } from "../../../../redux/actions/booking/searchFlightAction";
import Loading from "../../seat-selection/Loading";
import { Redirect } from "react-router";
import { globalActionType } from "../../../../redux/actions/shared/globalAction";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import Translation from "i18next";

class FareCalendar extends Component {
  fromAPIResponse = {
    unbundledAlternateDateOffers: [],
  };
  dateHeaders = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  state = {
    isLoading: true,
    isSeatPopUpModalOpen: false,
    selectedDate: "",
    redirect: false,
    isFirstLoading: true,
    isSecondLoading: true,
  };
  monthCounter = 1;
  currentDate = "";

  flightCalanderLookUP = {};
  soldOutDatesOutbound = {};
  noneScheduledDatesOutbound = {};
  temporaryHolder = "";

  componentDidMount() {
    this.currentDate = moment().locale("en").format("YYYY-MM-DD");
    this.init();
    this.init("second");
    window.addEventListener("scroll", this.scrollEventHandler);
    // document.getElementById('fare-calendar-month-inicator').addEventListener("change", this.handleMonthChange);
  }

  init = (month = "first") => {
    var currentDate = this.currentDate;
    if (month !== "first") {
      this.setState({ isSecondLoading: true });
      this.monthCounter += 2;
      var tempAddedDays =
        moment(this.currentDate).startOf("month").add(1, "M").daysInMonth() ===
        31
          ? 15
          : 14;
      currentDate = moment(this.currentDate)
        .startOf("month")
        .add(1, "M")
        .add(tempAddedDays, "days")
        .format("YYYY-MM-DD");
      this.currentDate = currentDate;
    } else {
      this.setState({ isFirstLoading: true });
    }
    var fareCalendarService = new FareCalendarService();

    fareCalendarService
      .getFareCalender(
        currentDate,
        this.props.searchFlight.searchRequest,
        this.props.token
      )
      .then((response) => {
        this.fromAPIResponse.unbundledAlternateDateOffers =
          response.data.unbundledAlternateDateOffers;
        this.soldOutDatesOutbound = response.data.soldOutDatesOutbound;
        this.noneScheduledDatesOutbound =
          response.data.noneScheduledDatesOutbound;
        if (this.initilizeFareCalender()) {
          if (month !== "first") {
            this.setState({ isSecondLoading: false });
          } else {
            this.setState({ isFirstLoading: false });
          }
        }
      })
      .catch((e) => {
        HttpService.getTokenServive()
          .then((response) => {
            this.props.saveToken(response.data.tokenDetail.access_token);
            fareCalendarService
              .getFareCalender(
                currentDate,
                this.props.searchFlight.searchRequest,
                response.data.tokenDetail.access_token
              )
              .then((response) => {
                this.fromAPIResponse.unbundledAlternateDateOffers =
                  response.data.unbundledAlternateDateOffers;
                this.soldOutDatesOutbound = response.data.soldOutDatesOutbound;
                this.noneScheduledDatesOutbound =
                  response.data.noneScheduledDatesOutbound;
                if (this.initilizeFareCalender()) {
                  if (month !== "first")
                    this.setState({ isSecondLoading: false });
                  else this.setState({ isFirstLoading: false });
                }
              })
              .catch((e) => {
                console.log(e);
                this.setState({
                  isSecondLoading: false,
                  isFirstLoading: false,
                });
              });
          })
          .catch((e) => {
            this.setState({ isSecondLoading: false, isFirstLoading: false });
            console.log(e);
          });
      });
  };

  initilizeFareCalender = () => {
    // var flightCalanderLookUP = {};
    var tempMonthHolder = {};
    var tempMonthHolderForSoldOutDates = {};
    if (this.fromAPIResponse?.unbundledAlternateDateOffers !== null) {
      this.fromAPIResponse?.unbundledAlternateDateOffers[0]?.map(
        (element, index) => {
          var tempHolder = moment(
            element.itineraryPart[0].segments[0].departure
          )
            .locale("en")
            .format("MMMM");
          if (tempHolder in this.flightCalanderLookUP) {
            this.flightCalanderLookUP[tempHolder][
              [
                moment(element.itineraryPart[0].segments[0].departure)
                  .locale("en")
                  .format("YYYY-MM-DD"),
              ]
            ] = element;
          } else {
            tempMonthHolder[
              moment(element.itineraryPart[0].segments[0].departure)
                .locale("en")
                .format("YYYY-MM-DD")
            ] = element;
            this.flightCalanderLookUP[tempHolder] = tempMonthHolder;
            tempMonthHolder = {};
          }
          return null;
        }
      );
    }
    this.soldOutDatesOutbound?.map((element) => {
      var tempHolder = moment(element).locale("en").format("MMMM");
      if (tempHolder in this.flightCalanderLookUP) {
        this.flightCalanderLookUP[tempHolder][element] = { soldout: true };
      } else {
        tempMonthHolderForSoldOutDates[element] = { soldout: true };
        this.flightCalanderLookUP[tempHolder] = tempMonthHolderForSoldOutDates;
        tempMonthHolderForSoldOutDates = {};
      }
      return null;
    });
    this.noneScheduledDatesOutbound?.map((element) => {
      var tempHolder = moment(element).locale("en").format("MMMM");
      if (tempHolder in this.flightCalanderLookUP) {
        //don nothing
      } else {
        this.flightCalanderLookUP[tempHolder] = {};
      }
      return null;
    });

    return true;
  };

  changeToShortForm(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  triggerNewSearch = async (date) => {
    var searchRequest = JSON.parse(
      JSON.stringify(this.props.searchFlight.searchRequest)
    );
    searchRequest.itineraryParts[0].when.date = date;

    this.props.updateFlightRequest(searchRequest);
    this.props.updateIsLoading(true);
    this.setState({ isSecondLoading: true, isFirstLoading: true });
    window.scrollTo({ top: 0, behavior: "smooth" });

    HttpService.postService(
      searchRequest,
      "/Availability/Search",
      this.props.token
    )
      .then((response) => {
        this.props.saveSearchResult(response);
        this.setState({ redirect: true });

        this.setState({ isLoading: false, redirect: true });
      })
      .catch((error) => {
        HttpService.getTokenServive()
          .then((response) => {
            this.props.saveToken(response.data.tokenDetail.access_token);
            HttpService.postService(
              this.props.searchFlight.searchRequest,
              "/Availability/Search",
              response.data.tokenDetail.access_token
            )
              .then((response) => {
                this.props.saveSearchResult(response);
                this.setState({
                  isSecondLoading: false,
                  isFirstLoading: false,
                  redirect: true,
                });
              })
              .catch((error) => {
                this.setState({
                  isSecondLoading: false,
                  isFirstLoading: false,
                });
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isSecondLoading: false, isFirstLoading: false });
          });
      });
  };

  scrollEventHandler = () => {
    try {
      var temp = {};
      var active = "";
      var firstElement = document.getElementById(
        Object.keys(this.flightCalanderLookUP)[0]
      );
      Object.keys(this.flightCalanderLookUP)
        .reverse()
        .map((key) => {
          temp[key] = document.getElementById(key);

          if (
            temp[key].offsetTop + firstElement.nextElementSibling.offsetHeight >
            window.pageYOffset
          ) {
            active = key;
          }
          return null;
        });

      document.getElementById("fare-calendar-month-inicator").value = active;

      // console.log(active);
    } catch (e) {
      console.log(e);
    }
  };
  handleMonthChange = (e) => {
    try {
      var element = document.getElementById(e.target.value);
      window.scrollTo({ top: element.offsetTop - 120, behavior: "smooth" });
    } catch (e) {
      console.log(e);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEventHandler);
  }

  // componentDidUpdate() {
  //   try {
  //     if (false){//this.state.selectedDate === "") {
  //       var element = document.getElementById(
  //         moment(this.currentDate).locale("en").format("MMMM")
  //       );
  //       window.scrollTo({ top: element.offsetTop - 120, behavior: "smooth" });
  //     }
  //   } catch (e) {
  //     // console.log(e);
  //   }
  // }

  sortFareCalender() {
    var resultArray = Object.keys(this.flightCalanderLookUP).sort(
      (firstElement, secondElement) => {
        var first = moment(
          Object.keys(this.flightCalanderLookUP[firstElement])[0]
        ).locale("en");
        var second = moment(
          Object.keys(this.flightCalanderLookUP[secondElement])[0]
        ).locale("en");

        if (first.isBefore(second)) return -1;
        if (first.isAfter(second)) return 1; //firstElement.departure < secondElement.departure
        return 0;
      }
    );
    return resultArray;
  }

  loadMore = (month = "first") => {
    if (this.monthCounter > 6) {
      return true;
    }
    var currentDate = moment(this.currentDate).startOf("month").add(1, "M");
    var tempAddedDays = currentDate.daysInMonth() === 31 ? 15 : 14;

    currentDate = currentDate.add(tempAddedDays, "days").format("YYYY-MM-DD");
    this.currentDate = currentDate;

    this.monthCounter += 1;
    var fareCalendarService = new FareCalendarService();
    if (month !== "first") this.setState({ isSecondLoading: true });
    else this.setState({ isFirstLoading: true });
    if (month === "first") this.loadMore("second");

    fareCalendarService
      .getFareCalender(
        currentDate,
        this.props.searchFlight.searchRequest,
        this.props.token
      )
      .then((response) => {
        this.fromAPIResponse.unbundledAlternateDateOffers =
          response.data.unbundledAlternateDateOffers;
        this.soldOutDatesOutbound = response.data.soldOutDatesOutbound;
        this.noneScheduledDatesOutbound =
          response.data.noneScheduledDatesOutbound;

        this.initilizeFareCalender();
        if (month !== "first") this.setState({ isSecondLoading: false });
        else this.setState({ isFirstLoading: false });
      })
      .catch((e) => {
        HttpService.getTokenServive()
          .then((response) => {
            this.props.saveToken(response.data.tokenDetail.access_token);
            fareCalendarService
              .getFareCalender(
                currentDate,
                this.props.searchFlight.searchRequest,
                response.data.tokenDetail.access_token
              )
              .then((response) => {
                this.fromAPIResponse.unbundledAlternateDateOffers =
                  response.data.unbundledAlternateDateOffers;
                this.soldOutDatesOutbound = response.data.soldOutDatesOutbound;
                this.noneScheduledDatesOutbound =
                  response.data.noneScheduledDatesOutbound;
                // if(month !== "first"){
                //   temporaryHolder
                // }
                this.initilizeFareCalender();
                // this.currentDate = currentDate;
                if (month !== "first")
                  this.setState({ isSecondLoading: false });
                else this.setState({ isFirstLoading: false });
              })
              .catch((e) => {
                console.log(e);
                this.setState({
                  isSecondLoading: false,
                  isFirstLoading: false,
                });
              });
          })
          .catch((e) => {
            this.setState({ isSecondLoading: false, isFirstLoading: false });
            console.log(e);
          });
      });
  };

  handleDayClick = (event, element) => {
    if (element !== undefined && !element.soldout) {
      this.setState({
        selectedDate: moment(element.itineraryPart[0].segments[0].departure)
          .locale("en")
          .format("YYYY-MM-DD"),
      });
      window.scrollTo({
        top: event.target.offsetTop - 200,
        behavior: "smooth",
      });
    }
  };

  getPrice(price) {
    if (window.innerWidth > 767) return this.numberWithCommas(price);
    else return this.changeToShortForm(price);
  }
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/flightSelect",
            isLoading: true,
          }}
        />
      );
    }
    return (
      <>
        {this.state.isFirstLoading || this.state.isSecondLoading ? (
          <div className="">
            <Loading />
          </div>
        ) : null}
        <>
          <div className="d-flex row container mx-auto mt-2 fare-calendar-months-container">
            <div className="search-parameters d-flex col-12 pt-3">
              <p>
                Select your flights for your{" "}
                {AirportFullName.getFullName(
                  this.props.searchFlight.searchRequest.itineraryParts[0].from
                    .code
                )}{" "}
                -{" "}
                {AirportFullName.getFullName(
                  this.props.searchFlight.searchRequest.itineraryParts[0].to
                    .code
                )}
                .
              </p>
            </div>
          </div>
          <div className="d-flex row container mx-auto calender-weekheader-container mt-0">
            <div className="row d-flex col-12 pt-3">
              <div className="col-12 d-flex col-lg-7 justify-content-center justify-content-lg-end">
                <select
                  className="form-control calender-month-select d-flex"
                  id="fare-calendar-month-inicator"
                  onChange={(e) => this.handleMonthChange(e)}
                >
                  {Object.keys(this.flightCalanderLookUP).map(
                    (internal_key) => {
                      return (
                        <option value={internal_key} key={internal_key}>
                          {internal_key}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="col-5 justtify-content-center d-none d-lg-flex justify-content-end">
                Prices are lowest, one way, per passenger
              </div>
            </div>
            {this.dateHeaders.map((dateHeader) => {
              return (
                <div
                  key={dateHeader}
                  className="d-flex calendar-dateHeader-container"
                >
                  <div className="d-flex calendar-dateHeader justify-content-center align-items-center flex-column">
                    {dateHeader}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex row mx-3 container mx-auto fare-calendar-months-container">
            {this.sortFareCalender().map((internal_key) => {
              return (
                <>
                  <div
                    id={internal_key}
                    className="d-flex justify-content-center w-100"
                    key={internal_key}
                  >
                    <h4 className="py-5">
                      {moment(
                        Object.keys(this.flightCalanderLookUP[internal_key])[0]
                      )
                        .locale("en")
                        .format("MMMM YYYY")}
                    </h4>
                  </div>
                  <div className="d-flex row mx-3 mx-auto w-100">
                    <>
                      {/* push the moth to appropriate day */}
                      {[
                        ...Array(
                          parseInt(
                            moment(
                              Object.keys(
                                this.flightCalanderLookUP[internal_key]
                              )[0]
                            )
                              .subtract(
                                Object.keys(this.flightCalanderLookUP)[0] ===
                                  internal_key
                                  ? moment(
                                      Object.keys(
                                        this.flightCalanderLookUP[internal_key]
                                      )[0]
                                    )
                                      .locale("en")
                                      .format("e")
                                  : parseInt(
                                      moment(
                                        Object.keys(
                                          this.flightCalanderLookUP[
                                            internal_key
                                          ]
                                        )[0]
                                      )
                                        .locale("en")
                                        .format("DD")
                                    ) - 1,
                                "days"
                              )
                              .locale("en")
                              .format("e")
                          )
                        ),
                      ].map((index) => {
                        return (
                          <div
                            key={index}
                            className="d-flex calendar-date-container"
                          ></div>
                        );
                      })}
                      {/* list the dates */}
                      {[
                        ...Array(
                          moment(
                            Object.keys(
                              this.flightCalanderLookUP[internal_key]
                            )[0]
                          ).daysInMonth()
                        ).keys(),
                      ]
                        .slice(
                          Object.keys(this.flightCalanderLookUP)[0] !==
                            internal_key
                            ? 0
                            : moment(
                                Object.keys(
                                  this.flightCalanderLookUP[internal_key]
                                )[0]
                              ).format("DD") -
                                (parseInt(
                                  moment(
                                    Object.keys(
                                      this.flightCalanderLookUP[internal_key]
                                    )[0]
                                  )
                                    .locale("en")
                                    .format("e")
                                ) +
                                  1)
                        )
                        .map((index, arrayIndex) => {
                          var tempClassList =
                            "d-flex calendar-date justify-content-center align-items-center flex-column " +
                            (arrayIndex === 0 ||
                            moment(
                              Object.keys(
                                this.flightCalanderLookUP[internal_key]
                              )[0]
                            )
                              .startOf("month")
                              .add(index, "days")
                              .locale("en")
                              .format("ddd") === "Sun"
                              ? "calendar-date-left-end "
                              : "");
                          tempClassList =
                            tempClassList +
                            (parseInt(
                              moment(
                                Object.keys(
                                  this.flightCalanderLookUP[internal_key]
                                )[0]
                              )
                                .endOf("month")
                                .locale("en")
                                .format("DD")
                            ) ===
                              index + 1 ||
                            moment(
                              Object.keys(
                                this.flightCalanderLookUP[internal_key]
                              )[0]
                            )
                              .startOf("month")
                              .add(index, "days")
                              .locale("en")
                              .format("ddd") === "Sat"
                              ? "calendar-date-right-end "
                              : "");
                          return (
                            <>
                              <div
                                className={
                                  this.state.selectedDate ===
                                  moment(
                                    Object.keys(
                                      this.flightCalanderLookUP[internal_key]
                                    )[0]
                                  )
                                    .startOf("month")
                                    .add(index, "days")
                                    .format("YYYY-MM-DD")
                                    ? "d-flex calendar-date-container active"
                                    : "d-flex calendar-date-container"
                                }
                                key={internal_key + "-" + index}
                              >
                                {this.state.selectedDate ===
                                moment(
                                  Object.keys(
                                    this.flightCalanderLookUP[internal_key]
                                  )[0]
                                )
                                  .startOf("month")
                                  .add(index, "days")
                                  .format("YYYY-MM-DD") ? (
                                  <span
                                    className="clear-selected-date"
                                    onClick={() =>
                                      this.setState({ selectedDate: "" })
                                    }
                                  >
                                    x
                                  </span>
                                ) : null}
                                <div
                                  role="button"
                                  index="1"
                                  className={
                                    moment(
                                      Object.keys(
                                        this.flightCalanderLookUP[internal_key]
                                      )[0]
                                    )
                                      .startOf("month")
                                      .add(index, "days")
                                      .format("YYYY-MM-DD") in
                                    this.flightCalanderLookUP[internal_key]
                                      ? tempClassList + "present"
                                      : tempClassList + "no-flight-day"
                                  }
                                  onClick={(event) =>
                                    this.handleDayClick(
                                      event,
                                      this.flightCalanderLookUP[internal_key][
                                        moment(
                                          Object.keys(
                                            this.flightCalanderLookUP[
                                              internal_key
                                            ]
                                          )[0]
                                        )
                                          .startOf("month")
                                          .add(index, "days")
                                          .format("YYYY-MM-DD")
                                      ]
                                    )
                                  }
                                >
                                  <span className="d-flex fare-calender-day">
                                    {moment(
                                      Object.keys(
                                        this.flightCalanderLookUP[internal_key]
                                      )[0]
                                    )
                                      .startOf("month")
                                      .add(index, "days")
                                      .format("DD")}
                                  </span>

                                  {moment(
                                    Object.keys(
                                      this.flightCalanderLookUP[internal_key]
                                    )[0]
                                  )
                                    .startOf("month")
                                    .add(index, "days")
                                    .format("YYYY-MM-DD") in
                                  this.flightCalanderLookUP[internal_key] ? (
                                    <span className="d-flex fare-calendar-total-price-container">
                                      {!this.flightCalanderLookUP[internal_key][
                                        moment(
                                          Object.keys(
                                            this.flightCalanderLookUP[
                                              internal_key
                                            ]
                                          )[0]
                                        )
                                          .startOf("month")
                                          .add(index, "days")
                                          .format("YYYY-MM-DD")
                                      ].soldout ? (
                                        <>
                                          <span className="d-inline-flex fare-calendar-price">
                                            {this.getPrice(
                                              this.flightCalanderLookUP[
                                                internal_key
                                              ][
                                                moment(
                                                  Object.keys(
                                                    this.flightCalanderLookUP[
                                                      internal_key
                                                    ]
                                                  )[0]
                                                )
                                                  .startOf("month")
                                                  .add(index, "days")
                                                  .format("YYYY-MM-DD")
                                              ].total.alternatives[0][0].amount
                                            )}
                                          </span>
                                          <span className="d-inline-flex fare-calendar-currency pl-1">
                                            {
                                              this.flightCalanderLookUP[
                                                internal_key
                                              ][
                                                moment(
                                                  Object.keys(
                                                    this.flightCalanderLookUP[
                                                      internal_key
                                                    ]
                                                  )[0]
                                                )
                                                  .startOf("month")
                                                  .add(index, "days")
                                                  .format("YYYY-MM-DD")
                                              ].total.alternatives[0][0]
                                                .currency
                                            }
                                          </span>
                                        </>
                                      ) : (
                                        <span className="fare-calendar-sold-out">
                                          {Translation.t("sold_out")}
                                        </span>
                                      )}
                                    </span>
                                  ) : (
                                    <span className="d-inline-flex fare-calendar-no-flight">
                                      <span className="d-flex d-md-none">
                                        {Translation.t("NONE")}
                                      </span>
                                      <span className="d-none d-md-flex">
                                        {Translation.t("no_flight")}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </>
                  </div>
                </>
              );
            })}
            <div className="row mx-0 w-100 pt-4 pb-5 mb-5">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <button
                  disabled={this.monthCounter > 6 ? true : false}
                  className="bk-submit-btn btn-ethiopian btn"
                  onClick={() => this.loadMore()}
                >
                  {Translation.t("load_next_month")}
                </button>
              </div>
            </div>
            {this.state.selectedDate !== "" ? (
              <div className="row justify-content-center mx-0 py-3 w-100 fare-calender-footer">
                <div className="col-6 d-flex align-items-center">
                  <p className="selected-departure-date-p">
                    {Translation.t("selected_departure_date")}{" "}
                    <span className="selected-departure-date">
                      {this.state.selectedDate}
                    </span>{" "}
                  </p>
                </div>
                <div className="col-3 d-flex align-items-center">
                  <button
                    className="bk-submit-btn btn-ethiopian btn"
                    onClick={() =>
                      this.triggerNewSearch(this.state.selectedDate)
                    }
                  >
                    {Translation.t("continue")}
                  </button>
                </div>
                <div className="d-flex d-md-none">
                  <span
                    className="clear-selected-date-on-bottom"
                    onClick={() => this.setState({ selectedDate: "" })}
                  >
                    x
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </>
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.searchFlight.token,
    searchFlight: state.searchFlight,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveToken: (token) =>
      dispatch({
        type: searchFlightAction.SAVETOKEN,
        token: token,
      }),
    updateIsLoading: (value) => {
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      });
    },
    saveSearchResult: (result) =>
      dispatch({
        type: searchFlightAction.SAVESEARCHRESULT,
        searchResult: result,
      }),
    updateFlightRequest: (value) => {
      dispatch({
        type: searchFlightAction.UPDATEFLIGHTREQUEST,
        searchRequest: value,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FareCalendar);
