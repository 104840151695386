import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import Translation from "i18next";
import { connect } from "react-redux";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import PassengerService from "../../../service/booking/passenger/PassengerService";

const selectStyleDisabled = AirportSelectStyle.disabledSelect;
const selectStyleDesktop = AirportSelectStyle.desktopStyle;
const passengerService = new PassengerService();

const ExchangePassengers = (props) => {
  const getSplitBirthDate = (type) => {
    var splitedBirthDate = props.passenger.passengerInfo.dateOfBirth.split("-");
    if (type === "year") {
      return splitedBirthDate[0];
    } else if (type === "month") {
      return splitedBirthDate[1];
    } else {
      return splitedBirthDate[2];
    }
  };
  const getPhoneNumber = (number) => {
    if (number === 1) {
      try {
        return (
          "+" +
          props.passenger.passengerInfo.phones[0].countryCode +
          props.passenger.passengerInfo.phones[0].areaCode +
          props.passenger.passengerInfo.phones[0].countryCode +
          props.passenger.passengerInfo.phones[0].number
        );
      } catch {
        return null;
      }
    } else {
      try {
        return (
          "+" +
          props.passenger.passengerInfo.phones[1].countryCode +
          props.passenger.passengerInfo.phones[1].areaCode +
          props.passenger.passengerInfo.phones[0].countryCode +
          props.passenger.passengerInfo.phones[1].number
        );
      } catch {
        return null;
      }
    }
  };

  return (
    <>
      <>
        <Card className="mt-3">
          <Card.Header className="passanger-info-card-header passenger-header-sticky">
            <h5>
              {props.passenger.passengerDetails.firstName}{" "}
              {props.passenger.passengerDetails.lastName} (
              {Translation.t("ADT")})
            </h5>
          </Card.Header>
          <Card.Body className="passanger-info-card-body">
            <Row>
              {props.passenger.passengerInfo.type === "ADT" && (
                <Col xs="12" sm={12} md={6} lg={2} className="px-1 my-2">
                  <Select
                    styles={selectStyleDisabled}
                    menuIsOpen={false}
                    isSearchable={false}
                    placeholder={
                      <span style={{ color: "black" }}>
                        {props.passenger.passengerDetails.prefix}
                      </span>
                    }
                  />
                  <label className="form-sub-label mx-2">
                    {Translation.t("title")}
                  </label>
                </Col>
              )}
              <Col xs="12" sm={12} md={6} lg={4} className="px-1 my-2">
                <input
                  className="form-control widget-input disabled-input"
                  disabled
                  placeholder="First Name"
                  defaultValue={props.passenger.passengerDetails.firstName}
                />
                <label className="form-sub-label mx-2">
                  {Translation.t("first_name")}
                </label>
              </Col>
              <Col
                xs="12"
                sm={12}
                md={6}
                lg={props.passenger.passengerInfo.type !== "ADT" ? 4 : 3}
                className="px-1 my-2"
              >
                <input
                  className="form-control widget-input disabled-input"
                  disabled
                  defaultValue={props.passenger.passengerDetails.middleName}
                  placeholder={Translation.t("middle_name")}
                />
                <label className="form-sub-label mx-2">
                  {Translation.t("middle_name")}
                </label>
              </Col>
              <Col xs="12" sm={12} md={6} lg={3} className="px-1 my-2">
                <input
                  className="form-control widget-input disabled-input"
                  disabled
                  defaultValue={props.passenger.passengerDetails.lastName}
                  placeholder="Last Name"
                />
                <label className="form-sub-label mx-2">
                  {Translation.t("last_name")}
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Row className="mr-0">
                  <div className="col-4 pr-0">
                    <Select
                      styles={selectStyleDisabled}
                      isSearchable={false}
                      menuIsOpen={false}
                      placeholder={
                        <span style={{ color: "black" }}>
                          {getSplitBirthDate("year")}
                        </span>
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_year")}
                    </label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      styles={selectStyleDisabled}
                      isSearchable={false}
                      menuIsOpen={false}
                      placeholder={
                        <span style={{ color: "black" }}>
                          {getSplitBirthDate("month")}
                        </span>
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_month")}
                    </label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      styles={selectStyleDisabled}
                      isSearchable={false}
                      menuIsOpen={false}
                      placeholder={
                        <span style={{ color: "black" }}>
                          {getSplitBirthDate("day")}
                        </span>
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_day")}
                    </label>
                  </div>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Select
                  styles={selectStyleDisabled}
                  menuIsOpen={false}
                  isSearchable={false}
                  placeholder={
                    <span style={{ color: "black" }}>
                      {props.passenger.passengerInfo.gender}
                    </span>
                  }
                />
                <label className="form-sub-label mx-2">
                  {Translation.t("gender")}
                </label>
              </Col>
            </Row>
            {props.index === 0 && (
              <div>
                <Row>
                  <Col xs={12} sm={12} md={6} className="px-1 my-2">
                    <PhoneInput
                      inputProps={{
                        className:
                          "form-control  widget-input disabled-input my-2",
                      }}
                      disabled
                      value={getPhoneNumber(1)}
                    />
                    <label className="form-sub-label mx-2">
                      {Translation.t("phone_number")}
                    </label>
                  </Col>
                  <Col xs={12} sm={12} md={6} className="px-1 my-2">
                    <PhoneInput
                      inputProps={{
                        className:
                          "form-control  widget-input disabled-input my-2",
                      }}
                      disabled
                      placeholder="Phone Number 2"
                      value={getPhoneNumber(2)}
                    />
                    <label className="form-sub-label mx-2">
                      {Translation.t("phone_number_2")}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={6} className="px-1 my-2">
                    <input
                      className="form-control widget-input disabled-input"
                      disabled
                      defaultValue={props.passenger.passengerInfo.emails[0]}
                      placeholder={Translation.t("email_address")}
                    />
                    <label className="form-sub-label mx-2">
                      {Translation.t("email_address")}
                    </label>
                  </Col>
                </Row>
              </div>
            )}
          </Card.Body>
        </Card>
      </>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    state: state,
  };
};
// const mapDispatchToProps = (dispacth) => {
//   return {
//     changeSelectValidation: (valueParameter, validationParameter, domain) =>
//       dispacth({
//         type: accountAction.CHANGEVALIDATION,
//         domain: domain,
//         valueParameter: valueParameter,
//         validationParameter: validationParameter,
//       }),
//     updateAccountModel: (value, valueParameter) =>
//       dispacth({
//         type: accountAction.UPADTE,
//         value: value,
//         valueParameter: valueParameter,
//       }),
//     validateAccountModel: () => {
//       dispacth({
//         type: accountAction.VALIDATECREATEACCOUNT,
//       });
//     },
//     updateGender: (value) => {
//       dispacth({
//         type: accountAction.UPDATEGENDER,
//         value: value,
//       });
//     },
//   };
// };
export default connect(mapStateTopProps, null)(ExchangePassengers);
