import React from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
import Translation from "../../../service/shared/multi-lingual/Translation";

export default class ManageTripFareRule extends React.Component {
  returnFare = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.fareRule.segmentFareRules.map((item, key1) => (
            <Tab
              eventKey={key1}
              key={key1}
              title={item.segmentKeys.map((segmentKeys, key2) =>
                key2 === 0 ? (
                  <span key={key2}>
                    <span style={{ color: "black" }}>{segmentKeys.origin}</span>
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{ fontSize: "20px", color: "black" }}
                    />
                    {"  "}
                    <span style={{ color: "black" }}>
                      {segmentKeys.destination}
                    </span>
                  </span>
                ) : (
                  <span key={key2}>
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{ fontSize: "20px", color: "black" }}
                    />
                    {"  "}
                    <span style={{ color: "black" }}>
                      {segmentKeys.destination}
                    </span>
                  </span>
                )
              )}
            >
              {item.fareBasisRules.fareRules.map((fareRules, key3) => (
                <div key={key3}>
                  <h5>
                    <pre>{fareRules.ruleCode}</pre>
                  </h5>
                  <div>
                    <pre>{fareRules.ruleText}</pre>
                  </div>
                </div>
              ))}
            </Tab>
          ))}
        </Tabs>
      </div>
    );
  };
  render() {
    return (
      <>
        <Modal
          show={this.props.showFareRule}
          onHide={() => this.props.hideFareRule()}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Translation Key="fare_rules" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {" "}
              <Translation Key="fare_rules_body" />
            </p>
            {this.props.fareRule !== null ? (
              <span>{this.returnFare()}</span>
            ) : (
              <p className="loading"></p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() => this.props.hideFareRule()}
            >
              <Translation Key="close" />
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
