import HttpService from "../shared/HttpService";

export default class FlightInfoService {

  getFlightScheduleByRoute = (data, token) => {
    let relativeUrl = "/FlightSchedule/ByRoute";
    var searchRequest = {
      FlightSchedule: [
        {
          Origin: "ADD",
          Destination: "MQX",
          requestType: 2,
          departureDate: "2021-07-25",
        },
      ],
    };

    searchRequest.FlightSchedule = data;

    return HttpService.postFlightInfo(searchRequest, relativeUrl, token);
  };
  getFlightStatusByRoute = (data, token) => {
    let relativeUrl = "/FlightStatus/ByRoute";
    var searchRequest = {
      "Origin":"ADD",
      "Destination":"MQX"
    };

    searchRequest.Origin = data.Origin;
    searchRequest.Destination = data.Destination;

    return HttpService.postFlightInfo(searchRequest, relativeUrl, token);
  };
  getFlightStatusByFlightNumber = (data, token) => {
    let relativeUrl = "/FlightStatus/ByFlightNumber";
    var searchRequest = {
      "FlightNumber":"500"
    };

    searchRequest.FlightNumber = data;

    return HttpService.postFlightInfo(searchRequest, relativeUrl, token);
  };
}
