import BaggageInfoService from "../../../service/shared/BaggageInfoService";
import { shoppingCartAction } from "../../actions/booking/shoopingCartAction";

const initialState = {
    fareRule:null,
    baggageInfo:null
  };
  var tempBaggageInfoService= new BaggageInfoService();
  const shoopingCartReducer = (state = initialState, action) => {
    switch (action.type) {
      case shoppingCartAction.SAVEFARERULE:
        return {
          ...state,
          fareRule: action.fareRule,
        };
      case shoppingCartAction.SAVEBAGGAGEINFO:
        var tempBaggageInfo = tempBaggageInfoService.jsonMap(action.baggageInfo);
        return {
          ...state,
          baggageInfo: tempBaggageInfo,
        };
        case shoppingCartAction.RESETFARERULEANDBAGGAGE:
          return {
            ...state,
            baggageInfo: null,
            fareRule:null,
          };
        
      default:
        return state;
    }
  };
  
  export default shoopingCartReducer;