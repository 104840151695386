import HttpService from "../shared/HttpService";

export default class CheckInService {
  updateHideOptions = (state, value) => {
    state.checkInModel.searchType = value;
    return state;
  };
  validationCheckModel = () => {};

  pnrLookUp = (data, token) => {
    let relativeUrl = "/etibe-checkinapi/V1.0/PNR/Lookup";
    var searchRequest = {
      pnr: "GRYUXK",
      CheckInType: 2,
    };

    searchRequest.pnr = data.pnr;
    // searchRequest.lastName = data.lastName;

    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  };

  updateDocument(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/Update/Document";
    data.checkInSabreDataRequest = checkInSabreDataRequest;
    var searchRequest = {
      returnSession: true,
      passengerDetails: [
        {
          passengerId: "p01.01",
          documents: [
            {
              document: {
                id: "p01.01.d01",
                type: "PASSPORT",
                number: "EP5397278",
                personName: {
                  prefix: "MR",
                  first: "WORLD",
                  middle: null,
                  last: "HELLO",
                  suffix: null,
                  raw: "HELLO/WORLD MR",
                },
                nationality: "ET",
                countryOfBirth: "ET",
                placeOfBirth: "addis",
                dateOfBirth: "1996-06-16",
                issueDate: "2018-05-26",
                issuingCountry: "ET",
                expiryDate: "2021-09-26",
                gender: "MALE",
              },
            },
            {
              document: {
                id: "p01.01.v01",
                type: "VISA",
                number: "EP5397278",
                personName: {
                  prefix: null,
                  first: "MOHAMMED SEID",
                  middle: null,
                  last: "TESEMMA",
                  suffix: null,
                  raw: null,
                },
                nationality: "US",
                countryOfBirth: "US",
                placeOfBirth: "addis",
                dateOfBirth: "1996-06-16",
                issueDate: "2018-05-26",
                applicableCountry: "US",
                issuingPlace: "vegas",
                issuingCountry: "US",
                expiryDate: "2021-09-26",
                gender: "MALE",
              },
            },
          ],
          addresses: [
            {
              id: "p01.01.a01",
              type: "RESIDENCE",
              street1: "Sabre Drive 21",
              postalCode: "123456",
              country: "ET",
              city: "Addis",
              stateProvince: "AddisAbaba",
            },
            {
              id: "p01.01.a02",
              type: "DESTINATION",
              street1: "Sabre Drive 21",
              postalCode: "123456",
              country: "US",
              city: "Vegas",
              stateProvince: "Vegas",
            },
          ],
          weightCategory: "CHILD",
        },
      ],
      checkInSabreDataRequest: "",
    };

    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.passengerDetails = data;

    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }

  verifyTimatic(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/Timatic/Verify";
    data.checkInSabreDataRequest = checkInSabreDataRequest;

    var searchRequest = {
      returnSession: true,
      passengerIds: [],
    };

    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.passengerIds = data;

    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }

  getSeatMap(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/Seat/Map";
    data.checkInSabreDataRequest = checkInSabreDataRequest;

    var searchRequest = {
      returnSession: true,
      passengers: [],
      segmentIds: [],
    };

    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.passengers = data.passengerIds;
    searchRequest.segmentIds = data.segmentIds;

    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }

  selectSeat(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/Seat/Select";

    var searchRequest = {
      selectSeatRequests: [
        {
          returnSession: true,
          seatNumber: "11C",
          passengerFlightId: "p01.01.s1.f1",
        },
      ],
    };
    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.selectSeatRequests[0].passengerFlightId =
      data.passengerFlightId;
    searchRequest.selectSeatRequests[0].seatNumber = data.seatNumber;
    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }
  pnrCheckin(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/PNR/CheckIn";

    var searchRequest = {
      returnSession: true,
      passengerIds: ["p01.01"],
      outputFormat: "",
      waiveAutoReturnCheckIn: false,
    };
    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.passengerIds = data;
    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }
  emailBoardingPass(data, token) {
    let relativeUrl = "/etibe-checkinapi/V1.0/BoardingPass/EmailBoardingPass";

    var searchRequest = {
      pnr: "MIEJXA",
      CheckInType: 2,
      email: "yisak@ethiopianairlines.com",
      SenderName: "Ene Negn",
      emailandNames: { Tadesse: "yisak@ethiopianairlines.com" },
    };
    // searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.pnr = data.pnr;
    searchRequest.emailandNames = data.emailandNames;
    // searchRequest.SenderName = data.SenderName;
    searchRequest.email = data.email;
    // searchRequest.SegmentIds = data.SegmentIds;
    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }
  getBoardingPass(data, token, checkInSabreDataRequest) {
    let relativeUrl = "/etibe-checkinapi/V1.0/BoardingPass/Get";

    var searchRequest = {
      passengerFlightIds: ["p01.01.s1.f1"],
      returnSession: true,
      outputFormat: "IMAGE",
    };
    searchRequest.checkInSabreDataRequest = checkInSabreDataRequest;
    searchRequest.pnr = data.pnr;
    searchRequest.passengerFlightIds = data.passengerFlightIds;
    // searchRequest.SenderName = data.SenderName;
    // searchRequest.email = data.email;
    // searchRequest.SegmentIds = data.SegmentIds;
    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }
  getBoardingPassCustom(pnr, token) {
    let relativeUrl = "/etibe-checkinapi/V1.0/BoardingPass/Custom";

    var searchRequest = {
      pnr: "EKJCSW",
      CheckInType: 2,
    };
    searchRequest.pnr = pnr;
    // searchRequest.SenderName = data.SenderName;
    // searchRequest.email = data.email;
    // searchRequest.SegmentIds = data.SegmentIds;
    return HttpService.checkInPostService(searchRequest, relativeUrl, token);
  }
}
