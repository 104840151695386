

export class TravelBankValidatorService
{
    constructor()
    {
        this.isMembershipNumberValid=true;
        this.isPasswordValid=true;
        this.travelBankValidLists =[];
    }

  validateTravelBankInfo(travelBankProfile)
    {
        this.travelBankValidLists =[];
        for(let index =0;index<2;index++)
        {
            this.travelBankValidLists.push(true);
        }
        
        this.isMembershipNumberValid=this.validateTravelBankType(travelBankProfile.MembershipNumber,0);
        this.isPasswordValid=this.validateTravelBankType(travelBankProfile.Password,1);

         return {"travelBankValidation":this,"travelBankValidLists":  this.travelBankValidLists};
    }
    validateTravelBankType(type,index)
    {
        if(type === "" || type == null)
        {
           this.travelBankValidLists[index]=false;
           return false;
        }
        else
        {
            this.travelBankValidLists[index]=true;
            return  true;
        }
    }

}