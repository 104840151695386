import { React, Component } from "react";
import Seat from "./Seat";
import { Col, Row, Modal } from "react-bootstrap";
import "./Seats.css";
import Translation from "i18next";

class Seats extends Component {
  state = {
    isSeatPopUpModalOpen: false,
    isNextModalOpened: false,
    isModalForContinuingToBaggage: false,
  };
  seatName = "";
  top = 0;
  right = 0;
  left = 0;
  prevElement = "";
  firstRowExit = true;
  columnRefLookup = {};
  openSeatPopUpModal = (event, value) => {
    var ClientRect = event.target.getBoundingClientRect();

    this.seatName = value;

    this.top = ClientRect.y + 70;
    this.right = ClientRect.right;
    this.left = ClientRect.left;

    this.setState({ isSeatPopUpModalOpen: true });
  };
  closeSeatPopUpModal = () => {};

  checkAllPassengersSelectSeat = (selectedSeats, passangers) => {
    console.log("continueToBaggage");

    const countAdultOrChildPassenger = passangers.filter(
      (passenger) =>
        passenger.passenger.passengerInfo.type === "ADT" ||
        passenger.passenger.passengerInfo.type === "CHD"
    ).length;
    if (
      selectedSeats[this.props.seatMapIdentifier.replace("-", "")] !== undefined
    ) {
      const selectedSeatsCount = Object.keys(
        selectedSeats[this.props.seatMapIdentifier.replace("-", "")]
      ).length;
      if (
        countAdultOrChildPassenger === selectedSeatsCount &&
        this.props.seatMapIndex - 1 !== this.props.TotalSeatMaps
      ) {
        this.setState({ isNextModalOpened: true });
      }
      if (
        countAdultOrChildPassenger === selectedSeatsCount &&
        this.props.seatMapIndex - 1 === this.props.TotalSeatMaps
      ) {
        this.setState({ isModalForContinuingToBaggage: true });
        // this.props.continueToBaggage();
      }
    }
  };

  getModal = () => {
    return (
      <Modal
        show={this.state.isNextModalOpened}
        onHide={() => {
          this.setState({
            isNextModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h4>{Translation.t("next_flight")}</h4>
          <h5>{Translation.t("do_you_want_to_proceed_to_next_flight")}</h5>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button
              className="col-12 col-lg-3 btn btn-success mr-lg-2 mb-3 mb-lg-0"
              onClick={() => this.handleViewNextFlight()}
            >
              {Translation.t("view_next_flight")}
            </button>
            <button
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
              onClick={() => this.setState({ isNextModalOpened: false })}
            >
              {Translation.t("cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  getModalForContinuingToBaggage = () => {
    return (
      <Modal
        show={this.state.isModalForContinuingToBaggage}
        onHide={() => {
          this.setState({
            isNextModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h4>{Translation.t("continue_to_extras")}</h4>
          <h5>{Translation.t("message_for_all_selected_passanger")}</h5>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button
              className="col-12 col-lg-3 btn btn-success mr-lg-2 mb-3 mb-lg-0"
              onClick={() => this.props.continueToBaggage()}
            >
              {Translation.t("Continue")}
            </button>
            <button
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
              onClick={() =>
                this.setState({ isModalForContinuingToBaggage: false })
              }
            >
              {Translation.t("cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  handleViewNextFlight = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ isNextModalOpened: false });
    this.props.nextFlight(this.props.seatMapIndex);
  };
  getAisleCountHalf(columns) {
    var counter = 0;
    columns.map((column) => {
      if (
        column.characteristic !== null &&
        column.characteristic[0].value === "AISLE"
      ) {
        counter = counter + 1;
      }
      this.columnRefLookup[column.id] = column.id.slice(1);
      return null;
    });
    return counter / 2;
  }
  getBulkHeadCount = (columns, outIndex, seats, fromRight) => {
    // console.log(seats.slot[outIndex]);
    var counter = 0;
    var startCounting = false;
    if (fromRight) {
      if (outIndex === seats.slot.length - 1) {
        if (
          seats.slot[seats.slot.length - 1].columnRef ===
          columns[columns.length - 1].id
        ) {
          return parseInt(counter / 3);
        } else {
          columns.map((column) => {
            counter = counter + 1;
            if (column.id === seats.slot[outIndex].columnRef) {
              if (startCounting) {
                counter = counter + 1;
              }
              if (column.id === seats.slot[outIndex].columnRef) {
                startCounting = true;
              }
            }
            return null;
          });
          if(columns.length === 4){
            if (counter > 2) {
              return 2;
            }
          }
          if (counter > 3) {
            return 3;
          }
          return parseInt(counter);
        }
      } else {
        columns.map((column) => {
          if (
            outIndex !== seats.slot.length - 1 &&
            column.id === seats.slot[outIndex + 1].columnRef
          ) {
            startCounting = false;
          }
          if (startCounting) {
            counter = counter + 1;
          }
          if (column.id === seats.slot[outIndex].columnRef) {
            startCounting = true;
          }
          return null;
        });
        if (counter > 3) {
          return 3;
        }
        return parseInt(counter);
      }
    } else {
      if (outIndex === 0) {
        if (seats.slot[0].columnRef === columns[0].id) {
          return parseInt(counter / 3);
        } else {
          columns.map((column) => {
            counter = counter + 1;
            if (column.id === seats.slot[outIndex].columnRef) {
              return;
            }
          });
          return parseInt(counter / 3);
        }
      } else {
        columns.map((column) => {
          if (startCounting) {
            counter = counter + 1;
          }
          if (column.id === seats.slot[outIndex].columnRef) {
            startCounting = true;
          }
          if (
            outIndex !== seats.slot.length - 1 &&
            column.id === seats.slot[outIndex + 1].columnRef
          ) {
            startCounting = false;
          }
          if (outIndex === seats.slot.length - 1) {
            startCounting = false;
          }
          return null;
        });
        return parseInt(0 / 3);
      }
    }

    // columns.map((column) => {
    //   if (
    //     column.characteristic !== null &&
    //     column.characteristic[0].value === "AISLE"
    //   ) {
    //     counter = counter + 1;
    //   }
    //   this.columnRefLookup[column.id] = column.id.slice(1);
    // });
    // return counter / 2;
  };
  render() {
    // console.log(this.columnRefLookup);
    return (
      <div
        key={"seats-container-" + this.props.SeatMapId}
        className={
          "my-5 seats-container-list seat-column-" +
          (this.props.Cabin.column.length +
            2 +
            this.getAisleCountHalf(this.props.Cabin.column))
        }
        id={"seats-container-" + this.props.SeatMapId}
      >
        {this.getModal()}
        {this.getModalForContinuingToBaggage()}
        <Row className="justify-content-center mx-0 seat-row">
          <div className={"right-or-left-side LEFT_SIDE"}></div>
          {this.props.Cabin.column.map((column, index) => {
            return (
              <>
                <Col
                  key={column.id}
                  className="justify-content-center text-center column-names"
                >
                  {column.name}
                </Col>
                {column.characteristic !== null &&
                column.characteristic[0].value === "AISLE" &&
                this.props.Cabin.column[index + 1] !== undefined &&
                this.props.Cabin.column[index + 1].characteristic !== null &&
                this.props.Cabin.column[index + 1].characteristic[0].value ===
                  "AISLE" ? (
                  <div className="AISLE text-center mb-3 d-flex align-items-center justify-content-center col"></div>
                ) : null}
              </>
            );
          })}
          <div className={"right-or-left-side RIGHT_SIDE"}></div>
        </Row>

        {this.props.Cabin.row.map((seats, outIndex) => {
          return (
            <Row className="mx-0 seat-row justify-content-center">
              <div className={"right-or-left-side LEFT_SIDE"}></div>
              {seats.slot.map((slot, index) => {
                return (
                  <>
                    {slot.seat === null ? (
                      <>
                        {seats.slot[0].columnRef === slot.columnRef &&
                        seats.slot.length <= this.props.Cabin.column.length ? (
                          <>
                            {[
                              ...Array(
                                this.getAisleCountHalf(
                                  this.props.Cabin.column
                                ) -
                                  seats.slot.length +
                                  this.props.Cabin.column.length
                              ).keys(),
                            ].map((element) => {
                              return (
                                <div className="seat-top-wrapper h-50 justify-content-center col"></div>
                              );
                            })}
                          </>
                        ) : null}
                        {this.props.Cabin.column.filter(
                          (column) => column.id === slot.columnRef
                        )[0].characteristic !== null &&
                        this.props.Cabin.column.filter(
                          (column) => column.id === slot.columnRef
                        )[0].characteristic[0].value === "AISLE" ? (
                          <div className="AISLE text-center h-50 d-flex align-items-center justify-content-center col"></div>
                        ) : (
                          <div className="seat-top-wrapper h-50 justify-content-center col"></div>
                        )}
                      </>
                    ) : (
                      <>
                      {/* detect slot jump */}
                      {seats.slot.length < this.props.Cabin.column.length && index !== 0 && slot.position === "AISLE" &&
                        seats.slot[index + 1] !== undefined &&
                        seats.slot[index + 1].position !== "AISLE" ? (
                          <div className="AISLE text-center h-50 d-flex align-items-center justify-content-center col">
                            {seats.number}
                          </div>
                        ) : null}
                        {/* seat infornt bult calculator */}
                        {seats.slot.length < this.props.Cabin.column.length ? (
                          <>
                            {[
                              ...Array(
                                this.getBulkHeadCount(
                                  this.props.Cabin.column,
                                  index,
                                  seats,
                                  false
                                )
                              ).keys(),
                            ].map((element) => {
                              return (
                                <div className="seat-top-wrapper h-50 justify-content-center col from-left"></div>
                              );
                            })}
                          </>
                        ) : null}
                        {slot.position === "AISLE" && index === 0 ? (
                          <div className="AISLE text-center h-50 d-flex align-items-center justify-content-center col">
                            {/* {seats.number} */}
                          </div>
                        ) : null}
                        <Seat
                          key={"seat-" + index}
                          SeatMapId={this.props.SeatMapId}
                          checkAllPassengersSelectSeat={
                            this.checkAllPassengersSelectSeat
                          }
                          closeSeatPopUpModal={this.closeSeatPopUpModal}
                          keyType={"seat-" + index}
                          seat={slot.seat}
                          seatMapIdentifier={this.props.seatMapIdentifier}
                          row={slot.number}
                          column={this.props.Cabin.column[index]}
                          DepartureDate={this.props.DepartureDate}
                          position={slot.position}
                          facility={slot.facility}
                          characteristic={seats.characteristic}
                        />
                        {slot.position === "AISLE" &&
                        seats.slot[index - 1] !== undefined &&
                        seats.slot[index - 1].position !== "AISLE" ? (
                          <div className="AISLE text-center h-50 d-flex align-items-center justify-content-center col">
                            {seats.number}
                          </div>
                        ) : null}
                        {/* seat next bult calculator */}
                        {seats.slot.length < this.props.Cabin.column.length ? (
                          <>
                            {[
                              ...Array(
                                this.getBulkHeadCount(
                                  this.props.Cabin.column,
                                  index,
                                  seats,
                                  true
                                )
                              ).keys(),
                            ].map((element) => {
                              return (
                                <div className="seat-top-wrapper h-50 justify-content-center col from-right"></div>
                              );
                            })}
                          </>
                        ) : null}
                      </>
                    )}
                  </>
                );
              })}
              <div className={"right-or-left-side RIGHT_SIDE"}></div>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default Seats;
