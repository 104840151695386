import React, { Component } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
class ExchangeFlightSelectionHeader extends Component {
  state = {};
  render() {
    return (
      <section className="bg-white exchange-flight-selection-header mb-4">
        <div className="exchange-waring-icon-container">
          <span className="exchange-waring-icon">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
        </div>
        <div>
          <h5>
            Hello <span>{this.props.passengerInfo.firstName}</span>! Let's pick
            up your flights
          </h5>
          <p>
            Prices below show the difference amount and the change fees between
            the old and new full ticket price for all passengers. Please note
            that an exchange fee may apply. Your original flight payment was
            <span className="originalPayment">
              <span>{" " + this.props.originalPayment.amount}</span>
              <span>{" " + this.props.originalPayment.currency}</span>.
            </span>{" "}
            To continue your exchange, select your flights.
          </p>
        </div>
      </section>
    );
  }
}

export default ExchangeFlightSelectionHeader;
