import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import Translation from "i18next";
import { Col, Form, Row } from "react-bootstrap";
import { PaymentService } from "../../../../../service/booking/payment/PaymentService";
import AirportSelectStyle from "../../../../../master-data/booking-widget/airpot-select-style";
import CreditCardHeader from "./CreditCardHeader";
import { CreditCardInfo } from "../../../../../model/payment/CreditCardInfo";
import HttpService from "../../../../../service/shared/HttpService";
import SubmitPayment from "../../SubmitPayment";
import ProgressSimpler from "../../../../shared/progress-simple/ProgressSimpler";
import { CreditCardValidatorService } from "../../../../../service/booking/payment/CreditCardValidatorService";
import Loading from "../../../seat-selection/Loading";
import { Redirect } from "react-router-dom";
class CreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
      redirectToThirdParty: false,
      credirCardRedirect: null,
      responseData: null,
      creditCardInfoState: new CreditCardInfo(),
      creditCardValidator: new CreditCardValidatorService(),
    };
    this.selectStyle = AirportSelectStyle.desktopStyle;
    this.creditCardInfo = new CreditCardInfo();
    this.creditCardValidatorTemp = new CreditCardValidatorService();
    this.creditCardInfo.CreditCardType = PaymentService.getCreditCardTypes(
      this.props.creditCardOptions
    ).filter((option) => option.value === "VI")[0].value;
  }
  getAmount = () => {
    
    let price = {};

    for (let index = 0; index < this.props.creditCardOptions.length; index++) {
      if (this.props.creditCardOptions[index].paymentInfo.amount !== null) {
        price = this.props.creditCardOptions[index].paymentInfo.amount;
        break;
      }
    }
    return price;
  };
  handleSubmit = (event) => {
    event.preventDefault();

    let price = this.getAmount();
    this.creditCardInfo.Amount = price.amount;
    this.creditCardInfo.Currency = price.currency;

    this.setState({
      creditCardValidator: this.state.creditCardValidator.validateCardInfo(
        this.creditCardInfo
      ).creditCardValidation,
    });
    this.creditCardValidatorTemp =
      this.state.creditCardValidator.validateCardInfo(
        this.creditCardInfo
      ).creditCardValidation;
    let creditCardValidLists = this.state.creditCardValidator.validateCardInfo(
      this.creditCardInfo
    ).creditCardValidLists;
    let isCreditCardInfoValid = !creditCardValidLists.includes(false);
    if (isCreditCardInfoValid) {
      let creditCardRequestObj = PaymentService.mapToRequestObject(
        this.creditCardInfo
      );
      creditCardRequestObj.cookieSabreDataRequest =
        this.props.searchFlight.searchResult.data.cookieSabreDataRequest;
      this.setState({ isLoading: true });
      HttpService.postService(
        creditCardRequestObj,
        "/Purchase/Purchase",
        this.props.searchFlight.token
      ).then((response) => {
        if (response.data.pnr === null) {
          this.setState({
            isLoading: false,
            creditCardInfoState: this.creditCardInfo,
            redirectToThirdParty: true,
            credirCardRedirect:
              response.data.redirectInfo.payerAuthenticationRequestForm,
          });
        } else {
          this.setState({
            isLoading: false,
            creditCardInfoState: this.creditCardInfo,
            redirect: true,
            responseData: response.data,
          });
        }
      });
    } else {
      window.scrollTo({ top: 300, behavior: "smooth" });
    }
  };
  onInputKeyDown = (creditCardValidatorTemp) => {
    this.setState({ creditCardValidator: creditCardValidatorTemp });
  };
  render() {
    //if (this.state.isLoading) {
    //  return (
    //    <div>
    //      <ProgressSimpler />
    //    </div>
    //  );
    //  }
    if (this.state.redirectToThirdParty) {
      return (
        <Redirect
          to={{
            pathname: "/redirect-payment",
            state: { redirectToPath: this.state.credirCardRedirect },
            //type: "hold"
          }}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/payment-summary",
            state: this.state.responseData,
            //type: "hold"
          }}
        />
      );
    }
    return (
      <div className="container bg-white py-3 payment-form">
        {this.state.isLoading ? <Loading /> : null}
        <Form onSubmit={this.handleSubmit}>
          <CreditCardHeader creditCardOptions={this.props.creditCardOptions} />
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>{Translation.t("credit_card_type")} *</Form.Label>
              <Select
                defaultValue={PaymentService.getCreditCardTypes(
                  this.props.creditCardOptions
                ).filter((option) => option.value === "VI")}
                options={PaymentService.getCreditCardTypes(
                  this.props.creditCardOptions
                )}
                styles={this.selectStyle}
                isSearchable={false}
                onChange={(event) =>
                  (this.creditCardInfo.CreditCardType = event.value)
                }
                placeholder={
                  this.state.creditCardValidator.isCreditCardTypeValid
                    ? Translation.t("credit_card_type")
                    : Translation.t("credit_card_type_required")
                }
                className={
                  this.state.creditCardValidator.isCreditCardTypeValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isCreditCardTypeValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>
                <br />
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.CreditCardNumber}
                onChange={(event) =>
                  (this.creditCardInfo.CreditCardNumber = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isCreditCardNumberValid
                    ? Translation.t("credit_card_number")
                    : Translation.t("credit_card_number_required")
                }
                className={
                  this.state.creditCardValidator.isCreditCardNumberValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isCreditCardNumberValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.FullName}
                onChange={(event) =>
                  (this.creditCardInfo.FullName = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isFullNameValid
                    ? Translation.t("full_name")
                    : Translation.t("full_name_required")
                }
                className={
                  this.state.creditCardValidator.isFullNameValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isFullNameValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Row>
                <Col xs={12} sm={12} md={6} lg={4} className="my-2">
                  <Form.Control
                    type="text"
                    value={this.state.creditCardInfoState.ExpireMonth}
                    maxlength={2}
                    onChange={(event) =>
                      (this.creditCardInfo.ExpireMonth = event.target.value)
                    }
                    placeholder={
                      this.state.creditCardValidator.isExpireMonthValid
                        ? Translation.t("expire_month")
                        : Translation.t("expire_month_required")
                    }
                    className={
                      this.state.creditCardValidator.isExpireMonthValid
                        ? "input-field"
                        : "invalid-input-field"
                    }
                    onKeyDown={() => {
                      this.creditCardValidatorTemp.isExpireMonthValid = true;
                      this.onInputKeyDown(this.creditCardValidatorTemp);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} className="my-2">
                  <Form.Control
                    type="text"
                    value={this.state.creditCardInfoState.ExpireYear}
                    onChange={(event) =>
                      (this.creditCardInfo.ExpireYear = event.target.value)
                    }
                    maxlength={4}
                    placeholder={
                      this.state.creditCardValidator.isExpireYearValid
                        ? Translation.t("expire_year")
                        : Translation.t("expire_year_required")
                    }
                    className={
                      this.state.creditCardValidator.isExpireYearValid
                        ? "input-field"
                        : "invalid-input-field"
                    }
                    onKeyDown={() => {
                      this.creditCardValidatorTemp.isExpireYearValid = true;
                      this.onInputKeyDown(this.creditCardValidatorTemp);
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={4} className="my-2">
                  <Form.Control
                    type="text"
                    value={this.state.creditCardInfoState._CVC}
                    onChange={(event) =>
                      (this.creditCardInfo._CVC = event.target.value)
                    }
                    placeholder={
                      this.state.creditCardValidator.isCVCValid
                        ? Translation.t("cvc")
                        : Translation.t("cvc_required")
                    }
                    className={
                      this.state.creditCardValidator.isCVCValid
                        ? "input-field"
                        : "invalid-input-field"
                    }
                    onKeyDown={() => {
                      this.creditCardValidatorTemp.isCVCValid = true;
                      this.onInputKeyDown(this.creditCardValidatorTemp);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>
                {" "}
                <br />{" "}
              </Form.Label>
              <Form.Control
                type="email"
                value={this.state.creditCardInfoState.Email}
                onChange={(event) =>
                  (this.creditCardInfo.Email = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isEmailValid
                    ? Translation.t("email_address")
                    : Translation.t("email_address_required")
                }
                className={
                  this.state.creditCardValidator.isEmailValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isEmailValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>{Translation.t("phone_number")} </Form.Label>
              <PhoneInput
                country={"et"}
                value=""
                onChange={(event, country) => {
                  this.creditCardInfo.PhoneNumber = event;
                  this.creditCardInfo.PhoneCountryCode = country.dialCode;
                }}
                placeholder={
                  this.state.creditCardValidator.isPhoneNumberValid
                    ? Translation.t("phone_number")
                    : Translation.t("phone_number_required")
                }
                className={
                  this.state.creditCardValidator.isPhoneNumberValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isPhoneNumberValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.BillingAddess}
                onChange={(event) =>
                  (this.creditCardInfo.BillingAddess = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isBillingAddressValid
                    ? Translation.t("billing_address")
                    : Translation.t("billing_address_required")
                }
                className={
                  this.state.creditCardValidator.isBillingAddressValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isBillingAddressValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.Address}
                onChange={(event) =>
                  (this.creditCardInfo.Address = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isAddressValid
                    ? Translation.t("address")
                    : Translation.t("address_required")
                }
                className={
                  this.state.creditCardValidator.isAddressValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isAddressValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>{Translation.t("country")} *</Form.Label>
              <Select
                options={PaymentService.getCountryList(this.props.countryList)}
                styles={this.selectStyle}
                placeholder={
                  this.state.creditCardValidator.isCountryValid
                    ? Translation.t("country")
                    : Translation.t("country_required")
                }
                className={
                  this.state.creditCardValidator.isCountryValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={(event) => {
                  this.creditCardValidatorTemp.isCountryValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
                onChange={(event) => {
                  this.creditCardInfo.Country = event.value;
                  this.creditCardValidatorTemp.isCountryValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Label>
                {" "}
                <br />{" "}
              </Form.Label>
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.City}
                onChange={(event) =>
                  (this.creditCardInfo.City = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isCityValid
                    ? Translation.t("city")
                    : Translation.t("city_required")
                }
                className={
                  this.state.creditCardValidator.isCityValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isCityValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className="my-2">
              <Form.Control
                type="text"
                value={this.state.creditCardInfoState.PostalCode}
                onChange={(event) =>
                  (this.creditCardInfo.PostalCode = event.target.value)
                }
                placeholder={
                  this.state.creditCardValidator.isPostalCodeValid
                    ? Translation.t("zip_code")
                    : Translation.t("zip_code_required")
                }
                className={
                  this.state.creditCardValidator.isPostalCodeValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.creditCardValidatorTemp.isPostalCodeValid = true;
                  this.onInputKeyDown(this.creditCardValidatorTemp);
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} className="my-2">
              <div className="py-4">
                <SubmitPayment />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    countryList: state.payment.countryList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
