import React, { Component } from "react";
class FlightSearchHeadText extends Component {
  render() {
    return (
      <div className="header-title  text-left">
        <h2 className="search-form-header">Search for Flights</h2>
      </div>
    );
  }
}
export default FlightSearchHeadText;
