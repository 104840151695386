import { React, Component } from "react";
// import { Breadcrumb } from "react-bootstrap";
import { Row, Modal, Tabs, Tab } from "react-bootstrap";
import Seats from "./seats/Seats";
import ShoppingCart from "../shared/shoppingCart/ShoppingCart";
import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";
import { connect } from "react-redux";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckInService from "../../../service/check-in/CheckInService";
import { checkInAction } from "../../../redux/actions/check-in/checkInAction";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import { SeatSelectionService } from "../../../service/booking/seat-selection/SeatSelectionService";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import { Redirect } from "react-router";
import CheckInBreadCrumb from "../shared/bread-crumb/CheckInBreadCrumb";
import Loading from "../../booking/seat-selection/Loading";

class SeatSelection extends Component {
  passengerSeatMaps = "";
  state = {
    isLoading: true,
    redirect: false,
    IsContinueModalOpen: false,
    possibleSeatSelectionSegment: 0,
    countAdultOrChildPassenger: 0,
    returnToBack: false,
    redirectToConfirmationTemp: false,
    legendToggler: true,
    countINFANT: 0,
  };

  initSeatSeleciton = () => {
    this.props.clearSeatSelectionEntry();

    let seatSelectionServiceForChecking = new CheckInService();

    // let seatSelectionService = new SeatSelectionService();
    var data = { passengerIds: [], segmentIds: [] };

    Object.keys(this.props.activePassengers).map((key) => {
      data.passengerIds.push({ id: this.props.activePassengers[key].id });
      return null;
    });

    this.props.itineraryPart[0].segment.map((segment) => {
      data.segmentIds.push(segment.id);
      return null;
    });

    seatSelectionServiceForChecking
      .getSeatMap(data, this.props.token, this.props.checkInSabreDataRequest)
      .then((response) => {
        if (response.data.statusAPI === "SUCCESS") {
          this.props.updateReservation(response.data.reservation);
          this.props.updateSeatMap(response.data.seatMap);
          this.props.updatePassengers(response.data.passengers);

          // this.props.storeSeatMaps(response.data.segmentSeatMaps);
          const counter = response.data.seatMap.filter(
            (segmentSeatMap) =>
              segmentSeatMap.cabin !== null &&
              segmentSeatMap.cabin[0].row.length !== 0
          ).length;
          this.props.changeActivePassangerCode(
            response.data.passengers.seatMapPassenger[0].passenger.type
              .value[0],
            response.data.passengers.seatMapPassenger[0].passenger.id
          );
          const countAdultOrChildPassenger =
            response.data.passengers.seatMapPassenger.filter(
              (passengerSeatMap) =>
                passengerSeatMap.passenger.type.value === "ADULT" ||
                passengerSeatMap.passenger.type.value === "CHILD"
            ).length;

          this.setState({
            isLoading: false,
            possibleSeatSelectionSegment: counter,
            countAdultOrChildPassenger: countAdultOrChildPassenger,
            activeTab: response.data.seatMap[0].id,
            countINFANT:
              response.data.passengers.seatMapPassenger.length -
              countAdultOrChildPassenger,
          });
          var selectedSeats = {};
          var activePassangerIdentifierCode = "";

          this.props.passengers.map((passenger, index) => {
            passenger.passengerSegments.passengerSegment.map(
              (passengerSegment) => {
                passengerSegment.passengerFlight.map((passengerFlight) => {
                  if (passenger.id in this.props.activePassengers) {
                    if (passengerFlight.seat !== null) {
                      selectedSeats[passengerFlight.id] = {
                        seatCode: passengerFlight.seat.value,
                        passengerId: passenger.id,
                        segmentId: passengerSegment.segmentRef,
                      };
                      if (!(passengerSegment.segmentRef in selectedSeats))
                        selectedSeats[passengerSegment.segmentRef] = {};

                      if (activePassangerIdentifierCode === "")
                        activePassangerIdentifierCode = passenger.id;

                      selectedSeats[passengerSegment.segmentRef][
                        passengerFlight.seat.value
                      ] = {
                        seatCode: passengerFlight.seat.value,
                        passengerIndex: index,
                        activePassangerIdentifierCode:
                          passenger.type.value[0] + passenger.id[2],
                        passengerRequestIdentifierCode: passengerFlight.id,
                      };
                    }
                  }
                  return null;
                });
                return null;
              }
            );
            return null;
          });

          this.props.initializeSelectedSeats({
            selectedSeats: selectedSeats,
            activePassangerIdentifierCode: activePassangerIdentifierCode,
          });
        }
      });
  };

  componentDidMount() {
    this.initSeatSeleciton();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  changeActivePassangerCode() {}

  handleContinueClick = () => {
    const selectedSeatCount = Object.keys(this.props.selectedSeats).length;

    if (this.state.possibleSeatSelectionSegment === 0) {
      this.setState({ redirect: true });
    } else {
      if (
        selectedSeatCount ===
        this.state.possibleSeatSelectionSegment +
          this.state.possibleSeatSelectionSegment *
            this.state.countAdultOrChildPassenger +
          this.state.countINFANT
      ) {
        this.setState({ redirect: true });
      } else {
        this.setState({ IsContinueModalOpen: true });
      }
    }
    console.log(
      "this.state.possibleSeatSelectionSegment : " +
        this.state.possibleSeatSelectionSegment
    );
    console.log(
      "this.state.countAdultOrChildPassenger : " +
        this.state.countAdultOrChildPassenger
    );
    console.log("selectedSeatCount : " + selectedSeatCount);
  };

  removeSeat = (passengerIndex, seatMapIdentifier) => {
    let seatSelectionServiceForChecking = new CheckInService();
    this.props.seatSelectingUpdate(true);

    var checkinPayload = {
      seatNumber: null,
      passengerFlightId:
        passengerIndex + "." + seatMapIdentifier + ".f" + seatMapIdentifier[1],
    };

    seatSelectionServiceForChecking
      .selectSeat(
        checkinPayload,
        this.props.token,
        this.props.checkInSabreDataRequest
      )
      .then((response) => {
        this.props.seatSelectingUpdate(false);
        if (
          response.data.errorCode === "" ||
          response.data.errorCode === null
        ) {
          this.props.updateReservation(response.data.reservation);
          this.updateActivePassengersDataAfterSubmit(
            response.data.reservation.passengers.passenger
          );
        }
        this.props.removeSelectedSeat(passengerIndex, seatMapIdentifier);
      })
      .catch((error) => {
        seatSelectionServiceForChecking
          .selectSeat(
            checkinPayload,
            this.props.token,
            this.props.checkInSabreDataRequest
          )
          .then((response) => {
            this.props.seatSelectingUpdate(false);
            if (
              response.data.errorCode === "" ||
              response.data.errorCode === null
            ) {
              this.props.seatSelectingUpdate(false);
              // this.props.removeSelectedSeat(passengerIndex, seatMapIdentifier);
              this.props.updateReservation(response.data.reservation);
              this.updateActivePassengersDataAfterSubmit(
                response.data.reservation.passengers.passenger
              );
            }
            this.props.removeSelectedSeat(passengerIndex, seatMapIdentifier);
          })
          .catch((error) => {
            this.props.seatSelectingUpdate(false);
            console.log(error);
            if (error.response !== undefined) {
              this.errorHeader = "Session Expired";
              this.errorBody = "Session is expired. Start booking from scratch";
            } else {
              this.errorHeader = "Offline";
              this.errorBody =
                "can not comunicate to the server. try connecting to the internet and try again";
            }
            this.setState({ isExceptionModalOpened: true });
          });
      });
  };

  getModal = () => {
    return (
      <Modal
        show={this.state.IsContinueModalOpen}
        onHide={() => {
          this.setState({
            IsContinueModalOpen: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h2>
            <span data-translation="seatSelection.proceedWithoutSeatSelection.title">
              {Translation.t("Proceed")}?
            </span>
          </h2>
          <p>
            <span data-translation="seatSelection.proceedWithoutSeatSelection.description">
              {Translation.t("message_when_seat_donnot_selected")}
            </span>
          </p>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button
              className="col-12 col-lg-2 btn btn-success mr-lg-2 mb-3 mb-lg-0"
              onClick={() => this.setState({ redirect: true })}
            >
              {Translation.t("continue")}
            </button>
            <button
              className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
              onClick={() => this.setState({ IsContinueModalOpen: false })}
            >
              {Translation.t("cancel")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  nextFlight = (tabKey) => {
    this.setState({ activeTab: tabKey });

    // var firstPassenger = this.props.passangers[0];
    // this.props.changeActivePassangerCode(
    //   firstPassenger.passenger.passengerInfo.type[0] +
    //     firstPassenger.passenger.passengerIndex,
    //   firstPassenger.passenger.passengerIndex
    // );
  };

  continueToBaggage = () => {
    this.setState({ redirect: true });
  };
  redirectToPassanger() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({ returnToBack: true });
  }

  updateActivePassengersDataAfterSubmit = (passengers) => {
    var activepassengers = {};
    passengers
      .filter((passenger) => passenger.id in this.props.activePassengers)
      .map((passenger) => {
        activepassengers[passenger.id] = passenger;
        return null;
      });
    this.props.initActivePassengers(activepassengers);
  };

  render() {
    document.title = "Seats | Check-in";
    if (this.state.redirectToConfirmationTemp) {
      return (
        <Redirect
          to={{
            pathname: "/check-in/confirmation",
          }}
        />
      );
    }
    if (this.state.redirect) {
      this.props.history.push("/check-in/ancillary");
    }
    if (this.state.returnToBack) {
      return (
        <Redirect
          to={{
            pathname: "/check-in/passengers",
          }}
        />
      );
    }

    var counter = 1;
    return (
      <>
        {this.props.seatSelecting ? <Loading /> : null}
        {this.state.IsContinueModalOpen ? this.getModal() : null}
        <CheckInBreadCrumb />
        <Row className="mx-0 justify-content-center all-passangers-container w-100 wrapper-with-shopping-cart">
          {this.state.isLoading ? (
            <div className="left-content-container d-flex justify-content-center">
              {" "}
              <ProgressSimpler />
            </div>
          ) : (
            <div className="left-content-container mx-auto mx-xl-0 px-xl-0">
              <h2>{Translation.t("select_seats")}</h2>
              <p>
                {Translation.t("please_select_the_seats_from_the_seat_map")}
              </p>
              <button
                className="float-right bk-submit-btn btn-ethiopian mx-2 btn seat_next_btn"
                onClick={() => this.handleContinueClick()}
              >
                {Translation.t("next_page")}
              </button>
              <Tabs
                defaultActiveKey={1}
                id="seat-selection-tab"
                activeKey={this.state.activeTab}
                onSelect={(eventKey) => this.setState({ activeTab: eventKey })}
              >
                {this.props.seatMap.map((seatMap) => {
                  return (
                    <Tab
                      key={seatMap.id}
                      eventKey={seatMap.id}
                      title={
                        <>
                          <span className="flight-index">{counter++}</span>{" "}
                          <span className="flight-overview-on-seat">
                            {AirportFullName.getCityName(
                              seatMap.segment.departureAirport
                            ) +
                              " " +
                              Translation.t("to") +
                              " " +
                              AirportFullName.getCityName(
                                seatMap.segment.arrivalAirport
                              )}
                          </span>
                        </>
                      }
                    >
                      <div className="seat-selection-container">
                        <div className="seat-selection">
                          <div className="row mx-0">
                            <div className="seatmap-header col-12">
                              <h5 className="seatmap-header-title">
                                {AirportFullName.getFullName(
                                  seatMap.segment.departureAirport
                                ) +
                                  " " +
                                  Translation.t("to") +
                                  " " +
                                  AirportFullName.getFullName(
                                    seatMap.segment.arrivalAirport
                                  )}
                                {/* Dubai (DXB), UAE to Addis Ababa (ADD), Ethiopia. */}
                              </h5>
                            </div>
                            <div className="seatmap-header col-12">
                              <h6 className="flight-inline-massage">
                                {Translation.t("passanger_selection_message")}
                              </h6>
                            </div>
                          </div>
                          {seatMap.cabin[0].row.length === 0 ? (
                            <>
                              <h5 className="seatmap-header col-12">
                                {Translation.t("seat_map_is_not_available")}
                              </h5>
                            </>
                          ) : (
                            <div className="row mx-0 seat-selection-container">
                              <div className="col-12 col-lg-4 left-container">
                                <div className="passangers-title">
                                  <h5>{Translation.t("passengers-label")}</h5>
                                </div>
                                <div className="passanger-lists">
                                  {this.props.seatMapPassengers.seatMapPassenger.map(
                                    (passengerSeatMap) => {
                                      return (
                                        <div
                                          onClick={() =>
                                            passengerSeatMap.passenger.type
                                              .value === "INFANT"
                                              ? null
                                              : this.props.changeActivePassangerCode(
                                                  passengerSeatMap.passenger
                                                    .type.value[0] +
                                                    passengerSeatMap.passenger
                                                      .id,
                                                  passengerSeatMap.passenger.id
                                                )
                                          }
                                          className={
                                            passengerSeatMap.passenger.id ===
                                            this.props.passengerIndex
                                              ? "single-passange row align-items-center active"
                                              : "single-passange row align-items-center"
                                          }
                                          role="button"
                                          tabIndex="1"
                                          disabled={
                                            passengerSeatMap.passenger.type
                                              .value === "INFANT"
                                          }
                                        >
                                          <span
                                            className={
                                              passengerSeatMap.passenger.type
                                                .value === "INFANT"
                                                ? "passanger-identifier-infant d-inline-flex"
                                                : "passanger-identifier d-inline-flex"
                                            }
                                          >
                                            {
                                              this.props
                                                .activePassangerIdentifierCodeMapper[
                                                passengerSeatMap.passenger.type
                                                  .value[0] +
                                                  passengerSeatMap.passenger
                                                    .id[2]
                                              ]
                                            }
                                          </span>
                                          <span className="passanger-name d-inline-flex">
                                            {passengerSeatMap.passenger
                                              .personName.first +
                                              " " +
                                              passengerSeatMap.passenger
                                                .personName.last}
                                          </span>
                                          {passengerSeatMap.passenger.id +
                                            "." +
                                            seatMap.segment.id +
                                            ".f" +
                                            seatMap.segment.id[1] in
                                          this.props.selectedSeats ? (
                                            <>
                                              <span className="selected-seat-name d-inline-flex">
                                                {
                                                  this.props.selectedSeats[
                                                    passengerSeatMap.passenger
                                                      .id +
                                                      "." +
                                                      seatMap.segment.id +
                                                      ".f" +
                                                      seatMap.segment.id[1]
                                                  ].seatCode
                                                }
                                              </span>
                                              {passengerSeatMap.passenger.type
                                                .value === "INFANT" ? null : (
                                                <button
                                                  className="clear-seat-selection d-inline-flex"
                                                  onClick={() =>
                                                    this.removeSeat(
                                                      passengerSeatMap.passenger
                                                        .id,
                                                      seatMap.segment.id
                                                    )
                                                  }
                                                >
                                                  x
                                                </button>
                                              )}
                                            </>
                                          ) : (
                                            <span>
                                              {passengerSeatMap.passenger.type
                                                .value === "INFANT"
                                                ? "Infant"
                                                : Translation.t("select_seat")}
                                            </span>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div className="legend-container px-0">
                                  {this.props.isMedium ? (
                                    <span
                                      tabIndex="0"
                                      onClick={() =>
                                        this.props.isMedium
                                          ? this.setState({
                                              legendToggler:
                                                !this.state.legendToggler,
                                            })
                                          : null
                                      }
                                      className="legend-title legend-medium d-flex"
                                    >
                                      {this.state.legendToggler
                                        ? Translation.t("hide_legend")
                                        : Translation.t("show_legend")}
                                    </span>
                                  ) : (
                                    <h6 className="legend-title px-3">
                                      {Translation.t("legend")}
                                    </h6>
                                  )}

                                  {(this.props.isMedium &&
                                    this.state.legendToggler) ||
                                  (!this.props.isMedium &&
                                    this.state.legendToggler) ||
                                  (!this.props.isMedium &&
                                    !this.state.legendToggler) ? (
                                    <ul className="seats-list row mx-0 px-4">
                                      <li
                                        id="seat-type-seat0"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            ></span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("available_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat4"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              disabled="true"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            >
                                              ×
                                            </span>
                                          </div>
                                        </div>
                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("unavailable_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat1"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex premium-seat "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              tabindex="1"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center"
                                            >
                                              {" "}
                                            </span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("premium_seat")}
                                        </span>
                                        <span className="seat-legend-price d-inline-flex">
                                          {/* <span className="amount">3372</span>{" "}
                                        <span className="currency">ETB</span> */}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat2"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <div
                                          role="button"
                                          className="seat-insider-wrapper justify-content-center d-flex "
                                        >
                                          <span
                                            role="button"
                                            className="seat-frame-legend d-flex"
                                          ></span>
                                          <div
                                            role="button"
                                            className="seat-container-legend justify-content-center d-flex"
                                          >
                                            <span
                                              tabindex="1"
                                              role="button"
                                              className="seat-legend d-flex align-items-center justify-content-center extra-leg-seat-legend"
                                            ></span>
                                          </div>
                                        </div>

                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("extra_leg_seat")}
                                        </span>
                                      </li>
                                      <li
                                        id="seat-type-seat3"
                                        className="col-12 mx-0 px-0 legend-seat-type row align-items-center my-3"
                                      >
                                        <span className="seat-legend-description d-inline-flex">
                                          {Translation.t("Exit")}
                                        </span>
                                      </li>
                                    </ul>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-12 col-lg-8 right-container pt-3">
                                <div className="row mx-0">
                                  <div className="col-12">
                                    <span className="seat-map-deck d-flex justify-content-center">
                                      {seatMap.cabin[0].deck}{" "}
                                      {Translation.t("Deck")}
                                    </span>
                                  </div>
                                  <div className="col-12">
                                    <span className="aircraft-name d-flex justify-content-center">
                                      {seatMap.aircraft}
                                    </span>
                                  </div>
                                  <div className="col-12 seat-map-instructions">
                                    <p className="text-center">
                                      To request your seat, please click on the
                                      seat you would like on the map. Your seat
                                      selection cannot be confirmed until you
                                      finish your purchase. And your seat
                                      reservation will be cancelled after
                                      24hours, if payment is not effected within
                                      this time frame. <br />
                                      Please note that amount paid for the
                                      selected seat is non-refundable,
                                      non-transferable, non-exchangeable
                                    </p>
                                  </div>
                                </div>
                                <Seats
                                  seatMapIdentifier={seatMap.segment.id}
                                  Cabin={seatMap.cabin[0]}
                                  SeatMapId={seatMap.id}
                                  DepartureDate={seatMap.segment.departureDate}
                                  seatMapIndex={counter}
                                  TotalSeatMaps={this.props.seatMap.length}
                                  nextFlight={this.nextFlight}
                                  continueToBaggage={this.continueToBaggage}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Tab>
                  );
                })}
              </Tabs>
              <div className="col-12 justify-content-end d-flex pr-0 pt-2">
                <button
                  className="bk-submit-btn btn-ethiopian mx-2 btn"
                  onClick={() =>
                    this.props.history.push("/check-in/passengers")
                  }
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <button
                  className="bk-submit-btn btn-ethiopian mx-2 btn"
                  onClick={() => this.handleContinueClick()}
                >
                  {Translation.t("continue")}
                </button>
              </div>
            </div>
          )}
          <div className="right-shopping-cart-container">
            {!this.props.isMedium && <ShoppingCart />}
          </div>
        </Row>
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    checkInSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    token: state.searchFlight.token,
    selectedSeats: state.checkIn.selectedSeats,
    passengerIndex: state.seatSelection.passengerIndex,
    seatSelecting: state.checkIn.seatSelecting,
    isMedium: state.global.isMedium,
    passengers: state.checkIn.reservation.passengers.passenger,
    itineraryPart: state.checkIn.reservation.itinerary.itineraryPart,
    seatMap: state.checkIn.seatMap,
    activePassengers: state.checkIn.activePassengers,
    seatMapPassengers: state.checkIn.passengers,
    activePassangerIdentifierCodeMapper:
      state.checkIn.activePassangerIdentifierCodeMapper,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSeat: (result) => {
      dispatch({
        type: seatSelectionAction.CHANGESEAT,
        searchResult: result,
      });
    },
    storeSeatMaps: (payload) => {
      dispatch({
        type: seatSelectionAction.STORESEATRESULT,
        payload: payload,
      });
    },
    changeActivePassangerCode: (value, passengerIndex) => {
      dispatch({
        type: seatSelectionAction.CHANGEACTIVEPASSANGERCODE,
        activePassangerIdentifierCode: value,
        passengerIndex: passengerIndex,
      });
    },
    removeSelectedSeat: (passengerId, seatMapIdentifier) => {
      dispatch({
        type: checkInAction.CHECKINREMOVESEATSELECTION,
        passengerId: passengerId,
        seatMapIdentifier: seatMapIdentifier,
      });
    },
    seatSelectingUpdate: (value) =>
      dispatch({
        type: checkInAction.CHECKINSEATSELECTING,
        value: value,
      }),
    updateReservation: (value) =>
      dispatch({
        type: checkInAction.UPDATERESERVATION,
        reservation: value,
      }),
    updateSeatMap: (value) =>
      dispatch({
        type: checkInAction.UPDATECHECKINSEATMAP,
        seatMap: value,
      }),
    updatePassengers: (value) =>
      dispatch({
        type: checkInAction.UPDATECHECKINPASSENGERS,
        passengers: value,
      }),
    initializeSelectedSeats: (value) =>
      dispatch({
        type: checkInAction.CHECKININITSELECTEDSEATS,
        selectedSeats: value,
      }),
    clearSeatSelectionEntry: () => {
      dispatch({
        type: checkInAction.CHECKINCLEARSEATSELECTIONENTRY,
      });
    },
    initActivePassengers: (value) =>
      dispatch({
        type: checkInAction.INITACTIVEPASSENGER,
        activePassengers: value,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SeatSelection);
