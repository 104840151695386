import SearchRequest, {
  SearchRequestItinerary,
} from "../../../model/API/SearchRequest";

import moment from "moment";

export default class SearchFlightService {
  searchFlight = (action) => {
    
    var searchRequest = new SearchRequest();
    searchRequest.passengers.ADT = action.newValue.passengerInfo.adult;
    searchRequest.passengers.CHD = action.newValue.passengerInfo.child;
    searchRequest.passengers.INF = action.newValue.passengerInfo.infant;
    searchRequest.cabinClass = action.newValue.cabinClass.value;
    searchRequest.promoCodes = action.newValue.promoCode;
    searchRequest.itineraryParts = [];
    var searchRequestItinerary = new SearchRequestItinerary();
    if (action.newValue.journyType === "roundTrip") {
      searchRequestItinerary = new SearchRequestItinerary();
      searchRequestItinerary.from.code =
        action.newValue.itineraryPart[0].params.from.value;
      searchRequestItinerary.to.code =
        action.newValue.itineraryPart[0].params.to.value;
      searchRequestItinerary.when.date =
        action.newValue.itineraryPart[0].params.when.departureDate;
      searchRequest.itineraryParts.push(searchRequestItinerary);
      searchRequestItinerary = new SearchRequestItinerary();
      searchRequestItinerary.from.code =
        action.newValue.itineraryPart[0].params.to.value;
      searchRequestItinerary.to.code =
        action.newValue.itineraryPart[0].params.from.value;
      searchRequestItinerary.when.date =
        action.newValue.itineraryPart[0].params.when.arrivalDate;
      searchRequest.itineraryParts.push(searchRequestItinerary);
    } else if (action.newValue.journyType === "oneWay") {
      searchRequestItinerary.from.code =
        action.newValue.itineraryPart[0].params.from.value;
      searchRequestItinerary.to.code =
        action.newValue.itineraryPart[0].params.to.value;
      searchRequestItinerary.when.date =
        action.newValue.itineraryPart[0].params.when.departureDate;
      searchRequest.itineraryParts.push(searchRequestItinerary);
    } else if (action.newValue.journyType === "stopover") {
      action.newValue.itineraryPart.forEach((element) => {
        searchRequestItinerary = new SearchRequestItinerary();
        if (
          element.params.from.value !== "ADD" &&
          element.params.to.value !== "ADD" && element.params.stopDays !== "init" && element.params.stopDays !== 0
        ) {
          searchRequestItinerary.from.code = element.params.from.value;
          searchRequestItinerary.to.code = "ADD";
          searchRequestItinerary.when.date = element.params.when.departureDate;
          searchRequest.itineraryParts.push(searchRequestItinerary);

          searchRequestItinerary = new SearchRequestItinerary();
          searchRequestItinerary.from.code = "ADD";
          searchRequestItinerary.to.code = element.params.to.value;
          searchRequestItinerary.when.date = moment(
            element.params.when.departureDate
          ).add(element.params.stopDays, "days").format("YYYY-MM-DD");
          searchRequest.itineraryParts.push(searchRequestItinerary);
        } else {
          searchRequestItinerary.from.code = element.params.from.value;
          searchRequestItinerary.to.code = element.params.to.value;
          searchRequestItinerary.when.date = element.params.when.departureDate;
          searchRequest.itineraryParts.push(searchRequestItinerary);
        }
      });
    } else {
      action.newValue.itineraryPart.forEach((element) => {
        searchRequestItinerary = new SearchRequestItinerary();
        searchRequestItinerary.from.code = element.params.from.value;
        searchRequestItinerary.to.code = element.params.to.value;
        searchRequestItinerary.when.date = element.params.when.departureDate;
        searchRequest.itineraryParts.push(searchRequestItinerary);
      });
    }

    searchRequest.journyType = action.newValue.journyType;

    return searchRequest;
  };
}
