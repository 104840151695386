import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

import Translation from "i18next";
// import { useHistory } from "react-router-dom";

const IdleController = (props) => {
  // let history = useHistory();

  // const [showAdditionalOption, setShowAdditonalOption] = useState(false);

  // const [isLodaing, setIsLoading] = useState(false);
  // const [isErrorOcurred, setIsErrorOcurred] = useState(false);
  let timeOutController;

  const resetTimer = () => {
    console.log("cleared");
    clearTimeout(timeOutController);
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/flight-status-result" &&
      window.location.pathname !== "/flight-schedule-result" &&
      window.location.pathname !== "/check-in" &&
      !disableContinue
    ) {
      timeOutController = setTimeout(handleTimeOut, 7*60*1000); //60*7000)
    }
  };

  const [idleTimeOut, setIdleTimeOut] = useState(false);
  const [disableContinue, setDisableContinue] = useState(() => {
    window.onload = resetTimer;

    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    document.ontouchmove = resetTimer;
    return false;
  });;
  // const [isErrorOcurredMsg, setIsErrorOcurredMsg] = useState(false);
  // const [pnrValidationMsg, setPnrValidationMsg] = useState("");
  // const [pnrValidationMsg, setPnrValidationMsg] = useState("");
  // var checkInModel = new CheckInModel();
  // let TimeOutWarning;
  let TimeOutFinalWarning;
  const handleTimeOut = () => {
    // if(!disableContinue){

    // }
    // setDisableContinue(false);
    setIdleTimeOut(true);
    console.log("timmer endded");
    
    TimeOutFinalWarning = setTimeout(handleFinalWarning, 2*60*1000);
  };

  const handleFinalWarning = () =>{
    setDisableContinue(true);
  }

  // const [timmer, setTimmer] = useState(() => {
  //   window.onload = resetTimer;

  //   // DOM Events
  //   document.onmousemove = resetTimer;
  //   document.onkeypress = resetTimer;
  //   document.ontouchmove = resetTimer;
  //   return 0;
  // });

  const startOver = () => {

    var url = window.location.pathname;
    if (url.includes("check-in")) {
      window.location.replace(window.location.origin + "/check-in");
    }
    else{
      window.location.replace(window.location.origin);
    }
  }

  return (
    <>
      {idleTimeOut ? (
        <Modal
          show={idleTimeOut}
          onHide={() => {
            setIdleTimeOut(false);
            // resetCheckinModel();
          }}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Body>
            <h4>{!disableContinue ? Translation.t("your_session_is_about_to_expire") : Translation.t("your_session_has_expired")}</h4>
            <p>{!disableContinue ? Translation.t("to_prevent_it_from_expiring_click_the_button_below") : Translation.t("to_start_over_click_the_button_below")}</p>
            <div className="row w-100 justify-content-center justify-content-md-end py-3 py-md-1 mx-0">
              {disableContinue ? null : <button
                className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal mb-3"
                onClick={() => {
                  setIdleTimeOut(false);
                  //   resetCheckinModel();
                }}
              >
                {Translation.t("continue")}
              </button>}
              <button
                className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal mb-3"
                onClick={() => {
                  // setIdleTimeOut(false); 
                  //   resetCheckinModel();
                  startOver();
                }}
              >
                {Translation.t("start_over")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pnr: state.checkIn.checkInModel.pnr,
    firstName: state.checkIn.checkInModel.firstName,
    lastName: state.checkIn.checkInModel.lastName,
    searchType: state.checkIn.checkInModel.searchType,
    flightNumber: state.checkIn.checkInModel.flightNumber,
    departureAirport: state.checkIn.checkInModel.DepartureAirport,
    arrivingCity: state.checkIn.checkInModel.arrivingCity,
    ticketNumber: state.checkIn.checkInModel.ticketNumber,
    token: state.searchFlight.token,
    isGlobalSessionExpired: state.global.isGlobalSessionExpired,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateHidenOption: (value) =>
//       dispatch({
//         type: checkInAction.UPDATEHIDENOPTION,
//         value: value,
//       }),
//     updateCheckInSabreDataRequest: (value) =>
//       dispatch({
//         type: checkInAction.UPDATECHECKInSABREDATAREQUEST,
//         checkInSabreDataRequest: value,
//       }),
//     updateReservation: (value) =>
//       dispatch({
//         type: checkInAction.UPDATERESERVATION,
//         reservation: value,
//       }),
//     initActivePassengers: (value) =>
//       dispatch({
//         type: checkInAction.INITACTIVEPASSENGER,
//         activePassengers: value,
//       }),
//   };
// };

export default connect(mapStateToProps)(IdleController);
