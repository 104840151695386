import React from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
import PhoneInput from "react-phone-input-2";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import { connect } from "react-redux";
const EditContactInfo = (props) => {
  const validateEmail = (inputValue) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mailformat.test(inputValue.value)) {
      props.updateContactInfo(inputValue.value, "email");
    } else {
      inputValue.classList.add("invalid_input");
      inputValue.placeholder = Translation.t("email_address_required");
    }
  };
  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <div className="et-checkbox">
            <input type="checkbox" id="edit-passenger-remember-me" />
            <label htmlFor="edit-passenger-remember-me" className="d-inline">
              {Translation.t("remember_me")}
            </label>
          </div>
        </Col>
      </Row>
      <h3 className="m-3">{Translation.t("contact_info")}</h3>
      <Row>
        <Col xs={12} md={6}>
          <PhoneInput
            inputProps={{
              className: "form-control  widget-input my-2",
              id: "edit-passenger-phone-1",
              // placeholder: this.props.passengerList[0].validation.isPhoneValid
              // ? Translation.t("phone_number")
              // : Translation.t("phone_number_required"),
            }}
            value={"+" + props.phones[0].countryCode + props.phones[0].number}
            enableAreaCodes={true}
            enableSearch={true}
            inputExtraProps={{
              name: "edit-passenger-phone-1",
              required: true,
              autoFocus: true,
            }}
            onChange={(value, country, e, formattedValue) => {
              var inputValue = document.getElementById(
                "edit-passenger-phone-1"
              );
              if (
                inputValue.value === null ||
                inputValue.value === "" ||
                inputValue.value === "+"
              ) {
                inputValue.classList.add("invalid_input");
                inputValue.placeholder = Translation.t("phone_number_required");
              } else {
                props.updateContactInfo(formattedValue, "phone1");
              }
            }}
            onBlur={() => {
              var inputValue = document.getElementById(
                "edit-passenger-phone-1"
              );
              if (
                inputValue.value === null ||
                inputValue.value === "" ||
                inputValue.value === "+"
              ) {
                inputValue.classList.add("invalid_input");
                inputValue.placeholder = Translation.t("phone_number_required");
              }
            }}
            onFocus={() => {
              var inputField = document.getElementById(
                "edit-passenger-phone-1"
              );
              inputField.classList.remove("invalid_input");
              inputField.placeholder = Translation.t("phone_number");
            }}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("phone_number")}
          </label>
        </Col>

        <Col xs={12} md={6}>
          <PhoneInput
            className="form-control  widget-input my-2"
            value={
              props.phones[1] !== undefined && props.phones[1] !== null
                ? "+" + props.phones[1].countryCode + props.phones[1].number
                : null
            }
            onChange={(value, country, e, formattedValue) =>
              props.updateContactInfo(formattedValue, "phone2")
            }
            placeholder={Translation.t("phone_number_2")}
          />
          <label className="form-sub-label mx-2">
            {Translation.t("phone_number_2")}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={6} className="my-2">
          <input
            className="form-control  widget-input"
            type="email"
            defaultValue={props.emails}
            id="edit-passenger-email"
            onChange={(value) => {
              
              var value = document.getElementById("edit-passenger-email");
              validateEmail(value);
            }}
            onBlur={() => {
              var inputValue = document.getElementById("edit-passenger-email");
              if (
                inputValue.value === null ||
                inputValue.value === "" ||
                inputValue.value === "+"
              ) {
                inputValue.classList.add("invalid_input");
                inputValue.placeholder = Translation.t(
                  "email_address_required"
                );
              }
            }}
            onFocus={() => {
              var inputField = document.getElementById("edit-passenger-email");
              inputField.classList.remove("invalid_input");
              inputField.placeholder = Translation.t("email_address");
            }}
          />
          <label
            className="form-sub-label mx-2"
            id="edit-passenger-email-label"
          >
            {Translation.t("email_address")}
          </label>
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispacth) => {
  return {
    updateContactInfo: (value, updateType) =>
      dispacth({
        type: passengerAction.UPDATEPASSENGERCONTACT,
        value: value,
        updateType: updateType,
      }),
  };
};
export default connect(null, mapDispatchToProps)(EditContactInfo);
