import React, { Component } from "react";
import { ListGroup, Col } from "react-bootstrap";
// import RecentSearchListTemp from "../../../master-data/booking-widget/recentSearch.json";
import {AirportFullName} from "../../../../service/shared/AirportFullName";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt,faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import { connect } from "react-redux";
import CabinClassService from "../../../../service/booking/cabin-class/CabinClassService.js";
class RecentSearchList extends Component {
  constructor(props){
    super(props);
    this.cabinClassService = new CabinClassService();
  }
  fillSearchForm=(flightIndex,journeyType)=>{
    this.props.fillSearchForm(flightIndex,journeyType);
    this.props.showHideRecentSearchList();
  }
  render() {
    return (
      <Col xs={12} sm={12} md={12} className="pt-2 recent-flight-list-container">
        {" "}
        <div className="mb-3">
          <ListGroup>
          {this.props.recentSearchState.map((recentSearch, index) => {
            if(recentSearch.journeyType==="oneWay"){
              return(
                <div key={index} onClick={this.fillSearchForm.bind(this,recentSearch,"oneWay")} className="recent-search-list-item">
                  <ListGroup.Item className="mb-1 recent-search-list-item">
                      <div className="recent-search-airport-cnt">
                          {" "}

                          <div className="recent-search-city">
                          <span className="circle-span">{index+1}</span> 
                            <span className="pl-3">{AirportFullName.getFullName(recentSearch.itineraryParts[0].from)}</span>
                            <span className="spark-icon-arrows-left-right">
                              {" "}
                              <FontAwesomeIcon icon={faLongArrowAltRight} className="trip-arrow"/>
                            </span>
                            <span >{AirportFullName.getFullName(recentSearch.itineraryParts[0].to)}</span>
                          </div>
                          
                        </div>
                        <div>
                          <span className="recent-search-journey-class">
                            ONE WAY | {this.cabinClassService.getCabinClassByCode(recentSearch.cabinClass)}
                          </span>
                        </div>
                      </ListGroup.Item>  
                </div>
              )
            }
            if(recentSearch.journeyType==="roundTrip"){
              return(
                <div key={index} onClick={this.fillSearchForm.bind(this,recentSearch,"roundTrip")}>
                  <ListGroup.Item className="mb-1 recent-search-list-item">
                      <div className="recent-search-airport-cnt">
                          {" "}
                          <div className="recent-search-city">
                          <span className="circle-span">{index+1}</span> 
                            <span className="pl-3">{AirportFullName.getFullName(recentSearch.itineraryParts[0].from)}</span>
                            <span className="spark-icon-arrows-left-right">
                              {" "}
                              <FontAwesomeIcon icon={faExchangeAlt} className="trip-arrow"/>
                            </span>
                            <span >{AirportFullName.getFullName(recentSearch.itineraryParts[0].to)}</span>
                          </div>
                          
                        </div>
                        <div>
                          <span className="recent-search-journey-class">
                            ROUND TRIP | {this.cabinClassService.getCabinClassByCode(recentSearch.cabinClass)}
                          </span>
                        </div>
                      </ListGroup.Item>  
                </div>
              )
            }
            if(recentSearch.journeyType==="multicity"){
              return(
                <div key={index} onClick={this.fillSearchForm.bind(this,recentSearch,"multicity")}>
                  <ListGroup.Item className="mb-1 recent-search-list-item">
            <div className="recent-search-airport-cnt">
                {" "}
                {
                  recentSearch.itineraryParts.map((itinerary,itineraryIndex)=>(
                    itineraryIndex===0?
                      <div className="recent-search-city" key={itineraryIndex}>
                    <span className="circle-span">{index+1}</span> 
    
                      <span className="pl-3">{AirportFullName.getFullName(itinerary.from)}</span>
                      <span className="spark-icon-arrows-left-right">
                        {" "}
                        <FontAwesomeIcon icon={faLongArrowAltRight} className="trip-arrow"/>
                      </span>
                      <span>{AirportFullName.getFullName(itinerary.to)}</span>
                    </div>:
               <div className="recent-search-city more-result" key={itineraryIndex}>
               <span className="pl-3">{AirportFullName.getFullName(itinerary.from)} </span>
               <span className="spark-icon-arrows-left-right">
                 {" "}
                 <FontAwesomeIcon icon={faLongArrowAltRight} className="trip-arrow"/>
               </span>
               <span>{AirportFullName.getFullName(itinerary.to)}</span>
             </div>
                  ))
                }
            </div>
              <div>
                <span className="recent-search-journey-class">
                  MULTI CITY  | {this.cabinClassService.getCabinClassByCode(recentSearch.cabinClass)}
                </span>
              </div>
            </ListGroup.Item>  
                </div>
              )
            }
            if(recentSearch.journeyType==="stopover"){
              return(
                <div key={index} onClick={this.fillSearchForm.bind(this,index,"stopover")}>
                  <ListGroup.Item className="mb-1 recent-search-list-item">
            <div className="recent-search-airport-cnt">
                {" "}
                {
                  recentSearch.itineraryParts.map((itinerary,itineraryIndex)=>(
                    itineraryIndex===0?
                      <div className="recent-search-city" key={itineraryIndex}>
                    <span className="circle-span">{index+1}</span> 
    
                      <span className="pl-3">{AirportFullName.getFullName(itinerary.from)}</span>
                      <span className="spark-icon-arrows-left-right">
                        {" "}
                        <FontAwesomeIcon icon={faLongArrowAltRight} className="trip-arrow"/>
                      </span>
                      <span>{AirportFullName.getFullName(itinerary.to)}</span>
                    </div>:
               <div className="recent-search-city more-result" key={itineraryIndex}>
               <span className="pl-3">{AirportFullName.getFullName(itinerary.from)} </span>
               <span className="spark-icon-arrows-left-right">
                 {" "}
                 <FontAwesomeIcon icon={faLongArrowAltRight} className="trip-arrow"/>
               </span>
               <span>{AirportFullName.getFullName(itinerary.to)}</span>
             </div>
                  ))
                }
            </div>
              <div>
                <span className="recent-search-journey-class">
                 Stopover | {this.cabinClassService.getCabinClassByCode(recentSearch.cabinClass)}
                </span>
              </div>
            </ListGroup.Item>  
                </div>
              )
            }
            else{
              return<></>
            }
          })}  
          </ListGroup>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    recentSearchState : state.recentSearch.recentSearchList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fillSearchForm:(recentSearch,journeyType)=>dispatch({type:BookingWidgetAction.FILLSEARCHFORM,recentSearch:recentSearch,journeyType:journeyType}),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RecentSearchList);
