import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";
import Translation from "i18next";
import { AirportFullName } from "../../../service/shared/AirportFullName";
class ExchangeFlightSelectionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
    };
  }
  toggleFlightDetail = () => {
    this.setState({ showDetail: !this.state.showDetail });
  };
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let time = moment(dateString)
      .locale(localStorage.getItem("i18nextLng"))
      .format("LT");

    let newDate = moment(dateString)
      .locale(localStorage.getItem("i18nextLng"))
      .format("DD MMMM YYYY");
    return (
      <span>
        {newDate} {time}{" "}
      </span>
    );
  };
  renderStops(segments, stops, key) {
    if (stops > 0) {
      if (segments.length > 1) {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
            {segments.map((segment, index) => (
              <span key={index}>
                {segment.flight.stopAirports.map((airport, index2) => (
                  <span key={index2}>{airport.airport}</span>
                ))}
              </span>
            ))}
          </span>
        );
      }
    } else {
      return <span>{Translation.t("non-stop")}</span>;
    }
  }
  renderAirportName(airportCode) {
    let airportName = AirportFullName.getFullName(airportCode);
    return <span>{airportName}</span>;
  }

  render() {
    
    return (
      <div>
        <Button
          className="selected-flight-detail-btn mb-1"
          onClick={() => this.toggleFlightDetail()}
        >
          <span>{Translation.t("details")}</span>
          <span className="px-2">
            <FontAwesomeIcon
              icon={this.state.showDetail ? faCaretUp : faCaretDown}
            />
          </span>
        </Button>
        {this.state.showDetail && (
          <div className="segment-info">
            {this.props.itineraryPart.map((itinerary, inx) => (
              <Row className="segment-info-detail" inx={inx}>
                {itinerary.segments.map((segment, index) => (
                  <Col xs={12} className="mb-5 mt-4 segment-info-col ">
                    <div className="segment-line"></div>
                    {segment.origin != null && (
                      <div className="pb-2 segment-info-origin">
                        <span className="segment-info-airportcode">
                          {segment.origin}
                        </span>{" "}
                        <span className="segment-info segment-info-airport-name">
                          {this.renderAirportName(segment.origin)}
                        </span>
                      </div>
                    )}
                    {segment.duration != null && (
                      <div className="segment-info-date py-1">
                        <span className="pr-3">
                          {this.parseDate(segment.departure)}
                        </span>
                        <span></span>
                      </div>
                    )}

                    <div className="py-1 pt-3">
                      {" "}
                      <span className="pr-1 ">
                        {segment.flight.airlineCode}
                      </span>
                      {segment.flight.flightNumber != null && (
                        <span>{segment.flight.flightNumber}</span>
                      )}{" "}
                      {segment.flight.operatingAirlineCode != null && (
                        <span>
                          {Translation.t("operated_by")}{" "}
                          {segment.flight.operatingAirlineCode}
                        </span>
                      )}
                    </div>
                    {segment.duration != null &&
                      segment.manufacturerName != null && (
                        <div className="py-1">
                          Aircraft: {segment.manufacturerName}{" "}
                          {segment.equipmentDescription}
                        </div>
                      )}
                    {segment.departureTerminal != null && (
                      <div className="py-1">
                        {Translation.t("departure")}{" "}
                        {segment.flight.departureTerminal}
                      </div>
                    )}
                    {segment.arrivalTerminal != null && (
                      <div className="py-1">
                        {Translation.t("arrival")}{" "}
                        {segment.flight.arrivalTerminal}
                      </div>
                    )}
                    <div className="segment-info-destination">
                      {segment.arrival != null && (
                        <div className="py-1 segment-info-date">
                          {this.parseDate(segment.arrival)}{" "}
                        </div>
                      )}
                      {segment.destination != null && (
                        <div className="py-1">
                          <span className="segment-info-airportcode">
                            {segment.destination}
                          </span>{" "}
                          <span className="segment-info segment-info-airport-name">
                            {this.renderAirportName(segment.destination)}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="segment-line"></div>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default ExchangeFlightSelectionDetail;
