import React from "react";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
class PromoCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromoCode: false,
    };
  }
  componentDidMount(){
    this.props.AddPromoCode(null);
  }
  render() {
    if (
      !this.state.showPromoCode &&
      (this.props.promoCode === null || this.props.promoCode === undefined || this.props.promoCode ==="DOM81" || this.props.promoCode ==="DOM71")
    ) {
      return (
        <div className="col-sm-12 col-md-4 my-2">
          <Button
            id="promo_code_button"
            onClick={() => {
              this.setState({ showPromoCode: true });
            }}
            className="btn-md btn-success promo-code-btn mt-1"
          >
            <FontAwesomeIcon
              icon={faTag}
              size="lg"
              className="mr-2 promo-code-icon"
            />
            <span></span>
            {Translation.t("promo_code")}
          </Button>
        </div>
      );
    } else {
      return (
        <div className="col-sm-12 col-md-4 my-2">
          <input
            id="promo_code_input"
            defaultValue={this.props.promoCode}
            name="promoCode"
            onBlur={() => {
              var promoCodeValue =
                document.getElementById("promo_code_input").value;
              if (promoCodeValue === null || promoCodeValue === "") {
                this.setState({ showPromoCode: false });
                this.props.AddPromoCode(null);
              } else {
                this.props.AddPromoCode(promoCodeValue);
              }
            }}
            className="form-control mt-1 widget-input"
            placeholder={Translation.t("promo_code_placeholder")}
          />
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    promoCode: state.bookingWiget.PromoCode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    AddPromoCode: (input) =>
      dispatch({ type: BookingWidgetAction.ADDPROMOCODE, value: input }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
