import { faClock, faInfoCircle, faPlaneDeparture, faSuitcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";

const WidgetType = (props) => {
  return (
    <div className="booking-type">
      <ButtonGroup aria-label="booking-type" className="btn-block booking-type-container">
        <Button
          variant={props.widgetType === "bookFlight" ? "success" : "white"}
          className={props.widgetType === "bookFlight" ? "active" : ""}
          onClick={() => props.changeWidgetType("bookFlight")}
        >
          <FontAwesomeIcon icon={faPlaneDeparture} className="mx-2" />
          Book a flight
        </Button>
        <Button
          variant={props.widgetType === "flightStatus" ? "success" : "white"}
          className={props.widgetType === "flightStatus" ? "active" : ""}
          onClick={() => props.changeWidgetType("flightStatus")}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mx-2" />
          Flight Status
        </Button>
        <Button
          variant={props.widgetType === "flightSchedule" ? "success" : "white"}
          className={props.widgetType === "flightSchedule" ? "active" : ""}
          onClick={() => props.changeWidgetType("flightSchedule")}
        >
          <FontAwesomeIcon icon={faClock} className="mx-2" />
          Flight Schedule
        </Button>
        <Button
          variant={props.widgetType === "manageTrip" ? "success" : "white"}
          className={props.widgetType === "manageTrip" ? "active" : ""}
          onClick={() => props.changeWidgetType("manageTrip")}
        >
          <FontAwesomeIcon icon={faSuitcase} className="mx-2" />
          Manage my Trips
        </Button>
      </ButtonGroup>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    widgetType: state.bookingWiget.widgetType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetType);
