import {
  faTimesCircle,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
import ExchangeFlightSelectionDetail from "../ExchangeFlightSelectionDetail";
import ExchangeItineraryBrandItemMob from "./ExchangeItineraryBrandItemMob";

class ExchangeItineraryBrandMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEconomySelected: true,
      isBusinessSelected: false,
      selectedBrand: "Economy",
    };
  }
  componentDidMount() {
    if (this.props.echeepPrice === "N/A") {
      this.setState({ isBusinessSelected: true });
      this.setState({ isEconomySelected: false });
    }
    if (this.props.bcheepPrice === "N/A") {
      this.setState({ isEconomySelected: true });
      this.setState({ isBusinessSelected: false });
    }
  }
  toggleBrand(type) {
    if (type === "bus") {
      this.setState({ isBusinessSelected: true });
      this.setState({ isEconomySelected: false });
    } else {
      this.setState({ isEconomySelected: true });
      this.setState({ isBusinessSelected: false });
    }
  }

  render() {
    return (
      <div className="bg-white pb-3 pt-2">
        <div className="itinerary-brand-mob bg-white">
          <div className="text-center py-2">
            <Row className="m-0">
              <Col>
                <div className="fare-option-head">
                  {Translation.t("fare_options")}
                </div>
              </Col>
              <Col>
                <div
                  className="float-right"
                  onClick={() => this.props.onCloseClicked()}
                >
                  <span className="fare-option-close">
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <div className="text-center fly-brand-info">
            <Row className="m-0">
              {this.props.echeepPrice != "N/A" && (
                <Col
                  className={
                    this.state.isEconomySelected
                      ? "brand-type active"
                      : "brand-type"
                  }
                  onClick={() => {
                    this.toggleBrand("eco");
                  }}
                >
                  <div>
                    <span>{Translation.t("economy")}</span>
                  </div>
                  <div>
                    <span>{Translation.t("from")} </span>
                    <span>{this.props.echeepPrice}</span>
                  </div>
                </Col>
              )}
              {this.props.bcheepPrice != "N/A" && (
                <Col
                  className={
                    this.state.isBusinessSelected
                      ? "brand-type active"
                      : "brand-type"
                  }
                  onClick={() => {
                    this.toggleBrand("bus");
                  }}
                >
                  <div>
                    <span>{Translation.t("business")}</span>
                  </div>
                  <div>
                    <span>{Translation.t("from")} </span>
                    <span>{this.props.bcheepPrice}</span>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <Row className="px-3 py-3 pb-0">
          <Col xs={12}>
            <ExchangeFlightSelectionDetail
              itineraryPart={this.props.itineraryPart}
            />
          </Col>
        </Row>
        {this.props.brandOffers.map((brand, key) => (
          <ExchangeItineraryBrandItemMob
            key={key}
            brand={brand}
            selectedBrand={
              this.state.isBusinessSelected ? "Business" : "Economy"
            }
            selectFlight={this.props.selectFlight}
          />
        ))}

        {/* <ItineraryBrandItemMob />
         <ItineraryBrandItemMob /> */}
      </div>
    );
  }
}
export default ExchangeItineraryBrandMob;
