import React, { useState } from "react";
import { Row, Button, Col, Modal } from "react-bootstrap";
import Translation from "i18next";
import { connect } from "react-redux";
import EditPassenger from "./EditPassenger";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

const EditPassengerInfo = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [redirectToManageTrip, setRedirectToManageTrip] = useState(false);
  var history = useHistory();

if(redirectToManageTrip){
history.push("/manage-trip-detail");
}
const updatepassenger = () => {
  
  var serverForData = {
    PNR: props.pnr,
    PassengersDetail: props.passengersInfo,
    cookieSabreDataRequest: props.cookieSabreDataRequest,
  };
  setIsLoading(true);
  HttpService.postManageTrip(serverForData, "/passenger/DetailUpdate", props.token)
    .then((passengerResponse) => {
      if (passengerResponse.data.statusAPI === "SUCCESS") {
        setRedirectToManageTrip(true);
      } else {
        setOpenErrorModal(true);
        setErrorMessage(passengerResponse.data.messageAPI);
      }
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
};
  if (props.passengersInfo !== undefined) {
    return (
      <>
        {isLoading && <Loading />}
        <Modal show={openErrorModal} onHide={() => setOpenErrorModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Error Message List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <FontAwesomeIcon icon={faTimes} className="mx-2" />
                {errorMessage}
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              onClick={() => setOpenErrorModal(false)}
            >
              {Translation.t("close")}
            </button>
          </Modal.Footer>
        </Modal>
        <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
          <div className="col-12 col-xl-7 mx-auto mx-xl-0">
            <div className="mt-1 mx-auto mr-xl-0">
              <div className="mx-3">
                <h3>Passenger Information</h3>
              </div>
              {props.passengersInfo.map((passenger, index) => (
                <EditPassenger
                  passenger={passenger}
                  key={index}
                  index={index}
                />
              ))}
              <Row>
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <div className="float-right">
                    <Button className="bk-submit-btn btn-ethiopian mx-2">
                      {Translation.t("cancel")}
                    </Button>
                    <Button
                      className="bk-submit-btn btn-ethiopian ml-2"
                      onClick={() => updatepassenger()}
                    >
                      {Translation.t("update_passenger_info")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </>
    );
  } else {
    return <></>;
  }
};
const mapStateToProps = (state) => {
  return {
    passengersInfo: state.passenger.passengers,
    isPassengerUpdateFormValid: state.passenger.isPassengerUpdateFormValid,
    token: state.searchFlight.token,
    cookieSabreDataRequest:
      state.manageMyBooking.responeData.cookieSabreDataRequest,
      pnr: state.manageMyBooking.formInput.pnr,
  };
};
export default connect(mapStateToProps, null)(EditPassengerInfo);
