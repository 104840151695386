import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import AirportSelection from "../../booking/booking-widget/airport-selection/AirportSelection";
import { connect } from "react-redux";
import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";
import FlightInfoService from "../../../service/manage-my-booking/FlightInfoService";
import HttpService from "../../../service/shared/HttpService";
import { flightStatusAction } from "../../../redux/actions/manage-my-booking/flightStatusAction";
import { globalActionType } from "../../../redux/actions/shared/globalAction";

import Translation from "i18next";

const EditFlightStatus = (props) => {
  const [itineraryPart, setItineraryPart] = useState(() => {
    const initialState = JSON.parse(JSON.stringify(props.itineraryPart));
    return initialState;
  });

  const [searchType, setSearchType] = useState(() => {
    return props.flightStatusSearchType;
  });
  const [showEditForm, setShowEditForm] = useState(false);
  const flightInfoService = new FlightInfoService();

  const handleFlightStatusSaveClick = () => {
    if (
      props.itineraryPart[0].params.from !== null &&
      props.itineraryPart[0].params.to !== null
    ) {
      props.updateIsLoading(true);
      // by route
      if (searchType === 1) {
        var payload = {
          Origin: props.itineraryPart[0].params.from.value,
          Destination: props.itineraryPart[0].params.to.value,
        };

        flightInfoService
          .getFlightStatusByRoute(payload, props.token)
          .then((response) => {
            if (
              response.data.statusAPI === "ERROR" ||
              response.data.statusAPI === null
            ) {
              props.updateFlightStatusPresent(false);
            }
            if (response.data.statusAPI === "SUCCESS") {
              props.updateFlightStatusPresent(true);
              props.initFlightStatus({
                todayFlightStatusResponse:
                  response.data.todayFlightStatusResponse,
                tomorrowFlightStatusResponse:
                  response.data.tomorrowFlightStatusResponse,
              });
            }
            props.updateSearchType(searchType);
            props.updateIsLoading(false);
          })
          .catch((error) => {
            HttpService.getTokenServive().then((response) => {
              this.props.saveToken(response.data.tokenDetail.access_token);
              flightInfoService
                .getFlightStatusByRoute(payload, props.token)
                .then((response) => {
                  if (
                    response.data.statusAPI === "ERROR" ||
                    response.data.statusAPI === null
                  ) {
                    props.updateFlightStatusPresent(false);
                  }
                  if (response.data.statusAPI === "SUCCESS") {
                    props.updateFlightStatusPresent(true);
                    props.initFlightStatus({
                      todayFlightStatusResponse:
                        response.data.todayFlightStatusResponse,
                      tomorrowFlightStatusResponse:
                        response.data.tomorrowFlightStatusResponse,
                    });
                  }
                  props.updateSearchType(searchType);
                  props.updateIsLoading(false);
                });
            });
          });
      }
      // by flight number
      else {
        flightInfoService
          .getFlightStatusByFlightNumber(props.flightNumber, props.token)
          .then((response) => {
            console.log(response);
            if (response.data.statusAPI === "ERROR") {
              props.updateFlightStatusPresent(false);
            }
            if (response.data.statusAPI === "SUCCESS") {
              props.updateFlightStatusPresent(true);
              props.initFlightStatus({
                todayFlightStatusResponse:
                  response.data.todayFlightStatusResponse,
                tomorrowFlightStatusResponse:
                  response.data.tomorrowFlightStatusResponse,
                yesterdayFlightStatusResponse:
                  response.data.yesterdayFlightStatusResponse,
              });
            }
            props.updateSearchType(searchType);
            props.updateIsLoading(false);
          })
          .catch((error) => {
            HttpService.getTokenServive().then((response) => {
              this.props.saveToken(response.data.tokenDetail.access_token);
              flightInfoService
                .getFlightStatusByFlightNumber(props.flightNumber, props.token)
                .then((response) => {
                  console.log(response);
                  if (response.data.statusAPI === "ERROR") {
                    props.updateFlightStatusPresent(false);
                  }
                  if (response.data.statusAPI === "SUCCESS") {
                    props.updateFlightStatusPresent(true);
                    props.initFlightStatus({
                      todayFlightStatusResponse:
                        response.data.todayFlightStatusResponse,
                      tomorrowFlightStatusResponse:
                        response.data.tomorrowFlightStatusResponse,
                      yesterdayFlightStatusResponse:
                        response.data.yesterdayFlightStatusResponse,
                    });
                  }
                  props.updateSearchType(searchType);
                  props.updateIsLoading(false);
                });
            });
          });
      }
    }
  };

  var searchBody;
  if (searchType === 1) {
    searchBody = (
      <Row>
        <AirportSelection flightIndex={0} />
      </Row>
    );
  } else {
    searchBody = (
      <Row className="my-2">
        <Col xs={12} sm={12} md={6}>
          <input
            className="form-control widget-input"
            placeholder="Flight Number"
            value={props.flightNumber}
            type="number"
            onChange={(event) => props.updateFlightNumber(event.target.value)}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <div className="edit-schedule-wrapper">
        <div className="flight-selection-container">
          <Row className="mx-0 w-100">
            {itineraryPart[0].params.from !== null &&
            itineraryPart[0].params.to !== null &&
            props.flightStatusSearchType === 1 ? (
              <>
                <Col xs={4}>
                  {itineraryPart[0].params.from !== null
                    ? props.isMedium
                      ? itineraryPart[0].params.from.value
                      : itineraryPart[0].params.from.label
                    : null}
                </Col>
                <Col xs={4}>
                  {itineraryPart[0].params.to !== null
                    ? props.isMedium
                      ? itineraryPart[0].params.to.value
                      : itineraryPart[0].params.to.label
                    : null}
                </Col>
              </>
            ) : null}

            <Col xs={3} className="d-flex justify-content-end ml-auto px-0">
              <button
                className="btn btn-md btn-success"
                onClick={() => setShowEditForm(!showEditForm)}
              >
                <FontAwesomeIcon icon={faPen} className="mx-2" />
              </button>
            </Col>
          </Row>

          {showEditForm ? (
            <div className="mx-0 w-100 py-4 edit-schedule-insider">
              <h2>{Translation.t("enter_flight_information")}</h2>
              <p>{Translation.t("please_note")}</p>
              <div className="journey-type">
                <Button
                  variant={searchType === 1 ? "success" : "white"}
                  className={searchType === 1 ? "active" : ""}
                  onClick={() => setSearchType(1)}
                >
                  {Translation.t("route")}
                </Button>
                <Button
                  variant={searchType === 2 ? "success" : "white"}
                  className={searchType === 2 ? "active" : ""}
                  onClick={() => setSearchType(2)}
                >
                  {Translation.t("flight_#")}
                </Button>
                {searchBody}
                <Row className="mt-3 justify-content-end">
                  <Col xs={12} sm={12} md={6} lg={2}>
                    <Button
                      variant="danger"
                      className="btn-block bk-submit-btn btn-ethiopian mt-3"
                      onClick={() => setShowEditForm(!showEditForm)}
                    >
                      {Translation.t("cancel")}
                    </Button>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={2}>
                    <Button
                      variant="danger"
                      className="btn-block bk-submit-btn btn-ethiopian my-3"
                      id="start-booking-btn"
                      disabled={
                        (searchType === 1 &&
                          props.itineraryPart[0].params.from === null &&
                          props.itineraryPart[0].params.to === null) ||
                        (searchType === 2 && props.flightNumber === "")
                          ? "disabled"
                          : ""
                      }
                      onClick={() => handleFlightStatusSaveClick()}
                    >
                      {Translation.t("save")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
    token: state.searchFlight.token,
    flightNumber: state.flightStatus.flightNumber,
    isLoading: state.global.isLoading,
    flightStatusSearchType: state.flightStatus.flightStatusSearchType,
    isMedium: state.global.isMedium,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBackedupSchedules: (value) =>
      dispatch({
        type: flightScheduleAction.SCHEDULEINITFLIGHTS,
        payload: value,
      }),
    saveToken: (token) =>
      dispatch({
        type: searchFlightAction.SAVETOKEN,
        token: token,
      }),
    updateSearchType: (value) =>
      dispatch({
        type: flightStatusAction.UPDATESTATUSSERACHTYPE,
        payload: value,
      }),
    updateFlightNumber: (value) =>
      dispatch({
        type: flightStatusAction.UPDATEFLIGHTNUMBER,
        payload: value,
      }),
    updateFlightStatusPresent: (value) =>
      dispatch({
        type: flightStatusAction.ISFLIGHTSTATUSPRESENT,
        payload: value,
      }),
    updateIsLoading: (value) =>
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      }),
    initFlightStatus: (value) =>
      dispatch({
        type: flightStatusAction.INITFLIGHTSTATUS,
        payload: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFlightStatus);
