export class DocumentModel {
  document = {
    id: "",
    type: "",
    number: "",
    nationality: "",
    personName: {
      prefix: "",
      first: "",
      middle: "",
      last: "",
      suffix: "",
      raw: "",
    },
    countryOfBirth: "",
    placeOfBirth: "",
    dateOfBirth: "",
    dayOfBirth: "",
    monthOfBirth: "",
    yearOfBirth: "",
    issueDate: "",
    issueYear: "",
    issueMonth: "",
    issueDay: "",
    issuingCountry: "",
    expiryDate: "",
    expiryYear: "",
    expiryMonth: "",
    expiryDay: "",
    gender: "",
    issuingPlace: "",
  };
}
