export class UnionPayRequestObj
{
constructor()
{
    this.requestObj ={
        "paymentRequired": true,
        "payment": [
          {
            "@type": null,
            "@id": "1",
            "amount": {
              "amount": null,
              "currency":null
            },
            "number": null,
            "cvc": null,
            "pin": null,
            "holderName": null,
            "expirationDate": null,
            "cardCode": null,
            "installment": null,
            "received3rdPartyData": null,
            "selectedDCCOffer": null,
            "redirectData": null,
            "redirectUrlData": {
              "defaultURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Default",
              "pendingURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Pending",
              "approvedURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Approved",
              "declinedURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Declined",
              "errorURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Error",
              "cancelURL": "https://azuremobileapitest.ethiopianairlines.com:19081/Payment/WebPayment/LandingPage/Cancel"
            },
            "afopClientDetail": {
              "firstName": null,
              "lastName": null,
              "birthDate": null,
              "gender": null,
              "email":null,
              "street": null,
              "houseNumber": null,
              "postcode": null,
              "province": null,
              "city": null,
              "country": null,
              "issuer": null
            },
            "fopCode": null,
            "fopSubcode": null,
            "cancelURL": null,
            "successURL": null,
            "payerId": null,
            "token": null,
            "status": null
          }
        ],
        "billingData": null,
        "fraudNetData": null,
        "holdOptionId": null,
        "cookieSabreDataRequest":null
      }
}
}