import React, { useState } from "react";
import { faUserCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import Translation from "i18next";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import AdultYear from "../../../master-data/shared/adultYear.json";
import Month from "../../../master-data/shared/month.json";
import Day from "../../../master-data/shared/day.json";
import PhoneType from "../../../master-data/shared/phoneType.json";
import PhoneInput from "react-phone-input-2";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import { connect } from "react-redux";
import { accountAction } from "../../../redux/actions/manage-my-booking/accountAction";
import { useHistory } from "react-router-dom";
import AccountService from "../../../service/manage-my-booking/account/AccountService";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";

const selectStyle = AirportSelectStyle.desktopStyle;
const passengerService = new PassengerService();
const Year = AdultYear;
const accountService = new AccountService();

const CreateAccount = (props) => {
  var history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isPassWordMatch, setIsPasswordMatch] = useState(true);
  const [backToHome, setBackToHome] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  if (props.isUserSignedIn) {
    history.push("/manage-profile");
  }
  if (backToHome) {
    history.push("/");
  }
  const createAccountHandler = async () => {
    checkPasswordMatch();
    if (isAgree) {
      var isFormValid = await accountService.validateCreateAccount(
        props.account
      ).isFormValid;
      if (isFormValid && isPassWordMatch) {
        setIsLoading(true);
        var createAccountRequestObJ = accountService.createAccountRequestObJ(
          props.account.createAccountModle
        );
        console.log(createAccountRequestObJ);

        await HttpService.postAccount(
          createAccountRequestObJ,
          "/Create",
          props.token
        )
          .then((createResponse) => {
            if (createResponse.data.statusAPI === "SUCCESS") {
              var loginRequestDate = {
                userId: props.userName,
                password: props.password,
                captcha: null,
              };
              HttpService.postAccount(loginRequestDate, "/Login", props.token)
                .then((loginResponse) => {
                  if (loginResponse.data.statusAPI === "SUCCESS") {
                    setIsLoading(false);
                    props.signIn(
                      true,
                      loginResponse.data.result.user.personalDetails.firstName,
                      loginResponse.data.result.user.personalDetails.lastName,
                      loginResponse.data.cookieSabreDataRequest,
                      loginResponse.data.shebaMilesDetails.currentAwardPoints
                    );
                    history.push({
                      pathname: "/manage-profile",
                      search: "newAccount=true",
                    });
                  } else {
                    setIsLoading(false);
                    setShowModal(true);
                    loginResponse.data.errorList.push(
                      loginResponse.data.messageAPI
                    );
                    setErrorMessage(loginResponse.data.errorList);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setIsLoading(false);
                });
            } else {
              setIsLoading(false);
              setShowModal(true);
              createResponse.data.errorList.push(
                createResponse.data.messageAPI
              );
              setErrorMessage(createResponse.data.errorList);
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      } else {
        if (isPassWordMatch) {
          document
            .getElementById("create-account-retype-password")
            .classList.add("invalid_input");
        }
        props.validateAccountModel();
      }
    }
  };
  const checkPasswordMatch = () => {
    var password = document.getElementById("create-account-password").value;
    var retypePassword = document.getElementById(
      "create-account-retype-password"
    );

    if (password !== retypePassword.value) {
      retypePassword.classList.add("invalid_input");
      setIsPasswordMatch(false);
    } else {
      setIsPasswordMatch(true);
      retypePassword.classList.remove("invalid_input");
    }
  };
  return (
    <Container>
      {isLoading && <Loading />}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{Translation.t("error_msg_list")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <FontAwesomeIcon icon={faTimes} className="mx-2" />
              {errorMessage}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={() => setShowModal(false)}
          >
            {Translation.t("close")}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3">
        <h3>{Translation.t("create_profile_3_step")}</h3>
      </div>
      <Row className="create-account w-100 m-0 p-0">
        <Col>
          <div className="create-account-header">
            <h5>{Translation.t("why_create_profile")}</h5>
            <Row className="w-100 m-0 p-0">
              <div className="col-12 col-md-1 d-flex justify-content-center">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  size="3x"
                  className="float-left"
                />
              </div>
              <div className="col-11 col-md-11 m-0 p-0">
                <p>{Translation.t("why_create_profile_description")}</p>
              </div>
            </Row>
            <Row></Row>
            <p>
              <b>{Translation.t("profile_tip")}</b>
              {/* <FontAwesomeIcon icon={faStickyNote} size="2x" className="mx-3"/> */}
            </p>
            <p>
              <b>{Translation.t("please_note")}</b>
            </p>
          </div>
          <div className="create-account-basic-info mt-3">
            <hr />
            <h4>
              <span className="account-step mr-3">1</span>
              {Translation.t("basic_info")}
            </h4>
            <hr />
            <Row>
              <Col xs="12" sm={12} md={6} lg={2} className="px-1 my-2">
                <Select
                  className={props.isTitleValid ? "" : "is-invalid-select"}
                  value={props.title}
                  isSearchable={false}
                  styles={selectStyle}
                  options={passengerService.getTitleList()}
                  placeholder={
                    props.isTitleValid ? (
                      Translation.t("title")
                    ) : (
                      <span className="invalid-select-placeholder">
                        {Translation.t("title_required")}
                      </span>
                    )
                  }
                  onFocus={() =>
                    props.changeSelectValidation(
                      "title",
                      "isTitleValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "title",
                      "isTitleValid",
                      "onBlur"
                    )
                  }
                  onChange={(value) => props.updateAccountModel(value, "title")}
                ></Select>
              </Col>
              <Col xs="12" sm={12} md={6} lg={4} className="px-1 my-2">
                <input
                  id="create-account-first-name"
                  className={
                    props.isFirstNameValid
                      ? "form-control widget-input"
                      : "form-control  widget-input invalid_input"
                  }
                  placeholder={
                    props.isFirstNameValid
                      ? Translation.t("first_name")
                      : Translation.t("first_name_required")
                  }
                  defaultValue={props.firstName}
                  onFocus={() =>
                    props.changeSelectValidation(
                      "firstName",
                      "isFirstNameValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "firstName",
                      "isFirstNameValid",
                      "onBlur"
                    )
                  }
                  onChange={() => {
                    var value = document.getElementById(
                      "create-account-first-name"
                    ).value;
                    props.updateAccountModel(value, "firstName");
                  }}
                />
              </Col>
              <Col xs="12" sm={12} md={6} lg={3} className="px-1 my-2">
                <input
                  className="form-control  widget-input"
                  placeholder={Translation.t("middle_name")}
                  id="create-account-middle-name"
                  defaultValue={props.middleName}
                  onChange={() => {
                    var value = document.getElementById(
                      "create-account-middle-name"
                    ).value;
                    props.updateAccountModel(value, "middleName");
                  }}
                />
              </Col>
              <Col xs="12" sm={12} md={6} lg={3} className="px-1 my-2">
                <input
                  className={
                    props.isLastNameValid
                      ? "form-control widget-input"
                      : "form-control  widget-input invalid_input"
                  }
                  id="create-account-last-name"
                  placeholder={
                    props.isLastNameValid
                      ? Translation.t("last_name")
                      : Translation.t("last_name_required")
                  }
                  defaultValue={props.lastName}
                  onFocus={() =>
                    props.changeSelectValidation(
                      "lastName",
                      "isLastNameValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "lastName",
                      "isLastNameValid",
                      "onBlur"
                    )
                  }
                  onChange={() => {
                    var value = document.getElementById(
                      "create-account-last-name"
                    ).value;
                    props.updateAccountModel(value, "lastName");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Row className="mr-0">
                  <div className="col-4 pr-0">
                    <Select
                      className={props.isYearValid ? "" : "is-invalid-select"}
                      styles={selectStyle}
                      options={Year}
                      value={props.year}
                      isSearchable={false}
                      placeholder={
                        props.isYearValid ? (
                          Translation.t("birth_year")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_year_required")}
                          </span>
                        )
                      }
                      onFocus={() =>
                        props.changeSelectValidation(
                          "year",
                          "isYearValid",
                          "onFocus"
                        )
                      }
                      onBlur={() =>
                        props.changeSelectValidation(
                          "year",
                          "isYearValid",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        props.updateAccountModel(value, "year")
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_year")}
                    </label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      className={props.isMonthValid ? "" : "is-invalid-select"}
                      styles={selectStyle}
                      value={props.month}
                      options={Month}
                      isSearchable={false}
                      placeholder={
                        props.isMonthValid ? (
                          Translation.t("birth_month")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_month_required")}
                          </span>
                        )
                      }
                      onFocus={() =>
                        props.changeSelectValidation(
                          "month",
                          "isMonthValid",
                          "onFocus"
                        )
                      }
                      onBlur={() =>
                        props.changeSelectValidation(
                          "month",
                          "isMonthValid",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        props.updateAccountModel(value, "month")
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_month")}
                    </label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      className={props.isDayValid ? "" : "is-invalid-select"}
                      value={props.day}
                      styles={selectStyle}
                      options={Day}
                      isSearchable={false}
                      placeholder={
                        props.isDayValid ? (
                          Translation.t("birth_day")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_day_required")}
                          </span>
                        )
                      }
                      onFocus={() =>
                        props.changeSelectValidation(
                          "day",
                          "isDayValid",
                          "onFocus"
                        )
                      }
                      onBlur={() =>
                        props.changeSelectValidation(
                          "day",
                          "isDayValid",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        props.updateAccountModel(value, "day")
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">
                      {Translation.t("birth_day")}
                    </label>
                  </div>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Select
                  className={props.isGenderValid ? "" : "is-invalid-select"}
                  value={props.gender}
                  styles={selectStyle}
                  options={passengerService.getGenderList()}
                  isSearchable={false}
                  placeholder={
                    props.isGenderValid ? (
                      Translation.t("gender")
                    ) : (
                      <span className="invalid-select-placeholder">
                        {Translation.t("gender_required")}
                      </span>
                    )
                  }
                  onFocus={() =>
                    props.changeSelectValidation(
                      "gender",
                      "isGenderValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "gender",
                      "isGenderValid",
                      "onBlur"
                    )
                  }
                  onChange={(value) =>
                    props.updateAccountModel(value, "gender")
                  }
                ></Select>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Select
                  className={props.isPhoneType1Valid ? "" : "is-invalid-select"}
                  value={props.phoneType1}
                  styles={selectStyle}
                  options={PhoneType}
                  isSearchable={false}
                  placeholder={
                    props.isPhoneType1Valid ? (
                      <span>{Translation.t("phone_type")} *</span>
                    ) : (
                      <span className="invalid-select-placeholder">
                        {Translation.t("phone_type_required")}
                      </span>
                    )
                  }
                  onFocus={() =>
                    props.changeSelectValidation(
                      "phoneType1",
                      "isPhoneType1Valid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "phoneType1",
                      "isPhoneType1Valid",
                      "onBlur"
                    )
                  }
                  onChange={(value) =>
                    props.updateAccountModel(value, "phoneType1")
                  }
                ></Select>
              </Col>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <PhoneInput
                  inputProps={{
                    className: props.isPhone1Valid
                      ? "form-control  widget-input my-2"
                      : "form-control  widget-input my-2 invalid_input",
                    placeholder: Translation.t("phone_number"),
                    id: "create-account-phone-1",
                  }}
                  inputExtraProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  enableAreaCodes={true}
                  enableSearch={true}
                  value={props.phone1}
                  onChange={(value, country, e, formattedValue) => {
                    props.updateAccountModel(formattedValue, "phone1");
                  }}
                  onBlur={() => {
                    var inputValue = document.getElementById(
                      "create-account-phone-1"
                    );
                    if (
                      inputValue.value === null ||
                      inputValue.value === "" ||
                      inputValue.value === "+"
                    ) {
                      inputValue.classList.add("invalid_input");
                      inputValue.placeholder = Translation.t(
                        "phone_number_required"
                      );
                    }
                  }}
                  onFocus={() => {
                    var inputField = document.getElementById(
                      "create-account-phone-1"
                    );
                    inputField.classList.remove("invalid_input");
                    inputField.placeholder = Translation.t("phone_number");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Select
                  className={props.isPhoneType2Valid ? "" : "is-invalid-select"}
                  styles={selectStyle}
                  options={PhoneType}
                  isSearchable={false}
                  value={props.phoneType2}
                  placeholder={Translation.t("phone_type")}
                  onChange={(value) =>
                    props.updateAccountModel(value, "phoneType2")
                  }
                ></Select>
              </Col>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <PhoneInput
                  inputProps={{
                    className: props.isPhone2Valid
                      ? "form-control  widget-input my-2"
                      : "form-control  widget-input my-2 invalid_input",
                    placeholder: Translation.t("phone_number_2"),
                  }}
                  inputExtraProps={{
                    required: true,
                    autoFocus: true,
                  }}
                  enableAreaCodes={true}
                  enableSearch={true}
                  value={props.phone2}
                  onChange={(value, country, e, formattedValue) => {
                    props.updateAccountModel(formattedValue, "phone2");
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <input
                  className={
                    props.isEmailValid
                      ? "form-control  widget-input"
                      : "form-control  widget-input invalid_input"
                  }
                  type="email"
                  id="create-account-email"
                  defaultValue={props.email}
                  placeholder={
                    props.isEmailValid
                      ? Translation.t("email_address")
                      : Translation.t("email_address_required")
                  }
                  onFocus={() =>
                    props.changeSelectValidation(
                      "email",
                      "isEmailValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "email",
                      "isEmailValid",
                      "onBlur"
                    )
                  }
                  onChange={(value) => {
                    var value = document.getElementById(
                      "create-account-email"
                    ).value;
                    props.updateAccountModel(value, "email");
                    props.changeSelectValidation(
                      "email",
                      "isEmailValid",
                      "onBlur"
                    );
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <b>{Translation.t("consent_info")}</b>
                <Row>
                  <div className="col-12">
                    <p>
                      <b>{Translation.t("operational_updates")}</b>
                    </p>
                    <div className="et-checkbox">
                      <input
                        type="checkbox"
                        id="operational-update"
                        checked={props.operationalUpdatesEnabled}
                        onChange={() => {
                          props.updateAccountModel(
                            !props.operationalUpdatesEnabled,
                            "operationalUpdatesEnabled"
                          );
                        }}
                      />
                      <label htmlFor="operational-update" className="d-inline">
                        {Translation.t("operational_updates_description")}
                      </label>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-12">
                    <p>
                      <b>{Translation.t("marketing_consent")}</b>
                    </p>
                    <div className="et-checkbox">
                      <input
                        type="checkbox"
                        id="marketing-consent"
                        checked={props.marketingConsentEnabled}
                        onChange={() => {
                          props.updateAccountModel(
                            !props.marketingConsentEnabled,
                            "marketingConsentEnabled"
                          );
                        }}
                      />
                      <label htmlFor="marketing-consent" className="d-inline">
                        {Translation.t("marketing_consent_description")}
                      </label>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="create-account-account-setting">
            <hr />
            <h4>
              <span className="account-step mr-3">2</span>
              {Translation.t("account_settings")}
            </h4>
            <hr />
            <h6>{Translation.t("account_info")}</h6>
            <Row>
              <Col xs={12}>
                <input
                  className={
                    props.isUserNameValid
                      ? "form-control  widget-input my-1"
                      : "form-control  widget-input my-1 invalid_input"
                  }
                  autoComplete={false}
                  id="create-account-user-name"
                  placeholder={
                    props.isUserNameValid
                      ? Translation.t("user_name")
                      : Translation.t("user_name_required")
                  }
                  defaultValue={props.userName}
                  onFocus={() =>
                    props.changeSelectValidation(
                      "userName",
                      "isUserNameValid",
                      "onFocus"
                    )
                  }
                  onBlur={() =>
                    props.changeSelectValidation(
                      "userName",
                      "isUserNameValid",
                      "onBlur"
                    )
                  }
                  onChange={(value) => {
                    var value = document.getElementById(
                      "create-account-user-name"
                    ).value;
                    props.updateAccountModel(value, "userName");
                  }}
                />
                <label>
                  <b>{Translation.t("user_name_tip")}</b>:{" "}
                  {Translation.t("user_name_tip_description")}
                </label>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <input
                  className={
                    props.isPasswordValid
                      ? "form-control  widget-input my-1"
                      : "form-control  widget-input my-1 invalid_input"
                  }
                  autoComplete={false}
                  id="create-account-password"
                  placeholder={
                    props.isPasswordValid
                      ? Translation.t("password")
                      : Translation.t("password_required")
                  }
                  defaultValue={props.password}
                  type="password"
                  onFocus={() =>
                    props.changeSelectValidation(
                      "password",
                      "isPasswordValid",
                      "onFocus"
                    )
                  }
                  onBlur={() => {
                    checkPasswordMatch();
                    props.changeSelectValidation(
                      "password",
                      "isPasswordValid",
                      "onBlur"
                    );
                  }}
                  onChange={(value) => {
                    var value = document.getElementById(
                      "create-account-password"
                    ).value;
                    props.updateAccountModel(value, "password");
                  }}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <input
                  className={
                    isPassWordMatch
                      ? "form-control  widget-input my-1"
                      : "form-control  widget-input my-1 invalid_input"
                  }
                  placeholder={
                    isPassWordMatch
                      ? Translation.t("retype_password")
                      : Translation.t("retype_password_error")
                  }
                  id="create-account-retype-password"
                  type="password"
                  onBlur={() => {
                    checkPasswordMatch();
                  }}
                  onChange={() => {
                    checkPasswordMatch();
                  }}
                  onFocus={() => {
                    var retypePassword = document.getElementById(
                      "create-account-retype-password"
                    );
                    retypePassword.classList.remove("invalid_input");
                    retypePassword.placeholder =
                      Translation.t("retype_password");
                  }}
                />
              </Col>
              <Col xs={12}>
                <label>
                  <b>{Translation.t("password_tip")}</b>:{" "}
                  {Translation.t("password_tip_description")}
                </label>
              </Col>
            </Row>
          </div>
          <div className="create-account-footer">
            <hr />

            <h4>
              <span className="account-step mr-3">3</span>
              {Translation.t("complete_profile")}
            </h4>
            <hr />
            <Row className="w-100">
              <Col>
                <div className="et-checkbox">
                  <input
                    type="checkbox"
                    id="account-agree"
                    checked={isAgree}
                    onChange={() => {
                      setIsAgree(!isAgree);
                    }}
                  />
                  <label htmlFor="account-agree" className="mx-3">
                    {Translation.t("create_account_agree")}
                  </label>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col sm={12} md={6}></Col>
        <Col sm={12} md={6}>
          <span className="float-right">
            <Button
              variant="danger"
              className="bk-submit-btn btn-ethiopian mx-2 btn-danger"
              onClick={() => setBackToHome(true)}
            >
              {Translation.t("cancel")}
            </Button>
            <Button
              variant="danger"
              className={
                isAgree
                  ? "bk-submit-btn btn-ethiopian mx-2"
                  : "bk-submit-btn btn-ethiopian mx-2  btn-not-allowd disabled"
              }
              onClick={() => createAccountHandler()}
            >
              {Translation.t("create_account")}
            </Button>
          </span>
        </Col>
      </Row>
    </Container>
  );
};
const mapStateToProps = (state) => {
  return {
    title: state.account.createAccountModle.title,
    isTitleValid: state.account.createAccountModle.validation.isTitleValid,
    isFirstNameValid:
      state.account.createAccountModle.validation.isFirstNameValid,
    firstName: state.account.createAccountModle.firstName,
    middleName: state.account.createAccountModle.middleName,
    isLastNameValid:
      state.account.createAccountModle.validation.isLastNameValid,
    lastName: state.account.createAccountModle.lastName,
    isYearValid: state.account.createAccountModle.validation.isYearValid,
    year: state.account.createAccountModle.year,
    isMonthValid: state.account.createAccountModle.validation.isMonthValid,
    month: state.account.createAccountModle.month,
    isDayValid: state.account.createAccountModle.validation.isDayValid,
    day: state.account.createAccountModle.day,
    isGenderValid: state.account.createAccountModle.validation.isGenderValid,
    gender: state.account.createAccountModle.gender,
    isPhoneType1Valid:
      state.account.createAccountModle.validation.isPhoneType1Valid,
    phoneType1: state.account.createAccountModle.phoneType1,
    isPhoneType2Valid:
      state.account.createAccountModle.validation.isPhoneType2Valid,
    phoneType2: state.account.createAccountModle.phoneType2,
    email: state.account.createAccountModle.email,
    isEmailValid: state.account.createAccountModle.validation.isEmailValid,
    isUserNameValid:
      state.account.createAccountModle.validation.isUserNameValid,
    userName: state.account.createAccountModle.userName,
    isPasswordValid:
      state.account.createAccountModle.validation.isPasswordValid,
    password: state.account.createAccountModle.password,
    operationalUpdatesEnabled:
      state.account.createAccountModle.operationalUpdatesEnabled,
    marketingConsentEnabled:
      state.account.createAccountModle.marketingConsentEnabled,
    agree: state.account.createAccountModle.agree,
    phone1: state.account.createAccountModle.phone1,
    phone2: state.account.createAccountModle.phone2,
    isPhone1Valid: state.account.createAccountModle.validation.isPhone1Valid,
    isPhone2Valid: state.account.createAccountModle.validation.isPhone2Valid,
    isUserSignedIn: state.global.isUserSignedIn,
    account: state.account,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectValidation: (valueParameter, validationParameter, domain) =>
      dispatch({
        type: accountAction.CHANGEVALIDATION,
        domain: domain,
        valueParameter: valueParameter,
        validationParameter: validationParameter,
      }),
    updateAccountModel: (value, valueParameter) =>
      dispatch({
        type: accountAction.UPADTE,
        value: value,
        valueParameter: valueParameter,
      }),
    validateAccountModel: () => {
      dispatch({
        type: accountAction.VALIDATECREATEACCOUNT,
      });
    },
    signIn: (value, firstName, lastName, profileCookie, currentAwardPoints) =>
      dispatch({
        type: accountAction.SIGNEDIN,
        value: value,
        firstName: firstName,
        lastName: lastName,
        profileCookie: profileCookie,
        currentAwardPoints: currentAwardPoints,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
