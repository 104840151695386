import React, { Component } from "react";
import { Button, ButtonGroup, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";

class JourneyType extends Component {
  onJourneyTypeSelected = (journeyType) => {
    this.props.changeJournyType(journeyType);
  };

  render() {
    return (
      <Col xs={12} sm={12} md={8} lg={8} className="px-0">
        {" "}
        <div className="journey-type">
          {/* <div className="d-flex my-4">
          <input type="radio" id="oneWay" name="journey-type" checked={this.props.journeyType==="oneWay"} onChange={this.onJourneyTypeSelected.bind(this,"oneWay")}/>
          <label htmlFor="oneWay">ONE WAY</label>
          <input type="radio" id="roundTrip" name="journey-type" checked={this.props.journeyType==="roundTrip"} onChange={this.onJourneyTypeSelected.bind(this,"roundTrip")}/>
          <label htmlFor="roundTrip">ROUND TRIP</label>
          <input type="radio" id="multicity" name="journey-type" checked={this.props.journeyType==="multicity"} onChange={this.onJourneyTypeSelected.bind(this,"multicity")}/>
          <label htmlFor="multicity">MULTI-CITY</label>
          <input type="radio" id="stopover" name="journey-type" checked={this.props.journeyType==="stopover"} onChange={this.onJourneyTypeSelected.bind(this,"stopover")}/>
          <label htmlFor="stopover">STOP OVER</label>
          </div> */}
          <ButtonGroup aria-label="journey-type" className="btn-block">
            <Button
              variant={
                this.props.journeyType === "oneWay" ? "success" : "white"
              }
              className={this.props.journeyType === "oneWay" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this, "oneWay")}
              id="oneWay"
            >
              ONE WAY
            </Button>
            <Button
              variant={
                this.props.journeyType === "roundTrip" ? "success" : "white"
              }
              className={this.props.journeyType === "roundTrip" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this, "roundTrip")}
              id="roundTrip"
            >
              ROUND TRIP
            </Button>
            <Button
              variant={
                this.props.journeyType === "multicity" ? "success" : "white"
              }
              className={this.props.journeyType === "multicity" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this, "multicity")}
              id="multicity"
            >
              MULTI-CITY
            </Button>
            <Button
              variant={
                this.props.journeyType === "stopover" ? "success" : "white"
              }
              className={this.props.journeyType === "stopover" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this, "stopover")}
              id="stopover"
            >
              STOP OVER
            </Button>
          </ButtonGroup>
          {/*
        <ButtonGroup aria-label="journey-type" className="btn-block">
            <Button
              variant={this.props.journeyType==="oneWay" ? "success" : "white"}
              className={this.props.journeyType==="oneWay" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this,"oneWay")}
            >
              ONE WAY
            </Button>
            <Button
              variant={this.props.journeyType==="roundTrip" ? "success" : "white"}
              className={this.props.journeyType==="roundTrip" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this,"roundTrip")}
            >
              ROUND TRIP
            </Button>
            <Button
              variant={this.props.journeyType==="multicity" ? "success" : "white"}
              className={this.props.journeyType==="multicity" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this,"multicity")}
            >
              MULTI-CITY
            </Button>
            <Button
              variant={this.props.journeyType==="stopover" ? "success" : "white"}
              className={this.props.journeyType==="stopover" ? "active" : ""}
              onClick={this.onJourneyTypeSelected.bind(this,"stopover")}
            >
              STOP OVER
            </Button>
          </ButtonGroup>
         */}
        </div>
      </Col>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    journeyType: state.bookingWiget.journyType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeJournyType: (journeyType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEJOURNYTYPE,
        journeyType: journeyType,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JourneyType);
