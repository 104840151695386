import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "react-bootstrap";
import "./CheckIn.css";
import Translation from "i18next";

import { connect } from "react-redux";
import { checkInAction } from "../../redux/actions/check-in/checkInAction";
import CheckInBreadCrumb from "./shared/bread-crumb/CheckInBreadCrumb";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { AirportFullName } from "../../service/shared/AirportFullName";
import ShoppingCart from "./shared/shoppingCart/ShoppingCart";

const CheckInIternary = (props) => {

  const toHourMinute = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + (hours > 1 ? " hrs " : " hr ") +  (minutes !== 0 ? minutes + (minutes > 1 ? " mins" : " min") : "")}</span>;
  };
  let history = useHistory();
  document.title = "Itinerary | Check-in";
  return (
    <>
      <CheckInBreadCrumb />
      <Row className="mx-0 justify-content-center all-passangers-container w-100 wrapper-with-shopping-cart">
        <div className="left-content-container d-flex justify-content-cente row mx-auto mx-xl-0">
          <h2 className="col-12 px-0 mb-3">{Translation.t("your_itinerary")}</h2>
          <div className="check-in-itinerary-list-container col-12">
            {props.reservation.itinerary.itineraryPart[0].segment.map(
              (segment, index) => {
                return (
                  <>
                    {index === 0 ? (
                      <hr className="iternary-parts-separator-first" />
                    ) : (
                      <hr className="iternary-parts-separator" />
                    )}
                    <div className="check-in-itinerary-title-container row mx-0 p-2 p-md-3">
                      <div className="d-flex px-2 check-in-departure-name">
                        {AirportFullName.getCityName(
                          segment.flightDetail[0].departureAirport
                        )}
                      </div>
                      <div className="d-flex px-2 align-self-center">
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          className="check-in-right-arrow"
                        />
                      </div>
                      <div className="d-flex px-2 check-in-arrival-name">
                        {AirportFullName.getCityName(
                          segment.flightDetail[0].arrivalAirport
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row p-2 pt-4">
                        <div className="col-12">
                          <div className="row mx-0">
                            <div className="col-12 d-flex d-md-none mb-3 px-0">
                              <div className="col px-0">
                                <span className="check-in-flight-status-indicator ml-auto">
                                  {
                                    segment.flightDetail[0]
                                      .departureFlightScheduleStatus
                                  }
                                </span>
                              </div>
                              <div className="col px-0 justify-content-end d-flex">
                                <div className="check-in-flight-brand ml-auto">
                                  {segment.fareInfo.brand}
                                </div>
                              </div>
                            </div>
                            <div className="col col-md-2">
                              <div className="row">
                                <div className="check-in-departure-date">
                                  {moment(segment.flightDetail[0].departureTime)
                                    .locale("en")
                                    .format("ddd, MMM DD, YYYY")}
                                </div>
                              </div>
                              <div className="row">
                                <span className="check-in-departure-time">
                                  {moment(segment.flightDetail[0].departureTime)
                                    .locale("en")
                                    .format("HH:mm")}
                                </span>
                              </div>
                              <div className="row">
                                <span className="check-in-departure-code">
                                  {segment.flightDetail[0].departureAirport}
                                </span>
                              </div>
                            </div>
                            <div className="col col-md-1 d-flex align-self-center">
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                className="check-in-right-arrow"
                              />
                            </div>
                            <div className="col col-md-3">
                              <div className="row">
                                <div className="check-in-arrival-date">
                                  {moment(segment.flightDetail[0].arrivalTime)
                                    .locale("en")
                                    .format("ddd, MMM DD, YYYY")}
                                </div>
                              </div>
                              <div className="row">
                                <span className="check-in-arrival-time">
                                  {moment(segment.flightDetail[0].arrivalTime)
                                    .locale("en")
                                    .format("HH:mm")}
                                </span>
                              </div>
                              <div className="row">
                                <span className="check-in-arrival-code">
                                  {segment.flightDetail[0].arrivalAirport}
                                </span>
                              </div>
                            </div>
                            <div className="col-12 col-md-3 mt-3 mt-md-0">
                              <div className="row">
                                <div className="check-in-duration-label">
                                {Translation.t("duration")}
                                </div>
                              </div>
                              <div className="row">
                                <span className="check-in-duration">
                                  {toHourMinute(
                                    moment(
                                      segment.flightDetail[0].arrivalTime
                                    ).diff(
                                      moment(
                                        segment.flightDetail[0].departureTime
                                      ),
                                      "minutes"
                                    )
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="col-2 d-none d-md-block">
                              <div className="row">
                                <span className="check-in-flight-status-indicator ml-auto">
                                  {
                                    segment.flightDetail[0]
                                      .departureFlightScheduleStatus
                                  }
                                </span>
                              </div>
                              <div className="row">
                                <div className="check-in-flight-brand ml-auto">
                                  {segment.fareInfo.brand}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row p-2 mx-0">
                        <div className="col-6">
                          <div className="row">
                            <div className="check-in-boarding-label">{Translation.t("boarding")}</div>
                          </div>
                          <div className="row">
                            <span className="boarding">
                              {moment(segment.flightDetail[0].boardingTime)
                                .locale("en")
                                .format("HH:mm")}
                            </span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="check-in-terminal-gate-label">
                            {Translation.t("terminal_gate")}
                            </div>
                          </div>
                          <div className="row">
                            <span className="check-in-terminal-gate">
                              {(segment.flightDetail[0].departureTerminal === null ? "-" : segment.flightDetail[0].departureTerminal)  +
                                "/" +
                                (segment.flightDetail[0].departureGate === null ? "-" : segment.flightDetail[0].departureGate)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row p-3">
                        <div className="d-flex">
                          {segment.flightDetail[0].operatingAirline === "ET" ? (
                            <img
                              src="//www.gstatic.com/flights/airline_logos/35px/ET.png"
                              alt="ET"
                            />
                          ) : (
                            <img
                              src="/otherAirline_logo.png"
                              alt="other airline logo beside ET"
                              height="40"
                              width="40"
                            />
                          )}
                        </div>
                        <div className="d-flex pl-2 row mx-0">
                          <div className="col-12 mx-0">
                            {" "}
                            <span className="check-in-flight-name">
                              {segment.flightDetail[0].operatingAirline +
                                " " +
                                segment.flightDetail[0].operatingFlightNumber}
                            </span>
                          </div>
                          <div className="col-12 mx-0">
                            {" "}
                            <span className="check-in-flight-equipment">
                              {segment.flightDetail[0].equipment}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
          <h3 className="mt-5 mb-3 col-12 px-0">{Translation.t("hazardous_materials")}</h3>
          <div className="check-in-itinerary-list-container">
            <div className="check-in-itinerary-title-container row mx-0 py-5 px-4">
              <p>
              {Translation.t("do_you_have_any_dangerous_goods_or_hazardous_materials_in_your_bags")}
              </p>
              <div className="row mx-0 justify-content-center">
                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end flammable_liquids"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("flammable_liquids")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end magnetised_materials"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                     {Translation.t("magnetised_materials")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end infectious_substances"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("infectious_substances")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end explosives"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("explosives")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end radioactive_materials"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("radioactive_materials")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end corrosives"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("corrosives")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end toxic_substances"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("toxic_substances")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end gases"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("gases")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end flammable_solids"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("flammable_solids")}
                    </span>
                  </div>
                </div>

                <div className="d-flex hazard-icon-container row">
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex hazard-icons align-self-end organic_peroxides"></div>
                  </div>
                  <div className="row mx-0 w-100 justify-content-center">
                    <span
                      className="d-flex align-self-center"
                      style={{ fontSize: "12px" }}
                    >
                      {Translation.t("organic_peroxides")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12 Container ducp-container sabre-dx-container-check-in-page-button-wrapper pt-4">
            <div className="row justify-content-end">
              <a
                className="justify-content-center bk-submit-btn btn-ethiopian btn d-flex col-12 col-md-4 mr-md-2 py-2 px-3"
                href="/check-in/error"
              >
                <span>{Translation.t("yes_i_am_carrying_these_items")}</span>
              </a>
              <button
                className="justify-content-center bk-submit-btn btn-ethiopian btn d-flex col-12 col-md-4 ml-md-2 py-2 px-3 mt-3 mt-md-0"
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  history.push("/check-in/passengers");
                }}
              >
                {/* redirect to seat */}
                <span>
                {Translation.t("no_i_am_not_carrying_these_items")}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="right-shopping-cart-container">
          {!props.isMedium && <ShoppingCart />}
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pnr: state.checkIn.checkInModel.pnr,
    firstName: state.checkIn.checkInModel.firstName,
    reservation: state.checkIn.reservation,
    lastName: state.checkIn.checkInModel.lastName,
    searchType: state.checkIn.checkInModel.searchType,
    flightNumber: state.checkIn.checkInModel.flightNumber,
    departureAirport: state.checkIn.checkInModel.DepartureAirport,
    arrivingCity: state.checkIn.checkInModel.arrivingCity,
    ticketNumber: state.checkIn.checkInModel.ticketNumber,
    isMedium: state.global.isMedium,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateHidenOption: (value) =>
      dispatch({
        type: checkInAction.UPDATEHIDENOPTION,
        value: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckInIternary);
