import React, { Component } from "react";
import { faAddressCard, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import ManageBookingSummaryPassengerDetail from "./ManageBookingSummaryPassengerDetail";

class ManageBookingSummaryPassenger extends Component {
    constructor(props)
    {
        super(props);
        this.state={
            showPassengerDetail:true
        }
    }
    renderPassengerType(passengerTypeCode)
    {
       let passengerType ="";
        switch(passengerTypeCode)
        {
         case "ADT":
             passengerType= "Adult";
             break;
         case "CHD":
            passengerType= "Child";
            break;  
        case "INF":
                passengerType= "Infant";
                break;
         default:
            passengerType= "";
    
        }
        return <span>{passengerType}</span>;
    }

  render() {
    return (
      <section className="payment-summary-passenger-detail mt-3">
        <div onClick={()=>this.setState({showPassengerDetail:!this.state.showPassengerDetail})} className="py-3">
          <h2>
            <span>Passengers</span>
            <span className="float-right">
              <FontAwesomeIcon icon={this.state.showPassengerDetail?faAngleUp:faAngleDown} />
            </span>
          </h2>
        </div>
        {this.state.showPassengerDetail &&
        <section className="pym-passenger-detai-list">
            {this.props.passengerList.map((passenger,index)=><div className="pym-passenger-detai-list-itm">
        <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Row>
              <Col xs={1}>
                <h3><span className="pym-passenger-count">{index+1}</span></h3>
              </Col>
              <Col >
                <h3 className="pym-passenger-name">{passenger.passengerInfo.type === "ADT" &&<span>{passenger.passengerDetails.prefix}</span>} {passenger.passengerDetails.firstName} {passenger.passengerDetails.lastName}</h3>
                <div>
                  <em>{this.renderPassengerType(passenger.passengerInfo.type)}</em>
                </div>
              </Col>
            </Row>
          </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                            <h6>eTicket : <span className="ticket-number">{this.props.passengerTicketInfo[index].eticketNumber}</span></h6>
          </Col>
        </Row>
       
        </div>
        <ManageBookingSummaryPassengerDetail 
        passengerInfo={passenger.passengerInfo} 
        index={index} 
        contactInfo={this.props.contactInfo}
        tripDetail={this.props.tripDetail}
        />
       
        </div>)}
        
        </section>}
      </section>
    );
  }
}
export default ManageBookingSummaryPassenger;
