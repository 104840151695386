// import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import { Modal,Button } from 'react-bootstrap';
class ExceptionModal extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            showModal:this.props.showModal
        }
    }
    closeModal=()=>
    {
        this.setState({showModal:false})
    }
    render()
    {
  return(<div>
      <Modal show={this.state.showModal} 
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
       
        <Modal.Body><h4 className="exception-msg px-4 py-3">{this.props.exceptionMessage}</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.closeModal} className="close-exception-modal-btn py-0" >
            OK
          </Button>
          
        </Modal.Footer>
      </Modal>
  </div>)
    }
}
export default ExceptionModal;