import React from "react";
import "./BreadCrumb.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRight,
  faChair,
  faCheckCircle,
  faExchangeAlt,
  faLongArrowAltRight,
  faMoneyCheck,
  faPlane,
  faShoppingCart,
  faSuitcase,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Translation from "i18next";
import { Redirect } from "react-router";
import OutsideClickHandler from "react-outside-click-handler";
import ShoppingCart from "../shoppingCart/ShoppingCart";

import moment from "moment";

class CheckInBreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentBreadCrumb: 2,
      redirect: false,
      redirectTo: null,
      showDropDown: false,
      showShoppingCart: false,
      showShoppingCartButton: false,
    };
  }

  componentDidMount = () => {
    var url = window.location.pathname;
    if (url.includes("/itinerary")) {
      this.setState({ currentBreadCrumb: 2, showShoppingCartButton: true });
    } else if (url.includes("/passenger")) {
      this.setState({ currentBreadCrumb: 3, showShoppingCartButton: true });
    } else if (url.includes("/seats")) {
      this.setState({ currentBreadCrumb: 4, showShoppingCartButton: true });
    } else if (url.includes("/ancillary")) {
      this.setState({ currentBreadCrumb: 5, showShoppingCartButton: true });
    }
    console.log(
      url.includes("/itinerary"),
      url.includes("/passenger"),
      url.includes("/seat")
    );
  };

  flightSearch = () => {
    var origin = null;
    var destination = null;
    try {
      this.props.bookingWiget.itineraryPart.forEach((itineraryPart, index) => {
        if (index === 0 && itineraryPart !== null) {
          origin = itineraryPart.params.from.value;
          destination = itineraryPart.params.to.value;
        } else {
          if (itineraryPart.params.to !== null) {
            destination = itineraryPart.params.to.value;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    if (this.props.bookingWiget.journyType === "oneWay") {
      return (
        <span>
          {origin} <FontAwesomeIcon icon={faLongArrowAltRight} size="1x" />{" "}
          {destination}
        </span>
      );
    } else if (this.props.bookingWiget.journyType === "roundTrip") {
      return (
        <span>
          {origin} <FontAwesomeIcon icon={faExchangeAlt} size="1x" />{" "}
          {destination}
        </span>
      );
    } else {
      return (
        <span>
          {origin} {"  "}
          {destination}
        </span>
      );
    }
  };
  getNumberOfPassenge = () => {
    var passengerNo = this.props.reservation.passengers.passenger.length;
    if (passengerNo > 1) {
      return passengerNo + " " + Translation.t("passengers-label");
    } else {
      return passengerNo + " " + Translation.t("passenger-label");
    }
  };
  changePage = (goTo) => {
    if (goTo === "iternary" && this.state.currentBreadCrumb > 2) {
      this.setState({ redirect: true, redirectTo: "/check-in/itinerary" });
    } else if (goTo === "passenger" && this.state.currentBreadCrumb > 3) {
      this.setState({ redirect: true, redirectTo: "/check-in/passengers" });
    } else if (goTo === "seat" && this.state.currentBreadCrumb > 4) {
      this.setState({ redirect: true, redirectTo: "/check-in/seats" });
    } else if (goTo === "extra" && this.state.currentBreadCrumb > 5) {
      this.setState({ redirect: true, redirectTo: "/check-in/ancillary" });
    }
  };
  renderActivePage(page) {
    switch (page) {
      case 2:
        return (
          <div className="bread-crumb-mobile-item">
            <span>
              <FontAwesomeIcon icon={faPlane} size="1x" />
            </span>
            {/* {"  "} {this.flightSearch()} */}
            <span className="float-right">
              {this.state.currentBreadCrumb - 1} of 5{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            <div className="select-flight">
              {Translation.t("select_flight")}{" "}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faUser} size="1x" />
            {"  "}
            {Translation.t("passenger-label")}
            <span className="float-right">
              {this.state.currentBreadCrumb - 1} of 5{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            <div id="passenger-text"> {this.getNumberOfPassenge()}</div>
          </div>
        );
      case 4:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faChair} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb - 1} of 5{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Seat Selection
          </div>
        );
      case 5:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faSuitcase} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb - 1} of 5{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Extras
          </div>
        );
      case 6:
        return (
          <div className="bread-crumb-mobile-item">
            <span className="float-right">
              {this.state.currentBreadCrumb - 1} of 5
            </span>
            <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Payment
          </div>
        );
      default:
        return null;
    }
  }
  renderBreadcrumbMediumScreen() {
    return (
      <>
        <div className="parent-div">
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ showDropDown: false });
            }}
          >
            <div
              className="bread-crumb-mobile-dropdown bread-crumb-mobile-dropdown-check-in"
              onClick={() => {
                this.setState({ showDropDown: !this.state.showDropDown });
              }}
            >
              {this.renderActivePage(this.state.currentBreadCrumb)}

              <div
                className="bread-crumb-mobile-dropdown-list"
                style={
                  this.state.showDropDown
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="bread-crumb-mobile-item-md">
                  <div
                    className={
                      this.state.currentBreadCrumb === 2
                        ? "breadcrumb-custom-current px-0 py-2"
                        : this.state.currentBreadCrumb > 2
                        ? "breadcrumb-custom-disabled previsous px-0 py-2"
                        : "breadcrumb-custom-disabled previsous px-0 py-2"
                    }
                    onClick={this.changePage.bind(this, "iternary")}
                  >
                    <div className="row mx-0 d-flex">
                      <div className="col-1 d-flex mr-2 align-items-center">
                        <span>
                          {this.state.currentBreadCrumb > 2 ? (
                            <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                          ) : (
                            <FontAwesomeIcon icon={faPlane} size="1x" />
                          )}
                        </span>
                      </div>
                      <div className="row mx-0 d-flex">
                        {this.props.reservation.itinerary.itineraryPart[0]
                          .segment.length > 1 ? (
                          <>
                            <div className="flex-column mx-0">
                              <span className="d-flex bread-crumb-check-in-label">
                                Multiple Flights
                              </span>
                              <span className="d-flex bread-crumb-check-in-value">
                                Staring From{" "}
                                {moment(
                                  this.props.reservation.itinerary
                                    .itineraryPart[0].segment.departureTime
                                )
                                  .locale("en")
                                  .format("ddd, MMM DD, YYYY")}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex-column mx-0">
                              <span className="d-flex bread-crumb-check-in-label">
                                <span className="pr-1">
                                  {
                                    this.props.reservation.itinerary
                                      .itineraryPart[0].segment[0]
                                      .flightDetail[0].departureAirport
                                  }
                                </span>{" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="align-self-center"
                                  size="1x"
                                />
                                <span className="pl-1">
                                  {
                                    this.props.reservation.itinerary
                                      .itineraryPart[0].segment[0]
                                      .flightDetail[0].arrivalAirport
                                  }
                                </span>
                              </span>
                              <span className="d-flex bread-crumb-check-in-value">
                                Staring From{" "}
                                {moment(
                                  this.props.reservation.itinerary
                                    .itineraryPart[0].segment.departureTime
                                )
                                  .locale("en")
                                  .format("ddd, MMM DD, YYYY")}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <div
                    className={
                      this.state.currentBreadCrumb === 3
                        ? "breadcrumb-custom-current px-0 py-2"
                        : this.state.currentBreadCrumb > 3
                        ? "breadcrumb-custom-disabled previsous px-0 py-2"
                        : "breadcrumb-custom-disabled btn-not-allowd px-0 py-2"
                    }
                    onClick={this.changePage.bind(this, "passenger")}
                  >
                    <div className="row mx-0">
                      <span className="col-1 d-flex mr-2 align-items-center">
                        {this.state.currentBreadCrumb > 3 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faUser} size="1x" />
                        )}
                      </span>
                      <div className="flex-colum">
                        <span className="d-flex bread-crumb-check-in-label">
                        {" " +
                        (this.countSelectedUsers("ADULT") !== 0
                          ? this.countSelectedUsers("ADULT") + " Adult"
                          : " ") +
                        (this.countSelectedUsers("CHILD") !== 0 &&
                        this.countSelectedUsers("ADULT") !== 0 &&
                        this.countSelectedUsers("INFANT") !== 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("CHILD") !== 0 &&
                        this.countSelectedUsers("ADULT") !== 0 &&
                        this.countSelectedUsers("INFANT") === 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("CHILD") !== 0
                          ? this.countSelectedUsers("CHILD") + " Child"
                          : " ") +
                          
                        ((this.countSelectedUsers("CHILD") !== 0 ||
                        this.countSelectedUsers("ADULT") !== 0 ) &&
                        this.countSelectedUsers("INFANT") !== 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("INFANT") !== 0
                          ? this.countSelectedUsers("INFANT") + " Infant"
                          : " ") +
                        " Selected"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <div
                    className={
                      this.state.currentBreadCrumb === 4
                        ? "breadcrumb-custom-current px-0 py-2"
                        : this.state.currentBreadCrumb > 4
                        ? "breadcrumb-custom-disabled previsous px-0 py-2"
                        : "breadcrumb-custom-disabled btn-not-allowd px-0 py-2"
                    }
                    onClick={this.changePage.bind(this, "seat")}
                  >
                    <div className="row mx-0">
                      <span className="col-1 d-flex mr-2 align-items-center">
                        {this.state.currentBreadCrumb > 4 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faChair} size="1x" />
                        )}
                      </span>
                      <div className="flex-colum">
                        <span className="d-flex bread-crumb-check-in-label">
                          Seats
                        </span>
                        <span className="d-flex bread-crumb-check-in-value">
                          {this.props.reservation.passengers.passenger.filter(
                            (passenger) =>
                              passenger.type.value !== "INFANT" &&
                              passenger.id in this.props.activePassengers
                          ).length +
                            " of " +
                            this.props.reservation.itinerary.itineraryPart[0]
                              .segment.length *
                              this.props.reservation.passengers.passenger.filter(
                                (passenger) => passenger.type.value !== "INFANT"
                              ).length +
                            " Seats Selected"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <div
                    className={
                      this.state.currentBreadCrumb === 5
                        ? "breadcrumb-custom-current px-0 py-3"
                        : this.state.currentBreadCrumb > 5
                        ? "breadcrumb-custom-disabled previsous px-0 py-3"
                        : "breadcrumb-custom-disabled btn-not-allowd px-0 py-3"
                    }
                    onClick={this.changePage.bind(this, "extra")}
                  >
                    <div className="row mx-0">
                      <span className="col-1 d-flex mr-2 align-items-center">
                        {this.state.currentBreadCrumb > 5 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faSuitcase} size="1x" />
                        )}
                      </span>
                      <div className="flex-colum">
                        <span className="d-flex bread-crumb-check-in-label">
                          Extras
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <div
                    className={
                      this.state.currentBreadCrumb === 6
                        ? "breadcrumb-custom-current px-0 py-3"
                        : this.state.currentBreadCrumb > 6
                        ? "breadcrumb-custom-disabled previsous px-0 py-3"
                        : "breadcrumb-custom-disabled btn-not-allowd px-0 py-3"
                    }
                  >
                    <div className="row mx-0">
                      <span className="col-1 d-flex mr-2 align-items-center">
                        {this.state.currentBreadCrumb > 6 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
                        )}
                      </span>
                      <div className="flex-colum">
                        <span className="d-flex bread-crumb-check-in-label">
                          Payment
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OutsideClickHandler>

          <span className="float-right shopping-cart-for-checkin edit-flight-button mt-4">
            <button
              onClick={() =>
                this.setState({
                  showShoppingCart: !this.state.showShoppingCart,
                })
              }
              className="btn btn-md btn-success mx-1"
            >
              <FontAwesomeIcon icon={faShoppingCart} />{" "}
            </button>
          </span>
        </div>
        {this.props.isMedium && this.state.showShoppingCartButton ? (
          <ShoppingCart showShoppingCart={this.state.showShoppingCart} />
        ) : null}
      </>
    );
  }
  renderBreadcrumbLargeScreen() {
    return (
      <>
        <div className="breadcrumb-Card">
          <ul className="breadcrumb-custom">
            <li>
              <span className="breadcrumb-custom-home">
                <p className="text-white">.</p>
              </span>
            </li>
            <li>
              <a
                style={{ paddingLeft: "33px" }}
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 2
                    ? "breadcrumb-custom-current"
                    : "breadcrumb-custom-disabled previsous"
                }
                // onMouseOver={() => {
                //   var textSelect = document.getElementById("select-flight-lg");
                //   textSelect.innerHTML = Translation.t("review_flight");
                // }}
                onClick={this.changePage.bind(this, "iternary")}
                // onMouseOut={() => {
                //   var textSelect = document.getElementById("select-flight-lg");
                //   textSelect.innerHTML = Translation.t("select_flight");
                // }}
              >
                <div className="row mx-0 d-flex">
                  <div className="col-1 d-flex mr-2">
                    <span>
                      {this.state.currentBreadCrumb > 2 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faPlane} size="1x" />
                      )}
                    </span>
                  </div>
                  <div className="row mx-0 d-flex">
                    {this.props.reservation.itinerary.itineraryPart[0].segment
                      .length > 1 ? (
                      <>
                        <div className="flex-column mx-0">
                          <span className="d-flex bread-crumb-check-in-label">
                            Multiple Flights
                          </span>
                          <span className="d-flex bread-crumb-check-in-value">
                            Staring From{" "}
                            {moment(
                              this.props.reservation.itinerary.itineraryPart[0]
                                .segment.departureTime
                            )
                              .locale("en")
                              .format("ddd, MMM DD, YYYY")}
                          </span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-column mx-0">
                          <span className="d-flex bread-crumb-check-in-label">
                            <span className="pr-1">
                              {
                                this.props.reservation.itinerary
                                  .itineraryPart[0].segment[0].flightDetail[0]
                                  .departureAirport
                              }
                            </span>{" "}
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="align-self-center"
                              size="1x"
                            />
                            <span className="pl-1">
                              {
                                this.props.reservation.itinerary
                                  .itineraryPart[0].segment[0].flightDetail[0]
                                  .arrivalAirport
                              }
                            </span>
                          </span>
                          <span className="d-flex bread-crumb-check-in-value">
                            Staring From{" "}
                            {moment(
                              this.props.reservation.itinerary.itineraryPart[0]
                                .segment.departureTime
                            )
                              .locale("en")
                              .format("ddd, MMM DD, YYYY")}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 3
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 3
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onClick={this.changePage.bind(this, "passenger")}
              >
                <div className="row">
                  <span className="d-flex">
                    {this.state.currentBreadCrumb > 3 ? (
                      <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                    ) : (
                      <FontAwesomeIcon icon={faUser} size="1x" />
                    )}
                  </span>
                  <div className="flex-colum px-3">
                    <span className="d-flex bread-crumb-check-in-label">
                      {" " + this.getNumberOfPassenge()}
                    </span>
                    <span className="d-flex bread-crumb-check-in-value">
                      {" " +
                        (this.countSelectedUsers("ADULT") !== 0
                          ? this.countSelectedUsers("ADULT") + " Adult"
                          : " ") +
                        (this.countSelectedUsers("CHILD") !== 0 &&
                        this.countSelectedUsers("ADULT") !== 0 &&
                        this.countSelectedUsers("INFANT") !== 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("CHILD") !== 0 &&
                        this.countSelectedUsers("ADULT") !== 0 &&
                        this.countSelectedUsers("INFANT") === 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("CHILD") !== 0
                          ? this.countSelectedUsers("CHILD") + " Child"
                          : " ") +
                          
                        ((this.countSelectedUsers("CHILD") !== 0 ||
                        this.countSelectedUsers("ADULT") !== 0 ) &&
                        this.countSelectedUsers("INFANT") !== 0
                          ? ", "
                          : "") +
                        (this.countSelectedUsers("INFANT") !== 0
                          ? this.countSelectedUsers("INFANT") + " Infant"
                          : " ") +
                        " Selected"}
                    </span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 4
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 4
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onClick={this.changePage.bind(this, "seat")}
              >
                <div className="row">
                  <span className="d-flex">
                    {this.state.currentBreadCrumb > 4 ? (
                      <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                    ) : (
                      <FontAwesomeIcon icon={faChair} size="1x" />
                    )}
                  </span>
                  <div className="flex-colum px-3">
                    <span className="d-flex bread-crumb-check-in-label">
                      Seats
                    </span>
                    <span className="d-flex bread-crumb-check-in-value">
                      {this.props.reservation.passengers.passenger.filter(
                        (passenger) =>
                          passenger.type.value !== "INFANT" &&
                          passenger.id in this.props.activePassengers
                      ).length +
                        " of " +
                        this.props.reservation.itinerary.itineraryPart[0]
                          .segment.length *
                          this.props.reservation.passengers.passenger.filter(
                            (passenger) =>
                              passenger.type.value !== "INFANT" &&
                              passenger.id in this.props.activePassengers
                          ).length +
                        " Seats Selected"}
                    </span>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 5
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 5
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onClick={this.changePage.bind(this, "extra")}
              >
                <span>
                  {this.state.currentBreadCrumb > 5 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faSuitcase} size="1x" />
                  )}
                </span>
                {"  "}
                Extras
                <p id="extras-text-lg"></p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 6
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 6
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
              >
                <span>
                  {this.state.currentBreadCrumb > 6 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faSuitcase} size="1x" />
                  )}
                </span>{" "}
                {"  "}
                Payment
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }

  countSelectedUsers(type) {
    var temp = { ADULT: 0, CHILD: 0, INFANT: 0 };
    Object.keys(this.props.activePassengers).map((key) => {
      if (this.props.activePassengers[key].type.value === "ADULT")
        temp.ADULT = temp.ADULT + 1;
      if (this.props.activePassengers[key].type.value === "CHILD")
        temp.CHILD = temp.CHILD + 1;
      if (this.props.activePassengers[key].type.value === "INFANT")
        temp.INFANT = temp.INFANT + 1;
      return null;
    });

    if (type === "ADULT") return temp.ADULT;
    if (type === "INFANT") return temp.INFANT;
    return temp.CHILD;
  }

  toggleShoppingCart = () => {
    this.setState({ showShoppingCart: !this.state.showShoppingCart });
  };
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      );
    }
    return (
      <>
        {this.renderBreadcrumbMediumScreen()}
        {this.renderBreadcrumbLargeScreen()}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookingWiget: state.bookingWiget,
    passenger: state.bookingWiget.passengerInfo,
    isMobile: state.global.isMobile,
    isMedium: state.global.isMedium,
    reservation: state.checkIn.reservation,
    activePassengers: state.checkIn.activePassengers,
  };
};
export default connect(mapStateToProps, null)(CheckInBreadCrumb);
