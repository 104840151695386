import HttpService from "../shared/HttpService" //"../../shared/HttpService";

export default class ExchangeSeatSelectionService {
  updateHideOptions = (state, value) => {
    state.checkInModel.searchType = value;
    return state;
  };
  validationCheckModel = () => {};

//   initializeSeats = (data, token) => {
//     let relativeUrl = "/etibe-exchangeapi/V1.0/Seat/Map";
//     var searchRequest = {
//       Pnr: "GRYUXK",
//       LastName: "last",
//     };

//     searchRequest.Pnr = data.pnr;
//     searchRequest.LastName = data.lastName;

//     return HttpService.postServiceExchange(searchRequest, relativeUrl, token);
//   };

  getSeatMap(cookieSabreDataRequest, token) {
    let relativeUrl = "/etibe-exchangeapi/V1.0/Seat/Map";
    // data.checkInSabreDataRequest = checkInSabreDataRequest;

   
    // searchRequest.passengers = data.passengerIds;
    // searchRequest.segmentIds = data.segmentIds;

    return HttpService.postServiceExchange(cookieSabreDataRequest, relativeUrl, token);
  }

  selectSeat(data, token, cookieSabreDataRequest) {
    let relativeUrl = "/etibe-exchangeapi/V1.0/Seat/Select";

    var searchRequest = {
      seatOperations: [
        {
          messages: null,
          seatCode: "11C",
          selectedSegment: {
            origin: "ADD",
            destination: "DXB",
            departure: "2021-05-25T21:45:00",
          },
          passengerIndex: 1,
        },
      ],
    };
    searchRequest.cookieSabreDataRequest = cookieSabreDataRequest;

    searchRequest.seatOperations[0].seatCode = data.seatCode;
    searchRequest.seatOperations[0].passengerIndex = data.passengerIndex;
    searchRequest.seatOperations[0].selectedSegment.origin = data.origin;
    searchRequest.seatOperations[0].selectedSegment.destination =
      data.destination;
    searchRequest.seatOperations[0].selectedSegment.departure = data.departure;

    // searchRequest.selectSeatRequests[0].passengerFlightId = data.passengerFlightId;
    // searchRequest.selectSeatRequests[0].seatNumber = data.seatNumber;
    return HttpService.postServiceExchange(searchRequest, relativeUrl, token);
  }
}
