export class ExchangeSearchRequest {
    constructor()
    {
        this.searchRequest ={
            "searchCriteria": {
                "cabinClass": 3,
                "currency": null,
                "awardBooking": false,
                "searchType": 1,
                "promoCodes": [],
                "itineraryParts": [
                   
                ],
                "passengers": null,
                "pointOfSale": null,
                "trendIndicator": null,
                "preferredOperatingCarrier": null
            },
            "exchangeType": "both",
            "segmentsToExchange": null,
            "cookieSabreDataRequest": null
        }
    }
}

export class ExchangeSearchItineraryPart{
    constructor()
    {
    this.itineraryPart= {
        "from": {
            "code": "ADD",
            "useNearbyLocations": false
        },
        "to": {
            "code": "DXB",
            "useNearbyLocations": false
        },
        "when": {
            "date": "2021-06-25"
        },
        "selectedOfferRef": null,
        "plusMinusDays": null
    };
    }
}