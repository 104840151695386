import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "../../../booking/flight-selection/FlightSelection.css";
import Translation from "i18next";
import ExchangeItineraryBrandMob from "./ExchangeItineraryBrandMob";

class ExchangeItineraryPartOfferMob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFareOptions: false,
      itineraryPartBrand: this.props.itineraryPartBrand,
    };
  }
  thousands_separators = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  // renderStops(segments,stops)
  // {
  //   if(stops > 0)
  //   {
  //   return(
  //     <div>
  //     {
  //       segments.map((segment,key)=> <span key={key}>{(key !== 0) &&<span>{stops} <span>{stops > 1?"Stops":"Stop"}</span> {segment.origin}</span>}</span>)
  //     }
  //     </div>
  //   );
  //   }
  //   else{
  //     return(<span>Non-stop</span>)
  //   }
  // }
  renderStops(segments, stops) {
    if (stops > 0) {
      if (segments.length > 1) {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
            {segments.map((segment, index) => (
              <span key={index}>
                {segment.flight.stopAirports.map((airport, index2) => (
                  <span key={index2}>{" " + airport.airport}</span>
                ))}
              </span>
            ))}
          </span>
        );
      }
    } else {
      return <span>{Translation.t("non-stop")}</span>;
    }
  }
  showFareOptions = () => {
    this.setState({ showFareOptions: true });
  };

  hidFareOptions = () => {
    this.setState({ showFareOptions: false });
  };
  render() {
    return (
      <div
        className={
          "itinerary-part-offer-mob bg-white mt-" + this.props.marginTop
        }
      >
        {!this.state.showFareOptions && (
          <div onClick={() => this.showFareOptions()}>
            <div className="operating-flight-mob py-1">
              <Row>
                <Col className="mx-3">
                  <span>{this.props.index + 1}</span>
                </Col>
                <Col>
                  <div className="text-right pr-2 py-1">
                    <span></span>
                  </div>
                </Col>
              </Row>
            </div>
            {this.props.itineraryPartBrand.itineraryPart.map(
              (itinerary, key) => (
                <div className="pt-3" key={key}>
                  {key > 0 && <hr className="py-2" />}

                  <div className="text-center flight-city-duration-mob">
                    <Row>
                      <Col>
                        <span className="font-weight-bold">
                          {itinerary.segments[0].origin}
                        </span>
                      </Col>
                      <Col xs={6}>
                        <span>{this.parseTime(itinerary.totalDuration)}</span>
                      </Col>
                      <Col>
                        <span className="font-weight-bold">
                          {
                            itinerary.segments[itinerary.segments.length - 1]
                              .destination
                          }
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center py-2">
                    <Row>
                      <Col>
                        <span className="flight-time-mob">
                          {this.parseDate(itinerary.segments[0].departure)}
                        </span>
                      </Col>
                      <Col xs={2} sm={3} md={2} className="m-0 p-0">
                        <FontAwesomeIcon icon={faPlaneDeparture} />
                        {/* <span className="flight-separtor-dot"></span> */}
                      </Col>
                      <Col>
                        <span className="flight-time-mob">
                          {this.parseDate(
                            itinerary.segments[itinerary.segments.length - 1]
                              .arrival
                          )}
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="text-center">
                    <Row>
                      <Col xs={3} sm={3} md={3}>
                        <span></span>
                      </Col>
                      <Col>
                        <span>
                          {this.renderStops(
                            itinerary.segments,
                            itinerary.stops
                          )}
                        </span>
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        <span></span>
                      </Col>
                    </Row>
                  </div>
                </div>
              )
            )}
            <div className="text-center fly-price-info-mob py-1">
              <Row>
                <Col></Col>
                {this.props.itineraryPartBrand.echeepPrice != "N/A" ? (
                  <Col xs={12} sm={12} className="float-right">
                    <div className="text-right py-1">
                      <span className="fly-price-mob">
                        {Translation.t("from")}{" "}
                        <span>
                          {this.thousands_separators(
                            this.props.itineraryPartBrand.echeepPrice
                          )}
                        </span>{" "}
                      </span>
                    </div>
                  </Col>
                ) : (
                  <Col xs={12} sm={12} className="float-right">
                    <div className="text-right py-1">
                      <span className="fly-price-mob">
                        {Translation.t("from")}{" "}
                        <span>
                          {this.thousands_separators(
                            this.props.itineraryPartBrand.bcheepPrice
                          )}
                        </span>{" "}
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}
        {this.state.showFareOptions && (
          <div id="HEADER">
            <ExchangeItineraryBrandMob
              onCloseClicked={this.hidFareOptions}
              selectFlight={this.props.selectFlight}
              brandOffers={this.props.itineraryPartBrand.brandOffers}
              echeepPrice={this.props.itineraryPartBrand.echeepPrice}
              bcheepPrice={this.props.itineraryPartBrand.bcheepPrice}
              itineraryPart={this.props.itineraryPartBrand.itineraryPart}
            />
          </div>
        )}
      </div>
    );
  }
}
export default ExchangeItineraryPartOfferMob;
