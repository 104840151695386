import React, { Component } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translation from "i18next";
import ManageBookingSummaryRemark from "./ManageBookingSummaryRemark";

class ManageBookingSummaryHeader extends Component {
    constructor(props)
    {
        super(props);
    }
  render() {
    return (
      <section >
          <div className="payment-summary-header">
          <h2>
          <span className="pr-3 check-icon">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>

          <span>
          {Translation.t("your_trip_is_booked_confirmation_code")} <span>{this.props.pnr}</span>. {Translation.t("trip_summary_information")}
          </span>
        </h2>
          </div>
      
            { this.props.type != null && <ManageBookingSummaryRemark />}
      </section>
    );
  }
}
export default ManageBookingSummaryHeader;
