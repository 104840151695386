import React, { Component } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Translation from "i18next";
import { connect } from "react-redux";
import { HoldReservationRequestObj } from '../../../../../model/payment/HoldReservationRequestObj';
import SubmitPayment from '../../SubmitPayment';
import HttpService from '../../../../../service/shared/HttpService';
import ProgressSimpler from '../../../../shared/progress-simple/ProgressSimpler';
import { Redirect } from 'react-router';
import Loading from '../../../seat-selection/Loading';
class HoldReservation extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            isLoading:false,
            redirect:false,
            responseData:null
        }
        this.holdReservationRequestObj = new HoldReservationRequestObj();
    }
    handleSubmit = (event) => {
        event.preventDefault();   
        this.holdReservationRequestObj.requestObj.DepartureDate = this.props.searchFlight.searchResult.data.searchResponse.brandedResults.itineraryPartBrands[0][0].departure;
        this.holdReservationRequestObj.requestObj.cookieSabreDataRequest = this.props.searchFlight.searchResult.data.cookieSabreDataRequest;
        this.setState({ isLoading: true });
        HttpService.postService(
            this.holdReservationRequestObj.requestObj,
          "/Purchase/HoldBNPL",
          this.props.searchFlight.token
        ).then((response) => {
          

          this.setState({ isLoading: false,redirect:true,responseData:response.data});
           
        });
        
      };
    render()
    {
        //if (this.state.isLoading) {
        //    return (
        //      <div>
        //        <ProgressSimpler />
        //      </div>
        //    );
        //  }
          if (this.state.redirect) {
            return (
              <Redirect
                to={{
                  pathname: "/payment-summary",
                        state: this.state.responseData,
                        type:"hold"
                }}
              />
            );
          }
        return (<div className="container hold_reservation_container bg-white pt-4">
            {this.state.isLoading?<Loading />:null}
            <div className="pb-3">
            <h4>{Translation.t("pay_at_airport_title")}</h4>
            <div>
            {Translation.t("pay_at_airport_info")}
            </div>
            <div>
            <strong>{Translation.t("pay_at_airport_note")}</strong> {Translation.t("pay_at_airport_remark")}
            </div>
            </div>
            <div>
            <Form onSubmit={this.handleSubmit}>
                    <Row>
                    <Col>
              <div className="py-4">
                <SubmitPayment />
              </div>
            </Col>
                    </Row>
                </Form>
            </div>
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {
      searchFlight: state.searchFlight,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
     
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(HoldReservation);