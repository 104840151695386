import React, { Component } from 'react';
import parse from 'html-react-parser';

import $ from 'jquery';
import Loading from '../../../seat-selection/Loading';

class CreditCardRedirect extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
        let redirectPath = this.props.location.state.redirectToPath;
        redirectPath.replace("><", ">\n<");
        //  var data = html;
        //  $("#unionpayview").html(data);
        $("html").append(redirectPath);
    }
    render() {
        
        return (<div className="container text-center"><Loading /></div>);
    }
}
export default CreditCardRedirect;