import { combineReducers, createStore } from 'redux'
import { persistReducer,persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import storageSession from "redux-persist/lib/storage/session";
import  globalReducer from '../reducer/shared/globalReducer';
import  bookingWigetReducers  from '../reducer/booking/bookingWidgetReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import recentSearchReducer from '../reducer/booking/recenteSearchReducer';
import searchFlightReducer from '../reducer/booking/searchFlightReducer';
import shoopingCartReducer from '../reducer/booking/shooppingCartReducer';
import passengerhReducer from '../reducer/booking/passengerReducer';
import flightSelectionReducer from '../reducer/booking/flightSelectionReducer';
import filteredFlightsReducer from '../reducer/booking/filteredFlightsReducer';
import seatSelectionReducer from '../reducer/booking/seatSelectionReducer';
// const rootPersistConfig = {
//   key: 'root',
//   storage: storageSession,
// }

import ancillaryReducer from '../reducer/booking/ancillaryReducer';
import paymentReducer from '../reducer/booking/paymentReducer';
import matrixReducer from '../reducer/booking/matrixReducer';
import accountReducer from '../reducer/manage-my-booking/accountReducer';
import checkInReducer from '../reducer/check-in/checkInReducer';
import flightScheduleReducer from '../reducer/manage-my-booking/flightScheduleReducer';
import flightStatusReducer from '../reducer/manage-my-booking/flightStatusReducer';

import manageAncillaryReducer from '../reducer/manage-my-booking/manageAncillaryReducer';

import exchangeIntialReducer from '../reducer/exchange/exchangeReducer';
import exchangeReducer from '../reducer/exchange/exchangeReducer';
import manageMyBookingReducer from '../reducer/manage-my-booking/manageMyBookingReducer';

const bookingWigetPersistConfig = {
    key : 'bookingWiget',
    storage: storageSession,
    blacklist:['validation',"promoCode"]
}
const globalPersistConfig = {
    key : 'global',
    storage: storage,
    blacklist : ["isMobile"]
}
const recentSearchPersistConfig = {
    key : 'RecentSearch',
    storage: storage,
}
const searchFlightPersistConfig = {
    key : 'FlightSearch',
    storage: storageSession,
}
const shoppingCartConfig = {
    key : 'ShoppingCart',
    storage: storageSession,
}
const passengerInfoConfig={
    key:"passengerInfo",
    storage: storageSession
}
const flightSelectionConfig = {
    key : 'flightSelection',
    storage: storageSession,
}
const filteredFlights = {
    key : 'filteredFlights',
    storage: storageSession,
}
const ancillaryConfig = {
    key : 'ancillaryList',
    storage: storageSession,
}
const paymentConfig = {
    key : 'countryList',
    storage: storageSession,
}
const matrixConfig = {
    key : 'matrix',
    storage: storageSession,
}
const seatSelectionConfig = {
    key : 'SeatSelection',
    storage: storageSession,
}
const accountConfig={
    key:"account",
    storage: storage,
}
const checkInConfig={
    key:"checkIn",
    storage:storageSession
}
const flightScheduleConfig={
    key:"flightSchedule",
    storage:storageSession
}
const flightStatusConfig={
    key:"flightStatus",
    storage:storageSession
}
const manageAncillaryConfig={
    key:"mangeAncillary",
    storage:storageSession
}
const exchangeConfig={
    key:"exchange",
    storage:storageSession
}
const manageMyBookingConfig={
    key:"manageMyBooking",
    storage:storageSession
}
const rootReducer = combineReducers({
  bookingWiget: persistReducer(bookingWigetPersistConfig, bookingWigetReducers),
  global: persistReducer(globalPersistConfig, globalReducer),
  recentSearch: persistReducer(recentSearchPersistConfig, recentSearchReducer),
  searchFlight: persistReducer(searchFlightPersistConfig, searchFlightReducer),
  shoppingCart: persistReducer(shoppingCartConfig, shoopingCartReducer),
  passenger: persistReducer(passengerInfoConfig, passengerhReducer),
  flightSelection: persistReducer(
    flightSelectionConfig,
    flightSelectionReducer
  ),
  filteredFlights: persistReducer(filteredFlights, filteredFlightsReducer),
  ancillary: persistReducer(ancillaryConfig, ancillaryReducer),
  payment: persistReducer(paymentConfig, paymentReducer),
  matrix: persistReducer(matrixConfig, matrixReducer),
  ancillary: persistReducer(ancillaryConfig, ancillaryReducer),
  seatSelection: persistReducer(seatSelectionConfig, seatSelectionReducer),
  filteredFlights: persistReducer(filteredFlights, filteredFlightsReducer),
  account: persistReducer(accountConfig, accountReducer),
  manageAncillary: persistReducer(
    manageAncillaryConfig,
    manageAncillaryReducer
  ),
  checkIn: persistReducer(checkInConfig, checkInReducer),
  flightSchedule: persistReducer(flightScheduleConfig, flightScheduleReducer),
  flightStatus: persistReducer(flightStatusConfig, flightStatusReducer),
  checkIn: persistReducer(checkInConfig, checkInReducer),
  exchange: persistReducer(exchangeConfig, exchangeReducer),
  manageMyBooking: persistReducer(
    manageMyBookingConfig,
    manageMyBookingReducer
  ),
});
export const store = createStore(rootReducer,composeWithDevTools());
export const persistor = persistStore(store);