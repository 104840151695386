import React, { Component } from "react";
import { connect } from "react-redux";
import "../../booking/payment/Payment.css";
import { paymentAction } from "../../../redux/actions/booking/paymentAction";

import HttpService from "../../../service/shared/HttpService";
import TravelBank from "../../booking/payment/payment-options/travel-bank/TravelBank";
import PaymentOption from "../../booking/payment/payment-options/PaymentOption";
import Loading from "../../booking/seat-selection/Loading";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import { CountryService } from "../../../service/shared/CountryService";
import ExchangeCreditCard from "./payment-options/credit-card/ExchangeCreditCard";
import ExchangeHoldReservation from "./payment-options/hold-reservation/ExchangeHoldReservation";
import ExchangeFloCashPaymentOptions from "./payment-options/flocash/ExchangeFloCashPaymentOptions";
import ExchangeUnionPay from "./payment-options/union-pay/ExchangeUnionPay";
class ExchangePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCardOptions: [],
      flocashOptions: [],
      priceInfo: null,
      unionPay: null,
      travelBank: null,
      holdReservation: null,
      selectedPaymentOption: "",
      isLoading: false,
    };
    this.setSelectedPaymentOption = this.setSelectedPaymentOption.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (this.props.countryList.length === 0) {
      this.getCountryList(this.props.searchFlight.token);
    }
    this.getPaymentOptions();
    
  }
  getPaymentOptions = () => {
    
    let country = AirportFullName.getCountryName(
      this.props.exchangeFlightSearchResult.data.searchResponse.brandedResults
        .itineraryPartBrands[0][0].itineraryPart[0].segments[0].origin
    );

    let countryCode = CountryService.getCountryCode(
      country,
      this.props.countryList
    );

    let airportCode =
      this.props.exchangeFlightSearchResult.data.searchResponse.brandedResults
        .itineraryPartBrands[0][0].itineraryPart[0].segments[0].origin;
    let departureDate =
      this.props.exchangeFlightSearchResult.data.searchResponse.brandedResults
        .itineraryPartBrands[0][0].departure;
    let cookieSabreDataRequest =
      this.props.exchangeFlightSearchResult.data.cookieSabreDataRequest;
    let token = this.props.searchFlight.token;
    let requestObject = {
      IsToday: false,
      IsFlightWithIn48Hours: false,
      CountryCode: countryCode,
      AirportCode: airportCode,
      DepartureDateTime: departureDate,
      //"PromoCode": "DOM71",
      paymentType: 0,
      cookieSabreDataRequest: cookieSabreDataRequest,
    };
    this.setState({ isLoading: true });
    HttpService.postServiceExchange(
      requestObject,
      "/etibe-exchangeapi/V1.0/PaymentOption/GetPaymentOption",
      token
    ).then((response) => {
      
      this.setState({ isLoading: false });
      this.constructPaymentOptioins(response.data.payment.paymentProducts);
      
    });
  };

  getCountryList(token) {
    
    HttpService.getCountryListService(
      "/_masterdatasvc-masterdata/api/Country/list?memoryCachRefresh=true",
      "",
      token
    ).then((response) => {
      this.props.saveCountryList(response.data.lstCountriesDetail);
      
    });
  }
  setSelectedPaymentOption(option) {
    
    this.setState({ selectedPaymentOption: option });
  }
  renderPaymentOption() {
    if (this.state.selectedPaymentOption === "AFOP") {
      return (
        <ExchangeFloCashPaymentOptions
          flocashOptions={this.state.flocashOptions}
          amount={this.state.priceInfo}
        />
      );
    }
    if (this.state.selectedPaymentOption === "TB") {
      // return(<TravelBank />);
      return <TravelBank />;
    }
    if (this.state.selectedPaymentOption === "CC") {
      return (
        <ExchangeCreditCard
          creditCardOptions={this.state.creditCardOptions}
          countryList={this.props.countryList}
        />
      );
    }
    if (this.state.selectedPaymentOption === "UP") {
      return <ExchangeUnionPay unionPay={this.state.unionPay} />;
    }
    if (this.state.selectedPaymentOption === "HR") {
      return <ExchangeHoldReservation amount={this.state.priceInfo} />;
    }
  }
  constructPaymentOptioins(paymentOptions) {
    
    this.setState({
      creditCardOptions: [],
      flocashOptions: [],
      unionPay: null,
    });
    let creditCardOptionList = [];
    let flocashOptionList = [];

    paymentOptions.forEach((paymentOption) => {
      if (paymentOption.paymentInfo.paymentType == "AFOP") {
        paymentOption.paymentInfo.afopDetails.forEach((afop) => {
          if (afop.fopCode === "BR") {
            this.setState({ unionPay: afop });
          } else {
            flocashOptionList.push(afop);
          }
        });
        
        this.setState({
          flocashOptions: flocashOptionList,
          priceInfo: paymentOption.paymentInfo.amount,
        });
      }
      if (paymentOption.paymentInfo.paymentType == "CREDIT_CARD") {
        creditCardOptionList.push(paymentOption);
      }
      if (paymentOption.paymentInfo.paymentType == "TRAVEL_BANK") {
        this.setState({ travelBank: paymentOption.paymentInfo });
      }
      if (
        paymentOption.paymentInfo.paymentType == "REMOTE" &&
        paymentOption.paymentInfo.paymentCode == "BA_remote"
      ) {
        this.setState({ holdReservation: paymentOption.paymentInfo });
      }
    });
    this.setState({ creditCardOptions: creditCardOptionList });
  }

  render() {
    //if(this.state.isLoading)
    //{
    //    return(<div className="container text-center"><ProgressSimpler /></div>);
    //}

    return (
      <div className="container mt-3">
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <PaymentOption
            paymentInfo={this.state}
            setSelectedPaymentOption={this.setSelectedPaymentOption}
          />
        )}
        {this.renderPaymentOption()}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    countryList: state.payment.countryList,
    exchangeFlightSearchResult: state.exchange.flightSearchResult,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCountryList: (countryList) => {
      dispatch({
        type: paymentAction.SAVECOUNTRYLIST,
        data: countryList,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangePayment);
