import Translation from "i18next";

export default class CabinClassService {
    getCabinClassList = () => {
      return [
        {
            "value":3,
            "label":Translation.t("all_class")
         },
         {
             "value":2,
             "label":Translation.t("business_class")
          },
          {
             "value":1,
             "label":Translation.t("economy_class")
          }
      ];
    };
    getCabinClassByCode = (cabicClassCode) => {
        if(cabicClassCode===3){
            return Translation.t("all_class");
        }
       else if(cabicClassCode===2){
            return Translation.t("business_class");
        }
        else{
            return Translation.t("economy_class")
        }
      };
  }