export class CreditCardRequestObjPlaceHolder
{
constructor()
{
    this.requestObj ={
        "paymentRequired": true,
      
        "payment": [
  
      
        ],
      
        "billingData": null,
      
        "languageForBooking": null,
      
        "fraudNetData": {
              "httpHeaders":null,
              "deviceFingerPrintId":"123456789",
              "timestamp": "2018-12-07",
              "ipAddress":"192.168.42.11"
        },
      
        "remarksAndSSRs": null,
      
        "profileInput": null,
      
        "queuePlacementData": null,
      
        "holdOptionId": null,
      
         "cookieSabreDataRequest": null,
      
        "TinNumber": null,
      
        "CompanyName": null,
      
        "Email": null
      }
}
}