import React from "react";

import moment from "moment";
import { connect } from "react-redux";
// import FlightList from "../flight list temp/FlightList";
import OutsideClickHandler from "react-outside-click-handler";

import { Card, Col } from "react-bootstrap";

import DoubleRangeSlider from "../../booking/flight-selection/range slider/DoubleRangeSlider"; //range slider/DoubleRangeSlider";

import FlightScheduleSort from "./FlightScheduleSort";
import Translation from "i18next";


import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";


class FlightScheduleFilter extends React.Component {
  state = {
    price: 0,
    departMin: 0,
    departMax: 0,
    isClosed: true,
    filterCriteria: {
      NoOfStops: [],
      Departure: { min: null, max: null },
    },
    filterCriteriaActive: {},
    isFiltersModalOpened: false,
    checkedStop: [],
    Accordion: 0,
    searchResponse: {},
  };
  constructor(props) {
    super(props);
    this.state = this.initFilters();
    this.props.updateSortFilterCriteria(this.state.filterCriteria);
    // console.log("this.state = this.initFilters();");
  }

  initFilters = () => {
    try {
      var p = {};
      var countsNoOfStopsCount = [...this.props.flightSchedules].reduce(
        (first, second) => {
          var name = second.totalStops;
          if (!p.hasOwnProperty(name)) {
            p[name] = 0;
          }
          p[name]++;
          return p;
        },
        {}
      );
      countsNoOfStopsCount = Object.keys(countsNoOfStopsCount).map((k) => {
        return {
          name:
            parseInt(k) === 0
              ? "Non-Stop "
              : k + " Stop" + (k > 1 ? "s " : " "),
          count: countsNoOfStopsCount[k],
          stops: k,
        };
      });
      p = {};
      var variableDepartureTime = this.props.flightSchedules.reduce(
        (firstElement, secondElement) => {
          var name =
            "Departure time : " +
            moment(secondElement.flights[0].departure).format("HH:mm");
          if (!p.hasOwnProperty(name)) {
            p[name] = moment(secondElement.flights[0].departure).format(
              "HH:mm"
            );
          }
          return p;
        },
        {}
      );

      p = {};

      variableDepartureTime = Object.keys(variableDepartureTime).map((k) => {
        return variableDepartureTime[k];
      });

      var minDepartTime = variableDepartureTime.reduce(
        (first, second) => {
          if (first < second) return first;
          else return second;
        },
        ["Infinity"]
      );

      var maxDepartTime = variableDepartureTime.reduce(
        (first, second) => {
          if (first > second) return first;
          else return second;
        },
        ["-Infinity"]
      );

      var filterCriteria = {
        NoOfStops: countsNoOfStopsCount,
        Departure: { min: minDepartTime, max: maxDepartTime },
      };

      var checkedStop = {};

      filterCriteria.NoOfStops.map((val, index) => {
        checkedStop[val.name] = true;
        return index;
      });
      var state = { ...this.state };

      state.filterCriteria = filterCriteria;
      state.checkedStop = checkedStop;
      //time
      // state.filterCriteriaActive= {};
      // state.isFiltersModalOpened = false;
      // state.isClosed = true;
      state.departMin = minDepartTime;
      state.departMax = maxDepartTime;
      state.searchResponse = this.props.flightSchedules;
      

      return state;
    } catch (e) {
      return null;
    }
  };

  componentDidUpdate() {
    // this.props.searchResponseUpdate({flightIndex : this.props.flightIndex, searchResponse : this.getItineraryPartBrands()});
    try {
      document.getElementById("sticky-header-outer-container").style.height =
        "auto";
    } catch {}
  }

  getNumberOfStopsFuction = () => {
    return (
      <>
        {this.state.filterCriteria.NoOfStops.length === 1 ? (
          parseInt(this.state.filterCriteria.NoOfStops[0].stops) === 0 ? (
            <div className="row col-12 px-0 mx-0">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  defaultChecked="checked"
                  disabled
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>{Translation.t("non_stop")}</span>
              </div>
              <div className="col pr-0">
                <span>{this.state.filterCriteria.NoOfStops[0].count}</span>
              </div>
            </div>
          ) : (
            <div className="row col-12 px-0 mx-0">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  defaultChecked="checked"
                  disabled
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>
                  {Translation.t(
                    this.state.filterCriteria.NoOfStops[0].stops + "-stop"
                  )}
                  {/* {this.state.filterCriteria.NoOfStops[0].stops +
                    " - Stop" +
                    (this.state.filterCriteria.NoOfStops[0].stops > 1
                      ? "s"
                      : "")}{" "} */}
                </span>
              </div>
              <div className="col pr-0">
                <span>{this.state.filterCriteria.NoOfStops[0].count}</span>
              </div>
            </div>
          )
        ) : (
          <>
            <div className="row col-12 px-0 mx-0 py-1">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  checked={
                    this.state.filterCriteria.NoOfStops.length ===
                    Object.keys(this.state.checkedStop).reduce(
                      (first, second) => {
                        if (this.state.checkedStop[second]) return first + 1;
                        return first;
                      },
                      0
                    )
                      ? "checked"
                      : ""
                  }
                  onChange={(event) => {
                    var temp = this.state.checkedStop;
                    for (const [key] of Object.entries(
                      this.state.checkedStop
                    )) {
                      temp[key] = event.target.checked;
                    }
                    this.setState({ checkedStop: temp });
                  }}
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>{Translation.t("All")}</span>
              </div>
              <div className="col pr-0">
                <span>
                  {this.state.filterCriteria.NoOfStops.reduce(
                    (a, b) => a + b.count,
                    0
                  )}
                </span>
              </div>
            </div>
            {this.state.filterCriteria.NoOfStops.map((val, index) => {
              return (
                <div key={index} className="row col-12 px-0 mx-0 py-1">
                  <div key={"checkbox-" + index} className="col pl-0">
                    <input
                      type="checkbox"
                      className="et-ibe-checkbox"
                      value={val.name}
                      checked={this.state.checkedStop[val.name]}
                      onChange={(event) => {
                        var temp = this.state.checkedStop;
                        temp[event.target.value] = event.target.checked;
                        this.setState({ checkedStop: temp });
                      }}
                    />
                  </div>
                  <div className="col-6 px-0">
                    <span>{val.name}</span>
                  </div>
                  <div className="col pr-0">
                    <span>{val.count}</span>
                  </div>
                </div>
              );
              //   <span>{val.name}</span>;
            })}
          </>
        )}
      </>
    );
  };

  getFilterOptions = () => {
    if (window.innerWidth < 992) {
      return (
        <>
          <button
            value="ALL"
            disabled={
              Object.entries(this.state.filterCriteriaActive).length === 0
                ? "disabled"
                : false
            }
            className="px-3 clear-all-filterCriteria"
            onClick={(event) => this.clearFilter(event)}
          >
            {Translation.t("clear_filter")}
          </button>
          <div className="accordion">
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 0 ? "none" : 0,
                  })
                }
              >
                {Translation.t("filter_time")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.Departure !== undefined
                    ? "Departure: " + this.state.filterCriteriaActive.Departure
                    : null}
                </span>
                <span className="filter-highlight">
                  {" "}
                  {this.state.filterCriteriaActive.Arrival !== undefined
                    ? "Arrival: " + this.state.filterCriteriaActive.Arrival
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 0 ? (
                <Card.Body>
                  <h5>
                    {Translation.t("departure_time")}
                    <button
                      value="Time"
                      disabled={
                        this.state.filterCriteriaActive.Departure !==
                          undefined ||
                        this.state.filterCriteriaActive.Arrival !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h5>
                  <DoubleRangeSlider
                    className="mt-4 card-double-range px-0"
                    onMinChange={(val) => this.setState({ departMin: val })}
                    onMaxChange={(val) => this.setState({ departMax: val })}
                    disabled={
                      this.state.filterCriteria.Departure.min ===
                      this.state.filterCriteria.Departure.max
                    }
                    currentMin={this.state.departMin}
                    currentMax={this.state.departMax}
                    min={this.state.filterCriteria.Departure.min}
                    max={this.state.filterCriteria.Departure.max}
                  />
                </Card.Body>
              ) : null}
            </Card>
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 2 ? "none" : 2,
                  })
                }
              >
                {Translation.t("number_of_tops")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.stops !== undefined
                    ? "Stops " + this.state.filterCriteriaActive.stops
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 2 ? (
                <Card.Body>
                  <h1 className="pb-3">
                    {" "}
                    <button
                      value="stops"
                      disabled={
                        this.state.filterCriteriaActive.stops !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h1>
                  {this.getNumberOfStopsFuction()}
                </Card.Body>
              ) : null}
            </Card>
          </div>
        </>
      );
    } else {
      return (
        <div className="row body-section d-none d-lg-flex px-0 mx-0">
          <div className="col-12 col-lg-6 filter-rows">
            <h5>
              {Translation.t("departure_time")}
              <button
                value="Time"
                disabled={
                  this.state.filterCriteriaActive.Departure !== undefined ||
                  this.state.filterCriteriaActive.Arrival !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h5>
            <DoubleRangeSlider
              className="mt-4 px-0 card-double-range"
              onMinChange={(val) => this.setState({ departMin: val })}
              onMaxChange={(val) => this.setState({ departMax: val })}
              disabled={
                this.state.filterCriteria.Departure.min ===
                this.state.filterCriteria.Departure.max
              }
              currentMin={this.state.departMin}
              currentMax={this.state.departMax}
              min={this.state.filterCriteria.Departure.min}
              max={this.state.filterCriteria.Departure.max}
            />
          </div>
          <div className="col-12 col-lg-6 filter-rows">
            <h4 className="filter-title">
              {Translation.t("number_of_tops")}
              <button
                value="stops"
                disabled={
                  this.state.filterCriteriaActive.stops !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h4>
            {this.getNumberOfStopsFuction()}
          </div>
        </div>
      );
    }
  };

  checkStopOverIsIncluded = (element, tempStopsFinilized) => {
    var result = false;
    console.log(element.flights[0]);
    tempStopsFinilized.map((val) => {
      if (parseInt(val.stops) === parseInt(element.totalStops))
        result = true;
      return val;
    });
    return result;
  };

  getItineraryPartBrands = (filterCriteriaActive) => {
    var tempFilterResult = JSON.parse(
      JSON.stringify(
        this.props.backedUpFlights
      )
    );

    if (filterCriteriaActive.stops !== undefined) {
      var tempStopsFinilized = this.state.filterCriteria.NoOfStops.filter(
        (element) => {
          if (filterCriteriaActive.stops.indexOf(element.name) !== -1) {
            return element.stops;
          }
          return null;
        }
      );
      tempFilterResult = tempFilterResult.filter((element) => {
        if (this.checkStopOverIsIncluded(element, tempStopsFinilized)) {
          return element;
        }
        return null;
      });
    }
    if (filterCriteriaActive.Departure !== undefined) {
      tempFilterResult = tempFilterResult.filter((element) => {
        if (
          moment(element.flights[0].departure).format("HH:MM") >= this.state.departMin &&
          moment(element.flights[0].departure).format("HH:MM") <= this.state.departMax
        ) {
          return element;
        }
        return null;
      });
    }
    return tempFilterResult;
  };

  handleUpdate = () => {
    var temp = {};
    // if all are not selected
    if (
      this.state.filterCriteria.NoOfStops.length !==
      Object.keys(this.state.checkedStop).reduce((first, second) => {
        if (this.state.checkedStop[second]) return first + 1;
        return first;
      }, 0)
    ) {
      temp["stops"] =
        ": " +
        Object.keys(this.state.checkedStop).filter((key) => {
          if (this.state.checkedStop[key]) return key;
          else return null;
        });
    }
    if (
      this.state.departMax !== this.state.filterCriteria.Departure.max ||
      this.state.departMin !== this.state.filterCriteria.Departure.min
    ) {
      temp["Departure"] =
        " Between " +
        moment(this.state.departMin, "HH:mm").format("h:mm A") +
        " and " +
        moment(this.state.departMax, "HH:mm").format("h:mm A");
    }
    this.setState({ filterCriteriaActive: temp, isClosed: true });
    this.props.updateFlightSchedules(this.getItineraryPartBrands(temp));
  };

  clearFilter = (event) => {
    var temp = this.state.filterCriteriaActive;
    if (event.target.value === "ALL") {
      this.setState({
        filterCriteriaActive: {},
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
      });
      temp = {};
    } else if (event.target.value === "stops") {
      var tempforStops = this.state.checkedStop;
      for (const [key] of Object.entries(this.state.checkedStop)) {
        tempforStops[key] = true;
      }
      this.setState({ checkedStop: tempforStops });
    } else if (event.target.value === "Departure") {
      this.setState({
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
      });
    } else if (event.target.value === "Time") {
      this.setState({
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
      });
      delete temp["Departure"];
    }
    delete temp[event.target.value];
    this.setState({ filterCriteriaActive: temp });
    this.props.updateFlightSchedules(this.getItineraryPartBrands(temp));
  };

  handleCancleButtonClick = () => {
    this.setState({ isClosed: true });
  };

  getActiveFilter = () => {
    var tempFilterDictionary = Object.entries(this.state.filterCriteriaActive);

    if (tempFilterDictionary.length === 0) {
      return;
    }

    if (tempFilterDictionary.length === 1) {
      return (
        <>
          {tempFilterDictionary.map(([key, val]) => {
            return (
              <span
                key={key}
                className="et-std-color text-capitalize filter-options d-inline-flex"
              >
                {Translation.t(key)} {val}
                <button
                  value={key}
                  className="clear-filter-button d-inline-flex align-self-center"
                  onClick={(event) => this.clearFilter(event)}
                >
                  x
                </button>{" "}
              </span>
            );
          })}
        </>
      );
    } else
      return (
        <>
          <OutsideClickHandler
            display={"inline-block"}
            onOutsideClick={() =>
              this.setState({ isFiltersModalOpened: false })
            }
          >
            <span
              className="filter-toggler-multi-criteria"
              onClick={() =>
                this.setState({
                  isFiltersModalOpened: !this.state.isFiltersModalOpened,
                })
              }
            >
              {tempFilterDictionary.length + " " + Translation.t("filters")}
            </span>
            <span
              className={
                this.state.isFiltersModalOpened
                  ? "row col-12 col-md-11 col-xl-7 col-md-6 px-0 filter-list-container"
                  : "d-none"
              }
            >
              {tempFilterDictionary.map(([key, val]) => {
                return (
                  <span
                    key={key}
                    className="text-capitalize col-12 mx-0 row filter-option-list"
                  >
                    <span className="col-11 px-0">
                      {Translation.t(key) + val + " "}
                    </span>
                    <button
                      value={key}
                      className="clear-filter-button d-flex align-self-center col-1 px-0 justify-content-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      x
                    </button>{" "}
                  </span>
                );
              })}
            </span>
          </OutsideClickHandler>
        </>
      );
  };

  render() {
    return (
      <>
      <div className="internatl-filter-option-container customized row mx-0 pb-2 pb-md-0">
              <Col xs={12} className="pl-2 pl-sm-3">
                <div>
                  <span className="itinerary-part-offer-header-label">
                    {this.props.flightSchedules.length}{" "}
                    {Translation.t("results")}
                  </span>
                  <FlightScheduleSort flightSchedules={this.props.flightSchedules} />
                 
                      <button
                        className="itinerary-part-offer-header-label pl-4 sort-by-button"
                        onClick={() =>
                          this.setState({
                            isClosed: !this.state.isClosed,
                            isFiltersModalOpened: false,
                          })
                        }
                      >
                        {this.state.isClosed ? Translation.t("show_filter") : Translation.t("hide_filter")}
                      </button>
                      {this.getActiveFilter()}
                </div>
              </Col>
              
            </div>
        {this.state.isClosed ? (
          <>
            {null}
            </>
        ) : (
          <div
            className="search-filter-wrapper w-100"
            id="search-filter-wrapper"
          >
            <div className="search-filter-inside px-0 py-4">
              {this.getFilterOptions()}
              <div className="row bottom-section justify-content-lg-end mb-3 mt-5 mx-0">
                <div className="col-6 col-lg-2">
                  <button
                    className="col-12 btn bg-white text-secondary border text-uppercase filter-bottom-button"
                    onClick={() => this.handleCancleButtonClick()}
                  >
                    {Translation.t("cancel")}
                  </button>
                </div>
                <div className="col-6 col-lg-2 pl-0">
                  <button
                    onClick={() => this.handleUpdate()}
                    className="col-12 btn btn-success text-uppercase std-et-bg-color filter-bottom-button"
                  >
                    {Translation.t("update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    fareType: state.bookingWiget.fareType,
    isMobile: state.global.isMobile,
    journyType: state.bookingWiget.journyType,
    flightIndex: state.flightSelection.flightIndex,
    backedUpFlights: state.flightSchedule.backedUpFlights,
    flightSchedules: state.flightSchedule.flightSchedules,
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
      updateSortFilterCriteria: (filterCriteria) => {
        dispatch({
          type: flightScheduleAction.SCHEDULEUPDATESORTOPTIONS,
          filterCriteria: filterCriteria,
        });
      },
      updateFlightSchedules: (value) => {
        dispatch({
          type: flightScheduleAction.UPDATEFLIGHTSCHEDULES,
          payload: value,
        });
      },
    };
  };

export default connect(mapStateTopProps, mapDispatchToProps)(FlightScheduleFilter);
