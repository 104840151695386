export default class PassengerForm {
  passengerIndex = null;
  passengerDetails = {
    firstName: null,
    lastName: null,
    prefix: null,
    middleName: null,
  };
  preferences = {
    specialRequests: null,
    frequentFlyerProgramme: null,
    frequentFlyerNumber: null,
    mealPreference:null
  };
  contact = {
    email: null,
    phone: null,
    recieveEmail:false
  };
  passengerInfo = {
    birthOfYear: null,
    birthOfMonth: null,
    birthOfDay: null,
    gender: null,
    type:null
  };
  validation = {
    isFirstNameValid: true,
    isLastNameValid: true,
    isprefixValid: true,
    isYearValid: true,
    isMonthValid: true,
    isDayValid: true,
    isGenderValid: true,
    isPhoneValid: true,
    isEmailValid: true,
  };
}

