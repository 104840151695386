export  class BaggageRequest {
  cookieSabreDataRequest = null;
  Flow = 0;
  ancillaryOperations = [];
}
export class AncillaryOperation    {
  ancillaryCode= null;
  selectedTravelPart={
    id: null,
    origin: null,
    destination: null,
    departure: null,
    type: "ITINERARY_PART",
  };
  selectedPassengers= [];
}
export class CheckInBaggageRequest {
  returnSession = true;
  ancillaryOffers = [];
  checkInSabreDataRequest = null;
}

export class CheckInAncillaryOperation {
  passengerId = null;
  ancillaryId = null;
  segmentIds = [];
  quantity = 0;
  id = "ae2";
}


export class BaggageModel {
  passengerId = null;
  passengerIdItinerary =null;
  amount=[];
  bags = [];
} 
export class Amount {
  totalQuantity = 0;
  totalPrice = 0;
}
export class Bags {
  code = null;
  quantity = 0;
  itineryId = null;
  assignedQuantity = 0;
  price = 0;
  name = null;
  maxQuantityPerPax = 0;
  currency = null;
}              