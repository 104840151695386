export class LoginModel {
  userName = null;
  password = null;
  isUserNameValid = true;
  isPasswordValid = true;
}
export class CreateAccountModle {
  title = null;
  firstName = null;
  middleName = null;
  lastName = null;
  year = null;
  month = null;
  day = null;
  gender = null;
  phoneType1 = null;
  phone1 = null;
  phoneType2 = null;
  phone2 = null;
  email = null;
  operationalUpdatesEnabled = false;
  marketingConsentEnabled = false;
  userName = null;
  password = null;
  agree = false;
  validation = new CreateAccountValidation();
  isFormValid = false;
}
export class CreateAccountValidation {
  isTitleValid = true;
  isFirstNameValid = true;
  isLastNameValid = true;
  isYearValid = true;
  isMonthValid = true;
  isDayValid = true;
  isGenderValid = true;
  isPhoneType1Valid = true;
  isPhone1Valid = true;
  isPhoneType2Valid = true;
  isPhone2Valid = true;
  isEmailValid = true;
  isUserNameValid = true;
  isPasswordValid = true;
}
export class BillingInfo {
  cardNumber = null;
  fullName = null;
  expirationDate = null;
  billingAddress = null;
  address = null;
  country = null;
  city = null;
  zipCode = null;
  validation = new BillingInfoValidation();
}
export class BillingInfoValidation {
  isCardNumberValid = true;
  isFullNameValid = true;
  isExpirationDateValid = true;
  isBillingAddressValid = true;
  isAddressValid = true;
  isCountryValid = true;
  isCityValid = true;
  isZipCodeValid = true;
}
export class UpdatePassword {
  oldPassword = null;
  newPassword = null;
  validation = new UpadatePasswordValidation();
}
export class UpadatePasswordValidation {
  isOldPasswordValid = true;
  isNewPasswordValid = true;
  passwordIsConfirmed= false;
}
