export class FloCashInfo {

    constructor() {
        
        this.FirstName = null;
        this.LastName = null;
        this.Email = null;
        this.PhoneNumber = null;
        this.PhoneCountryCode = "251";
        this.Country = null;
        this.Amount = null;
        this.Currency = null;
        this.PaymentID = 0;

    }
}
