import { AirportFullName } from "./AirportFullName";

export class CountryService{
    static getCountryCode(countryName,countryList)
    {
   
     for(let country of countryList)
     {
         if(country.countryName === countryName ||country.threeDigitCountryCode === countryName || country.enCountryCode === countryName)
         {
             return country.enCountryCode;
         }
         else{
             
             if(country.countryName.includes("("))
             {
                 
                 let threeCodeCountryName = country.countryName.substring(country.countryName.indexOf("(")+1,country.countryName.indexOf(")"));
                 if(threeCodeCountryName === countryName)
                 {
                    return country.enCountryCode;
                 }
             }
         }
     }   
    }
}