import { CheckInModel } from "../../../model/checkIn/CheckInModel";
import CheckInAncillaryService from "../../../service/check-in/CheckInAncilllaryService";
import CheckInService from "../../../service/check-in/CheckInService";
import { checkInAction } from "../../actions/check-in/checkInAction";

const initialState = {
  checkInModel: new CheckInModel(),
  isFormValid: false,
  reservation: null,
  checkInSabreDataRequest: "",
  selectedSeats: {},
  passengerIndex: 1,
  activePassangerIdentifierCode: "A1",
  seatSelecting: false,
  baggageModel: [],
  overAllTotal: 0,
  finaloverAllTotal: 0,
  bagIsAdded: false,
  isNewBagAdded: false,
  oldValue: 0,
  activePassengers: {},
  passengers: {},
  listOfPassengers: {},
  activePassangerIdentifierCodeMapper: {},
  selectedSeatHistory: [],
  boardingPass : null
};
var checkInService = new CheckInService();
var baggageService = new CheckInAncillaryService();
const checkInReducer = (state = initialState, action) => {
  var seat = {};
  var temp  = {};
  var newBaggageModal = {};

  switch (action.type) {
    case checkInAction.UPDATEHIDENOPTION:
      var tempState = checkInService.updateHideOptions(state, action.value);
      return {
        ...state,
        checkInModel: tempState.checkInModel,
      };
    case checkInAction.UPDATECHECKInSABREDATAREQUEST:
      return {
        ...state,
        checkInSabreDataRequest: action.checkInSabreDataRequest,
      };
    case checkInAction.UPDATERESERVATION:
      return { ...state, reservation: action.reservation };
    case checkInAction.UPDATECHECKINPASSENGERS:
      return { ...state, passengers: action.passengers };

    case checkInAction.UPDATEBOARDINGPASS:
      return { ...state, boardingPass: action.boardingPass };

    case checkInAction.UPDATECHECKINSEATMAP:
      var AdultCounter = 1;
      var ChildCounter = 1;
      var InfantCounter = 1;

      var tempActivePassangerIdentifierCodeMapper = {};

      Object.keys(state.activePassengers).map((key) => {
        if (state.activePassengers[key].type.value[0] === "A") {
          tempActivePassangerIdentifierCodeMapper["A" + key[2]] =
            "A" + AdultCounter;
          AdultCounter++;
        }
        if (state.activePassengers[key].type.value[0] === "C") {
          tempActivePassangerIdentifierCodeMapper["C" + key[2]] =
            "C" + ChildCounter;
          ChildCounter++;
        }
        if (state.activePassengers[key].type.value[0] === "I") {
          tempActivePassangerIdentifierCodeMapper["I" + key[2]] =
            "I" + InfantCounter;
          InfantCounter++;
        }
        return null;
      });

      return {
        ...state,
        seatMap: action.seatMap,
        activePassangerIdentifierCodeMapper:
          tempActivePassangerIdentifierCodeMapper,
      };
    case checkInAction.CHECKINSEATSELECTING: {
      return {
        ...state,
        seatSelecting: action.value,
      };
    }
    case checkInAction.CHECKINREMOVESEATSELECTION:
      temp = { ...state.selectedSeats };
      var identifier =
        action.passengerId +
        "." +
        action.seatMapIdentifier +
        ".f" +
        action.seatMapIdentifier[1];

      var temp2 = { ...temp[identifier] };
      delete temp[identifier];

      delete temp[action.seatMapIdentifier][temp2.seatCode];

      return {
        ...state,
        selectedSeats: temp,
      };

    case checkInAction.CHECKINCHANGEACTIVEPASSANGERCODE:
      return {
        ...state,
        activePassangerIdentifierCode: action.activePassangerIdentifierCode,
        passengerIndex: action.passengerIndex,
      };
    case checkInAction.CHECKININITSELECTEDSEATS:
      return {
        ...state,
        selectedSeats: action.selectedSeats.selectedSeats,
        activePassangerIdentifierCode:
          action.selectedSeats.activePassangerIdentifierCode,
        selectedSeatHistory: JSON.parse(
          JSON.stringify(action.selectedSeats.selectedSeats)
        ),
      };

    case checkInAction.CHECKINSELECTSEAT:
      var passengerRequestIdentifierCode = action.payload.passengerIndex;
      var passengerRequestIdentifierCodeForSelectSeat =
        action.payload.passengerIndex +
        "." +
        action.payload.seatMapIdentifier +
        ".f" +
        action.payload.seatMapIdentifier[1];
      var tempSelectedSeats = { ...state.selectedSeats };

      var tempSelectedSeatsSegmentLevel =
        tempSelectedSeats[action.payload.seatMapIdentifier];
      var passengerAssociatedWithSeat =
        tempSelectedSeats[passengerRequestIdentifierCodeForSelectSeat];

      if (passengerAssociatedWithSeat !== null && passengerAssociatedWithSeat !== undefined) {
        delete tempSelectedSeatsSegmentLevel[
          passengerAssociatedWithSeat["seatCode"]
        ];

        tempSelectedSeats[action.payload.seatMapIdentifier] =
          tempSelectedSeatsSegmentLevel;

        seat = {};
        seat = {
          seatCode: action.payload.seatCode,
          passengerIndex: action.payload.passengerIndex,
          activePassangerIdentifierCode:
            state.activePassengers[passengerRequestIdentifierCode]
              .fiscalCode[0] + action.payload.passengerIndex[2],
          passengerRequestIdentifierCode:
            passengerRequestIdentifierCodeForSelectSeat,
        };
        tempSelectedSeats[action.payload.seatMapIdentifier][
          action.payload.seatCode
        ] = seat;
        tempSelectedSeats[passengerRequestIdentifierCodeForSelectSeat] = {
          seatCode: action.payload.seatCode,
          passengerId: action.payload.passengerIndex,
          segmentId: action.payload.seatMapIdentifier,
        };
      } else {
        seat = {};
        seat = {
          seatCode: action.payload.seatCode,
          passengerIndex: action.payload.passengerIndex,
          activePassangerIdentifierCode:
            state.activePassengers[passengerRequestIdentifierCode]
              .fiscalCode[0] + action.payload.passengerIndex[2],
          passengerRequestIdentifierCode:
            passengerRequestIdentifierCodeForSelectSeat,
        };
        var tempSeat = {};
        tempSeat[
          action.payload.seatCode
        ] = seat;
        tempSelectedSeats[action.payload.seatMapIdentifier] = tempSeat;
        
        tempSelectedSeats[passengerRequestIdentifierCodeForSelectSeat] = {
          seatCode: action.payload.seatCode,
          passengerId: action.payload.passengerIndex,
          segmentId: action.payload.seatMapIdentifier,
        };
      }
      return {
        ...state,
        selectedSeats: tempSelectedSeats,
      };

    case checkInAction.INITACTIVEPASSENGER:
      return {
        ...state,
        activePassengers: action.activePassengers,
        listOfPassengers: action.activePassengers,
      };

    case checkInAction.UPDATEACTIVEPASSENGER:
      temp = { ...state.activePassengers };
      if (temp === undefined) temp = {};

      if (action.payload.status) {
        if (action.payload.passengerIdentifier in state.activePassengers) {
        } else {
          temp[action.payload.passengerIdentifier] =
            state.listOfPassengers[action.payload.passengerIdentifier];
        }
      } else {
        delete temp[action.payload.passengerIdentifier];
      }
      return {
        ...state,
        activePassengers: temp,
      };

    case checkInAction.CHECKINCLEARSEATSELECTIONENTRY:
      return {
        ...state,
        selectedSeats: {},
      };

    case checkInAction.CHECKIN_SAVEANCILLARYDATA:
      var intialBaggageModal = baggageService.initializeBaggageModel(
        action.data,
        state.activePassengers
      );
      return {
        ...state,
        baggageModel: intialBaggageModal.passengerList,
        overAllTotal: intialBaggageModal.overAllTotal,
        bagIsAdded: intialBaggageModal.isBaggageAddedBefore,
        finaloverAllTotal: intialBaggageModal.overAllTotal,
        oldValue: intialBaggageModal.overAllTotal,
      };
    case checkInAction.CHECKIN_ADDBAG:
      newBaggageModal = new baggageService.addBag(
        state,
        action.ancillaryCode,
        action.passengerKey,
        action.price
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case checkInAction.CHECKIN_MINUSBAG:
      newBaggageModal = new baggageService.minusBag(
        state,
        action.ancillaryCode,
        action.passengerKey,
        action.price
      );
      return {
        ...state,
        baggageModel: newBaggageModal.baggageModel,
        overAllTotal: newBaggageModal.overAllTotal,
      };
    case checkInAction.CHECKIN_ADDTOCART:
      var bagIsAdded = true;
      if (state.overAllTotal === state.finaloverAllTotal) {
        bagIsAdded = false;
      }
      return {
        ...state,
        bagIsAdded: bagIsAdded,
        reservation: action.reservation,
        finaloverAllTotal: state.overAllTotal,
        isNewBagAdded: bagIsAdded,
      };
    default:
      return state;
  }
};
export default checkInReducer;
