import React, { Component } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { connect } from "react-redux";
import Translation from "../../../service/shared/multi-lingual/Translation";

class FareOptions extends Component {
  setFareType = (event) => {
    this.props.updateFareType(event.target.value);
  };

  render() {
    return (
      <Col xs={12} sm={12} md={6}>
        <div className="journey-type">
          <div className="text-left">
            <label className="fare-option-label">Show fare in</label>
          </div>
          <ButtonGroup aria-label="fare-option" className="btn-block">
            <button
              id="currecy-btn"
              value="CURRENCY"
              className={
                this.props.fareType === "CURRENCY"
                  ? "fare-option-btn-active btn"
                  : "fare-option-btn btn"
              }
              onClick={this.setFareType}
            >
              <Translation Key="CURRENCY" />
            </button>
            <button
              id="miles-btn"
              value="MILES"
              className={
                this.props.fareType === "MILES"
                  ? "fare-option-btn-active btn"
                  : "fare-option-btn btn"
              }
              onClick={this.setFareType}
            >
              <Translation Key="MILES" />
            </button>
          </ButtonGroup>
        </div>
      </Col>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    fareType: state.bookingWiget.fareType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFareType: (value) =>
      dispatch({
        type: BookingWidgetAction.UPDATEFARETYPE,
        value: value,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FareOptions);
