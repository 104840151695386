import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faBan,
  faCheck,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

import React from "react";
import { Alert } from "react-bootstrap";
class Notification extends React.Component {
  render() {
    if (this.props.body !== undefined) {
      if (this.props.error) {
        return (
          // <div className="mx-5 mt-5 h-100" style={{minHeight:this.getScreenHeight()}}>
          <div className="mt-3 h-100 mx-3">
            <div className="row mb-5">
              <div
                className="col-md-1 col-sm-12 px-0 text-center text-white notification-icon-container d-flex justify-content-center py-2 bg-danger"
                // style={{ backgroundColor: "red"}}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faBan} size="2x" />
                </span>
              </div>
              <div
                className="col-md-11 col-sm-12 px-0"
                style={{ textAlign: "left" }}
              >
                <Alert variant="light" className="m-0 alert-custom-radius">
                  <Alert.Heading className="heading-4 pt-3 danger-alert-text">
                    {this.props.header}
                  </Alert.Heading>
                  <p>{this.props.body}</p>
                  <span
                    className="error-technical-info user-select-none"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {this.props.extra}
                  </span>
                </Alert>
              </div>
            </div>
          </div>
        );
      } else if (this.props.success) {
        return (
          // <div className="mx-5 mt-5 h-100" style={{minHeight:this.getScreenHeight()}}>
          <div className="mt-3 h-100 mx-3">
            <div className="row mb-5">
              <div
                className="col-md-1 col-sm-12 px-0 text-center text-white notification-icon-container d-flex justify-content-center py-2 bg-success"
                // style={{ backgroundColor: "red"}}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} size="2x" />
                </span>
              </div>
              <div
                className="col-md-11 col-sm-12 px-0"
                style={{ textAlign: "left" }}
              >
                <Alert variant="light" className="m-0 alert-custom-radius">
                  <Alert.Heading className="heading-4 pt-3 success-alert-text">
                    {this.props.header}
                  </Alert.Heading>
                  <p>{this.props.body}</p>
                </Alert>
              </div>
            </div>
          </div>
        );
      }else if(this.props.info) {
        return (
          <div className="mt-3 h-100 mx-3">
            <div className="row mb-5">
              <div
                className="col-md-1 col-sm-12 px-0 text-center notification-icon-container d-flex justify-content-center py-2"
                style={{ backgroundColor: "#d1ecf1" }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faInfo} size="3x" />
                </span>
              </div>
              <div
                className="col-md-11 col-sm-12 px-0"
                style={{ textAlign: "left" }}
              >
                <Alert variant="light" className="m-0 alert-custom-radius">
                  <Alert.Heading className="heading-4 pt-3 success-alert-text">
                    {this.props.header}
                  </Alert.Heading>
                  <p>{this.props.body}</p>
                  <span
                    className="error-technical-info user-select-none"
                    style={{ fontSize: "12px", fontWeight: "500" }}
                  >
                    {this.props.extra}
                  </span>
                </Alert>
              </div>
            </div>
          </div>
        );
      }
      else {
        return (
          <div className="mt-3 h-100 mx-3">
            <div className="row mb-5">
              <div
                className="col-md-1 col-sm-12 px-0 text-center notification-icon-container d-flex justify-content-center py-2"
                style={{ backgroundColor: "#ffe066" }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faExclamation} size="3x" />
                </span>
              </div>
              <div
                className="col-md-11 col-sm-12 px-0"
                style={{ textAlign: "left" }} 
              >
                <Alert variant="warning" className="m-0 alert-custom-radius">
                  <Alert.Heading className="heading-2">
                    {this.props.header}
                  </Alert.Heading>
                  <h3>{this.props.body}</h3>
                </Alert>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <></>;
    }
  }
}

export default Notification;
