export default class SearchRequest {
  cabinClass = 3;
  awardBooking = false;
  searchType = 2;
  promoCodes = [];
  currency = "CURRENCY";
  itineraryParts = [];
  passengers = {};
  journyType = "";
}

export class SearchRequestItinerary {
  from = {
    code: null,
  };
  to = {
    code: null,
  };

  when = {
    date: null,
  };
  selectedOfferRef = null;
}
