import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
import ExchangePaymentSummaryTripDetail from "./ExchangePaymentSummaryTripDetail";
import ExchangePaymentSummaryPassenger from "./ExchangePaymentSummaryPassenger";
import ExchangePaymentSummaryHeader from "./ExchangePaymentSummaryHeader";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import ExchangePaymentSummaryAction from "./ExchangePaymentSummaryAction";

class ExchangePaymentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerList: [],
      tripDetail: [],
      pnr: null,
      contactInfo: null,
      passengerTicketInfo: [],
      payments: {},
      priceBreakdown: {},
    };
  }
  componentDidMount() {
    console.log(this.props.location);
    window.scrollTo({ top: 0, behavior: "smooth" });

    this.setState({
      pnr: this.props.location.state.pnr.reloc,
      tripDetail: this.props.location.state.pnr.itinerary.itineraryParts,
      passengerList: this.props.location.state.pnr.passengers,
      contactInfo: this.props.location.state.pnr.contact,
      passengerTicketInfo:
        this.props.location.state.pnr.travelPartsAdditionalDetails[0]
          .passengers,
      payments: this.props.location.state.pnr.payments,
      priceBreakdown: this.props.location.state.pnr.priceBreakdown,
    });
  }
  getCityName(airport) {
    // let cityName = airport.substring(0,airport.indexOf("("));
    return AirportFullName.getCityName(airport);
  }
  render() {
    return (
      <section className="exchange-payment-summary-container">
        <ExchangePaymentSummaryHeader
          pnr={this.state.pnr}
          type={
            this.props.location.type === null ? null : this.props.location.type
          }
        />
        <section className="payment-summary-confirmation-header">
          <Row>
            <Col xs={12} sm={12} md={4} lg={3}>
              <div>
                <div> {Translation.t("confirmation_code")}</div>
                <h3>
                  <mark>{this.state.pnr}</mark>
                </h3>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6}>
              <div className="payment-summary-itinerary pt-3">
                <span>
                  {this.getCityName(
                    this.props.location.state.pnr.itinerary.itineraryParts[0]
                      .segments[0].origin
                  )}
                </span>
                <span className="px-3"></span>
                <span>
                  {this.getCityName(
                    this.props.location.state.pnr.itinerary.itineraryParts[0]
                      .segments[
                      this.props.location.state.pnr.itinerary.itineraryParts[0]
                        .segments.length - 1
                    ].destination
                  )}
                </span>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={12}>
              {/* <div className="pt-3">
                <span>{Translation.t("print")}</span>
              </div> */}
              <ExchangePaymentSummaryAction
                itineraryParts={
                  this.props.location.state.pnr.itinerary.itineraryParts
                }
                state={this.state}
              />
            </Col>
          </Row>
        </section>
        <ExchangePaymentSummaryTripDetail tripDetail={this.state.tripDetail} />
        <ExchangePaymentSummaryPassenger
          passengerList={this.state.passengerList}
          contactInfo={this.state.contactInfo}
          passengerTicketInfo={this.state.passengerTicketInfo}
          tripDetail={this.state.tripDetail}
        />
      </section>
    );
  }
}
export default ExchangePaymentSummary;
