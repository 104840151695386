export const globalActionType = {
  UPDATELOCALE: "UPDATELOCALE",
  UPDATEISMOBILE: "UPDATEISMOBILE",
  TOGGLEISLOADING: "TOGGLEISLOADING",
  UPDATEISLOADING: "UPDATEISLOADING",
  UPDATEISMEDIUM: "UPDATEISMEDIUM",
  KEEPMESIGNEDIN: "KEEPMESIGNEDIN",
  SIGNOUT: "SIGNOUT",
  SIGNEDIN: "SIGNEDIN",
  UPDATEISGLOBALSESSIONEXPIRED: "UPDATEISGLOBALSESSIONEXPIRED",
  TELEBIRR: "TELEBIRR",
};
