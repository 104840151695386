import React from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";

import { useHistory } from "react-router-dom";
import moment from "moment";

import Translation from "i18next";


const FlightStatusResult = (props) => {
  let history = useHistory();

  const handleSelectClick = (date) => {
    console.log(date, props.itineraryPart);
    props.changeWidgetType("bookFlight");

    props.setDATE({
      date: date,
      flightIndex: 0,
      isReturn: false,
    });

    props.updateSearchcritrion(date);

    history.push("/");
  };

  const displayForMobile = () => {
    return (
      <>
        {props.flightSchedule.operatingDays.map((operatingDay, index) => {
          return (
            <div
              className={
                index === 3
                  ? "schedule-date-column schedule-select-column schedule-date-column-mobile px-0"
                  : "schedule-date-column schedule-select-column schedule-date-column-mobile"
              }
            >
              <div
                onClick={() => handleSelectClick(operatingDay.date)}
                className={
                  index === 3
                    ? "schedule-today schedule-date-button d-flex justify-content-center flex-column"
                    : "schedule-date-button d-flex justify-content-center flex-column"
                }
              >
                <p className="p-0 m-0 text-center d-flex justify-content-center align-self-center">
                  {moment(operatingDay.date).locale("en").format("ddd MM/DD")}
                </p>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="schedule-search-result-row mt-3">
        <Row className="mx-0">
          <Col md={3} className="px-0 d-flex">
            <div className="d-flex justify-content-start align-self-center w-100">
              <Row className="w-100 mx-0">
                <div className="d-flex d-md-none col-12 px-0 py-2">
                  <Col xs={7}>{Translation.t("depart")}</Col>
                  <Col xs={5} className="px-0">
                    {Translation.t("arrive")}
                  </Col>
                </div>

                <Col xs={12} className="row mx-0">
                  <Col xs={5} className="px-0">
                    {moment(props.flightSchedule.flights[0].departure)
                      .locale("en")
                      .format("DD MMM HH:mm")}
                  </Col>
                  <Col xs={2} className="px-0">
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="1x"
                      className="mx-2"
                    />
                  </Col>
                  <Col xs={5} className="px-0">
                    {moment(props.flightSchedule.flights[props.flightSchedule.flights.length - 1].arrival)
                      .locale("en")
                      .format("DD MMM HH:mm")}
                  </Col>
                </Col>
                <Col xs={12}>
                  <img
                    src="//www.gstatic.com/flights/airline_logos/35px/ET.png"
                    alt="Ethiopian Airlines"
                  />
                  <span className="mx-3 flight-number"> <span>{props.flightSchedule.flights[0].operatingCarrier + " " + props.flightSchedule.flights[0].flightNumber}</span></span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={1} className="d-flex">
            <div className="justify-content-start align-self-center">
              <b>
                {props.flightSchedule.totalStops === 0
                  ? "Non-stop"
                  : props.flightSchedule.totalStops === 1
                  ? "1 Stop"
                  : props.flightSchedule.totalStops + " Stops"}
              </b>
            </div>
          </Col>
          {/* desktop version */}
          <div className="d-none d-md-flex col-8">
            {/* departureDate */}
            {props.flightSchedule.operatingDays.map((operatingDay, index) => {
              return (
                <div
                  className={
                    index === 3
                      ? "schedule-date-column schedule-select-column schedule-date-column-select px-0"
                      : "schedule-date-column schedule-select-column schedule-date-column-select"
                  }
                >
                  {operatingDay.available ? (
                    <div
                      onClick={() => handleSelectClick(operatingDay.date)}
                      className={
                        index === 3
                          ? "schedule-today schedule-date-button d-flex justify-content-center flex-column"
                          : "schedule-date-button d-flex justify-content-center flex-column"
                      }
                    >
                      <p className="p-0 m-0 text-center d-flex justify-content-center align-self-center">
                        <FontAwesomeIcon icon={faPlaneDeparture} />
                      </p>
                      <p className="p-0 m-0 text-center d-flex justify-content-center align-self-center">
                    
                        <b>{Translation.t("select")}</b>
                      </p>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="d-flex d-md-none row mx-0">{displayForMobile()}</div>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    arrivingCity: state.checkIn.checkInModel.arrivingCity,
    ticketNumber: state.checkIn.checkInModel.ticketNumber,
    token: state.searchFlight.token,
    itineraryPart: state.bookingWiget.itineraryPart,
    flightSchedules: state.flightSchedule.flightSchedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSearchcritrion: (value) =>
      dispatch({
        type: flightScheduleAction.UPADTESEARCHCRITRION,
        value: value,
      }),
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
      setDATE: (value) =>
      dispatch({
        type: BookingWidgetAction.DATESELECTION,
        payload: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlightStatusResult);
