import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Translation from "i18next";
import moment from "moment";

import { Col, Row } from "react-bootstrap";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import ExchangeFlightSelectionDetail from "../../flight-selection/ExchangeFlightSelectionDetail";
class ExchangePaymentSummaryTripDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTripDetail: true,
    };
  }
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };
  renderStops(segments, stops) {
    if (stops > 0) {
      return (
        <div>
          {segments.map((segment, key) => (
            <span key={key}>
              {key !== 0 && (
                <span>
                  {stops}{" "}
                  <span>
                    {stops > 1 ? Translation.t("stops") : Translation.t("stop")}
                  </span>
                </span>
              )}
            </span>
          ))}
        </div>
      );
    } else {
      return <span>{Translation.t("non-stop")}</span>;
    }
  }
  getCityName(airport) {
    return AirportFullName.getCityName(airport);
  }
  render() {
    return (
      <section className="payment-summary-trip-detail">
        <h2
          className="payment-summary-trip-detail-head"
          onClick={() =>
            this.setState({ showTripDetail: !this.state.showTripDetail })
          }
        >
          <span>Your Trip Details</span>
          <span className="float-right">
            <FontAwesomeIcon
              icon={this.state.showTripDetail ? faAngleUp : faAngleDown}
            />
          </span>
        </h2>
        {this.state.showTripDetail && (
          <div className="trip-detail">
            {this.props.tripDetail.map((itineraryPart, key) => (
              <section className="payment-summary-trip-detail-list" key={key}>
                <Row className="pt-3">
                  <Col xs={12} sm={12} md={12} lg={5}>
                    <Row>
                      <Col xs={12} sm={12} md={12} lg={6}>
                        <span className="pr-4">
                          {this.getCityName(itineraryPart.segments[0].origin)}
                        </span>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6}>
                        <span className="pl-0 pl-md-4">
                          {this.getCityName(
                            itineraryPart.segments[
                              itineraryPart.segments.length - 1
                            ].destination
                          )}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={5}>
                    <Row>
                      <Col>
                        <time className="pr-4">
                          {moment(itineraryPart.segments[0].departure).format(
                            "L"
                          )}
                        </time>
                      </Col>
                      <Col>
                        <time className="pl-4">
                          {" "}
                          {moment(itineraryPart.segments[0].arrival).format(
                            "L"
                          )}
                        </time>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={5}>
                    <Row>
                      <Col>
                        <h5>
                          <strong>
                            {" "}
                            <time className="pr-4">
                              {moment(
                                itineraryPart.segments[0].departure
                              ).format("hh:mm A")}
                            </time>
                          </strong>
                        </h5>
                      </Col>
                      <Col>
                        <h5>
                          <strong>
                            {" "}
                            <time className="pl-4">
                              {moment(itineraryPart.segments[0].arrival).format(
                                "hh:mm A"
                              )}
                            </time>
                          </strong>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={5} className="text-center">
                    <h5>
                      <strong>
                        <time>
                          {" "}
                          {this.parseTime(itineraryPart.totalDuration)}
                        </time>
                      </strong>
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={5}></Col>
                  <Col xs={12} sm={12} md={12} lg={5} className="text-center">
                    <div>
                      <strong>Non-stop</strong>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {itineraryPart.segments.map((segment, index) => (
                      <div className="py-1" key={index}>
                        <span className="px-1">
                          {segment.flight.airlineCode}
                        </span>
                        <span className="pr-2">
                          {segment.flight.flightNumber}
                        </span>
                        <span className="pr-2">{segment.manufacturerName}</span>
                        <span>{segment.equipmentDescription}</span>
                      </div>
                    ))}
                  </Col>
                </Row>
                {key === this.props.tripDetail.length - 1 && (
                  <Row className="py-3">
                    <Col xs={12}>
                      <ExchangeFlightSelectionDetail
                        itineraryPart={this.props.tripDetail}
                      />
                    </Col>
                  </Row>
                )}
              </section>
            ))}
          </div>
        )}
      </section>
    );
  }
}
export default ExchangePaymentSummaryTripDetail;
