import { faChair } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
class ExchangePaymentSummaryPassengerSeat extends Component {
  constructor(props) {
    super(props);
  }
  getCityName(airport) {
    return AirportFullName.getCityName(airport);
  }
  render() {
    return (
      <section>
        <div>
          <h4 className="pym-summary-psg-seat-head">
            <span className="pr-2">
              <FontAwesomeIcon icon={faChair} />
            </span>
            Seats &amp; Extras:
          </h4>
        </div>

        {this.props.tripDetail.map((itineraryPart) => (
          <div className="pb-2">
            {itineraryPart.segments.map((segment) => (
              <div>
                <Row>
                  <Col xs={12} sm={12} md={8} lg={5}>
                    <Row>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <span className="pym-summary-airport-name">
                          {this.getCityName(segment.origin)}{" "}
                        </span>
                      </Col>
                      <Col xs={6} sm={6} md={6} lg={6}>
                        <span className="pym-summary-airport-name">
                          {this.getCityName(segment.destination)}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={8} lg={12} className="py-2">
                    <span>
                      <b>{segment.origin}</b>
                    </span>
                    <span className="px-2">-</span>
                    <span>
                      <b>{segment.destination}</b>
                    </span>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={12}>
                    {/* <span>Seat : None</span> */}
                  </Col>
                </Row>
              </div>
            ))}{" "}
          </div>
        ))}
      </section>
    );
  }
}
export default ExchangePaymentSummaryPassengerSeat;
