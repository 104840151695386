import React, { Component } from "react";
import "./Exception.css";
class NotFoundException extends Component {
  state = {};
  render() {
    return (
      <div
        className="notfound-exception"
        style={{ background: "url('/exception/404.jpg')" }}
      ></div>
    );
  }
}

export default NotFoundException;
