import { React, Component } from "react";
import Seat from "./Seat";
import { Col, Row, Modal } from "react-bootstrap";
import Translation from "i18next";

class Seats extends Component {
  state = {
    isSeatPopUpModalOpen: false,
    isNextModalOpened: false,
    isModalForContinuingToBaggage : false
    };
  seatName = "";
  top = 0;
  right = 0;
  left = 0;
  prevElement = "";
  firstRowExit = true;
  openSeatPopUpModal = (event, value) => {
    var ClientRect = event.target.getBoundingClientRect();
   
    this.seatName = value;

    this.top = ClientRect.y + 70;
    this.right = ClientRect.right;
    this.left = ClientRect.left;

    this.setState({ isSeatPopUpModalOpen: true });
  };
  closeSeatPopUpModal = () => {
    
  };

  checkAllPassengersSelectSeat = (selectedSeats, passangers) => {
    const countAdultOrChildPassenger = passangers.filter(
      (passenger) =>
        passenger.passenger.passengerInfo.type === "ADT" ||
        passenger.passenger.passengerInfo.type === "CHD"
    ).length;
    if (
      selectedSeats[this.props.seatMapIdentifier.replace("-", "")] !== undefined
    ) {
      const selectedSeatsCount = Object.keys(
        selectedSeats[this.props.seatMapIdentifier.replace("-", "")]
      ).length;
      if (countAdultOrChildPassenger === selectedSeatsCount && this.props.seatMapIndex - 1  !== this.props.TotalSeatMaps) {
        this.setState({ isNextModalOpened: true });
      }
      if(countAdultOrChildPassenger === selectedSeatsCount && this.props.seatMapIndex - 1  === this.props.TotalSeatMaps){

        this.setState({isModalForContinuingToBaggage : true});
        // this.props.continueToBaggage();
      }
    }
  };

  getModal = () => {
    return (
      <Modal
        show={this.state.isNextModalOpened}
        onHide={() => {
          this.setState({
            isNextModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h4>{Translation.t("next_flight")}</h4>
          <h5>{Translation.t("do_you_want_to_proceed_to_next_flight")}</h5>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button className="col-12 col-lg-3 btn btn-success mr-lg-2 mb-3 mb-lg-0" onClick={() => this.handleViewNextFlight()}>{Translation.t("view_next_flight")}</button>
            <button className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal" onClick={() => this.setState({isNextModalOpened: false})}>{Translation.t("cancel")}</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  getModalForContinuingToBaggage = () =>{
    return (
      <Modal
        show={this.state.isModalForContinuingToBaggage}
        onHide={() => {
          this.setState({
            isNextModalOpened: false,
          });
        }}
        size="xl"
        centered
      >
        <Modal.Body>
          <h4>{Translation.t("continue_to_extras")}</h4>
          <h5>{Translation.t("message_for_all_selected_passanger")}</h5>
          <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
            <button className="col-12 col-lg-3 btn btn-success mr-lg-2 mb-3 mb-lg-0" onClick={() => this.props.continueToBaggage()}>{Translation.t("Continue")}</button>
            <button className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal" onClick={() => this.setState({isModalForContinuingToBaggage: false})}>{Translation.t("cancel")}</button>
          </div>
        </Modal.Body>
      </Modal>
    );

  }

  handleViewNextFlight = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.setState({isNextModalOpened: false});
    this.props.nextFlight(this.props.seatMapIndex);
  }


  render() {
    return (
      <div
        key={"seats-container-" + this.props.SeatMapId}
        className={
          "my-5 seats-container-list seat-column-" +
          this.props.Cabin.seatColumns.length
        }
        id={"seats-container-" + this.props.SeatMapId}
      >
        {this.getModal()}
        {this.getModalForContinuingToBaggage()}
        <Row className="justify-content-center mx-0 seat-row">
          {this.props.Cabin.seatColumns.map((columnName) => {
            return (
              <>
                {columnName === "LEFT_SIDE" ||
                columnName === "RIGHT_SIDE" ? null : columnName === "AISLE" ? (
                  <Col key={columnName} className="AISLE text-center">
                    {this.props.row}
                  </Col>
                ) : (
                  <Col
                    key={columnName}
                    className="justify-content-center text-center column-names"
                  >
                    {columnName}
                  </Col>
                )}
              </>
            );
          })}
        </Row>

        {this.props.Cabin.seatRows.map((seats) => {
          return (
            <Row className="mx-0 seat-row justify-content-center">
              {seats.seats.map((seat, index) => {
                return (
                  <>
                    {(this.props.Cabin.seatColumns[index] === "LEFT_SIDE" ||
                      this.props.Cabin.seatColumns[index] === "RIGHT_SIDE") &&
                    seat.slotCharacteristics.indexOf("EXIT") !== -1 ? (
                      <div className="exit-seat">
                        {this.firstRowExit ? (
                          "EXIT"
                        ) : (
                          <div className="second-row exit-indicator"></div>
                        )}
                        {this.props.Cabin.seatColumns[index] === "RS"
                          ? (this.firstRowExit = false)
                          : null}
                      </div>
                    ) : (
                      <Seat
                        key={"seat-" + index}
                        SeatMapId={this.props.SeatMapId}
                        checkAllPassengersSelectSeat={
                          this.checkAllPassengersSelectSeat
                        }
                        closeSeatPopUpModal={this.closeSeatPopUpModal}
                        keyType={"seat-" + index}
                        seat={seat}
                        seatMapIdentifier={this.props.seatMapIdentifier}
                        row={seats.rowNumber}
                        column={this.props.Cabin.seatColumns[index]}
                        DepartureDate={this.props.DepartureDate}
                      />
                    )}
                  </>
                );
              })}
            </Row>
          );
        })}
      </div>
    );
  }
}

export default Seats;
