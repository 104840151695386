import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { CreditCardRequestObjPlaceHolder } from "../../../../model/payment/CreditCardRequestObjPlaceHolder";
import HttpService from "../../../../service/shared/HttpService";
import Loading from "../../../booking/seat-selection/Loading";
class SkipPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: false,
      redirectToThirdParty: false,
      responseData: null,
      credirCardRedirect: null,
    };
  }
  componentDidMount() {
    let creditCardRequestObj = new CreditCardRequestObjPlaceHolder();
    creditCardRequestObj.requestObj.paymentRequired = false;
    creditCardRequestObj.requestObj.cookieSabreDataRequest =
      this.props.cookieSabreDataRequest;
    this.setState({ isLoading: true });
    HttpService.postServiceManageTrip(
      creditCardRequestObj.requestObj,
      "/etibe-managetrip/V1.0/Purchase/Purchase",
      this.props.searchFlight.token
    ).then(
      (response) => {
        
        if (response.data.pnr === null) {
          this.setState({
            isLoading: false,
            redirectToThirdParty: true,
            credirCardRedirect:
              response.data.redirectInfo.payerAuthenticationRequestForm,
          });
        } else {
          this.setState({
            isLoading: false,
            redirect: true,
            responseData: response.data,
          });
        }
      },
      (error) => {
        
        this.setState({ isLoading: false });
      }
    );
  }
  render() {
    if (this.state.redirectToThirdParty) {
      return (
        <Redirect
          to={{
            pathname: "/redirect-payment",
            state: { redirectToPath: this.state.credirCardRedirect },
            //type: "hold"
          }}
        />
      );
    }
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/payment-summary",
            state: this.state.responseData,
            //type: "hold"
          }}
        />
      );
    }
    return (
      <section>{this.state.isLoading ? <Loading /> : <div></div>}</section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    cookieSabreDataRequest: state.seatSelection.cookieSabreDataRequest,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(SkipPaymentOption);
