import React, { Component } from "react";
import Translation from "i18next";
import { Col, Row } from "react-bootstrap";
class ManageTripPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreditCard: false,
      isTravelBank: false,
      isFloCash: false,
      isHold: false,
      isUnionPay: false,
    };
  }
  setActivePaymentOption(opt) {
    switch (opt) {
      case "CC":
        this.setState({
          isCreditCard: true,
          isUnionPay: false,
          isHold: false,
          isFloCash: false,
          isTravelBank: false,
        });
        break;
      case "TB":
        this.setState({
          isCreditCard: false,
          isUnionPay: false,
          isHold: false,
          isFloCash: false,
          isTravelBank: true,
        });
        break;
      case "AFOP":
        this.setState({
          isCreditCard: false,
          isUnionPay: false,
          isHold: false,
          isFloCash: true,
          isTravelBank: false,
        });
        break;
      case "HR":
        this.setState({
          isCreditCard: false,
          isUnionPay: false,
          isHold: true,
          isFloCash: false,
          isTravelBank: false,
        });
        break;
      case "UP":
        this.setState({
          isCreditCard: false,
          isUnionPay: true,
          isHold: false,
          isFloCash: false,
          isTravelBank: false,
        });
        break;
    }
  }
  setSelectedPaymentOption(option) {
    this.props.setSelectedPaymentOption(option);
    this.setActivePaymentOption(option);
  }
  render() {
    return (
      <div className="bg-white pt-3 px-2 px-sm-2 px-md-4 px-lg-4">
        <div className="select-payment-option-head py-3">
          <h3>{Translation.t("select_payment_form")}</h3>
        </div>
        <Row>
          <Col xs={6} sm={6} md={4} lg={3} xl={2}>
            <div
              className={
                this.state.isHold
                  ? "payment-option-col active"
                  : "payment-option-col"
              }
              onClick={() => this.setSelectedPaymentOption("HR")}
            >
              <img
                src="/defaultPaymentLogo.png"
                className="payment-option-logo"
              />
              <div className="pt-2">{Translation.t("hold_reservation")}</div>
            </div>
          </Col>

          {this.props.paymentInfo.travelBank != null && (
            <Col xs={6} sm={6} md={4} lg={3} xl={2}>
              <div
                className={
                  this.state.isTravelBank
                    ? "payment-option-col active"
                    : "payment-option-col"
                }
                onClick={() => this.setSelectedPaymentOption("TB")}
              >
                <img src="/travel-bank.png" className="payment-option-logo" />
                <div className="pt-2">{Translation.t("travel_bank")}</div>
              </div>
            </Col>
          )}
          {this.props.paymentInfo.creditCardOptions.length > 0 && (
            <Col xs={6} sm={6} md={4} lg={3} xl={2}>
              <div
                className={
                  this.state.isCreditCard
                    ? "payment-option-col active"
                    : "payment-option-col"
                }
                onClick={() => this.setSelectedPaymentOption("CC")}
              >
                <img src="/credit-card.png" className="payment-option-logo" />
                <div className="pt-2">{Translation.t("credit_card")}</div>
              </div>
            </Col>
          )}

          {this.props.paymentInfo.flocashOptions.length > 0 && (
            <Col xs={6} sm={6} md={4} lg={3} xl={2}>
              <div
                className={
                  this.state.isFloCash
                    ? "payment-option-col active"
                    : "payment-option-col"
                }
                onClick={() => this.setSelectedPaymentOption("AFOP")}
              >
                <img
                  src="/flocash.png"
                  className="payment-option-logo"
                  style={{
                    width: "100px",
                    objectFit: "cover",
                  }}
                />
                <div className="pt-2">{Translation.t("flocash")}</div>
              </div>
            </Col>
          )}
          {this.props.paymentInfo.unionPay != null && (
            <Col xs={6} sm={6} md={4} lg={3} xl={2}>
              <div
                className={
                  this.state.isUnionPay
                    ? "payment-option-col active"
                    : "payment-option-col"
                }
                onClick={() => this.setSelectedPaymentOption("UP")}
              >
                <img src="/unionpay-logo.png" className="payment-option-logo" />
                <div className="pt-2">
                  {this.props.paymentInfo.unionPay.paymentName}
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
export default ManageTripPaymentOption;
