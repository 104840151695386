export class HoldReservationRequestObj {
  constructor() {
    this.requestObj = {
      paymentRequired: false,
      payment: null,
      DepartureDate: "2020-10-25T12:05:56.895",
      holdOptionId: "BNPL_24Hrs",
      cookieSabreDataRequest: null,
    };
  }
}

export class HoldReservationRequestObjTwoHours {
  constructor() {
    this.requestObj = {
      paymentRequired: false,
      payment: [],
      IsConsentOn: false,
      DepartureDate:"",
      cookieSabreDataRequest: null,
    };
  }
}
export class payment {
  constructor() {
    this.requestObj = {
      "@type": "REMOTE",
      "@id": "1",
      amount: {
        amount: null,
        currency: "",
      },
    };
  }
}
