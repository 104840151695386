import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Select from "react-select";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import Month from "../../../master-data/shared/month.json";
import Day from "../../../master-data/shared/day.json";
import Translation from "i18next";
import "./PassengerInfo.css";
import ContactInfo from "./ContactInfo";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import { connect } from "react-redux";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import HttpService from "../../../service/shared/HttpService";
import { accountAction } from "../../../redux/actions/manage-my-booking/accountAction";
import moment from "moment";
class PassengerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFrequentFlyer: false,
      showSpecialRequest: false,
      passengerInfoChanged: false,
      savedNameList: [],
      showMealPreference: moment(this.props.departureDate).isAfter(
        moment().format("YYYY-MM-DD")
      ),
    };
    var currentYear = new Date().getFullYear();
    this.selectStyle = AirportSelectStyle.desktopStyle;
    this.passengerService = new PassengerService();
    this.Year = [];
    if (
      this.props.passengerList[this.props.index].passengerInfo.type === "child"
    ) {
      var childYearStart = currentYear - 2;
      for (var i = 0; i < 11; i++) {
        var tempChildYear = {
          value: childYearStart - i,
          label: childYearStart - i,
        };
        this.Year.push(tempChildYear);
      }
    } else if (
      this.props.passengerList[this.props.index].passengerInfo.type === "infant"
    ) {
      for (var i = 0; i < 3; i++) {
        var tempInfantYear = {
          value: currentYear - i,
          label: currentYear - i,
        };
        this.Year.push(tempInfantYear);
      }
    } else {
      var adultYear = currentYear - 12;
      for (var i = 0; i < 95; i++) {
        var tempAdultYear = {
          value: adultYear - i,
          label: adultYear - i,
        };
        this.Year.push(tempAdultYear);
      }
    }
  }
  componentDidMount() {
    const profileList = JSON.parse(localStorage.getItem("IBE_PassengerInfo"));
    if (profileList !== null) {
      this.setState({ savedNameList: Object.getOwnPropertyNames(profileList) });
    }
    if (this.props.isUserSignedIn && this.props.index === 0) {
      this.fillPassengerForm();
    }
    // document.getElementById("passenger-0-first-name").focus();
  }
  fillPassengerForm = async () => {
    this.setState({ passengerInfoChanged: false });

    if (this.props.userProfile === null) {
      // this.setState({ profileDataLoading: true });
      await HttpService.postAccount(
        this.props.profileCookie,
        "/ProfileRetrieve",
        this.props.token
      )
        .then((accountRetrieveResponse) => {
          if (accountRetrieveResponse.data.statusAPI === "SUCCESS") {
            this.props.fillPassengerForm(accountRetrieveResponse.data.profile);
            this.props.iniliazeProfile(accountRetrieveResponse.data.profile);
          }
          // this.setState({ profileDataLoading: false });
        })
        .catch((error) => {
          // this.setState({ profileDataLoading: false });
          console.log(error);
        });
    } else {
      this.props.fillPassengerForm(this.props.userProfile);
    }
  };
  enterAnotherPassenger = () => {
    this.setState({ passengerInfoChanged: true });
    this.props.enterAnotherPassenger();
  };
  render() {
    return (
      <>
        <Card className="mt-3">
          <Card.Header className="passanger-info-card-header passenger-header-sticky">
            <h5>
              {this.props.getHeaderText(this.props.index)} (
              {Translation.t(
                this.props.passengerList[this.props.index].passengerInfo.type
              )}
              )
            </h5>
          </Card.Header>
          <Card.Body className="passanger-info-card-body">
            {this.props.isUserSignedIn && (
              <div>
                {this.state.passengerInfoChanged ? (
                  <h6>
                    {this.props.firstName} {this.props.lastName} is loyalty
                    member.,{" "}
                    <a
                      href="#"
                      style={{ color: "#2e7d32" }}
                      onClick={this.fillPassengerForm}
                    >
                      <b> Pre-fill loyalty passenger information</b>
                    </a>
                    , or continue to enter new passenger info
                  </h6>
                ) : (
                  <h6>
                    You have signed in as {this.props.firstName}{" "}
                    {this.props.lastName}, so we've pre-filled this information.
                    If you are booking for someone else, then{" "}
                    <a
                      href="#"
                      style={{ color: "#2e7d32" }}
                      onClick={this.enterAnotherPassenger}
                    >
                      <b>ENTER NEW PASSENGER INFO</b>
                    </a>
                  </h6>
                )}
              </div>
            )}
            <p>{Translation.t("passange_form_message")}</p>
            <div></div>

            <h4 className="my-3">{Translation.t("basic_info")}</h4>
            <Row>
              {this.props.passengerList[this.props.index].passengerInfo.type ===
                "adult" && (
                <Col xs="12" sm={12} md={6} lg={2} className="px-1 my-2">
                  <Select
                    className={
                      this.props.passengerList[this.props.index].validation
                        .isprefixValid
                        ? null
                        : "is-invalid-select"
                    }
                    id={"passenger-" + this.props.index + "-prefix"}
                    isSearchable={false}
                    value={
                      this.props.passengerList[this.props.index]
                        .passengerDetails.prefix
                    }
                    onFocus={() =>
                      this.props.changeSelectValidation(
                        this.props.index,
                        "prefix",
                        "props.onFocus"
                      )
                    }
                    onBlur={() =>
                      this.props.changeSelectValidation(
                        this.props.index,
                        "prefix",
                        "onBlur"
                      )
                    }
                    onChange={(value) =>
                      this.props.updatePassengerMethod(
                        this.props.index,
                        value,
                        "passengerDetails",
                        "prefix",
                        null,
                        true
                      )
                    }
                    styles={this.selectStyle}
                    options={this.passengerService.getTitleList()}
                    placeholder={
                      this.props.passengerList[this.props.index].validation
                        .isprefixValid ? (
                        Translation.t("title")
                      ) : (
                        <span className="invalid-select-placeholder">
                          {Translation.t("title_required")}
                        </span>
                      )
                    }
                  ></Select>
                </Col>
              )}
              <Col xs="12" sm={12} md={6} lg={4} className="px-1 my-2">
                <input
                  className={
                    this.props.passengerList[this.props.index].validation
                      .isFirstNameValid
                      ? "form-control  widget-input"
                      : "form-control  widget-input invalid_input"
                  }
                  placeholder={
                    this.props.passengerList[this.props.index].validation
                      .isFirstNameValid
                      ? Translation.t("first_name")
                      : Translation.t("first_name_required")
                  }
                  id={"passenger-" + this.props.index + "-first-name"}
                  defaultValue={
                    this.props.passengerList[this.props.index].passengerDetails
                      .firstName
                  }
                  onKeyDown={(e) => this.props.onKeyDownFunction(e)}
                  onBlur={() => {
                    this.props.updatePassengerMethod(
                      this.props.index,
                      document.getElementById(
                        "passenger-" + this.props.index + "-first-name"
                      ).value,
                      "passengerDetails",
                      "firstName",
                      "passenger-" + this.props.index + "-first-name"
                    );
                  }}
                  onChange={() => {
                    if (this.props.index === 0) {
                      this.props.updatePassengerMethod(
                        this.props.index,
                        document.getElementById(
                          "passenger-" + this.props.index + "-first-name"
                        ).value,
                        "passengerDetails",
                        "firstName",
                        "passenger-" + this.props.index + "-first-name"
                      );
                    }
                  }}
                  onFocus={() =>
                    this.props.onFocus(
                      "passenger-" + this.props.index + "-first-name"
                    )
                  }
                  list={
                    this.props.index === 0
                      ? "passenger-0-first-name-list"
                      : null
                  }
                />
                {this.props.index === 0 && (
                  <datalist id="passenger-0-first-name-list">
                    {this.state.savedNameList.map((opt, i) => (
                      <option key={i}>{opt}</option>
                    ))}
                  </datalist>
                )}
              </Col>
              <Col
                xs="12"
                sm={12}
                md={6}
                lg={
                  this.props.passengerList[this.props.index].passengerInfo
                    .type !== "adult"
                    ? 4
                    : 3
                }
                className="px-1 my-2"
              >
                <input
                  className="form-control  widget-input"
                  placeholder={Translation.t("middle_name")}
                  id={"passenger-" + this.props.index + "-middle-name"}
                  defaultValue={
                    this.props.passengerList[this.props.index].passengerDetails
                      .middleName
                  }
                  onBlur={() => {
                    var inputValue = document.getElementById(
                      "passenger-" + this.props.index + "-middle-name"
                    ).value;
                    if (inputValue !== null && inputValue !== "") {
                      this.props.updatePassengerMethod(
                        this.props.index,
                        inputValue,
                        "passengerDetails",
                        "middleName"
                      );
                    }
                  }}
                  onKeyDown={(e) => this.props.onKeyDownFunction(e)}
                />
              </Col>
              <Col
                xs="12"
                sm={12}
                md={6}
                lg={
                  this.props.passengerList[this.props.index].passengerInfo
                    .type !== "adult"
                    ? 4
                    : 3
                }
                className="px-1 my-2"
              >
                <input
                  className={
                    this.props.passengerList[this.props.index].validation
                      .isLastNameValid
                      ? "form-control  widget-input"
                      : "form-control  widget-input invalid_input"
                  }
                  placeholder={
                    this.props.passengerList[this.props.index].validation
                      .isLastNameValid
                      ? Translation.t("last_name")
                      : Translation.t("last_name_required")
                  }
                  id={"passenger-" + this.props.index + "-last-name"}
                  defaultValue={
                    this.props.passengerList[this.props.index].passengerDetails
                      .lastName
                  }
                  onBlur={() => {
                    this.props.updatePassengerMethod(
                      this.props.index,
                      document.getElementById(
                        "passenger-" + this.props.index + "-last-name"
                      ).value,
                      "passengerDetails",
                      "lastName",
                      "passenger-" + this.props.index + "-last-name"
                    );
                  }}
                  onFocus={() => {
                    this.props.onFocus(
                      "passenger-" + this.props.index + "-last-name"
                    );
                  }}
                  onKeyDown={(e) => this.props.onKeyDownFunction(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Row className="mr-0">
                  <div className="col-4 pr-0">
                    <Select
                      className={
                        this.props.passengerList[this.props.index].validation
                          .isYearValid
                          ? null
                          : "is-invalid-select"
                      }
                      styles={this.selectStyle}
                      options={this.Year}
                      isSearchable={false}
                      id={"passenger-" + this.props.index + "birth-of-year"}
                      value={
                        this.props.passengerList[this.props.index].passengerInfo
                          .birthOfYear
                      }
                      onFocus={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "year",
                          "props.onFocus"
                        )
                      }
                      onBlur={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "year",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          value,
                          "passengerInfo",
                          "birthOfYear"
                        )
                      }
                      placeholder={
                        this.props.passengerList[this.props.index].validation
                          .isYearValid ? (
                          Translation.t("birth_year")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_year_required")}
                          </span>
                        )
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">Birth Year</label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      className={
                        this.props.passengerList[this.props.index].validation
                          .isMonthValid
                          ? null
                          : "is-invalid-select"
                      }
                      styles={this.selectStyle}
                      options={Month}
                      isSearchable={false}
                      placeholder={
                        this.props.passengerList[this.props.index].validation
                          .isMonthValid ? (
                          Translation.t("birth_month")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_month_required")}
                          </span>
                        )
                      }
                      id={"passenger-" + this.props.index + "birth-of-month"}
                      value={
                        this.props.passengerList[this.props.index].passengerInfo
                          .birthOfMonth
                      }
                      onFocus={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "month",
                          "props.onFocus"
                        )
                      }
                      onBlur={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "month",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          value,
                          "passengerInfo",
                          "birthOfMonth"
                        )
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">Birth Month</label>
                  </div>
                  <div className="col-4 px-0">
                    <Select
                      className={
                        this.props.passengerList[this.props.index].validation
                          .isDayValid
                          ? null
                          : "is-invalid-select"
                      }
                      styles={this.selectStyle}
                      options={Day}
                      isSearchable={false}
                      placeholder={
                        this.props.passengerList[this.props.index].validation
                          .isDayValid ? (
                          Translation.t("birth_day")
                        ) : (
                          <span className="invalid-select-placeholder">
                            {Translation.t("birth_day_required")}
                          </span>
                        )
                      }
                      id={"passenger-" + this.props.index + "birth-of-day"}
                      value={
                        this.props.passengerList[this.props.index].passengerInfo
                          .birthOfDay
                      }
                      onFocus={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "day",
                          "props.onFocus"
                        )
                      }
                      onBlur={() =>
                        this.props.changeSelectValidation(
                          this.props.index,
                          "day",
                          "onBlur"
                        )
                      }
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          value,
                          "passengerInfo",
                          "birthOfDay"
                        )
                      }
                    ></Select>
                    <label className="form-sub-label mx-2">Birth Day</label>
                  </div>
                </Row>
              </Col>
              <Col xs={12} sm={12} md={6} className="px-1 my-2">
                <Select
                  className={
                    this.props.passengerList[this.props.index].validation
                      .isGenderValid
                      ? null
                      : "is-invalid-select"
                  }
                  styles={this.selectStyle}
                  options={this.passengerService.getGenderList()}
                  isSearchable={false}
                  placeholder={
                    this.props.passengerList[this.props.index].validation
                      .isGenderValid ? (
                      Translation.t("gender")
                    ) : (
                      <span className="invalid-select-placeholder">
                        {Translation.t("gender_required")}
                      </span>
                    )
                  }
                  id={"passenger-" + this.props.index + "gender"}
                  value={
                    this.props.passengerList[this.props.index].passengerInfo
                      .gender
                  }
                  onFocus={() =>
                    this.props.changeSelectValidation(
                      this.props.index,
                      "gender",
                      "props.onFocus"
                    )
                  }
                  onBlur={() =>
                    this.props.changeSelectValidation(
                      this.props.index,
                      "gender",
                      "onBlur"
                    )
                  }
                  onChange={(value) =>
                    this.props.updatePassengerMethod(
                      this.props.index,
                      value,
                      "passengerInfo",
                      "gender"
                    )
                  }
                ></Select>
              </Col>
            </Row>
            {this.props.index === 0 &&
            this.props.passengerList[this.props.index].passengerInfo.type ===
              "adult" ? (
              <ContactInfo
                updatePassengerMethod={this.props.updatePassengerMethod}
              />
            ) : null}

            <h4 className="my-3">{Translation.t("additional_info")}</h4>
            <Row>
              <Col xs={12} sm={12} md={6}>
                <span className="add_flight_btn my-2">
                  {this.state.showFrequentFlyer ? (
                    <span>
                      <Button
                        className="btn-danger text-white  mr-2 btn-sm"
                        style={{ minWidth: "27.58px" }}
                        onClick={() =>
                          this.setState({
                            showFrequentFlyer: !this.state.showFrequentFlyer,
                          })
                        }
                      >
                        -
                      </Button>{" "}
                      <label>{Translation.t("remove_frequent_flyer")}</label>
                    </span>
                  ) : (
                    <span>
                      <Button
                        className="btn-success std-et-bg-color text-white btn mr-2 btn-sm"
                        style={{ minWidth: "27.58px" }}
                        onClick={() =>
                          this.setState({
                            showFrequentFlyer: !this.state.showFrequentFlyer,
                          })
                        }
                      >
                        +
                      </Button>{" "}
                      <label>{Translation.t("add_frequent_flyer")}</label>
                    </span>
                  )}
                </span>
              </Col>
              <Col xs={12} sm={12} md={6}>
                <span className="add_flight_btn my-2">
                  {this.state.showSpecialRequest ? (
                    <span>
                      <Button
                        className="btn-danger text-white  mr-2 btn-sm"
                        style={{ minWidth: "27.58px" }}
                        onClick={() =>
                          this.setState({
                            showSpecialRequest: !this.state.showSpecialRequest,
                          })
                        }
                      >
                        -
                      </Button>{" "}
                      <label>{Translation.t("remove_special_request")}</label>
                    </span>
                  ) : (
                    <span>
                      <Button
                        className="btn-success std-et-bg-color text-white btn mr-2 btn-sm"
                        style={{ minWidth: "27.58px" }}
                        onClick={() =>
                          this.setState({
                            showSpecialRequest: !this.state.showSpecialRequest,
                          })
                        }
                      >
                        +
                      </Button>{" "}
                      <label>{Translation.t("add_special_request")}</label>
                    </span>
                  )}
                </span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {this.state.showFrequentFlyer || this.state.showSpecialRequest ? (
          <Card>
            <Card.Body>
              {this.state.showFrequentFlyer ? (
                <Row>
                  <div className="col-12">
                    <h5>{Translation.t("frequent_flyer")}</h5>
                    <p>{Translation.t("frequent_flyer_message")}</p>
                    <p>
                      {this.props.searchRequest.itineraryParts.map(
                        (itinerary, index) => {
                          if (
                            index !== 0 &&
                            index !==
                              this.props.searchRequest.itineraryParts.length
                          ) {
                            return (
                              <b key={index}>
                                {itinerary.from.code} to {"  "}{" "}
                                {itinerary.to.code}
                              </b>
                            );
                          } else {
                            return (
                              <b key={index}>
                                {itinerary.from.code} to {"  "}{" "}
                                {itinerary.to.code} |{" "}
                              </b>
                            );
                          }
                        }
                      )}
                    </p>
                  </div>
                  <div className="col-12 col-lg-6 my-2">
                    <Select
                      styles={this.selectStyle}
                      isSearchable={false}
                      options={this.passengerService.getFrequentFlyer()}
                      placeholder={Translation.t("frequent_flyer_programme")}
                      value={
                        this.props.passengerList[this.props.index].preferences
                          .frequentFlyerProgramme
                      }
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          value,
                          "preferences",
                          "frequentFlyerProgramme"
                        )
                      }
                    ></Select>
                  </div>
                  <div className="col-12 col-lg-6 my-2">
                    <input
                      className="form-control  widget-input"
                      placeholder={Translation.t("frequent_flyer_numbers")}
                      defaultValue={
                        this.props.passengerList[this.props.index].preferences
                          .frequentFlyerNumber
                      }
                      id={"frequent-flyer-number-" + this.props.index}
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          document.getElementById(
                            "frequent-flyer-number-" + this.props.index
                          ).value,
                          "preferences",
                          "frequentFlyerNumber"
                        )
                      }
                    />
                  </div>
                </Row>
              ) : null}
              {this.state.showSpecialRequest ? (
                <Row>
                  <div className="col-12 mt-4">
                    <h5>{Translation.t("special_requests")}</h5>
                  </div>
                  <div className="col-12 col-lg-6">
                    <Select
                      styles={this.selectStyle}
                      isSearchable={false}
                      options={this.passengerService.getSpecialRequests()}
                      placeholder={Translation.t("special_requests")}
                      value={
                        this.props.passengerList[this.props.index].preferences
                          .specialRequests
                      }
                      onChange={(value) =>
                        this.props.updatePassengerMethod(
                          this.props.index,
                          value,
                          "preferences",
                          "specialRequests"
                        )
                      }
                    ></Select>
                  </div>
                  {this.state.showMealPreference && (
                    <div className="col-12 col-lg-6">
                      <Select
                        styles={this.selectStyle}
                        isSearchable={false}
                        options={this.passengerService.getMealPreference()}
                        placeholder={Translation.t("meal_preference")}
                        value={
                          this.props.passengerList[this.props.index].preferences
                            .mealPreference
                        }
                        onChange={(value) =>
                          this.props.updatePassengerMethod(
                            this.props.index,
                            value,
                            "preferences",
                            "mealPreference"
                          )
                        }
                      ></Select>
                    </div>
                  )}
                </Row>
              ) : null}
            </Card.Body>
          </Card>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchRequest: state.searchFlight.searchRequest,
    passengerList: state.passenger.passengerList,
    firstName: state.account.createAccountModle.firstName,
    lastName: state.account.createAccountModle.lastName,
    isUserSignedIn: state.global.isUserSignedIn,
    userProfile: state.account.profile,
    profileCookie: state.account.profileCookie,
    token: state.searchFlight.token,
    departureDate: state.searchFlight.searchRequest.itineraryParts[0].when.date,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeSelectValidation: (index, domain, className) =>
      dispatch({
        type: passengerAction.CHANGEVALIDATION,
        index: index,
        domain: domain,
        className: className,
      }),
    enterAnotherPassenger: () =>
      dispatch({
        type: passengerAction.REMOVEFILLEDPASSENGERFORM,
      }),
    fillPassengerForm: (userProfile) =>
      dispatch({
        type: passengerAction.FILLPASSENGERFORM,
        userProfile: userProfile,
      }),
    iniliazeProfile: (data) =>
      dispatch({
        type: accountAction.INTIALZEPROFILE,
        data: data,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PassengerInfo);
