export class TravelBankRequestObj
{
    constructor()
    {
        this.requestObj={
            "payment": [
                {
                    "@type": "TRAVEL_BANK",
                    "amount": {
                        "amount":  null,
                        "currency": null
                    },
                    "paymentCode": "BT"
                }
            ],
            "paymentRequired": false,
            "cookieSabreDataRequest": null
        }
    }
}