import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "../../../master-data/locale/en/transaltion-en.json";
import translationAR from "../../../master-data/locale/ar/transaltion.json";
import translationDE from "../../../master-data/locale/de/transaltion.json";
import translationES from "../../../master-data/locale/es/transaltion.json";
import translationFR from "../../../master-data/locale/fr/transaltion.json";
import translationIT from "../../../master-data/locale/it/transaltion.json";
import translationPT from "../../../master-data/locale/pt/transaltion.json";
import translationZH from "../../../master-data/locale/zh/transaltion.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ar", "fr", "zh", "pt", "es", "de", "it"];

const resources = {
  en: {
    translation: translationEN
  },
  ar : {
    translation: translationAR
  },
  fr : {
    translation: translationFR
  },
  zh : {
    translation: translationZH
  },
  pt : {
    translation: translationPT
  },
  es : {
    translation: translationES
  },
  de : {
    translation: translationDE
  },
  it : {
    translation: translationIT
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,

    detection: {
      checkWhitelist: true
    },
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false
    }
  });
export default i18n;
