import Translation from "i18next";
import CreditCard from "../../../component/booking/payment/payment-options/credit-card/CreditCard"
import { CreditCardRequestObj } from "../../../model/payment/CreditCardRequestObj";
import { FloCashRequestObj } from "../../../model/payment/FloCashRequestObj";
import { UnionPayRequestObj } from "../../../model/payment/UnionPayRequestObj";
export class PaymentService
{
    static getCountryList=(CountryList)=>{
        
        let countryListTemp = [];
        CountryList.forEach(country => {
            countryListTemp.push( { value: country.countryShortCode, label: country.countryName  });
        });
        return countryListTemp;
    }
    static getCreditCardTypes(creditCardOptions)
    {
       
        let creditCardList =[];

        creditCardOptions.forEach(creditCard => {
            
            let creditCardOption = null;
            switch (creditCard.paymentInfo.paymentCode) {
                case "DS":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label:Translation.t("discover")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                case "AX":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label: Translation.t("american_express")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                case "DC":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label: Translation.t("dinners_club")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                case "MA":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label: Translation.t("maestro")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                case "VI":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label: Translation.t("visa")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                case "CA":
                    creditCardOption = {
                        value: creditCard.paymentInfo.paymentCode,
                        label: Translation.t("master_card")
                    };
                    creditCardList.push(creditCardOption);
                    break;
                default:
                    break;
            }

           
        });
  
        return creditCardList;
    }
   static mapToRequestObject(creditCardInfo)
    {
        
        let creditCardRequestObject = new CreditCardRequestObj();
        creditCardRequestObject.requestObj.payment[0].amount.amount = creditCardInfo.Amount;
        creditCardRequestObject.requestObj.payment[0].amount.currency = creditCardInfo.Currency;
        creditCardRequestObject.requestObj.payment[0].number = creditCardInfo.CreditCardNumber;
        creditCardRequestObject.requestObj.payment[0].cvc = creditCardInfo._CVC;
       creditCardRequestObject.requestObj.payment[0].holderName = creditCardInfo.FullName;
       creditCardRequestObject.requestObj.payment[0].expirationDate = creditCardInfo.ExpireYear + "-"+creditCardInfo.ExpireMonth;
        creditCardRequestObject.requestObj.payment[0].cardCode = creditCardInfo.CreditCardType;
        creditCardRequestObject.requestObj.billingData.street1 = creditCardInfo.BillingAddess;
        creditCardRequestObject.requestObj.billingData.street2 = creditCardInfo.Address;
        creditCardRequestObject.requestObj.billingData.city = creditCardInfo.City;
        creditCardRequestObject.requestObj.billingData.zipCode = creditCardInfo.PostalCode;
        creditCardRequestObject.requestObj.billingData.country = creditCardInfo.Country;
        creditCardRequestObject.requestObj.billingData.phone.countryCode = creditCardInfo.PhoneCountryCode;
        creditCardRequestObject.requestObj.billingData.phone.number = creditCardInfo.PhoneNumber.substring(creditCardInfo.PhoneCountryCode.length);
        creditCardRequestObject.requestObj.billingData.email = creditCardInfo.Email;
         return  creditCardRequestObject.requestObj;
    }
    static mapToUnionPayRequestObject(unionPayInfo)
    {
        
        let unionPayRequestObject = new UnionPayRequestObj();
        unionPayRequestObject.requestObj.payment[0]["@type"]="AFOP";
        unionPayRequestObject.requestObj.payment[0].amount.amount = unionPayInfo.Amount;
        unionPayRequestObject.requestObj.payment[0].amount.currency = unionPayInfo.Currency;
        unionPayRequestObject.requestObj.payment[0].fopCode = unionPayInfo.FopCode;
        unionPayRequestObject.requestObj.payment[0].fopSubcode = unionPayInfo.FopSubCode;
        unionPayRequestObject.requestObj.payment[0].afopClientDetail.email = unionPayInfo.Email;
        unionPayRequestObject.requestObj.payment[0].afopClientDetail.country = unionPayInfo.Country;

        return unionPayRequestObject.requestObj;
    }
    static mapToFlocashRequestObject(floCashInfo)
    {
        let floCashRequestObject = new FloCashRequestObj();
        floCashRequestObject.requestObj.Amount = floCashInfo.Amount;
        floCashRequestObject.requestObj.Currency = floCashInfo.Currency;
        floCashRequestObject.requestObj.Email = floCashInfo.Email;
        floCashRequestObject.requestObj.Country = floCashInfo.Country;
        floCashRequestObject.requestObj.FirstName = floCashInfo.FirstName;
        floCashRequestObject.requestObj.LastName = floCashInfo.LastName;
        floCashRequestObject.requestObj.PhoneNumber.CountryCode = floCashInfo.PhoneCountryCode;
        floCashRequestObject.requestObj.PhoneNumber.Number = floCashInfo.PhoneNumber.substring(floCashInfo.PhoneCountryCode.length);;
        floCashRequestObject.requestObj.PaymentID = floCashInfo.PaymentID;
        return floCashRequestObject.requestObj;
    }
}