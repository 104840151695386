import React, { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
// import ItineraryPartBrandItem from "./ItineraryPartBrandItem";
// import ItineraryPartBrandSelector from "./ItineraryPartBrandSelector";
import Translation from "i18next";

class ExchangeItineraryBrand extends Component {
  // constructor(props) {
  //   super(props);
  // }

  renderEconomyBrand() {
    if (this.props.isEconomySelected) {
      return (
        <div className={"my-4 itinerary-brand-container"}>
          <div className="itinerary-brand-header">
            <Row className="m-0">
              <Col className="itinerary-brand-col col">
                <div className="text-center itinerary-brand-item-head py-3">
                  {Translation.t("economy_result")}: {this.props.isActive}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("fare")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("tax")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("seat_left")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("total")}
                </div>
              </Col>
              {this.props.itineraryPartBrand.brandOffers.map(
                (brand, key) =>
                  brand.cabinClass === "Economy" && (
                    <Col key={key} className="itinerary-brand-col col">
                      <div className="text-center itinerary-brand-item-head py-3">
                        {brand.brandLabel}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.fare.alternatives[0][0].currency}{" "}
                        {brand.fare.alternatives[0][0].amount}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.taxes.alternatives[0][0].currency}{" "}
                        {brand.taxes.alternatives[0][0].amount}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.seatsRemaining.count}
                      </div>
                      <div className="text-center itinerary-brand-item py-3 total-price">
                        {brand.total.alternatives[0][0].currency}{" "}
                        {brand.total.alternatives[0][0].amount}
                      </div>

                      <div className="text-center itinerary-brand-item py-3">
                        <Button
                          className="brand-selector-btn"
                          onClick={() =>
                            this.props.selectFlight(
                              brand.shoppingBasketHashCode
                            )
                          }
                        >
                          Select
                        </Button>
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </div>
      );
    }
  }
  renderBusinessBrand() {
    if (this.props.isBusinessSelected) {
      return (
        <div className={"my-4 itinerary-brand-container"}>
          <div className="itinerary-brand-header">
            <Row className="m-0">
              <Col className="itinerary-brand-col col">
                <div className="text-center itinerary-brand-item-head py-3">
                  {Translation.t("business_result")}: {this.props.isActive}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("fare")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("tax")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("seat_left")}
                </div>
                <div className="text-justify itinerary-brand-item first py-3">
                  {Translation.t("total")}
                </div>
              </Col>
              {this.props.itineraryPartBrand.brandOffers.map(
                (brand, key) =>
                  brand.cabinClass === "Business" && (
                    <Col key={key} className="itinerary-brand-col col">
                      <div className="text-center itinerary-brand-item-head py-3">
                        {brand.brandLabel}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.fare.alternatives[0][0].currency}{" "}
                        {brand.fare.alternatives[0][0].amount}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.taxes.alternatives[0][0].currency}{" "}
                        {brand.taxes.alternatives[0][0].amount}
                      </div>
                      <div className="text-center itinerary-brand-item py-3">
                        {brand.seatsRemaining.count}
                      </div>
                      <div className="text-center itinerary-brand-item py-3 total-price">
                        {brand.total.alternatives[0][0].currency}{" "}
                        {brand.total.alternatives[0][0].amount}
                      </div>

                      <div className="text-center itinerary-brand-item py-3">
                        <Button
                          className="brand-selector-btn"
                          onClick={() =>
                            this.props.selectFlight(
                              brand.shoppingBasketHashCode
                            )
                          }
                        >
                          Select
                        </Button>
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        {this.renderEconomyBrand()}
        {this.renderBusinessBrand()}
      </div>
    );
  }
}
export default ExchangeItineraryBrand;
