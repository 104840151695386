export  class ExchangeIntialRequest{

    constructor()
    {
        this.requestObject ={
            "PNR" : null,
            "lastName":null
          }
    }
}
