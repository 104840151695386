import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Translation from "i18next";
import { connect } from "react-redux";
import NewCreditCardInput from "./NewCreditCardInput";
import EditCreditCardInput from "./EditCreditCard";
import UserName from "../../shared/UserName";

const BillingInfo = (props) => {
  const [showCardForm, setShowCardForm] = useState(false);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="media">
            <UserName />
            <div className="media-body mx-2">
              <h3 className="frequent-flyer-h3">
                {Translation.t("biiling_info_title")}
              </h3>

              <p>{Translation.t("biiling_info_description")}</p>
            </div>
          </div>
        </Col>
      </Row>
      <div
        style={{ backgroundColor: "#a4adc6" }}
        className="mt-2 py-2 d-none d-md-block"
      >
        <Row className="w-100 m-0 p-0">
          <Col md={3}>
            <p>{Translation.t("card_number")}</p>
          </Col>
          <Col md={3}>
            <p>{Translation.t("name_on_card")} </p>
          </Col>
          <Col md={3}>
            <p>{Translation.t("expiry_date")}</p>
          </Col>
          <Col md={3}>
            <p>{Translation.t("action")}</p>
          </Col>
        </Row>
      </div>
      <EditCreditCardInput setShowCardForm={setShowCardForm} />
      <div className="pt-2" style={{ backgroundColor: "white" }}>
        <Row>
          <Col sm={12}>
            <p
              className="text-center user-another-card"
              onClick={() => setShowCardForm(!showCardForm)}
            >
              {showCardForm ? "- " : "+ "}
              {Translation.t("use_another_card")}
            </p>
          </Col>
        </Row>
        {showCardForm && (
          <NewCreditCardInput setShowCardForm={setShowCardForm} />
        )}
      </div>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    firstName: state.account.firstName,
    lastName: state.account.lastName,
  };
};
export default connect(mapStateTopProps, null)(BillingInfo);
