import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import "./CheckIn.css";
import Select from "react-select";
import AirportSelectStyle from "../../master-data/booking-widget/airpot-select-style";
import { connect } from "react-redux";
import { checkInAction } from "../../redux/actions/check-in/checkInAction";
import AirportListEn from "../../master-data/locale/en/airport-list-en.json";

import Translation from "i18next";
import CheckInService from "../../service/check-in/CheckInService";
import Loading from "../booking/seat-selection/Loading";
import { useHistory } from "react-router-dom";
import HttpService from "../../service/shared/HttpService";

const customStylesSelect = AirportSelectStyle.desktopStyle;
const airPortList = AirportListEn;
const CheckIn = (props) => {
  let history = useHistory();

  const [showAdditionalOption, setShowAdditonalOption] = useState(false);
  const [isLodaing, setIsLoading] = useState(false);
  const [isErrorOcurred, setIsErrorOcurred] = useState(false);
  const [isErrorOcurredMsg, setIsErrorOcurredMsg] = useState(false);
  const [pnrValidationMsg, setPnrValidationMsg] = useState("");
  // const [pnrValidationMsg, setPnrValidationMsg] = useState("");
  // var checkInModel = new CheckInModel();
  const [checkInModelState, setCheckInModelState] = useState({
    pnr: "",
    firstName: "",
    lastName: "",
    fnFirstName: "",
    cnFirstName: "",
    fnLastName: "",
    cnLastName: "",
    tnLastName: "",
    searchType: {
      label: "Flight Number",
      value: "Flight Number",
    },
    flightNumber: false,
    DepartureAirport: false,
    arrivingCity: false,
    ticketNumber: false,

    pnrValidation: true,
    firstNameValidation: true,
    lastNameValidation: true,
    fnFirstNameValidation: true,
    cnFirstNameValidation: true,
    fnLastNameValidation: true,
    cnLastNameValidation: true,
    tnLastNameValidation: true,
    flightNumberValidation: true,
    DepartureAirportValidation: true,
    arrivingCityValidation: true,
    ticketNumberValidation: true,
  });
  const updateCheckInModel = (
    event,
    fromSelect = false,
    DepartOrArrive = false
  ) => {
    var temp = { ...checkInModelState };
    if (fromSelect) {
      if (DepartOrArrive) {
        temp.DepartureAirport = event;
      } else {
        temp.arrivingCity = event;
      }
    } else {
      if (event.target.id === "pnr") {
        if (event.target.value.length < 19) temp.pnr = event.target.value;
      }
      //for firstname
      if (event.target.id === "First Name") {
        temp.firstName = event.target.value;
      }
      if (event.target.id === "FN First Name") {
        temp.fnFirstName = event.target.value;
      }
      if (event.target.id === "CN First Name") {
        temp.cnFirstName = event.target.value;
      }
      // for last name
      if (event.target.id === "Last Name") {
        temp.lastName = event.target.value;
      }
      if (event.target.id === "FN Last Name") {
        temp.fnLastName = event.target.value;
      }
      if (event.target.id === "CN Last Name") {
        temp.cnLastName = event.target.value;
      }
      if (event.target.id === "TN Last Name") {
        temp.tnLastName = event.target.value;
      }

      if (event.target.id === "Ticket Number") {
        if (
          parseInt(event.target.value) < 10000000000000 ||
          event.target.value === ""
        )
          temp.ticketNumber = event.target.value;
      }
      if (event.target.id === "Flight Number") {
        if (parseInt(event.target.value) < 10000 || event.target.value === "")
          temp.flightNumber = event.target.value;
      }
    }
    setCheckInModelState(temp);
  };

  const updateValidation = (name, value, event) => {
    var temp = { ...checkInModelState };

    if (name === "pnrValidation") {
      if (value) {
        var tempLen = temp.pnr.length;
        var tempValue = temp.pnr.length;
        if (event !== undefined) {
          tempLen = event.target.value.length;
          tempValue = event.target.value;
        }
        if (
          !(tempLen === 5 || tempLen === 6) &&
          (isNaN(tempValue) || tempLen < 7)
        ) {
          setPnrValidationMsg(
            "Record Locator must have 5 or 6 alphanumeric characters. Frequent Flyer Number must have 7 to 18 digits."
          );
          temp[name] = false;
        } else {
          setPnrValidationMsg("");
          temp[name] = true;
        }
      }
    } else {
      temp[name] = value;
      setCheckInModelState(temp);
    }
  };

  const onKeyDownFunction = (e) => {
    var keynum;
    if (window.event) {
      keynum = e.keyCode;
    } else if (e.which) {
      keynum = e.which;
    }
    let isValid =
      (keynum <= 90 && keynum >= 65) ||
      keynum === 8 ||
      keynum === 9 ||
      keynum === 13 ||
      keynum === 32 ||
      keynum === 36 ||
      keynum === 37 ||
      keynum === 38 ||
      keynum === 39 ||
      keynum === 40;

    if (!isValid) {
      e.preventDefault();
    }
  };

  const resetCheckinModel = () => {
    setCheckInModelState({
      pnr: "",
      firstName: "",
      lastName: "",
      fnFirstName: "",
      cnFirstName: "",
      fnLastName: "",
      cnLastName: "",
      tnLastName: "",
      searchType: {
        label: "Flight Number",
        value: "Flight Number",
      },
      flightNumber: "",
      DepartureAirport: "",
      arrivingCity: "",
      ticketNumber: "",

      pnrValidation: true,
      firstNameValidation: true,
      lastNameValidation: true,
      fnFirstNameValidation: true,
      cnFirstNameValidation: true,
      fnLastNameValidation: true,
      cnLastNameValidation: true,
      tnLastNameValidation: true,
      flightNumberValidation: true,
      DepartureAirportValidation: true,
      arrivingCityValidation: true,
      ticketNumberValidation: true,
    });
    setPnrValidationMsg("");
  };

  const isFormValid = () => {
    if (!showAdditionalOption) {
      return (
        checkInModelState.pnrValidation &&
        checkInModelState.lastNameValidation &&
        checkInModelState.pnr !== ""
        // && checkInModelState.lastName !== ""
      );
    } else if (props.searchType.value === "City Name") {
      return (
        checkInModelState.DepartureAirportValidation &&
        checkInModelState.arrivingCityValidation &&
        checkInModelState.cnLastNameValidation &&
        checkInModelState.DepartureAirport !== "" &&
        checkInModelState.arrivingCity !== "" &&
        checkInModelState.cnLastName !== ""
      );
    } else if (props.searchType.value === "Ticket Number") {
      return (
        checkInModelState.ticketNumberValidation &&
        checkInModelState.tnLastNameValidation &&
        checkInModelState.ticketNumber !== "" &&
        checkInModelState.tnLastName !== ""
      );
    } else {
      return (
        checkInModelState.DepartureAirportValidation &&
        checkInModelState.fnLastNameValidation &&
        checkInModelState.DepartureAirport !== "" &&
        checkInModelState.fnLastName !== ""
      );
    }
  };

  const hideOptionBody = () => {
    if (props.searchType.value === "City Name") {
      return (
        <div>
          <Select
            styles={customStylesSelect}
            id="Departure City"
            options={airPortList}
            className={
              checkInModelState.DepartureAirportValidation
                ? "my-2"
                : "my-2 is-invalid-select"
            }
            placeholder={
              checkInModelState.DepartureAirportValidation ? (
                <span>{Translation.t("select_departure_airport")}</span>
              ) : (
                <span style={{ color: "rgb(147 0 0)" }}>
                  {Translation.t("select_departure_airport_required")}
                </span>
              )
            }
            onBlur={() =>
              updateValidation(
                "DepartureAirportValidation",
                checkInModelState.DepartureAirport !== ""
              )
            }
            onFocus={() => updateValidation("DepartureAirportValidation", true)}
            value={checkInModelState.DepartureAirport}
            onChange={(event) => updateCheckInModel(event, true, true)}
          />
          <Select
            styles={customStylesSelect}
            id="Arriving City"
            options={airPortList}
            className={
              checkInModelState.arrivingCityValidation
                ? "my-2"
                : "my-2 is-invalid-select"
            }
            placeholder={
              checkInModelState.arrivingCityValidation ? (
                <span>Arriving City *</span>
              ) : (
                <span style={{ color: "rgb(147 0 0)" }}>
                  Arriving City Required *
                </span>
              )
            }
            onBlur={() =>
              updateValidation(
                "arrivingCityValidation",
                checkInModelState.arrivingCity !== ""
              )
            }
            onFocus={() => updateValidation("arrivingCityValidation", true)}
            value={checkInModelState.arrivingCity}
            onChange={(event) => updateCheckInModel(event, true, false)}
          />
          <input
            id="CN First Name"
            value={checkInModelState.cnFirstName}
            className="form-control widget-input my-2"
            placeholder="First Name"
            onKeyDown={(e) => onKeyDownFunction(e)}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
          <input
            id="CN Last Name"
            value={checkInModelState.cnLastName}
            onChange={(event) => updateCheckInModel(event)}
            className={
              checkInModelState.cnLastNameValidation
                ? "form-control widget-input my-2"
                : "form-control widget-input my-2 invalid_input"
            }
            placeholder={
              checkInModelState.cnLastNameValidation
                ? Translation.t("last_name")
                : Translation.t("last_name_required")
            }
            onKeyDown={(e) => onKeyDownFunction(e)}
            onBlur={() =>
              updateValidation(
                "cnLastNameValidation",
                checkInModelState.cnLastName !== ""
              )
            }
            onFocus={() => updateValidation("cnLastNameValidation", true)}
            autoComplete="off"
          />
        </div>
      );
    } else if (props.searchType.value === "Ticket Number") {
      return (
        <div>
          <input
            id="Ticket Number"
            type="number"
            value={checkInModelState.ticketNumber}
            className={
              checkInModelState.ticketNumberValidation
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            placeholder={
              checkInModelState.ticketNumberValidation
                ? "Ticket Number *"
                : "Ticket Number Required *"
            }
            onBlur={() =>
              updateValidation(
                "ticketNumberValidation",
                checkInModelState.tnLastName !== ""
              )
            }
            onFocus={() => updateValidation("ticketNumberValidation", true)}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
          <input
            value={checkInModelState.tnLastName}
            onChange={(event) => updateCheckInModel(event)}
            id="TN Last Name"
            className={
              checkInModelState.tnLastNameValidation
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            placeholder={
              checkInModelState.tnLastNameValidation
                ? Translation.t("last_name")
                : Translation.t("last_name_required")
            }
            onKeyDown={(e) => onKeyDownFunction(e)}
            onBlur={() =>
              updateValidation(
                "tnLastNameValidation",
                checkInModelState.tnLastName !== ""
              )
            }
            onFocus={() => updateValidation("tnLastNameValidation", true)}
            autoComplete="off"
          />
        </div>
      );
    } else {
      return (
        <div>
          <input
            id="Flight Number"
            type="number"
            value={checkInModelState.flightNumber}
            className={
              checkInModelState.flightNumberValidation
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            placeholder={
              checkInModelState.flightNumberValidation
                ? "Flight Number *"
                : "Flight Number Required *"
            }
            onBlur={() =>
              updateValidation(
                "flightNumberValidation",
                checkInModelState.flightNumber !== ""
              )
            }
            onFocus={() => updateValidation("flightNumberValidation", true)}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
          <Select
            styles={customStylesSelect}
            id="Departure City"
            options={airPortList}
            className={
              checkInModelState.DepartureAirportValidation
                ? "my-2"
                : "my-2 is-invalid-select"
            }
            placeholder={
              checkInModelState.DepartureAirportValidation ? (
                <span>{Translation.t("select_departure_airport")}</span>
              ) : (
                <span style={{ color: "rgb(147 0 0)" }}>
                  {Translation.t("select_departure_airport_required")}
                </span>
              )
            }
            onBlur={() =>
              updateValidation(
                "DepartureAirportValidation",
                checkInModelState.DepartureAirport !== ""
              )
            }
            onFocus={() => updateValidation("DepartureAirportValidation", true)}
            value={checkInModelState.DepartureAirport}
            onChange={(event) => updateCheckInModel(event, true, true)}
          />
          <input
            id="FN First Name"
            className="form-control  widget-input my-2"
            placeholder="First Name"
            onKeyDown={(e) => onKeyDownFunction(e)}
            value={checkInModelState.fnFirstName}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
          <input
            id="FN Last Name"
            className={
              checkInModelState.fnLastNameValidation
                ? "form-control  widget-input my-2"
                : "form-control  widget-input my-2 invalid_input"
            }
            placeholder={
              checkInModelState.fnLastNameValidation
                ? Translation.t("last_name")
                : Translation.t("last_name_required")
            }
            onKeyDown={(e) => onKeyDownFunction(e)}
            onBlur={() =>
              updateValidation(
                "fnLastNameValidation",
                checkInModelState.fnLastName !== ""
              )
            }
            onFocus={() => updateValidation("fnLastNameValidation", true)}
            value={checkInModelState.fnLastName}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
        </div>
      );
    }
  };

  const retrieve = () => {
    // var temp = "/etibe-checkinapi/V1.0/PNR/Lookup";
    setIsLoading(true);
    var checkIn = new CheckInService();

    checkIn
      .pnrLookUp(
        { pnr: checkInModelState.pnr, lastName: checkInModelState.lastName },
        props.token
      )
      .then((response) => {
        var activepassengers = {};
        setIsLoading(false);
        if (response.data.statusAPI === "ERROR") {
          if (response.data.errorCode === "ERR.DCCI.RESERVATION_NOT_FOUND") {
            setIsErrorOcurred(true);
            setIsErrorOcurredMsg(response.data.messageAPI);
          } else {
            history.push("/check-in/error");
          }
        } else {
          var ticketedPnr = true;
          var checkedInList = [];

          response.data.reservation.passengers.passenger.map((passenger) => {
            if (passenger.ticket !== null) {
              ticketedPnr = false;
            }
            if (
              passenger.passengerSegments.passengerSegment[0].passengerFlight[0]
                .checkedIn
            ) {
              checkedInList.push("true");
            }
            activepassengers[passenger.id] = passenger;
            return null;
          });

          console.log(
            checkedInList,
            response.data.reservation.passengers.passenger.length
          );

          var alreadyCheckedIn =
            checkedInList.length ===
            response.data.reservation.passengers.passenger.length;

          if (ticketedPnr) {
            history.push("/check-in/error");
          } else {
            props.updateCheckInSabreDataRequest(
              response.data.checkInSabreDataRequest
            );
            props.initActivePassengers(activepassengers);
            props.updateReservation(response.data.reservation);
            if (alreadyCheckedIn) {
              history.push("/check-in/confirmation");
            } else {
              history.push("/check-in/itinerary");
            }
          }
        }
        //  statusAPI: "ERROR"
      })
      .catch((error) => {
        HttpService.getTokenServive().then((response) => {
          this.props.saveToken(response.data.tokenDetail.access_token);

          checkIn
            .pnrLookUp(
              {
                pnr: checkInModelState.pnr,
                lastName: checkInModelState.lastName,
              },
              props.token
            )
            .then((response) => {
              var activepassengers = {};
              setIsLoading(false);
              if (response.data.statusAPI === "ERROR") {
                if (
                  response.data.errorCode === "ERR.DCCI.RESERVATION_NOT_FOUND"
                ) {
                  setIsErrorOcurred(true);
                  setIsErrorOcurredMsg(response.data.messageAPI);
                } else {
                  history.push("/check-in/error");
                }
              } else {
                var ticketedPnr = true;
                var checkedInList = [];

                response.data.reservation.passengers.passenger.map(
                  (passenger) => {
                    if (passenger.ticket !== null) {
                      ticketedPnr = false;
                    }
                    if (
                      passenger.passengerSegments.passengerSegment[0]
                        .passengerFlight[0].checkedIn
                    ) {
                      checkedInList.push("true");
                    }
                    activepassengers[passenger.id] = passenger;
                    return null;
                  }
                );

                console.log(
                  checkedInList,
                  response.data.reservation.passengers.passenger.length
                );

                var alreadyCheckedIn =
                  checkedInList.length ===
                  response.data.reservation.passengers.passenger.length;

                if (ticketedPnr) {
                  history.push("/check-in/error");
                } else {
                  props.updateCheckInSabreDataRequest(
                    response.data.checkInSabreDataRequest
                  );
                  props.initActivePassengers(activepassengers);
                  props.updateReservation(response.data.reservation);
                  if (alreadyCheckedIn) {
                    history.push("/check-in/confirmation");
                  } else {
                    history.push("/check-in/itinerary");
                  }
                }
              }
            });
        });
      });
  };
  document.title = "Check-in";
  return (
    <Container className="py-5">
      {isLodaing ? <Loading /> : null}
      {isErrorOcurred ? (
        <Modal
          show={isErrorOcurred}
          onHide={() => {
            setIsErrorOcurred(false);
            resetCheckinModel();
          }}
          size="xl"
          centered
        >
          <Modal.Body>
            <h5 className="py-4 px-3">{isErrorOcurredMsg}</h5>
            <div className="row w-100 justify-content-center justify-content-md-end py-5 py-md-3 mx-0">
              <button
                className="col-12 col-lg-2 btn ml-lg-2 cancel-seat-continue-modal"
                onClick={() => {
                  setIsErrorOcurred(false);
                  resetCheckinModel();
                }}
              >
                {Translation.t("close")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
      <h3>{Translation.t("check_in")}</h3>
      <p>{Translation.t("enter_your_booking_details")}</p>
      <div className="check-in-box col-12 col-md-8 col-lg-6">
        <p>{Translation.t("fields_marked_with_are_required")}</p>
        <div className={showAdditionalOption ? "disabled-input-check-in" : ""}>
          <input
            id="pnr"
            onClick={() => {
              if (showAdditionalOption) {
                setShowAdditonalOption(false);
              }
            }}
            className={
              checkInModelState.pnrValidation
                ? "form-control  widget-input my-2 " +
                  (checkInModelState.pnr !== "" ? "text-uppercase" : "")
                : "form-control  widget-input my-2 invalid_input " +
                  (checkInModelState.pnr !== "" ? "text-uppercase" : "")
            }
            placeholder={
              checkInModelState.pnrValidation
                ? "Record Locator or Frequent Flyer Number *"
                : "Record Locator or Frequent Flyer Number Required *"
            }
            onBlur={() => updateValidation("pnrValidation", true)}
            onFocus={() => updateValidation("pnrValidation", false)}
            value={checkInModelState.pnr}
            onChange={(event) => {
              updateCheckInModel(event);
              updateValidation("pnrValidation", true, event); /**/
            }}
            autoComplete="off"
          />
          {pnrValidationMsg !== "" ? (
            <span
              className="text-danger d-flex"
              style={{
                fontSize: "13px",
                padding: "0.1rem .75rem .375rem .375rem",
              }}
            >
              {pnrValidationMsg}
            </span>
          ) : null}
          <input
            id="First Name"
            onClick={() => {
              if (showAdditionalOption) {
                setShowAdditonalOption(false);
              }
            }}
            value={checkInModelState.firstName}
            className="form-control  widget-input my-2"
            placeholder="First Name"
            onKeyDown={(e) => onKeyDownFunction(e)}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
          <input
            placeholder="Last Name"
            id="Last Name"
            onClick={() => {
              if (showAdditionalOption) {
                setShowAdditonalOption(false);
              }
            }}
            className={
              checkInModelState.lastName !== ""
                ? "form-control text-uppercase widget-input my-2"
                : "form-control widget-input my-2"
            }
            onKeyDown={(e) => onKeyDownFunction(e)}
            value={checkInModelState.lastName}
            onChange={(event) => updateCheckInModel(event)}
            autoComplete="off"
          />
        </div>
        <div>
          <label
            onClick={() => {
              setShowAdditonalOption(!showAdditionalOption);
              resetCheckinModel();
            }}
          >
            {showAdditionalOption ? (
              <span>
                HIDE ADDITIONAL OPTIONS <FontAwesomeIcon icon={faAngleUp} />
              </span>
            ) : (
              <span>
                SHOW ADDITIONAL OPTIONS <FontAwesomeIcon icon={faAngleDown} />
              </span>
            )}
          </label>
        </div>
        {showAdditionalOption && (
          <div>
            <Select
              value={props.searchType}
              styles={customStylesSelect}
              isSearchable={false}
              onChange={(selectedOption) => {
                props.updateHidenOption(selectedOption);
                resetCheckinModel();
              }}
              options={[
                {
                  value: "Flight Number",
                  label: "Flight Number",
                },
                {
                  value: "City Name",
                  label: "City Name",
                },
                {
                  value: "Ticket Number",
                  label: "Ticket Number",
                },
              ]}
              placeholder="Select Options"
            />
            {hideOptionBody()}
          </div>
        )}
        <div className="float-right">
          <Button
            disabled={isFormValid() ? "" : "disabled"}
            variant="success"
            onClick={() => retrieve()}
            className="btn-block bk-submit-btn btn-ethiopian"
          >
            {Translation.t("RETRIEVE")}
          </Button>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    pnr: state.checkIn.checkInModel.pnr,
    firstName: state.checkIn.checkInModel.firstName,
    lastName: state.checkIn.checkInModel.lastName,
    searchType: state.checkIn.checkInModel.searchType,
    flightNumber: state.checkIn.checkInModel.flightNumber,
    departureAirport: state.checkIn.checkInModel.DepartureAirport,
    arrivingCity: state.checkIn.checkInModel.arrivingCity,
    ticketNumber: state.checkIn.checkInModel.ticketNumber,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateHidenOption: (value) =>
      dispatch({
        type: checkInAction.UPDATEHIDENOPTION,
        value: value,
      }),
    updateCheckInSabreDataRequest: (value) =>
      dispatch({
        type: checkInAction.UPDATECHECKInSABREDATAREQUEST,
        checkInSabreDataRequest: value,
      }),
    updateReservation: (value) =>
      dispatch({
        type: checkInAction.UPDATERESERVATION,
        reservation: value,
      }),
    initActivePassengers: (value) =>
      dispatch({
        type: checkInAction.INITACTIVEPASSENGER,
        activePassengers: value,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
