import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import HttpService from "../../../service/shared/HttpService";
import BookingWidget from "../../booking/booking-widget/BookingWidget";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import Notification from "../../booking/booking-widget/Notification";
import { useHistory } from "react-router";
import Translation from "i18next";

const ForgotPasswordLandingPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  var history = useHistory();
  const [changeKey, setChangeKey] = useState(() => {
    var changeKey = props.location.search.replace("?changeKey=", "");
    if (changeKey === undefined || changeKey === null || changeKey === "") {
      history.push("/");
    } else {
      return changeKey;
    }
  });

  const forgotPasswordHandler = () => {
    if (isSuccessful) {
      history.push("/");
    } else if (!isLoading) {
      var newPassword = document.getElementById("forgot-password-input-new");
      var confirmPassword = document.getElementById(
        "forgot-password-input-confirm"
      );

      if (
        newPassword.value !== null &&
        newPassword.value !== "" &&
        newPassword.value === confirmPassword.value
      ) {
        setIsLoading(true);
        HttpService.postAccount();
      } else {
        if (newPassword.value === null || newPassword.value === "") {
          newPassword.classList.add("invalid_input");
        }
        if (confirmPassword.value === null || confirmPassword.value === "") {
          confirmPassword.classList.add("invalid_input");
        }
        if (newPassword.value !== confirmPassword.value) {
          confirmPassword.classList.add("invalid_input");
        }
      }
    }
  };

  return (
    <>
      <Modal
        show={true}
        //   onHide={() => props.setShowModal()}
        size="xl"
        centered
      >
        <Modal.Header style={{ backgroundColor: "whitesmoke" }}>
          <Modal.Title>{Translation.t("forgot_or_reset_password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "whitesmoke" }}>
          {isSuccessful ? (
            <Notification
              success={true}
              body={Translation.t("forgot_passwrod_email")}
            />
          ) : (
            <div>
              {" "}
              <h5>
                <b> {Translation.t("enter_new_password")}</b>
              </h5>
              {errorOccured && (
                <Notification
                  error={true}
                  body={Translation.t("profile_does_not_exist")}
                />
              )}
              <Row>
                <Col xs={12} sm={12} md={6} className="my-sm-2">
                  <input
                    className="form-control widget-input my-3"
                    placeholder="Password"
                    type="password"
                    id="forgot-password-input-new"
                    onFocus={() => {
                      var forgotPassInput = document.getElementById(
                        "forgot-password-input-new"
                      );
                      forgotPassInput.classList.remove("invalid_input");
                    }}
                  />
                </Col>
                <Col xs={12} sm={12} md={6} className="my-sm-2">
                  <input
                    className="form-control widget-input my-3"
                    type="password"
                    placeholder="Confirm Password"
                    id="forgot-password-input-confirm"
                    onFocus={() => {
                      var forgotPassInput = document.getElementById(
                        "forgot-password-input-confirm"
                      );
                      forgotPassInput.classList.remove("invalid_input");
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "whitesmoke" }}>
          <Button
            variant="danger"
            className={
              isLoading
                ? "bk-submit-btn btn-ethiopian m-2 btn-not-allowd disabled"
                : "bk-submit-btn btn-ethiopian m-2"
            }
            onClick={() => forgotPasswordHandler()}
          >
            {isSuccessful ? (
              <span>{Translation.t("close")}</span>
            ) : isLoading ? (
              <ProgressSimpler />
            ) : (
              <span>{Translation.t("reset_password")}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <BookingWidget />
    </>
  );
};
export default ForgotPasswordLandingPage;
