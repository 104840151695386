

export class FloCashValidatorService {
    constructor() {
        this.isEmailValid = true;
        this.isFirstNameValid = true;
        this.isLastNameValid = true;
        this.isPhoneNumberValid = true;
        this.isCountryValid = true;
        this.floCashValidLists = [];
    }

    validateFloCashInfo(floCashInfo) {
        this.floCashValidLists = [];
        for (let index = 0; index < 5; index++) {
            this.floCashValidLists.push(true);
        }
        
        this.isFirstNameValid = this.validateFloCashType(floCashInfo.FirstName, 0);
        this.isLastNameValid = this.validateFloCashType(floCashInfo.LastName, 1);
        this.isEmailValid = this.validateFloCashType(floCashInfo.Email, 2);
        this.isPhoneNumberValid = this.validateFloCashType(floCashInfo.PhoneNumber, 3);
        this.isCountryValid = this.validateFloCashType(floCashInfo.Country, 4);

        return { "floCashValidation": this, "floCashValidLists": this.floCashValidLists };
    }
    validateFloCashType(type, index) {
        if (type === "" || type == null) {
            this.floCashValidLists[index] = false;
            return false;
        }
        else {
            if (index === 2) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.floCashValidLists[index] = re.test(String(type).toLowerCase());
                return re.test(String(type).toLowerCase());
            }
            this.floCashValidLists[index] = true;
            return true;
        }
    }

}