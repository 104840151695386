import { flightStatusAction } from "../../actions/manage-my-booking/flightStatusAction";

const initialState = {
    backedUpFlights: [],
    FilterCriteria : [],
    countSortUpdate : 0,
    flightStatusSearchType : 1,
    statusFlights : [],
    flightNumber : "",
    isFlightStatusPresent : true
};
const flightStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case flightStatusAction.STATUSFILTERUPDATE:
        return state;
      case flightStatusAction.STATUSUPDATESORTOPTIONS:
        return {...state,
          FilterCriteria : action.filterCriteria,
        };
      case flightStatusAction.STATUSUPDATECOUNTSORTUPDATE:
        return {
          ...state,
          countSortUpdate : state.countSortUpdate + 1  
        }
      case flightStatusAction.INITFLIGHTSTATUS:
        return {
          ...state,
          statusFlights : action.payload,  
          backedUpFlights : action.payload 
        }
      case flightStatusAction.UPDATEFLIGHTSTATUS:
        return {
          ...state,
          statusFlights : action.payload
        }
      case flightStatusAction.UPDATESTATUSSERACHTYPE:
        return {
          ...state,
          flightStatusSearchType : action.payload
        }
      case flightStatusAction.UPDATEFLIGHTNUMBER:
        return {
          ...state,
          flightNumber : action.payload
        }
      case flightStatusAction.ISFLIGHTSTATUSPRESENT:
        return {
          ...state,
          isFlightStatusPresent : action.payload
        }
      // case 
    default:
      return state;
  }
};
export default flightStatusReducer;
