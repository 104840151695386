import React, { Component } from "react";
import ItineraryPartOffer from "../../booking/flight-selection/itinerary-part-offer/ItineraryPartOffer";
import { connect } from "react-redux";
import ExchangeItineraryPartOffer from "./ExchangeItineraryPartOffer";
import { ExchangeSearchItineraryPart } from "../../../model/exchange/ExchangeSearchRequest";
import { ExchangeFlightSelectRequest } from "../../../model/exchange/ExchangeFlightSelectRequest";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import { exchangeAction } from "../../../redux/actions/exchange/exchangeAction";
import { Redirect } from "react-router";
import ExchangeFlightSelectionHeader from "./ExchangeFlightSelectionHeader";

class ExchangeFlightSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRedirectToConfirmation: false,
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  selectFlight = (shoppingCode) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    
    let exchangeSelectItineraryPart = new ExchangeSearchItineraryPart();
    let exchangeSelectRequest = new ExchangeFlightSelectRequest();
    exchangeSelectItineraryPart.itineraryPart.from.code =
      this.props.exchangeSearchRequestData.itineraryPart[0].params.from.value;
    exchangeSelectItineraryPart.itineraryPart.to.code =
      this.props.exchangeSearchRequestData.itineraryPart[0].params.to.value;
    exchangeSelectItineraryPart.itineraryPart.when.date =
      this.props.exchangeSearchRequestData.itineraryPart[0].params.when.departureDate;
    exchangeSelectItineraryPart.itineraryPart.selectedOfferRef = shoppingCode;
    exchangeSelectRequest.request.itineraryParts.push(
      exchangeSelectItineraryPart.itineraryPart
    );
    if (this.props.exchangeFlightExtraInfo.isRoundTrip) {
      let exchangeSelectItineraryPart2 = new ExchangeSearchItineraryPart();
      exchangeSelectItineraryPart2.itineraryPart.from.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.to.value;
      exchangeSelectItineraryPart2.itineraryPart.to.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.from.value;
      exchangeSelectItineraryPart2.itineraryPart.when.date =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.when.arrivalDate;
      exchangeSelectRequest.request.itineraryParts.push(
        exchangeSelectItineraryPart2.itineraryPart
      );
    }
    exchangeSelectRequest.request.cookieSabreDataRequest =
      this.props.exchangeFlightExtraInfo.cookieSabreDataRequest;
    this.setState({ isLoading: true });
    HttpService.postServiceExchange(
      exchangeSelectRequest.request,
      "/etibe-exchangeapi/V1.0/Availability/SelectFlights",
      this.props.searchFlight.token
    ).then(
      (response) => {
        this.props.updateFlightSelectionResult(response.data);

        this.setState({ isLoading: false, isRedirectToConfirmation: true });
        
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };
  render() {
    if (this.state.isRedirectToConfirmation) {
      return (
        <Redirect
          to={{
            pathname: "/exchange/flight-confirmation",
          }}
        />
      );
    }
    return (
      <section>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div className="mt-4 flight-selection-container">
            <div>
              <h3 className="text-white py-3">Choose Flights</h3>
            </div>
            <ExchangeFlightSelectionHeader
              passengerInfo={this.props.passengerInfo}
              originalPayment={this.props.originalPayment}
            />
            {this.props.exchangeFlightSearchResult.data.searchResponse.brandedResults.itineraryPartBrands[0].map(
              (itineraryPartBrand, key) =>
                itineraryPartBrand.brandOffers.length > 0 && (
                  <ExchangeItineraryPartOffer
                    marginTop={2}
                    key={key}
                    index={key}
                    // duration={itineraryPartBrand.duration}
                    itineraryPartBrand={itineraryPartBrand}
                    selectFlight={this.selectFlight}
                    flightId={key}
                  />
                )
            )}
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    exchangeFlightSearchResult: state.exchange.flightSearchResult,
    exchangeFlightExtraInfo: state.exchange.flightSearchExtraInfo,
    passengerInfo: state.exchange.exchangePassengers[0].passengerDetails,
    originalPayment: state.exchange.originalPayment,
    exchangeSearchRequestData: state.bookingWiget,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFlightSelectionResult: (flightResult) => {
      dispatch({
        type: exchangeAction.SAVEFLIGHTSELECTIONRESULT,
        value: flightResult,
      });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeFlightSelection);
