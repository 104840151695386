import React from "react";

import moment from "moment";
import "./FilterFlights.css";
import { connect } from "react-redux";
// import FlightList from "../flight list temp/FlightList";
import OutsideClickHandler from "react-outside-click-handler";

import { Card, Col, Row } from "react-bootstrap";

import DoubleRangeSlider from "../range slider/DoubleRangeSlider";
import SingleRangeSlider from "../range slider/SingleRangeSlider";
import { filteredFlightsActions } from "../../../../redux/actions/booking/filteredFlightsActions";
import { flightSelectionAction } from "../../../../redux/actions/booking/flightSelectionAction";

import FlightSort from "../flight-sort/FlightSort";
import Translation from "i18next";

class FilterFlights extends React.Component {
  state = {
    price: 0,
    duration: 0,
    departMin: 0,
    departMax: 0,
    arriveMin: 0,
    arriveMax: 0,
    isClosed: true,
    filterCriteria: {
      NoOfStops: [],
      Duration: null,
      ArrivalTime: { min: null, max: null },
      Departure: { min: null, max: null },
      Price: null,
    },
    filterCriteriaActive: {},
    isFiltersModalOpened: false,
    checkedStop: [],
    Accordion: 0,
    searchResponse: {},
  };
  constructor(props) {
    super(props);
    if(this.props.journyType === "roundTrip" ||
    this.props.journyType === "oneWay"){
      this.state = this.initFilters();
      this.props.updateSortFilterCriteria(this.state.filterCriteria);
    }
  }

  initFilters = () => {
    var p = {};
    var countsNoOfStopsCount = [
      ...this.props.searchResponse.brandedResults.itineraryPartBrands[
        this.props.flightIndex
      ],
    ].reduce((first, second) => {
      var name = second.itineraryPart.stops;
      if (!p.hasOwnProperty(name)) {
        p[name] = 0;
      }
      p[name]++;
      return p;
    }, {});
    countsNoOfStopsCount = Object.keys(countsNoOfStopsCount).map((k) => {
      return {
        name:
          parseInt(k) === 0 ? "Non-Stop " : k + " Stop" + (k > 1 ? "s " : " "),
        count: countsNoOfStopsCount[k],
        stops: k,
      };
    });
    p = {};
    var variablePrice = [
      ...this.props.searchResponse.brandedResults.itineraryPartBrands[
        this.props.flightIndex
      ],
    ].map((secondElement) => {
      secondElement.brandOffers.map((secondChildElement) => {
        var name =
          "Prices " + secondChildElement.total.alternatives[0][0].amount;
        if (!p.hasOwnProperty(name)) {
          p[name] = secondChildElement.total.alternatives[0][0].amount;
        }
        return name;
      });
      return secondElement;
    });
    variablePrice = p;
    var variablePriceExtended = Object.keys(variablePrice).map((k) => {
      return variablePrice[k];
    });

    var minPrice = Math.min(...variablePriceExtended);
    var maxPrice = Math.max(...variablePriceExtended);
    p = {};

    var variableDuration = [
      ...this.props.searchResponse.brandedResults.itineraryPartBrands[
        this.props.flightIndex
      ],
    ].reduce((firstElement, secondElement) => {
      var name = "Duration " + Math.ceil(secondElement.duration / 60);
      if (!p.hasOwnProperty(name)) {
        p[name] = Math.ceil(secondElement.duration / 60);
      }
      return p;
    }, {});

    p = {};
    var variableDepartureTime = this.props.searchResponse.brandedResults.itineraryPartBrands[
      this.props.flightIndex
    ].reduce((firstElement, secondElement) => {
      var name =
        "Departure time : " + moment(secondElement.departure).format("HH:mm");
      if (!p.hasOwnProperty(name)) {
        p[name] = moment(secondElement.departure).format("HH:mm");
      }
      return p;
    }, {});

    p = {};
    var variableArrivalTime = this.props.searchResponse.brandedResults.itineraryPartBrands[
      this.props.flightIndex
    ].reduce((firstElement, secondElement) => {
      var name =
        "Arrival time : " + moment(secondElement.departure).format("HH:mm");
      if (!p.hasOwnProperty(name)) {
        p[name] = moment(secondElement.arrival).format("HH:mm");
      }
      return p;
    }, {});

    variableArrivalTime = Object.keys(variableArrivalTime).map((k) => {
      return variableArrivalTime[k];
    });

    variableDepartureTime = Object.keys(variableDepartureTime).map((k) => {
      return variableDepartureTime[k];
    });

    variableDuration = Object.keys(variableDuration).map((k) => {
      return variableDuration[k];
    });

    var minDepartTime = variableDepartureTime.reduce(
      (first, second) => {
        if (first < second) return first;
        else return second;
      },
      ["Infinity"]
    );

    var maxDepartTime = variableDepartureTime.reduce(
      (first, second) => {
        if (first > second) return first;
        else return second;
      },
      ["-Infinity"]
    );
    var minArrivalTime = variableArrivalTime.reduce(
      (first, second) => {
        if (first < second) return first;
        else return second;
      },
      ["Infinity"]
    );

    var maxArrivalTime = variableArrivalTime.reduce(
      (first, second) => {
        if (first > second) return first;
        else return second;
      },
      ["-Infinity"]
    );

    var minDuration = Math.min(...variableDuration);
    var maxDuration = Math.max(...variableDuration);

    var filterCriteria = {
      NoOfStops: countsNoOfStopsCount,
      Duration: { min: minDuration, max: maxDuration },
      ArrivalTime: { min: minArrivalTime, max: maxArrivalTime },
      Departure: { min: minDepartTime, max: maxDepartTime },
      Price: { min: minPrice, max: maxPrice },
    };

    var checkedStop = {};

    filterCriteria.NoOfStops.map((val, index) => {
      checkedStop[val.name] = true;
      return index;
    });
    var state = { ...this.state };

    state.filterCriteria = filterCriteria;
    state.price = maxPrice;
    state.duration = maxDuration;
    state.checkedStop = checkedStop;
    //time
    // state.filterCriteriaActive= {};
    // state.isFiltersModalOpened = false;
    // state.isClosed = true;
    state.departMin = minDepartTime;
    state.departMax = maxDepartTime;
    state.arriveMin = minArrivalTime;
    state.arriveMax = maxArrivalTime;
    state.searchResponse = JSON.parse(
      JSON.stringify(this.props.searchResponse)
    );
    return state;
  };

  componentDidUpdate() {
    try {
      document.getElementById("sticky-header-outer-container").style.height =
        "auto";
    } catch {}
  }

  getNumberOfStopsFuction = () => {
    return (
      <>
        {this.state.filterCriteria.NoOfStops.length === 1 ? (
          parseInt(this.state.filterCriteria.NoOfStops[0].stops) === 0 ? (
            <div className="row col-12 px-0 mx-0">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  defaultChecked="checked"
                  disabled
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>{Translation.t("non_stop")}</span>
              </div>
              <div className="col pr-0">
                <span>{this.state.filterCriteria.NoOfStops[0].count}</span>
              </div>
            </div>
          ) : (
            <div className="row col-12 px-0 mx-0">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  defaultChecked="checked"
                  disabled
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>
                  {Translation.t(
                    this.state.filterCriteria.NoOfStops[0].stops + "-stop"
                  )}
                </span>
              </div>
              <div className="col pr-0">
                <span>{this.state.filterCriteria.NoOfStops[0].count}</span>
              </div>
            </div>
          )
        ) : (
          <>
            <div className="row col-12 px-0 mx-0 py-1">
              <div className="col pl-0">
                <input
                  type="checkbox"
                  checked={
                    this.state.filterCriteria.NoOfStops.length ===
                    Object.keys(this.state.checkedStop).reduce(
                      (first, second) => {
                        if (this.state.checkedStop[second]) return first + 1;
                        return first;
                      },
                      0
                    )
                      ? "checked"
                      : ""
                  }
                  onChange={(event) => {
                    var temp = this.state.checkedStop;
                    for (const [key] of Object.entries(
                      this.state.checkedStop
                    )) {
                      temp[key] = event.target.checked;
                    }
                    this.setState({ checkedStop: temp });
                  }}
                  className="et-ibe-checkbox"
                />
              </div>
              <div className="col-6 px-0">
                <span>{Translation.t("All")}</span>
              </div>
              <div className="col pr-0">
                <span>
                  {this.state.filterCriteria.NoOfStops.reduce(
                    (a, b) => a + b.count,
                    0
                  )}
                </span>
              </div>
            </div>
            {this.state.filterCriteria.NoOfStops.map((val, index) => {
              return (
                <div key={index} className="row col-12 px-0 mx-0 py-1">
                  <div key={"checkbox-" + index} className="col pl-0">
                    <input
                      type="checkbox"
                      className="et-ibe-checkbox"
                      value={val.name}
                      checked={this.state.checkedStop[val.name]}
                      onChange={(event) => {
                        var temp = this.state.checkedStop;
                        temp[event.target.value] = event.target.checked;
                        this.setState({ checkedStop: temp });
                      }}
                    />
                  </div>
                  <div className="col-6 px-0">
                    <span>{val.name}</span>
                  </div>
                  <div className="col pr-0">
                    <span>{val.count}</span>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };

  getFilterOptions = () => {
    if (window.innerWidth < 992) {
      return (
        <>
          <button
            value="ALL"
            disabled={
              Object.entries(this.state.filterCriteriaActive).length === 0
                ? "disabled"
                : false
            }
            className="px-3 clear-all-filterCriteria"
            onClick={(event) => this.clearFilter(event)}
          >
            {Translation.t("clear_filter")}
          </button>
          <div className="accordion">
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 0 ? "none" : 0,
                  })
                }
              >
                {Translation.t("filter_time")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.Departure !== undefined
                    ? "Departure: " + this.state.filterCriteriaActive.Departure
                    : null}
                </span>
                <span className="filter-highlight">
                  {" "}
                  {this.state.filterCriteriaActive.Arrival !== undefined
                    ? "Arrival: " + this.state.filterCriteriaActive.Arrival
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 0 ? (
                <Card.Body>
                  <h5>
                    {Translation.t("departure_time")}
                    <button
                      value="Time"
                      disabled={
                        this.state.filterCriteriaActive.Departure !==
                          undefined ||
                        this.state.filterCriteriaActive.Arrival !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h5>
                  <DoubleRangeSlider
                    className="mt-4 card-double-range px-0"
                    onMinChange={(val) => this.setState({ departMin: val })}
                    onMaxChange={(val) => this.setState({ departMax: val })}
                    disabled={
                      this.state.filterCriteria.Departure.min ===
                      this.state.filterCriteria.Departure.max
                    }
                    currentMin={this.state.departMin}
                    currentMax={this.state.departMax}
                    min={this.state.filterCriteria.Departure.min}
                    max={this.state.filterCriteria.Departure.max}
                  />

                  <h5>{Translation.t("arrival_time")}</h5>
                  <DoubleRangeSlider
                    className="card-double-range px-0"
                    onMinChange={(val) => this.setState({ arriveMin: val })}
                    onMaxChange={(val) => this.setState({ arriveMax: val })}
                    currentMin={this.state.arriveMin}
                    currentMax={this.state.arriveMax}
                    disabled={
                      this.state.filterCriteria.ArrivalTime.min ===
                      this.state.filterCriteria.ArrivalTime.max
                    }
                    min={this.state.filterCriteria.ArrivalTime.min}
                    max={this.state.filterCriteria.ArrivalTime.max}
                  />
                </Card.Body>
              ) : null}
            </Card>
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 1 ? "none" : 1,
                  })
                }
              >
                {Translation.t("filter_duration")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.duration !== undefined
                    ? "Duration " + this.state.filterCriteriaActive.duration
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 1 ? (
                <Card.Body>
                  <h1 className="pb-3">
                    {" "}
                    <button
                      value="duration"
                      disabled={
                        this.state.filterCriteriaActive.duration !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h1>
                  <SingleRangeSlider
                    indicator_name={"hr"}
                    className="my-4 card-single"
                    value={this.state.duration}
                    onChange={(val) => this.setState({ duration: val })}
                    disabled={
                      this.state.filterCriteria.Duration.min ===
                      this.state.filterCriteria.Duration.max
                    }
                    min={this.state.filterCriteria.Duration.min}
                    max={this.state.filterCriteria.Duration.max}
                  />
                </Card.Body>
              ) : null}
            </Card>
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 2 ? "none" : 2,
                  })
                }
              >
                {Translation.t("number_of_tops")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.stops !== undefined
                    ? "Stops " + this.state.filterCriteriaActive.stops
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 2 ? (
                <Card.Body>
                  <h1 className="pb-3">
                    {" "}
                    <button
                      value="stops"
                      disabled={
                        this.state.filterCriteriaActive.stops !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h1>
                  {this.getNumberOfStopsFuction()}
                </Card.Body>
              ) : null}
            </Card>
            <Card>
              <Card.Header
                onClick={() =>
                  this.setState({
                    Accordion: this.state.Accordion === 3 ? "none" : 3,
                  })
                }
              >
                {Translation.t("price")}
                <span className="filter-highlight">
                  {this.state.filterCriteriaActive.price !== undefined
                    ? "Price " + this.state.filterCriteriaActive.price
                    : null}
                </span>
              </Card.Header>
              {this.state.Accordion === 3 ? (
                <Card.Body>
                  <h1 className="pb-3">
                    {" "}
                    <button
                      value="price"
                      disabled={
                        this.state.filterCriteriaActive.price !== undefined
                          ? false
                          : "disabled"
                      }
                      className="clear-filter-button-for-each d-inline-flex align-self-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      {Translation.t("clear")}
                    </button>
                  </h1>
                  <SingleRangeSlider
                    className="my-4 card-single"
                    indicator_name={this.props.searchResponse.currency}
                    value={this.state.price}
                    onChange={(val) => this.setState({ price: val })}
                    disabled={
                      this.state.filterCriteria.Price.min ===
                      this.state.filterCriteria.Price.max
                    }
                    min={this.state.filterCriteria.Price.min}
                    max={this.state.filterCriteria.Price.max}
                  />
                </Card.Body>
              ) : null}
            </Card>
          </div>
        </>
      );
    } else {
      return (
        <div className="row body-section d-none d-lg-flex px-0 mx-0">
          <div className="col-12 col-lg-3 filter-rows">
            <h5>
              {Translation.t("departure_time")}
              <button
                value="Time"
                disabled={
                  this.state.filterCriteriaActive.Departure !== undefined ||
                  this.state.filterCriteriaActive.Arrival !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h5>
            <DoubleRangeSlider
              className="mt-4 px-0 card-double-range"
              onMinChange={(val) => this.setState({ departMin: val })}
              onMaxChange={(val) => this.setState({ departMax: val })}
              disabled={
                this.state.filterCriteria.Departure.min ===
                this.state.filterCriteria.Departure.max
              }
              currentMin={this.state.departMin}
              currentMax={this.state.departMax}
              min={this.state.filterCriteria.Departure.min}
              max={this.state.filterCriteria.Departure.max}
            />

            <h5 className="pb-2">{Translation.t("arrival_time")}</h5>
            <DoubleRangeSlider
              className="px-0 card-double-range"
              onMinChange={(val) => this.setState({ arriveMin: val })}
              onMaxChange={(val) => this.setState({ arriveMax: val })}
              currentMin={this.state.arriveMin}
              currentMax={this.state.arriveMax}
              disabled={
                this.state.filterCriteria.ArrivalTime.min ===
                this.state.filterCriteria.ArrivalTime.max
              }
              min={this.state.filterCriteria.ArrivalTime.min}
              max={this.state.filterCriteria.ArrivalTime.max}
            />
          </div>
          <div className="col-12 col-lg-3 filter-rows">
            <h4 className="filter-title">
              {Translation.t("filter_duration")}
              <button
                value="duration"
                disabled={
                  this.state.filterCriteriaActive.duration !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h4>
            <SingleRangeSlider
              indicator_name={"hr"}
              className="my-4 card-single"
              value={this.state.duration}
              onChange={(val) => this.setState({ duration: val })}
              disabled={
                this.state.filterCriteria.Duration.min ===
                this.state.filterCriteria.Duration.max
              }
              min={this.state.filterCriteria.Duration.min}
              max={this.state.filterCriteria.Duration.max}
            />
          </div>
          <div className="col-12 col-lg-3 filter-rows">
            <h4 className="filter-title">
              {Translation.t("number_of_tops")}
              <button
                value="stops"
                disabled={
                  this.state.filterCriteriaActive.stops !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h4>
            {this.getNumberOfStopsFuction()}
          </div>
          <div className="col-12 col-lg-3 filter-rows">
            <h4 className="filter-title">
              {Translation.t("price")}
              <button
                value="price"
                disabled={
                  this.state.filterCriteriaActive.price !== undefined
                    ? false
                    : "disabled"
                }
                className="clear-filter-button-for-each d-inline-flex align-self-center"
                onClick={(event) => this.clearFilter(event)}
              >
                {Translation.t("clear")}
              </button>
            </h4>
            <SingleRangeSlider
              className="my-4 card-single"
              indicator_name={this.props.searchResponse.currency}
              value={this.state.price}
              onChange={(val) => this.setState({ price: val })}
              disabled={
                this.state.filterCriteria.Price.min ===
                this.state.filterCriteria.Price.max
              }
              min={this.state.filterCriteria.Price.min}
              max={this.state.filterCriteria.Price.max}
            />
          </div>
        </div>
      );
    }
  };

  checkStopOverIsIncluded = (element, tempStopsFinilized) => {
    var result = false;
    tempStopsFinilized.map((val) => {
      if (parseInt(val.stops) === parseInt(element.itineraryPart.stops))
        result = true;
      return val;
    });
    return result;
  };

  getItineraryPartBrands = (filterCriteriaActive) => {
    var temp = this.state.searchResponse;

    var tempFilterResult = JSON.parse(
      JSON.stringify(temp.brandedResults.itineraryPartBrands[this.props.flightIndex])
    ).filter((val) => {
      val.brandOffers = val.brandOffers.filter((childVal) => {
        if (filterCriteriaActive.price !== undefined) {
          if (
            childVal.total.alternatives[0][0].amount <=
            parseInt(filterCriteriaActive.price.split(": < ")[1])
          ) {
            return childVal;
          }
          return null;
        } else return childVal;
      });
      if (val.brandOffers.length !== 0) return val;
      return null;
    });

    if (filterCriteriaActive.duration !== undefined) {
      tempFilterResult = tempFilterResult.filter((element) => {
        if (
          Math.ceil(element.duration / 60) <=
          parseInt(filterCriteriaActive.duration.split(": < ")[1])
        ) {
          return element;
        }
        return null;
      });
    }
    if (filterCriteriaActive.stops !== undefined) {
      var tempStopsFinilized = this.state.filterCriteria.NoOfStops.filter(
        (element) => {
          if (filterCriteriaActive.stops.indexOf(element.name) !== -1) {
            return element.stops;
          }
          return null;
        }
      );
      tempFilterResult = tempFilterResult.filter((element) => {
        if (this.checkStopOverIsIncluded(element, tempStopsFinilized)) {
          return element;
        }
        return null;
      });
    }

    if (filterCriteriaActive.Departure !== undefined) {
      tempFilterResult = tempFilterResult.filter((element) => {
        if (
          moment(element.departure).format("HH:MM") >= this.state.departMin &&
          moment(element.departure).format("HH:MM") <= this.state.departMax
        ) {
          return element;
        }
        return null;
      });
    }
    if (filterCriteriaActive.Arrival !== undefined) {
      tempFilterResult = tempFilterResult.filter((element) => {
        if (
          moment(element.arrival).format("HH:MM") >= this.state.arriveMin &&
          moment(element.arrival).format("HH:MM") <= this.state.arriveMax
        ) {
          return element;
        }
        return null;
      });
    }
    return tempFilterResult;
  };

  handleUpdate = () => {
    var temp = {};
    if (
      parseInt(this.state.duration) !== this.state.filterCriteria.Duration.max
    ) {
      temp["duration"] = ": < " + this.state.duration;
    }
    if (parseInt(this.state.price) !== this.state.filterCriteria.Price.max) {
      temp["price"] = ": < " + this.state.price;
    }
    // if all are not selected
    if (
      this.state.filterCriteria.NoOfStops.length !==
      Object.keys(this.state.checkedStop).reduce((first, second) => {
        if (this.state.checkedStop[second]) return first + 1;
        return first;
      }, 0)
    ) {
      temp["stops"] =
        ": " +
        Object.keys(this.state.checkedStop).filter((key) => {
          if (this.state.checkedStop[key]) return key;
          else return null;
        });
    }
    if (
      this.state.departMax !== this.state.filterCriteria.Departure.max ||
      this.state.departMin !== this.state.filterCriteria.Departure.min
    ) {
      temp["Departure"] =
        " Between " +
        moment(this.state.departMin, "HH:mm").format("h:mm A") +
        " and " +
        moment(this.state.departMax, "HH:mm").format("h:mm A");
    }
    if (
      this.state.arriveMax !== this.state.filterCriteria.ArrivalTime.max ||
      this.state.arriveMin !== this.state.filterCriteria.ArrivalTime.min
    ) {
      temp["Arrival"] =
        " Between " +
        moment(this.state.arriveMin, "HH:mm").format("h:mm A") +
        " and " +
        moment(this.state.arriveMax, "HH:mm").format("h:mm A");
    }
    this.setState({ filterCriteriaActive: temp, isClosed: true });
    this.props.searchResponseUpdate({
      flightIndex: this.props.flightIndex,
      searchResponse: this.getItineraryPartBrands(temp),
    });
  };

  clearFilter = (event) => {
    var temp = this.state.filterCriteriaActive;
    if (event.target.value === "ALL") {
      this.setState({
        filterCriteriaActive: {},
        price: this.state.filterCriteria.Price.max,
        duration: this.state.filterCriteria.Duration.max,
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
        arriveMin: this.state.filterCriteria.ArrivalTime.min,
        arriveMax: this.state.filterCriteria.ArrivalTime.max,
      });
      temp = {};
    } else if (event.target.value === "duration") {
      this.setState({ duration: this.state.filterCriteria.Duration.max });
    } else if (event.target.value === "price") {
      this.setState({ price: this.state.filterCriteria.Price.max });
    } else if (event.target.value === "stops") {
      var tempforStops = this.state.checkedStop;
      for (const [key] of Object.entries(this.state.checkedStop)) {
        tempforStops[key] = true;
      }
      this.setState({ checkedStop: tempforStops });
    } else if (event.target.value === "Departure") {
      this.setState({
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
      });
    } else if (event.target.value === "Arrival") {
      this.setState({
        arriveMin: this.state.filterCriteria.ArrivalTime.min,
        arriveMax: this.state.filterCriteria.ArrivalTime.max,
      });
    } else if (event.target.value === "Time") {
      this.setState({
        departMin: this.state.filterCriteria.Departure.min,
        departMax: this.state.filterCriteria.Departure.max,
        arriveMin: this.state.filterCriteria.ArrivalTime.min,
        arriveMax: this.state.filterCriteria.ArrivalTime.max,
      });
      delete temp["Arrival"];
      delete temp["Departure"];
    }
    delete temp[event.target.value];
    this.setState({ filterCriteriaActive: temp });
    this.props.searchResponseUpdate({
      flightIndex: this.props.flightIndex,
      searchResponse: this.getItineraryPartBrands(temp),
    });
  };

  handleCancleButtonClick = () => {
    this.setState({ isClosed: true });
  };

  getActiveFilter = () => {
    var tempFilterDictionary = Object.entries(this.state.filterCriteriaActive);

    if (tempFilterDictionary.length === 0) {
      return;
    }

    if (tempFilterDictionary.length === 1) {
      return (
        <>
          {tempFilterDictionary.map(([key, val]) => {
            return (
              <span
                key={key}
                className="et-std-color text-capitalize filter-options d-inline-flex"
              >
                {Translation.t(key)} {val}
                <button
                  value={key}
                  className="clear-filter-button d-inline-flex align-self-center"
                  onClick={(event) => this.clearFilter(event)}
                >
                  x
                </button>{" "}
              </span>
            );
          })}
        </>
      );
    } else
      return (
        <>
          <OutsideClickHandler
            display={"inline-block"}
            onOutsideClick={() =>
              this.setState({ isFiltersModalOpened: false })
            }
          >
            <span
              className="filter-toggler-multi-criteria"
              onClick={() =>
                this.setState({
                  isFiltersModalOpened: !this.state.isFiltersModalOpened,
                })
              }
            >
              {tempFilterDictionary.length + " " + Translation.t("filters")}
            </span>
            <span
              className={
                this.state.isFiltersModalOpened
                  ? "row col-12 col-md-11 col-xl-7 col-md-6 px-0 filter-list-container"
                  : "d-none"
              }
            >
              {tempFilterDictionary.map(([key, val]) => {
                return (
                  <span
                    key={key}
                    className="text-capitalize col-12 mx-0 row filter-option-list"
                  >
                    <span className="col-11 px-0">
                      {Translation.t(key) + val + " "}
                    </span>
                    <button
                      value={key}
                      className="clear-filter-button d-flex align-self-center col-1 px-0 justify-content-center"
                      onClick={(event) => this.clearFilter(event)}
                    >
                      x
                    </button>{" "}
                  </span>
                );
              })}
            </span>
          </OutsideClickHandler>
        </>
      );
  };

  

  render() {
    return (
      <>
        {this.state.isClosed ? (
          <>
            <div className="internatl-filter-option-container row mx-0 pb-2 pb-md-0">
              <Col lg={7} md={7} className="pl-2 pl-sm-3">
                <div>
                  <span className="pr-2" id="display_on_flight_ribbon_out_of_view_port">
                      {moment(this.props.queryDate).locale(this.props.locale).format("ddd, MMM DD")}
                  </span>
                  <span className="itinerary-part-offer-header-label">
                    {this.props.itineraryPartBrandsLength}{" "}
                    {Translation.t("results")}
                  </span>
                  <FlightSort />
                  {this.props.journyType === "roundTrip" ||
                  this.props.journyType === "oneWay" ? (
                    <>
                      <button
                        className="itinerary-part-offer-header-label pl-4 sort-by-button"
                        onClick={() =>
                          this.setState({
                            isClosed: false,
                            isFiltersModalOpened: false,
                          })
                        }
                      >
                        {Translation.t("show_filter")}
                      </button>
                      {this.getActiveFilter()}
                    </>
                  ) : null}
                </div>
              </Col>
              <Col lg={5} md={5} className="itinerary-part-offer-brands-header">
                <Row>
                  <Col lg={6} md={5}>
                    <div>
                      <span className="brand-label">
                        {Translation.t("economy")}
                      </span>
                    </div>
                  </Col>
                  <Col lg={6} md={5}>
                    <div>
                      <span className="brand-label">
                        {Translation.t("business")}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </>
        ) : (
          <div
            className="search-filter-wrapper w-100"
            id="search-filter-wrapper"
          >
            <div className="search-filter-inside px-0 py-4">
              {this.getFilterOptions()}
              <div className="row bottom-section justify-content-lg-end mb-3 mt-5 mx-0">
                <div className="col-6 col-lg-2">
                  <button
                    className="col-12 btn bg-white text-secondary border text-uppercase filter-bottom-button"
                    onClick={() => this.handleCancleButtonClick()}
                  >
                    {Translation.t("cancel")}
                  </button>
                </div>
                <div className="col-6 col-lg-2 pl-0">
                  <button
                    onClick={() => this.handleUpdate()}
                    className="col-12 btn btn-success text-uppercase std-et-bg-color filter-bottom-button"
                  >
                    {Translation.t("update")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    fareType: state.bookingWiget.fareType,
    isMobile: state.global.isMobile,
    locale: state.global.locale,
    journyType: state.searchFlight.searchRequest.journyType,
    searchResponse: state.searchFlight.searchResult.data.searchResponse,
    backedUpFlights: state.filteredFlights.backedUpFlights,
    flightIndex: state.flightSelection.flightIndex,
    queryDate: state.searchFlight.searchResult.data.date,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FILTERUPDATE: (index) => {
      dispatch({
        type: filteredFlightsActions.FILTERUPDATE,
        flightIndex: index,
      });
    },
    updateSortFilterCriteria: (filterCriteria) => {
      dispatch({
        type: filteredFlightsActions.UPDATESORTOPTIONS,
        filterCriteria: filterCriteria,
      });
    },
    searchResponseUpdate: (payload) => {
      dispatch({
        type: flightSelectionAction.UPDATESEARCHRESPONSE,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FilterFlights);
