import React, { Component } from "react";
import { Form, Button, Row } from "react-bootstrap";
import AirportSelection from "../booking/booking-widget/airport-selection/AirportSelection";
import Select from "react-select";
import DatePicker from "../booking/booking-widget/booking-calendar/DatePicker";
import CabinClass from "../booking/booking-widget/CabinClass";
import { connect } from "react-redux";
import { exchangeAction } from "../../redux/actions/exchange/exchangeAction";
import "./Exchange.css";
import {
  ExchangeSearchItineraryPart,
  ExchangeSearchRequest,
} from "../../model/exchange/ExchangeSearchRequest";
import HttpService from "../../service/shared/HttpService";
import Loading from "../booking/seat-selection/Loading";
import { Redirect } from "react-router";

class ExchangeWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRedirectToFlightSelection: false,
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  renderPassengerType = (type) => {
    switch (type) {
      case "ADT":
        return "ADULT";
      case "CHD":
        return "CHILD";
      case "INF":
        return "INFANT";
      default:
        return "";
    }
  };
  onSearchClicked = () => {
    
    let exchangeSearchRequest = new ExchangeSearchRequest();
    exchangeSearchRequest.searchRequest.cabinClass =
      this.props.exchangeSearchRequestData.cabinClass.value;
    exchangeSearchRequest.searchRequest.cookieSabreDataRequest =
      this.props.exchangeFlightExtraInfo.cookieSabreDataRequest;
    exchangeSearchRequest.searchRequest.exchangeType =
      this.props.exchangeFlightExtraInfo.exchangeType;
    if (this.props.exchangeSearchRequestData.journyType === "roundTrip") {
      let itinerayPart1 = new ExchangeSearchItineraryPart();
      itinerayPart1.itineraryPart.from.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.from.value;
      itinerayPart1.itineraryPart.to.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.to.value;
      itinerayPart1.itineraryPart.when.date =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.when.departureDate;
      exchangeSearchRequest.searchRequest.searchCriteria.itineraryParts.push(
        itinerayPart1.itineraryPart
      );
      let itinerayPart2 = new ExchangeSearchItineraryPart();
      itinerayPart2.itineraryPart.from.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.to.value;
      itinerayPart2.itineraryPart.to.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.from.value;
      itinerayPart2.itineraryPart.when.date =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.when.arrivalDate;
      exchangeSearchRequest.searchRequest.searchCriteria.itineraryParts.push(
        itinerayPart2.itineraryPart
      );
    } else {
      let itinerayPart1 = new ExchangeSearchItineraryPart();
      itinerayPart1.itineraryPart.from.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.from.value;
      itinerayPart1.itineraryPart.to.code =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.to.value;
      itinerayPart1.itineraryPart.when.date =
        this.props.exchangeSearchRequestData.itineraryPart[0].params.when.departureDate;
      exchangeSearchRequest.searchRequest.searchCriteria.itineraryParts.push(
        itinerayPart1.itineraryPart
      );
    }
    this.props.updateFlightSearchRequest(exchangeSearchRequest.searchRequest);
    this.setState({ isLoading: true });
    HttpService.postServiceExchange(
      exchangeSearchRequest.searchRequest,
      "/etibe-exchangeapi/V1.0/Availability/SearchFlights",
      this.props.searchFlight.token
    ).then(
      (response) => {
        

        this.setState({ isLoading: false });

        if (response.data.statusAPI === "SUCCESS") {
          this.props.updateFlightSearchResult(response);

          this.setState({ isRedirectToFlightSelection: true });
        } else {
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };
  render() {
    if (this.state.isRedirectToFlightSelection) {
      return (
        <Redirect
          to={{
            pathname: "/exchange/flight-selection",
          }}
        />
      );
    }
    return (
      <section className="mt-4">
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="container bg-white  exchange-widget-container">
              <h2 className="pb-4">Search for New Flight(s)</h2>
              <div>
                <h4>Please Note:</h4>
                <div>
                  <span>
                    All fields are required unless marked as optional. Use latin
                    characters only.
                  </span>
                </div>
                <hr />
              </div>
              <div>
                <span>
                  Prices are quoted per adult in the Reward Points and include
                  taxes and fees except for the Booking and Service Fee which is
                  charged per passenger per booking for payment by credit or
                  debit card. Fares exclude any local taxes that are payable at
                  the airport.
                </span>
              </div>
              <hr />
              <div className="py-3">
                <h4>Passengers</h4>
                <div>
                  <span>
                    The change will be for all passengers in the booking. Please
                    call customer service, +251 116 179 900 (International
                    Charges Apply), for additional questions.
                  </span>
                </div>
                {this.props.exchangePassengers.map((passenger, key) => (
                  <div className="pt-3" key={key}>
                    <h5>
                      <span className="passenger-counter">{key + 1}</span>{" "}
                      <span>{passenger.passengerDetails.firstName}</span>{" "}
                      <span>{passenger.passengerDetails.lastName}</span>{" "}
                      <span>
                        (
                        {this.renderPassengerType(passenger.passengerInfo.type)}
                        )
                      </span>{" "}
                    </h5>
                  </div>
                ))}

                <hr />
              </div>
              <div className="exchange-widget-form">
                <Form>
                  <Row>
                    <AirportSelection flightIndex={0} />
                  </Row>
                  <Row>
                    <DatePicker
                      flightIndex={0}
                      name="departure_date_0"
                      maxDate={
                        this.props.exchangeFlightExtraInfo.arrivalDate !== null
                          ? this.props.exchangeFlightExtraInfo.arrivalDate
                          : null
                      }
                    />

                    {this.props.exchangeFlightExtraInfo.isRoundTrip && (
                      <DatePicker
                        flightIndex={0}
                        name="return_date_0"
                        isReturn={true}
                        minDate={
                          this.props.exchangeFlightExtraInfo.arrivalMinDate
                        }
                      />
                    )}
                  </Row>
                  <Row>
                    <CabinClass />
                  </Row>
                </Form>
              </div>
            </div>
            <div className="container mt-4  text-right px-sm-3 px-md-0">
              <Button className="exchange-cancel-btn">CANCEL</Button>
              <span className="px-2"></span>
              <Button
                className="continue-btn"
                onClick={() => this.onSearchClicked()}
              >
                CONTINUE
              </Button>
            </div>
          </div>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    exchangePassengers: state.exchange.exchangePassengers,
    exchangeFlightExtraInfo: state.exchange.flightSearchExtraInfo,
    exchangeSearchRequestData: state.bookingWiget,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePassenger: (passengers) => {
      dispatch({
        type: exchangeAction.SAVEPASSENGER,
        value: passengers,
      });
    },
    updateFlightSearchResult: (searchResult) => {
      dispatch({
        type: exchangeAction.SAVEFLIGHTSEARCHRESULT,
        value: searchResult,
      });
    },
    updateFlightSearchRequest: (searchRequest) => {
      dispatch({
        type: exchangeAction.SAVeFLIGHTSEARCHRFORCONFIRMATION,
        value: searchRequest,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeWidget);
