import React, { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
import Translation from "i18next";
import FlightSelectionSummaryDetail from "../../FlightSelectionSummaryDetail";

class ItineraryBrandItemMob extends Component {
  thousands_separators = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };

  renderEconomyBrands() {
    if (
      this.props.selectedBrand === "Economy" &&
      this.props.brand.cabinClass === "Economy"
    ) {
      return (
        <div className="text-center pt-2  fly-brand-item-info-mob">
          <Row className="py-3 m-0">
            <Col>
              <div className="brand-name">
                <span>{this.props.brand.brandLabel}</span>
              </div>
            </Col>
          </Row>
          <Row className="brand-fare-breakdown">
            <Col className="brand-fare-breakdown-col border-0">
              <div>{Translation.t("fare")}</div>
              <div className="py-2">
                <span>
                  {this.props.brand.fare.alternatives[0][0].currency + " "}
                </span>
                {this.thousands_separators(
                  this.props.brand.fare.alternatives[0][0].amount
                )}
              </div>
            </Col>
            <Col className="brand-fare-breakdown-col">
              <div>{Translation.t("tax")}</div>
              <div className="py-2">
                <span>
                  {this.props.brand.taxes.alternatives[0][0].currency + " "}
                </span>
                {this.thousands_separators(
                  this.props.brand.taxes.alternatives[0][0].amount
                )}
              </div>
            </Col>
            <Col className="brand-fare-breakdown-col">
              <div>{Translation.t("seat_left")}</div>
              <div className="py-2">
                {this.props.brand.seatsRemaining.count}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="py-3">
              <Button
                className="brand-selector-btn"
                onClick={() =>
                  this.props.selectFlight(
                    this.props.brand.shoppingBasketHashCode
                  )
                }
              >
                <span>
                  {this.props.brand.total.alternatives[0][0].currency + " "}
                </span>
                <span>
                  {this.thousands_separators(
                    this.props.brand.total.alternatives[0][0].amount
                  ) + " "}
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }
  renderBusinessBrands() {
    if (
      this.props.selectedBrand === "Business" &&
      this.props.brand.cabinClass === "Business"
    ) {
      return (
        <div className="text-center pt-2  fly-brand-item-info-mob">
          <Row className="py-3 m-0">
            <Col>
              <div className="brand-name">
                <span>{this.props.brand.brandLabel}</span>
              </div>
            </Col>
          </Row>
          <Row className="brand-fare-breakdown">
            <Col className="brand-fare-breakdown-col border-0">
              <div>{Translation.t("fare")}</div>
              <div className="py-2">
                <span>
                  {this.props.brand.fare.alternatives[0][0].currency + " "}
                </span>
                {this.props.brand.fare.alternatives[0][0].amount}
              </div>
            </Col>
            <Col className="brand-fare-breakdown-col">
              <div>{Translation.t("tax")}</div>
              <div className="py-2">
                <span>
                  {this.props.brand.taxes.alternatives[0][0].currency + " "}
                </span>
                {this.props.brand.taxes.alternatives[0][0].amount}
              </div>
            </Col>
            <Col className="brand-fare-breakdown-col">
              <div>{Translation.t("seat_left")}</div>
              <div className="py-2">
                {this.props.brand.seatsRemaining.count}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="py-3">
              <Button
                className="brand-selector-btn"
                onClick={() =>
                  this.props.selectFlight(
                    this.props.brand.shoppingBasketHashCode
                  )
                }
              >
                <span>
                  {this.props.brand.total.alternatives[0][0].currency + " "}
                </span>
                <span>
                  {this.props.brand.total.alternatives[0][0].amount + " "}
                </span>
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        {this.renderEconomyBrands()}
        {this.renderBusinessBrands()}
      </div>
    );
  }
}
export default ItineraryBrandItemMob;
