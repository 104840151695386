import HttpService from "../../shared/HttpService";
// import SelectionModel from "../../model/seat-selection/SelectionModel.js";


export class FareCalendarService{
  //  getSeatMap=(cookieSabreData,token) => {
  //     let relativeUrl ="/Seat/Map";
  //     var searchRequest = {};
  //     searchRequest=cookieSabreData;

  //     return HttpService.postService(cookieSabreData,relativeUrl,token);
  // }

  getFareCalender=(date, searchFlightsProps, token) => {
    let relativeUrl ="/Availability/AdvancedSearch";
    var searchRequest = {
      "cabinClass": null,
      "currency": null,
      "awardBooking": false,
      "searchType": "CALENDAR30",
      "promoCodes": [],
      "itineraryParts": [
          {
              "from": {
                  "code": "CPT"
              },
              "to": {
                  "code": "IAD"
              },
              "when": {
                  "date": "2021-04-15"
              },
              "selectedOfferRef": null
          }
      ],
      "passengers": {
          "ADT": 1,
          "CHD": 0,
          "INF": 0
      },
      "pointOfSale": null,
      "trendIndicator": null,
      "preferredOperatingCarrier": null
  };

    var temp = JSON.parse(JSON.stringify(searchFlightsProps));
  
    temp.itineraryParts[0].when.date = date
    searchRequest.itineraryParts = temp.itineraryParts;
    searchRequest.passengers = temp.passengers;

    return HttpService.postGetFareCalender(searchRequest,relativeUrl,token);
  }
}