import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "react-bootstrap";
import "./progress-loader.css";
import Translation from "i18next";
export default class FlightLoading extends React.Component {
  componentDidMount()
  {
    window.scrollTo({ top: 0, behavior: "smooth" });

  }
  render() {
    return (
      <>
        <div className="py-3 flight-selection-container">
          <h3 className="text-white flight-selection-head-txt">
            {Translation.t("choose_your_flight")}
          </h3>
          <h5 className="text-white flight-selection-head-txt py-2">
            <Skeleton width="70%" />
          </h5>
          <h4 className="text-white">
            <Skeleton width="50%" />
          </h4>
        </div>
        <div className="p-0 d-flex justify-content-between flight-ribbon-container flight-selection-container ">
          <div className="justify-content-center mob-indicator d-flex align-items-center">
            <button className="back-alternate-btn alternate-btn back-alternate-shadow">
              <Skeleton />
            </button>
          </div>
          <div className="col px-0 d-flex horizontal_flex_container">
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
                style={{ hover: "none" }}
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center ">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
            <div className="flight-ribbon-top-wrapper align-items-center">
              {" "}
              <div
                role="button"
                className="col px-0 fareoption-container fareoption-container-disabled-hover"
              >
                <div className="py-3 px-2">
                  <span className="date">
                    <Skeleton />
                  </span>
                  <h5>
                    <span className="currency">
                      <Skeleton />
                    </span>
                  </h5>
                </div>
              </div>{" "}
            </div>
          </div>
          <div className="justify-content-center mob-indicator d-flex align-items-center">
            <button className="next-alternate-btn alternate-btn next-alternate-shadow">
              <Skeleton />
            </button>
          </div>
        </div>
        <br />
        <div id="sticky-header-outer-container">
          <div
            className="itinerary-part-offer-header flight-selection-container search-filter-wrapper"
            id="sticky-header-container"
          >
            <Row className="mx-0">
              <div className="internatl-filter-option-container row mx-0">
                <Col lg={7} md={7}>
                  <div>
                    <span className="itinerary-part-offer-header-label">
                      <Skeleton width="100px" />
                      <span className="mx-5">
                        <Skeleton width="100px" />{" "}
                      </span>
                      <span className="mx-2">
                        <Skeleton width="100px" />{" "}
                      </span>
                    </span>
                  </div>
                </Col>
                <Col
                  lg={5}
                  md={5}
                  className="itinerary-part-offer-brands-header"
                >
                  <Row>
                    <Col lg={6} md={5}>
                      <div>
                        <span className="brand-label">
                          <Skeleton width="100px" />{" "}
                        </span>
                      </div>
                    </Col>
                    <Col lg={6} md={5}>
                      <div>
                        <span className="brand-label">
                          <Skeleton width="100px" />{" "}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Row>
            <Row className="itinerary-time-duration-row mx-0">
              <Col lg={7} md={7}>
                <Row>
                  <Col lg={7} md={7}>
                    <div>
                      <span className="flight-time-label">
                        <Skeleton width="100px" />{" "}
                      </span>
                    </div>
                  </Col>
                  <Col lg={5} md={5}>
                    <div>
                      <span className="flight-duration-label">
                        <Skeleton width="100px" />{" "}
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={5}></Col>
            </Row>
          </div>
        </div>
        <div className="itinerary-part-offer py-4 mt-2 flight-selection-container ">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="flight-time row">
                    <div className="col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                  <div className="flight-route row">
                    <div className="col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flight-duration col-lg-5 col-md-5">
                  <div>
                    {" "}
                    <span>
                      <span>
                        <Skeleton />
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <div>
                        <Skeleton />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="py-2 aircraft-info col-12">
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <Skeleton width="100px" height="50px" />{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="itinerary-part-offer py-4 mt-2 flight-selection-container ">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="flight-time row">
                    <div className="col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                  <div className="flight-route row">
                    <div className="col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flight-duration col-lg-5 col-md-5">
                  <div>
                    {" "}
                    <span>
                      <span>
                        <Skeleton />
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <div>
                        <span></span>
                        <span>
                          <span>
                            <Skeleton />
                          </span>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="py-2 aircraft-info col-12">
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <Skeleton width="100px" height="50px" />{" "}
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div className="itinerary-part-offer py-4 mt-2 flight-selection-container ">
          <div className="row">
            <div className="col-lg-7 col-md-7">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="flight-time row">
                    <div className="col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      <span>
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                  <div className="flight-route row">
                    <div className="col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="pl-4 col">
                      {" "}
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flight-duration col-lg-5 col-md-5">
                  <div>
                    {" "}
                    <span>
                      <span>
                        <Skeleton />
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <div>
                        <span></span>
                        <span>
                          <span>
                            <Skeleton />
                          </span>
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div>
                    <Skeleton width="100px" height="50px" />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col"></div>
            <div className="py-2 aircraft-info col-12">
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
              <div className="py-1">
                <span className="px-1">
                  <Skeleton width="200px" />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div>
                <Skeleton width="100px" height="50px" />{" "}
              </div>
            </div>
          </div>
          <div></div>
        </div>

          <div className="itinerary-part-offer-mob bg-white mt-2 flight-selection-container">
            <div>
              <div className="operating-flight-mob py-1">
                <div className="row">
                  <div className="mx-3 col">
                    <span>
                      <Skeleton />
                    </span>
                  </div>
                  <div className="col">
                    <div className="text-right pr-2 py-1">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className="text-center flight-city-duration-mob">
                  <div className="row">
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="col-6">
                      <span>
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center py-2">
                  <div className="row">
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="m-0 p-0 col-md-2 col-sm-3 col-2"></div>
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                    <div className="col">
                      <span>
                        <span>
                          <span>
                            <Skeleton />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center fly-price-info-mob py-1">
                <div className="row">
                  <div className="col"></div>
                  <div className="float-right col-sm-12 col-12">
                    <div className="text-right py-1">
                      <span className="fly-price-mob">
                        <Skeleton width="80%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="itinerary-part-offer-mob bg-white mt-2 flight-selection-container">
            <div>
              <div className="operating-flight-mob py-1">
                <div className="row">
                  <div className="mx-3 col">
                    <span>
                      <Skeleton />
                    </span>
                  </div>
                  <div className="col">
                    <div className="text-right pr-2 py-1">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className="text-center flight-city-duration-mob">
                  <div className="row">
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="col-6">
                      <span>
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center py-2">
                  <div className="row">
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="m-0 p-0 col-md-2 col-sm-3 col-2"></div>
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                    <div className="col">
                      <span>
                        <span>
                          <span>
                            <Skeleton />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center fly-price-info-mob py-1">
                <div className="row">
                  <div className="col"></div>
                  <div className="float-right col-sm-12 col-12">
                    <div className="text-right py-1">
                      <span className="fly-price-mob">
                      <Skeleton width="80%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="itinerary-part-offer-mob bg-white mt-2 flight-selection-container">
            <div>
              <div className="operating-flight-mob py-1">
                <div className="row">
                  <div className="mx-3 col">
                    <span>
                      <Skeleton />
                    </span>
                  </div>
                  <div className="col">
                    <div className="text-right pr-2 py-1">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className="text-center flight-city-duration-mob">
                  <div className="row">
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                    <div className="col-6">
                      <span>
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="col">
                      <span className="font-weight-bold">
                        <Skeleton />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center py-2">
                  <div className="row">
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                    <div className="m-0 p-0 col-md-2 col-sm-3 col-2"></div>
                    <div className="col">
                      <span className="flight-time-mob">
                        <span>
                          <Skeleton />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="row">
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                    <div className="col">
                      <span>
                        <span>
                          <span>
                            <Skeleton />
                          </span>
                        </span>
                      </span>
                    </div>
                    <div className="col-md-3 col-sm-3 col-3">
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center fly-price-info-mob py-1">
                <div className="row">
                  <div className="col"></div>
                  <div className="float-right col-sm-12 col-12">
                    <div className="text-right py-1">
                      <span className="fly-price-mob">
                      <Skeleton width="80%"/>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </>
    );
  }
}
