import React from "react";

import "./DoubleRangeSlider.css";

class DoubleRangeSlider extends React.Component {
  state = {
    sliderWidth: 0,
    offsetSliderWidht: 0,
    min: 0,
    max: 200,
    minValueBetween: 10,

    currentMin: 60,
    inputMin: 60,

    currentMax: 100,
    inputMax: 100,
  };

  componentDidMount() {
    var maxFromProps = this.timeToNumber(this.props.max);
    var minFromProps = this.timeToNumber(this.props.min);
    var currentMaxFromProps = this.timeToNumber(this.props.currentMax);
    var currentMinFromProps = this.timeToNumber(this.props.currentMin);
    this.minValue.style.width = (100*(currentMinFromProps - minFromProps))/(maxFromProps - minFromProps) + "%";
    this.maxValue.style.width = (100*(currentMaxFromProps - minFromProps))/(maxFromProps - minFromProps) + "%";

    this.setState({
      sliderWidth: this.slider.offsetWidth,
      offsetSliderWidht: this.slider.getBoundingClientRect().x,
      min: minFromProps,
      currentMin: currentMinFromProps,
      inputMin: currentMinFromProps,

      inputMax: currentMaxFromProps,
      currentMax: currentMaxFromProps,
      max: maxFromProps,
    });
    this.minValueDrag.addEventListener("touchstart", this.changeMinValue, {
      passive: false,
    });
    this.maxValueDrag.addEventListener("touchstart", this.changeMaxValue, {
      passive: false,
    });
  }

  setMin = (e) => {
    const { min, max, currentMax, minValueBetween } = this.state;
    const inputMin = this.timeToNumber(e.target.value);
    var currentMin = parseInt(inputMin);
    if (inputMin >= min && inputMin <= currentMax - minValueBetween) {
      this.setState({
        inputMin,
        currentMin: parseInt(inputMin),
      });

      this.minValue.style.width = (inputMin * 100) / max + "%";
    } else if (inputMin < min) {
      currentMin = parseInt(min);
      this.setState({
        inputMin: min,
        currentMin: parseInt(min),
      });
      this.minValue.style.width = 100 + "%";
    } else {
      currentMin = parseInt(currentMax - minValueBetween);
      this.setState({
        inputMin: parseInt(currentMax - minValueBetween),
        currentMin: parseInt(currentMax - minValueBetween),
      });
      this.minValue.style.width = (100*(currentMax - minValueBetween - min))/(max - min) + "%";
    }
    this.props.onMinChange(this.numberToTime(currentMin));
  };

  changeMinValue = (e) => {
    e.preventDefault();

    document.addEventListener("mousemove", this.onMouseMoveMin);
    document.addEventListener("mouseup", this.onMouseUpMin);

    document.addEventListener("touchmove", this.onTouchMoveMin);
    document.addEventListener("touchend", this.onMouseUpMin);
  };

  onTouchMoveMin = (e) => {
    if (e.touches.length !== 0) {
      const {
        min,
        max,
        currentMax,
        minValueBetween,
        sliderWidth,
        offsetSliderWidht,
      } = this.state;
      var touch = e.touches[0];
      var ratio = (max - min) / max;
      const dragedWidht = touch.clientX - offsetSliderWidht;
      const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
      const currentMin = min + parseInt(Math.abs(parseInt((max * dragedWidhtInPercent) / 100) * ratio));
      if (
        dragedWidht >= 0 &&
        currentMin >= min &&
        currentMin <= currentMax - minValueBetween
      ) {
        this.minValue.style.width = dragedWidhtInPercent + "%";
        this.minValue.dataset.content = currentMin;
        this.setState({
          currentMin,
          inputMin: currentMin,
        });
        this.props.onMinChange(this.numberToTime(currentMin));
      }
    }
  };

  onMouseMoveMin = (e) => {
    const {
      min,
      max,
      currentMax,
      minValueBetween,
      sliderWidth,
      offsetSliderWidht,
    } = this.state;
    const dragedWidht = e.clientX - offsetSliderWidht;
    var ratio = (max - min) / max;
    const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
    const currentMin =
      min +
      parseInt(Math.abs(parseInt((max * dragedWidhtInPercent) / 100) * ratio));
    if (
      dragedWidht >= 0 &&
      currentMin >= min &&
      currentMin <= currentMax - minValueBetween
    ) {
      this.minValue.style.width = dragedWidhtInPercent + "%";
      this.minValue.dataset.content = currentMin;
      this.setState({
        currentMin,
        inputMin: currentMin,
      });
      this.props.onMinChange(this.numberToTime(currentMin));
    }
  };

  onMouseUpMin = () => {
    document.removeEventListener("mouseup", this.onMouseUpMin);
    document.removeEventListener("mousemove", this.onMouseMoveMin);

    document.removeEventListener("touchend", this.onMouseMoveMin);
    document.removeEventListener("touchmove", this.onTouchMoveMin);
  };

  setMax = (e) => {
    const { min, max, currentMin, minValueBetween } = this.state;

    const inputMax = this.timeToNumber(e.target.value);
    var currentMax = inputMax;

    if (inputMax >= currentMin + minValueBetween && inputMax <= max) {
      this.setState({
        inputMax,
        currentMax: parseInt(inputMax),
      });
      this.maxValue.style.width = (inputMax * 100) / max + "%";
    } else if (inputMax > max) {
      currentMax = max;
      this.setState({
        inputMax: max,
      });
      this.maxValue.style.width = 100 + "%";
    } else {
      currentMax = currentMin + minValueBetween;
      this.setState({
        inputMax: currentMin + minValueBetween,
        currentMax: currentMin + minValueBetween,
      });
      this.maxValue.style.width =
      (100*(currentMin + minValueBetween - min))/(max - min) + "%";//((currentMin + minValueBetween) * 100) / max + "%";
    }
    this.props.onMaxChange(this.numberToTime(currentMax));
  };

  changeMaxValue = (e) => {
    e.preventDefault();

    document.addEventListener("mousemove", this.onMouseMoveMax);
    document.addEventListener("mouseup", this.onMouseUpMax);

    document.addEventListener("touchmove", this.onTouchMoveMax);
    document.addEventListener("touchend", this.onMouseUpMax);
  };

  onMouseMoveMax = (e) => {
    const {
      min,
      max,
      currentMin,
      minValueBetween,
      sliderWidth,
      offsetSliderWidht,
    } = this.state;
    const maxWalueThumb = this.maxValue;
    const dragedWidht = e.clientX - offsetSliderWidht;
    var ratio = (max - min) / max;
    const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
    const currentMax = min + Math.ceil(Math.abs(parseInt((max * dragedWidhtInPercent) / 100)) * ratio);
    var temdragedWidht = min  + parseInt(dragedWidht/ratio);
    if (temdragedWidht >= currentMin + minValueBetween && currentMax >= currentMin + minValueBetween && currentMax <= max ) {
      maxWalueThumb.style.width = dragedWidhtInPercent + "%";
      maxWalueThumb.dataset.content = currentMax;
      this.setState({
        currentMax,
        inputMax: currentMax,
      });
      this.props.onMaxChange(this.numberToTime(currentMax));
    }
  };

  onTouchMoveMax = (e) => {
    if (e.touches.length !== 0) {
      const {
        min,
        max,
        currentMin,
        minValueBetween,
        sliderWidth,
        offsetSliderWidht,
      } = this.state;
      const maxWalueThumb = this.maxValue;
      var touch = e.touches[0];
      const dragedWidht = touch.clientX - offsetSliderWidht;
      const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
      var ratio = (max - min) / max;
      
      const currentMax = min + Math.ceil(Math.abs(parseInt((max * dragedWidhtInPercent) / 100)) * ratio);
    var temdragedWidht = min  + parseInt(dragedWidht/ratio);

      if (temdragedWidht >= currentMin + minValueBetween && currentMax >= currentMin + minValueBetween && currentMax <= max) {
        maxWalueThumb.style.width = dragedWidhtInPercent + "%";
        maxWalueThumb.dataset.content = currentMax;
        this.setState({
          currentMax,
          inputMax: currentMax,
        });
      this.props.onMaxChange(this.numberToTime(currentMax));
      }
    }
  };

  onMouseUpMax = () => {
    document.removeEventListener("mouseup", this.onMouseUpMax);
    document.removeEventListener("mousemove", this.onMouseMoveMax);

    document.removeEventListener("touchend", this.onMouseUpMax);
    document.removeEventListener("touchmove", this.onTouchMoveMax);
  };

  maxForMin = () => {
    const { currentMax, minValueBetween } = this.state;
    return currentMax - minValueBetween;
  };

  minForMax = () => {
    const { currentMin, minValueBetween } = this.state;
    return currentMin + minValueBetween;
  };

  numberToTime = (number) => {
    var hrs =
      number / 60 >= 10 ? parseInt(number / 60) : "0" + parseInt(number / 60);
    var min = number % 60 >=10 ? number % 60 : "0" + (number % 60);
    return hrs + ":" + min;
  };

  timeToNumber = (time) => {
    var temp = time.split(":");
    return parseInt(temp[0]) * 60 + parseInt(temp[1]);
  };

  render() {
    const { min, max, currentMin, inputMin, currentMax, inputMax } = this.state;

    return (
      <div className={this.props.className}>

        <div ref={(ref) => (this.slider = ref)} id="slider">
          <div
            ref={(ref) => (this.minValue = ref)}
            id="min"
            disabled={this.props.disabled}
            data-content={currentMin}
          >
            <div
              ref={(ref) => (this.minValueDrag = ref)}
              id="min-drag"
              onMouseDown={this.props.disabled ? null : this.changeMinValue}
            ></div>
          </div>

          <div
            ref={(ref) => (this.maxValue = ref)}
            id="max"
            disabled={this.props.disabled}
            data-content={currentMax}
          >
            <div
              ref={(ref) => (this.maxValueDrag = ref)}
              id="max-drag"
              onMouseDown={this.props.disabled ? null : this.changeMaxValue}
            ></div>
          </div>
        </div>
        <div className="row py-2 my-3 w-100">
          <input
            type="time"
            className="col-5 form-control d-flex py-0 filter-input-inditor px-1"
            value={this.numberToTime(inputMin)}
            min={this.numberToTime(min)}
            max={this.numberToTime(this.maxForMin())}
            onChange={this.setMin}
            disabled={ this.props.disabled}
          />
          <span className="col-1 px-0 text-center font-weight-bold">-</span>
          <input
            type="time"
            className="col-6 form-control d-flex py-0 filter-input-inditor px-1"
            value={this.numberToTime(inputMax)}
            onChange={this.setMax}
            min={this.numberToTime(this.minForMax())}
            max={this.numberToTime(max)}
            disabled={ this.props.disabled}
          />
        </div>
      </div>
    );
  }
}

export default DoubleRangeSlider;
