import React, { Component } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Translation from "i18next";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AirportSelectStyle from "../../../../../master-data/booking-widget/airpot-select-style";
import { PaymentService } from "../../../../../service/booking/payment/PaymentService";
import HttpService from "../../../../../service/shared/HttpService";
import { paymentAction } from "../../../../../redux/actions/booking/paymentAction";
import { UnionPayInfo } from "../../../../../model/payment/UnionPayInfo";
import ProgressSimpler from "../../../../shared/progress-simple/ProgressSimpler";
import { UnionPayValidatorService } from "../../../../../service/booking/payment/UnionPayValidatorService";
import RedirectToUnionPay from "./RedirectToUnionPay";
import SubmitPayment from "../../../../booking/payment/SubmitPayment";
class ExchangeUnionPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      unionPayInfoState: new UnionPayInfo(),
      unionPayValidatorState: new UnionPayValidatorService(),
      unionPayRedirect: null,
    };
    this.selectStyle = AirportSelectStyle.desktopStyle;
    this.unionPayInfo = new UnionPayInfo();
    this.unionPayValidator = new UnionPayValidatorService();
  }
  componentDidMount() {
    if (this.props.countryList.length === 0) {
      HttpService.getCountryListService(
        "/_masterdatasvc-masterdata/api/Country/list?memoryCachRefresh=true",
        "",
        this.props.searchFlight.token
      ).then((response) => {
        this.props.saveCountryList(response.data.lstCountriesDetail);
      });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.unionPayInfo.FopCode = this.props.unionPay.fopCode;
    this.unionPayInfo.FopSubCode = this.props.unionPay.fopSubCode;
    this.unionPayInfo.Amount =
      this.props.flightSelectionResult.total.alternatives[0][0].amount;
    this.unionPayInfo.Currency =
      this.props.flightSelectionResult.total.alternatives[0][0].currency;
    let unionPayRequestObj = PaymentService.mapToUnionPayRequestObject(
      this.unionPayInfo
    );
    unionPayRequestObj.cookieSabreDataRequest =
      this.props.flightCookieSabreRequest.cookieSabreDataRequest;
    this.setState({
      unionPayValidatorState:
        this.state.unionPayValidatorState.validateUnionPayInfo(
          this.unionPayInfo
        ).unionPayValidation,
    });
    this.unionPayValidator =
      this.state.unionPayValidatorState.validateUnionPayInfo(
        this.unionPayInfo
      ).unionPayValidation;
    let unionPayValidLists =
      this.state.unionPayValidatorState.validateUnionPayInfo(
        this.unionPayInfo
      ).unionPayValidLists;
    let isUnionPayInfoValid = !unionPayValidLists.includes(false);
    if (isUnionPayInfoValid) {
      this.setState({ isLoading: true });
      HttpService.postServiceExchange(
        unionPayRequestObj,
        "/etibe-exchangeapi/V1.0/Purchase/Purchase",
        this.props.searchFlight.token
      ).then((response) => {
        
        let redirectPathHtml =
          response.data.redirectInfo.payerAuthenticationRequestForm;
        // redirectPathHtml = redirectPathHtml.replace("<html>", "");
        // redirectPathHtml = redirectPathHtml.replace("</html>", "");

        this.setState({ isLoading: false, unionPayRedirect: redirectPathHtml });
      });
    }
  };
  onInputKeyDown = (unionPayValidator) => {
    
    this.setState({ unionPayValidatorState: unionPayValidator });
  };
  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <ProgressSimpler />
        </div>
      );
    }
    if (this.state.unionPayRedirect != null) {
      
      return (
        //  <RedirectToUnionPay redirectPath={this.state.redirectPath}/>
        <Redirect
          to={{
            pathname: "/redirectUnionPay",
            state: { redirectToPath: this.state.unionPayRedirect },
          }}
        />
      );
    }
    return (
      <div className="container bg-white py-4 union-pay-container">
        <Row>
          <Col>
            <img src="/unionpay-logo.png" className="payment-option-logo" />
            <span className="h4 pl-3">Union Pay</span>
          </Col>
        </Row>
        <div className="py-3">{Translation.t("union_pay_redirect_remark")}</div>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col xs={12} className="my-2">
              <Form.Label>{Translation.t("email_address")}</Form.Label>
              <Form.Control
                type="email"
                value={this.state.unionPayInfoState.Email}
                onChange={(event) =>
                  (this.unionPayInfo.Email = event.target.value)
                }
                placeholder={
                  this.state.unionPayValidatorState.isEmailValid
                    ? Translation.t("email_address")
                    : Translation.t("email_required")
                }
                className={
                  this.state.unionPayValidatorState.isEmailValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.unionPayValidator.isEmailValid = true;
                  this.onInputKeyDown(this.unionPayValidator);
                }}
              />
            </Col>
            <Col xs={12} className="my-2">
              <Form.Label>{Translation.t("select_payment_country")}</Form.Label>
              <Select
                options={PaymentService.getCountryList(this.props.countryList)}
                styles={this.selectStyle}
                placeholder={
                  this.state.unionPayValidatorState.isCountryValid
                    ? Translation.t("select_country")
                    : Translation.t("country_required")
                }
                className={
                  this.state.unionPayValidatorState.isCountryValid
                    ? "input-field"
                    : "invalid-input-field"
                }
                onKeyDown={() => {
                  this.unionPayValidator.isCountryValid = true;
                  this.onInputKeyDown(this.unionPayValidator);
                }}
                onChange={(event) => {
                  this.unionPayInfo.Country = event.value;
                  this.unionPayValidator.isCountryValid = true;
                  this.onInputKeyDown(this.unionPayValidator);
                }}
              />
            </Col>
            <Col>
              <div className="py-4">
                <SubmitPayment />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    countryList: state.payment.countryList,
    flightCookieSabreRequest: state.exchange.flightSearchExtraInfo,
    flightSelectionResult: state.exchange.flightSelectionResult,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveCountryList: (countryList) => {
      dispatch({
        action: paymentAction.SAVECOUNTRYLIST,
        data: countryList,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ExchangeUnionPay);
