import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import AirportSelection from "../../booking/booking-widget/airport-selection/AirportSelection";
import DatePicker from "../../booking/booking-widget/booking-calendar/DatePicker";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Translation from "i18next";

const FlightSchedule = (props) => {

  let history = useHistory();

  return (
    <>
      <h2>{Translation.t("enter_flight_information")}</h2>
      <p>
      {Translation.t("please_note")}
      </p>
      <Row>
        <AirportSelection flightIndex={0} />
      </Row>
      <Row>
        <DatePicker flightIndex={0} name="departure_date_0" />
      </Row>
      <Row className="mt-5">
        <Col xs={12} sm={12} md={7} lg={8}></Col>
        <Col xs={12} sm={12} md={5} lg={4}>
          <Button
            variant="danger"
            className="btn-block bk-submit-btn btn-ethiopian"
            id="start-booking-btn"
            onClick={() => history.push("/flight-schedule-result")}
            disabled={props.itineraryPart[0].params.from !== null &&
              props.itineraryPart[0].params.to !== null &&
              props.itineraryPart[0].params.when.departureDate !== null ? "" : "disabled"}
          >
          {Translation.t("search")}
          </Button>
        </Col>
      </Row>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
    isMedium: state.global.isMedium,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    storeSeatMaps: (payload, selectedSeats) => {
      dispatch({
        type: "MBYSTORESEATRESULT",
        payload: payload,
        selectedSeats: selectedSeats,
      });
    }
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FlightSchedule);