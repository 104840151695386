import { paymentAction } from "../../actions/booking/paymentAction";

const intialState = {
  countryList: [],
  reservationDetail: null,
  rawRequest : "",
};
const paymentReducer = (state = intialState, action) => {
  switch (action.type) {
    case paymentAction.SAVECOUNTRYLIST:
      let countryList = action.data;
      return {
        ...state,
        countryList: countryList,
      };
    case paymentAction.SAVERESERVATIONDETAIL:
      return {
        ...state,
        reservationDetail: action.reservationDetail,
      };
      case paymentAction.SAVERAWREQUEST:
        return {
          ...state,
          rawRequest: action.rawRequest,
        };
    default:
      return state;
  }
};
export default paymentReducer;
