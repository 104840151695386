import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import AirportSelection from "../../booking/booking-widget/airport-selection/AirportSelection";
import { connect } from "react-redux";
import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { flightStatusAction } from "../../../redux/actions/manage-my-booking/flightStatusAction";
import { useHistory } from "react-router-dom";
import { globalActionType } from "../../../redux/actions/shared/globalAction";
import Translation from "i18next";

const FlightStatus = (props) => {
  // const [searchType, setSearchType] = useState(1);
  let history = useHistory();

  var searchBody;
  if (props.flightStatusSearchType === 1) {
    searchBody = (
      <Row>
        <AirportSelection flightIndex={0} />
      </Row>
    );
  } else {
    searchBody = (
      <Row className="my-2">
        <Col xs={12} sm={12} md={6}>
          <input
            className="form-control widget-input"
            placeholder="Flight Number"
            value={props.flightNumber}
            type="number"
            onChange={(event) => props.updateFlightNumber(event.target.value)}
          />
        </Col>
      </Row>
    );
  }
  return (
    <>
      <h2>{Translation.t("enter_flight_information")}</h2>
      <p>
        <b>{Translation.t("please_note")}</b>
      </p>
      <div className="journey-type">
        <Button
          variant={props.flightStatusSearchType === 1 ? "success" : "white"}
          className={props.flightStatusSearchType === 1 ? "active" : ""}
          onClick={() => props.updateSearchType(1)}
        >
          {Translation.t("route")}
        </Button>
        <Button
          variant={props.flightStatusSearchType === 2 ? "success" : "white"}
          className={props.flightStatusSearchType === 2 ? "active" : ""}
          onClick={() => props.updateSearchType(2)}
        >
          {Translation.t("flight_#")}
        </Button>
        {searchBody}
        <Row className="mt-5">
          <Col xs={12} sm={12} md={7} lg={8}></Col>
          <Col xs={12} sm={12} md={5} lg={4}>
            {console.log(
              props.flightStatusSearchType === 1 &&
                props.itineraryPart[0].params.from === null &&
                props.itineraryPart[0].params.to === null,
              props.flightStatusSearchType === 2 && props.flightNumber === ""
            )}
            <Button
              variant="danger"
              className="btn-block bk-submit-btn btn-ethiopian"
              id="start-booking-btn"
              disabled={
                (props.flightStatusSearchType === 1 &&
                  props.itineraryPart[0].params.from === null &&
                  props.itineraryPart[0].params.to === null) ||
                (props.flightStatusSearchType === 2 &&
                  props.flightNumber === "")
                  ? "disabled"
                  : ""
              }
              onClick={() => {
                props.updateIsLoading(true);
                history.push("/flight-status-result");
              }}
            >
            {Translation.t("Search")}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
    isLoading: state.global.isLoading,
    flightSchedules: state.flightSchedule.flightSchedules,
    flightStatusSearchType: state.flightStatus.flightStatusSearchType,
    flightNumber: state.flightStatus.flightNumber,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBackedupSchedules: (value) =>
      dispatch({
        type: flightScheduleAction.SCHEDULEINITFLIGHTS,
        payload: value,
      }),
    updateSearchType: (value) =>
      dispatch({
        type: flightStatusAction.UPDATESTATUSSERACHTYPE,
        payload: value,
      }),
    updateFlightNumber: (value) =>
      dispatch({
        type: flightStatusAction.UPDATEFLIGHTNUMBER,
        payload: value,
      }),
    updateIsLoading: (value) =>
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FlightStatus);
