import React from "react";
import "./EditFlight.css";
import { Row, Col } from "react-bootstrap";
import CabinClass from "../CabinClass";
import JourneyType from "../JourneyType";
import PassengerSelector from "../passenger-selection/PassengerSelector";
import StartBookingButton from "../StartBookingButton";
import AirportSelection from "../airport-selection/AirportSelection";
import DatePicker from "../booking-calendar/DatePicker";
import PromoCode from "../PromoCode";
import { connect } from "react-redux";
import Multicity from "../multicity/Multicity";
import Stopover from "../stopover/StopOver";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import Translation from "i18next";
import FlightLoading from "../../../shared/progress-loading/FlightLoading";
import { Redirect } from "react-router";
import HttpService from "../../../../service/shared/HttpService";
import { flightSelectionAction } from "../../../../redux/actions/booking/flightSelectionAction";
import { matrixAction } from "../../../../redux/actions/booking/matrixAction";



class EditFlight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchCriteriaChange: false,
    };
    this.oldBookingWidget = JSON.parse(JSON.stringify(this.props.bookingWiget));
  }
  searchCriteriaChange = (value) => {
    if (this.state.isSearchCriteriaChange !== value)
      this.setState({ isSearchCriteriaChange: value });
  };
  componentDidUpdate = () => {
    if (
      JSON.stringify(this.oldBookingWidget) ===
      JSON.stringify(this.props.bookingWiget)
    ) {
      this.searchCriteriaChange(false);
    } else {
      this.searchCriteriaChange(true);
    }
  };
  getJourneyType = () => {
    if (this.props.journeyType === "multicity") {
      return (
        <>
          <Multicity displayLabel={true} />
          <Row>
            <PassengerSelector labelMessage="Passengers" />
            <CabinClass labelMessage="Cabin Class" />
          </Row>
        </>
      );
    }

    if (this.props.journeyType === "stopover") {
      return (
        <>
          <Stopover displayLabel={true}/>
          <Row>
            <PassengerSelector labelMessage="Passengers"/>
            <CabinClass labelMessage="Cabin Class"/>
          </Row>
        </>
      );
    } else
      return (
        <>
          <Row>
            <Col>
              <h5>{Translation.t("airports")}</h5>
            </Col>
          </Row>
          <Row>
            <AirportSelection flightIndex={0} />
          </Row>

          <Row>
            <PassengerSelector labelMessage="Passengers" />
            <CabinClass labelMessage="Cabin Class" />
          </Row>
          <Row>
            <Col>
              <h5 className="mt-3">Travel Date</h5>
            </Col>
          </Row>
          <Row>
            <DatePicker flightIndex={0} name="Edit Departure Date" />
            {this.props.journeyType === "roundTrip" ? (
              <DatePicker
                flightIndex={0}
                name="Edit Return Date"
                isReturn={true}
                minDate={
                  this.props.bookingWiget.itineraryPart[0].params.when
                    .departureDate
                }
              />
            ) : null}
          </Row>
        </>
      );
  };
  redirectToMatrix = () => {
    var tempSerachRequest = this.props.flightSearchRequest;
    tempSerachRequest.searchType = "MATRIX";
    HttpService.postService(
      tempSerachRequest,
      "/Availability/AdvancedSearch",
      this.props.token
    )
      .then((response) => {
        this.props.saveMatrixResult(response.data);
        this.props.history.push("/matrix");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeLoadingType = (
    redirectToFlightSelection = false,
    redirectToMatrix = false
  ) => {
    if (redirectToFlightSelection) {
      this.props.updateFlightIndex();
      this.props.history.push("/flightSelect");
    } else if (redirectToMatrix) {
      if (this.journeyType === "roundTrip") {
        this.redirectToMatrix();
      }
    } else {
      this.setState({ isLoading: !this.state.isLoading });
    }
  };
  render() {
    if (this.state.isLoading) {
      return (
        <>
          <FlightLoading />
        </>
      );
    } else {
      return (
        <>
          {this.props.openEditFlight && (
            <div className="edit-flight-box">
              <div className="container px-0 px-sm-5">
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <h4 className="float-left">
                      {Translation.t("view_or_edit_trip_details")}
                    </h4>
                  </Col>
                  <JourneyType />
                </Row>
                {this.getJourneyType()}
                <Row>
                  <PromoCode />
                </Row>

                <StartBookingButton
                  editFlight={true}
                  hideEditFlight={this.props.closeEditFlight}
                  isSearchCriteriaChange={this.state.isSearchCriteriaChange}
                  changeLoading={this.changeLoadingType}
                />
              </div>
            </div>
          )}
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    bookingWiget: state.bookingWiget,
    journeyType: state.bookingWiget.journyType,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeJournyType: (journeyType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEJOURNYTYPE,
        journeyType: journeyType,
      }),
    updateFlightIndex: () => {
      dispatch({
        type: flightSelectionAction.UPDATEFLIGHTINDEX,
        flightIndex: 0,
      });
    },
    saveMatrixResult: (responseData) => {
      dispatch({
        type: matrixAction.INITIALIZESATE,
        responseData: responseData,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFlight);
