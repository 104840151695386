import React from "react";
import "./ancillary.css";
import { Accordion, Card, Row, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCheck,
  faEdit,
  faInfo,
  faInfoCircle,
  faPlaneArrival,
  faPlaneDeparture,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { ancillaryAction } from "../../../redux/actions/booking/ancillaryAction";
import BaggageService from "../../../service/booking/ancillary/BaggageService";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../seat-selection/Loading"
class BaggagePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading:false
    };
    this.minimumPriceFinder();
  }
  componentDidMount()
  {
    window.scrollTo({ top: 0, behavior: "smooth" });

  }
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  ancillaryForPassenger = (passengerId, itineraryID) => {
    var passenger;
    this.props.ancillaryPassengers.forEach((tempPassenger) => {
      if (tempPassenger["@id"] === passengerId) {
        passenger = tempPassenger;
      }
    });
    var tempAccillary = [];
    this.props.ancillaries.ancillaries.forEach((ancillary) => {
      ancillary.travelPartOffers.forEach((travelPartOffers) => {
        if (travelPartOffers.travelPart["@ref"] === itineraryID) {
          travelPartOffers.passengerOffers.forEach((passenger) => {
            if (passenger.ancillaryPassenger["@ref"] === passengerId) {
              tempAccillary.push(ancillary);
            }
          });
        }
      });
    });

    return {
      passenger: passenger,
      ancillary: tempAccillary,
    };
  };
  minimumPriceFinder = () => {
    var minmumPrice = 0;
    var currency = null;
    this.props.ancillaries.ancillaries.forEach((ancillary) => {
      ancillary.travelPartOffers.forEach((travelPartOffers) => {
        travelPartOffers.passengerOffers.forEach((passenger) => {
          if (minmumPrice === 0) {
            minmumPrice =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount;
            currency =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].currency;
          } else if (
            passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount <
            minmumPrice
          ) {
            minmumPrice =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount;
          }
        });
      });
    });

    this.minimumPrice =this.numberWithCommas(minmumPrice);
    this.currency = currency;
  };
  addBaggage = (passengerId,ancillaryIndex,ancillaryCode,price,maxQuantityPerPax,itinerayIndex,itineryId) => {
    var passenger = this.props.baggageModel.filter(p=>p.passengerId===passengerId)[0];
    if(passenger.bags[itinerayIndex][ancillaryIndex].quantity < maxQuantityPerPax){
      this.props.addBag(passengerId,ancillaryIndex, ancillaryCode, price,itinerayIndex,itineryId);
    }

  }
  minusBaggage = (passengerId,ancillaryIndex,price,itinerayIndex) => {
    var passenger = this.props.baggageModel.filter(p=>p.passengerId===passengerId)[0];
    if(passenger.bags[itinerayIndex][ancillaryIndex].quantity !==0){
      this.props.minusBag(passengerId, ancillaryIndex, price,itinerayIndex);
    }
  }
  getPassengerData=(attribute,passengerId,itinerayIndex=null,ancillaryIndex=null)=>{
    var passenger = this.props.baggageModel.filter(p=>p.passengerId===passengerId)[0];
     if (ancillaryIndex !== null && itinerayIndex != null) {
       return passenger.bags[itinerayIndex][ancillaryIndex].quantity;
     } else if (itinerayIndex !== null) {
       return this.numberWithCommas(passenger.amount[itinerayIndex][attribute]);
     } 
    }
  addToCart=()=>{
    if(this.props.totalPrice !=0||this.props.bagIsAdded){
      this.setState({ showModal: false,isLoading:true });
      var baggageService = new BaggageService();
     var baggageRequest = baggageService.buildBaggageRequest(this.props.bagIsAdded,this.props.itineraryList,this.props.baggageModel,this.props.cookieSabreDataRequest,this.props.baggageRequest);
     if(baggageRequest.ancillaryOperations.length !==0)
     {
       HttpService.ancillaryPostService(
         baggageRequest,
         "/Ancillary/AddAncillary",
         this.props.token
       )
         .then((ancillaryResponse) => {
           this.setState({isLoading:false});
           if (ancillaryResponse.data.statusAPI === "SUCCESS") {
             this.props.addedToCart(ancillaryResponse.data.ancillaryOperationResults,baggageRequest);
           }
         })
         .catch((error) => {
           console.log(error);
         });
     }
    }

  }
  getClassNameForButton=(passengerId,itinerayIndex=null,ancillaryIndex=null, maxQuantityPerPax=0)=>{
    var passenger = this.props.baggageModel.filter(p=>p.passengerId===passengerId)[0];
     if (ancillaryIndex !== null && itinerayIndex != null) {

       var tempQunatity = passenger.bags[itinerayIndex][ancillaryIndex].quantity;
       if(tempQunatity ===maxQuantityPerPax){
         return"bk-submit-btn btn-ethiopian bag-button btn-not-allowd disabled";
       }
       else{
        return" bk-submit-btn btn-ethiopian bag-button ";

       }
     }
  }
  render() {
    return (
      <>
        {this.state.isLoading?<Loading/>:null}
        <h4>
          <Translation Key="ancillary_header" />
        </h4>
        <h6>
          <Translation Key="ancillary_paragraph" />
        </h6>
        <div className="card bag-card">
          <div className="bag-header m-3">
            <h3 className="my-4">{this.props.ancillaries.name}</h3>
            <img
              alt="Image for Excess Baggage"
              class="bag-img"
              src={this.props.ancillaries.logo}
            />
            <p className="bag-intro">
              {this.props.ancillaries.description !== null
                ? this.props.ancillaries.description.split("\n")[0]
                : null}
            </p>
          </div>
          <div className="bag-content mx-3">
            {this.props.bagIsAdded ? (
              <div>
                <h3 className="added-to-cart">
                  <FontAwesomeIcon icon={faCheck} />
                  <Translation Key="added_to_cart" />
                </h3>
              </div>
            ) : null}
            <p>
              {" "}
              <Translation Key="bags" />
            </p>
            <p>
              <b>
                {this.props.bagIsAdded
                  ? this.numberWithCommas(this.props.finaloverAllTotal)
                  : this.minimumPrice}{" "}
                {this.currency}
              </b>
            </p>
            <p className="bag-text">
              {" "}
              <Translation Key="per_bag" />
              / <Translation Key="per_passenger" />
            </p>
            <p className="bag-axillary">
              <Translation Key="free_bags_included" />
            </p>
          </div>
          <div className="bag-footer">
            <Button
              className="bk-submit-btn btn-ethiopian m-3"
              onClick={() => this.setState({ showModal: true })}
            >
              {this.props.bagIsAdded ? (
                <span>
                  <FontAwesomeIcon icon={faEdit} />
                  <Translation Key="change" />
                </span>
              ) : (
                <Translation Key="add_bags" />
              )}
            </Button>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <Row>
              <div className="col-12 col-lg-4 d-none d-lg-block">
                <h3 className="my-4">{this.props.ancillaries.name}</h3>

                <img
                  alt="Image for Excess Baggage"
                  className="bag-img"
                  src={this.props.ancillaries.logo}
                />
              </div>
              <div className="col-12 col-lg-5 mt-lg-5">
                <h6 className="mt-4">
                  {this.props.ancillaries.description !== null
                    ? this.props.ancillaries.description.split("\n")[1]
                    : null}
                </h6>
              </div>
              <div className="col-12 col-lg-3 mt-lg-5 d-none d-lg-block">
                <div class="bag-content mt-4">
                  {this.props.bagIsAdded ? (
                    <div>
                      <h5 className="added-to-cart">
                        <FontAwesomeIcon icon={faCheck} />
                        <Translation Key="added_to_cart" />
                      </h5>
                    </div>
                  ) : null}
                  <p>
                    {" "}
                    <Translation Key="bags" />
                  </p>
                  <p>
                    <b>
                      {this.minimumPrice} {this.currency}
                    </b>
                  </p>
                  <p class="bag-text">
                    {" "}
                    <Translation Key="per_bag" />
                    / <Translation Key="per_passenger" />
                  </p>
                </div>
              </div>
            </Row>
          </Modal.Header>

          <Modal.Body>
            {this.props.itinerary.itineraryParts.map(
              (itinerary, itinerayIndex) => {
                var stopAirport = [];
                return (
                  <Accordion defaultActiveKey={'"' + itinerayIndex + '"'}>
                    <Card key={itinerayIndex} className="bag-modal-body">
                      <Accordion.Toggle eventKey={'"' + itinerayIndex + '"'}>
                        <Card.Header className="bag-header">
                          <h4>
                            {this.props.journeytype === "roundTrip" ||
                            this.props.journeytype === "oneWay" ? (
                              itinerayIndex === 0 ? (
                                <span>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                                  <Translation Key="departure_flight" />
                                </span>
                              ) : (
                                <span>
                                  <FontAwesomeIcon icon={faPlaneArrival} />{" "}
                                  <Translation Key="return_flight" />
                                </span>
                              )
                            ) : (
                              <span>
                                <span>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                                  {itinerayIndex + 1}
                                  {"  "}
                                  {itinerary.segments[0].origin}
                                  {"  "}
                                  <FontAwesomeIcon icon={faAngleRight} />{" "}
                                  {
                                    itinerary.segments[
                                      itinerary.segments.length - 1
                                    ].destination
                                  }
                                </span>
                              </span>
                            )}
                          </h4>
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={'"' + itinerayIndex + '"'}>
                        <Card.Body>
                          <div>
                            <h5 className="text-left">
                              {" "}
                              {itinerary.segments.map(
                                (segments, segmentsIndex) => {
                                  stopAirport.push(
                                    segments.flight.airlineCode +
                                      " " +
                                      segments.flight.flightNumber
                                  );
                                }
                              )}
                              {stopAirport.join(",")}
                              {" : "}
                              {AirportFullName.getFullName(
                                itinerary.segments[0].origin
                              )}{" "}
                              <FontAwesomeIcon icon={faAngleRight} />{" "}
                              {AirportFullName.getFullName(
                                itinerary.segments[
                                  itinerary.segments.length - 1
                                ].destination
                              )}
                            </h5>
                            <p className="text-left">
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Select the number of bags, and their respective
                              weight, that you will be carrying.
                            </p>
                          </div>
                          <div>
                            {this.props.ancillaryPassengers.map(
                              (passenger, passengerIndex) => {
                                var passengerAncillary = this.ancillaryForPassenger(
                                  passenger["@id"],
                                  itinerary["@id"],
                                  itinerary.segments[0].origin,
                                  itinerary.segments[
                                    itinerary.segments.length - 1
                                  ].destination,
                                  itinerary.segments[0].departure
                                );
                                if (passengerAncillary.ancillary.length !== 0) {
                                  return (
                                    <Row
                                      key={passengerIndex}
                                      className="mt-lg-5 ml-lg-5"
                                    >
                                      <div className="col-12 col-lg-4 text-center">
                                        <Row className="text-center">
                                          <div className="col-12 text-center">
                                            <h5 className="mb-0 pr-5">
                                              {" "}
                                              {
                                                passengerAncillary.passenger
                                                  .firstName
                                              }{" "}
                                              {
                                                passengerAncillary.passenger
                                                  .lastName
                                              }
                                            </h5>
                                          </div>
                                          <div className="col-12 mt-0 text-center ">
                                            <Row className="">
                                              <div className="col-md-2 m-0 text-right d-none d-md-block">
                                                <FontAwesomeIcon
                                                  icon={faSuitcase}
                                                  size="2x"
                                                />
                                              </div>
                                              <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                                {"  "}
                                                {"  "}
                                                <Translation Key="number_of_bags" />
                                                :{" "}
                                                <span className="mx-2">
                                                  {this.getPassengerData(
                                                    "totalQuantity",
                                                    passenger["@id"],
                                                    itinerayIndex
                                                  )}
                                                </span>
                                                <b>
                                                  <span className="mx-1">
                                                    {this.getPassengerData(
                                                      "totalPrice",
                                                      passenger["@id"],
                                                      itinerayIndex
                                                    )}
                                                  </span>{" "}
                                                  {this.currency}
                                                </b>
                                              </div>
                                              <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                                <span className="text-center">
                                                  <Translation Key="free_bags" />
                                                </span>
                                              </div>
                                            </Row>
                                          </div>
                                        </Row>
                                      </div>
                                      <div className="col-12 col-lg-8 text-center">
                                        <Row>
                                          {passengerAncillary.ancillary.map(
                                            (ancillary, ancillaryIndex) => (
                                              <div
                                                className="col-12 mt-2 mt-lg-5 mt-md-0 col-lg-6 col-xl-6 text-center"
                                                key={ancillaryIndex}
                                              >
                                                {ancillary.name}
                                                <p>
                                                  <b>
                                                    {this.numberWithCommas(
                                                      ancillary
                                                        .travelPartOffers[0]
                                                        .passengerOffers[0]
                                                        .ancillaryPrice
                                                        .totalPrice
                                                        .alternatives[0][0]
                                                        .amount
                                                    )}
                                                  </b>
                                                  {"  "}{" "}
                                                  <b>
                                                    {
                                                      ancillary
                                                        .travelPartOffers[0]
                                                        .passengerOffers[0]
                                                        .ancillaryPrice
                                                        .totalPrice
                                                        .alternatives[0][0]
                                                        .currency
                                                    }
                                                  </b>
                                                </p>

                                                <div className="quantity text-xl-left">
                                                  <span className="bag-quantity-input pt-2">
                                                    {" "}
                                                    {this.getPassengerData(
                                                      "quantity",
                                                      passenger["@id"],
                                                      itinerayIndex,
                                                      ancillaryIndex
                                                    )}
                                                  </span>
                                                  <span className="float-xl-right">
                                                    <Button
                                                      className={this.getClassNameForButton(
                                                        passenger["@id"],
                                                        itinerayIndex,
                                                        ancillaryIndex
                                                      )}
                                                      onClick={this.minusBaggage.bind(
                                                        this,
                                                        passenger["@id"],
                                                        ancillaryIndex,
                                                        ancillary
                                                          .travelPartOffers[0]
                                                          .passengerOffers[0]
                                                          .ancillaryPrice
                                                          .totalPrice
                                                          .alternatives[0][0]
                                                          .amount,
                                                        itinerayIndex
                                                      )}
                                                    >
                                                      -
                                                    </Button>

                                                    <Button
                                                      className={this.getClassNameForButton(
                                                        passenger["@id"],
                                                        itinerayIndex,
                                                        ancillaryIndex,
                                                        ancillary
                                                          .ancillaryConfiguration
                                                          .maxQuantityPerPax
                                                      )}
                                                      onClick={this.addBaggage.bind(
                                                        this,
                                                        passenger["@id"],
                                                        ancillaryIndex,
                                                        ancillary.code,
                                                        ancillary
                                                          .travelPartOffers[0]
                                                          .passengerOffers[0]
                                                          .ancillaryPrice
                                                          .totalPrice
                                                          .alternatives[0][0]
                                                          .amount,
                                                        ancillary
                                                          .ancillaryConfiguration
                                                          .maxQuantityPerPax,
                                                        itinerayIndex,
                                                        itinerary["@id"]
                                                      )}
                                                    >
                                                      +
                                                    </Button>
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </Row>
                                      </div>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <Row>
                                      <div className="col-6">
                                        <h5 className="mb-0 pr-5">
                                          {" "}
                                          {
                                            passengerAncillary.passenger
                                              .firstName
                                          }{" "}
                                          {
                                            passengerAncillary.passenger
                                              .lastName
                                          }
                                        </h5>
                                      </div>
                                      <div className="col-6">
                                        <h6 className="float-right">
                                          Unavailable for{" "}
                                          {passengerAncillary.passenger.type}
                                        </h6>
                                      </div>
                                    </Row>
                                  );
                                }
                              }
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              }
            )}
            <div className="text-right">
              <h5>
                <b>
                  <Translation Key="total" />:{" "}
                  <span id="baggageTotal">
                    {this.numberWithCommas(this.props.totalPrice)}
                  </span>{" "}
                  {this.currency}
                </b>
              </h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="float-right">
              <Button
                variant="danger"
                className="bk-submit-btn btn-white btn-ethiopian-danger m-2"
                style={{ borderColor: "red" }}
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                <Translation Key="cancel" />
              </Button>
              <Button
                variant="danger"
                className={
                  this.props.totalPrice !== 0 || this.props.bagIsAdded
                    ? "bk-submit-btn btn-ethiopian m-2"
                    : "bk-submit-btn btn-ethiopian btn-not-allowd m-2 disabled"
                }
                onClick={this.addToCart}
              >
                {this.props.bagIsAdded ? (
                  <Translation Key="update_cart" />
                ) : (
                  <Translation Key="add_to_cart" />
                )}
              </Button>
            </span>{" "}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baggageModel: state.ancillary.baggageModel,
    totalPrice:state.ancillary.overAllTotal,
    finaloverAllTotal:state.ancillary.finaloverAllTotal,
    journeytype : state.bookingWiget.journyType,
    bagIsAdded:state.ancillary.bagIsAdded,
    cookieSabreDataRequest:state.ancillary.ancillaryList.cookieSabreDataRequest,
    token: state.searchFlight.token,
    itineraryList:state.ancillary.ancillaryList.itinerary.itineraryParts,
    baggageRequest:state.ancillary.baggageRequest
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBag: (
      passengerId,
      ancillaryIndex,
      ancillaryCode,
      price,
      itinerayIndex,
      itineryId
    ) =>
      dispatch({
        type: ancillaryAction.ADDBAG,
        passengerId: passengerId,
        ancillaryIndex: ancillaryIndex,
        ancillaryCode: ancillaryCode,
        price: price,
        itinerayIndex: itinerayIndex,
        itineryId: itineryId,
      }),
    minusBag: (passengerId, ancillaryIndex, price, itinerayIndex) =>
      dispatch({
        type: ancillaryAction.MINUSBAG,
        passengerId: passengerId,
        ancillaryIndex: ancillaryIndex,
        price: price,
        itinerayIndex: itinerayIndex,
      }),
    addedToCart: (ancillaryOperationResults,baggageRequest) =>
      dispatch({
        type: ancillaryAction.ADDTOCART,
        ancillaryOperationResults:ancillaryOperationResults,
        baggageRequest:baggageRequest
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaggagePage);
