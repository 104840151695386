export class CheckInCreditCardRequest{
    constructor()
    {
        this.requestObj = {
            "ReturnSession": true,
            "AirExtraIds": [],
            "CreditCard": {
                "NameOnCard":null,
                "TypeCode": null,
                "Number": null,
                "ExpirationDetails": null
            },
            "AuthorizationDetails": {
                "SecurityCode": null,
                "Address": {
                    "Street1": null,
                    "PostalCode": null,
                    "Country": null,
                    "City": null,
                    "StateProvince": null
                },
                "Email": null,
                "Phone": {
                    "Number": null
                }
            },
            "Context3Ds": {
                "SessionId": "25444c03fd6f46e8adb8e6a9d726d89c",
                "AdditionalId": "123",
                "IpAddress": "127.0.0.1",
                "AcceptHeader": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
                "UserAgent": "Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/31.0.1650.57 Safari/537.36",
                "ReturnUrl": "https://localhost:8443/SSW2010/ETET/remotePaymentReturn.html?_eventId=submitPaRes&execution"
            },
            "NotificationRecipients": [
                {
                    "Email": "Aadsf@sadf.sdf",
                    "PassengerId": "p01.01"
                }
            ],
            "CheckInSabreDataRequest": null,
            "MemberProfile": null,
            "RiskifiedOrderId": null
        }
    }
}