import React from "react";
import Translation  from "i18next";
import App from "../../../App";
import Notification from "../../booking/booking-widget/Notification";
import { Container } from "react-bootstrap";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,
    errorMessage:null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorMessage: error });
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Notification
            header={Translation.t("error_boundary_header")}
            body={this.state.errorMessage !=null?this.state.errorMessage.toString():Translation.t("error_boundary_body")}
            // body="we are working on getting this fixed as soon as we can. You may able to try again."
          />
        </Container>
      );
    }
    return this.props.children;
  }
}
