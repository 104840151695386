import React, { Component } from "react";
import ItineraryPartOfferHeader from "./itinerary-part-offer/ItineraryPartOfferHeader";
import "./FlightSelection.css";
import ItineraryPartOffer from "./itinerary-part-offer/ItineraryPartOffer";
import { connect } from "react-redux";
import { FlightSelectionService } from "../../../service/booking/flight-selection/FlightSelectionService";
import { flightSelectionAction } from "../../../redux/actions/booking/flightSelectionAction";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";
import FlightSelectionHeader from "./FlightSelectionHeader";
import ExceptionModal from "../../shared/expection/ExceptionModal";
import { ApiResponseStatus } from "../../../master-data/shared/APIResponseStatus";
import ProgressLoader from "../../shared/progress-loading/progress-loader";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import { globalActionType } from "../../../redux/actions/shared/globalAction";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import FlightLoading from "../../shared/progress-loading/FlightLoading";
import Notification from "../booking-widget/Notification";
import HttpService from "../../../service/shared/HttpService";
class FlightSelection extends Component {
  constructor(props) {
    super(props);
    var temp = JSON.parse(
      JSON.stringify(
        this.props.flightSearchResult.data.searchResponse.brandedResults
          .itineraryPartBrands
      )
    );
    this.state = {
      itineraryPartBrandsState: temp,
      isLoading: false,
      isException: false,
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.props.updateIsLoading(false);

    // this.setState({
    //   itineraryPartBrandsState: this.props.flightSearchResult.data
    //     .searchResponse.brandedResults.itineraryPartBrands[
    //     this.props.flightSelection.flightIndex
    //   ],
    // });
  }
  selectFlight = async (offerRef) => {
    // let flightResult = null;
    let flightSelectionService = new FlightSelectionService();
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.props.updateIsLoading(true);
    this.setState({ isLoading: true });
    if (
      this.props.flightSelection.flightIndex + 1 ===
      this.props.flightSearchRequest.itineraryParts.length
    ) {
      flightSelectionService
        .selectFlight(
          this.props.flightSearchRequest,
          offerRef,
          this.props.flightSelection.flightIndex,
          this.props.flightSearchResult.data.cookieSabreDataRequest,
          this.props.token,
          this.props.flightSearchResult
        )
        .then((response) => {
          window.scrollTo({ top: 0, behavior: "smooth" });

          if (response.data.statusAPI === ApiResponseStatus.SUCCESS) {
            this.setState({ isException: false }, () => {
              this.props.updateSelectionResult(response.data);
            });
            this.props.history.push("/flightsummary");
          } else {
            this.setState({ isException: true });
          }
          this.props.updateIsLoading(false);
        });
    } else {
      await flightSelectionService
        .searchContext(
          this.props.flightSearchRequest,
          offerRef,
          this.props.flightSelection.flightIndex,
          this.props.flightSearchResult.data.cookieSabreDataRequest,
          this.props.token,
          this.props.flightSearchResult
        )
        .then((response) => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          if (response.data.statusAPI === ApiResponseStatus.SUCCESS) {
            this.setState(
              {
                itineraryPartBrandsState:
                  response.data.searchResponse.brandedResults
                    .itineraryPartBrands,
                isLoading: false,
                isException: false,
              },
              () => {
                this.props.updateFlightResult(response);
                this.props.updateFlightIndex(
                  this.props.flightSelection.flightIndex + 1
                );
              }
            );
          } else {
            this.setState({ isException: true });
          }
          this.props.updateIsLoading(false);
        });
    }
  };

  // componentDidUpdate(){
  //   console.log(this.props.countSortUpdate)
  // }

  render() {
    if (this.state.isException) {
      return (
        <ExceptionModal
          showModal={this.state.isException}
          exceptionMessage="Sorry, Unable to process your request please try again"
        />
      );
    }
    if (this.props.isLoading) {
      if (
        this.props.journyType === "roundTrip" &&
        this.props.flightSelection.flightIndex === 0
      ) {
        return <FlightLoading />;
      } else {
        return (
          <div className="progress-container">
            {" "}
            <ProgressSimpler />
          </div>
        );
      }
    } else {
      return (
        <>
          <BreadCrumb history={this.props.history} />
          {this.props.flightSearchResult.data.statusAPI !== "SUCCESS" ? (
            <div className="mt-4 flight-selection-container">
              <FlightSelectionHeader
                flightIndex={this.props.flightSelection.flightIndex}
                // itineraryParts={this.state.itineraryPartBrandsState}
                itineraryParts={this.props.flightSearchRequest.itineraryParts}
                journyType={this.props.journyType}
              />
              {this.props.itineraryPartBrands[
                this.props.flightSelection.flightIndex
              ].length === 0 ? null : (
                <ItineraryPartOfferHeader
                  itineraryPartBrandsLength={
                    this.props.itineraryPartBrands[
                      this.props.flightSelection.flightIndex
                    ].length
                  }
                />
              )}

              <Notification
                header={this.props.flightSearchResult.data.statusAPI}
                body={this.props.flightSearchResult.data.messageAPI}
              />
            </div>
          ) : (
            <div className="mt-4 flight-selection-container">
              <FlightSelectionHeader
                flightIndex={this.props.flightSelection.flightIndex}
                // itineraryParts={this.state.itineraryPartBrandsState}
                itineraryParts={this.props.flightSearchRequest.itineraryParts}
                journyType={this.props.journyType}
              />
              {this.props.flightSearchResult.data.searchResponse.brandedResults
                .itineraryPartBrands[this.props.flightSelection.flightIndex]
                .length != 0 ? (
                <ItineraryPartOfferHeader
                  itineraryPartBrandsLength={
                    this.props.itineraryPartBrands[
                      this.props.flightSelection.flightIndex
                    ].length
                  }
                />
              ) : null}
              {this.props.flightSearchResult.data.searchResponse.brandedResults
                .itineraryPartBrands[this.props.flightSelection.flightIndex]
                .length != 0 ? (
                this.props.flightSearchResult.data.searchResponse.brandedResults.itineraryPartBrands[
                  this.props.flightSelection.flightIndex
                ].map(
                  (itineraryPartBrand, key) =>
                    itineraryPartBrand.brandOffers.length > 0 && (
                      <ItineraryPartOffer
                        marginTop={2}
                        key={this.props.flightSelection.flightIndex + "-" + key}
                        index={key}
                        // duration={itineraryPartBrand.duration}
                        itineraryPartBrand={itineraryPartBrand}
                        selectFlight={this.selectFlight}
                        flightId={
                          this.props.flightSelection.flightIndex + "-" + key
                        }
                      />
                    )
                )
              ) : (
                <h2>Flight not found</h2>
              )}
            </div>
          )}
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    flightSearchResult: state.searchFlight.searchResult,
    flightSearchRequest: state.searchFlight.searchRequest,
    flightSelection: state.flightSelection,
    token: state.searchFlight.token,
    itineraryPartBrands:
      state.searchFlight.searchResult.data.searchResponse.brandedResults
        .itineraryPartBrands,
    listenToChanges:
      state.searchFlight.searchResult.data.searchResponse.brandedResults
        .itineraryPartBrands[state.flightSelection.flightIndex],
    backedUpFlights: state.filteredFlights.backedUpFlights,
    isLoading: state.global.isLoading,
    journyType: state.bookingWiget.journyType,
    // countSortUpdate : state.filteredFlights.countSortUpdate
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateFlightIndex: (index) => {
      dispatch({
        type: flightSelectionAction.UPDATEFLIGHTINDEX,
        flightIndex: index,
      });
    },
    updateFlightResult: (result) => {
      dispatch({
        type: searchFlightAction.SAVESEARCHRESULT,
        searchResult: result,
      });
    },
    updateSelectionResult: (result) => {
      dispatch({
        type: flightSelectionAction.SAVEFLIGHTSELECTIONSUMMARY,
        selectionResult: result,
      });
    },
    updateIsLoading: (value) => {
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FlightSelection);
