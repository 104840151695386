
export default class BaggageInfoService{

    jsonMap=(baggageInfo)=>{
      var  baggageInfoCheckeId =[];
        var baggageInfoCarryOn=[];
        baggageInfo.segmentDisclosure.forEach(segmentDisclosure => {
            var tempbaggageInfoCheckeId =[];
            var ischeckedInBaggageValid = false;
            var tempbaggageInfoCarryOn =[];
            var isCarryOnValid = false;
            segmentDisclosure.passengerBaggageDisclosure.forEach((passengerBaggageDisclosure,index) => {
                if(passengerBaggageDisclosure.checkedInBaggage!==null)
                {
                    ischeckedInBaggageValid= true;
                    var bagList = [];
                    for (
                      var i = 0;
                      i <
                      parseInt(
                        passengerBaggageDisclosure.checkedInBaggage.freeBaggageAllowance
                          .totalUnits
                      );
                      i++
                    ) {
                        bagList.push(index);
                    }
                    
                   var tempbaggageInfo = {
                     baggageRestrictions:
                       passengerBaggageDisclosure.checkedInBaggage
                         .freeBaggageAllowance.baggageRestrictions,
                     totalWeight:
                       passengerBaggageDisclosure.checkedInBaggage
                         .freeBaggageAllowance.totalWeight,
                     totalUnits:
                       passengerBaggageDisclosure.checkedInBaggage
                         .freeBaggageAllowance.totalUnits,
                     passangerType: passengerBaggageDisclosure.passengerType,

                     bag: bagList,
                   };
                    tempbaggageInfoCheckeId.push(tempbaggageInfo);
                }


                if(passengerBaggageDisclosure.carryOnBaggage!==null)
                {
                    isCarryOnValid=true;
                    var bagList1 = [];
                    for (
                      var j = 0;
                      j <
                      parseInt(
                        passengerBaggageDisclosure.carryOnBaggage.freeBaggageAllowance
                          .totalUnits
                      );
                      j++
                    ) {
                        bagList1.push(index);
                    }
                    var tempCarryOnBaggage = {
                        baggageRestrictions:
                        passengerBaggageDisclosure.carryOnBaggage
                          .freeBaggageAllowance.baggageRestrictions,
                      additionalInfo:
                        passengerBaggageDisclosure.carryOnBaggage
                          .additionalInfo,
                      passangerType: passengerBaggageDisclosure.passengerType,
                      totalUnits:
                        passengerBaggageDisclosure.carryOnBaggage
                          .freeBaggageAllowance.totalUnits,
                      bag: bagList1,
                    };
                    tempbaggageInfoCarryOn.push(tempCarryOnBaggage);
                }

            });
            if(ischeckedInBaggageValid){
                var  finalBaggage ={
                    generalData:{
                     origin:segmentDisclosure.segment.origin,
                     destination:segmentDisclosure.segment.destination
                    },
                    passengerBaggageDisclosure:tempbaggageInfoCheckeId
     
                 }
                 baggageInfoCheckeId.push(finalBaggage);
            }
            if(isCarryOnValid){
                var  finalBaggage1 ={
                    generalData:{
                     origin:segmentDisclosure.segment.origin,
                     destination:segmentDisclosure.segment.destination
                    },
                    passengerBaggageDisclosure:tempbaggageInfoCarryOn
     
                 }
                 baggageInfoCarryOn.push(finalBaggage1);
            }

        });
        return baggageInfo={
            baggageInfoCheckeId:baggageInfoCheckeId,
             baggageInfoCarryOn:baggageInfoCarryOn
        }
    }
    
}