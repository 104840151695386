import ItineraryPart from "../../../model/itineraryPart/ItineraryPart";
import Passenger from "../../../model/passenger/passenger";
import Validation from "../../../model/itineraryPart/validation";
import BookingWidgetService from "../../../service/booking/booking-widget/BookingWidgetService";
import { BookingWidgetAction } from "../../actions/booking/bookingWidgetAction";
import { exchangeAction } from "../../actions/exchange/exchangeAction";
let passenger = new Passenger();
const initialState = {
  journyType: "roundTrip",
  widgetType:"bookFlight",
  itineraryPart: [new ItineraryPart()],
  passengerInfo:  new Passenger(),
  validation: [
    new Validation(),
    new Validation(),
    new Validation(),
    new Validation(),
    new Validation(),
    new Validation(),
  ],
  promoCode: null,
  cabinClass: {
    value: 3,
    label: "All",
  },
  fareType: "CURRENCY",
  isFormValid:true,
};
var BookingWidgetServiceTemp = new BookingWidgetService();
const bookingWigetReducer = (state = initialState, action) => {
  switch (action.type) {
    case BookingWidgetAction.AIRPORTSELECTION:
      return UpdateAirport(state, action);
    case BookingWidgetAction.DATESELECTION:
      return setDate(state, action);
    case BookingWidgetAction.ADDSTOPOVER:
      return ADDSTOPOVER(state, action);
    case BookingWidgetAction.ADDFLIGHT:
      return AddFlight(state);
    case BookingWidgetAction.REMOVEFLIGHT:
      return RemoveFlight(state);
    case BookingWidgetAction.ADDPROMOCODE:
      return {
        ...state,
        PromoCode: action.value,
      };
    case BookingWidgetAction.PASSENGERSELECTION:
      let passenger = new Passenger();
      passenger.adult = action.passenger.adult;
      passenger.child = action.passenger.child;
      passenger.infant = action.passenger.infant;
      return {
        ...state,
        passengerInfo: passenger,
      };

    case BookingWidgetAction.CHANGEJOURNYTYPE:
      return changeJourneyType(state, action);
      case BookingWidgetAction.CHANGEWIDGETTYPE:
        return{
        ...state,
        widgetType:action.widgetType,
        }
    case BookingWidgetAction.VALIDATEWIDGET:
      const tempState = BookingWidgetServiceTemp.validateWidget(
        state,
        action.domain,
        action.flightIndex
      );
      return {
        ...state,
        itineraryPart: tempState.itinerary,
        validation: tempState.validation,
        isFormValid:tempState.isFormValid
      };
    case BookingWidgetAction.UPDATECABINCLASS:
      return {
        ...state,
        cabinClass: action.cabinClass,
      };

    case BookingWidgetAction.UPDATESTOPDAYS:
      return updateStopDays(state, action);
    case BookingWidgetAction.REMOVESTOPOVER:
      return removeStopover(state, action);
    case BookingWidgetAction.UPDATEFARETYPE:
      return setFareType(state, action);
      case BookingWidgetAction.FILLSEARCHFORM:        
        const tempStateItinerary = BookingWidgetServiceTemp.recentSearch(action.recentSearch,action.journeyType);
        return {
            ...state,
            itineraryPart:tempStateItinerary.itinerary,
            journyType:action.journeyType,
            passengerInfo:tempStateItinerary.passenger,
            validation:tempStateItinerary.validation,
            cabinClass:tempStateItinerary.cabinClass,
            promoCode:tempStateItinerary.promoCode
        };
   case exchangeAction.SAVEFLIGHTSEARCH:
     
      return action.newValue;
      
    default:
      return state;
  }
};


function UpdateAirport(state, action) {
  const newArray = [...state.itineraryPart];
  if (action.part === "from") {
    newArray[action.flightIndex].params.from = action.value;
  } else {
    newArray[action.flightIndex].params.to = action.value;
  }
  return {
    ...state,
    itineraryPart: newArray,
  };
}

function ADDSTOPOVER(state, action) {
  const newArray = [...state.itineraryPart];
  const newIternary = new ItineraryPart();
  newIternary.params.to = action.payload.to;
  newIternary.params.from = action.payload.from;

  newArray.push(newIternary);

  return {
    ...state,
    itineraryPart: newArray,
  };
}
function AddFlight(state) {
  const newItineraryArray = [...state.itineraryPart];
  var initialItineraryPart = new ItineraryPart();
  newItineraryArray.push(initialItineraryPart);
  return {
    ...state,
    itineraryPart: newItineraryArray,
  };
}
function RemoveFlight(state, action) {
  const newItineraryArray = [...state.itineraryPart];
  newItineraryArray.pop();
  return {
    ...state,
    itineraryPart: newItineraryArray,
  };
}
function setDate(state, action) {
  const newArray = [...state.itineraryPart];
  if (action.payload.isReturn)
    newArray[action.payload.flightIndex].params.when.arrivalDate =
      action.payload.date;
  else
    newArray[action.payload.flightIndex].params.when.departureDate =
      action.payload.date;

  return {
    ...state,
    itineraryPart: newArray,
  };
}
function changeJourneyType(state, action) {
  var newItineraryArray = [...state.itineraryPart];
  const newValidationArray = [...state.validation];
  newValidationArray.forEach((validation) => {
    validation.params = new Validation().params;
  });
  if (action.journeyType === "multicity") {
    if (newItineraryArray.length === 1) {
      newItineraryArray.push(new ItineraryPart());
    }
  }
  return {
    ...state,
    journyType: action.journeyType,
    itineraryPart: newItineraryArray,
    validation: newValidationArray,
  };
}
function updateStopDays(state, action) {
  var newArray = [...state.itineraryPart];
  newArray[action.payload.flightIndex].params.stopDays =
    action.payload.stopDays;

  return {
    ...state,
    itineraryPart: newArray,
  };
}

function removeStopover(state, action) {
  var newArray = state.itineraryPart.filter(
    (val, index) => index !== parseInt(action.payload.flightIndex)
  );
  return {
    ...state,
    itineraryPart: newArray,
  };
}

function setFareType(state, action) {
  return {
    ...state,
    fareType: action.value,
  };
}

export default bookingWigetReducer;
