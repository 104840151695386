import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Translation from "i18next";
import { connect } from "react-redux";
import { TravelBankProfile } from '../../../../../model/payment/TravelBankInfo';
import SubmitPayment from '../../SubmitPayment';
import { TravelBankValidatorService } from "../../../../../service/booking/payment/TravelBankValidatorService";
import HttpService from '../../../../../service/shared/HttpService';
import { TravelBankSignInRequestObj } from '../../../../../model/payment/TravelBankSignInRequestObj';
import ProgressSimpler from '../../../../shared/progress-simple/ProgressSimpler';
import { TravelBankRequestObj } from '../../../../../model/payment/TravelBankRequestObj';

class TravelBank extends Component
{
    constructor(props)
    {
        super(props);
        this.state={
            isLoading:false,
            isTravelBankSignInRequestValid:false,
            travelBankAPIresponse:null,
            travelBankProfileState : new TravelBankProfile(),
            travelBankValidatorState : new TravelBankValidatorService()
        }
        this.travelBankProfile = new TravelBankProfile();
        this.travelBankValidatorService = new TravelBankValidatorService();
        this.travelRequestObj=new TravelBankRequestObj();
    }
    onInputKeyDown=(travelBankValidator)=>
    {
      
      this.setState({travelBankValidatorState:travelBankValidator});
    }
    submitTravelBank =(event)=>{
        event.preventDefault();
        
        let travelBankSignInRequestObj = new TravelBankSignInRequestObj();
        travelBankSignInRequestObj.requestObj.userId =this.travelBankProfile.MembershipNumber;
        travelBankSignInRequestObj.requestObj.password =this.travelBankProfile.Password;
        travelBankSignInRequestObj.requestObj.cookieSabreDataRequest =this.props.searchFlight.searchResult.data.cookieSabreDataRequest;
        this.setState({travelBankValidatorState:this.state.travelBankValidatorState.validateTravelBankInfo(this.travelBankProfile).travelBankValidation});
        this.travelBankValidatorService = this.state.travelBankValidatorState.validateTravelBankInfo(this.travelBankProfile).travelBankValidation;
        let travelBankValidLists =this.state.travelBankValidatorState.validateTravelBankInfo(this.travelBankProfile).travelBankValidLists;
        let isTravelBankInfoValid = !travelBankValidLists.includes(false);
        if(isTravelBankInfoValid)
        {
            this.setState({ isLoading: true });
            HttpService.postService(
                travelBankSignInRequestObj.requestObj,
              "/PaymentOption/GetTravelBankPaymentOption",
              this.props.searchFlight.token
            ).then((response) => {
              
              this.setState({ isLoading: false,travelBankAPIresponse:response.data});//availableAmountForTrip
        
            });
        }
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.travelRequestObj.requestObj.payment[0].amount.amount =this.state.travelBankAPIresponse.availableAmountForTrip.amount;
        this.travelRequestObj.requestObj.payment[0].amount.currency =this.state.travelBankAPIresponse.availableAmountForTrip.currency;
        this.travelRequestObj.requestObj.cookieSabreDataRequest = this.props.searchFlight.searchResult.data.cookieSabreDataRequest;

      
        this.setState({ isLoading: true });
        HttpService.postService(
            this.travelRequestObj.requestObj,
          "/Purchase/Purchase",
          this.props.searchFlight.token
        ).then((response) => {
          
          this.setState({ isLoading: false});
    
        });
        
      };
    render()
    {
        if (this.state.isLoading) {
            return (
              <div>
                <ProgressSimpler />
              </div>
            );
          }
        return(<div className="container bg-white travel-bank-form pt-4">
            <div>
                <h3>
                {Translation.t("travel_bank")}
                </h3>
                <p>
                {Translation.t("travel_bank_remark")}

                </p>
            </div>
            <Form onSubmit={this.submitTravelBank}>
           <Row>
               <Col xs={12} className="my-2">
               <Form.Control 
               value={this.state.travelBankProfileState.MembershipNumber}
                onChange={(event) =>
                    (this.travelBankProfile.MembershipNumber = event.target.value)
                  }
                  placeholder={this.state.travelBankValidatorState.isMembershipNumberValid?Translation.t("membership_number"):Translation.t("membership_number_required")}
                  className={this.state.travelBankValidatorState.isMembershipNumberValid?"input-field":"invalid-input-field"}
                  onKeyDown={()=>{this.travelBankValidatorService.isMembershipNumberValid=true;this.onInputKeyDown(this.travelBankValidatorService)}}
               ></Form.Control>
               </Col>
               <Col xs={12} className="my-2">
               <Form.Control 
               type="password"
                value={this.state.travelBankProfileState.Password}
                onChange={(event) =>
                    (this.travelBankProfile.Password = event.target.value)
                  }
                  placeholder={this.state.travelBankValidatorState.isPasswordValid?Translation.t("password"):Translation.t("password_required")}
                  className={this.state.travelBankValidatorState.isPasswordValid?"input-field":"invalid-input-field"}
                  onKeyDown={()=>{this.travelBankValidatorService.isPasswordValid=true;this.onInputKeyDown(this.travelBankValidatorService)}}></Form.Control>
               </Col>
               <Col xs={12} className="my-2">
               <Row >
                   {/* <Col>
                   <div>
                       <label>
                           Forgot Password
                       </label>
                   </div>
                   </Col> */}
                   <Col className="text-left">
                    <Button  type="submit" className="travel-bank-submit-btn">
                    {Translation.t("sign_in")}
                    </Button>
                   </Col>
               </Row>
               </Col>
           </Row>
           </Form>
           <hr />
           <Form onSubmit={this.handleSubmit}>

               <Row>
               <Col>
              <div className="py-4">
                <SubmitPayment />
              </div>
            </Col>
               </Row>
           </Form>

        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
      searchFlight: state.searchFlight,
    };
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(TravelBank);
  