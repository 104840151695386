import React, { Component } from "react";
import { ListGroup, Nav } from "react-bootstrap";
import Translation from "i18next";

import "./Header.css";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { Redirect } from "react-router";

class HeaderDropdownLinkSM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langSelectorStatus: "hide",
      mshowDropdownLinks: false,
      showLangSelector: false,
      showLoginPage: false,
      showModal: false,
      redirectToHome: false,
      redirectTocheckIn: false,
    };
  }

  render() {
    if (this.state.redirectToHome) {
      this.setState({ redirectToHome: false });
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    if (this.state.redirectTocheckIn) {
      this.setState({ redirectTocheckIn: false });
      return (
        <Redirect
          to={{
            pathname: "/check-in",
          }}
        />
      );
    }
    return (
      <ListGroup className="drop-down-header-links sm">
        <ListGroup.Item className="p-0">
          <Nav.Link href="" className="px-4 header-link"
           onClick={() => {
            this.setState({ redirectTocheckIn: true });
            this.props.toggleShowDropdownLinks();
          }}>
            {Translation.t("check-in")}
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="p-0">
          <Nav.Link href="" className="px-4 header-link"
          onClick={() => {
            this.props.changeWidgetType("manageTrip");
            this.setState({ redirectToHome: true });
            this.props.toggleShowDropdownLinks();
          }}>
            {Translation.t("manage_my_trips")}
          </Nav.Link>
        </ListGroup.Item>
        <ListGroup.Item className="p-0">
          {" "}
          <Nav.Link href="#pricing" className="px-4 header-link">
            {Translation.t("help")}
          </Nav.Link>
        </ListGroup.Item>
      </ListGroup>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    isUserSignedIn: state.global.isUserSignedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(HeaderDropdownLinkSM);
