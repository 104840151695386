import React, { Component } from "react";
class SearchFormRemark extends Component {
  render() {
    return (
      <div className="mb-2 search-form-remark">
        <div className="title-content text-justify" role="note">
          <b >
            <span>Please Note:</span>
          </b>
          <span>
            All fields are required unless marked as optional. Use latin
            characters only.
          </span>
        </div>
      </div>
    );
  }
}
export default SearchFormRemark;
