import React from "react";
import AirportSelection from "../airport-selection/AirportSelection";
import { connect } from "react-redux";
import Translation from "../../../../service/shared/multi-lingual/Translation";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import { Button, Col, Row } from "react-bootstrap";
import DatePicker from "../booking-calendar/DatePicker";

import "./multicity.css";
class MultiCity extends React.Component {
  render() {
    return (
      <div className="">
        {this.props.itineraryPart.map((itinerary, index) => (
          <div key={index} className="mb-3">
            <Row>
              <div className="col-12">
                <label className="flight_index">
                  <Translation Key="flight" /> {index + 1}
                </label>
                {index > 1 ? (
                  index === this.props.itineraryPart.length - 1 ? (
                    <Button
                      onClick={this.props.RemoveFlight}
                      className="remove_flight_btn btn-danger btn-sm"
                      style={{
                        position: "relative",
                        top: "61%",
                        zIndex: 2,
                      }}
                      id="remove-flight-btn"
                    >
                      X
                    </Button>
                  ) : null
                ) : null}
              </div>
            </Row>
            {this.props.displayLabel && (
              <Row>
                <div className="col-12">
                  <h5>Airports</h5>
                </div>
              </Row>
            )}

            <Row>
              <AirportSelection flightIndex={index} />
            </Row>
            {this.props.displayLabel && (
              <Row>
                <Col>
                  <h5 className="mt-3">Travel Date</h5>
                </Col>
              </Row>
            )}
            <Row>
              <DatePicker
                flightIndex={index}
                name={"departure_date_" + index}
                minDate={
                  index - 1 >= 0
                    ? this.props.itineraryPart[index - 1].params.when
                        .departureDate
                    : null
                }
              />
            </Row>
          </div>
        ))}
        <Row>
          <div className="col-12">
            <span className="add_flight_btn my-2">
              <Button
                className="btn-success std-et-bg-color text-white btn mr-2 btn-sm"
                onClick={this.props.AddFlight}
                disabled={this.props.itineraryPart.length === 6}
                id="add-flight-btn"
              >
                +
              </Button>
              <label>
                {" "}
                <Translation Key="add_flight" />
              </label>
            </span>
          </div>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    itineraryPart: state.bookingWiget.itineraryPart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    AddFlight: () =>
      dispatch({
        type: BookingWidgetAction.ADDFLIGHT,
      }),
    RemoveFlight: () =>
      dispatch({
        type: BookingWidgetAction.REMOVEFLIGHT,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MultiCity);
