import airportListEn from "../../master-data/locale/en/airport-list-en.json";
export class AirportFullName {
  static getFullName(airportCode) {
    const locale = localStorage.getItem("i18nextLng");
    if (locale === "en") {
      for (let i of airportListEn) {
        if (i.value === airportCode) {
          return i.label;
        }
      }
    }
  }
  static getCityName(airportCode) {
    const locale = localStorage.getItem("i18nextLng");
    if (locale === "en") {
      for (let i of airportListEn) {
        if (i.value === airportCode) {
          return i.label.substring(0, i.label.indexOf(","));
        }
      }
    }
  }
  static getCabinClass(cabinClassCode) {
    if (cabinClassCode === 3) {
    } else if (cabinClassCode === 3) {
    } else {
    }
  }
  static getCountryName(cityCode) {
    for (let i of airportListEn) {
      if (i.value === cityCode) {
        return i.label.substring(i.label.indexOf(",") + 1).trim();
      }
    }
  }
}
