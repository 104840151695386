import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import ManageBookingSummaryHeader from "./ManageBookingSummaryHeader";
import ManageBookingSummaryTripDetail from "./ManageBookingSummaryTripDetail";
import ManageBookingSummaryPassenger from "./ManageBookingSummaryPassenger";
import { connect } from "react-redux";

import "./ManageBooking.css";
import ManageBookingSummaryAction from "./ManageBookingSummaryAction";
import Loading from "../../booking/seat-selection/Loading";
import { ManageTripIntialRequest } from "../../../model/manage-trip/ManageTripIntialRequest";
import HttpService from "../../../service/shared/HttpService";
import { Redirect } from "react-router";
import { manageMyBookingAction } from "../../../redux/actions/manage-my-booking/manageMyBookingAction";
import GlobalTripShoppingCart from "../shared/GlobalTripShoppingCart";
class ManageBookingSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerList: [],
      tripDetail: [],
      pnr: null,
      contactInfo: null,
      passengerTicketInfo: [],
      isLoading: true,
      redirectToExchange: false,
      // redirectToPassenger:false,
      // redirectToExtras:false,
      redirectToPassenger: false,
      redirectToExtras: false,
      redirectToSeat: false,
      exchangeRequest: {},
      payments: {},
      priceBreakdown: {},
    };
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let manangeTripIntailRequest = new ManageTripIntialRequest();
    manangeTripIntailRequest.requestObject.PNR = this.props.pnr;
    manangeTripIntailRequest.requestObject.lastName = this.props.lastName;
    let relativePath = "/etibe-managetrip/V1.0/passenger/PNR";
    let token = this.props.searchFlight.token;
    this.setState({
      exchangeRequest: manangeTripIntailRequest.requestObject,
    });
    HttpService.postServiceManageTrip(
      manangeTripIntailRequest.requestObject,
      relativePath,
      token
    ).then(
      (response) => {
        this.setState({
          isLoading: false,
          pnr: response.data.pnr.reloc,
          tripDetail: response.data.pnr.itinerary.itineraryParts,
          passengerList: response.data.pnr.passengers,
          contactInfo: response.data.pnr.contact,
          passengerTicketInfo:
            response.data.pnr.travelPartsAdditionalDetails[0].passengers,
          payments: response.data.pnr.payments,
          priceBreakdown: response.data.pnr.priceBreakdown,
        });

        this.props.saveData(response.data);
      },
      (error) => {
        this.setState({
          isLoading: false,
        });
      }
    );
  }
  getCityName(airport) {
    // let cityName = airport.substring(0,airport.indexOf("("));
    return AirportFullName.getCityName(airport);
  }
  redirect = (to) => {
    switch (to) {
      case "exchange": {
        this.setState({ redirectToExchange: true });
        break;
      }
      case "extras": {
        this.setState({ redirectToExtras: true });
        break;
      }
      case "passenger": {
        this.setState({ redirectToPassenger: true });
        break;
      }
      case "extras": {
        this.props.history.push("/myb/edit-ancillaries");
        break;
      }
      case "passenger": {
        this.props.history.push("/myb/edit-passenger-info");
        break;
      }
      case "seat": {
        this.setState({ redirectToSeat: true });
        break;
      }
      default: {
      }
    }
  };
  render() {
    if (this.state.redirectToExchange) {
      return (
        <Redirect
          to={{
            pathname: "/exchange",
            state: { request: this.state.exchangeRequest },
          }}
        />
      );
    }
    if (this.state.redirectToExtras) {
      return (
        <Redirect
          to={{
            pathname: "/myb/edit-ancillaries",
          }}
        />
      );
    }
    if (this.state.redirectToPassenger) {
      return (
        <Redirect
          to={{
            pathname: "/myb/edit-passenger-info",
          }}
        />
      );
    }
    // if (this.state.redirectToExtras) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/myb/edit-ancillaries",
    //       }}
    //     />
    //   );
    // }
    // if (this.state.redirectToPassenger) {
    //   return (
    //     <Redirect
    //       to={{
    //         pathname: "/myb/edit-passenger-info",
    //       }}
    //     />
    //   );
    // }

    if (this.state.redirectToSeat) {
      return (
        <Redirect
          to={{
            pathname: "/myb/seat-selection",
          }}
        />
      );
    }
    
    return (
      <section className="container-fluid manage-booking-summary-container">
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <div>
            <ManageBookingSummaryHeader
              pnr={this.state.pnr}
              type={
                this.props.location.type === null
                  ? null
                  : this.props.location.type
              }
            />
            <section className="payment-summary-confirmation-header">
              <Row>
                <Col xs={12} sm={12} md={4} lg={2}>
                  <div>
                    <div> {Translation.t("confirmation_code")}</div>
                    <h3>
                      <mark>{this.state.pnr}</mark>
                    </h3>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                  <div className="manage-booking-summary-itinerary pt-3">
                    <span onClick={() => this.redirect("extras")}>EXTRAS</span>
                    {/* <span className="px-3"></span> */}
                    <span
                      className="px-2"
                      onClick={() => this.redirect("seat")}
                    >
                      ADD OR MODIFY SEATS
                    </span>
                    <span className="px-2">CANCEL FLIGHT</span>
                    <span
                      className="px-2"
                      onClick={() => this.redirect("passenger")}
                    >
                      PASSENGER INFO
                    </span>
                    <span
                      className="px-2"
                      onClick={() => this.redirect("exchange")}
                    >
                      EXCHANGE
                    </span>
                    <span className="px-2">UPGRADE</span>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={12}>
                  <ManageBookingSummaryAction
                    itineraryParts={this.state.tripDetail}
                    state={this.state}
                  />
                </Col>
              </Row>
            </section>
            <ManageBookingSummaryTripDetail
              tripDetail={this.state.tripDetail}
            />
            <ManageBookingSummaryPassenger
              passengerList={this.state.passengerList}
              contactInfo={this.state.contactInfo}
              passengerTicketInfo={this.state.passengerTicketInfo}
              tripDetail={this.state.tripDetail}
            />
            <GlobalTripShoppingCart />
          </div>
        )}
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    searchFlight: state.searchFlight,
    lastName: state.manageMyBooking.formInput.lastName,
    pnr: state.manageMyBooking.formInput.pnr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveData: (data) =>
      dispatch({
        type: manageMyBookingAction.SAVEDATA,
        data: data,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageBookingSummary);
