import React, { Component } from "react";
import "./progress-loader.css";

class ProgressLoader extends Component {
  render() {
    let displayMess = "Loading";
    if (this.props.displayMess != null) {
      displayMess = this.props.displayMess;
    }
    return (
      <div className="Loading-div">
        <div className="divcontainer">
          <br />
          <br />
          <div className="loader blue"></div>
          <div className="loader yellow"></div>
          <div className="loader red"></div>
          <br />
        </div>
        <p className="displayMessage">
          {displayMess}
        </p>
      </div>
    );
  }
}

export default ProgressLoader;
