import React from "react";
import "./BreadCrumb.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faChair,
  faCheckCircle,
  faExchangeAlt,
  faHome,
  faLongArrowAltRight,
  faMoneyCheck,
  faPen,
  faPlane,
  faShoppingCart,
  faSuitcase,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import EditFlight from "../../booking/booking-widget/edit-flight/EditFlight";
import { connect } from "react-redux";
import Translation from "i18next";
import { Redirect } from "react-router";
import OutsideClickHandler from "react-outside-click-handler";
import { Button } from "react-bootstrap";
import ShoppingCart from "../shoppingCart/ShoppingCart";

class BreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFlight: false,
      currentBreadCrumb: 2,
      redirect: false,
      redirectTo: null,
      showDropDown: false,
      showShoppingCart: false,
      showShoppingCartButton: false,
    };
  }

  componentDidMount = () => {
    console.log(this.props.history);
    var url = window.location.pathname;
    if (url.includes("flight")) {
      if (url.includes("flightsummary")) {
        this.setState({ showShoppingCartButton: true, currentBreadCrumb: 2 });
      } else {
        this.setState({ currentBreadCrumb: 2 });
      }
    } else if (url.includes("passenger")) {
      this.setState({ currentBreadCrumb: 3, showShoppingCartButton: true });
    } else if (url.includes("seatSelection")) {
      this.setState({ currentBreadCrumb: 4, showShoppingCartButton: true });
    } else if (url.includes("ancillary")) {
      this.setState({ currentBreadCrumb: 5, showShoppingCartButton: true });
    }
  };
  toggleEditFlight = () => {
    this.setState({ openEditFlight: !this.state.openEditFlight });
  };
  toggleShoppingCart = () => {
    this.setState({ showShoppingCart: !this.state.showShoppingCart });
  };
  flightSearch = () => {
    var origin = null;
    var destination = null;
    try {
      this.props.bookingWiget.itineraryPart.forEach((itineraryPart, index) => {
        if (index === 0 && itineraryPart !== null) {
          origin = itineraryPart.params.from.value;
          destination = itineraryPart.params.to.value;
        } else {
          if (itineraryPart.params.to !== null) {
            destination = itineraryPart.params.to.value;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    if (this.props.journyType === "oneWay") {
      return (
        <span>
          {origin} <FontAwesomeIcon icon={faLongArrowAltRight} size="1x" />{" "}
          {destination}
        </span>
      );
    } else if (this.props.journyType === "roundTrip") {
      return (
        <span>
          {origin} <FontAwesomeIcon icon={faExchangeAlt} size="1x" />{" "}
          {destination}
        </span>
      );
    } else {
      return (
        <span>
          {origin} {"  "}
          {destination}
        </span>
      );
    }
  };
  getNumberOfPassenge = () => {
    var passengerNo =
      this.props.passenger.adult +
      this.props.passenger.child +
      this.props.passenger.infant;
    if (passengerNo > 1) {
      return passengerNo + " " + Translation.t("passengers-label");
    } else {
      return passengerNo + " " + Translation.t("passenger-label");
    }
  };
  changePage = (goTo) => {
    if (goTo === "flight" && this.state.currentBreadCrumb > 2) {
      this.setState({ redirect: true, redirectTo: "/flightsummary" });
    } else if (goTo === "passenger" && this.state.currentBreadCrumb > 3) {
      this.setState({ redirect: true, redirectTo: "/passenger" });
    } else if (goTo === "seat" && this.state.currentBreadCrumb > 4) {
      this.setState({ redirect: true, redirectTo: "/seatSelection" });
    } else if (goTo === "extra" && this.state.currentBreadCrumb > 5) {
      this.setState({ redirect: true, redirectTo: "/ancillary" });
    }
  };
  componentDidMount() {}
  renderActivePage(page) {
    switch (page) {
      case 2:
        return (
          <div className="bread-crumb-mobile-item">
            <span>
              <FontAwesomeIcon icon={faPlane} size="1x" />
            </span>
            {"  "} {this.flightSearch()}
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            <div className="select-flight">
              {Translation.t("select_flight")}{" "}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faUser} size="1x" />
            {"  "}
            Passenger
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            <div id="passenger-text"> {this.getNumberOfPassenge()}</div>
          </div>
        );
      case 4:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faChair} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Seat Selection
          </div>
        );
      case 5:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faSuitcase} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Extras
          </div>
        );
      case 6:
        return (
          <div className="bread-crumb-mobile-item">
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6
            </span>
            <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Payment
          </div>
        );
    }
  }
  renderBreadcrumbMediumScreen() {
    return (
      <>
        <div className="parent-div">
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ showDropDown: false });
            }}
          >
            <div
              className="bread-crumb-mobile-dropdown"
              onClick={() => {
                this.setState({ showDropDown: !this.state.showDropDown });
              }}
            >
              {this.renderActivePage(this.state.currentBreadCrumb)}

              <div
                className="bread-crumb-mobile-dropdown-list"
                style={
                  this.state.showDropDown
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="bread-crumb-mobile-item-md">
                  <p className="breadcrumb-custom-disabled btn-not-allowd">
                    {" "}
                    <FontAwesomeIcon icon={faHome} size="1x" /> {"  "}Home
                  </p>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 2
                        ? "breadcrumb-custom-current"
                        : "breadcrumb-custom-disabled previsous"
                    }
                    onMouseOver={() => {
                      var textSelect = document.getElementById("select-flight");
                      textSelect.innerHTML = Translation.t("review_flight");
                    }}
                    onClick={this.changePage.bind(this, "flight")}
                    onMouseOut={() => {
                      var textSelect = document.getElementById("select-flight");
                      textSelect.innerHTML = Translation.t("select_flight");
                    }}
                  >
                    <span>
                      {this.state.currentBreadCrumb !== "flight" ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faPlane} size="1x" />
                      )}
                    </span>
                    {"  "} {this.flightSearch()}
                    <p id="select-flight" className="m-0">
                      {Translation.t("select_flight")}{" "}
                    </p>
                  </p>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 3
                        ? "breadcrumb-custom-current"
                        : this.state.currentBreadCrumb > 3
                        ? "breadcrumb-custom-disabled previsous"
                        : "breadcrumb-custom-disabled btn-not-allowd"
                    }
                    onClick={this.changePage.bind(this, "passenger")}
                  >
                    <span>
                      {this.state.currentBreadCrumb > 3 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faUser} size="1x" />
                      )}
                    </span>{" "}
                    {"  "}
                    Passenger
                    <div id="passenger-text m-0">
                      {this.state.currentBreadCrumb === 3
                        ? Translation.t("info_required")
                        : this.getNumberOfPassenge()}
                    </div>
                  </p>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 4
                        ? "breadcrumb-custom-current"
                        : this.state.currentBreadCrumb > 4
                        ? "breadcrumb-custom-disabled previsous"
                        : "breadcrumb-custom-disabled btn-not-allowd"
                    }
                    onClick={this.changePage.bind(this, "seat")}
                  >
                    <span>
                      {this.state.currentBreadCrumb > 4 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faChair} size="1x" />
                      )}
                    </span>
                    Seat Selection
                  </p>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 5
                        ? "breadcrumb-custom-current"
                        : this.state.currentBreadCrumb > 5
                        ? "breadcrumb-custom-disabled previsous"
                        : "breadcrumb-custom-disabled btn-not-allowd"
                    }
                    onClick={this.changePage.bind(this, "extra")}
                  >
                    <span>
                      {this.state.currentBreadCrumb > 5 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faSuitcase} size="1x" />
                      )}
                    </span>{" "}
                    {"  "}
                    Extras
                  </p>
                </div>
                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 6
                        ? "breadcrumb-custom-current"
                        : this.state.currentBreadCrumb > 6
                        ? "breadcrumb-custom-disabled previsous"
                        : "breadcrumb-custom-disabled btn-not-allowd"
                    }
                  >
                    <span>
                      {this.state.currentBreadCrumb > 6 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
                      )}
                    </span>
                    {"  "}
                    Payment
                  </p>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
          <span className="float-right  edit-flight-button mt-4">
            {/* <button
              className="btn btn-md btn-success mx-1"
              style={{ marginRight: "3.3em" }}
              onClick={() => this.toggleEditFlight()}
            >
              <FontAwesomeIcon icon={faPen} />{" "}
            </button> */}
            <button
              onClick={() => this.toggleShoppingCart()}
              className="btn btn-md btn-success mx-1"
            >
              <FontAwesomeIcon icon={faShoppingCart} />{" "}
            </button>
          </span>
        </div>{" "}
      </>
    );
  }
  renderBreadcrumbLargeScreen() {
    return (
      <>
        <div className="breadcrumb-Card">
          <ul className="breadcrumb-custom">
            <li>
              <a href="/" title="" className="breadcrumb-custom-home">
                {" "}
                <FontAwesomeIcon icon={faHome} size="1x" />
                {"  "}
                <p>Home</p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 2
                    ? "breadcrumb-custom-current"
                    : "breadcrumb-custom-disabled previsous"
                }
                onMouseOver={() => {
                  var textSelect = document.getElementById("select-flight-lg");
                  textSelect.innerHTML = Translation.t("review_flight");
                }}
                onClick={this.changePage.bind(this, "flight")}
                onMouseOut={() => {
                  var textSelect = document.getElementById("select-flight-lg");
                  textSelect.innerHTML = Translation.t("select_flight");
                }}
              >
                <span>
                  {this.state.currentBreadCrumb > 2 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faPlane} size="1x" />
                  )}
                </span>
                {"  "} {this.flightSearch()}
                <p id="select-flight-lg">{Translation.t("select_flight")} </p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 3
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 3
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onMouseOver={() => {
                  if (this.state.currentBreadCrumb >= 3) {
                    var textSelect =
                      document.getElementById("passenger-text-lg");
                    textSelect.innerHTML = Translation.t("edit_info");
                  }
                }}
                onClick={this.changePage.bind(this, "passenger")}
                onMouseOut={() => {
                  var textSelect = document.getElementById("passenger-text-lg");
                  if (this.state.currentBreadCrumb === 3) {
                    textSelect.innerHTML = Translation.t("info_required");
                  }
                  if (this.state.currentBreadCrumb > 3) {
                    var passengerText = this.getNumberOfPassenge();
                    textSelect.innerHTML = passengerText;
                  }
                }}
              >
                <span>
                  {this.state.currentBreadCrumb > 3 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faUser} size="1x" />
                  )}
                </span>
                {"  "}
                Passenger
                <p id="passenger-text-lg">
                  {this.state.currentBreadCrumb === 3
                    ? Translation.t("info_required")
                    : this.getNumberOfPassenge()}
                </p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 4
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 4
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onMouseOver={() => {
                  if (this.state.currentBreadCrumb >= 4) {
                    var textSelect = document.getElementById("seat-text-lg");
                    textSelect.innerHTML = Translation.t("edit_seat");
                  }
                }}
                onClick={this.changePage.bind(this, "seat")}
                onMouseOut={() => {
                  if (this.state.currentBreadCrumb >= 4) {
                    var textSelect = document.getElementById("seat-text-lg");
                    textSelect.innerHTML = "";
                  }
                }}
              >
                <span>
                  {this.state.currentBreadCrumb > 4 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faChair} size="1x" />
                  )}
                </span>
                {"  "}
                Seat Selection
                <p id="seat-text-lg"></p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 5
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 5
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
                onMouseOver={() => {
                  if (this.state.currentBreadCrumb === 5) {
                    var textSelect = document.getElementById("extras-text-lg");
                    textSelect.innerHTML = Translation.t("edit_extras");
                  }
                }}
                onClick={this.changePage.bind(this, "extra")}
                onMouseOut={() => {
                  if (this.state.currentBreadCrumb === 5) {
                    var textSelect = document.getElementById("extras-text-lg");
                    textSelect.innerHTML = "";
                  }
                }}
              >
                <span>
                  {this.state.currentBreadCrumb > 5 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faSuitcase} size="1x" />
                  )}
                </span>
                {"  "}
                Extras
                <p id="extras-text-lg"></p>
              </a>
            </li>
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 6
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 6
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
              >
                <span>
                  {this.state.currentBreadCrumb > 6 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faSuitcase} size="1x" />
                  )}
                </span>{" "}
                {"  "}
                Payment
              </a>
            </li>
          </ul>
          {/* <button
            className="btn btn-md btn-success float-right  edit-flight-button mr-3"
            onClick={this.toggleEditFlight}
          >
            <FontAwesomeIcon icon={faPen} />
          </button> */}
        </div>
        {/* <EditFlight
          openEditFlight={this.state.openEditFlight}
          closeEditFlight={this.toggleEditFlight}
          history={this.props.history}
        /> */}
        {this.props.isMedium && this.state.showShoppingCartButton ? (
          <ShoppingCart showShoppingCart={this.state.showShoppingCart} />
        ) : null}
      </>
    );
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      );
    }
    return (
      <>
        {this.renderBreadcrumbMediumScreen()}
        {this.renderBreadcrumbLargeScreen()}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookingWiget: state.bookingWiget,
    journyType: state.searchFlight.searchRequest.journyType,
    passenger: state.bookingWiget.passengerInfo,
    isMobile: state.global.isMobile,
    isMedium: state.global.isMedium,
  };
};
export default connect(mapStateToProps, null)(BreadCrumb);
