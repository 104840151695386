import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "../FlightSelection.css";
import Translation from "i18next";

import FilterFlights from "../filter flights/FilterFlights";

class ItineraryPartOfferHeader extends Component {
  popped = false;

  componentDidMount() {
    document.getElementById("sticky-header-container").style.setProperty("width", "100%", "important"); //= "100% !important";
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if(window.innerWidth > 767){
      try{
        var breadCrumb = "";
        if(window.innerWidth > 1037){
          breadCrumb = document.getElementsByClassName("breadcrumb-Card")[0].offsetHeight;
        }
        else{
          breadCrumb = document.getElementsByClassName("bread-crumb-mobile-dropdown")[0].offsetHeight;
        }
      }
      catch{
      }
      var topStickyHeader = document.getElementById("sticky-header-container").getBoundingClientRect().top;
      var topStickyHeaderHeight = document.getElementById("sticky-header-outer-container").getBoundingClientRect().height;

      var display_on_flight_ribbon_out_of_view_port = document.getElementById("display_on_flight_ribbon_out_of_view_port");

      var editFlightBox = document.getElementsByClassName("edit-flight-box");
      if(editFlightBox.length !== 0){
        breadCrumb = editFlightBox[0].getBoundingClientRect().height + 65
      }
      if (topStickyHeader < breadCrumb && !this.popped) {
        this.popped = true;
        try {
          document.getElementById("sticky-header-container").style.position =
            "fixed";
          document.getElementById("sticky-header-container").style.top = breadCrumb + "px";
          document.getElementById("sticky-header-container").style.width = "96%";
          document.getElementById("sticky-header-container").style.setProperty("box-shadow", "rgb(80 80 80 / 43%) 0px 3px 9px 0px");
        document.getElementById("sticky-header-outer-container").style.height = topStickyHeaderHeight + "px";
        // display_on_flight_ribbon_out_of_view_port.style.display = "inline-block";
        display_on_flight_ribbon_out_of_view_port.className = "display_it pr-2";
        } catch {}
      }
      
      try {
        var nextSiblingTop = document.getElementById("sticky-header-outer-container").nextSibling.getBoundingClientRect().top;
        if (
          this.popped &&
          nextSiblingTop > (breadCrumb + topStickyHeaderHeight)
        ) {
          document.getElementById("sticky-header-container").style.position =
            "relative";
          document.getElementById("sticky-header-container").style.top = "0";
          document.getElementById("sticky-header-container").style.setProperty("width", "100%", "important");
          this.popped = false;
          document.getElementById("sticky-header-container").style.setProperty("box-shadow", "none");
        document.getElementById("sticky-header-outer-container").style.height = "auto";
        // display_on_flight_ribbon_out_of_view_port.style.display = "none";
        display_on_flight_ribbon_out_of_view_port.className = display_on_flight_ribbon_out_of_view_port.className.replace(/\bdisplay_it\b/g, "");
  
        }
      } catch {}
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <div id="sticky-header-outer-container">
  <div className="itinerary-part-offer-header flight-selection-container search-filter-wrapper" id="sticky-header-container">
        <Row className="mx-0">
          <FilterFlights flightIndex={this.props.flightIndex} itineraryPartBrandsLength={this.props.itineraryPartBrandsLength}/>
        </Row>
        <Row className="itinerary-time-duration-row mx-0">
          <Col lg={7} md={7}>
            <Row>
              <Col lg={7} md={7}>
                <div>
                  <span className="flight-time-label">{Translation.t("time")}</span>
                </div>
              </Col>
              <Col lg={5} md={5}>
                <div>
                  <span className="flight-duration-label">{Translation.t("duration_header")}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={5}></Col>
        </Row>
      </div>
    
      </div>
    );
  }
}
export default ItineraryPartOfferHeader;
