import { flightScheduleAction } from "../../actions/manage-my-booking/flightScheduleAction";

const initialState = {
    backedUpFlights: [],
    FilterCriteria : [],
    countSortUpdate : 0,
    flightSchedules : [],
    isFlightSchedulePresent : true
};
const flightScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case flightScheduleAction.SCHEDULEFILTERUPDATE:
        return state;
      case flightScheduleAction.SCHEDULEUPDATESORTOPTIONS:
        return {...state,
          FilterCriteria : action.filterCriteria,
        };
      case flightScheduleAction.SCHEDULEUPDATECOUNTSORTUPDATE:
        return {
          ...state,
          countSortUpdate : state.countSortUpdate + 1  
        }
      case flightScheduleAction.UPDATEFLIGHTSCHEDULES:
        // flightSchedules
        return {
          ...state,
          flightSchedules : action.payload  
        }
      case flightScheduleAction.SCHEDULEINITFLIGHTS:
        return {
          ...state,
          backedUpFlights : action.payload,
          flightSchedules : action.payload
        }
        case flightScheduleAction.ISFLIGHTSCHEDULEPRESENT:
          return {
            ...state,
            isFlightSchedulePresent : action.payload
          }
    default:
      return state;
  }
};
export default flightScheduleReducer;
