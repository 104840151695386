import React from "react";
import "./SingleRangeSlider.css";
import Translation from "i18next";

class SingleRangeSlider extends React.Component{
    state = {
        sliderWidth: 0,
        offsetSliderWidht: 0,
        min: 0,
        max: 200,
        currentMin: 60,
        inputMin: 60,
    
        currentMax: 100,
      };
    
      componentDidMount() {
        var maxFromProps = this.props.max;
        var minFromProps = this.props.min;
        var currentValue = this.props.value;
        // console.log(currentValue);
        if(minFromProps === maxFromProps){
          this.minValue.style.width = "100%";
        }
        else{
          this.minValue.style.width = (100*(currentValue - minFromProps))/(maxFromProps - minFromProps) + "%";
        }
        this.setState({
          sliderWidth: this.slider.offsetWidth,
          offsetSliderWidht: this.slider.getBoundingClientRect().x,
          min: minFromProps,
          currentMin: currentValue,
          inputMin: currentValue,
    
          currentMax: maxFromProps,
          max: maxFromProps,
        });
        this.minValueDrag.addEventListener("touchstart", this.changeMinValue, {
          passive: false
        });
      }
    
      setMin = (e) => {
        const { min, max, currentMax } = this.state;
        const inputMin = parseInt(e.target.value);
        
        var tempCurent = parseInt(inputMin);

        if (inputMin >= min && inputMin <= currentMax) {
          this.setState({
            inputMin,
            currentMin: parseInt(inputMin),
          });
    
          this.minValue.style.width = (inputMin * 100) / max + "%";
        } else if (inputMin < min) {
            tempCurent = parseInt(min);
          this.setState({
            inputMin: min,
            currentMin: parseInt(min),
          });
          this.minValue.style.width = 100 + "%";
        } else {
            tempCurent = parseInt(currentMax);
          this.setState({
            inputMin: parseInt(currentMax ),
            currentMin: parseInt(currentMax ),
          });
          this.minValue.style.width = (100*(currentMax - min))/(max - min) + "%";
        }
        this.props.onChange(tempCurent);
      };
      
      changeMinValue = (e) => {
        e.preventDefault();
    
        document.addEventListener("mousemove", this.onMouseMoveMin);
        document.addEventListener("mouseup", this.onMouseUpMin);
    
        document.addEventListener("touchmove", this.onTouchMoveMin);
        document.addEventListener("touchend", this.onMouseUpMin);
      };
    
      onTouchMoveMin = (e) => {
        if (e.touches.length !== 0) {
          const {
            min,
            max,
            currentMax,
            sliderWidth,
            offsetSliderWidht,
          } = this.state;
          var touch = e.touches[0];
          var ratio = (max - min) / max;
          const dragedWidht = touch.clientX - offsetSliderWidht;
          const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
          const currentMin = min + parseInt(Math.abs(parseInt((max * dragedWidhtInPercent) / 100) * ratio));
          if (
            dragedWidht >= 0 &&
            currentMin >= min &&
            currentMin <= currentMax
          ) {
            this.minValue.style.width = dragedWidhtInPercent + "%";
            this.minValue.dataset.content = currentMin;
            this.setState({
              currentMin,
              inputMin: currentMin,
            });
            this.props.onChange(this.minValue.dataset.content);
          }
        }
      };
    
      onMouseMoveMin = (e) => {
        const {
          min,
          max,
          currentMax,
          sliderWidth,
          offsetSliderWidht,
        } = this.state;
        const dragedWidht = e.clientX - offsetSliderWidht;
        var ratio = (max - min) / max;
        const dragedWidhtInPercent = (dragedWidht * 100) / sliderWidth;
        const currentMin = min + Math.ceil(Math.abs((max * dragedWidhtInPercent) / 100) * ratio);
        if ( dragedWidht >= 0 && currentMin >= min && currentMin <= currentMax ) {
          this.minValue.style.width = dragedWidhtInPercent + "%";
          this.minValue.dataset.content = currentMin;
          this.setState({
            currentMin,
            inputMin: currentMin,
          });
          this.props.onChange(this.minValue.dataset.content);
        }
      };
    
      onMouseUpMin = () => {
        document.removeEventListener("mouseup", this.onMouseUpMin);
        document.removeEventListener("mousemove", this.onMouseMoveMin);
    
        document.removeEventListener("touchend", this.onMouseMoveMin);
        document.removeEventListener("touchmove", this.onTouchMoveMin);
      };   

    render() {
        const { currentMin, min, max } = this.state;
        return (
            <>
            <div className={this.props.className}>
            <div ref={(ref) => (this.slider = ref)} id="slider">
              <div
                ref={(ref) => (this.minValue = ref)}
                id="min-slider" disabled={this.props.disabled}
                data-content={currentMin}
              >
                <div
                  ref={(ref) => (this.minValueDrag = ref)}
                  id="min-drag"
                  onMouseDown={this.props.disabled ? null : this.changeMinValue}
                ></div>
              </div>
            </div>
          </div>
          <form className="pb-3">
              <div className="form-group row px-3">
                <label
                  className="form-label d-flex filter-input-inditor filter-inut-indictor-text"
                  htmlFor={"Number-"+this.props.indicator_name}
                >
                  {Translation.t("under")}
                </label>
                <div className="col px-1">
                  <input
                    type="number"
                    id={"Number-"+this.props.indicator_name}
                    className="form-control d-flex py-0 filter-input-inditor px-1"
                    value={currentMin}
                    disabled={ this.props.disabled}
                    min={min}
                    max={max}
                    onChange={this.setMin}
                  />
                </div>
                <span className="filter-input-inditor filter-inut-indictor-text d-flex">
                  {this.props.indicator_name}
                </span>
              </div>
            </form>
            </>
        );
      }

}



export default SingleRangeSlider;