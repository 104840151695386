import {
  Amount,
  BaggageModel,
  Bags,
  CheckInAncillaryOperation,
  CheckInBaggageRequest,
} from "../../model/API/AddBaggage";

export default class CheckInAncillaryService {
  initializeBaggageModel = (ApiResponse, acivePassengers) => {
    const activePassengersId = Object.getOwnPropertyNames(acivePassengers);
    var tempPassengerList = [];
    activePassengersId.forEach((activePassengerId) => {
      var tempPassenger = new BaggageModel();
      var tempAmout = new Amount();
      tempPassenger.amount.push(tempAmout);
      tempPassenger.passengerId = activePassengerId;
      var ListOfBaggages = ApiResponse.ancillaries.filter(
        (a) => a.group === "BG"
      );
      ListOfBaggages.forEach((baggageDetail) => {
        var ancillarOffer = ApiResponse.ancillaryOffers.filter(
          (o) => o.ancillaryRef === baggageDetail.id
        )[0];
        var allowedPassenger =
          ancillarOffer.passengerTravelPortionToAncillaryMap[0].passengerAncillaryPricing.filter(
            (p) => p.passengerRef === activePassengerId
          );
        if (allowedPassenger.length !== 0) {
          var pricedAncillary = ApiResponse.pricedAncillaries.filter(
            (p) => p.ancillaryRef === baggageDetail.id
          )[0];
          var tempBag = new Bags();
          tempBag.code = baggageDetail.id;
          tempBag.name = baggageDetail.name;
          try {
            tempBag.price = parseFloat(
              pricedAncillary.ancillaryFee.totalFee[0].total.equivAmount.value
            );
            tempBag.currency =
              pricedAncillary.ancillaryFee.totalFee[0].total.equivAmount.currency;
          } catch {
            tempBag.price = parseFloat(
              pricedAncillary.ancillaryFee.totalFee[0].total.amount.value
            );
            tempBag.currency =
              pricedAncillary.ancillaryFee.totalFee[0].total.amount.currency;
          }

          tempBag.maxQuantityPerPax = parseInt(
            baggageDetail.ancillaryRules.customRules.maxQuantityPerPassenger
          );
          tempPassenger.bags.push(tempBag);
        }
      });
      tempPassengerList.push(tempPassenger);
    });

    return {
      passengerList: tempPassengerList,
      overAllTotal: 0,
      isBaggageAddedBefore: false,
      overAllTotal: 0,
    };
  };

  addBag = (state, ancillaryCode, passengerKey, price) => {
    
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal + price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerId === passengerKey
    )[0];
    var bag = currentPassenger.bags.filter((b) => b.code === ancillaryCode)[0];
    bag.quantity++;

    currentPassenger.amount[0].totalQuantity++;
    currentPassenger.amount[0].totalPrice += price;
    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };
  minusBag = (state, ancillaryCode, passengerKey, price) => {
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal - price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerId === passengerKey
    )[0];
    var bag = currentPassenger.bags.filter((b) => b.code === ancillaryCode)[0];
    bag.quantity--;
    currentPassenger.amount[0].totalQuantity--;
    currentPassenger.amount[0].totalPrice -= price;
    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };

  buildBaggageRequest = (
    itineraryPart,
    baggageModels,
    cookieSabreDataRequest
  ) => {
    
    var segmentIds = [];
    itineraryPart.segment.map((segment) => {
      segmentIds.push(segment.id);
    });
    var checkeInBaggageRequest = new CheckInBaggageRequest();
    checkeInBaggageRequest.checkInSabreDataRequest = cookieSabreDataRequest;

    baggageModels.forEach((baggageModel) => {
      baggageModel.bags.forEach((bag) => {
        if (bag.quantity > bag.assignedQuantity) {
          var tempAncillaryOperationsCheckIn = new CheckInAncillaryOperation();
          tempAncillaryOperationsCheckIn.passengerId = baggageModel.passengerId;
          tempAncillaryOperationsCheckIn.ancillaryId = bag.code;
          tempAncillaryOperationsCheckIn.segmentIds = segmentIds;
          tempAncillaryOperationsCheckIn.quantity = bag.quantity;
          checkeInBaggageRequest.ancillaryOffers.push(
            tempAncillaryOperationsCheckIn
          );
        }
      });
    });
    return checkeInBaggageRequest;
  };
}
