import {
  faCalendarAlt,
  faCalendarPlus,
  faExternalLinkAlt,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { AirportFullName } from "../../../../service/shared/AirportFullName";

class PaymentSummaryAddCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showOutlook: false,
      showGoogle: false,
    };
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };
  handleShow = () => {
    this.setState({ showModal: true });
  };
  constructLink = (flightInfo, type) => {
    
    let from = flightInfo.origin;
    let to = flightInfo.destination;
    let startDate = flightInfo.departure.replace(/\-/g, "");
    startDate = startDate.replace(/\:/g, "");
    let endDate = flightInfo.arrival.replace(/\-/g, "");
    endDate = endDate.replace(/\:/g, "");
    let flightNumber = flightInfo.flight.flightNumber;
    let operatingAirline = flightInfo.flight.operatingAirlineCode;
    if (type === "outlook") {
      let link =
        "http://calendar.live.com/calendar/calendar.aspx?path=/calendar/view/Month&rru=addevent&subject=Flight+from+" +
        from +
        "+to+" +
        to +
        "&location=DLNVWT,+flight+" +
        flightNumber +
        "+operated+by+" +
        operatingAirline +
        "&startdt=" +
        startDate +
        "&enddt=" +
        endDate +
        "#xd_co_f=ZmFhOTk0YmQtMjdiYy00M2RlLTljYmUtMjQyMmE3MjJkOTgw~";
      return link;
    }

    if (type === "google") {
      let redirectLink =
        "https://www.google.com/calendar/render?action=TEMPLATE&text=Flight+from+" +
        from +
        "+to+" +
        to +
        "&dates=" +
        startDate +
        "/" +
        endDate +
        "&details=+flight+" +
        flightNumber +
        "+operated+by+" +
        operatingAirline +
        "&location=" +
        AirportFullName.getFullName(from) +
        "&sf=true";
      return redirectLink;
    }
  };
  showLink() {}
  render() {
    //
    return (
      <>
        <div onClick={this.handleShow} className="px-3 text-center">
          <img
            className="custom-img-sm"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAJ3SURBVEiJpdVdaI9xFAfwD0P291JTKxYlaeVCikhIqYmbTSmEklKoFW7cKHkrIxfa5GaFlKJImDKRkknK242mSMpLKc07QzYXv/Pw+P//tHHq1/M833N+53t+5/zOeSiVBtzAjjK6P8mu2FNfrBgUzwFYiHFYjIvxfN5HgkU4jUaMwVO0ozcj2ImveIBuvIuo+irfcD8WTMdMbMsM2nLGW7C6H85F9JOLsDYYWMa4Ga/6SbARw8spBpXBPuJ8PwmexvojQQFrc/gXnMES5U/ZFynkCXrwOqf8hu94I92wf5GePEE3TqIaU6Qb1Y1TmIjxEcDtsJ+GKjzBo/AzC0NwT6rhKkqP3yxdsfVSw8GRcNiMmljNgR0Om4bYMx0teYfFBJXYh3Mih1Ka9uJW6CvjfS/ehk0h9uwL/U8pvkXtuIBebArsMS7hE14ENiGwrLHuYj/WSKkukbZy4H9K2Ubbisu4jhmBnZCi7cDIWB2BHQ+byTgrRd+Yd1hMMFUaegdQG9gwzJfyXh3rVmBZ904JsuWo+xvBG+wOw67AhmOPNLze4X287wlyUvc3YLNUqxLJajBEKuDYnG5EYNU5rDqwEfFdgTkRfVX4uULpLZqNBVIXt+Cl9F+YFJE3hd3acN6JoxgV2NDQ10rToCRFG3AoHGe5XIlWvzp6dDhsxYqwaZRqsFS63l34UI6gJ444LN4zm6qIrleq053AKsJmpDQyanAM64oJsoHWhHnSLGoP7ECc5qo0ez5Heur8Phbyzh9mYOb4pvTb7NR/GSyltYBlOedtqM+K3CUVuKFke9/kGg7iWXwXRIqzE8yVftA9JVv/TQZiO679AIGzjt8OFxyJAAAAAElFTkSuQmCC"
          />
          <div>Add to Calendar</div>
        </div>

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add to Calendar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="calendar-container">
              <div
                onClick={() =>
                  this.setState({ showOutlook: true, showGoogle: false })
                }
              >
                <Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#2e7d32",
                    border: "1px solid #ccc",
                  }}
                  className="btn-block add-to-calendar-btn"
                >
                  <h5>
                    <span className="px-2">
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </span>
                    Outlook
                  </h5>
                </Button>
              </div>
              {/* <div>
              <h5>iCal</h5>
            </div> */}
              <br />
              <div
                onClick={() =>
                  this.setState({ showGoogle: true, showOutlook: false })
                }
              >
                <Button
                  style={{
                    backgroundColor: "#fff",
                    color: "#2e7d32",
                    border: "1px solid #ccc",
                  }}
                  className="btn-block add-to-calendar-btn"
                >
                  <h5>
                    {" "}
                    <span className="px-2">
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </span>
                    Google Calendar
                  </h5>
                </Button>
              </div>
              <br />
              <br />
            </div>
            <hr />
            <div className="calendar-links">
              {this.state.showOutlook && (
                <div className="calendar-outlook">
                  <h5>
                    <span className="px-2">
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </span>
                    Outlook
                  </h5>
                  <hr />
                  {this.props.itineraryParts.map((itinerary, key) => (
                    <div key={key}>
                      {key > 0 && <hr />}
                      {itinerary.segments.map((segment, index) => (
                        <div key={index} className="pb-2">
                          <h5 className="px-2 pb-1 text-center">
                            <span>{segment.origin}</span>
                            <span className="px-4">
                              <FontAwesomeIcon icon={faPlaneDeparture} />
                            </span>
                            <span>{segment.destination}</span>
                          </h5>
                          <a
                            target="_blank"
                            href={this.constructLink(segment, "outlook")}
                            className="add-to-calendar-link"
                          >
                            <span
                              style={{ color: "#999999", fontSize: "1.1rem" }}
                            >
                              Add to Outlook calendar ({segment.origin} -{" "}
                              {segment.destination})
                            </span>

                            <span className="px-2" style={{ color: "#2e7d32" }}>
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
              {this.state.showGoogle && (
                <div className="calendar-google">
                  <h5>
                    <span className="px-2">
                      <FontAwesomeIcon icon={faCalendarPlus} />
                    </span>
                    Google Calendar
                  </h5>
                  <hr />
                  {this.props.itineraryParts.map((itinerary, key) => (
                    <div key={key}>
                      {key > 0 && <hr />}
                      {itinerary.segments.map((segment, index) => (
                        <div key={index} className="pb-2">
                          <h5 className="pb-1 text-center">
                            <span>{segment.origin}</span>
                            <span className="px-4">
                              <FontAwesomeIcon icon={faPlaneDeparture} />
                            </span>
                            <span>{segment.destination}</span>
                          </h5>
                          <a
                            target="_blank"
                            href={this.constructLink(segment, "google")}
                            className="add-to-calendar-link"
                          >
                            <span
                              style={{ color: "#999999", fontSize: "1.1rem" }}
                            >
                              Add to Google calendar ({segment.origin} -{" "}
                              {segment.destination})
                            </span>
                            <span className="px-2" style={{ color: "#2e7d32" }}>
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </span>
                          </a>
                        </div>
                      ))}
                    </div>
                  ))}
                  {/* <a
                    target="_blank"
                    href="https://www.google.com/calendar/render?action=TEMPLATE&text=Flight+from+ADD+to+DXB&dates=20210522T214500Z/20210523T025500Z&details=+flight+600+operated+by+Ethiopian%20Airlines&location=Addis%20Ababa%20(ADD),%20Ethiopia&sf=true"
                  ></a> */}
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default PaymentSummaryAddCalendar;
