import React, { Component } from "react";
import { Button, Col, Form, ListGroup, ListGroupItem } from "react-bootstrap";
import Translation from "i18next";
import OutsideClickHandler from 'react-outside-click-handler';

import "./PassengerSelector.css";
import { BookingWidgetAction } from "../../../../redux/actions/booking/bookingWidgetAction";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCaretDown, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Passenger from "../../../../model/passenger/passenger";
class PassengerSelector extends Component {
  constructor(props)
  {
    super(props);
    this.state ={
      showPassengerSelector:false,
    }
  }


  removePassenger = (type) => {
    let passenger = new Passenger();
    passenger = this.props.passengerInfo;
    switch (type) {
      case "adult":
        if (passenger.adult > 1 && !(passenger.adult <= passenger.infant)) {
          passenger.adult--;
          this.props.updatePassengerInfo(passenger);
        }
        break;
      case "child":
        if (passenger.child > 0) {
          passenger.child--;
          this.props.updatePassengerInfo(passenger);
        }
        break;
      case "infant":
        if (passenger.infant > 0) {
          passenger.infant--;
          this.props.updatePassengerInfo(passenger);
        }
        break;
        default:
    }
  };
  addPassenger = (type) => {
    let passenger = new Passenger();
    passenger = this.props.passengerInfo;
    switch (type) {
      case "adult":
        if (passenger.adult + passenger.child < 9) {
          passenger.adult++;
          this.props.updatePassengerInfo(passenger);
        }
        break;
      case "child":
        if (passenger.adult + passenger.child < 9) {
          passenger.child++;
          this.props.updatePassengerInfo(passenger);
        }
        break;
      case "infant":
        if (passenger.infant < 3 && passenger.infant < passenger.adult) {
          passenger.infant++;
          this.props.updatePassengerInfo(passenger);
        }
        break;
        default:
    }
  };


  render() {
    return (
      <Col xs={12} sm={12} md={6} className="my-2">
        <h5>{this.props.labelMessage}</h5>
        <OutsideClickHandler
          onOutsideClick={() => {
            this.setState({ showPassengerSelector: false });
          }}
        >
          <Form.Group controlId="formBasicPassengerSelector" className="mb-0">
            <Form.Control
              type="text"
              placeholder={
                this.props.passengerInfo.adult +
                  this.props.passengerInfo.child +
                  this.props.passengerInfo.infant >
                1
                  ? this.props.passengerInfo.adult +
                    this.props.passengerInfo.child +
                    this.props.passengerInfo.infant +
                    " " +
                    Translation.t("passengers-label")
                  : this.props.passengerInfo.adult +
                    this.props.passengerInfo.child +
                    this.props.passengerInfo.infant +
                    " " +
                    Translation.t("passenger-label")
              }
              className="widget-input bg-white passenger-selector-input border-bottom-0"
              onClick={() => {
                this.setState({
                  showPassengerSelector: !this.state.showPassengerSelector,
                });
              }}
              readOnly
            />
            <FontAwesomeIcon
              icon={
                this.state.showPassengerSelector ? faWindowClose : faCaretDown
              }
              className="passenger-caret"
              onClick={() => {
                this.setState({
                  showPassengerSelector: !this.state.showPassengerSelector,
                });
              }}
            />
          </Form.Group>
          {this.state.showPassengerSelector && (
            <ListGroup className="passenger-type-selector-list">
              <ListGroupItem className="border-top-0">
                <div>
                  {this.props.passengerInfo.adult === 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("adult")}
                    </span>
                  )}
                  {this.props.passengerInfo.adult > 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("adults")}
                    </span>
                  )}
                  <span className="float-right d-inline-flex">
                    {this.props.passengerInfo.adult > 1 &&
                      !(
                        this.props.passengerInfo.adult <=
                        this.props.passengerInfo.infant
                      ) && (
                        <Button
                          className="pl-2 pr-2 passenger-minus"
                          onClick={() => this.removePassenger("adult")}
                        >
                          -
                        </Button>
                      )}
                    {(this.props.passengerInfo.adult <= 1 ||
                      this.props.passengerInfo.adult <=
                        this.props.passengerInfo.infant) && (
                      <Button
                        className="pl-2 pr-2 passenger-minus passenger-btn-disabled"
                        title="Not allowed"
                        onClick={() => this.removePassenger("adult")}
                      >
                        -
                      </Button>
                    )}
                    <span className="pl-3 pr-3 passenger-num-label">
                      {this.props.passengerInfo.adult}
                    </span>
                    {this.props.passengerInfo.adult +
                      this.props.passengerInfo.child <
                      9 && (
                      <Button
                        className="pl-2 pr-2 passenger-plus"
                        onClick={() => this.addPassenger("adult")}
                      >
                        +
                      </Button>
                    )}
                    {this.props.passengerInfo.adult +
                      this.props.passengerInfo.child >=
                      9 && (
                      <Button
                        className="pl-2 pr-2 passenger-plus passenger-btn-disabled"
                        title="Not allowed"
                        disabled
                        onClick={() => null}
                      >
                        +
                      </Button>
                    )}
                  </span>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div>
                  {this.props.passengerInfo.child <= 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("child")}
                    </span>
                  )}
                  {this.props.passengerInfo.child > 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("children")}
                    </span>
                  )}
                  <span className="float-right d-inline-flex">
                    {this.props.passengerInfo.child > 0 && (
                      <Button
                        className="pl-2 pr-2 passenger-minus"
                        onClick={() => this.removePassenger("child")}
                      >
                        -
                      </Button>
                    )}
                    {this.props.passengerInfo.child <= 0 && (
                      <Button
                        className="pl-2 pr-2 passenger-minus passenger-btn-disabled"
                        title="Not allowed"
                        onClick={() => this.removePassenger("child")}
                      >
                        -
                      </Button>
                    )}

                    <span className="pl-3 pr-3 passenger-num-label">
                      {this.props.passengerInfo.child}
                    </span>
                    {this.props.passengerInfo.adult +
                      this.props.passengerInfo.child <
                      9 && (
                      <Button
                        className="pl-2 pr-2 passenger-plus"
                        onClick={() => this.addPassenger("child")}
                      >
                        +
                      </Button>
                    )}
                    {this.props.passengerInfo.adult +
                      this.props.passengerInfo.child >=
                      9 && (
                      <Button
                        className="pl-2 pr-2 passenger-plus passenger-btn-disabled"
                        title="Not allowed"
                        onClick={() => this.addPassenger("child")}
                      >
                        +
                      </Button>
                    )}
                  </span>
                </div>
              </ListGroupItem>
              <ListGroupItem>
                <div>
                  {this.props.passengerInfo.infant <= 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("infant")}
                    </span>
                  )}
                  {this.props.passengerInfo.infant > 1 && (
                    <span className="passenger-type-label">
                      {Translation.t("infants")}
                    </span>
                  )}
                  <span className="float-right d-inline-flex">
                    {this.props.passengerInfo.infant > 0 && (
                      <Button
                        className="pl-2 pr-2 passenger-minus"
                        onClick={() => this.removePassenger("infant")}
                      >
                        -
                      </Button>
                    )}
                    {this.props.passengerInfo.infant <= 0 && (
                      <Button
                        className="pl-2 pr-2 passenger-minus passenger-btn-disabled"
                        title="Not allowed"
                        onClick={() => this.removePassenger("infant")}
                      >
                        -
                      </Button>
                    )}
                    <span className="pl-3 pr-3 passenger-num-label">
                      {this.props.passengerInfo.infant}
                    </span>
                    {this.props.passengerInfo.infant < 3 &&
                      this.props.passengerInfo.infant <
                        this.props.passengerInfo.adult && (
                        <Button
                          className="pl-2 pr-2 passenger-plus"
                          onClick={() => this.addPassenger("infant")}
                        >
                          +
                        </Button>
                      )}
                    {(this.props.passengerInfo.infant >= 3 ||
                      this.props.passengerInfo.infant ===
                        this.props.passengerInfo.adult) && (
                      <Button
                        className="pl-2 pr-2 passenger-plus passenger-btn-disabled"
                        title="Not allowed"
                        onClick={() => this.addPassenger("infant")}
                      >
                        +
                      </Button>
                    )}
                  </span>
                </div>
              </ListGroupItem>
            </ListGroup>
          )}
        </OutsideClickHandler>
      </Col>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    passengerInfo: state.bookingWiget.passengerInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePassengerInfo: (passengerInfo) => {
      dispatch({
        type: BookingWidgetAction.PASSENGERSELECTION,
        passenger: passengerInfo,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PassengerSelector);
