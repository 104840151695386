import React from "react";
import {
  Accordion,
  Card,
  Row,
  OverlayTrigger,
  Tooltip,
  Col,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faChair,
  faLuggageCart,
  faArrowRight,
  faPlaneDeparture,
} from "@fortawesome/free-solid-svg-icons";
// "../../../service/shared/multi-lingual/Translation"
import "./ShoppingCart.css";
import { connect } from "react-redux";
import Translation from "../../../../service/shared/multi-lingual/Translation";
import moment from "moment";

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itinerarySummary: 0,
      seatAccordion: -1,
      extraAccordion: -1,
      sucharchgesAccordion: -1,
      taxesAccordion: -1,
      showFareRule: false,
      showBaggageInformation: false,
      checkedIn: 1,
      carryOn: -1,
    };
  }

  toHourMinute = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{" " + hours + " hrs " + minutes + " mins"}</span>;
  };

  getHoursFromMins = (mins) => {
    if (mins <= 60) {
      return (
        <span>
          {"  "}
          {mins} {" mins"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {parseInt(mins / 60)}
          {" hr(s) "} {mins % 60} {" mins"}
        </span>
      );
    }
  };
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getFlightsSummary = () => {
    var itinerySummaryList = [];
    var adultPassengerList = [];
    var childPassengerList = [];
    var infatPassengerList = [];
    var numberOfAdult = 0;
    var numberOfChild = 0;
    var numberOfInfant = 0;

    this.props.reservation.itinerary.itineraryPart.map(
      (itineraryPart, itineraryIndex) => {
        itineraryPart.segment.map((segment, segmentIndex) => {
          itinerySummaryList.push(
            <div key={segmentIndex}>
              <div className="shopping-cart-internal-header check-in-shopping-cart-header">
                <span className="shopping-cart-disapled">
                  <Translation Key="departing" />
                </span>{" "}
                <span className="shopping-cart-date">
                  {moment(segment.flightDetail[0].departureTime)
                    .locale(localStorage.getItem("i18nextLng"))
                    .format("LT")}
                  ,{" "}
                  {moment(segment.flightDetail[0].departureTime)
                    .locale(localStorage.getItem("i18nextLng"))
                    .format("DD MMMM YYYY")}
                </span>
                <span className="check-in-flight-status-indicator-in-shopping-cart ml-auto float-right">
                  {segment.flightDetail[0].departureFlightScheduleStatus}
                </span>
              </div>
              <Row className="shopping-cart-internal-title mx-0">
                <div className="d-inline-flex px-3">
                  {segment.flightDetail[0].departureAirportName}
                </div>
                <div className="d-inline-flex">
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
                <div className="d-inline-flex px-3">
                  <span>{segment.flightDetail[0].arrivalAirportName}</span>
                </div>
              </Row>
              <Row className="shopping-cart-disapled mx-0">
                <div className="d-inline-flex pl-3">
                  <Translation Key="arriving" />:{" "}
                  <b>
                    {" "}
                    {moment(segment.flightDetail[0].arrivalTime)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("LT")}
                    ,{" "}
                    {moment(segment.flightDetail[0].arrivalTime)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("DD MMMM YYYY")}
                  </b>
                  /{" "}
                  {this.toHourMinute(
                    moment(segment.flightDetail[0].arrivalTime).diff(
                      moment(segment.flightDetail[0].departureTime),
                      "minutes"
                    )
                  )}
                  {/* {this.getHoursFromMins(itineraryPart.totalDuration)} */}
                </div>
              </Row>
              <Row className="shopping-cart-disapled mx-0">
                <div className="d-inline-flex pl-3">
                  <Translation Key="equipment" />:{" "}
                  <b>{segment.flightDetail[0].equipment}</b>/Class:
                  {segment.fareInfo.bookingClass}
                  {/* {this.getHoursFromMins(itineraryPart.totalDuration)} */}
                </div>
              </Row>
              <span className="pl-3">{segment.fareInfo.brand}</span> 
              <Row className="shopping-cart-disapled mx-0">
                <div className="col-4 d-flex">
                  {segment.flightDetail[0].airline === "ET" ? (
                    <img
                      className="shopping-cart-airline-logo"
                      alt=""
                      src="https://www.gstatic.com/flights/airline_logos/35px/ET.png"
                    />
                  ) : null}{" "}
                  <b className="pl-2">
                    {segment.flightDetail[0].airline}
                    {"  "}
                    {segment.flightDetail[0].flightNumber}
                  </b>
                </div>
              </Row>
            </div>
          );
          return null;
        });
        return null;
      }
    );

    this.props.reservation.passengers.passenger.map(
      (passenger, passengerIndex) => {
        if (passenger.fiscalCode === "ADT") {
          numberOfAdult++;
          adultPassengerList.push(
            <Row key={passengerIndex}>
              <Col>
                <div className="col-12 row mx-0 d-flex align-self-center py-2">
                  <span className="check-in-passenger-name-identifier d-flex justify-content-center align-items-center">
                    {passenger.personName.first[0] +
                      passenger.personName.last[0]}
                  </span>
                  <span className="mx-2">
                    {passenger.personName.first +
                      " " +
                      passenger.personName.last}
                  </span>
                </div>
              </Col>
            </Row>
          );
        } else if (passenger.fiscalCode === "CHD") {
          numberOfChild++;
          childPassengerList.push(
            <Row key={passengerIndex}>
              <Col>
                <div className="col-12 row mx-0 d-flex align-self-center py-2">
                  <span className="check-in-passenger-name-identifier d-flex justify-content-center align-items-center">
                    {passenger.personName.first[0] +
                      passenger.personName.last[0]}
                  </span>
                  <span className="mx-2">
                    {passenger.personName.first +
                      " " +
                      passenger.personName.last}
                  </span>
                </div>
              </Col>
            </Row>
          );
        } else {
          numberOfInfant++;
          infatPassengerList.push(
            <Row key={passengerIndex}>
              <Col>
                <div className="col-12 row mx-0 d-flex align-self-center py-2">
                  <span className="check-in-passenger-name-identifier d-flex justify-content-center align-items-center">
                    {passenger.personName.first[0] +
                      passenger.personName.last[0]}
                  </span>
                  <span className="mx-2">
                    {passenger.personName.first +
                      " " +
                      passenger.personName.last}
                  </span>
                </div>
              </Col>
            </Row>
          );
        }
        return null;
      }
    );
    return (
      <>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="0"
              className="shopping-cart-header-border active-shopping-cart-header"
              onClick={() => {
                if (this.state.itinerarySummary === 0) {
                  this.setState({ itinerarySummary: -1 });
                } else {
                  this.setState({ itinerarySummary: 0 });
                }
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={
                    this.state.itinerarySummary === 0 ? faAngleDown : faAngleUp  
                  }
                  className="shopping-cart-right-icon ml-3"
                />

                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  className={
                    this.state.itinerarySummary === 0
                      ? "shopping-cart-active shopping-cart-left-icon"
                      : "shopping-cart-disapled shopping-cart-left-icon"
                  }
                />
              </span>
              <div className="row px-0 mx-0">
                <div className="col shopping-cart-title">Itinerary Summary</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="shopping-cart-flight-summary-card shopping-cart-seat-section px-0 mx-0">
                {itinerySummaryList}
                <h3 className="px-3">Passengers</h3>
                <div>
                  <Row className="mx-0">
                    <Col>
                      <h6>Adult x {numberOfAdult}</h6>
                    </Col>
                  </Row>
                  <Row className="mx-0">
                    <Col>{adultPassengerList}</Col>
                  </Row>
                </div>
                {numberOfChild !== 0 ? (
                  <div>
                    <Row className="mx-0">
                      <Col>
                        <h6>Child x {numberOfChild}</h6>
                      </Col>
                    </Row>
                    <Row className="mx-0">
                      <Col>{childPassengerList}</Col>
                    </Row>
                  </div>
                ) : null}
                {numberOfInfant !== 0 ? (
                  <div>
                    <Row className="mx-0">
                      <Col>
                        <h6>Infant x {numberOfInfant}</h6>
                      </Col>
                    </Row>
                    <Row className="mx-0">
                      <Col>{infatPassengerList}</Col>
                    </Row>
                  </div>
                ) : null}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className="shopping-cart-header-border active-shopping-cart-header"
              onClick={() => {
                if (this.state.seatAccordion === 0) {
                  this.setState({ seatAccordion: -1 });
                } else {
                  this.setState({ seatAccordion: 0 });
                }
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={
                    this.state.seatAccordion === 0 ? faAngleUp : faAngleDown
                  }
                  className="shopping-cart-right-icon ml-3"
                />

                <FontAwesomeIcon
                  icon={faChair}
                  className={
                    this.state.seatAccordion === 0
                      ? "shopping-cart-active shopping-cart-left-icon"
                      : "shopping-cart-disapled shopping-cart-left-icon"
                  }
                />
              </span>
              <div className="row px-0 mx-0">
                <div className="col shopping-cart-title">Seat</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="shopping-cart-flight-summary-card shopping-cart-seat-section px-0 mx-0">
                <h5 className="px-3">Seats</h5>
                {this.props.reservation.itinerary.itineraryPart[0].segment.map(
                  (segment, index) => {
                    return (
                      <>
                        <div className="row py-2 px-2 shopping-cart-internal-header mx-0">
                          <span>Flight : </span>
                          <span>
                            {" " +
                              segment.flightDetail[0].departureAirport +
                              " - " +
                              segment.flightDetail[0].arrivalAirport}
                          </span>
                        </div>
                        <Row className="mx-0">
                          {this.props.reservation.passengers.passenger.filter(passenger => passenger.id in this.props.activePassengers).map(
                            (passenger) => {
                              return (
                                <>
                                  <Col xs="12" className="my-1">
                                    <OverlayTrigger
                                      key={
                                        passenger.passengerSegments
                                          .passengerSegment[index].id
                                      }
                                      placement="left"
                                      overlay={
                                        <Tooltip
                                          id={
                                            "tooltip-" +
                                            passenger.passengerSegments
                                              .passengerSegment[index].id
                                          }
                                        >
                                          <span className="text-uppercase p-2">
                                            {(passenger.personName.prefix !==
                                            null
                                              ? passenger.personName.prefix +
                                                " "
                                              : "") +
                                              passenger.personName.first +
                                              " " +
                                              passenger.personName.last}
                                          </span>
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className={
                                          passenger.type.value === "INF"
                                            ? "passanger-identifier-infant-shopping d-inline-flex"
                                            : "passanger-identifier-shopping d-inline-flex"
                                        }
                                      >
                                        {/* {console.log(this.passengerSeatMaps)} */}
                                        {passenger.personName.first[0] +
                                          passenger.personName.last[0]}
                                      </span>
                                    </OverlayTrigger>
                                    <span className="pl-2">
                                      {"Seat " +
                                        (passenger.passengerSegments
                                          .passengerSegment[index]
                                          .passengerFlight[0].seat !== null
                                          ? passenger.passengerSegments
                                              .passengerSegment[index]
                                              .passengerFlight[0].seat.value
                                          : "")}
                                    </span>
                                  </Col>
                                </>
                              );
                            }
                          )}
                        </Row>
                      </>
                    );
                  }
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion>
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="1"
              className="shopping-cart-header-border active-shopping-cart-header"
              onClick={() => {
                if (this.state.extraAccordion === 0) {
                  this.setState({ extraAccordion: -1 });
                } else {
                  this.setState({ extraAccordion: 0 });
                }
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={
                    this.state.extraAccordion === 0 ? faAngleUp : faAngleDown
                  }
                  className="shopping-cart-right-icon ml-3"
                />

                <FontAwesomeIcon
                  icon={faLuggageCart}
                  className={
                    this.state.extraAccordion === 0
                      ? "shopping-cart-active shopping-cart-left-icon"
                      : "shopping-cart-disapled shopping-cart-left-icon"
                  }
                />
              </span>
              <div className="row px-0 mx-0">
                <div className="col shopping-cart-title">Extras</div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="shopping-cart-flight-summary-card shopping-cart-seat-section px-0 mx-0">
                <h5 className="px-3">Bags</h5>
                {this.props.reservation.itinerary.itineraryPart[0].segment.map(
                  (segment, index) => {
                    return (
                      <>
                        <div className="row py-2 px-2 shopping-cart-internal-header mx-0">
                          <span>Flight : </span>
                          <span>
                            {" " +
                              segment.flightDetail[0].departureAirport +
                              " - " +
                              segment.flightDetail[0].arrivalAirport}
                          </span>
                        </div>
                        <Row className="mx-0">
                          {this.props.reservation.passengers.passenger
                            .filter(
                              (passenger) =>
                                passenger.id in this.props.activePassengers
                            )
                            .map((passenger) => {
                              return (
                                <>
                                  {passenger.passengerSegments.passengerSegment[
                                    index
                                  ].airExtra !== null ? (
                                    <Col xs="12" className="my-2 row mx-0">
                                      <div className="px-0">
                                        <OverlayTrigger
                                          key={
                                            passenger.passengerSegments
                                              .passengerSegment[index].id
                                          }
                                          placement="left"
                                          overlay={
                                            <Tooltip
                                              id={
                                                "tooltip-" +
                                                passenger.passengerSegments
                                                  .passengerSegment[index].id
                                              }
                                            >
                                              <span className="text-uppercase p-2">
                                                {(passenger.personName
                                                  .prefix !== null
                                                  ? passenger.personName
                                                      .prefix + " "
                                                  : "") +
                                                  passenger.personName.first +
                                                  " " +
                                                  passenger.personName.last}
                                              </span>
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className={
                                              passenger.type.value === "INF"
                                                ? "passanger-identifier-infant-shopping d-inline-flex"
                                                : "passanger-identifier-shopping d-inline-flex"
                                            }
                                          >
                                            {passenger.personName.first[0] +
                                              passenger.personName.last[0]}
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                      <div className="col pl-0 align-items-center">
                                        {passenger.passengerSegments.passengerSegment[
                                          index
                                        ].airExtra.map((airExtra) => {
                                          return (
                                            <div className="row mx-0 px-0">
                                              <span className="pl-2">
                                                {"Bag (" +
                                                  airExtra.ancillary.subCode +
                                                  ") x " +
                                                  airExtra.quantity}
                                              </span>
                                              <span className="d-flex ml-auto">
                                                {airExtra.paymentStatus
                                                  .value === "FULFILLED"
                                                  ? "Purchased"
                                                  : "Pending"}
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </Col>
                                  ) : null}
                                </>
                              );
                            })}
                        </Row>
                      </>
                    );
                  }
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <hr className="py-3 my-0" />
      </>
    );
  };
  shoppingCartBody = () => {
    return (
      <>
        <div className="shopping-cart mt-4 flight-selection-container std-rad">
          <Accordion defaultActiveKey="0" style={{ padding: 0, margin: 0 }}>
            <Card className="std-rad">
              <h1 className="ml-3 shopping-cart-header">Trip Summary</h1>
            </Card>
            {this.getFlightsSummary()}
          </Accordion>
        </div>
      </>
    );
  };
  render() {
    if (this.props.isMedium) {
      return (
        <>{this.props.showShoppingCart && <>{this.shoppingCartBody()}</>}</>
      );
    } else {
      return <>{this.shoppingCartBody()}</>;
    }
  }
}
const mapStateTopProps = (state) => {
  return {
    isMedium: state.global.isMedium,
    reservation: state.checkIn.reservation,
    activePassengers: state.checkIn.activePassengers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(ShoppingCart);
