export const checkInAction = {
  UPDATEHIDENOPTION: "UPDATEHIDENOPTION",
  UPDATECHECKINMODEL: "UPDATECHECKINMODEL",
  UPDATECHECKInSABREDATAREQUEST: "UPDATECHECKInSABREDATAREQUEST",
  UPDATERESERVATION: "UPDATERESERVATION",
  UPDATECHECKINPASSENGERS: "UPDATECHECKINPASSENGERS",
  UPDATECHECKINSEATMAP: "UPDATECHECKINSEATMAP",
  CHECKINSEATSELECTING: "CHECKINSEATSELECTING",
  CHECKINSELECTSEAT: "CHECKINSELECTSEAT",
  CHECKINCHANGESEAT: "CHECKINCHANGESEAT",
  CHECKINREMOVESEATSELECTION: "CHECKINREMOVESEATSELECTION",
  CHECKINCHANGEACTIVEPASSANGERCODE: "CHECKINCHANGEACTIVEPASSANGERCODE",
  CHECKININITSELECTEDSEATS: "CHECKININITSELECTEDSEATS",
  CHECKIN_SAVEANCILLARYDATA: "CHECKIN_SAVEANCILLARYDATA",
  CHECKIN_ADDBAG: "CHECKIN_ADDBAG",
  CHECKIN_MINUSBAG: "CHECKIN_MINUSBAG",
  CHECKIN_ADDTOCART: "CHECKIN_ADDTOCART",
  UPDATEACTIVEPASSENGER: "UPDATEACTIVEPASSENGER",
  INITACTIVEPASSENGER: "INITACTIVEPASSENGER",
  CHECKINCLEARSEATSELECTIONENTRY: "CHECKINCLEARSEATSELECTIONENTRY",
  UPDATEBOARDINGPASS: "UPDATEBOARDINGPASS",
};