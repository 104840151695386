import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import AirportSelectStyle from "../../../../master-data/booking-widget/airpot-select-style";
import Translation from "i18next";
import CountryList from "../../../../master-data/shared/countryList.json";
import NationalityList from "../../../../master-data/shared/nationalityList.json";
import DocumentType from "../../../../master-data/shared/documentType.json";
import { connect } from "react-redux";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import { SingleDatePicker } from "react-dates";
import esLocale from "moment/locale/es";
import enLocale from "moment/locale/en-gb";
import frLocale from "moment/locale/fr";
import arLocale from "moment/locale/ar";
import deLocale from "moment/locale/de";
import zhLocale from "moment/locale/zh-cn";
import ptLocale from "moment/locale/pt";
import moment from "moment";
import {
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
  OPEN_UP,
} from "react-dates/constants";
import UserName from "../../shared/UserName";
const selectStyleDesktop = AirportSelectStyle.desktopStyle;

const TravelDocument = (props) => {
  const [focuseDate, setFocuseDate] = useState(false);
  useEffect(() => {
    switch (props.locale) {
      case "en":
        moment.updateLocale("en", enLocale);
        break;
      case "ar":
        moment.updateLocale("ar", arLocale);
        break;
      case "zh":
        moment.updateLocale("zh", zhLocale);
        break;
      case "fr":
        moment.updateLocale("fr", frLocale);
        break;
      case "es":
        moment.updateLocale("es", esLocale);
        break;
      case "pt":
        moment.updateLocale("pt", ptLocale);
        break;
      case "de":
        moment.updateLocale("de", deLocale);
        break;
      default:
        moment.updateLocale("en", enLocale);
        break;
    }
  });

  const onChange = (value) => {
    setFocuseDate(!focuseDate);
    props.updateTravelDocument(
      value ? value.locale("en").format("YYYY-MM-DD") : null,
      "expirationDate"
    );
    props.changeTravelDocumentValidation(
      "expirationDate",
      "isExpirationDateValid",
      "onBlur"
    );
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="media">
            <UserName />

            <div className="media-body mx-2">
              <h3 className="frequent-flyer-h3">
                {Translation.t("travel_document")}
              </h3>
              <p>{Translation.t("all_fields_required")}</p>
            </div>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <Row>
          <Col xs={12} sm={12} md={6} lg={4}>
            <Select
              styles={selectStyleDesktop}
              className="my-2"
              placeholder={Translation.t("travel_document_type")}
              defaultValue={DocumentType.filter(
                (d) => d.value === props.documentType
              )}
              options={DocumentType}
              onChange={(documentType) =>
                props.updateTravelDocument(documentType.value, "documentType")
              }
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}>
            <input
              className="form-control widget-input my-2"
              placeholder={Translation.t("travel_document_number")}
              defaultValue={props.documentNumber}
              id="travel-document-number"
              onChange={() => {
                var value = document.getElementById(
                  "travel-document-number"
                ).value;
                props.updateTravelDocument(value, "documentNumber");
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={4}>
            <Select
              styles={selectStyleDesktop}
              className="my-2"
              placeholder={Translation.t("document_issuing_country")}
              defaultValue={CountryList.filter(
                (c) => c.value === props.issuingCountry
              )}
              onChange={(issuingCountry) =>
                props.updateTravelDocument(
                  issuingCountry.value,
                  "issuingCountry"
                )
              }
              options={CountryList}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={4}>
            <div
              className={
                props.isExpirationDateValid ? "my-2" : "invalid_input my-2"
              }
            >
              <SingleDatePicker
                showClearDate={false}
                displayFormat="YYYY-MM-DD"
                numberOfMonths={props.isMobile ? 3 : 1}
                orientation={
                  props.isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
                }
                disabled={false}
                readOnly={true}
                placeholder={Translation.t("expiration_date")}
                date={
                  props.expirationDate != null
                    ? moment(props.expirationDate)
                    : null
                }
                onDateChange={onChange}
                focused={focuseDate}
                onFocusChange={() => {
                  if (focuseDate) {
                    props.changeTravelDocumentValidation(
                      "expirationDate",
                      "isExpirationDateValid",
                      "onBlur"
                    );
                  } else {
                    props.changeTravelDocumentValidation(
                      "expirationDate",
                      "isExpirationDateValid",
                      "onFocus"
                    );
                  }

                  setFocuseDate(!focuseDate);
                }}
                withFullScreenPortal={props.isMobile}
                hideKeyboardShortcutsPanel={true}
                showDefaultInputIcon={true}
                id="travel-document-expiry-date"
                openDirection={OPEN_UP}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={4}>
            <Select
              styles={selectStyleDesktop}
              className="my-2"
              placeholder={Translation.t("nationality")}
              defaultValue={NationalityList.filter(
                (n) => n.value === props.nationality
              )}
              onChange={(nationality) =>
                props.updateTravelDocument(nationality.value, "nationality")
              }
              options={NationalityList}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    locale: state.global.locale,
    documentType: state.account.profile.travelDocument.documentType,
    documentNumber: state.account.profile.travelDocument.documentNumber,
    issuingCountry: state.account.profile.travelDocument.issuingCountry,
    expirationDate: state.account.profile.travelDocument.expirationDate,
    nationality: state.account.profile.travelDocument.nationality,
    isExpirationDateValid: state.account.isTravelDateExpirationDateValid,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
  };
};
const mapDispatchToProps = (dispacth) => {
  return {
    updateTravelDocument: (value, valueParameter) =>
      dispacth({
        type: accountAction.UPDATETRAVELDOCUMENT,
        value: value,
        valueParameter: valueParameter,
      }),
    changeTravelDocumentValidation: (
      valueParameter,
      validationParameter,
      domain
    ) =>
      dispacth({
        type: accountAction.UPDATETRAVELDOCUMENTVALIDATION,
        valueParameter: valueParameter,
        validationParameter: validationParameter,
        domain: domain,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(TravelDocument);
