export default class SelectionModel {
  messages = null;
  seatCode = "";
  selectedSegment = {
    origin: "",
    destination: "",
    departure: "",
  };
  passengerIndex = null;
}
