export class ExchangeFlightSelectRequest {
    constructor()
    {
        this.request ={
            
                "OutGoingShoppingBasketHashCode": 1,
                "ReturnShoppingBasketHashCode": 1,
                "itineraryParts": [],
                "cookieSabreDataRequest":null
            }
        }
    }

    
