export default class MatrixService{
    initializeState=(bundledAlternateDateOffers)=>{
        
        var topHeader=[];
        var leftHeader=[];
        var body=[];
         var  startDate = new Date(bundledAlternateDateOffers[0].departureDates[0]);
       var  endDate = new Date(bundledAlternateDateOffers[0].departureDates[1]);
      //  startDate.setDate(startDate.getDate() - 1);
      //  endDate.setDate(endDate.getDate() - 1);
        for (let index = 0; index < 7; index++) {
          var tempStartDate = new Date(JSON.parse(JSON.stringify(startDate)));
          var tempEndDate = new Date(JSON.parse(JSON.stringify(endDate)));
          topHeader.push(tempStartDate);
          leftHeader.push(tempEndDate);
          startDate.setDate(startDate.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
          body[index]=[];
        }
        leftHeader.forEach((leftDate,leftIndex) => {
            var leftDateYear= leftDate.getFullYear();
            var leftDateMonth= ("0" + (leftDate.getMonth() + 1)).slice(-2);
            var leftDateDate = ("0" + leftDate.getDate()).slice(-2)
            var tempLeftDate = leftDateYear+"-"+leftDateMonth+"-"+leftDateDate;
            topHeader.forEach((headerDate, headerDateIndex) => {
              var headeDateYear = headerDate.getFullYear();
              var headerDateMonth = ("0" + (headerDate.getMonth() + 1)).slice(
                -2
              );
              var headerDateDate = ("0" + headerDate.getDate()).slice(-2);
              var tempHeaderDate =
                headeDateYear + "-" + headerDateMonth + "-" + headerDateDate;
                var tempBundledAlternateDateOffers =bundledAlternateDateOffers.filter(b=>b.departureDates[0]===tempHeaderDate);
                tempBundledAlternateDateOffers.forEach(
                  (bundledAlternateDateOffer) => {
                    if (
                      bundledAlternateDateOffer.departureDates[1] ===
                      tempLeftDate
                    ) {
                      var data = {
                        soldout: bundledAlternateDateOffer.soldout,
                        status: bundledAlternateDateOffer.status,
                        total: bundledAlternateDateOffer.total,
                        startDate: bundledAlternateDateOffer.departureDates[0],
                        endDate: bundledAlternateDateOffer.departureDates[1],
                        isLowestPrice:bundledAlternateDateOffer.isLowestPrice
                      };
                      body[leftIndex][headerDateIndex] = data;
                      return true;
                    }
                  }
                );
              if(body[leftIndex][headerDateIndex]===undefined || body[leftIndex][headerDateIndex]===null){
                var data = {
                  soldout: "true",
                  startDate: "",
                  endDate: "",
                  status:"N/A"
                };
                body[leftIndex][headerDateIndex] = data;
              }
            });

            body[leftIndex][7]= leftDate;
        });


        return{
            topHeader:topHeader,
            leftHeader:leftHeader,
            body:body
        }
    }
}