export const seatSelectionAction = {
    SELECTSEAT : "SELECTSEAT",
    CHANGESEAT : "CHANGESEAT",
    STORESEATRESULT : "STORESEATRESULT",
    MBYSTORESEATRESULT : "MBYSTORESEATRESULT",
    CHANGEACTIVEPASSANGERCODE : "CHANGEACTIVEPASSANGERCODE",
    REMOVESEATSELECTION : "REMOVESEATSELECTION",
    SEATSELECTING : "SEATSELECTING",
    MYBSEATSELECTING : "MYBSEATSELECTING",
    MYBUPDATECOOKIESABREDATAREQUEST: "MYBUPDATECOOKIESABREDATAREQUEST",
    CLEARSEATSELECTIONENTRY : "CLEARSEATSELECTIONENTRY",
    MYBREMOVESEATSELECTION : "MYBREMOVESEATSELECTION",
    MYBSELECTSEAT : "MYBSELECTSEAT"
}   