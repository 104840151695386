import React from "react";
import { Row } from "react-bootstrap";
import FlightScheduleFilter from "./FlightScheduleFilter";
import Translation from "i18next";

const FlightScheduleHeader = (props) => {
  return (
    <>
      <div className="title-wrapper">
        <h2 className="flight-schedule-view-title">
          <span>
          {Translation.t("flight_schedules_for") + " "} <span>{props.flightSchedules[0].flights[0].origin}</span> to <span>{props.flightSchedules[0].flights[props.flightSchedules[0].flights.length - 1].destination}</span>
          </span>
        </h2>
      </div>
      <h3 className="flight-schedule-title-info">
        <span>
        {Translation.t("review_the_status_of_the_flights_that_match_your_search")}
        </span>
      </h3>
      <Row className="mx-0 flight-schedule-header-container">
        <div className="d-flex col-12 px-0 row mx-0">
            <FlightScheduleFilter flightSchedules={props.flightSchedules}/>
        </div>
      </Row>
    </>
  );
};

export default FlightScheduleHeader;
