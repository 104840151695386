import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import HttpService from "../../../service/shared/HttpService";
import Notification from "../../booking/booking-widget/Notification";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
import Translation from "i18next";

const ForgotPasswordModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const forgotPasswordHandler = () => {
    if (isSuccessful) {
      props.setShowModal();
    } else if (!isLoading) {
      var forgotPassInput = document.getElementById("forgot-password-input");
      if (forgotPassInput.value === null || forgotPassInput.value === "") {
        forgotPassInput.classList.add("invalid_input");
      } else {
        setIsLoading(true);
        var requestDate = {
          username: forgotPassInput.value,
        };
        HttpService.postAccount(requestDate, "/ResetPasswordKey", props.token)
          .then((loginResponse) => {
            if (loginResponse.data.statusAPI === "SUCCESS") {
              setIsLoading(false);
              setIsSuccessful(true);
            } else {
              setIsLoading(false);
              setErrorOccured(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setErrorOccured(true);
          });
      }
    }
  };

  return (
    <>
      <Modal show={true} onHide={() => props.setShowModal()} size="xl" centered>
        <Modal.Header closeButton style={{ backgroundColor: "whitesmoke" }}>
          <Modal.Title>{Translation.t("forgot_or_reset_password")}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "whitesmoke" }}>
          {isSuccessful ? (
            <Notification
              success={true}
              body={Translation.t("forgot_passwrod_email")}
            />
          ) : (
            <div>
              <h5>
              {Translation.t("forgot_password_description")}

              </h5>
              {errorOccured && (
                <Notification
                  error={true}
                  body={Translation.t("profile_does_not_exist")}
                />
              )}
              <input
                className="form-control widget-input my-3"
                placeholder="Enter your username or loyalty number"
                id="forgot-password-input"
                defaultValue={props.userName}
                onFocus={() => {
                  var forgotPassInput = document.getElementById(
                    "forgot-password-input"
                  );
                  forgotPassInput.classList.remove("invalid_input");
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "whitesmoke" }}>
          <Button
            variant="danger"
            className={
              isLoading
                ? "bk-submit-btn btn-ethiopian m-2 btn-not-allowd disabled"
                : "bk-submit-btn btn-ethiopian m-2"
            }
            onClick={() => forgotPasswordHandler()}
          >
            {isSuccessful ? (
              <span>{Translation.t("close")}</span>
            ) : isLoading ? (
              <ProgressSimpler />
            ) : (
              <span>{Translation.t("send_email")}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (state) => {
  return {
    userName: state.global.userName,
    token: state.searchFlight.token,
  };
};
export default connect(mapDispatchToProps, null)(ForgotPasswordModal);
