import React, { Component } from "react";
import ConnectionException from "./ConnectionException";
import GenericException from "./GenericException";
import InvalidRequestException from "./InvalidRequestException";
import NotFoundException from "./NotFoundException";
import ServerException from "./ServerException";
import UnAuthorizedException from "./UnAuthorizedException";
class Exception extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusCode: 400,
    };
  }
  componentDidMount() {}
  renderException = (statusCode) => {
    
    switch (statusCode) {
      case 400:
        return <InvalidRequestException statusCode={statusCode} />;
      case 401:
        return <UnAuthorizedException statusCode={statusCode} />;
      case 404:
        return <NotFoundException statusCode={statusCode} />;
      case 500:
        return <ServerException statusCode={statusCode} />;
      case 503:
        return <ConnectionException statusCode={statusCode} />;
      default:
        return <GenericException statusCode={statusCode} />;
    }
  };

  render() {
    return (
      <selection className="bg-white">
        <div>{this.renderException(this.state.statusCode)}</div>
      </selection>
    );
  }
}

export default Exception;
