export  const BookingWidgetAction = {
    AIRPORTSELECTION:"AIRPORTSELECTION",
    ADDFLIGHT:"ADDFLIGHT",
    REMOVEFLIGHT:"REMOVEFLIGHT",
    ADDPROMOCODE:"ADDPROMOCODE",
    DATESELECTION : "DATESELECTION",
    ADDSTOPOVER : "ADDSTOPOVER",
    PASSENGERSELECTION:"PASSENGERSELECTION",
    CHANGEJOURNYTYPE:"CHANGEJOURNYTYPE",
    UPDATECABINCLASS:"UPDATECABINCLASS",
    VALIDATEWIDGET:"VALIDATEWIDGET",
    UPDATESTOPDAYS : "UPDATESTOPDAYS",
    REMOVESTOPOVER : "REMOVESTOPOVER",
    FILLSEARCHFORM:"FILLSEARCHFORM",
    UPDATEFARETYPE : "UPDATEFARETYPE",
    CHANGEWIDGETTYPE:"CHANGEWIDGETTYPE"
}