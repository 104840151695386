import MatrixService from "../../../service/booking/matrix/MatrixService";
import { matrixAction } from "../../actions/booking/matrixAction";

const initialState = {
  topHeader: [],
  leftHeader: [],
  tableBody: [],
};
var matrixService = new MatrixService();
 const matrixReducer=(state = initialState,action)=>{
switch(action.type){
    case matrixAction.INITIALIZESATE:
      var newState = matrixService.initializeState(action.responseData.bundledAlternateDateOffers);
    return{
        ...state,
        topHeader:newState.topHeader,
        leftHeader:newState.leftHeader,
        tableBody:newState.body
    }
    default:
        return state;
}
}
export default matrixReducer;