import {
  faCreditCard,
  faPassport,
  faUser,
  faUserLock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { Button, ButtonGroup, Col, Row,Modal } from "react-bootstrap";
import Translation from "i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import PersonalDetail from "./PersonalDetail";
import AccountSetting from "./AccountSetting";
import BillingInfo from "./BillingInfo";
import TravelDocument from "./TravelDocument";
import FrequentFlyer from "./FrequentFlyer";
import AccountService from "../../../../service/manage-my-booking/account/AccountService";
import Loading from "../../../booking/seat-selection/Loading";
import HttpService from "../../../../service/shared/HttpService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Notification from "../../../booking/booking-widget/Notification";
import { useLocation } from 'react-router-dom';
const accountService = new AccountService();
const ManageProfile = (props) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage,setErrorMessage]=useState(null);
  const [intiliazeProfile,setIntiliazeProfile]=useState(true);
  const [isDataLoading,setIsDataLoading]=useState(false);
  var history = useHistory();
  const location = useLocation();

  const ProfileBody = () => {
    if (currentTab === 1) {
      return <PersonalDetail />;
    } else if (currentTab === 2) {
      return <AccountSetting />;
    } else if (currentTab === 3) {
      return <BillingInfo />;
    } else if (currentTab === 4) {
      return <FrequentFlyer />;
    } else {
      return <TravelDocument />;
    }
  };
  const accountRetrieve = async () => {
      setIsDataLoading(true);
      await HttpService.postAccount(
        props.profileCookie,
        "/ProfileRetrieve",
        props.token
      )
        .then((accountRetrieveResponse) => {
          if (accountRetrieveResponse.data.statusAPI === "SUCCESS") {
            props.iniliazeProfile(accountRetrieveResponse.data.profile);
          }
          setIntiliazeProfile(false);
        })
        .catch((error) => {
          setIntiliazeProfile(false);
          console.log(error);
        });
  };
  const saveUpdates = async () => {
    var requestObejectForApi = await accountService.buildRequestObjectForApi(
      props.profile,
      props.createAccountModel,
      props.profileCookie
    );
    setIsLoading(true);
    await HttpService.postAccount(requestObejectForApi, "/Update", props.token)
      .then((updateResponse) => {
        if (updateResponse.data.statusAPI === "SUCCESS") {
          setIsLoading(false);
          props.updateAccount(
            true,
            updateResponse.data.result.user.personalDetails.firstName,
            updateResponse.data.result.user.personalDetails.lastName,
            updateResponse.data.cookieSabreDataRequest
          );
        }
        else{
          setIsLoading(false);
          setShowModal(true);
          setErrorMessage(updateResponse.data.errorList);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setShowModal(true);
        setErrorMessage(["Your session is expired please login again"]);
        console.log(error);
      });
      if(props.oldPassword !== null&&props.oldPassword !== undefined&&props.newPassword !== null&&props.newPassword !== undefined&&props.passwordIsConfirmed){
        var updatePasswordObjectForApi={

        }
        await HttpService.postAccount(updatePasswordObjectForApi, "/RestPassword", props.token)
        .then((updateResponse) => {
          if (updateResponse.data.statusAPI === "SUCCESS") {
            setIsLoading(false);
            setShowModal(true);
            setErrorMessage(" your password has been successfully updated");
          }
          else{
            setIsLoading(false);
            setShowModal(true);
            setErrorMessage(updateResponse.data.errorList);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setShowModal(true);
          setErrorMessage(["Your session is expired please login again"]);
          console.log(error);
        });
      }
  };
  if(!props.isUserSignedIn){
    history.push("/");
  }
  if(intiliazeProfile){
    if(!isDataLoading){
      accountRetrieve();
    }
    return <Loading />;
  }
  return (
    <div className="manage-profile-box mt-4 flight-selection-container manage-profile-container">
      {isLoading && <Loading />}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error Message List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <FontAwesomeIcon icon={faTimes} className="mx-2" />
              {errorMessage}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            onClick={() => setShowModal(false)}
          >
            {Translation.t("close")}
          </button>
        </Modal.Footer>
      </Modal>
      <h3 className="my-3">Your Profile Details</h3>
      {location.search==="?newAccount=true"? (
        <Row>
          <Col>
            <Notification
              header="Congratulations your profile has successfully been created!"
              success={true}
              body="You will receive an email with your new account username and password. You can also view this information by logging into the application"
            />
          </Col>
        </Row>
      ):null}

      <div className="booking-type">
        <ButtonGroup aria-label="booking-type" className="btn-block">
          <Button
            variant={currentTab === 1 ? "success" : "white"}
            className={currentTab === 1 ? "active" : "manage-profile-tab"}
            onClick={() => setCurrentTab(1)}
          >
            <span className="manage-profile-tab">
              <FontAwesomeIcon icon={faUser} className="mx-1" />
              {Translation.t("personal_detail")}
            </span>
          </Button>
          <Button
            variant={currentTab === 2 ? "success" : "white"}
            className={currentTab === 2 ? "active" : ""}
            onClick={() => setCurrentTab(2)}
          >
            <span className="manage-profile-tab">
              <FontAwesomeIcon icon={faUserLock} className="mx-1" />
              {Translation.t("account_setting")}
            </span>
          </Button>
          {/* <Button
            variant={currentTab === 3 ? "success" : "white"}
            className={currentTab === 3 ? "active" : ""}
            onClick={() => setCurrentTab(3)}
          >
            <span className="manage-profile-tab">
              <FontAwesomeIcon icon={faCreditCard} className="mx-1" />
              {Translation.t("billing_info")}
            </span>
          </Button> */}
          <Button
            variant={currentTab === 4 ? "success" : "white"}
            className={currentTab === 4 ? "active" : ""}
            onClick={() => setCurrentTab(4)}
          >
            <span className="manage-profile-tab">
              {Translation.t("frequent_flyer_numbers")}
            </span>
          </Button>
          <Button
            variant={currentTab === 5 ? "success" : "white"}
            className={currentTab === 5 ? "active" : ""}
            onClick={() => setCurrentTab(5)}
          >
            <FontAwesomeIcon icon={faPassport} className="mx-1" />
            <span className="manage-profile-tab">
              {Translation.t("travel_document")}
            </span>
          </Button>
        </ButtonGroup>
      </div>
      <div className="manage-profile-body my-3">
        {ProfileBody()}
        <Row className="mt-4">
          <Col sm={12} md={6}></Col>
          <Col sm={12} md={6}>
            <span className="float-right">
              <Button
                variant="danger"
                className="bk-submit-btn btn-ethiopian mx-2"
                onClick={() => saveUpdates()}
              >
                {Translation.t("save_updates")}
              </Button>
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
};
const mapStateTopProps = (state) => {
  return {
    profile: state.account.profile,
    profileCookie: state.account.profileCookie,
    createAccountModel: state.account.createAccountModle,
    token: state.searchFlight.token,
    isUserSignedIn: state.global.isUserSignedIn,
    oldPassword: state.account.updatePassword.oldPassword,
    newPassword: state.account.updatePassword.newPassword,
    passwordIsConfirmed:
    state.account.updatePassword.validation.passwordIsConfirmed,
    };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (value, firstName, lastName, profileCookie) =>
      dispatch({
        type: accountAction.SIGNEDIN,
        value: value,
        firstName: firstName,
        lastName: lastName,
        profileCookie: profileCookie,
      }),
      iniliazeProfile: (data) =>
      dispatch({
        type: accountAction.INTIALZEPROFILE,
        data: data,
      }),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ManageProfile);


  // const GetCardType = (number) => {
  //   // visa
  //   var re = new RegExp("^4");
  //   if (number.match(re) != null) {
  //     return setCardType("Visa");
  //   }

  //   if (
  //     /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
  //       number
  //     )
  //   ) {
  //     return setCardType("Mastercard");
  //   }

  //   // AMEX
  //   re = new RegExp("^3[47]");
  //   if (number.match(re) != null) {
  //     return setCardType("AMEX");
  //   }

  //   // Discover
  //   re = new RegExp(
  //     "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
  //   );
  //   if (number.match(re) != null) {
  //     return setCardType("Discover");
  //   }

  //   // Diners
  //   re = new RegExp("^36");
  //   if (number.match(re) != null) {
  //     return setCardType("Diners");
  //   }

  //   // Diners - Carte Blanche
  //   re = new RegExp("^30[0-5]");
  //   if (number.match(re) != null) {
  //     return setCardType("Diners - Carte Blanche");
  //   }

  //   // JCB
  //   re = new RegExp("^35(2[89]|[3-8][0-9])");
  //   if (number.match(re) != null) {
  //     return setCardType("JCB");
  //   }

  //   // Visa Electron
  //   re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
  //   if (number.match(re) != null) {
  //     return setCardType("Visa Electron");
  //   }

  //   return setCardType(null);
  // };