import ItineraryPart from "../itineraryPart/ItineraryPart";
import Validation from "../itineraryPart/validation";
import Passenger from "../passenger/passenger";

export class ExchangeFlightSearchRequest 
{
    
    constructor()
    {

        this.flightRequest ={
            journyType: "roundTrip",
            widgetType:"bookFlight",
            itineraryPart: [],
            passengerInfo: {},
            validation: [
              new Validation(),
              new Validation(),
              new Validation(),
              new Validation(),
              new Validation(),
              new Validation(),
            ],
            promoCode: null,
            cabinClass: {
              value: 3,
              label: "All",
            },
            fareType: "currency",
            isFormValid:true,
          }
    }
}