import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCaretDown,faCaretUp } from '@fortawesome/free-solid-svg-icons'
import "./RecentSearch.css";
import RecentSearchList from "./RecentSearchList";
import Translation from "i18next";
class RecentSearch extends Component {
  constructor(props)
  {
    super(props);
    this.state={
      showRecentSearches:false
    }
  }
  showHideRecentSearchList=()=>{
    this.setState({showRecentSearches:!this.state.showRecentSearches});
  }

  render() {
    return (
      <Row>
           <Col xs={12} sm={12} md={6}>  <div className="text-left mb-2">
         <button className="border-0 pl-0 recent-search-btn" onClick={this.showHideRecentSearchList}>
            {Translation.t('recent-search-label')}
         </button>
         <FontAwesomeIcon icon={this.state.showRecentSearches?faCaretUp:faCaretDown} className="recent-search-caret" onClick={()=> {
             
             this.setState({showRecentSearches:!this.state.showRecentSearches});
           }}/>
         </div></Col>
         <Col xs={12} sm={12} md={12} className="p-0">
           {this.state.showRecentSearches && <RecentSearchList showHideRecentSearchList={this.showHideRecentSearchList}/>}
         </Col>
      </Row>
      
  );
  }
}
export default RecentSearch;