import React, { useState } from "react";
import { Container, Row, Modal } from "react-bootstrap";
import Notification from "../booking/booking-widget/Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChair,
  faUser,
  faArrowRight,
  faAngleDown,
  faAngleUp,
  faFileAlt,
  faPrint,
  faIdCard,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Translation from "i18next";
import Select from "react-select";
import AirportSelectStyle from "../../master-data/booking-widget/airpot-select-style";
import PassengerService from "../../service/booking/passenger/PassengerService";
import moment from "moment";

import CheckInService from "../../service/check-in/CheckInService";
import Loading from "../booking/seat-selection/Loading";

import { AirportFullName } from "../../service/shared/AirportFullName";
import ProgressSimpler from "../shared/progress-simple/ProgressSimpler";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import { checkInAction } from "../../redux/actions/check-in/checkInAction";

const Confirmation = (props) => {
  const passengerService = new PassengerService();
  const selectStyle = AirportSelectStyle.desktopStyle;
  let history = useHistory();
  const [confirming, setConfirming] = useState(true);
  const [isBoardingPassModalOpened, setIsBoardingPassModalOpened] =
    useState(false);
  const [email, setEmail] = useState("");
  const [gettingBoardingPass, setGettingBoardingPass] = useState(false);
  // const [gettingBoardingPass, setGettingBoardingPass] = useState(false);

  const [isPassengerListAccordionOpend, setIsPassengerListAccordionOpend] =
    useState(true);
  const [isFlightSummaryAccordionOpend, setFlightSummaryAccordionOpend] =
    useState(true);
  const [tripOption, setTripOption] = useState(false);

  const [isEmailModalOpened, setIsEmailModalOpened] = useState(false);
  const [passengerDetailIndicator, setPassengerDetailIndicator] = useState(
    () => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      const initialState = temp;
      return initialState;
    }
  );
  const [updatingDoc, setUpdatingDoc] = useState(false);
  const [frequentFlyer, setFrequentFlyer] = useState({
    memberAirline: "",
    memberId: "",
  });

  // let boardingPass = {};
  const [boardingPass, setBoardingPass] = useState(() => {
    var temp = {};
    props.reservation.passengers.passenger.map((passenger) => {
      temp[passenger.id] = "";
      return {};
    });
    return temp;
  });
  const [ErrorMessage, setErrorMessage] = useState("");
  const [
    localActivePassengerForBoardingModal,
    setLocalActivePassengerForBoardingModal,
  ] = useState("");


  const confirmChecking = () => {
    let checkInService = new CheckInService();
    var payload = [];
    props.passengers
      .filter(
        (passenger) =>
          passenger.id in props.activePassengers &&
          !passenger.passengerSegments.passengerSegment[0].passengerFlight[0]
            .checkedIn
      )
      .map((passenger) => {
        payload.push(passenger.id);
        return null;
      });
    if (payload.length === 0) {
      setConfirming(false);
    } else {
      checkInService
        .pnrCheckin(payload, props.token, props.checkInSabreDataRequest)
        .then((respone) => {
          if (
            respone.data.statusAPI === "SUCCESS" &&
            respone.data.results[0].status[0].type === "SUCCESS"
          ) {
          }
          setConfirming(false);
        });
    }
  };

  const [passengerFrequentFlyerIndicator, setPassengerFrequentFlyerIndicator] =
    useState(() => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      confirmChecking();
      const initialState = temp;
      return initialState;
    });
  const [passengerDocumentIndicator, setPassengerDocumentIndicator] = useState(
    () => {
      var temp = {};
      props.reservation.passengers.passenger.map((passenger) => {
        temp[passenger.id] = 0;
        return {};
      });
      const initialState = temp;
      return initialState;
    }
  );

  const handleDetaileClick = (passengerId) => {
    var temp = { ...passengerDetailIndicator };
    var temp1 = { ...passengerFrequentFlyerIndicator };
    var temp2 = { ...passengerDocumentIndicator };

    temp[passengerId] = !temp[passengerId];
    temp1[passengerId] = false;
    temp2[passengerId] = false;

    setPassengerFrequentFlyerIndicator(temp1);
    setPassengerDocumentIndicator(temp2);
    setPassengerDetailIndicator(temp);
  };

  const toHourMinute = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return (
      <span>
        {hours +
          (hours > 1 ? " hrs " : " hr ") +
          (minutes !== 0 ? minutes + (minutes > 1 ? " mins" : " min") : "")}
      </span>
    );
  };

  document.title = "Confirmation | Check-in";

  const getEmailModal = () => {
    return isEmailModalOpened ? (
      <Modal
        show={isEmailModalOpened}
        onHide={() => {
          setIsEmailModalOpened(false);
        }}
        size="xl"
        centered
      >
        <Modal.Body className="pb-4">
          <h5 className="py-3 px-3">
            {Translation.t("email_the_above_boarding_pass_to_access_it_later")}:
          </h5>
          <div className="row w-100 justify-content-center justify-content-md-end mx-0">
            <div className="row mx-0 col-12 px-0 mb-3 mb-lg-0">
              <div className="col-12 col-lg-6 px-0">
                <input
                  className="form-control widget-input mb-3"
                  placeholder="Email *"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <button
              className="col-12 col-lg-2 btn py-2 ml-lg-2 cancel-seat-continue-modal"
              onClick={() => {
                setIsEmailModalOpened(false);
              }}
            >
              {Translation.t("close")}
            </button>
            <button
              className="col-12 my-3 my-lg-0 col-lg-2 ml-lg-2 bk-submit-btn btn-ethiopian btn d-flex align-self-center align-items-center justify-content-center"
              onClick={() => {
                sendBoadingPass();
              }}
            >
              {Translation.t("submit")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    ) : null;
  };
  const sendBoadingPass = () => {
    let checkInService = new CheckInService();
    setIsEmailModalOpened(false);
    setGettingBoardingPass(true);

    checkInService
      .emailBoardingPass(
        { pnr: props.reservation.recordLocator, email: email },
        props.token
      )
      .then((response) => {
        setGettingBoardingPass(false);
      });
  };
  const initBoardingPass = (passengerId, forModal) => {
    // if (!boardingPassTracker) {
    if (props.boardingPass === null) {
      let checkInService = new CheckInService();
      checkInService
        .getBoardingPassCustom(props.reservation.recordLocator, props.token)
        .then((response) => {
          if (response.data.statusAPI !== "ERROR" && response.data.boardingPasses.length > 0) {
            var temBoardingPass = {};
            props.reservation.passengers.passenger
              .filter((passenger) => passenger.id in props.activePassengers)
              .map((passenger, index) => {
                if (
                  response.data.boardingPasses[index] !== undefined &&
                  response.data.boardingPasses[index] !== null
                ) {
                  temBoardingPass[passenger.id] = [];

                  response.data.personNames.map((personName, index) => {
                    if (passenger.personName.raw === personName.raw) {
                      temBoardingPass[passenger.id].push(
                        response.data.boardingPasses[index]
                          .boardingPassByteImage
                      );
                    }
                  return null;
                  });
                }
                return null;
              });
            setBoardingPass(temBoardingPass);
            props.setBoardingPass(temBoardingPass);
            if (forModal) {
              setIsBoardingPassModalOpened(true);
            } else {
              printWithIframe(temBoardingPass[passengerId]);
            }
          }
          setIsEmailModalOpened(false);
          setGettingBoardingPass(false);
        });
    }
  };

  const initBoardingPassForMultiple = () => {
    // if (!boardingPassTracker) {
    if (props.boardingPass === null) {
      let checkInService = new CheckInService();
      checkInService
        .getBoardingPassCustom(props.reservation.recordLocator, props.token)
        .then((response) => {
          if (response.data.statusAPI !== "ERROR" && response.data.boardingPasses.length > 0) {
            var temBoardingPass = {};
            props.reservation.passengers.passenger
              .filter((passenger) => passenger.id in props.activePassengers)
              .map((passenger, index) => {
                if (
                  response.data.boardingPasses[index] !== undefined &&
                  response.data.boardingPasses[index] !== null
                ) {
                  temBoardingPass[passenger.id] = [];

                  response.data.personNames.map((personName, index) => {
                    if (passenger.personName.raw === personName.raw) {
                      temBoardingPass[passenger.id].push(
                        response.data.boardingPasses[index]
                          .boardingPassByteImage
                      );
                    }
                    return null;
                  });

                  // temBoardingPass[passenger.id] =
                  //   response.data.boardingPasses[index].boardingPassByteImage;
                }
                return null;
              });
            setBoardingPass(temBoardingPass);
            props.setBoardingPass(temBoardingPass);
            printAllBoardingPass(true, temBoardingPass);
          }
          // setIsEmailModalOpened(false);
          setGettingBoardingPass(false);
        });
    }
  };

  // const getBoadingPass = () => {
  //   let checkInService = new CheckInService();
  //   var payload = { passengerFlightIds: [], pnr: "" };
  //   props.passengers.map((passenger) => {
  //     // payload.push(passenger.id );
  //     passenger.passengerSegments.passengerSegment.map((passengerSegment) => {
  //       passengerSegment.passengerFlight.map((passengerFlight) => {
  //         payload.passengerFlightIds.push(passengerFlight.id);
  //         return null;
  //       });
  //       return null;
  //     });
  //     return null;
  //   });
  //   payload.pnr = props.reservation.recordLocator;
  //   setConfirming(true);
  //   checkInService
  //     .getBoardingPass(payload, props.token, props.checkInSabreDataRequest)
  //     .then((response) => {
  //       setIsEmailModalOpened(false);
  //       setConfirming(false);
  //     });
  // };
  const Example = ( data ) => {
    return <img
      className="img-fluid"
      style={{ borderRadius: "13px" }}
      alt="Boarding pass"
      src={`data:image/jpeg;base64,${data}`}
    />
  };

  const printWithIframe = (data) => {
    // data = boardingPass[passengerId];
    const iframe = document.createElement("iframe");

    iframe.style.height = 0;
    iframe.style.visibility = "hidden";
    iframe.style.width = 0;

    iframe.setAttribute("srcdoc", "<html><body></body></html>");

    document.body.appendChild(iframe);

    iframe.addEventListener("load", function () {
     var image = document.createElement("img");

      // image.style.maxWidth = "100%";
      var body = iframe.contentDocument.body;
      data.map((boardingPassByteImage) => {
        image = document.createElement("img");
        image.style.maxWidth = "100%";
        image.setAttribute(
          "src",
          "data:image/jpeg;base64," + boardingPassByteImage
        );
        body.appendChild(image);
        return null;
      });
      // image.setAttribute("src", "data:image/jpeg;base64," + data);
      // body.appendChild(image);
      image.addEventListener("load", function () {
        iframe.contentWindow.print();
      });
    });
    iframe.contentWindow.addEventListener("afterprint", function () {
      iframe.parentNode.removeChild(iframe);
    });
  };

  const getBoardingPassModal = () => {
    return isBoardingPassModalOpened ? (
      <Modal
        show={isBoardingPassModalOpened}
        onHide={() => {
          setIsBoardingPassModalOpened(false);
        }}
        centered
        size={"sm"}
        className="check-in-boarding-pass-modal"
      >
        <Modal.Body className="p-0">
          <span
            className="boarding-pass-close-btn d-flex justify-content-center align-items-center"
            onClick={() => setIsBoardingPassModalOpened(false)}
          >
            x
          </span>
          {props.boardingPass[localActivePassengerForBoardingModal].map(boardingPassByteImage => {
            return <Example data={boardingPassByteImage} />
          })}
          {/* <Example data={boardingPass[localActivePassengerForBoardingModal]} /> */}
        </Modal.Body>
      </Modal>
    ) : null;
  };

  const handleBoardingPassClick = (passengerId, forModal) => {
    setLocalActivePassengerForBoardingModal(passengerId);
    // if (!boardingPassTracker) {
    if (props.boardingPass === null) {
      setGettingBoardingPass(true);
      initBoardingPass(passengerId, forModal);
    } else {
      if (!forModal) {
        printWithIframe(props.boardingPass[passengerId]);
      } else {
        setIsBoardingPassModalOpened(true);
      }
    }
  };

  const printAllBoardingPass = (fromInit = false, LocalboardingPass) => {
    if (fromInit) {
      const iframe = document.createElement("iframe");
      var image = document.createElement("img");
      iframe.style.height = 0;
      iframe.style.visibility = "hidden";
      iframe.style.width = 0;
      iframe.setAttribute("srcdoc", "<html><body></body></html>");
      document.body.appendChild(iframe);
      iframe.addEventListener("load", function () {
        var body = iframe.contentDocument.body;
        Object.keys(LocalboardingPass).map((key) => {
          LocalboardingPass[key].map((boardingPassByteImage) => {
            image = document.createElement("img");
            image.style.maxWidth = "100%";
            image.setAttribute(
              "src",
              "data:image/jpeg;base64," + boardingPassByteImage
            );
            body.appendChild(image);
            return null;
          });
          return null;
        });
        image.addEventListener("load", function () {
          iframe.contentWindow.print();
        });
      });
      iframe.contentWindow.addEventListener("afterprint", function () {
        iframe.parentNode.removeChild(iframe);
      });
    } else {
      // if (boardingPassTracker) {
      if (props.boardingPass !== null) {
        const iframe = document.createElement("iframe");
        image = document.createElement("img");
        iframe.style.height = 0;
        iframe.style.visibility = "hidden";
        iframe.style.width = 0;
        iframe.setAttribute("srcdoc", "<html><body></body></html>");
        document.body.appendChild(iframe);
        iframe.addEventListener("load", function () {
          // boardingPass
          // image.setAttribute("src", "data:image/jpeg;base64," + data);
          var body = iframe.contentDocument.body;
          Object.keys(boardingPass).map((key) => {
            props.boardingPass[key].map((boardingPassByteImage) => {
              image = document.createElement("img");
              image.style.maxWidth = "100%";
              image.setAttribute(
                "src",
                "data:image/jpeg;base64," + boardingPassByteImage
              );
              body.appendChild(image);
              return null;
            });
            return null;
          });
          image.addEventListener("load", function () {
            iframe.contentWindow.print();
          });
        });
        iframe.contentWindow.addEventListener("afterprint", function () {
          iframe.parentNode.removeChild(iframe);
        });
      } else {
        setGettingBoardingPass(true);
        initBoardingPassForMultiple();
      }
    }
  };

  const submitFrequentFlyer = (passengerId, memberAirline, memberId) => {
    setUpdatingDoc(true);
    let checkInService = new CheckInService();
    var PayloadFrequentFlyer = {
      passengerId: "",
      loyaltyAccounts: "",
    };
    var loyaltyAccounts = {
      memberAirline: "",
      memberId: "",
    };

    loyaltyAccounts.memberAirline = memberAirline;
    loyaltyAccounts.memberId = memberId;
    PayloadFrequentFlyer.passengerId = passengerId;
    PayloadFrequentFlyer.loyaltyAccounts = [loyaltyAccounts];

    checkInService
      .updateDocument(
        [PayloadFrequentFlyer],
        props.token,
        props.checkInSabreDataRequest
      )
      .then((response) => {
        if (response.data.statusAPI === "SUCCESS") {
          // props.updateReservation(response.data.reservation);
          // updateDocumentTrackerAfterSubmit(response.data.reservation);
        }
        if (response.data.statusAPI === "ERROR") {
          setErrorMessage(
            "Unable to add frequent flyer number at this time. Please try again"
          );
        }
        setUpdatingDoc(false);
      })
      .catch((error) => {
        console.error(error);
        setUpdatingDoc(false);
      });
  };

  const handleFrequentFlyerClick = (passengerId) => {
    var temp = { ...passengerFrequentFlyerIndicator };
    var temp1 = { ...passengerDetailIndicator };
    var temp2 = { ...passengerDocumentIndicator };

    temp[passengerId] = !temp[passengerId];
    temp1[passengerId] = false;
    temp2[passengerId] = false;
    setPassengerFrequentFlyerIndicator(temp);
    setPassengerDocumentIndicator(temp2);
    setPassengerDetailIndicator(temp1);
    setErrorMessage("");
    setFrequentFlyer({ memberAirline: "", memberId: "" });
  };

  const handleChangeSeatClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push("/check-in/seats");
  };

  return (
    <>
      {confirming || gettingBoardingPass || updatingDoc ? <Loading /> : null}
      {getBoardingPassModal()}
      {getEmailModal()}
      <Container className="py-4">
        <h2>{Translation.t("Check_in_confirmation")}</h2>
        <>
          <Notification
            success={true}
            header={"Successfully Checked In Passengers"}
            body={
              "You will be required to present this boarding pass to airport security and our Guest Service agent while boarding the aircraft. Guests with checked baggage should arrive at the airport at least 90 minutes prior to the flight departure time. In addition, all guests must arrive at the boarding gate at least 45 minutes prior to departure."
            }
          />

          <div className="check-in-action-container row mx-0 my-4">
            <div className="col-12 col-md-2 py-4 check-in-action-left-container">
              <h6>{Translation.t("record_locator")}</h6>
              <h4 className="check-in-pnr-value">
                {props.reservation.recordLocator}
              </h4>
            </div>
            <div className="col-12 col-md-10 check-in-trip-options-container mx-0 row d-none d-md-flex">
              <button
                onClick={() => setIsEmailModalOpened(true)}
                className="bk-submit-btn btn-ethiopian btn d-flex align-self-center align-items-center"
              >
                {Translation.t("email_boarding_pass")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn d-flex align-self-center align-items-center mx-2"
                onClick={() => printAllBoardingPass()}
              >
                {Translation.t("print_boarding_pass")}
              </button>
              <button
                className="bk-submit-btn btn-ethiopian btn d-flex align-self-center align-items-center"
                onClick={() => handleChangeSeatClick()}
              >
                {Translation.t("change_seats")}
              </button>
            </div>

            <div className="col-12 col-md-10 d-flex check-in-trip-options-container mx-0 row d-block d-md-none my-3">
              <button
                onClick={() => setTripOption(!tripOption)}
                className="bk-submit-btn btn-ethiopian btn d-flex align-self-center align-items-center w-100 justify-content-between"
              >
                <span className="d-flex">{Translation.t("trip_options")}</span>{" "}
                <FontAwesomeIcon icon={faAngleDown} className="d-flex" />
              </button>
              <OutsideClickHandler
                display={"block"}
                onOutsideClick={() => setTripOption(false)}
              >
                {tripOption ? (
                  <div className="row col-12 filter-list-container mt-5 px-0">
                    <button
                      onClick={() => setIsEmailModalOpened(true)}
                      className="bk-submit-btn btn-ethiopian btn d-flex justify-content-center align-self-center align-items-center col-12"
                    >
                      {Translation.t("email_boarding_pass")}
                    </button>
                    <button
                      className="bk-submit-btn btn-ethiopian btn d-flex justify-content-center align-self-center align-items-center col-12"
                      onClick={() => printAllBoardingPass()}
                    >
                      {Translation.t("print_boarding_pass")}
                    </button>
                    <button
                      className="bk-submit-btn btn-ethiopian btn justify-content-center d-flex align-self-center align-items-center col-12"
                      onClick={() => handleChangeSeatClick()}
                    >
                      {Translation.t("change_seats")}
                    </button>
                  </div>
                ) : <div></div> }
              </OutsideClickHandler>
            </div>
          </div>
          <div className="check-in-action-container row mx-0 my-4">
            <div
              className={
                isPassengerListAccordionOpend
                  ? "check-in-confirm-passenger-container check-in-confirm-passenger-container-active col-12 d-flex py-4"
                  : "check-in-confirm-passenger-container col-12 d-flex py-4"
              }
              onClick={() =>
                setIsPassengerListAccordionOpend(!isPassengerListAccordionOpend)
              }
            >
              <h2 className="d-flex user-select-none">
                {Translation.t("passengers")}
              </h2>
              <span className="d-flex ml-auto px-3 align-items-center fa-angle-customized">
                {isPassengerListAccordionOpend ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </span>
            </div>
            {isPassengerListAccordionOpend ? (
              <div className="col-12 px-0">
                {props.reservation.passengers.passenger
                  .filter((passenger) => passenger.id in props.activePassengers)
                  .map((passenger, index) => {
                    return (
                      <div key={"accordion-" + index}>
                        {index === 0 ? null : (
                          <hr className="checkin-passenger-separator" />
                        )}
                        <div className="row check-in-passenger-container pt-3 mx-0">
                          <div className="col-12 col-md-6">
                            <div className="row mx-0">
                              {confirming ? (
                                <div className="col-12 progress-simpler-check-in-confirmation">
                                  <ProgressSimpler />
                                </div>
                              ) : (
                                <div className="col-12 py-2">
                                  <span className="document-ready-status">
                                    {Translation.t("checked_in")}
                                  </span>
                                </div>
                              )}

                              <div className="col-12 row mx-0 d-flex align-self-center py-2">
                                <span className="check-in-passenger-name-identifier d-flex justify-content-center align-items-center">
                                  {passenger.personName.first[0] +
                                    passenger.personName.last[0]}
                                </span>
                                <span className="d-flex">
                                  <span className="px-2 check-in-passenger-fullname">
                                    {(passenger.personName.prefix !== null
                                      ? passenger.personName.prefix
                                      : "") +
                                      " " +
                                      passenger.personName.first +
                                      " " +
                                      passenger.personName.last}
                                    <span className="d-flex check-in-passenger-type">
                                      {passenger.type.value}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 d-flex justify-content-md-end">
                            <div className="row mx-0">
                              <div className="col-12 d-flex justify-content-md-end">
                                <span className="check-in-confirm-boarding-pass d-flex align-items-center py-2">
                                  {Translation.t("boarding_pass")} :{" "}
                                  <span
                                    className="d-inline-flex boarding-pass-icon"
                                    onClick={() =>
                                      handleBoardingPassClick(
                                        passenger.id,
                                        true
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faIdCard} />{" "}
                                  </span>
                                  <span
                                    className="d-inline-flex boarding-pass-icon"
                                    onClick={() =>
                                      handleBoardingPassClick(
                                        passenger.id,
                                        false
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faPrint} />{" "}
                                  </span>
                                  <span
                                    className="d-inline-flex boarding-pass-icon"
                                    onClick={() => setIsEmailModalOpened(true)}
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} />{" "}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          {passenger.type.value === "INFANT" ? null : (
                            <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 ml-auto d-flex">
                              <button
                                className="bk-submit-btn btn-ethiopian w-100 btn m-2"
                                onClick={() =>
                                  handleFrequentFlyerClick(passenger.id)
                                }
                              >
                                {Translation.t("add_frequent_flyer")}
                              </button>
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            passengerDetailIndicator[passenger.id] ||
                            passengerFrequentFlyerIndicator[passenger.id] ||
                            passengerDocumentIndicator[passenger.id]
                              ? "row mx-0 checkin-confirm-passenger-detail-container checkin-confirm-passenger-detail-container-active px-3"
                              : "row mx-0 checkin-confirm-passenger-detail-container px-3"
                          }
                        >
                          <div className="col pb-4">
                            <span
                              onClick={() => handleDetaileClick(passenger.id)}
                              className="check-in-passenger-detail-buton"
                            >
                              {Translation.t("details_check_in")}{" "}
                              <FontAwesomeIcon
                                icon={
                                  passengerDetailIndicator[passenger.id]
                                    ? faAngleUp
                                    : faAngleDown
                                }
                              />{" "}
                            </span>
                          </div>
                        </div>
                        {passengerDetailIndicator[passenger.id] ? (
                          <div className="row mx-0 pb-5">
                            <div className="col-12 px-4 px-lg-5">
                              <h5>
                                <FontAwesomeIcon icon={faChair} />{" "}
                                {Translation.t("seats")} &amp;
                                {Translation.t("extras")}
                              </h5>
                              <div className="row mx-0 px-3 py-2">
                                <div className="d-flex px-2 check-in-passenger-departure-name">
                                  {AirportFullName.getCityName(
                                    props.reservation.itinerary.itineraryPart[0]
                                      .segment[0].flightDetail[0]
                                      .departureAirport
                                  )}
                                </div>
                                <div className="d-flex px-2 align-self-center">
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="check-in-right-arrow"
                                  />
                                </div>
                                <div className="d-flex px-2 check-in-passenger-arrival-name">
                                  {AirportFullName.getCityName(
                                    props.reservation.itinerary.itineraryPart[0]
                                      .segment[0].flightDetail[0].arrivalAirport
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 px-4 px-lg-5">
                              {props.reservation.itinerary.itineraryPart[0].segment.map(
                                (segment, segmentIndex) => {
                                  return (
                                    <div key={segmentIndex}>
                                      <div className="row mx-0">
                                        <div className="col row mx-0 airport-code-container px-4">
                                          {
                                            segment.flightDetail[0]
                                              .departureAirport
                                          }
                                          <span className="px-2">-</span>
                                          {
                                            segment.flightDetail[0]
                                              .arrivalAirport
                                          }
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          props.reservation.itinerary
                                            .itineraryPart[0].segment.length ===
                                          segmentIndex + 1
                                            ? "row mx-0"
                                            : "row mx-0 pb-3"
                                        }
                                      >
                                        <div className="d-flex pl-4 pr-3 col-3">
                                          {Translation.t("seat")}:
                                          {passenger.passengerSegments
                                            .passengerSegment[segmentIndex]
                                            .passengerFlight[0].seat === null
                                            ? "None"
                                            : passenger.passengerSegments
                                                .passengerSegment[segmentIndex]
                                                .passengerFlight[0].seat.value}
                                        </div>
                                        <div className="d-flex pr-l col-3">
                                          {Translation.t("extras")} :
                                        </div>
                                        {passenger.passengerSegments
                                          .passengerSegment[segmentIndex]
                                          .airExtra !== null ? (
                                          <div className="d-flex col row mx-0">
                                            <h5 className="col-12 px-0">
                                              {Translation.t(
                                                "additional_extras"
                                              )}
                                            </h5>
                                            <ul className="d-flex col-12 px-4">
                                              {passenger.passengerSegments.passengerSegment[
                                                segmentIndex
                                              ].airExtra.map(
                                                (airExtraElement, airExtraElementIndex) => {
                                                  return (
                                                    <li key={"li" + airExtraElementIndex}>
                                                      {airExtraElement.quantity +
                                                        " " +
                                                        airExtraElement
                                                          .ancillary.subCode}
                                                    </li>
                                                  );
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className="col-12 px-4 px-lg-5">
                              <hr />
                            </div>
                            <div className="col-12 col-lg-6 px-4 px-lg-5">
                              <h5>
                                <FontAwesomeIcon icon={faUser} />
                                {Translation.t("passenger_details")}
                              </h5>
                              <h6 className="pl-4 passenger-detail-sub-title">
                                {Translation.t("basic_info")}
                              </h6>
                              <span className="d-flex pl-4">
                                {Translation.t("date_of_birth")}:{" "}
                                {passenger.passengerDocument !== null &&
                                passenger.passengerDocument.length !== 0 ? (
                                  <>
                                    {
                                      passenger.passengerDocument[0].document
                                        .dateOfBirth
                                    }{" "}
                                  </>
                                ) : null}
                              </span>
                              <span className="d-flex pl-4">
                                {Translation.t("gender_check_in")}:
                                {passenger.passengerDocument !== null &&
                                passenger.passengerDocument.length !== 0 ? (
                                  <>
                                    {
                                      passenger.passengerDocument[0].document
                                        .gender
                                    }
                                  </>
                                ) : null}
                              </span>
                            </div>
                            {passenger.passengerDocument !== null &&
                            passenger.passengerDocument.length !== 0 ? (
                              <div className="col-12 col-lg-6 px-4 px-lg-5 mt-3 mt-lg-0">
                                <h5>
                                  <FontAwesomeIcon icon={faFileAlt} />{" "}
                                  {Translation.t("additional_information")}
                                </h5>
                                <h6 className="pl-4 passenger-detail-sub-title">
                                  {Translation.t(
                                    "additional_information_travel_documents"
                                  )}
                                </h6>
                                {passenger.passengerDocument.map(
                                  (passengerDocument, passengerDocumentIndex) => {
                                    return (
                                      <span className="d-flex pl-4" key={"span" + passengerDocumentIndex}>
                                        <span className="travel-document-type-container">
                                          {passengerDocument.document.type +
                                            ": "}
                                        </span>
                                        <span className="travel-document-detail-container pl-2">
                                          {"#" +
                                            passengerDocument.document
                                              .number}{" "}
                                          |{" "}
                                          {passengerDocument.document.type ===
                                          "VISA"
                                            ? "Issued: " +
                                              passengerDocument.document
                                                .issueDate
                                            : "Exp.: " +
                                              passengerDocument.document
                                                .expiryDate}
                                        </span>
                                      </span>
                                    );
                                  }
                                )}
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        {passengerFrequentFlyerIndicator[passenger.id] ? (
                          <>
                            <Row className="mx-0 mt-3">
                              <div className="col-12 px-4">
                                <h5>
                                  {Translation.t(
                                    "add_frequent_flyer_information"
                                  )}
                                </h5>
                                {ErrorMessage !== "" ? (
                                  <h6 className="text-danger">
                                    {ErrorMessage}
                                  </h6>
                                ) : null}
                              </div>
                              <div className="flex-column col-12 col-lg-6 my-2">
                                <div className="col-12 my-2">
                                  <Select
                                    styles={selectStyle}
                                    isSearchable={false}
                                    options={passengerService.getFrequentFlyer()}
                                    placeholder={Translation.t(
                                      "frequent_flyer_programme"
                                    )}
                                    onChange={(event) =>
                                      setFrequentFlyer({
                                        memberAirline: event.value,
                                        memberId: frequentFlyer.memberId,
                                      })
                                    }
                                  ></Select>
                                </div>
                                <div className="col-12 my-2">
                                  <input
                                    className="form-control  widget-input"
                                    placeholder={Translation.t(
                                      "frequent_flyer_numbers"
                                    )}
                                    onChange={(event) =>
                                      setFrequentFlyer({
                                        memberId: event.target.value,
                                        memberAirline:
                                          frequentFlyer.memberAirline,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </Row>
                            <div className="row mx-0 justify-content-end px-4 pb-4">
                              <button
                                className="d-flex bk-submit-btn btn-ethiopian btn mr-2 align-items-center"
                                onClick={() =>
                                  handleFrequentFlyerClick(passenger.id)
                                }
                              >
                                {Translation.t("cancel")}
                              </button>
                              <button
                                className="d-flex bk-submit-btn btn-ethiopian btn ml-2 align-items-center"
                                disabled={
                                  frequentFlyer.memberId === "" ||
                                  frequentFlyer.memberAirline === ""
                                    ? "disabled"
                                    : ""
                                }
                                onClick={() =>
                                  submitFrequentFlyer(
                                    passenger.id,
                                    frequentFlyer.memberAirline,
                                    frequentFlyer.memberId
                                  )
                                }
                              >
                                {Translation.t("save")}
                              </button>
                            </div>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="check-in-action-container row mx-0 my-4">
            <div
              className={
                isFlightSummaryAccordionOpend
                  ? "check-in-confirm-passenger-container check-in-confirm-passenger-container-active col-12 d-flex py-4"
                  : "check-in-confirm-passenger-container col-12 d-flex py-4"
              }
              onClick={() =>
                setFlightSummaryAccordionOpend(!isFlightSummaryAccordionOpend)
              }
            >
              <h2 className="d-flex user-select-none">
                {Translation.t("flight_summary")}
              </h2>
              <span className="d-flex ml-auto px-3 align-items-center fa-angle-customized">
                {isFlightSummaryAccordionOpend ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </span>
            </div>
            {isFlightSummaryAccordionOpend ? (
              <div className="check-in-itinerary-list-container w-100">
                {props.reservation.itinerary.itineraryPart[0].segment.map(
                  (segment, index) => {
                    return (
                      <div key={"itinerary" + index}>
                        {index === 0 ? null : ( // <hr className="iternary-parts-separator-first" />
                          <hr className="iternary-parts-separator" />
                        )}
                        <div className="check-in-itinerary-title-container row mx-0 py-3 px-3">
                          <div className="d-flex px-2 check-in-departure-name">
                            {AirportFullName.getCityName(
                              segment.flightDetail[0].departureAirport
                            )}
                          </div>
                          <div className="d-flex px-2 align-self-center">
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              className="check-in-right-arrow"
                            />
                          </div>
                          <div className="d-flex px-2 check-in-arrival-name">
                            {AirportFullName.getCityName(
                              segment.flightDetail[0].arrivalAirport
                            )}
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row p-2 pt-4">
                            <div className="col-12">
                              <div className="row mx-0">
                                <div className="col-12 d-flex d-md-none mb-3 px-0">
                                  <div className="col px-0">
                                    <span className="check-in-flight-status-indicator ml-auto">
                                      {
                                        segment.flightDetail[0]
                                          .departureFlightScheduleStatus
                                      }
                                    </span>
                                  </div>
                                  <div className="col px-0 justify-content-end d-flex">
                                    <div className="check-in-flight-brand ml-auto">
                                      {segment.fareInfo.brand}
                                    </div>
                                  </div>
                                </div>
                                <div className="col col-md-2">
                                  <div className="row">
                                    <div className="check-in-departure-date">
                                      {moment(
                                        segment.flightDetail[0].departureTime
                                      )
                                        .locale("en")
                                        .format("ddd, MMM DD, YYYY")}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <span className="check-in-departure-time">
                                      {moment(
                                        segment.flightDetail[0].departureTime
                                      )
                                        .locale("en")
                                        .format("HH:mm")}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <span className="check-in-departure-code">
                                      {segment.flightDetail[0].departureAirport}
                                    </span>
                                  </div>
                                </div>
                                <div className="col col-md-1 d-flex align-self-center">
                                  <FontAwesomeIcon
                                    icon={faArrowRight}
                                    className="check-in-right-arrow"
                                  />
                                </div>
                                <div className="col col-md-3">
                                  <div className="row">
                                    <div className="check-in-arrival-date">
                                      {moment(
                                        segment.flightDetail[0].arrivalTime
                                      )
                                        .locale("en")
                                        .format("ddd, MMM DD, YYYY")}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <span className="check-in-arrival-time">
                                      {moment(
                                        segment.flightDetail[0].arrivalTime
                                      )
                                        .locale("en")
                                        .format("HH:mm")}
                                    </span>
                                  </div>
                                  <div className="row">
                                    <span className="check-in-arrival-code">
                                      {segment.flightDetail[0].arrivalAirport}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3 mt-3 mt-md-0">
                                  <div className="row">
                                    <div className="check-in-duration-label">
                                      {Translation.t("duration")}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <span className="check-in-duration">
                                      {toHourMinute(
                                        moment(
                                          segment.flightDetail[0].arrivalTime
                                        ).diff(
                                          moment(
                                            segment.flightDetail[0]
                                              .departureTime
                                          ),
                                          "minutes"
                                        )
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-2 d-none d-md-block">
                                  <div className="row">
                                    <span className="check-in-flight-status-indicator ml-auto">
                                      {
                                        segment.flightDetail[0]
                                          .departureFlightScheduleStatus
                                      }
                                    </span>
                                  </div>
                                  <div className="row">
                                    <div className="check-in-flight-brand ml-auto">
                                      {segment.fareInfo.brand}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row p-2 mx-0">
                            <div className="col-6">
                              <div className="row">
                                <div className="check-in-boarding-label">
                                  {Translation.t("boarding")}
                                </div>
                              </div>
                              <div className="row">
                                <span className="boarding">
                                  {moment(segment.flightDetail[0].boardingTime)
                                    .locale("en")
                                    .format("HH:mm")}
                                </span>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="row">
                                <div className="check-in-terminal-gate-label">
                                  {Translation.t("terminal_gate")}
                                </div>
                              </div>
                              <div className="row">
                                <span className="check-in-terminal-gate">
                                  {(segment.flightDetail[0]
                                    .departureTerminal === null
                                    ? "-"
                                    : segment.flightDetail[0]
                                        .departureTerminal) +
                                    "/" +
                                    (segment.flightDetail[0].departureGate ===
                                    null
                                      ? "-"
                                      : segment.flightDetail[0].departureGate)}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row p-3">
                            <div className="d-flex">
                              {segment.flightDetail[0].operatingAirline ===
                              "ET" ? (
                                <img
                                  src="//www.gstatic.com/flights/airline_logos/35px/ET.png"
                                  alt="ET"
                                />
                              ) : (
                                <img
                                  src="/otherAirline_logo.png"
                                  alt="other airline logo beside ET"
                                  height="40"
                                  width="40"
                                />
                              )}
                            </div>
                            <div className="d-flex pl-2 row mx-0">
                              <div className="col-12 mx-0">
                                {" "}
                                <span className="check-in-flight-name">
                                  {segment.flightDetail[0].operatingAirline +
                                    " " +
                                    segment.flightDetail[0]
                                      .operatingFlightNumber}
                                </span>
                              </div>
                              <div className="col-12 mx-0">
                                {" "}
                                <span className="check-in-flight-equipment">
                                  {segment.flightDetail[0].equipment}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : null}
          </div>
        </>
      </Container>
    </>
  );
};

const mapStateTopProps = (state) => {
  return {
    isMobile: state.global.isMobile,
    reservation: state.checkIn.reservation,
    checkInSabreDataRequest: state.checkIn.checkInSabreDataRequest,
    token: state.searchFlight.token,
    passengers: state.checkIn.reservation.passengers.passenger,
    activePassengers: state.checkIn.activePassengers,
    boardingPass: state.checkIn.boardingPass,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setBoardingPass: (value) =>
      dispatch({
        type: checkInAction.UPDATEBOARDINGPASS,
        boardingPass: value,
      })
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Confirmation);
