import React, { Component } from 'react';
import parse from 'html-react-parser';

import $ from 'jquery';
import ProgressSimpler from '../../../../shared/progress-simple/ProgressSimpler';

class RedirectToUnionPay extends Component
{
    constructor(props)
    {
        super(props);
    }
    componentDidMount()
    {
        
         let redirectPath =this.props.location.state.redirectToPath;
         redirectPath.replace("><", ">\n<");
        //  var data = html;
        //  $("#unionpayview").html(data);
         $("html").append(redirectPath);
    }
render()
{
    
    return(<div className="container text-center"><ProgressSimpler /></div>);
}
}
export default RedirectToUnionPay;