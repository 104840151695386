import React from "react";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import "./ancillary.css";
import { connect } from "react-redux";
import BaggagePage from "./BaggagePage";
import ShoppingCart from "../../shared/shoppingCart/ShoppingCart";
import { Row, Col, Button } from "react-bootstrap";
import { ancillaryAction } from "../../../redux/actions/booking/ancillaryAction";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Notification from "../booking-widget/Notification";

class AncillaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.intializeState();
  }
  intializeState = async () => {
    if (!this.props.bagIsAdded) {
      await this.props.intializeState(this.props.ancillaryList);
    }
  };
  render() {
    return (
      <>
        <BreadCrumb history={this.props.history} />
        <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
          <div className="col-12 col-xl-7 mx-0">
            {this.props.ancillaryList.ancillaryGroups !== undefined ? (
              this.props.ancillaryList.ancillaryGroups.map(
                (ancillary, index) => {
                  if (ancillary.code === "BG") {
                    return (
                      <BaggagePage
                        key={index}
                        ancillaries={ancillary}
                        itinerary={this.props.ancillaryList.itinerary}
                        ancillaryPassengers={
                          this.props.ancillaryList.ancillaryPassengers
                        }
                      />
                    );
                  }
                }
              )
            ) : (
              <div className="mt-4">
                <Notification info={true} header="No Ancillary" body="" />
              </div>
            )}

            <Row className="mt-4">
              <Col sm={12} md={6}></Col>
              <Col sm={12} md={6}>
                <span className="float-right ">
                  <Button
                    variant="danger"
                    className="bk-submit-btn btn-ethiopian mx-2"
                    onClick={() => this.setState({ backPage: true })}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                  <Button
                    variant="danger"
                    className="bk-submit-btn btn-ethiopian mx-2"
                    onClick={() => {
                      if (
                        this.props.isTeleBirrPayment === "true" ||
                        this.props.isTeleBirrPayment === true
                      ) {
                        this.props.history.push("/pay-with-telebirr");
                      } else {
                        // this.props.history.push("/payment");
                        this.props.history.push("/pay-with-telebirr");
                      }
                    }}
                  >
                    {Translation.t("continue")}
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
          <div className="col-12 col-xl-3">
            {!this.props.isMedium && <ShoppingCart />}
          </div>
        </Row>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ancillaryList: state.ancillary.ancillaryList,
    bagIsAdded: state.ancillary.bagIsAdded,
    isMedium: state.global.isMedium,
    isTeleBirrPayment: state.global.isTeleBirrPayment,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    intializeState: (data) =>
      dispatch({
        type: ancillaryAction.SAVEANCILLARYDATA,
        data: data,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AncillaryList);
