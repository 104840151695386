import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import Translation from "i18next";
import AirportSelectStyle from "../../../master-data/booking-widget/airpot-select-style";
import PassengerService from "../../../service/booking/passenger/PassengerService";
import Select from "react-select";
import EditContactInfo from "./EditContactInfo";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import { connect } from "react-redux";

const selectStyle = AirportSelectStyle.desktopStyle;
const passengerService = new PassengerService();

const EditPassenger = (props) => {
  const [showFrequentFlyer, setShowFrequentFlyer] = useState(false);
  const [showSpecialRequest, setShowSpecialRequest] = useState(false);
  const [hasSpecialRequest, setHasSpecialRequest] = useState(false);
  const [hasFrequentFlyer, setHasFrequentFlyer] = useState(false);

  if (
    props.passenger.preferences.specialPreferences.specialRequests.length !== 0
  ) {
    if (!hasSpecialRequest) {
      setHasSpecialRequest(true);
      setShowSpecialRequest(true);
    }
  }
  if (props.passenger.preferences.frequentFlyer.length !== 0) {
    if (!hasFrequentFlyer) {
      setHasFrequentFlyer(true);
      setShowFrequentFlyer(true);
    }
  }
  return (
    <>
      <Card className="my-3">
        <Card.Header className="passanger-info-card-header passenger-header-sticky">
          <h5>
            {props.passenger.passengerDetails.firstName +
              " " +
              props.passenger.passengerDetails.lastName}{" "}
            ({props.passenger.passengerInfo.type})
          </h5>
        </Card.Header>
        <Card.Body className="passanger-info-card-body">
          {props.passenger.passengerInfo.type === "ADT" && (
            <Row>
              <Col xs={12} md={6}>
                <p>
                  {Translation.t("title")}{" "}
                  <b className="mx-3">
                    {props.passenger.passengerDetails.prefix}
                  </b>
                </p>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12} md={6}>
              <p>
                {Translation.t("first_name")}{" "}
                <b className="mx-3">
                  {props.passenger.passengerDetails.firstName}
                </b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <p>
                {Translation.t("middle_name")}{" "}
                <b className="mx-3">
                  {props.passenger.passengerDetails.middleName}
                </b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <p>
                {Translation.t("last_name")}{" "}
                <b className="mx-3">
                  {props.passenger.passengerDetails.lastName}
                </b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <p>
                Date of Birth (Adult 12+) (YYYY-MM-DD){" "}
                <b className="mx-3">
                  {props.passenger.passengerInfo.dateOfBirth}
                </b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <p>
                {Translation.t("gender")}{" "}
                <b className="mx-3">{props.passenger.passengerInfo.gender}</b>
              </p>
            </Col>
          </Row>
          {props.index === 0 && (
            <EditContactInfo
              emails={props.passenger.passengerInfo.emails[0]}
              phones={props.passenger.passengerInfo.phones}
            />
          )}
          <h3 className="m-3">{Translation.t("additional_info")}</h3>
          <Row>
            <Col xs={12} sm={12} md={6}>
              <span className="add_flight_btn my-2">
                {showFrequentFlyer ? (
                  <span>
                    <Button
                      className="btn-danger text-white  mr-2 btn-sm"
                      style={{ minWidth: "27.58px" }}
                      onClick={() => setShowFrequentFlyer(!showFrequentFlyer)}
                    >
                      -
                    </Button>{" "}
                    <label>{Translation.t("remove_frequent_flyer")}</label>
                  </span>
                ) : (
                  <span>
                    <Button
                      className="btn-success std-et-bg-color text-white btn mr-2 btn-sm"
                      style={{ minWidth: "27.58px" }}
                      onClick={() => setShowFrequentFlyer(!showFrequentFlyer)}
                    >
                      +
                    </Button>{" "}
                    <label>{Translation.t("add_frequent_flyer")}</label>
                  </span>
                )}
              </span>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <span className="add_flight_btn my-2">
                {showSpecialRequest ? (
                  <span>
                    <Button
                      className="btn-danger text-white  mr-2 btn-sm"
                      style={{ minWidth: "27.58px" }}
                      onClick={() => setShowSpecialRequest(!showSpecialRequest)}
                    >
                      -
                    </Button>{" "}
                    <label>{Translation.t("remove_special_request")}</label>
                  </span>
                ) : (
                  <span>
                    <Button
                      className="btn-success std-et-bg-color text-white btn mr-2 btn-sm"
                      style={{ minWidth: "27.58px" }}
                      onClick={() => setShowSpecialRequest(!showSpecialRequest)}
                    >
                      +
                    </Button>{" "}
                    <label>{Translation.t("add_special_request")}</label>
                  </span>
                )}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showFrequentFlyer || showSpecialRequest ? (
        <Card>
          <Card.Body>
            {showFrequentFlyer ? (
              <Row>
                <div className="col-12">
                  <h5>{Translation.t("frequent_flyer")}</h5>
                  <p>{Translation.t("frequent_flyer_message")}</p>
                </div>
                <div className="col-12 col-lg-6 my-2">
                  <Select
                    styles={selectStyle}
                    isSearchable={false}
                    options={passengerService.getFrequentFlyer()}
                    placeholder={Translation.t("frequent_flyer_programme")}
                    defaultValue={
                      hasFrequentFlyer
                        ? passengerService
                            .getFrequentFlyer()
                            .filter(
                              (x) =>
                                x.value ===
                                props.passenger.preferences.frequentFlyer[0]
                                  .airline
                            )
                        : null
                    }
                    onChange={(value) =>
                      props.updateAdditionalInfo(
                        value.value,
                        "frequentFlyerProgramme",
                        props.passenger["@id"]
                      )
                    }
                  ></Select>
                </div>
                <div className="col-12 col-lg-6 my-2">
                  <input
                    className="form-control  widget-input"
                    placeholder={Translation.t("frequent_flyer_numbers")}
                    id={
                      "edit-passenger-frequent-number-" + props.passenger["@id"]
                    }
                    defaultValue={
                      hasFrequentFlyer
                        ? passengerService
                            .getFrequentFlyer()
                            .filter(
                              (x) =>
                                x.value ===
                                props.passenger.preferences.frequentFlyer[0]
                                  .number
                            )
                        : null
                    }
                    onChange={(value) => {
                      var tempValue = document.getElementById(
                        "edit-passenger-frequent-number-" +
                          props.passenger["@id"]
                      ).value;
                      props.updateAdditionalInfo(
                        tempValue,
                        "frequentFlyerNumber",
                        props.passenger["@id"]
                      );
                    }}
                  />
                </div>
              </Row>
            ) : null}
            {showSpecialRequest ? (
              <Row>
                <div className="col-12 mt-4">
                  <h5>
                    {Translation.t("special_requests")}{" "}
                    {hasSpecialRequest && (
                      <Button
                        variant="danger"
                        className="bk-submit-btn btn-ethiopian mx-2 my-3"
                        onClick={() => {
                          props.updateAdditionalInfo(
                            "",
                            "specialRequests",
                            props.passenger["@id"]
                          );
                          setHasSpecialRequest(false);
                          setShowSpecialRequest(false);
                        }}
                      >
                        Cancel Special Request
                      </Button>
                    )}
                  </h5>
                </div>
                <div className="col-12 col-lg-6">
                  <Select
                    styles={selectStyle}
                    isSearchable={false}
                    options={passengerService.getSpecialRequests()}
                    placeholder={Translation.t("special_requests")}
                    defaultValue={passengerService
                      .getSpecialRequests()
                      .filter(
                        (x) =>
                          x.value ===
                          props.passenger.preferences.specialPreferences
                            .specialRequests[0]
                      )}
                    onChange={(value) =>
                      props.updateAdditionalInfo(
                        value.value,
                        "specialRequests",
                        props.passenger["@id"]
                      )
                    }
                  ></Select>
                </div>
              </Row>
            ) : null}
          </Card.Body>
        </Card>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAdditionalInfo: (value, updateType, passengerId) =>
      dispatch({
        type: passengerAction.UPDATEPASSENGERADDITIONALINFO,
        value: value,
        updateType: updateType,
        passengerId: passengerId,
      }),
  };
};
export default connect(null, mapDispatchToProps)(EditPassenger);
