import PassengerForm from "../../../model/passenger/passengerForm";
import Translation from "i18next";
import moment from "moment";
export default class PassengerService {
  addPassenger = (adultNo, childNo, infantNo) => {
    var passengerList = [];
    var passengerAdult = 1;
    var passengerChild = 1;
    var passengerInfant = 1;
    for (var i = 0; i < adultNo; i++) {
      var adultPassenger = new PassengerForm();
      adultPassenger.passengerIndex = passengerAdult;
      adultPassenger.passengerInfo.type = "adult";
      passengerList.push(adultPassenger);
      passengerAdult++;
    }
    for (var j = 0; j < childNo; j++) {
      var childPassenger = new PassengerForm();
      childPassenger.passengerIndex = passengerChild;
      childPassenger.passengerInfo.type = "child";
      passengerChild++;
      passengerList.push(childPassenger);
    }
    for (var k = 0; k < infantNo; k++) {
      var infantPassenger = new PassengerForm();
      infantPassenger.passengerIndex = passengerInfant;
      infantPassenger.passengerInfo.type = "infant";
      passengerList.push(infantPassenger);
      passengerInfant++;
    }
    return passengerList;
  };
  updatePassenger = (state, index, value, objName, inputName, updateGender) => {
    var passengerTempList = [...state.passengerList];
    passengerTempList[index][objName][inputName] = value;
    if (updateGender) {
      if (value.value === "MR") {
        passengerTempList[index].passengerInfo.gender = {
          value: "MALE",
          label: Translation.t("male"),
        };
        passengerTempList[index].validation.isGenderValid = true;
      } else if (value.value === "MRS" || value.value === "MS") {
        passengerTempList[index].passengerInfo.gender = {
          value: "FEMALE",
          label: Translation.t("female"),
        };
        passengerTempList[index].validation.isGenderValid = true;
      } else {
        passengerTempList[index].passengerInfo.gender = null;
      }
    }

    if (
      passengerTempList[index].passengerInfo.birthOfYear !== null &&
      passengerTempList[index].passengerInfo.birthOfMonth !== null &&
      passengerTempList[index].passengerInfo.birthOfDay !== null
    ) {
      var birthDateValidation = this.validateBirthDate(
        passengerTempList[index]
      );
      passengerTempList[index].validation.isYearValid = birthDateValidation;
      passengerTempList[index].validation.isMonthValid = birthDateValidation;
      passengerTempList[index].validation.isDayValid = birthDateValidation;
    }
    return passengerTempList;
  };
  checkPassengeList = (state) => {
    var passengerList = [...state.passengerList];
    var isFormValid = true;
    passengerList.forEach((passenger, index) => {
      if (passenger.passengerDetails.firstName === null) {
        passenger.validation.isFirstNameValid = false;
        isFormValid = false;
      }
      if (passenger.passengerDetails.lastName === null) {
        passenger.validation.isLastNameValid = false;
        isFormValid = false;
      }
      if (
        passenger.passengerDetails.prefix === null &&
        passenger.passengerInfo.type === "adult"
      ) {
        passenger.validation.isprefixValid = false;
        isFormValid = false;
      }

      if (passenger.passengerInfo.gender === null) {
        passenger.validation.isGenderValid = false;
        isFormValid = false;
      }
      if (
        passenger.passengerInfo.birthOfYear !== null &&
        passenger.passengerInfo.birthOfMonth !== null &&
        passenger.passengerInfo.birthOfDay !== null
      ) {
        var passengerBirthDateValidation = this.validateBirthDate(passenger);
        if (!passengerBirthDateValidation) {
          passenger.validation.isYearValid = false;
          passenger.validation.isMonthValid = false;
          passenger.validation.isDayValid = false;
          isFormValid = false;
        }
      } else {
        if (passenger.passengerInfo.birthOfYear === null) {
          passenger.validation.isYearValid = false;
          isFormValid = false;
        }
        if (passenger.passengerInfo.birthOfMonth === null) {
          passenger.validation.isMonthValid = false;
          isFormValid = false;
        }
        if (passenger.passengerInfo.birthOfDay === null) {
          passenger.validation.isDayValid = false;
          isFormValid = false;
        }
      }

      if (
        (passenger.contact.phone === null || passenger.contact.phone === "+") &&
        index === 0
      ) {
        passenger.validation.isPhoneValid = false;
        isFormValid = false;
      }
      if (passenger.contact.email === null && index === 0) {
        passenger.validation.isEmailValid = false;
        isFormValid = false;
      }
    });
    return {
      passengerList: passengerList,
      isFormValid: isFormValid,
    };
  };
  getTitleList = () => {
    return [
      {
        value: "MR",
        label: Translation.t("mr"),
      },
      {
        value: "MRS",
        label: Translation.t("mrs"),
      },
      {
        value: "MS",
        label: Translation.t("ms"),
      },
      {
        value: "DR",
        label: Translation.t("dr"),
      },
      {
        value: "PROF",
        label: Translation.t("prof"),
      },
    ];
  };
  getWeightCategoryForChildList = () => {
    return [
      {
        value: "ADULT_MALE",
        label: Translation.t("male"),
      },
      {
        value: "ADULT_MALE",
        label: Translation.t("female"),
      },
      {
        value: "CHILD",
        label: Translation.t("CHILD"),
      },
    ];
  };
  getWeightCategoryForInfantList = () => {
    return [
      {
        value: "CHILD",
        label: Translation.t("CHILD"),
      },
      {
        value: "INFANT",
        label: Translation.t("INFANT"),
      },
    ];
  };
  getGenderList = () => {
    return [
      {
        value: "MALE",
        label: Translation.t("male"),
      },
      {
        value: "FEMALE",
        label: Translation.t("female"),
      },
    ];
  };
  getFrequentFlyer = () => {
    return [
      { value: "ET", label: Translation.t("ET") },
      { value: "AC", label: Translation.t("AC") },
      { value: "NZ", label: Translation.t("NZ") },
      { value: "O6", label: Translation.t("O6") },
      { value: "NH", label: Translation.t("NH") },
      { value: "OZ", label: Translation.t("OZ") },
      { value: "KP", label: Translation.t("KP") },
      { value: "CM", label: Translation.t("CM") },
      { value: "MS", label: Translation.t("MS") },
      { value: "SK", label: Translation.t("SK") },
      { value: "AI", label: Translation.t("AI") },
      { value: "SQ", label: Translation.t("SQ") },
      { value: "AV", label: Translation.t("AV") },
      { value: "UA", label: Translation.t("UA") },
      { value: "A3", label: Translation.t("A3") },
      { value: "LH", label: Translation.t("LH") },
      { value: "TK", label: Translation.t("TK") },
      { value: "CA", label: Translation.t("CA") },
      { value: "TG", label: Translation.t("TG") },
      { value: "TP", label: Translation.t("TP") },
      { value: "SA", label: Translation.t("SA") },
    ];
  };
  getMealPreference = () => {
    return [
      { value: "AVML", label: "ASIAN VEGETARIAN MEAL" },
      { value: "BLML", label: "BLAND MEAL" },
      { value: "DBML", label: "DIABETIC MEAL" },
      { value: "FPML", label: "FRUIT PLATTER" },
      { value: "GFML", label: "GLUTEN-FREE MEAL" },
      { value: "HNML", label: "HINDU (NON VEGETARIAN) MEAL" },
      { value: "KSML", label: "KOSHER MEAL" },
      { value: "LCML", label: "LOW CALORIE MEAL" },
      { value: "LFML", label: "LOW CHOLESTEROL/LOW FAT MEAL" },
      { value: "LSML", label: "LOW SODIUM, NO SALT ADDED" },
      { value: "MOML", label: "MOSLEM MEAL" },
      { value: "NLML", label: "NON LACTOSE MEAL" },
      { value: "RVML", label: "RAW VEGETARIAN MEAL" },
      { value: "SFML", label: "SEA FOOD MEAL" },
    ];
  };
  getSpecialRequests = () => {
    return [
      { value: "BLND", label: Translation.t("BLND") },
      { value: "DEAF", label: Translation.t("DEAF") },
      { value: "MAAS", label: Translation.t("MAAS") },
      { value: "MEDA", label: Translation.t("MEDA") },
      {
        value: "WCHR",
        label: Translation.t("WCHR"),
      },
      {
        value: "WCHS",
        label: Translation.t("WCHS"),
      },
      {
        value: "WCHC",
        label: Translation.t("WCHC"),
      },
    ];
  };
  validatePassenger = (state, index, domain, type) => {
    var passengerList = [...state.passengerList];
    var passenger = passengerList[index];
    if (domain === "prefix") {
      if (type === "onBlur") {
        if (passenger.passengerDetails.prefix === null) {
          passenger.validation.isprefixValid = false;
        }
      } else {
        passenger.validation.isprefixValid = true;
      }
    }
    if (domain === "year" || domain === "month" || domain === "day") {
      if (
        passengerList[index].passengerInfo.birthOfYear !== null &&
        passengerList[index].passengerInfo.birthOfMonth !== null &&
        passengerList[index].passengerInfo.birthOfDay !== null
      ) {
        var birthDateValidation = this.validateBirthDate(passengerList[index]);
        passengerList[index].validation.isYearValid = birthDateValidation;
        passengerList[index].validation.isMonthValid = birthDateValidation;
        passengerList[index].validation.isDayValid = birthDateValidation;
      } else {
        if (domain === "year") {
          if (type === "onBlur") {
            if (passenger.passengerInfo.birthOfYear === null) {
              passenger.validation.isYearValid = false;
            }
          } else {
            passenger.validation.isYearValid = true;
          }
        } else if (domain === "month") {
          if (type === "onBlur") {
            if (passenger.passengerInfo.birthOfMonth === null) {
              passenger.validation.isMonthValid = false;
            }
          } else {
            passenger.validation.isMonthValid = true;
          }
        } else {
          if (type === "onBlur") {
            if (passenger.passengerInfo.birthOfDay === null) {
              passenger.validation.isDayValid = false;
            }
          } else {
            passenger.validation.isDayValid = true;
          }
        }
      }
    }

    if (domain === "gender") {
      if (type === "onBlur") {
        if (passenger.passengerInfo.gender === null) {
          passenger.validation.isGenderValid = false;
        }
      } else {
        passenger.validation.isGenderValid = true;
      }
    }
    return passengerList;
  };
  passengerDataForApi = (passengerList, cookieSabreDataRequest) => {
    var passengersListForApi = {
      passengers: [],
      contact: {
        emails: [],
        operationalUpdatesEnabled: false,
        phones: [
          {
            type: "MOBILE",
            countryCode: null,
            areaCode: null,
            number: null,
            extension: null,
          },
        ],
      },
      cookieSabreDataRequest: cookieSabreDataRequest,
    };
    passengerList.forEach((passenger, index) => {
      var passengerType;
      if (passenger.passengerInfo.type === "adult") {
        passengerType = "ADT";
      } else if (passenger.passengerInfo.type === "child") {
        passengerType = "CHD";
      } else {
        passengerType = "INF";
      }
      var passengerForApi = {};
      if (passengerType === "ADT") {
        passengerForApi = {
          "@id": (index + 1).toString(),
          passengerIndex: index + 1,
          passengerDetails: {
            firstName: passenger.passengerDetails.firstName,
            lastName: passenger.passengerDetails.lastName,
            prefix: passenger.passengerDetails.prefix.value,
            middleName: passenger.passengerDetails.middleName,
            maidenName: null,
            suffix: null,
          },
          passengerInfo: {
            dateOfBirth:
              passenger.passengerInfo.birthOfYear.value +
              "-" +
              passenger.passengerInfo.birthOfMonth.value +
              "-" +
              passenger.passengerInfo.birthOfDay.value,
            gender: passenger.passengerInfo.gender.value,
            redressNumber: null,
            knownTravelerNumber: null,
            type: passengerType,
            emails: [],
            phones: [],
            address: null,
          },
          preferences: {
            specialPreferences: {
              mealPreference: null,
              seatPreference: null,
              specialRequests: [],
            },
            frequentFlyer: [],
          },
          documentInfo: null,
          visaInfo: null,
        };
      } else {
        passengerForApi = {
          "@id": (index + 1).toString(),
          passengerIndex: index + 1,
          passengerDetails: {
            firstName: passenger.passengerDetails.firstName,
            lastName: passenger.passengerDetails.lastName,
            middleName: passenger.passengerDetails.middleName,
            maidenName: null,
            suffix: null,
          },
          passengerInfo: {
            dateOfBirth:
              passenger.passengerInfo.birthOfYear.value +
              "-" +
              passenger.passengerInfo.birthOfMonth.value +
              "-" +
              passenger.passengerInfo.birthOfDay.value,
            gender: passenger.passengerInfo.gender.value,
            redressNumber: null,
            knownTravelerNumber: null,
            type: passengerType,
            emails: [],
            phones: [],
            address: null,
          },
          preferences: {
            specialPreferences: {
              mealPreference: null,
              seatPreference: null,
              specialRequests: [],
            },
            frequentFlyer: [],
          },
          documentInfo: null,
          visaInfo: null,
        };
      }

      if (
        passenger.preferences.frequentFlyerProgramme !== null &&
        passenger.preferences.frequentFlyerNumber !== null
      ) {
        passengerForApi.preferences.frequentFlyer.push({
          airline: passenger.preferences.frequentFlyerProgramme.value,
          number: passenger.preferences.frequentFlyerNumber,
        });
      }
      if (
        passenger.preferences.specialRequests !== null &&
        passenger.preferences.specialRequests !== undefined
      ) {
        passengerForApi.preferences.specialPreferences.specialRequests.push(
          passenger.preferences.specialRequests.value
        );
      }
      if (
        passenger.preferences.mealPreference !== null &&
        passenger.preferences.mealPreference !== undefined
      ) {
        passengerForApi.preferences.specialPreferences.mealPreference =
          passenger.preferences.mealPreference.value;
      }
      if (index === 0) {
        passengersListForApi.contact.emails.push(passenger.contact.email);
        let phoneTemp = passenger.contact.phone.split(" ");
        passengersListForApi.contact.phones[0].countryCode =
          phoneTemp[0].replace("+", "");

        passengersListForApi.contact.phones[0].areaCode =
          passenger.contact.phone.substring(
            passenger.contact.phone.indexOf("(") + 1,
            passenger.contact.phone.indexOf(")")
          );

        phoneTemp.shift();
        passengersListForApi.contact.phones[0].number = phoneTemp
          .join("")
          .replace(
            "(" + passengersListForApi.contact.phones[0].areaCode + ")",
            ""
          )
          .replace("-", "");
        passengersListForApi.contact.operationalUpdatesEnabled =
          passenger.contact.recieveEmail;
        passengerForApi.passengerInfo.emails.push(passenger.contact.email);
      }
      passengersListForApi.passengers.push(passengerForApi);
    });
    return passengersListForApi;
  };
  removeValidation = (state) => {
    var passengerList = [...state.passengerList];
    passengerList.forEach((passenger) => {
      passenger.validation = {
        isFirstNameValid: true,
        isLastNameValid: true,
        isprefixValid: true,
        isYearValid: true,
        isMonthValid: true,
        isDayValid: true,
        isGenderValid: true,
        isPhoneValid: true,
        isEmailValid: true,
      };
    });
    return passengerList;
  };
  updateSavedProfile = (state, savedProfile) => {
    var passengerList = [...state.passengerList];
    passengerList[0] = savedProfile;
    return passengerList;
  };
  fillPassengerFrom = (state, userProfile) => {
    var tempPassenger = [...state.passengerList];

    try {
      tempPassenger[0].passengerDetails.firstName =
        userProfile.userDetails.firstName;
      tempPassenger[0].passengerDetails.lastName =
        userProfile.userDetails.lastName;
      tempPassenger[0].passengerDetails.prefix = {
        label: userProfile.userDetails.prefix,
        value: userProfile.userDetails.prefix,
      };
      tempPassenger[0].passengerDetails.middleName =
        userProfile.userDetails.middleName;

      // tempPassenger[0].preferences.frequentFlyerProgramme=userProfile.firstName;
      // tempPassenger[0].preferences.frequentFlyerNumber=userProfile.firstName;

      tempPassenger[0].contact.email = userProfile.userInfo.emails[0];
      if (userProfile.userInfo.phones[0].areaCode !== "") {
        tempPassenger[0].contact.phone =
          "+" +
          userProfile.userInfo.phones[0].countryCode +
          " (" +
          userProfile.userInfo.phones[0].areaCode +
          ") " +
          " " +
          userProfile.userInfo.phones[0].number;
      } else {
        tempPassenger[0].contact.phone =
          "+" +
          userProfile.userInfo.phones[0].countryCode +
          " " +
          userProfile.userInfo.phones[0].number;
      }
      var dateOfBirth = userProfile.userInfo.dateOfBirth.split("-");
      tempPassenger[0].passengerInfo.birthOfYear = {
        label: dateOfBirth[0],
        value: dateOfBirth[0],
      };
      tempPassenger[0].passengerInfo.birthOfMonth = {
        label: dateOfBirth[1],
        value: dateOfBirth[1],
      };
      tempPassenger[0].passengerInfo.birthOfDay = {
        label: dateOfBirth[2],
        value: dateOfBirth[2],
      };
      tempPassenger[0].passengerInfo.gender = {
        label: userProfile.userInfo.gender,
        value: userProfile.userInfo.gender,
      };
    } catch (error) {
      console.log(error);
    }

    return tempPassenger;
  };
  removeFillPassengerFrom = (state) => {
    var tempPassenger = [...state.passengerList];
    var newAdultPassenger = new PassengerForm();
    newAdultPassenger.passengerIndex = 0;
    newAdultPassenger.passengerInfo.type = "adult";
    tempPassenger[0] = newAdultPassenger;
    return tempPassenger;
  };

  updatePassengerContact = (state, value, type, passengerId) => {
    var passengers = [...state.passengers];
    if (type === "email") {
      passengers[0].passengerInfo.emails = value;
    } else if (type === "phone1") {
      try {
        let phoneTemp1 = value.split(" ");
        passengers[0].passengerInfo.phones[0].countryCode =
          phoneTemp1[0].replace("+", "");
        passengers[0].passengerInfo.phones[0].areaCode = value.substring(
          value.indexOf("(") + 1,
          value.indexOf(")")
        );
        phoneTemp1.shift();
        passengers[0].passengerInfo.phones[0].number = phoneTemp1
          .join("")
          .replace(
            "(" + passengers[0].passengerInfo.phones[0].areaCode + ")",
            ""
          )
          .replace("-", "");
      } catch (error) {
        console.log(error);
      }
    } else if (type === "phone2") {
      try {
        let phoneTemp2 = value.split(" ");
        if (passengers[0].passengerInfo.phones.length === 1) {
          var tempPhoneModel = {
            type: "HOME",
            countryCode: "",
            areaCode: "",
            number: "",
            extension: "",
          };
          tempPhoneModel.countryCode = phoneTemp2[0].replace("+", "");
          tempPhoneModel.areaCode = value.substring(
            value.indexOf("(") + 1,
            value.indexOf(")")
          );
          phoneTemp2.shift();
          tempPhoneModel.number = phoneTemp2
            .join("")
            .replace(
              "(" + passengers[0].passengerInfo.phones[0].areaCode + ")",
              ""
            )
            .replace("-", "");
          passengers[0].passengerInfo.phones.push(tempPhoneModel);
        } else {
          passengers[0].passengerInfo.phones[1].countryCode =
            phoneTemp2[0].replace("+", "");
          passengers[0].passengerInfo.phones[1].areaCode = value.substring(
            value.indexOf("(") + 1,
            value.indexOf(")")
          );
          phoneTemp2.shift();
          passengers[0].passengerInfo.phones[1].number = phoneTemp2
            .join("")
            .replace(
              "(" + passengers[0].passengerInfo.phones[0].areaCode + ")",
              ""
            )
            .replace("-", "");
        }
      } catch (error) {
        console.log(error);
      }
    }
    return passengers;
  };
  updatePassengerAdditionalInfo = (state, value, type, passengerId) => {
    var passengers = [...state.passengers];
    var passenger = passengers.filter((x) => x["@id"] === passengerId)[0];
    if (type === "frequentFlyerProgramme" || type === "frequentFlyerNumber") {
      if (passenger.preferences.frequentFlyer.length === 0) {
        var tempFrequentFlyer = {
          airline: "",
          number: "",
        };
        if (type === "frequentFlyerProgramme") {
          tempFrequentFlyer.airline = value;
        } else {
          tempFrequentFlyer.number = value;
        }
        passenger.preferences.frequentFlyer.push(tempFrequentFlyer);
      } else {
        if (type === "frequentFlyerProgramme") {
          passenger.preferences.frequentFlyer[0].airline = value;
        } else {
          passenger.preferences.frequentFlyer[0].number = value;
        }
      }
    } else if (type === "specialRequests") {
      if (value === "") {
        passenger.preferences.specialPreferences.specialRequests = [];
      } else {
        if (
          passenger.preferences.specialPreferences.specialRequests.length === 0
        ) {
          passenger.preferences.specialPreferences.specialRequests.push(value);
        } else {
          passenger.preferences.specialPreferences.specialRequests[0] = value;
        }
      }
    }
    return passengers;
  };
  iOS = () => {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  validateBirthDate = (passenger) => {
    if (this.iOS()) {
      return true;
    }
    var dateString =
      passenger.passengerInfo.birthOfYear.value +
      "-" +
      passenger.passengerInfo.birthOfMonth.value +
      "-" +
      passenger.passengerInfo.birthOfDay.value;
    let newDate = moment(dateString).format("YYYY-M-D");
    var age = moment().diff(newDate, "years", true);
    if (passenger.passengerInfo.type === "adult") {
      if (age < 12 || isNaN(age) || dateString !== newDate.toString()) {
        return false;
      } else {
        return true;
      }
    } else if (passenger.passengerInfo.type === "child") {
      if (
        age > 12 ||
        age < 2 ||
        isNaN(age) ||
        dateString !== newDate.toString()
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        age > 2 ||
        age <= 0 ||
        isNaN(age) ||
        dateString !== newDate.toString()
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
}
