import ItineraryPart from "../itineraryPart/ItineraryPart";
import Passenger from "../passenger/passenger";

export default class RecentSearch{
    params=      {
        promoCodes: null,
        cabinClass: null,
        passengers: new Passenger().params,
        itineraryParts: [new ItineraryPart().params],
        journeyType:null,
        id: null
      }
}