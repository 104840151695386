import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faChair,
  faCheckCircle,
  faHome,
  faMoneyCheck,
  faShoppingCart,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Translation from "i18next";
import { Redirect } from "react-router";
import OutsideClickHandler from 'react-outside-click-handler';
import ManageTripShoppingCart from "./ManageTripShoppingCart";

class ManageTripBreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditFlight: false,
      currentBreadCrumb: 2,
      redirect: false,
      redirectTo: null,
      showDropDown: false,
      showShoppingCart:false,
      showShoppingCartButton:false
    };
  }

  componentDidMount = () => {
    var url = window.location.pathname;
    if (url.includes("myb/edit-ancillaries")) {
        this.setState({showShoppingCartButton:true,currentBreadCrumb: 2  });
    } else if (url.includes("myb/seat-selection")) {
      this.setState({showShoppingCartButton:true,currentBreadCrumb: 2  });
    } else if (url.includes("myb/payment")) {
      this.setState({ currentBreadCrumb: 3, showShoppingCartButton: true });
    }

  };
  toggleShoppingCart=()=>{
    this.setState({ showShoppingCart: !this.state.showShoppingCart });

  }

  changePage = (goTo) => {
    if (goTo === "extras" && this.state.currentBreadCrumb > 2) {
      this.setState({ redirect: true, redirectTo: "/myb/edit-ancillaries" });
    } else if (goTo === "seat" && this.state.currentBreadCrumb > 2) {
      this.setState({ redirect: true, redirectTo: "/myb/seat-selection" });
    }
  };
  renderActivePage(page) {
    switch (page) {
      case 2:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faSuitcase} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
                            {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Extras
          </div>
        );
      case 3:
        return (
          <div className="bread-crumb-mobile-item">
            <FontAwesomeIcon icon={faChair} size="1x" />
            {"  "}
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6{" "}
                            {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Seat Selection
          </div>
        );
        case 4:
          return (
            <div className="bread-crumb-mobile-item">
            <span className="float-right">
              {this.state.currentBreadCrumb} of 6
            </span>
            <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
            {"  "}
            <span className="float-right">
                            {this.state.showDropDown ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </span>
            Payment
          </div>
          );

    }
  }
  renderBreadcrumbMediumScreen() {
    return (
      <>
        <div className="parent-div">
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ showDropDown: false });
            }}
          >
            <div
              class="bread-crumb-mobile-dropdown"
              onClick={() => {
                this.setState({ showDropDown: !this.state.showDropDown });
              }}
            >
              {this.renderActivePage(this.state.currentBreadCrumb)}

              <div
                className="bread-crumb-mobile-dropdown-list"
                style={
                  this.state.showDropDown
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="bread-crumb-mobile-item-md">
                  <p className="breadcrumb-custom-disabled btn-not-allowd">
                    {" "}
                    <FontAwesomeIcon icon={faHome} size="1x" /> {"  "}Home
                  </p>
                </div>
                {this.props.currentPage === "editAncillaries" ? (
                  <div className="bread-crumb-mobile-item-md">
                    <p
                      className={
                        this.state.currentBreadCrumb === 2
                          ? "breadcrumb-custom-current"
                          : this.state.currentBreadCrumb > 2
                          ? "breadcrumb-custom-disabled previsous"
                          : "breadcrumb-custom-disabled btn-not-allowd"
                      }
                      onClick={this.changePage.bind(this, "extra")}
                    >
                      <span>
                        {this.state.currentBreadCrumb > 2 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faSuitcase} size="1x" />
                        )}
                      </span>{" "}
                      {"  "}
                      Extras
                    </p>
                  </div>
                ) : (
                  <div className="bread-crumb-mobile-item-md">
                    <p
                      className={
                        this.state.currentBreadCrumb === 2
                          ? "breadcrumb-custom-current"
                          : this.state.currentBreadCrumb > 2
                          ? "breadcrumb-custom-disabled previsous"
                          : "breadcrumb-custom-disabled btn-not-allowd"
                      }
                      onClick={this.changePage.bind(this, "seat")}
                    >
                      <span>
                        {this.state.currentBreadCrumb > 2 ? (
                          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        ) : (
                          <FontAwesomeIcon icon={faChair} size="1x" />
                        )}
                      </span>
                      Seat Selection
                    </p>
                  </div>
                )}

                <div className="bread-crumb-mobile-item-md">
                  <p
                    className={
                      this.state.currentBreadCrumb === 3
                        ? "breadcrumb-custom-current"
                        : this.state.currentBreadCrumb > 3
                        ? "breadcrumb-custom-disabled previsous"
                        : "breadcrumb-custom-disabled btn-not-allowd"
                    }
                  >
                    <span>
                      {this.state.currentBreadCrumb > 6 ? (
                        <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                      ) : (
                        <FontAwesomeIcon icon={faMoneyCheck} size="1x" />
                      )}
                    </span>
                    {"  "}
                    Payment
                  </p>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
          <span className="float-right  edit-flight-button mt-4">
            <button
              onClick={()=>this.toggleShoppingCart()}
              className="btn btn-md btn-success mx-1"
            >
              <FontAwesomeIcon icon={faShoppingCart} />{" "}
            </button>
          </span>
        </div>{" "}
      </>
    );
  }
  renderBreadcrumbLargeScreen() {
    return (
      <>
        <div className="breadcrumb-Card">
          <ul className="breadcrumb-custom">
            <li>
              <a href="/myb/home" title="" className="breadcrumb-custom-home">
                {" "}
                <FontAwesomeIcon icon={faHome} size="1x" />
                {"  "}
                <p>Home</p>
              </a>
            </li>
            {this.props.currentPage === "editAncillaries" ? (
              <li>
                <a
                  href={() => false}
                  className={
                    this.state.currentBreadCrumb === 2
                      ? "breadcrumb-custom-current"
                      : this.state.currentBreadCrumb > 2
                      ? "breadcrumb-custom-disabled previsous"
                      : "breadcrumb-custom-disabled btn-not-allowd"
                  }
                  onMouseOver={() => {
                    if (this.state.currentBreadCrumb === 2) {
                      var textSelect =
                        document.getElementById("extras-text-lg");
                      textSelect.innerHTML = Translation.t("edit_extras");
                    }
                  }}
                  onClick={this.changePage.bind(this, "extra")}
                  onMouseOut={() => {
                    if (this.state.currentBreadCrumb === 2) {
                      var textSelect =
                        document.getElementById("extras-text-lg");
                      textSelect.innerHTML = "";
                    }
                  }}
                >
                  <span>
                    {this.state.currentBreadCrumb > 2 ? (
                      <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                    ) : (
                      <FontAwesomeIcon icon={faSuitcase} size="1x" />
                    )}
                  </span>
                  {"  "}
                  Extras
                  <p id="extras-text-lg"></p>
                </a>
              </li>
            ) : (
              <li>
                <a
                  href={() => false}
                  className={
                    this.state.currentBreadCrumb === 2
                      ? "breadcrumb-custom-current"
                      : this.state.currentBreadCrumb > 2
                      ? "breadcrumb-custom-disabled previsous"
                      : "breadcrumb-custom-disabled btn-not-allowd"
                  }
                  onMouseOver={() => {
                    if (this.state.currentBreadCrumb >= 2) {
                      var textSelect = document.getElementById("seat-text-lg");
                      textSelect.innerHTML = Translation.t("edit_seat");
                    }
                  }}
                  onClick={this.changePage.bind(this, "seat")}
                  onMouseOut={() => {
                    if (this.state.currentBreadCrumb >= 2) {
                      var textSelect = document.getElementById("seat-text-lg");
                      textSelect.innerHTML = "";
                    }
                  }}
                >
                  <span>
                    {this.state.currentBreadCrumb > 2 ? (
                      <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                    ) : (
                      <FontAwesomeIcon icon={faChair} size="1x" />
                    )}
                  </span>
                  {"  "}
                  Seat Selection
                  <p id="seat-text-lg"></p>
                </a>
              </li>
            )}
            <li>
              <a
                href={() => false}
                className={
                  this.state.currentBreadCrumb === 3
                    ? "breadcrumb-custom-current"
                    : this.state.currentBreadCrumb > 3
                    ? "breadcrumb-custom-disabled previsous"
                    : "breadcrumb-custom-disabled btn-not-allowd"
                }
              >
                <span>
                  {this.state.currentBreadCrumb > 6 ? (
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                  ) : (
                    <FontAwesomeIcon icon={faSuitcase} size="1x" />
                  )}
                </span>{" "}
                {"  "}
                Payment
              </a>
            </li>
          </ul>
        </div>
        {this.props.isMedium && this.state.showShoppingCartButton ? (
          <ManageTripShoppingCart showShoppingCart={this.state.showShoppingCart} />
        ) : null}
      </>
    );
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirectTo,
          }}
        />
      );
    }
    return (
      <>
        {this.renderBreadcrumbMediumScreen()}
        {this.renderBreadcrumbLargeScreen()}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookingWiget: state.bookingWiget,
    isMobile: state.global.isMobile,
    isMedium: state.global.isMedium,

  };
};
export default connect(mapStateToProps, null)(ManageTripBreadCrumb);
