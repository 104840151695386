export  const passengerAction = {
  ADDPASSENGER: "ADDPASSENGER",
  UPDATEPASSENGERINFO: "UPDATEPASSENGERINFO",
  SAVEPASSENGER: "SAVEPASSENGER",
  CHANGEVALIDATION: "CHANGEVALIDATION",
  REMOVEVALIDATION: "REMOVEVALIDATION",
  UPDATESAVEDPROFILE: "UPDATESAVEDPROFILE",
  CHANGEREMEMBERME: "CHANGEREMEMBERME",
  FILLPASSENGERFORM: "FILLPASSENGERFORM",
  REMOVEFILLEDPASSENGERFORM: "REMOVEFILLEDPASSENGERFORM",
  UPDATEPASSENGERCONTACT: "UPDATEPASSENGERCONTACT",
  UPDATEPASSENGERADDITIONALINFO: "UPDATEPASSENGERADDITIONALINFO",
};