import React from "react";
import {
  Accordion,
  Card,
  Row,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faExchangeAlt,
  faPlane,
  faAngleUp,
  faChair,
  faLuggageCart,
  faMoneyCheck,
  faFileInvoiceDollar,
  faLongArrowAltRight,
  faSuitcase,
  faShoppingCart,
} from "@fortawesome/free-solid-svg-icons";
import "./ShoppingCart.css";
import { connect } from "react-redux";
import HttpService from "../../../service/shared/HttpService";
import { shoppingCartAction } from "../../../redux/actions/booking/shoopingCartAction";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import moment from "moment";
import { Button } from "react-bootstrap";
import { seatSelectionAction } from "../../../redux/actions/booking/seatSelectionAction";
import { SeatSelectionService } from "../../../service/booking/seat-selection/SeatSelectionService";
import { ancillaryAction } from "../../../redux/actions/booking/ancillaryAction";
import FareRule from "./FareRule";
import BaggageInfo from "./BaggageInfo";

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flightAccordion: 0,
      seatAccordion: -1,
      extraAccordion: -1,
      sucharchgesAccordion: -1,
      taxesAccordion: -1,
      showFareRule: false,
      showBaggageInformation: false,
      checkedIn: 1,
      carryOn: -1,
    };
  }
  hideBaggageInfo = () => {
    this.setState({ showBaggageInformation: false });
  };
  hideFareRule = () => {
    this.setState({ showFareRule: false });
  };
  currencyForSeat = "";
  currentPageTracker = 1;

  totalFinder = () => {
    if (this.props.isAncillaryAdd) {
      return (
        this.props.searchFlight.selectionResult.total.alternatives[0][0]
          .amount +
        this.calculateSeatTotalPrice() +
        this.props.totalPriceBaggage
      );
    } else {
      return (
        this.props.searchFlight.selectionResult.total.alternatives[0][0]
          .amount + this.calculateSeatTotalPrice()
      );
    }
  };

  componentDidMount = () => {
    var sabreCookies = null;
    if (this.props.searchFlight.selectionResult !== null) {
      sabreCookies =
        this.props.searchFlight.selectionResult.cookieSabreDataRequest;
    }
    var url = window.location.pathname;


    if ((this.props.fareRule === null || url.includes("flightsummary")) && sabreCookies !== null ) {
      HttpService.postService(
        sabreCookies,
        "/Booking/GetFareRule",
        this.props.searchFlight.token
      )
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            this.props.saveFareRule(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if ((this.props.baggageInfo === null || url.includes("flightsummary")) && sabreCookies !== null) {
      HttpService.postService(
        sabreCookies,
        "/Bag/GetAllowance",
        this.props.searchFlight.token
      )
        .then((response) => {
          if (response.data.statusAPI === "SUCCESS") {
            this.props.saveBaggageInfo(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  carryOnBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCarryOn.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>
                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              {passengerBaggageDisclosure.baggageRestrictions.map(
                                (baggageRestrictions, key4) => (
                                  <>
                                    <li>
                                      <span key={key4}>
                                        <Translation Key="dimension" /> :{" "}
                                        {
                                          baggageRestrictions.dimensionDescription
                                        }
                                      </span>
                                    </li>
                                    <li>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </li>
                                  </>
                                )
                              )}
                            </ul>
                            <Translation Key="additional_info" /> :
                            <h6 className="text-center">
                              {passengerBaggageDisclosure.additionalInfo}
                            </h6>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  checkedInBaggage = () => {
    return (
      <div className="shopping-cart-tab">
        <Tabs defaultActiveKey="0">
          {this.props.baggageInfo.baggageInfoCheckeId.map(
            (baggage_info, baggage_infoKey) => (
              <Tab
                eventKey={baggage_infoKey}
                key={baggage_infoKey}
                title={
                  <span>
                    {baggage_info.generalData.origin}
                    {"  "}
                    <FontAwesomeIcon
                      icon={faPlane}
                      size="lg"
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    />
                    {"  "}
                    {baggage_info.generalData.destination}
                  </span>
                }
              >
                <Row>
                  {baggage_info.passengerBaggageDisclosure.map(
                    (
                      passengerBaggageDisclosure,
                      passengerBaggageDisclosureKey
                    ) => (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mt-3">
                        <Card key={passengerBaggageDisclosureKey}>
                          <Card.Body>
                            <h5
                              className="text-center"
                              style={{ color: "#206080" }}
                            >
                              <Translation Key="passenger_type" /> :{" "}
                              {passengerBaggageDisclosure.passangerType ===
                              "ADT" ? (
                                <Translation Key="adult" />
                              ) : (
                                <Translation Key="child" />
                              )}
                            </h5>
                            <div className="text-center">
                              {passengerBaggageDisclosure.bag.map(
                                (bag, bagKey) => (
                                  <FontAwesomeIcon
                                    icon={faSuitcase}
                                    className="m-3"
                                    size="2x"
                                    key={bagKey}
                                  />
                                )
                              )}
                            </div>
                            <span className="baggage-info-title">
                              {" "}
                              <Translation Key=" free_baggage" />
                            </span>

                            <ul className="baggage-info-title">
                              <li>
                                <Translation Key="total_units" />:{" "}
                                {passengerBaggageDisclosure.totalUnits}
                              </li>
                              <li>
                                {passengerBaggageDisclosure.baggageRestrictions.map(
                                  (baggageRestrictions, key4) => (
                                    <span key={key4}>
                                      <Translation Key="weight" /> :{" "}
                                      {baggageRestrictions.weightDescription}
                                    </span>
                                  )
                                )}
                              </li>
                              <li>
                                <Translation Key="total_weight" /> :{" "}
                                {passengerBaggageDisclosure.totalWeight}
                              </li>
                            </ul>
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  )}
                </Row>
              </Tab>
            )
          )}
        </Tabs>
      </div>
    );
  };
  retunBaggageInfo = () => {
    try {
      return (
        <Accordion defaultActiveKey="Checked-in">
          <Card>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Checked-in"
              className="baggage-info active-shopping-cart-header"
              onClick={() => {
                if (this.state.checkedIn === 1) {
                  this.setState({ checkedIn: -1 });
                } else {
                  this.setState({ checkedIn: 1 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.checkedIn === 1 ? faAngleUp : faAngleDown}
                  size="2x"
                  color="#555"
                  className="float-right"
                />
              </span>

              <h4 className="float-rigth">
                {" "}
                <Translation Key="checked_in_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Checked-in">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="checked_in_baggage_body" />
                </h5>
                {this.checkedInBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className=" ">
            <Accordion.Toggle
              as={Card.Header}
              eventKey="Carry-on"
              className="baggage-info"
              onClick={() => {
                if (this.state.carryOn === 2) {
                  this.setState({ carryOn: -1 });
                } else {
                  this.setState({ carryOn: 2 });
                }
              }}
            >
              <span>
                {"   "}
                <FontAwesomeIcon
                  icon={this.state.carryOn === 2 ? faAngleUp : faAngleDown}
                  size="2x"
                  className="float-right"
                />
              </span>

              <h4>
                {" "}
                <Translation Key="carry_on_baggage" />
              </h4>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="Carry-on">
              <Card.Body className=" p-1 p-md-3">
                <h5 style={{ color: "#4a5767" }}>
                  <Translation Key="Carry_on_baggage_body" />
                </h5>
                {this.carryOnBaggage()}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };
  getHoursFromMins = (mins) => {
    if (mins <= 60) {
      return (
        <span>
          {"  "}
          {mins} {" mins"}
        </span>
      );
    } else {
      return (
        <span>
          {" "}
          {parseInt(mins / 60)}
          {" hr(s) "} {mins % 60} {" mins"}
        </span>
      );
    }
  };
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  getFlights = () => {
    var shoppingCartFlight = [];
    var operatedBy = [];
    var priceList = [];
    var flightTotal = null;
    var origin = null;
    var destination = null;
    try {
      this.props.bookingWiget.itineraryPart.forEach((itineraryPart, index) => {
        if (index === 0 && itineraryPart !== null) {
          origin = itineraryPart.params.from.value;
          destination = itineraryPart.params.to.value;
        } else {
          if (itineraryPart.params.to !== null) {
            destination = itineraryPart.params.to.value;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }

    this.props.searchFlight.selectionResult.productBreakdowns.forEach(
      (productBreakdown) => {
        if (productBreakdown.label === "air") {
          productBreakdown.breakdownElements.forEach((breakdownElement) => {
            if (breakdownElement.label === "BASE_FARE") {
              breakdownElement.breakdownElementAssignment.travelPart.itineraryParts.forEach(
                (itineraryPart, index) => {
                  var time = null;
                  var date = null;
                  var stopAirport = [];
                  if (
                    index === 1 &&
                    this.props.bookingWiget.journyType === "roundTrip"
                  ) {
                    time = moment(itineraryPart.segments[0].departure)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("LT");
                    date = moment(itineraryPart.segments[0].departure)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("DD MMMM YYYY");
                    stopAirport = [];
                    operatedBy = [];
                    shoppingCartFlight.push(
                      <div key={index}>
                        <div className="shopping-cart-internal-header">
                          <span className="shopping-cart-disapled">
                            <Translation Key="returning" />
                          </span>{" "}
                          <span className="shopping-cart-date">
                            {time}, on {date}
                          </span>
                        </div>
                        <Row className="shopping-cart-internal-title">
                          <div className="d-inline-flex px-3">
                            {AirportFullName.getCityName(
                              itineraryPart.segments[0].origin
                            )}
                          </div>
                          <div className="d-inline-flex">
                            <span>
                              {AirportFullName.getCityName(
                                itineraryPart.segments[
                                  itineraryPart.segments.length - 1
                                ].destination
                              )}
                            </span>
                          </div>
                        </Row>
                        <Row className="shopping-cart-disapled">
                          <div className="d-inline-flex pl-3">
                            <Translation Key="duration" />:{" "}
                            {this.getHoursFromMins(itineraryPart.totalDuration)}
                            {itineraryPart.stops !== 0 ? (
                              <span style={{ color: "#b21111" }}>
                                /{itineraryPart.stops}{" "}
                                <Translation Key="stop" />
                                {itineraryPart.segments.map(
                                  (segment, index) => {
                                    segment.flight.stopAirports.forEach(
                                      (stopAirporTemp) => {
                                        stopAirport.push(
                                          stopAirporTemp.airport
                                        );
                                      }
                                    );
                                    if (
                                      itineraryPart.segments.length > 0 &&
                                      index != 0
                                    ) {
                                      stopAirport.push(segment.origin);
                                    }
                                  }
                                )}
                                ({stopAirport.join(",")})
                              </span>
                            ) : (
                              <span style={{ color: "#b21111" }}>
                                {" /"}
                                <Translation Key="non-stop" />
                              </span>
                            )}
                            {itineraryPart.segments.map((segment, index) => {
                              operatedBy.push(
                                <Row
                                  className="shopping-cart-disapled"
                                  key={index}
                                >
                                  <div className="col-4 d-flex">
                                    {segment.flight.airlineCode === "ET" ? (
                                      <img
                                        className="shopping-cart-airline-logo"
                                        alt=""
                                        src="https://dxbooking.ethiopianairlines.com/dx/ETDX/4.7.14-169.ETDX_FrequentFlyerPr/images/airlines/ET.png"
                                      />
                                    ) : null}

                                    {segment.flight.airlineCode}
                                    {"  "}
                                    {segment.flight.flightNumber}
                                  </div>
                                  <div className="col-8 d-flex">
                                    Operated by {segment.flight.airlineName}
                                  </div>
                                </Row>
                              );
                            })}
                          </div>
                        </Row>
                        {operatedBy}
                      </div>
                    );
                  } else {
                    time = moment(itineraryPart.segments[0].departure)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("LT");
                    date = moment(itineraryPart.segments[0].departure)
                      .locale(localStorage.getItem("i18nextLng"))
                      .format("DD MMMM YYYY");
                    stopAirport = [];
                    operatedBy = [];
                    shoppingCartFlight.push(
                      <div key={index}>
                        <div className="shopping-cart-internal-header">
                          <span className="shopping-cart-disapled">
                            {this.props.bookingWiget.journyType ===
                              "multicity" ||
                            this.props.bookingWiget.journyType ===
                              "stopover" ? (
                              <span>{index + 1}</span>
                            ) : null}{" "}
                            <Translation Key="departing" />
                          </span>{" "}
                          <span className="shopping-cart-date">
                            {time}, on {date}
                          </span>
                        </div>
                        <Row className="shopping-cart-internal-title">
                          <div className="d-inline-flex px-3">
                            {AirportFullName.getCityName(
                              itineraryPart.segments[0].origin
                            )}
                          </div>
                          <div className="d-inline-flex">
                            <span>
                              {AirportFullName.getCityName(
                                itineraryPart.segments[
                                  itineraryPart.segments.length - 1
                                ].destination
                              )}
                            </span>
                          </div>
                        </Row>
                        <Row className="shopping-cart-disapled">
                          <div className="d-inline-flex pl-3">
                            <Translation Key="duration" />:
                            {this.getHoursFromMins(itineraryPart.totalDuration)}
                            {itineraryPart.stops !== 0 ? (
                              <span style={{ color: "#b21111" }}>
                                /{itineraryPart.stops}{" "}
                                <Translation Key="stop" />
                                {itineraryPart.segments.map(
                                  (segment, index) => {
                                    segment.flight.stopAirports.forEach(
                                      (stopAirporTemp) => {
                                        stopAirport.push(
                                          stopAirporTemp.airport
                                        );
                                      }
                                    );
                                    if (
                                      itineraryPart.segments.length !== 1 &&
                                      index + 1 !==
                                        itineraryPart.segments.length
                                    ) {
                                      stopAirport.push(segment.destination);
                                    }
                                  }
                                )}
                                ({stopAirport.join(",")})
                              </span>
                            ) : (
                              <span style={{ color: "#b21111" }}>
                                {" /"}
                                <Translation Key="non-stop" />
                              </span>
                            )}
                            {itineraryPart.segments.map((segment, index) => {
                              operatedBy.push(
                                <Row
                                  className="shopping-cart-disapled"
                                  key={index}
                                >
                                  <div className="col-4 d-flex">
                                    <img
                                      className="shopping-cart-airline-logo"
                                      alt=""
                                      src="https://dxbooking.ethiopianairlines.com/dx/ETDX/4.7.14-167.ETDX_Mastero_VT/images/airlines/ET.png"
                                    />
                                    {segment.flight.airlineCode}
                                    {"  "}
                                    {segment.flight.flightNumber}
                                  </div>
                                  <div className="col-8 d-flex">
                                    Operated by {segment.flight.airlineName}
                                  </div>
                                </Row>
                              );
                            })}
                          </div>
                        </Row>
                        {operatedBy}
                      </div>
                    );
                  }
                }
              );

              if (breakdownElement.price.alternatives.length !== 0) {
                flightTotal = (
                  <span className="float-right">
                    {this.numberWithCommas(
                      breakdownElement.price.alternatives[0][0].amount
                    )}{" "}
                    {breakdownElement.price.alternatives[0][0].currency}
                  </span>
                );
              }

              breakdownElement.subElements.forEach((subElement) => {
                priceList.push(
                  <Row className="shopping-cart-header-border shopping-cart-disapled">
                    <div className="col-6">
                      <span className="float-left mt-2">
                        {subElement.label === "ADT" ? (
                          <Translation Key="adult" />
                        ) : subElement.label === "CHD" ? (
                          <Translation Key="child" />
                        ) : (
                          <Translation Key="infant" />
                        )}{" "}
                        x {subElement.breakdownElementAssignment.quantity}
                      </span>
                    </div>
                    <div className="col-6">
                      <span className="float-right mt-2">
                        {this.numberWithCommas(
                          subElement.price.alternatives[0][0].amount
                        )}{" "}
                        {subElement.price.alternatives[0][0].currency}
                      </span>
                    </div>
                  </Row>
                );
              });
            }
          });
        }
      }
    );

    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="0"
          className="shopping-cart-header-border active-shopping-cart-header"
          onClick={() => {
            if (this.state.flightAccordion === 0) {
              this.setState({ flightAccordion: -1 });
            } else {
              this.setState({ flightAccordion: 0 });
            }
          }}
        >
          <span>
            {"   "}
            <FontAwesomeIcon
              icon={this.state.flightAccordion === 0 ? faAngleUp : faAngleDown}
              className="shopping-cart-right-icon ml-3"
            />

            <FontAwesomeIcon
              icon={faPlane}
              className={
                this.state.flightAccordion === 0
                  ? "shopping-cart-active shopping-cart-left-icon"
                  : "shopping-cart-disapled shopping-cart-left-icon"
              }
            />
          </span>
          <div className="row px-0 mx-0">
            <div className="col-5 col-lg-3 shopping-cart-title">{origin}</div>
            <div className="col-2 px-0">
              <FontAwesomeIcon
                icon={
                  this.props.bookingWiget.journyType === "oneWay"
                    ? faLongArrowAltRight
                    : faExchangeAlt
                }
                className="shopping-cart-center-icon"
              />
            </div>
            <div className="col-5 col-lg-3 shopping-cart-title">
              {destination}
            </div>
            <div className="col-12 col-lg-4 px-0 shopping-cart-price">
              {flightTotal}
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            {shoppingCartFlight}
            {priceList}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };
  getSurcharges = () => {
    var shoppingSurcharge = [];
    var surchargeTotal = null;

    try {
      this.props.searchFlight.selectionResult.productBreakdowns.forEach(
        (productBreakdown) => {
          if (productBreakdown.label === "air") {
            productBreakdown.breakdownElements.forEach((breakdownElement) => {
              if (breakdownElement.label === "SURCHARGES") {
                surchargeTotal = (
                  <span className="float-right shopping-cart-price">
                    {this.numberWithCommas(
                      breakdownElement.price.alternatives[0][0].amount
                    )}{" "}
                    {breakdownElement.price.alternatives[0][0].currency}
                  </span>
                );
                breakdownElement.subElements.forEach((subElement) => {
                  shoppingSurcharge.push(
                    <Row className="shopping-cart-header-border shopping-cart-disapled ">
                      <div className="col-6">
                        <span className="float-left mt-2">
                          {subElement.label === "ADT" ? (
                            <Translation Key="adult" />
                          ) : subElement.label === "CHD" ? (
                            <Translation Key="child" />
                          ) : (
                            <Translation Key="infant" />
                          )}{" "}
                          x {subElement.breakdownElementAssignment.quantity}
                        </span>
                      </div>
                      <div className="col-6">
                        <span className="float-right mt-2">
                          {this.numberWithCommas(
                            subElement.price.alternatives[0][0].amount
                          )}{" "}
                          {subElement.price.alternatives[0][0].currency}
                        </span>
                      </div>
                    </Row>
                  );
                });
              }
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }

    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="3"
          className="shopping-cart-header-border active-shopping-cart-header"
          onClick={() => {
            if (this.state.sucharchgesAccordion === 3) {
              this.setState({ sucharchgesAccordion: -1 });
            } else {
              this.setState({ sucharchgesAccordion: 3 });
            }
          }}
        >
          {"   "}
          <FontAwesomeIcon
            icon={
              this.state.sucharchgesAccordion === 3 ? faAngleUp : faAngleDown
            }
            className="shopping-cart-right-icon"
          />
          <FontAwesomeIcon
            icon={faMoneyCheck}
            className={
              this.state.sucharchgesAccordion === 3
                ? "shopping-cart-active shopping-cart-left-icon"
                : "shopping-cart-disapled shopping-cart-left-icon"
            }
          />
          <Row>
            <div className="col-6">
              <span className="shopping-cart-title float-left">
                <Translation Key="surcharges" />
              </span>
            </div>
            <div className="col-6">{surchargeTotal}</div>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body>{shoppingSurcharge}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  getTaxs = () => {
    var shoppingTax = [];
    var taxTotal = null;
    this.props.searchFlight.selectionResult.productBreakdowns.forEach(
      (productBreakdown) => {
        if (productBreakdown.label === "air") {
          productBreakdown.breakdownElements.forEach((breakdownElement) => {
            if (breakdownElement.label === "TAXES") {
              taxTotal = (
                <span className="float-right shopping-cart-price">
                  {this.numberWithCommas(
                    breakdownElement.price.alternatives[0][0].amount
                  )}{" "}
                  {breakdownElement.price.alternatives[0][0].currency}
                </span>
              );
              breakdownElement.subElements.forEach((subElement) => {
                shoppingTax.push(
                  <>
                    <Row className="shopping-cart-internal-header">
                      <div className="col-8 shopping-cart-disapled">
                        {subElement.label === "ADT" ? (
                          <Translation Key="per_adult" />
                        ) : subElement.label === "CHD" ? (
                          <Translation Key="per_child" />
                        ) : (
                          <Translation Key="per_infant" />
                        )}
                      </div>
                      <div className="col-4 shopping-cart-price float-right">
                        {subElement.price.alternatives.length !== 0 ? (
                          <span>
                            {this.numberWithCommas(
                              subElement.price.alternatives[0][0].amount
                            )}{" "}
                            {subElement.price.alternatives[0][0].currency}
                          </span>
                        ) : null}
                      </div>
                    </Row>

                    {subElement.subElements !== null
                      ? subElement.subElements.map((finalSubElement) => (
                          <Row>
                            <div className="col-8 shopping-cart-disapled">
                              {finalSubElement.label}
                            </div>
                            <div className="col-4 shopping-cart-price float-right">
                              {finalSubElement.price.alternatives.length !==
                              0 ? (
                                <span>
                                  {this.numberWithCommas(
                                    finalSubElement.price.alternatives[0][0]
                                      .amount
                                  )}{" "}
                                  {
                                    finalSubElement.price.alternatives[0][0]
                                      .currency
                                  }
                                </span>
                              ) : null}
                            </div>
                          </Row>
                        ))
                      : null}
                  </>
                );
              });
            }
          });
        }
      }
    );
    return (
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          eventKey="4"
          className="shopping-cart-header-border active-shopping-cart-header"
          onClick={() => {
            if (this.state.taxesAccordion === 4) {
              this.setState({ taxesAccordion: -1 });
            } else {
              this.setState({ taxesAccordion: 4 });
            }
          }}
        >
          {"   "}
          <FontAwesomeIcon
            icon={this.state.taxesAccordion === 4 ? faAngleUp : faAngleDown}
            className="shopping-cart-right-icon"
          />
          <FontAwesomeIcon
            icon={faFileInvoiceDollar}
            className={
              this.state.taxesAccordion === 4
                ? "shopping-cart-active shopping-cart-left-icon"
                : "shopping-cart-disapled shopping-cart-left-icon"
            }
          />
          <Row>
            <div className="col-6">
              <span className="shopping-cart-title float-left">
                <Translation Key="taxes_and_fee" />
              </span>
            </div>
            <div className="col-6">{taxTotal}</div>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body>{shoppingTax}</Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  };

  removeSeat = (
    flightIdentifier,
    passengerIndex,
    seatMapIdentifier,
    departure
  ) => {
    this.props.seatSelectingUpdate(true);
    var seatSelectionService = new SeatSelectionService();
    var payload = {
      departure: departure,
      seatMapIdentifier: seatMapIdentifier,
      seatCode: null,
      passengerIndex: this.props.passengerIndex,
    };

    seatSelectionService
      .selectSeat(this.props.cookieSabreDataRequest, this.props.token, payload)
      .then((response) => {
        this.props.seatSelectingUpdate(false);
        this.props.removeSelectedSeat(flightIdentifier, passengerIndex);
      });
  };

  calculateSeatTotalPrice = () => {
    var temp = Object.keys(this.props.selectedSeats).filter(
      (key) => "seat" in this.props.selectedSeats[key]
    );
    var total = 0;
    for (var i = 0; i < temp.length; i++) {
      if (
        !this.props.selectedSeats[temp[i]].seat.freeOfCharge &&
        this.props.selectedSeats[temp[i]].seat.total.alternatives.length !== 0
      ) {
        total =
          total +
          this.props.selectedSeats[temp[i]].seat.total.alternatives[0][0]
            .amount;
        this.currencyForSeat =
          this.props.selectedSeats[
            temp[i]
          ].seat.total.alternatives[0][0].currency;
      }
    }
    return total;
  };

  getSelectedSeats = () => {
    return (
      <>
        {/* {console.log(this.props.segmentSeatMaps)} */}
        {/* {this.props.segmentSeatMaps.map((segmentSeatMap) => {
              <h4>{segmentSeatMap.segment.origin + "-" + segmentSeatMap.segment.destination}</h4>
              console.log(segmentSeatMap)
            })} */}
        {this.props.segmentSeatMaps.map((segmentSeatMap) => {
          return (
            <>
              <span className="shopping-cart-seat-header d-flex">
                {"Flight : " +
                  segmentSeatMap.segment.origin +
                  "-" +
                  segmentSeatMap.segment.destination}
              </span>
              <div className="passanger-lists">
                <i class="fas fa-chair-office"></i>
                {this.props.segmentSeatMaps[0].passengerSeatMaps.map(
                  (passengerSeatMap) => {
                    return (
                      <div className="single-passange row align-items-center py-1">
                        <OverlayTrigger
                          key={passengerSeatMap.passenger.passengerIndex}
                          placement="left"
                          overlay={
                            <Tooltip
                              id={
                                "tooltip-" +
                                passengerSeatMap.passenger.passengerIndex
                              }
                            >
                              <span className="text-uppercase p-2">
                                {passengerSeatMap.passenger.passengerDetails
                                  .firstName +
                                  " " +
                                  passengerSeatMap.passenger.passengerDetails
                                    .lastName}
                              </span>
                            </Tooltip>
                          }
                        >
                          <span
                            className={
                              passengerSeatMap.passenger.passengerInfo.type ===
                              "INF"
                                ? "passanger-identifier-infant-shopping d-inline-flex"
                                : "passanger-identifier-shopping d-inline-flex"
                            }
                          >
                            {/* {console.log(this.passengerSeatMaps)} */}
                            {this.props.activePassangerIdentifierCodeMapper[passengerSeatMap.passenger.passengerInfo.type[0] +
                              passengerSeatMap.passenger.passengerIndex]}
                          </span>
                        </OverlayTrigger>
                        {segmentSeatMap.segment.origin +
                          segmentSeatMap.segment.destination +
                          passengerSeatMap.passenger.passengerIndex in
                        this.props.selectedSeats ? (
                          <>
                            <span className="shopping-cart-selected-seat-name d-inline-flex">
                              {" Seat " +
                                this.props.selectedSeats[
                                  segmentSeatMap.segment.origin +
                                    segmentSeatMap.segment.destination +
                                    passengerSeatMap.passenger.passengerIndex
                                ].seatCode}
                            </span>
                            <span className="shopping-cart-selected-seat-price d-inline-flex">
                              {this.props.selectedSeats[
                                segmentSeatMap.segment.origin +
                                  segmentSeatMap.segment.destination +
                                  passengerSeatMap.passenger.passengerIndex
                              ].seat.freeOfCharge
                                ? " Free "
                                : !this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.freeOfCharge &&
                                  this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.total.alternatives.length !== 0
                                ? this.numberWithCommas(
                                    this.props.selectedSeats[
                                      segmentSeatMap.segment.origin +
                                        segmentSeatMap.segment.destination +
                                        passengerSeatMap.passenger
                                          .passengerIndex
                                    ].seat.total.alternatives[0][0].amount
                                  ) +
                                  " " +
                                  this.props.selectedSeats[
                                    segmentSeatMap.segment.origin +
                                      segmentSeatMap.segment.destination +
                                      passengerSeatMap.passenger.passengerIndex
                                  ].seat.total.alternatives[0][0].currency
                                : null}
                            </span>
                            <button
                              className="clear-seat-selection d-inline-flex"
                              onClick={() =>
                                this.removeSeat(
                                  segmentSeatMap.segment.origin +
                                    segmentSeatMap.segment.destination +
                                    passengerSeatMap.passenger.passengerIndex,
                                  segmentSeatMap.segment.origin +
                                    "-" +
                                    segmentSeatMap.segment.destination,
                                  segmentSeatMap.segment.departure
                                )
                              }
                            >
                              x
                            </button>
                          </>
                        ) : (
                          <span className="passanger-name-shopping-cart d-inline-flex">
                            {passengerSeatMap.passenger.passengerInfo.type ===
                            "INF"
                              ? "n/a (Infant)"
                              : " -  - "}
                          </span>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </>
          );
        })}
      </>
    );
  };
  updateLocalVariableCurrentPageTracker() {
    var url = window.location.pathname;
    if (url.includes("flight")) {
      this.currentPageTracker = 2;
    } else if (url.includes("passenger")) {
      this.currentPageTracker = 3;
    } else if (url.includes("seatSelection")) {
      this.currentPageTracker = 4;
    } else if (url.includes("ancillary")) {
      this.currentPageTracker = 5;
    }
    // console.log(this.currentPageTracker);
  }
  removeBaggage = (ancillaryListForShoppingCart) => {
    var baggageRequest = [];
    this.props.baggageRequest.ancillaryOperations.forEach((bRequest) => {
      if (bRequest.selectedPassengers[0].quantity !== 0) {
        baggageRequest.push(bRequest);
      }
    });
    var tempBaggageRequest = baggageRequest.filter(
      (b) => b.id === ancillaryListForShoppingCart.id
    )[0];
    tempBaggageRequest.selectedPassengers[0].quantity = 0;

    this.props.baggageRequest.ancillaryOperations = baggageRequest;
    HttpService.ancillaryPostService(
      this.props.baggageRequest,
      "/Ancillary/AddAncillary",
      this.props.token
    )
      .then((ancillaryResponse) => {
        // this.setState({isLoading:false});
        if (ancillaryResponse.data.statusAPI === "SUCCESS") {
          this.props.removeFromCart(
            ancillaryResponse.data.ancillaryOperationResults,
            this.props.baggageRequest,
            ancillaryListForShoppingCart.price,
            ancillaryListForShoppingCart.id,
            ancillaryListForShoppingCart.assignedQuantity
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getExtras = () => {
    if (this.props.isAncillaryAdd) {
      return (
        <>
          {Object.keys(this.props.ancillaryListForShoppingCarts).map((key) => {
            var ancillaryListForShoppingCart =
              this.props.ancillaryListForShoppingCarts[key];
            return (
              <>
                <span className="shopping-cart-seat-header d-flex">
                  {"Flight : " + key}
                </span>
                {ancillaryListForShoppingCart.map(
                  (ancillaryListForShoppingCartSingle) => {
                    return (
                      <div className="passanger-lists">
                        <i class="fas fa-chair-office"></i>
                        <div className="single-passange row align-items-center py-1">
                          <OverlayTrigger
                            key={
                              ancillaryListForShoppingCartSingle.passengerIndex
                            }
                            placement="left"
                            overlay={
                              <Tooltip
                                id={
                                  "tooltip-" +
                                  ancillaryListForShoppingCartSingle.passengerIndex
                                }
                              >
                                <span className="text-uppercase p-2">
                                  {ancillaryListForShoppingCartSingle.firstName +
                                    " " +
                                    ancillaryListForShoppingCartSingle.lastName}
                                </span>
                              </Tooltip>
                            }
                          >
                            <span
                              className={
                                ancillaryListForShoppingCartSingle.type ===
                                "INF"
                                  ? "passanger-identifier-infant-shopping d-inline-flex"
                                  : "passanger-identifier-shopping d-inline-flex"
                              }
                            >
                              {ancillaryListForShoppingCartSingle.type[0] +
                                ancillaryListForShoppingCartSingle.passengerIndex}
                            </span>
                          </OverlayTrigger>
                          <>
                            <span className="shopping-cart-selected-seat-name d-inline-flex">
                              {ancillaryListForShoppingCartSingle.ancillaryName}
                            </span>
                            <span className="shopping-cart-selected-seat-price d-inline-flex">
                              {this.numberWithCommas(
                                ancillaryListForShoppingCartSingle.price
                              )}{" "}
                              {
                                this.props.searchFlight.selectionResult.total
                                  .alternatives[0][0].currency
                              }
                            </span>
                            <button
                              className="clear-seat-selection d-inline-flex"
                              onClick={() => {
                                this.removeBaggage(
                                  ancillaryListForShoppingCartSingle
                                );
                              }}
                            >
                              x
                            </button>
                          </>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            );
          })}
        </>
      );
    } else {
      return <div className="h4 text-center">No Extras Added Yet!</div>;
    }
  };
  shoppingCartBody = () => {
    return (
      <>
        <FareRule
          showFareRule={this.state.showFareRule}
          hideFareRule={this.hideFareRule}
        />
        <BaggageInfo
          showBaggageInformation={this.state.showBaggageInformation}
          hideBaggageInfo={this.hideBaggageInfo}
        />
        <div className="shopping-cart mt-4 flight-selection-container">
          <Accordion defaultActiveKey="0" style={{ padding: 0, margin: 0 }}>
            <Card>
              <h1 className="ml-3 shopping-cart-header">
                {" "}
                <Translation Key="trip-cost" />{" "}
              </h1>
            </Card>
            {this.getFlights()}
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="shopping-cart-header-border active-shopping-cart-header"
                onClick={() => {
                  if (this.state.seatAccordion === 1) {
                    this.setState({ seatAccordion: -1 });
                  } else {
                    this.setState({ seatAccordion: 1 });
                  }
                }}
              >
                <span>
                  {"   "}
                  <FontAwesomeIcon
                    icon={faChair}
                    className={
                      this.state.seatAccordion === 1 &&
                      this.currentPageTracker >= 4
                        ? "shopping-cart-active shopping-cart-left-icon"
                        : "shopping-cart-disapled shopping-cart-left-icon"
                    }
                  />
                </span>
                <FontAwesomeIcon
                  icon={
                    this.state.seatAccordion === 1 &&
                    this.currentPageTracker >= 4
                      ? faAngleUp
                      : faAngleDown
                  }
                  className="shopping-cart-right-icon"
                />
                <span
                  className={
                    this.currentPageTracker >= 5
                      ? "mx-3 shopping-cart-title  shopping-cart-header"
                      : "mx-3 shopping-cart-disapled  shopping-cart-header"
                  }
                >
                  {" "}
                  <Translation Key="seats" />{" "}
                  {this.calculateSeatTotalPrice() !== 0 ? (
                    <span className="float-right shopping-cart-selected-total-seat-price">
                      {this.numberWithCommas(this.calculateSeatTotalPrice()) +
                        " " +
                        this.currencyForSeat}
                    </span>
                  ) : null}
                </span>
              </Accordion.Toggle>
              {this.currentPageTracker >= 4 ? (
                <Accordion.Collapse
                  className="shopping-cart-collapse"
                  eventKey="1"
                >
                  <Card.Body className="px-0 shopping-cart-seat-section">
                    {this.getSelectedSeats()}
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="shopping-cart-header-border"
                onClick={() => {
                  if (this.state.extraAccordion === 1) {
                    this.setState({ extraAccordion: -1 });
                  } else {
                    this.setState({ extraAccordion: 1 });
                  }
                }}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faLuggageCart}
                    className={
                      this.state.extraAccordion === 1 &&
                      this.currentPageTracker >= 5
                        ? "shopping-cart-active shopping-cart-left-icon"
                        : "shopping-cart-disapled shopping-cart-left-icon"
                    }
                  />
                </span>
                <FontAwesomeIcon
                  icon={
                    this.state.extraAccordion === 1 &&
                    this.currentPageTracker >= 5
                      ? faAngleUp
                      : faAngleDown
                  }
                  className="shopping-cart-right-icon"
                />

                <span
                  className={
                    this.currentPageTracker >= 5
                      ? "mx-3 shopping-cart-title  shopping-cart-header"
                      : "mx-3 shopping-cart-disapled  shopping-cart-header"
                  }
                >
                  {" "}
                  <Translation Key="extras" />
                  {this.props.isAncillaryAdd ? (
                    <span className="float-right shopping-cart-selected-total-seat-price">
                      {this.numberWithCommas(this.props.totalPriceBaggage)}{" "}
                      {
                        this.props.searchFlight.selectionResult.total
                          .alternatives[0][0].currency
                      }
                    </span>
                  ) : null}
                </span>
              </Accordion.Toggle>
              {this.currentPageTracker >= 5 ? (
                <Accordion.Collapse
                  className="shopping-cart-collapse"
                  eventKey="1"
                >
                  <Card.Body className="px-0 shopping-cart-seat-section">
                    {this.getExtras()}
                  </Card.Body>
                </Accordion.Collapse>
              ) : null}
            </Card>
          </Accordion>
          <Accordion>{this.getSurcharges()}</Accordion>
          <Accordion>{this.getTaxs()}</Accordion>
          <Card>
            <Row className="m-4 shopping-cart-total-price">
              <div className="col-6 col-sm-6 col-md-5 col-lg-5">
                <span className="float-left shopping-cart-total-price">
                  <Translation Key="trip_total" />
                </span>
              </div>
              <div className="col-6 col-sm-6 col-md-7 col-lg-7">
                <span className="float-right">
                  {this.numberWithCommas(this.totalFinder())}{" "}
                  <span style={{ fontSize: "1.4rem" }}>
                    {" "}
                    {
                      this.props.searchFlight.selectionResult.total
                        .alternatives[0][0].currency
                    }
                  </span>
                </span>
              </div>
            </Row>
            <p className="col-12 shopping-cart-label">
              <span>
                <Translation Key="see_applicable" />{" "}
              </span>{" "}
              <span
                className="shopping-cart-label-tag"
                onClick={() => this.setState({ showFareRule: true })}
              >
                <Translation Key="fare_rules" />
              </span>{" "}
              <span>
                <Translation Key="and" />
              </span>{" "}
              <span
                className="shopping-cart-label-tag"
                onClick={() => this.setState({ showBaggageInformation: true })}
              >
                <Translation Key="baggage_info" />
              </span>
            </p>
          </Card>
        </div>
      </>
    );
  };
  render() {
    this.updateLocalVariableCurrentPageTracker();

    if (this.props.isMedium) {
      return (
        <>{this.props.showShoppingCart && <>{this.shoppingCartBody()}</>}</>
      );
    } else {
      return <>{this.shoppingCartBody()}</>;
    }
  }
}
const mapStateTopProps = (state) => {
  return {
    fareRule: state.shoppingCart.fareRule,
    baggageInfo: state.shoppingCart.baggageInfo,
    searchFlight: state.searchFlight,
    isMedium: state.global.isMedium,
    bookingWiget: state.bookingWiget,
    selectedSeats: state.seatSelection.selectedSeats,
    segmentSeatMaps: state.seatSelection.segmentSeatMaps,
    cookieSabreDataRequest:
      state.searchFlight.searchResult.data.cookieSabreDataRequest,
    token: state.searchFlight.token,
    isAncillaryAdd: state.ancillary.bagIsAdded,
    ancillaryListForShoppingCarts: state.ancillary.ancillaryListForShoppingCart,
    totalPriceBaggage: state.ancillary.finaloverAllTotal,
    baggageRequest: state.ancillary.baggageRequest,
    activePassangerIdentifierCodeMapper: state.seatSelection.activePassangerIdentifierCodeMapper,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    saveFareRule: (response) =>
      dispatch({ type: shoppingCartAction.SAVEFARERULE, fareRule: response }),
    saveBaggageInfo: (response) =>
      dispatch({
        type: shoppingCartAction.SAVEBAGGAGEINFO,
        baggageInfo: response,
      }),
    seatSelectingUpdate: (value) =>
      dispatch({
        type: seatSelectionAction.SEATSELECTING,
        value: value,
      }),
    removeSelectedSeat: (identifier) => {
      dispatch({
        type: seatSelectionAction.REMOVESEATSELECTION,
        identifier: identifier,
      });
    },
    removeFromCart: (
      ancillaryOperationResults,
      baggageRequest,
      price,
      id,
      assignedQuantity
    ) =>
      dispatch({
        type: ancillaryAction.REMOVEFROMCART,
        ancillaryOperationResults: ancillaryOperationResults,
        baggageRequest: baggageRequest,
        price: price,
        id: id,
        assignedQuantity: assignedQuantity,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(ShoppingCart);
