import SearchRequest, {
  SearchRequestItinerary,
} from "../../../model/API/SearchRequest";
import SearchFlightService from "../../../service/booking/searchFlight/SearchFlightService";
import { flightSelectionAction } from "../../actions/booking/flightSelectionAction";
import { searchFlightAction } from "../../actions/booking/searchFlightAction";
import moment from "moment";

const initialState = {
  searchRequest: new SearchRequest(),
  token: "",
  searchResult: null,
  selectionResult: null,
};
var searchFlightService = new SearchFlightService();
const searchFlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case searchFlightAction.SEARCHFLIGHT:
      var serviceResponse = searchFlightService.searchFlight(action);
      return {
        ...state,
        searchRequest: serviceResponse,
      };
    case searchFlightAction.SAVETOKEN:
      return {
        ...state,
        token: action.token,
      };
    case searchFlightAction.SAVESEARCHRESULT:
      return {
        ...state,
        searchResult: action.searchResult,
      };
    case searchFlightAction.UPDATEFLIGHTREQUEST:
      return {
        ...state,
        searchRequest: cleanupSearchParams(action),
      };
    case flightSelectionAction.SAVEFLIGHTSELECTIONSUMMARY:
      return {
        ...state,
        selectionResult: action.selectionResult,
      };
    case flightSelectionAction.UPDATESEARCHRESPONSE:
      var searchResult = state.searchResult;

      var temp = state.searchResult.data.searchResponse.brandedResults;

      temp.itineraryPartBrands[action.payload.flightIndex] =
        action.payload.searchResponse; //{ itineraryPartBrands :  action.payload.searchResponse};
      searchResult.data.searchResponse.brandedResults = temp;
      return { ...state, searchResult: searchResult };
    default:
      return state;
  }
};

const cleanupSearchParams = (action) => {
  var searchRequest = new SearchRequest();
  if (action.searchRequest.passengers !== undefined) {
    searchRequest = action.searchRequest;
  } else {
    searchRequest.passengers.ADT = action.searchRequest.passengerInfo.adult;
    searchRequest.passengers.CHD = action.searchRequest.passengerInfo.child;
    searchRequest.passengers.INF = action.searchRequest.passengerInfo.infant;
    searchRequest.cabinClass = action.searchRequest.cabinClass.value;
    if (
      action.searchRequest.PromoCode !== null &&
      action.searchRequest.PromoCode !== undefined
    ) {
      searchRequest.promoCodes.push(action.searchRequest.PromoCode);
    }

    searchRequest.currency = action.searchRequest.fareType;
    searchRequest.itineraryParts = [];
    var searchRequestItinerary = new SearchRequestItinerary();
    if (action.searchRequest.journyType === "roundTrip") {
      // if (
      //   action.searchRequest.itineraryPart[0].params.from.label.includes(
      //     "Ethiopia"
      //   ) &&
      //   action.searchRequest.itineraryPart[0].params.to.label.includes(
      //     "Ethiopia"
      //   ) &&
      //   searchRequest.promoCodes.length === 0
      // ) {
      //   searchRequest.promoCodes.push("DOM71");
      // }
      searchRequestItinerary = new SearchRequestItinerary();
      searchRequestItinerary.from.code =
        action.searchRequest.itineraryPart[0].params.from.value;
      searchRequestItinerary.to.code =
        action.searchRequest.itineraryPart[0].params.to.value;
      searchRequestItinerary.when.date = moment(
        action.searchRequest.itineraryPart[0].params.when.departureDate
      )
        .locale("en")
        .format("YYYY-MM-DD");
      searchRequest.itineraryParts.push(searchRequestItinerary);
      searchRequestItinerary = new SearchRequestItinerary();
      searchRequestItinerary.from.code =
        action.searchRequest.itineraryPart[0].params.to.value;
      searchRequestItinerary.to.code =
        action.searchRequest.itineraryPart[0].params.from.value;
      searchRequestItinerary.when.date = moment(
        action.searchRequest.itineraryPart[0].params.when.arrivalDate
      )
        .locale("en")
        .format("YYYY-MM-DD");
      searchRequest.itineraryParts.push(searchRequestItinerary);
    } else if (action.searchRequest.journyType === "oneWay") {
      // if (
      //   action.searchRequest.itineraryPart[0].params.from.label.includes(
      //     "Ethiopia"
      //   ) &&
      //   action.searchRequest.itineraryPart[0].params.to.label.includes(
      //     "Ethiopia"
      //   ) &&
      //   searchRequest.promoCodes.length === 0
      // ) {
      //   searchRequest.promoCodes.push("DOM71");
      // }
      searchRequestItinerary.from.code =
        action.searchRequest.itineraryPart[0].params.from.value;
      searchRequestItinerary.to.code =
        action.searchRequest.itineraryPart[0].params.to.value;
      searchRequestItinerary.when.date = moment(
        action.searchRequest.itineraryPart[0].params.when.departureDate
      )
        .locale("en")
        .format("YYYY-MM-DD");
      searchRequest.itineraryParts.push(searchRequestItinerary);
    } else if (action.searchRequest.journyType === "stopover") {
      action.searchRequest.itineraryPart.forEach((element) => {
        searchRequestItinerary = new SearchRequestItinerary();
        if (
          element.params.from.value !== "ADD" &&
          element.params.to.value !== "ADD" &&
          element.params.stopDays !== "init" &&
          element.params.stopDays !== 0
        ) {
          searchRequestItinerary.from.code = element.params.from.value;
          searchRequestItinerary.to.code = "ADD";
          searchRequestItinerary.when.date = moment(
            element.params.when.departureDate
          )
            .locale("en")
            .format("YYYY-MM-DD");
          searchRequest.itineraryParts.push(searchRequestItinerary);

          searchRequestItinerary = new SearchRequestItinerary();
          searchRequestItinerary.from.code = "ADD";
          searchRequestItinerary.to.code = element.params.to.value;
          searchRequestItinerary.when.date = moment(
            element.params.when.departureDate
          )
            .add(element.params.stopDays, "days")
            .locale("en")
            .format("YYYY-MM-DD");
          searchRequest.itineraryParts.push(searchRequestItinerary);
        } else {
          searchRequestItinerary.from.code = element.params.from.value;
          searchRequestItinerary.to.code = element.params.to.value;
          searchRequestItinerary.when.date = moment(
            element.params.when.departureDate
          )
            .locale("en")
            .format("YYYY-MM-DD");
          searchRequest.itineraryParts.push(searchRequestItinerary);
        }
      });
    } else {
      action.searchRequest.itineraryPart.forEach((element) => {
        searchRequestItinerary = new SearchRequestItinerary();
        searchRequestItinerary.from.code = element.params.from.value;
        searchRequestItinerary.to.code = element.params.to.value;
        searchRequestItinerary.when.date = moment(
          element.params.when.departureDate
        )
          .locale("en")
          .format("YYYY-MM-DD");
        searchRequest.itineraryParts.push(searchRequestItinerary);
      });
    }
    searchRequest.journyType = action.searchRequest.journyType;
  }

  return searchRequest;
};

export default searchFlightReducer;
