import { React, Component } from "react";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import "./FlightSort.css";
import { filteredFlightsActions } from "../../../../redux/actions/booking/filteredFlightsActions";
import { flightSelectionAction } from "../../../../redux/actions/booking/flightSelectionAction";
import Translation from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";

class FlightSort extends Component {
  state = {
    SortOption: "ByDepartureTime",
    orderBy: "ASC",
    isSortPossible: false,
    isClosed: true,
  };
  SortOptions = {
    ByDepartureTime: "ByDepartureTime",
    ByArrivalTime: "ByArrivalTime",
    ByNoOfStops: "ByNoOfStops",
    ByDuration: "ByDuration",
    ByEconomyPrice: "ByEconomyPrice",
    ByBusinessPrice: "ByBusinessPrice",
  };
  orderBy = {
    ASC: "ASC",
    DESC: "DESC",
  };
  IsByBusinessPricePossible = false;
  IsByEconomyPricePossible = false;

  constructor(props) {
    super(props);
    this.sortInit();
    this.state.isSortPossible =
      this.props.filterCriteria.Departure.min !==
        this.props.filterCriteria.Departure.max ||
      this.props.filterCriteria.ArrivalTime.min !==
        this.props.filterCriteria.ArrivalTime.max ||
      this.props.filterCriteria.NoOfStops.length > 1 ||
      this.props.filterCriteria.Duration.min !==
        this.props.filterCriteria.Duration.max ||
      this.props.filterCriteria.Price.min !==
        this.props.filterCriteria.Price.max;
  }

  sortInit = () => {
    var IsByBusinessPricePossible = false;
    var IsByEconomyPricePossible = false;
    this.props.searchResponse.brandedResults.itineraryPartBrands[
      this.props.flightIndex
    ].map((element) => {
      element.brandOffers.map((childElement) => {
        if (childElement.cabinClass === "Economy") {
          IsByEconomyPricePossible = true;
        }
        if (childElement.cabinClass === "Business") {
          IsByBusinessPricePossible = true;
        }
        return childElement;
      });
      return element;
    });
    this.IsByBusinessPricePossible = IsByBusinessPricePossible;
    this.IsByEconomyPricePossible = IsByEconomyPricePossible;
  };

  cleanUpPrice = (price) => {
    if (price === "N/A") return 0;
    //remove currency
    price = price.split(" ")[0];
    //remove comma
    price = price.split(",");
    if (price.length === 1) price = price[0];
    else {
      var tempPrice = "";
      for (var i = 0; i < price.length; i++) {
        tempPrice = tempPrice + price[i];
      }
      price = tempPrice;
    }
    return parseInt(price);
  };

  sortFlights = (array, searchType, orderBy) => {
    var resultArray = array;

    switch (searchType) {
      case this.SortOptions.ByDepartureTime:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.departure > secondElement.departure) return -1;
          if (firstElement.departure < secondElement.departure) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByArrivalTime:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.arrival > secondElement.arrival) return -1;
          if (firstElement.arrival < secondElement.arrival) return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByDuration:
        resultArray = array.sort((firstElement, secondElement) => {
          if (firstElement.duration > secondElement.duration) return -1;
          if (firstElement.duration < secondElement.duration) return 1;
          return 0;
        });
        break;

      case this.SortOptions.ByNoOfStops:
        resultArray = array.sort((firstElement, secondElement) => {
          if (
            firstElement.itineraryPart.stops > secondElement.itineraryPart.stops
          )
            return -1;
          if (
            firstElement.itineraryPart.stops < secondElement.itineraryPart.stops
          )
            return 1;
          return 0;
        });
        break;
      case this.SortOptions.ByEconomyPrice:
        resultArray = array.sort((firstElement, secondElement) => {
          // var compareVarForA = null;
          // var compareVarForB = null;
          // var tempVarForA = null;
          // var tempVarForB = null;

          if (
            this.cleanUpPrice(firstElement.echeepPrice) >
            this.cleanUpPrice(secondElement.echeepPrice)
          ) {
            return -1;
          }
          if (
            this.cleanUpPrice(firstElement.echeepPrice) <
            this.cleanUpPrice(secondElement.echeepPrice)
          ) {
            return 1;
          }
          return 0;
          // return Math.min(
          //   firstChildElement.total.alternatives[0][0].amount,
          //   secondChildElement.total.alternatives[0][0].amount
          // );

          // console.log(firstElement);

          /*if (this.state.orderBy === this.orderBy.ASC) {
            tempVarForA = firstElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Economy"
            );
            if (tempVarForA.length === 1) {
              
              compareVarForA = tempVarForA[0].total.alternatives[0][0].amount;
            } else if (tempVarForA.length > 1) {
              console.log(tempVarForA );
              compareVarForA = tempVarForA.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.min(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
            tempVarForB = secondElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Economy"
            );
            if (tempVarForB.length === 1) {
              compareVarForB = tempVarForB[0].total.alternatives[0][0].amount;
            } else if (tempVarForB.length > 1) {
              compareVarForB = tempVarForB.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.min(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
          } else {
            tempVarForA = firstElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Economy"
            );
            if (tempVarForA.length === 1) {
              compareVarForA = tempVarForA[0].total.alternatives[0][0].amount;
            } else if (tempVarForA.length > 1) {
              compareVarForA = tempVarForA.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.max(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
            tempVarForB = secondElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Economy"
            );
            if (tempVarForB.length === 1) {
              compareVarForB = tempVarForB[0].total.alternatives[0][0].amount;
            } else if (tempVarForB.length > 1) {
              compareVarForB = tempVarForB.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.max(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
          }
          if (compareVarForA > compareVarForB) {
            return -1;
          }
          if (compareVarForA < compareVarForB) {
            return 1;
          }
          return 0;*/
        });
        break;

      case this.SortOptions.ByBusinessPrice:
        resultArray = array.sort((firstElement, secondElement) => {
          if (
            this.cleanUpPrice(firstElement.bcheepPrice) >
            this.cleanUpPrice(secondElement.bcheepPrice)
          ) {
            return -1;
          }
          if (
            this.cleanUpPrice(firstElement.bcheepPrice) <
            this.cleanUpPrice(secondElement.bcheepPrice)
          ) {
            return 1;
          }
          return 0;
          /*
          var compareVarForA = null;
          var compareVarForB = null;
          var tempVarForA = null;
          var tempVarForB = null;
          if (this.state.orderBy === this.orderBy.ASC) {
            tempVarForA = firstElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Business"
            );
            if (tempVarForA.length === 1) {
              compareVarForA = tempVarForA[0].total.alternatives[0][0].amount;
            } else if (tempVarForA.length > 1) {
              compareVarForA = tempVarForA.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.min(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
            tempVarForB = secondElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Business"
            );
            if (tempVarForB.length === 1) {
              compareVarForB = tempVarForB[0].total.alternatives[0][0].amount;
            } else if (tempVarForB.length > 1) {
              compareVarForB = tempVarForB.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.min(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
          } else {
            tempVarForA = firstElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Business"
            );
            if (tempVarForA.length === 1) {
              compareVarForA = tempVarForA[0].total.alternatives[0][0].amount;
            } else if (tempVarForA.length > 1) {
              compareVarForA = tempVarForA.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.max(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
            tempVarForB = secondElement.brandOffers.filter(
              (offer) => offer.cabinClass === "Business"
            );
            if (tempVarForB.length === 1) {
              compareVarForB = tempVarForB[0].total.alternatives[0][0].amount;
            } else if (tempVarForB.length > 1) {
              compareVarForB = tempVarForB.reduce(function (
                firstChildElement,
                secondChildElement
              ) {
                return Math.max(
                  firstChildElement.total.alternatives[0][0].amount,
                  secondChildElement.total.alternatives[0][0].amount
                );
              });
            }
          }
          if (compareVarForA > compareVarForB) {
            return -1;
          }
          if (compareVarForA < compareVarForB) {
            return 1;
          }
          return 0;*/
        });
        break;
      default:
        break;
    }
    return orderBy === this.orderBy.ASC ? resultArray.reverse() : resultArray;
  };

  handleSortChoice = (SortOption, orderBy) => {
    // var selectElement = event.target;
    // this.setState({
    //   SortOption: selectElement.options[selectElement.selectedIndex].value,
    // });
    this.setState({
      SortOption: SortOption,
      orderBy: orderBy,
      isClosed: true,
    });
    var temp = JSON.parse(
      JSON.stringify(
        this.props.searchResponse.brandedResults.itineraryPartBrands[
          this.props.flightIndex
        ]
      )
    );
    temp = this.sortFlights(temp, SortOption, orderBy);
    this.props.searchResponseUpdate({
      flightIndex: this.props.flightIndex,
      searchResponse: temp,
    });
    // this.props.triggerRerender();
  };
  loading() {
    return (
      <div style={{ height: "48px" }} className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }

  render() {
    this.sortInit();

    return (
      <>
        {this.state.isLoading ? (
          this.loading()
        ) : (
          <OutsideClickHandler
            display={"inline-block"}
            onOutsideClick={() => this.setState({ isClosed: true })}
          >
            <button
              disabled={this.state.isSortPossible ? "" : "disabled"}
              className="itinerary-part-offer-header-label pl-3 sort-by-button d-flex px-0"
              onClick={() => this.setState({ isClosed: !this.state.isClosed })}
            >
              <span className="d-flex">{Translation.t("sort_by")}</span>
              <span className={this.state.isClosed ? "d-flex align-items-center" : "d-flex align-items-start"} style={{maxHeight : "16px"}}>
                <FontAwesomeIcon
                  icon={this.state.isClosed ? faSortDown : faSortUp}
                  className="pl-2"
                  size={"2x"}
                />
              </span>
            </button>

            <div
              className={
                this.state.isClosed
                  ? "d-none"
                  : "row col-10 col-sm-7 col-xl-5 px-0 filter-list-container"
              }
            >
              {this.props.filterCriteria.Departure.min !==
              this.props.filterCriteria.Departure.max ? (
                <>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByDepartureTime &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByDepartureTime,
                        this.orderBy.ASC
                      )
                    }
                  >
                    {Translation.t("by_departure_date_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByDepartureTime &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByDepartureTime,
                        this.orderBy.DESC
                      )
                    }
                  >
                    {Translation.t("by_departure_date_descending")}
                  </div>
                </>
              ) : null}

              {this.props.filterCriteria.ArrivalTime.min !==
              this.props.filterCriteria.ArrivalTime.max ? (
                <>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByArrivalTime &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByArrivalTime,
                        this.orderBy.ASC
                      )
                    }
                  >
                    {Translation.t("by_arrival_date_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption ===
                        this.SortOptions.ByArrivalTime &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByArrivalTime,
                        this.orderBy.DESC
                      )
                    }
                  >
                    {Translation.t("by_arrival_date_descending")}
                  </div>
                </>
              ) : null}

              {this.props.filterCriteria.NoOfStops.length > 1 ? (
                <>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByNoOfStops &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByNoOfStops,
                        this.orderBy.ASC
                      )
                    }
                  >
                    {Translation.t("by_no_of_stops_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByNoOfStops &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByNoOfStops,
                        this.orderBy.DESC
                      )
                    }
                  >
                    {Translation.t("by_no_of_stops_descending")}
                  </div>
                </>
              ) : null}

              {this.props.filterCriteria.Duration.min !==
              this.props.filterCriteria.Duration.max ? (
                <>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByDuration &&
                      this.state.orderBy === this.orderBy.ASC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByDuration,
                        this.orderBy.ASC
                      )
                    }
                  >
                    {Translation.t("by_duration_ascending")}
                  </div>
                  <div
                    className={
                      this.state.SortOption === this.SortOptions.ByDuration &&
                      this.state.orderBy === this.orderBy.DESC
                        ? "col-12 mx-0 row filter-option-list selected-filter-option"
                        : "col-12 mx-0 row filter-option-list"
                    }
                    onClick={() =>
                      this.handleSortChoice(
                        this.SortOptions.ByDuration,
                        this.orderBy.DESC
                      )
                    }
                  >
                    {Translation.t("by_duration_descending")}
                  </div>
                </>
              ) : null}
              {this.props.filterCriteria.Price.min !==
              this.props.filterCriteria.Price.max ? (
                <>
                  {this.IsByEconomyPricePossible ? (
                    <>
                      <div
                        className={
                          this.state.SortOption ===
                            this.SortOptions.ByEconomyPrice &&
                          this.state.orderBy === this.orderBy.ASC
                            ? "col-12 mx-0 row filter-option-list selected-filter-option"
                            : "col-12 mx-0 row filter-option-list"
                        }
                        onClick={() =>
                          this.handleSortChoice(
                            this.SortOptions.ByEconomyPrice,
                            this.orderBy.ASC
                          )
                        }
                      >
                        {Translation.t("by_economy_price_Low_to_High")}
                      </div>
                      <div
                        className={
                          this.state.SortOption ===
                            this.SortOptions.ByEconomyPrice &&
                          this.state.orderBy === this.orderBy.DESC
                            ? "col-12 mx-0 row filter-option-list selected-filter-option"
                            : "col-12 mx-0 row filter-option-list"
                        }
                        onClick={() =>
                          this.handleSortChoice(
                            this.SortOptions.ByEconomyPrice,
                            this.orderBy.DESC
                          )
                        }
                      >
                        {Translation.t("by_economy_price_High_to_Low")}
                      </div>
                    </>
                  ) : null}

                  {this.IsByBusinessPricePossible ? (
                    <>
                      <div
                        className={
                          this.state.SortOption ===
                            this.SortOptions.ByBusinessPrice &&
                          this.state.orderBy === this.orderBy.ASC
                            ? "col-12 mx-0 row filter-option-list selected-filter-option"
                            : "col-12 mx-0 row filter-option-list"
                        }
                        onClick={() =>
                          this.handleSortChoice(
                            this.SortOptions.ByBusinessPrice,
                            this.orderBy.ASC
                          )
                        }
                      >
                        {Translation.t("by_business_price_Low_to_High")}
                      </div>
                      <div
                        className={
                          this.state.SortOption ===
                            this.SortOptions.ByBusinessPrice &&
                          this.state.orderBy === this.orderBy.DESC
                            ? "col-12 mx-0 row filter-option-list selected-filter-option"
                            : "col-12 mx-0 row filter-option-list"
                        }
                        onClick={() =>
                          this.handleSortChoice(
                            this.SortOptions.ByBusinessPrice,
                            this.orderBy.DESC
                          )
                        }
                      >
                        {Translation.t("by_business_price_High_to_Low")}
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
          </OutsideClickHandler>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    //   fareType: state.bookingWiget.fareType,
    //   isMobile: state.global.isMobile,
    searchResponse: state.searchFlight.searchResult.data.searchResponse,
    filterCriteria: state.filteredFlights.FilterCriteria,
    flightIndex: state.flightSelection.flightIndex,
    //   alternateDayResponse: state.searchFlight.alternateDayResponse,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    FILTERUPDATE: (index) => {
      dispatch({
        type: filteredFlightsActions.FILTERUPDATE,
        flightIndex: index,
      });
    },
    // updateSortFilterCriteria: (filterCriteria) => {
    //   dispatch({
    //     type: filteredFlightsActions.UPDATESORTOPTIONS,
    //     filterCriteria: filterCriteria,
    //   });
    // },
    triggerRerender: () => {
      dispatch({
        type: filteredFlightsActions.UPDATECOUNTSORTUPDATE,
      });
    },
    searchResponseUpdate: (payload) => {
      dispatch({
        type: flightSelectionAction.UPDATESEARCHRESPONSE,
        payload: payload,
      });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(FlightSort);
