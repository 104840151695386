export class CreditCardInfo{

    constructor()
    {
        this.CreditCardType = null;
        this.CreditCardNumber = null;
        this.FullName = null;
        this.ExpireMonth = null;
        this.ExpireYear = null;
        this.Email = null;
        this._CVC = null;
        this.PhoneNumber = null;
        this.PhoneCountryCode="251";
        this.BillingAddess = null;
        this.Address = null;
        this.Country = null;
        this.City = null;
        this.PostalCode = null;
        this.Amount = null;
        this.Currency = null;

    }
}
