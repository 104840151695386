import React, { Component } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./BookingWidget.css";
import CabinClass from "./CabinClass";
import FareOptions from "./FareOption";
import JourneyType from "./JourneyType";
import PassengerSelector from "./passenger-selection/PassengerSelector";
import RecentSearch from "./recent-search/RecentSearch";
import SearchFormRemark from "./SearchFormRemark";
import StartBookingButton from "./StartBookingButton";
import AirportSelection from "./airport-selection/AirportSelection";
import DatePicker from "./booking-calendar/DatePicker";
import PromoCode from "./PromoCode";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import Multicity from "./multicity/Multicity";
import Stopover from "./stopover/StopOver";
import FlightSearchHeadText from "./FlightSearchHeadText";
import { flightSelectionAction } from "../../../redux/actions/booking/flightSelectionAction";
import FlightLoading from "../../shared/progress-loading/FlightLoading";
import { Redirect } from "react-router";
import { matrixAction } from "../../../redux/actions/booking/matrixAction";
import HttpService from "../../../service/shared/HttpService";
import Translation from "i18next";
import WidgetType from "./WidgetType";
import FlightStatus from "../../manage-my-booking/flight-status/FlightStatus";
import FlightSchedule from "../../manage-my-booking/flight-schedule/FlightSchedule";
import ManageTrip from "../../manage-my-booking/manage-trip/ManageTrip";
class BookingWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
    };
  }
  changeLoadingType = (
    redirectToFlightSelection = false,
    redirectToMatrix = false
  ) => {
    if (redirectToFlightSelection) {
      this.props.history.push("/flightSelect");
    } else if (redirectToMatrix) {
      if (this.props.journeyType === "roundTrip") {
        this.redirectToMatrix();
      } else if (this.props.journeyType === "oneWay") {
        this.props.history.push("/fareCalendar");
      } else {
        this.setState({ redirectToFlightSelection: true });
      }
    } else {
      if (this.state.isLoading) {
        this.setState({ isLoading: false, showModal: true });
      } else {
        this.setState({ isLoading: true });
      }
    }
  };
  getWidgetType = () => {
    if (this.props.widgetType === "bookFlight") {
      return (
        <>
          <FlightSearchHeadText />
          <RecentSearch />
          <Row className="mx-0">
            <Col xs={12} sm={12} md={4} lg={4}>
              {" "}
            </Col>
            <JourneyType />
          </Row>
          <SearchFormRemark />

          {this.getJourneyType()}

          <Row>{/* <FareOptions /> */}</Row>
          <Row>
            <PromoCode />
          </Row>
          <StartBookingButton changeLoading={this.changeLoadingType} />
        </>
      );
    } else if (this.props.widgetType === "flightStatus") {
      return <FlightStatus />;
    } else if (this.props.widgetType === "flightSchedule") {
      return <FlightSchedule />;
    } else {
      return <ManageTrip />;
    }
  };
  componentDidMount() {
    this.props.updateFlightIndex();
    document.title = "Ethiopian Airlines";
  }

  getJourneyType = () => {
    if (this.props.journeyType === "multicity") {
      return (
        <>
          <Multicity />
          <Row>
            <PassengerSelector />
            <CabinClass />
          </Row>
        </>
      );
    }

    if (this.props.journeyType === "stopover") {
      return (
        <>
          <Stopover />
          <Row>
            <PassengerSelector />
            <CabinClass />
          </Row>
        </>
      );
    } else
      return (
        <>
          <Modal
            show={this.state.showModal}
            onHide={() => {
              this.setState({ showModal: false });
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Error Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Something went wrong. we are working on getting this fixed as soon
              as we can. You may able to try again.
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-success"
                onClick={() => this.setState({ showModal: false })}
              >
                {Translation.t("ok")}
              </button>
            </Modal.Footer>
          </Modal>
          <Row>
            <AirportSelection flightIndex={0} />
          </Row>
          <Row>
            <PassengerSelector />
            <CabinClass />
          </Row>
          <Row>
            <DatePicker flightIndex={0} name="departure_date_0" />
            {this.props.journeyType === "roundTrip" && (
              <DatePicker
                flightIndex={0}
                name="return_date_0"
                isReturn={true}
                minDate={this.props.itineraryPart[0].params.when.departureDate}
              />
            )}
          </Row>
        </>
      );
  };

  redirectToMatrix = () => {
    var tempSerachRequest = this.props.flightSearchRequest;
    tempSerachRequest.searchType = "MATRIX";
    HttpService.postService(
      tempSerachRequest,
      "/Availability/AdvancedSearch",
      this.props.token
    )
      .then((response) => {
        this.props.saveMatrixResult(response.data);
        this.props.history.push("/matrix");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  render() {
    if (this.state.isLoading) {
      return (
        <>
          <FlightLoading />
        </>
      );
    }

    return (
      <>
        <div className="mt-0 mt-md-2 mt-lg-5">
          <div className="booking-widget">
            {/* <WidgetType /> */}
            <div className="booking-widget-body mt-5 mt-md-0">
              {this.getWidgetType()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    journeyType: state.bookingWiget.journyType,
    widgetType: state.bookingWiget.widgetType,
    itineraryPart: state.bookingWiget.itineraryPart,
    flightSearchRequest: state.searchFlight.searchRequest,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeJournyType: (journeyType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEJOURNYTYPE,
        journeyType: journeyType,
      }),
    updateFlightIndex: () => {
      dispatch({
        type: flightSelectionAction.UPDATEFLIGHTINDEX,
        flightIndex: 0,
      });
    },
    saveMatrixResult: (responseData) => {
      dispatch({
        type: matrixAction.INITIALIZESATE,
        responseData: responseData,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BookingWidget);
