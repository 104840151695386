import {
  CreateAccountModle,
  LoginModel,
  BillingInfo,
  UpdatePassword,
} from "../../../model/account/Account";
import AccountService from "../../../service/manage-my-booking/account/AccountService";
import { accountAction } from "../../actions/manage-my-booking/accountAction";

const initialState = {
  loginModel: new LoginModel(),
  createAccountModle: new CreateAccountModle(),
  isCreateAccountValid: true,
  billingInfo: new BillingInfo(),
  updatePassword: new UpdatePassword(),
  isLoginFormValid: false,
  profileCookie: null,
  firstName: null,
  lastName: null,
  profile: null,
  isTravelDateExpirationDateValid: true,
  currentAwardPoints: 0,
  trips: [],
};
const accountService = new AccountService();
const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountAction.VALIDATE:
      var tempLoginModel = accountService.validateLoginModel(state);
      return {
        ...state,
        loginModel: tempLoginModel.loginModel,
        isLoginFormValid: tempLoginModel.isLoginFormValid,
      };
    case accountAction.FOCUSUSERNAME:
      var tempLoginModel = state.loginModel;
      tempLoginModel.isUserNameValid = true;
      return {
        ...state,
        loginModel: tempLoginModel,
      };
    case accountAction.BLURUSERNAME:
      var tempLoginModel = state.loginModel;
      if (action.userName === "" || action.userName === null) {
        tempLoginModel.isUserNameValid = false;
      }
      tempLoginModel.userName = action.userName;
      return {
        ...state,
        loginModel: tempLoginModel,
      };
    case accountAction.FOCUSPASSWORD:
      var tempLoginModel = state.loginModel;
      tempLoginModel.isPasswordValid = true;
      return {
        ...state,
        loginModel: tempLoginModel,
      };
    case accountAction.BLURPASSWORD:
      var tempLoginModel = state.loginModel;
      if (action.password === "" || action.password === null) {
        tempLoginModel.isPasswordValid = false;
      }
      tempLoginModel.password = action.password;
      return {
        ...state,
        loginModel: tempLoginModel,
      };
    case accountAction.UPADTE:
      var updateAccountModel = accountService.updateCreateAccount(
        state,
        action.valueParameter,
        action.value
      );
      return {
        ...state,
        createAccountModle: updateAccountModel,
      };
    case accountAction.UPDATEPASSWORD:
      var updatePassword = accountService.updatePassword(
        state,
        action.valueParameter,
        action.value
      );
      return {
        ...state,
        updatePassword: updatePassword,
      };
    case accountAction.UPDATEBILLINGINFO:
      var updatedBillingInfo = accountService.updateBillingInfo(
        state,
        action.valueParameter,
        action.value
      );
      return {
        ...state,
        billingInfo: updatedBillingInfo,
      };
    case accountAction.UPDATETRAVELDOCUMENT:
      var updatedTravelDocument = accountService.updateTravelDocument(
        state,
        action.valueParameter,
        action.value
      );
      return {
        ...state,
        travelDocument: updatedTravelDocument,
      };
    case accountAction.CHANGEVALIDATION:
      var newAccountModel = accountService.changeValidationCreateAccountModel(
        state,
        action.domain,
        action.valueParameter,
        action.validationParameter
      );
      return {
        ...state,
        createAccountModle: newAccountModel,
      };
    case accountAction.UPDATEPASSWORDVALIDATION:
      var updatedPasswordModel = accountService.changeValidationPasswordUpdate(
        state,
        action.domain,
        action.valueParameter,
        action.validationParameter
      );
      return {
        ...state,
        UpdatePassword: updatedPasswordModel,
      };
    case accountAction.UPDATEBILLINGINFOVALIDATION:
      var newBillingInfo = accountService.changeValidationBillingInfo(
        state,
        action.domain,
        action.valueParameter,
        action.validationParameter
      );
      return {
        ...state,
        billingInfo: newBillingInfo,
      };
    case accountAction.VALIDATECREATEACCOUNT:
      var validateAccountModel = accountService.validateCreateAccount(state);
      return {
        ...state,
        createAccountModle: validateAccountModel,
      };
    case accountAction.UPDATETRAVELDOCUMENTVALIDATION:
      var validateTravelDocument =
        accountService.changeValidationTravelDocument(
          state,
          action.domain,
          action.valueParameter,
          action.validationParameter
        );

      return {
        ...state,
        isTravelDateExpirationDateValid: validateTravelDocument.profile,
        isTravelDateExpirationDateValid:
          validateTravelDocument.validationResult,
      };
    case accountAction.UPDATEFREQUENTFLYER:
      var updatedFrequentFlyer = accountService.updateFrequentFlyer(
        state,
        action.value,
        action.parameter,
        action.index
      );

      return {
        ...state,
        frequentFlyer: updatedFrequentFlyer,
      };
    case accountAction.UPDATEGENDER:
      var tempProfile = state.profile;
      tempProfile.userInfo.gender = action.value;
      return {
        ...state,
        profile: tempProfile,
      };
    case accountAction.SIGNEDIN:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        profileCookie: action.profileCookie,
        currentAwardPoints: action.currentAwardPoints,
      };
    case accountAction.INTIALZEPROFILE:
      var updateProfile = accountService.initialzeProfile(action.data);
      return {
        ...state,
        profile: updateProfile.profile,
        createAccountModle: updateProfile.createAccountModel,
      };
    case accountAction.ADDFREQUENTFLYER:
      var addnewFrequentFlyer = state.profile;
      addnewFrequentFlyer.frequentFlyers.push({
        airline: null,
        membershipID: null,
        membershipLevelTypeCode: null,
        membershipLevelValue: "0",
        accountBalance: 0,
        membershipStartDate: null,
      });
      return {
        ...state,
        profile: addnewFrequentFlyer,
      };
    case accountAction.REMOVEFREQUENTFLYER:
      var addnewFrequentFlyer = state.profile;
      addnewFrequentFlyer.frequentFlyers.pop();
      return {
        ...state,
        profile: addnewFrequentFlyer,
      };
    case accountAction.GETMYTRIPS:
      return {
        ...state,
        trips: action.data,
      };
    case accountAction.SIGNOUT:
      return {
        loginModel: new LoginModel(),
        createAccountModle: new CreateAccountModle(),
        isCreateAccountValid: true,
        billingInfo: new BillingInfo(),
        updatePassword: new UpdatePassword(),
        isLoginFormValid: false,
        profileCookie: null,
        firstName: null,
        lastName: null,
        profile: null,
        isTravelDateExpirationDateValid: true,
        currentAwardPoints: 0,
        trips: [],
      };
      default:
      return state;
  }
};
export default accountReducer;
