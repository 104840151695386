import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { ListGroup, Nav } from "react-bootstrap";
import Translation from "i18next";

import "./Header.css";
import LanguageSelector from "./LanguageSelector";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { Redirect } from "react-router";
import OutsideClickHandler from "react-outside-click-handler";

class SliderHeaderMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langSelectorStatus: "hide",
      mshowDropdownLinks: false,
      showLangSelector: false,
      showLoginPage: false,
      showModal: false,
      redirectToHome: false,
      redirectTocheckIn: false,
    };
  }
  toggleLangSelector = () => {
    if (this.state.langSelectorStatus === "hide") {
      this.setState({ langSelectorStatus: "show" });
    } else {
      this.setState({ langSelectorStatus: "hide" });
    }
  };
  render() {
    if (this.state.redirectToHome) {
      this.setState({ redirectToHome: false });
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    if (this.state.redirectTocheckIn) {
      this.setState({ redirectTocheckIn: false });
      return (
        <Redirect
          to={{
            pathname: "/check-in",
          }}
        />
      );
    }
    return (
      <div className={"slide-menu-mobile " + this.props.slideMenuStatus}>
        <ListGroup className="slide-menu-list">
          <ListGroup.Item className="border-top-0 slide-menu-list-item pt-5">
            <Nav.Link
              href=""
              className="px-4 header-link"
              onClick={() => {
                this.props.changeWidgetType("bookFlight");
                this.setState({ redirectToHome: true });
                this.props.toggleSlideMenu();
              }}
            >
              {Translation.t("book_a_flight")}
            </Nav.Link>
          </ListGroup.Item>
          <ListGroup.Item className="slide-menu-list-item">
            <Nav.Link
              href=""
              className="px-4 header-link"
              onClick={() => {
                this.setState({ redirectTocheckIn: true });
                this.props.toggleSlideMenu();
              }}
            >
              {Translation.t("check-in")}
            </Nav.Link>
          </ListGroup.Item>
          <ListGroup.Item className="slide-menu-list-item">
            <Nav.Link
              href=""
              className="px-4 header-link"
              onClick={() => {
                this.props.changeWidgetType("manageTrip");
                this.setState({ redirectToHome: true });
                this.props.toggleSlideMenu();
              }}
            >
              {Translation.t("manage_my_trips")}
            </Nav.Link>
          </ListGroup.Item>
          <ListGroup.Item className="slide-menu-list-item">
            <Nav.Link href="#pricing" className="px-4 header-link">
              {Translation.t("help")}
            </Nav.Link>
          </ListGroup.Item>
          <ListGroup.Item className="slide-menu-list-item">
            <Nav.Link
              href=""
              className="px-4 header-link"
              onClick={() => this.props.showLoginPageHandler()}
            >
              {Translation.t("login")}
            </Nav.Link>
          </ListGroup.Item>
          <ListGroup.Item className="slide-menu-list-item">
            <Nav.Link href="#pricing" className="px-4 header-link">
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({ langSelectorStatus: "hide" });
                }}
              >
                <div onClick={() => this.toggleLangSelector()}>
                  <span>
                    <FontAwesomeIcon
                      icon={faGlobe}
                      color="black"
                      className="lang-icon"
                    />
                  </span>
                  <span> EN</span>
                </div>
              </OutsideClickHandler>
            </Nav.Link>
          </ListGroup.Item>
          <LanguageSelector
            langSelectorStatus={this.state.langSelectorStatus}
          />
        </ListGroup>
      </div>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    isUserSignedIn: state.global.isUserSignedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(SliderHeaderMenu);
