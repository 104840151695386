import React, { useState } from "react";
import FlightStatusResult from "./FlightStatusResult";
import { Row, Col } from "react-bootstrap";
import Notification from "../../booking/booking-widget/Notification";
import moment from "moment";
// import FlightStatusHeader from "./FlightStatusHeader";
import EditFlightStatus from "./EditFlightStatus";
import FlightStatusHeader from "./FlightStatusHeader";
import FlightStatusFilter from "./FlightStatusFilter";
import Loading from "../../booking/seat-selection/Loading";
import { connect } from "react-redux";
import { flightScheduleAction } from "../../../redux/actions/manage-my-booking/flightScheduleAction";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";
import FlightInfoService from "../../../service/manage-my-booking/FlightInfoService";
import HttpService from "../../../service/shared/HttpService";
import { flightStatusAction } from "../../../redux/actions/manage-my-booking/flightStatusAction";
import { globalActionType } from "../../../redux/actions/shared/globalAction";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Translation from "i18next";

const AllFlightStatusResult = (props) => {
  let history = useHistory();

  const [fligthStatusDay, setFligthStatusDay] = useState("today");

  const flightInfoService = new FlightInfoService();
  const retrievFlightStatus = () => {
    if (
      (props.itineraryPart[0].params.from !== null &&
        props.itineraryPart[0].params.to !== null) ||
      props.flightStatusSearchType === 2
    ) {
      // by route
      if (props.flightStatusSearchType === 1) {
        var payload = {
          Origin: props.itineraryPart[0].params.from.value,
          Destination: props.itineraryPart[0].params.to.value,
        };

        flightInfoService
          .getFlightStatusByRoute(payload, props.token)
          .then((response) => {
            if (
              response.data.statusAPI === "ERROR" ||
              response.data.statusAPI === null
            ) {
              props.updateFlightStatusPresent(false);
            }
            if (response.data.statusAPI === "SUCCESS") {
              props.updateFlightStatusPresent(true);
              props.initFlightStatus({
                todayFlightStatusResponse:
                  response.data.todayFlightStatusResponse,
                tomorrowFlightStatusResponse:
                  response.data.tomorrowFlightStatusResponse,
              });
            }
            setLoading(false);
            props.updateIsLoading(false);
          })
          .catch((error) => {
            HttpService.getTokenServive().then((response) => {
              this.props.saveToken(response.data.tokenDetail.access_token);
              flightInfoService
                .getFlightStatusByRoute(payload, props.token)
                .then((response) => {
                  if (
                    response.data.statusAPI === "ERROR" ||
                    response.data.statusAPI === null
                  ) {
                    props.updateFlightStatusPresent(false);
                  }
                  if (response.data.statusAPI === "SUCCESS") {
                    props.updateFlightStatusPresent(true);
                    props.initFlightStatus({
                      todayFlightStatusResponse:
                        response.data.todayFlightStatusResponse,
                      tomorrowFlightStatusResponse:
                        response.data.tomorrowFlightStatusResponse,
                    });
                  }
                  setLoading(false);
                  props.updateIsLoading(false);
                });
            });
          });
      }
      // by flight number
      else {
        flightInfoService
          .getFlightStatusByFlightNumber(props.flightNumber, props.token)
          .then((response) => {
            console.log(response);
            console.log("by flight number");
            if (response.data.statusAPI === "ERROR") {
              props.updateFlightStatusPresent(false);
            }
            if (response.data.statusAPI === "SUCCESS") {
              props.updateFlightStatusPresent(true);
              props.initFlightStatus({
                todayFlightStatusResponse:
                  response.data.todayFlightStatusResponse,
                tomorrowFlightStatusResponse:
                  response.data.tomorrowFlightStatusResponse,
                yesterdayFlightStatusResponse:
                  response.data.yesterdayFlightStatusResponse,
              });
            }
            setLoading(false);
            props.updateIsLoading(false);
          })
          .catch((error) => {
            HttpService.getTokenServive().then((response) => {
              this.props.saveToken(response.data.tokenDetail.access_token);
              flightInfoService
                .getFlightStatusByFlightNumber(props.flightNumber, props.token)
                .then((response) => {
                  console.log(response);
                  console.log("by flight number");
                  if (response.data.statusAPI === "ERROR") {
                    props.updateFlightStatusPresent(false);
                  }
                  if (response.data.statusAPI === "SUCCESS") {
                    props.updateFlightStatusPresent(true);
                    props.initFlightStatus({
                      todayFlightStatusResponse:
                        response.data.todayFlightStatusResponse,
                      tomorrowFlightStatusResponse:
                        response.data.tomorrowFlightStatusResponse,
                      yesterdayFlightStatusResponse:
                        response.data.yesterdayFlightStatusResponse,
                    });
                  }
                  setLoading(false);
                  props.updateIsLoading(false);
                });
            });
          });
      }
    }
  };

  const [loading, setLoading] = useState(() => {
    props.updateFlightStatusPresent(true);
    retrievFlightStatus();
    return true;
  });
  // const getResultNumberAccordingly = () => {
  //   var numResult = 0;

  //   if (fligthStatusDay === "today") {
  //     numResult =
  //       props.statusFlights.todayFlightStatusResponse !== null
  //         ? props.statusFlights.todayFlightStatusResponse
  //             .routeFlightStatusResponses.length
  //         : 0;
  //   }
  //   if (fligthStatusDay === "tomorrow") {
  //     numResult =
  //       props.statusFlights.tomorrowFlightStatusResponse !== null
  //         ? props.statusFlights.tomorrowFlightStatusResponse
  //             .routeFlightStatusResponses.length
  //         : 0;
  //   }
  //   return numResult;
  // };
  return (
    <>
      {props.isFlightStatusPresent ? (
        <>
          {props.isLoading || loading ? (
            <Loading />
          ) : (
            <>
              <EditFlightStatus flightstatusResults={props.statusFlights} />
              <div className="mt-4 flight-selection-container">
                <FlightStatusHeader flightstatusResults={props.statusFlights} />
                <div className="flight-status-ribbons-container row justify-content-center mx-0">
                  {props.statusFlights.yesterdayFlightStatusResponse ===
                  undefined ? null : (
                    <div
                      className={
                        fligthStatusDay === "yesterday"
                          ? "flight-status-ribbon justify-content-center d-flex align-items-center active-ribbon"
                          : "flight-status-ribbon justify-content-center d-flex align-items-center"
                      }
                      onClick={() => setFligthStatusDay("yesterday")}
                    >
                      <span>
                        {Translation.t("yesterday")}
                        <br />
                        <span className="flight-status-ribbon-date d-flex justify-content-center">
                          {moment(props.statusFlights.date)
                            .subtract(1, "d")
                            .locale("en")
                            .format("MMMM DD")}
                        </span>
                      </span>
                    </div>
                  )}
                  <div
                    className={
                      ((fligthStatusDay === "today" ||
                        fligthStatusDay === "yesterday") &&
                        props.flightStatusSearchType === 1) ||
                      fligthStatusDay === "today"
                        ? "flight-status-ribbon justify-content-center d-flex align-items-center active-ribbon"
                        : "flight-status-ribbon justify-content-center d-flex align-items-center"
                    }
                    onClick={() => setFligthStatusDay("today")}
                  >
                    <span>
                      {Translation.t("today")}
                      <br />
                      <span className="flight-status-ribbon-date d-flex justify-content-center">
                        {moment(props.statusFlights.date)
                          .locale("en")
                          .format("MMMM DD")}
                      </span>
                    </span>
                  </div>
                  <div
                    className={
                      fligthStatusDay === "tomorrow"
                        ? "flight-status-ribbon justify-content-center d-flex align-items-center active-ribbon"
                        : "flight-status-ribbon justify-content-center d-flex align-items-center"
                    }
                    onClick={() => setFligthStatusDay("tomorrow")}
                  >
                    <span>
                      {Translation.t("tomorrow")}
                      <br />
                      <span className="flight-status-ribbon-date d-flex justify-content-center">
                        {moment(props.statusFlights.date)
                          .add(1, "d")
                          .locale("en")
                          .format("MMMM DD")}
                      </span>
                    </span>
                  </div>
                </div>

                {props.flightStatusSearchType === 2 ? null : (
                  <>
                    {fligthStatusDay === "today" ||
                    fligthStatusDay === "yesterday" ? (
                      <div className="my-3" key="today-filter">
                        <div className="flight-status-result-header mx-0">
                          <FlightStatusFilter
                            fligthStatusDay={fligthStatusDay}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="my-3" key="tommorow-filter">
                        <div className="flight-status-result-header mx-0">
                          <FlightStatusFilter
                            fligthStatusDay={fligthStatusDay}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div className="my-3">
                  <div className="flight-status-result-header mx-0">
                    <Row className="mx-0">
                      <Col md={5}>
                        <div className="d-flex justify-content-start">
                          <b className="ml-0 ml-md-3">
                            {Translation.t("departure_time")}
                          </b>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-start">
                          <b>{Translation.t("stops")}</b>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-flex justify-content-center">
                          <b>{Translation.t("status")}</b>
                        </div>
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </div>
                {props.flightStatusSearchType === 2 ? (
                  // #flight no
                  <div>
                    {fligthStatusDay === "yesterday" ? (
                      <>
                        {props.statusFlights.yesterdayFlightStatusResponse
                          .flights !== null ? (
                          <>
                            {props.statusFlights.yesterdayFlightStatusResponse.flights.map(
                              (flight) => {
                                return (
                                  <FlightStatusResult
                                    flights={[flight]}
                                    identifier={
                                      flight.departureInfo.scheduledDateTime
                                    }
                                    flightNumber={
                                      props.statusFlights
                                        .yesterdayFlightStatusResponse
                                        .flightNumber
                                    }
                                    airlineCode={
                                      props.statusFlights
                                        .yesterdayFlightStatusResponse
                                        .airlineCode
                                    }
                                  />
                                );
                              }
                            )}
                          </>
                        ) : (
                          <Notification
                            header={""}
                            body={"No flight available"}
                          />
                        )}
                      </>
                    ) : null}
                    {fligthStatusDay === "today" ? (
                      <>
                        {props.statusFlights.todayFlightStatusResponse
                          .flights !== null ? (
                          <>
                            {props.statusFlights.todayFlightStatusResponse.flights.map(
                              (flight) => {
                                return (
                                  <FlightStatusResult
                                    flights={[flight]}
                                    identifier={
                                      flight.departureInfo.scheduledDateTime
                                    }
                                    flightNumber={
                                      props.statusFlights
                                        .todayFlightStatusResponse.flightNumber
                                    }
                                    airlineCode={
                                      props.statusFlights
                                        .todayFlightStatusResponse.airlineCode
                                    }
                                  />
                                );
                              }
                            )}
                          </>
                        ) : (
                          <Notification
                            header={""}
                            body={"No flight available"}
                          />
                          // <span>no flight available</span>
                        )}
                      </>
                    ) : null}
                    {fligthStatusDay === "tomorrow" ? (
                      <>
                        {props.statusFlights.tomorrowFlightStatusResponse
                          .flights !== null ? (
                          <>
                            {props.statusFlights.tomorrowFlightStatusResponse.flights.map(
                              (flight) => {
                                return (
                                  <FlightStatusResult
                                    flights={[flight]}
                                    identifier={
                                      flight.departureInfo.scheduledDateTime
                                    }
                                    flightNumber={
                                      props.statusFlights
                                        .tomorrowFlightStatusResponse
                                        .flightNumber
                                    }
                                    airlineCode={
                                      props.statusFlights
                                        .tomorrowFlightStatusResponse
                                        .airlineCode
                                    }
                                  />
                                );
                              }
                            )}
                          </>
                        ) : (
                          <Notification
                            header={""}
                            body={Translation.t("no_flight_available")}
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                ) : (
                  // route
                  <div>
                    {fligthStatusDay === "today" ||
                    fligthStatusDay === "yesterday" ? (
                      <>
                        {props.statusFlights.todayFlightStatusResponse !==
                        null ? (
                          <>
                            {props.statusFlights.todayFlightStatusResponse.routeFlightStatusResponses.map(
                              (routeFlightStatusResponses) => {
                                // (flight) => {
                                return (
                                  <>
                                    <FlightStatusResult
                                      flights={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flights
                                      }
                                      identifier={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flights[0].departureInfo
                                          .scheduledDateTime
                                      }
                                      flightNumber={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flightNumber
                                      }
                                      airlineCode={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .airlineCode
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <Notification
                            header={""}
                            body={Translation.t("no_flight_available")}
                          />
                        )}
                      </>
                    ) : null}
                    {fligthStatusDay === "tomorrow" ? (
                      <>
                        {props.statusFlights.tomorrowFlightStatusResponse !==
                        null ? (
                          <>
                            {props.statusFlights.tomorrowFlightStatusResponse.routeFlightStatusResponses.map(
                              (routeFlightStatusResponses) => {
                                return (
                                  <>
                                    <FlightStatusResult
                                      flights={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flights
                                      }
                                      identifier={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flights[0].departureInfo
                                          .scheduledDateTime
                                      }
                                      flightNumber={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .flightNumber
                                      }
                                      airlineCode={
                                        routeFlightStatusResponses
                                          .segmentFlightStatusResponses[0]
                                          .airlineCode
                                      }
                                    />
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <Notification
                            header={""}
                            body={Translation.t("no_flight_available")}
                          />
                        )}
                      </>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div className="container mt-5">
          <Notification
            header={
              props.flightStatusSearchType === 1
                ? Translation.t("no_flights_found")
                : Translation.t("invalid_flight_number")
            }
            body={""}
          />
          <button
            className="bk-submit-btn btn-ethiopian btn ml-auto d-flex align-items-center"
            onClick={() => {
              history.push("/");
              props.updateFlightStatusPresent(true);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="pl-3"> {Translation.t("go_back")}</span>
          </button>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    token: state.searchFlight.token,
    itineraryPart: state.bookingWiget.itineraryPart,
    isLoading: state.global.isLoading,
    flightStatusSearchType: state.flightStatus.flightStatusSearchType,
    flightNumber: state.flightStatus.flightNumber,
    statusFlights: state.flightStatus.statusFlights,
    backedUpFlights: state.flightStatus.backedUpFlights,
    isFlightStatusPresent: state.flightStatus.isFlightStatusPresent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateBackedupSchedules: (value) =>
      dispatch({
        type: flightScheduleAction.SCHEDULEINITFLIGHTS,
        payload: value,
      }),
    saveToken: (token) =>
      dispatch({
        type: searchFlightAction.SAVETOKEN,
        token: token,
      }),
    updateFlightStatusPresent: (value) =>
      dispatch({
        type: flightStatusAction.ISFLIGHTSTATUSPRESENT,
        payload: value,
      }),
    initFlightStatus: (value) =>
      dispatch({
        type: flightStatusAction.INITFLIGHTSTATUS,
        payload: value,
      }),
    updateIsLoading: (value) =>
      dispatch({
        type: globalActionType.UPDATEISLOADING,
        value: value,
      }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFlightStatusResult);
