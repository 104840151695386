import { exchangeAction } from "../../actions/exchange/exchangeAction";

const intialState ={
    exchangePassengers:[],
    flightSearchRequest:{},
    flightSearchExtraInfo:{},
    flightSearchResult:{},
    flightSelectionResult:{},
    flightSearchRequestForConfirmation:{},
    originalPayment:{}
}
const exchangeReducer =(state = intialState,action)=>
{

    switch(action.type)
    {
    case exchangeAction.SAVEPASSENGER:
       let exchangePassengers =action.value;
        return {
          ...state,
          exchangePassengers:exchangePassengers
        };
    case exchangeAction.SAVEFLIGHTSEARCH:  
    return {
        ...state,
        flightSearchRequest:action
      };  
    case exchangeAction.SAVEFLIGHTEXTRAINFO:
        return {
            ...state,
            flightSearchExtraInfo:action.value
          }; 
    case exchangeAction.SAVEFLIGHTSEARCHRESULT:
        return {...state,
          flightSearchResult:action.value}; 
    case exchangeAction.SAVEFLIGHTSELECTIONRESULT:
      return{...state,
        flightSelectionResult:action.value
      }
    case exchangeAction.SAVeFLIGHTSEARCHRFORCONFIRMATION:
      return {
        ...state,
        flightSearchRequestForConfirmation:action.value
      }  
      case exchangeAction.SAVEORIGINALPAYMENT:
        return {
          ...state,
          originalPayment:action.value
        }  
        default:
            return state;
    }
}
export default exchangeReducer;