import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import {
  faBars,
  faEllipsisH,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import OutsideClickHandler from "react-outside-click-handler";
import Translation from "i18next";

import "./Header.css";
import HeaderDropdownLinkMD from "./HeaderDropdownLinkMD";
import HeaderDropdownLinkSM from "./HeaderDropdownLinkSM";
import SliderHeaderMenu from "./SlideHeaderMenu";
import LanguageSelector from "./LanguageSelector";
import Login from "../../manage-my-booking/account/Login";
import { connect } from "react-redux";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { Redirect } from "react-router";
import ForgotPasswordModal from "../../manage-my-booking/account/ForgotPasswordModal";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownLinks: false,
      slideMenuStatus: "hide",
      showLangSelector: false,
      showLoginPage: false,
      showModal: false,
      redirectToHome: false,
      redirectTocheckIn: false,
    };
  }
  setShowModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleSlideMenu = () => {
    if (this.state.slideMenuStatus === "hide") {
      this.setState({ slideMenuStatus: "show" });
    } else {
      this.setState({ slideMenuStatus: "hide" });
    }
  };
  toggleLanSelector = () => {
    this.setState({ showLangSelector: !this.state.showLangSelector });
  };
  showLoginPageHandler = () => {
    this.setState({
      showLoginPage: !this.state.showLoginPage,
      slideMenuStatus: "hide",
    });
  };

  toggleShowDropdownLinks = () => {
    this.setState({ showDropdownLinks: !this.state.showDropdownLinks });
  };

  render() {
    if (this.state.redirectToHome) {
      this.setState({ redirectToHome: false });
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    if (this.state.redirectTocheckIn) {
      this.setState({ redirectTocheckIn: false });
      return (
        <Redirect
          to={{
            pathname: "/check-in",
          }}
        />
      );
    }
    return (
      <>
        {this.state.showModal && (
          <ForgotPasswordModal setShowModal={this.setShowModal} />
        )}
        <Navbar bg="white" variant="white" className="header-nav ">
          <div className="pl-sm-0 pl-md-5 pl-lg-5 pl-xl-5">
            {/* <div className="header-menu-sm-container">
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({ slideMenuStatus: "hide" });
                }}
              >
                <div
                  className="header-menu-sm-container"
                  onClick={() => this.toggleSlideMenu()}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    color="black"
                    className="menu-icon-sm"
                  />
                </div>
                <SliderHeaderMenu
                  slideMenuStatus={this.state.slideMenuStatus}
                  showLoginPageHandler={this.showLoginPageHandler}
                  toggleSlideMenu={this.toggleSlideMenu}
                />
              </OutsideClickHandler>
            </div> */}
            <a href="/">
              {" "}
              <img
                src="/logo.png"
                alt="Ethiopian Logo"
                className="logo-image mb-2"
              />
            </a>

            <span></span>
          </div>
          {/* <div className="container header-links-md pt-3">
            <Nav className="ml-5">
              <Nav.Link
                href=""
                className="px-4 header-link"
                onClick={() => {
                  this.props.changeWidgetType("bookFlight");
                  this.setState({ redirectToHome: true });
                }}
              >
                {Translation.t("book_a_flight")}
              </Nav.Link>
              <Nav.Link
                href=""
                className="px-4 header-link check-in-link"
                onClick={() => {
                  this.setState({ redirectTocheckIn: true });
                }}
              >
                {Translation.t("check-in")}
              </Nav.Link>
              <Nav.Link
                href=""
                className="px-4 header-link manage-trip-link"
                onClick={() => {
                  this.props.changeWidgetType("manageTrip");
                  this.setState({ redirectToHome: true });
                }}
              >
                {Translation.t("manage_my_trips")}
              </Nav.Link>
              <Nav.Link
                href="https://www.ethiopianairlines.com/aa/services"
                className="px-4 header-link help-link"
              >
                {Translation.t("help")}
              </Nav.Link>
              <OutsideClickHandler
                onOutsideClick={() => {
                  this.setState({ showDropdownLinks: false });
                }}
              >
                <div className="px-4 pt-1 header-link dropdown-link">
                  <span
                    onClick={() => this.toggleShowDropdownLinks()}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      color="black"
                      className="lang-icon"
                    />
                  </span>
                  {this.state.showDropdownLinks && (
                    <>
                      <HeaderDropdownLinkMD toggleShowDropdownLinks={this.toggleShowDropdownLinks}/> <HeaderDropdownLinkSM toggleShowDropdownLinks={this.toggleShowDropdownLinks} />
                    </>
                  )}
                </div>
              </OutsideClickHandler>
              <Nav.Link href="#pricing" className="px-4 header-link">
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({ showLangSelector: false });
                  }}
                >
                  <div onClick={() => this.toggleLanSelector()}>
                    <span></span>
                    <span>{Translation.t("current language")}</span>
                    <svg
                      id="angle-down-solid"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      style={{ height: "11px", padding: " 0 7px" }}
                    >
                      <path d="M56.293 16.003c1.761-1.645 4.553-1.645 6.314 0 1.857 1.734 1.857 4.623 0 6.357l-27.45 25.638c-1.761 1.645-4.553 1.645-6.314 0l-27.45-25.638c-0.899-0.839-1.393-1.963-1.393-3.178s0.494-2.339 1.393-3.178c1.761-1.645 4.553-1.645 6.314 0l24.293 22.689 24.293-22.689z"></path>
                    </svg>
                  </div>

                  {this.state.showLangSelector && (
                    <LanguageSelector
                      onLangButtonClicked={this.toggleLanSelector}
                    />
                  )}
                </OutsideClickHandler>
              </Nav.Link>
            </Nav>
          </div>
          <Nav.Link className="pr-4 mr-4 header-link"></Nav.Link>
          <OutsideClickHandler
            onOutsideClick={() => {
              this.setState({ showLoginPage: false });
            }}
          >
            {this.props.isUserSignedIn && this.props.firstName !== null ? (
              <div
                className="media signed-in-user header-link"
                onClick={() => this.showLoginPageHandler()}
              >
                <div className="justify-content-center d-flex user-name-login-box">
                  <span className="user-name-text d-flex align-self-center">
                    {this.props.firstName[0] + this.props.lastName[0]}
                  </span>
                </div>

                <div className="media-body mt-3">
                  <span className="align-bottom">
                    {this.state.showLoginPage ? (
                      <FontAwesomeIcon
                        icon={faAngleUp}
                        size="2x"
                        className="align-bottom"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAngleDown}
                        size="2x"
                        className="align-bottom"
                      />
                    )}
                  </span>
                </div>
              </div>
            ) : (
              <Nav.Link
                onClick={() => this.showLoginPageHandler()}
                href=""
                className="pr-4 mr-4 header-link login-link"
              >
                {Translation.t("login")}
              </Nav.Link>
            )}
            <div className={this.props.isUserSignedIn ? "mt-5" : ""}>
              <Login
                showLoginPage={this.state.showLoginPage}
                showLoginPageHandler={this.showLoginPageHandler}
                modalHandler={this.setShowModal}
              />
            </div>
          </OutsideClickHandler> */}
        </Navbar>
      </>
    );
  }
}
const mapStateTopProps = (state) => {
  return {
    isUserSignedIn: state.global.isUserSignedIn,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeWidgetType: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Header);
