import {
  Amount,
  AncillaryOperation,
  BaggageModel,
  BaggageRequest,
  Bags,
} from "../../../model/API/AddBaggage";

export default class BaggageService {
  addBag = (
    state,
    passengerId,
    ancillaryIndex,
    ancillaryCode,
    price,
    itinerayIndex,
    itineryId,
    itineraryList
  ) => {
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal + price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerId === passengerId
    )[0];
    currentPassenger.bags[itinerayIndex][ancillaryIndex].quantity++;
    if (currentPassenger.bags[itinerayIndex][ancillaryIndex].code === null) {
      currentPassenger.bags[itinerayIndex][ancillaryIndex].code = ancillaryCode;
      currentPassenger.bags[itinerayIndex][ancillaryIndex].itineryId =
        itineryId;
      var tempItineraryList = itineraryList.filter(
        (i) => i["@id"] === itineryId
      )[0];
      currentPassenger.bags[itinerayIndex][ancillaryIndex].id =
        tempItineraryList.segments[0].origin +
        "-" +
        tempItineraryList.segments[tempItineraryList.segments.length - 1]
          .destination +
        "-" +
        ancillaryCode +
        "-" +
        passengerId;
      currentPassenger.amount[itinerayIndex].id =
        tempItineraryList.segments[0].origin +
        "-" +
        tempItineraryList.segments[tempItineraryList.segments.length - 1]
          .destination +
        "-" +
        passengerId;
    }
    currentPassenger.amount[itinerayIndex].totalQuantity++;
    currentPassenger.amount[itinerayIndex].totalPrice += price;

    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };
  minusBag = (state, passengerId, ancillaryIndex, price, itinerayIndex) => {
    var baggageModelList = [...state.baggageModel];
    var overAllTotal = state.overAllTotal - price;
    var currentPassenger = baggageModelList.filter(
      (p) => p.passengerId === passengerId
    )[0];
    currentPassenger.bags[itinerayIndex][ancillaryIndex].quantity--;
    currentPassenger.amount[itinerayIndex].totalQuantity--;
    currentPassenger.amount[itinerayIndex].totalPrice -= price;
    return {
      baggageModel: baggageModelList,
      overAllTotal: overAllTotal,
    };
  };
  intializeState = (data) => {
    if (data?.itinerary?.itineraryParts !== null) {
      try {
        var numberOfItinerary = data?.itinerary?.itineraryParts?.length;
        var numberOfAncillary = data?.ancillaryGroups.filter(
          (g) => g.code === "BG"
        )[0].ancillaries?.length;
        var passengerList = [];
        data.ancillaryPassengers.forEach((passenger) => {
          var tempPassenger = new BaggageModel();
          tempPassenger.passengerId = passenger["@id"];
          for (
            let indexOfItinerary = 0;
            indexOfItinerary < numberOfItinerary;
            indexOfItinerary++
          ) {
            for (
              let indexOfAncillary = 0;
              indexOfAncillary < numberOfAncillary;
              indexOfAncillary++
            ) {
              tempPassenger.bags[indexOfItinerary] = [];
            }
          }
          for (
            let indexOfItinerary = 0;
            indexOfItinerary < numberOfItinerary;
            indexOfItinerary++
          ) {
            for (
              let indexOfAncillary = 0;
              indexOfAncillary < numberOfAncillary;
              indexOfAncillary++
            ) {
              var bag = new Bags();
              tempPassenger.bags[indexOfItinerary][indexOfAncillary] = bag;
            }
          }
          for (var index2 = 0; index2 < numberOfItinerary; index2++) {
            var amount = new Amount();
            tempPassenger.amount.push(amount);
          }
          passengerList.push(tempPassenger);
        });
        return { passengerList: passengerList, ancillaryData: data };
      } catch (error) {
        console.log(error);
        return { passengerList: [], ancillaryData: data };
      }
    }
  };
  buildBaggageRequest = (
    bagIsAdded,
    itineraryList,
    baggaeModel,
    cookieSabreDataRequest,
    oldBaggageRequest
  ) => {
    var newBaggageRequest = new BaggageRequest();
    newBaggageRequest.cookieSabreDataRequest = cookieSabreDataRequest;
    baggaeModel.forEach((passenger) => {
      passenger.bags.forEach((bags) => {
        bags.forEach((bag) => {
          if (!bagIsAdded) {
            if (bag.quantity !== 0) {
              var tempItineraryList = itineraryList.filter(
                (i) => i["@id"] === bag.itineryId
              )[0];
              var tempAncillaryOperations = new AncillaryOperation();
              tempAncillaryOperations.ancillaryCode = bag.code;
              tempAncillaryOperations.selectedTravelPart.origin =
                tempItineraryList.segments[0].origin;
              tempAncillaryOperations.selectedTravelPart.destination =
                tempItineraryList.segments[
                  tempItineraryList.segments.length - 1
                ].destination;
              tempAncillaryOperations.selectedTravelPart.departure =
                tempItineraryList.segments[0].departure;
              tempAncillaryOperations.selectedTravelPart.id =
                tempItineraryList["@id"];
              tempAncillaryOperations.selectedPassengers.push({
                index: parseInt(passenger.passengerId),
                quantity: bag.quantity,
              });
              tempAncillaryOperations.id =
                tempAncillaryOperations.selectedTravelPart.origin +
                "-" +
                tempAncillaryOperations.selectedTravelPart.destination +
                "-" +
                bag.code +
                "-" +
                passenger.passengerId;
              newBaggageRequest.ancillaryOperations.push(
                tempAncillaryOperations
              );
            }
          } else {
            if (bag.itineryId !== null && bag.itineryId !== undefined) {
              var tempItineraryList = itineraryList.filter(
                (i) => i["@id"] === bag.itineryId
              )[0];
              var id =
                tempItineraryList.segments[0].origin +
                "-" +
                tempItineraryList.segments[
                  tempItineraryList.segments.length - 1
                ].destination +
                "-" +
                bag.code +
                "-" +
                passenger.passengerId;
              var tempOldBaggageRequest =
                oldBaggageRequest.ancillaryOperations.filter(
                  (b) => b.id === id
                )[0];

              if (
                tempOldBaggageRequest === undefined ||
                tempOldBaggageRequest === null
              ) {
                if (bag.quantity !== 0) {
                  var tempAncillaryOperations = new AncillaryOperation();
                  tempAncillaryOperations.ancillaryCode = bag.code;
                  tempAncillaryOperations.selectedTravelPart.origin =
                    tempItineraryList.segments[0].origin;
                  tempAncillaryOperations.selectedTravelPart.destination =
                    tempItineraryList.segments[
                      tempItineraryList.segments.length - 1
                    ].destination;
                  tempAncillaryOperations.selectedTravelPart.departure =
                    tempItineraryList.segments[0].departure;
                  tempAncillaryOperations.selectedTravelPart.id =
                    tempItineraryList["@id"];
                  tempAncillaryOperations.selectedPassengers.push({
                    index: parseInt(passenger.passengerId),
                    quantity: bag.quantity,
                  });
                  tempAncillaryOperations.id =
                    tempAncillaryOperations.selectedTravelPart.origin +
                    "-" +
                    tempAncillaryOperations.selectedTravelPart.destination +
                    "-" +
                    bag.code +
                    "-" +
                    passenger.passengerId;
                  oldBaggageRequest.ancillaryOperations.push(
                    tempAncillaryOperations
                  );
                }
              } else {
                if (
                  tempOldBaggageRequest.selectedPassengers[0].quantity === 0 &&
                  bag.quantity === 0
                ) {
                  oldBaggageRequest.ancillaryOperations =
                    oldBaggageRequest.ancillaryOperations.filter(
                      (b) => b.id !== id
                    );
                } else {
                  tempOldBaggageRequest.selectedPassengers[0].quantity =
                    bag.quantity;
                }
              }
            }
          }
        });
      });
    });
    if (bagIsAdded) {
      return oldBaggageRequest;
    } else {
      return newBaggageRequest;
    }
  };
  removeBaggage = (id, baggaeModel, price, assignedQuantity) => {
    baggaeModel.forEach((passenger) => {
      passenger.bags.forEach((bags) => {
        bags.forEach((bag) => {
          if (bag.id === id) {
            bag.quantity = 0;
            var idForAmountTemp = id.split("-");
            var idForAmount =
              idForAmountTemp[0] +
              "-" +
              idForAmountTemp[1] +
              "-" +
              idForAmountTemp[3];
            var passengerAmount = passenger.amount.filter(
              (a) => a.id === idForAmount
            )[0];
            passengerAmount.totalQuantity -= assignedQuantity;
            passengerAmount.totalPrice -= price;
          }
        });
      });
    });
    return baggaeModel;
  };
  groupArrayOfObjects = (list, key) => {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  addToCart = (ancillaryOperationResults, ancillaryData) => {
    var ancillaryForShoppingCart = [];
    var ancillaryList = ancillaryData.ancillaryGroups.filter(
      (a) => a.code === "BG"
    )[0];

    ancillaryOperationResults.forEach((ancillaryOperationResult) => {
      if (ancillaryOperationResult.assignedQuantity !== 0) {
        var tempPassenger = ancillaryData.ancillaryPassengers.filter(
          (p) => p.index === ancillaryOperationResult.passengerIndex
        )[0];
        var tempAncillary = ancillaryList.ancillaries.filter(
          (x) => x.code === ancillaryOperationResult.ancillaryCode
        )[0];

        var tempAncillary = {
          origin: ancillaryOperationResult.selectedTravelPart.origin,
          destination: ancillaryOperationResult.selectedTravelPart.destination,
          firstName: tempPassenger.firstName,
          lastName: tempPassenger.lastName,
          type: tempPassenger.type,
          ancillaryName: tempAncillary.name,
          price:
            tempAncillary.price * ancillaryOperationResult.assignedQuantity,
          passengerIndex: ancillaryOperationResult.passengerIndex,
          key:
            ancillaryOperationResult.selectedTravelPart.origin +
            "-" +
            ancillaryOperationResult.selectedTravelPart.destination,
          departure: ancillaryOperationResult.selectedTravelPart.departure,
          ancillaryCode: ancillaryOperationResult.ancillaryCode,
          selectedTravelPartID:
            ancillaryOperationResult.selectedTravelPart["@id"],
          id:
            ancillaryOperationResult.selectedTravelPart.origin +
            "-" +
            ancillaryOperationResult.selectedTravelPart.destination +
            "-" +
            ancillaryOperationResult.ancillaryCode +
            "-" +
            ancillaryOperationResult.passengerIndex,
          assignedQuantity: ancillaryOperationResult.assignedQuantity,
        };
        ancillaryForShoppingCart.push(tempAncillary);
      }
    });
    var groupedResult = this.groupArrayOfObjects(
      ancillaryForShoppingCart,
      "key"
    );
    return groupedResult;
  };
}
