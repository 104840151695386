import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { AirportFullName } from "../../../../service/shared/AirportFullName";
import Translation from "i18next";
import PaymentSummaryHeader from "../payment-summary/PaymentSummaryHeader";
import PaymentSummaryPassenger from "../payment-summary/PaymentSummaryPassenger";
import PaymentSummaryTripDetail from "../payment-summary/PaymentSummaryTripDetail";
import { useState } from "react";
import Notification from "../../../booking/booking-widget/Notification";

const TeleBirrPaymentSummary = (props) => {
  const [message, setMessage]=useState(null);
  const getCityName = (airport) => {
    return AirportFullName.getCityName(airport);
  };
  const openTeleBirrPayment=()=>{
    if (!window.consumerapp) {
      setMessage(
        "Unable to open the payment page, Please try again"
      );
    } else {
      try {
        window.handleinitDataCallback = function () {
          window.location.href =
            "https://ibe.ethiopianairlines.com/tele-birr-payment-summary-success";
        };

        window.consumerapp.evaluate(
          JSON.stringify({
            functionName: "js_fun_start_pay",
            params: {
              rawRequest:
              props.rawRequest,
              functionCallBackName: "handleinitDataCallback",
            },
          })
        );
      } catch (error) {
        setMessage("Sorry, we couldn't process your request at the moment. Please try again later.");
      }
    }
  }
  return (
    <section className="container">
      {( props.rawRequest !=="") &&<div className="d-flex justify-content-end"><button className="btn-block btn-ethiopian  bk-submit-btn px-3 my-3 green-bg" onClick={()=>{

openTeleBirrPayment()}} >Pay with Telebirr</button></div>}
      {message !== null && <Notification info={false} header={message} body="" />}
      <PaymentSummaryHeader
        pnr={props.reservationDetail.pnr.reloc}
        type="Tele Birr Payment"
      />
      
      <section className="payment-summary-confirmation-header">
        <Row>
          <Col xs={12} sm={12} md={4} lg={3}>
            <div>
              <div> {Translation.t("confirmation_code")}</div>
              <h3>
                <mark>{props.reservationDetail.pnr.reloc}</mark>
              </h3>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={6}>
            <div className="payment-summary-itinerary pt-3">
              <span>
                {getCityName(
                  props.reservationDetail.pnr.itinerary.itineraryParts[0]
                    .segments[0].origin
                )}
              </span>
              <span className="px-3"></span>
              <span>
                {getCityName(
                  props.reservationDetail.pnr.itinerary.itineraryParts[0]
                    .segments[
                    props.reservationDetail.pnr.itinerary.itineraryParts[0]
                      .segments.length - 1
                  ].destination
                )}
              </span>
            </div>
          </Col>
          {/* <Col xs={12} sm={12} md={8} lg={12}>
            <PaymentSummaryAction
              itineraryParts={
                props.reservationDetail.pnr.itinerary.itineraryParts
              }
              state={props.reservationDetail}
            />
          </Col> */}
        </Row>
      </section>
      <PaymentSummaryTripDetail
        tripDetail={props.reservationDetail.pnr.itinerary.itineraryParts}
      />
      <PaymentSummaryPassenger
        passengerList={props.reservationDetail.pnr.passengers}
        contactInfo={props.reservationDetail.pnr.contact}
        passengerTicketInfo={
          props.reservationDetail.pnr.travelPartsAdditionalDetails.length > 0
            ? props.reservationDetail.pnr.travelPartsAdditionalDetails[0]
                .passengers
            : []
        }
        tripDetail={props.reservationDetail.pnr.itinerary.itineraryParts}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    reservationDetail: state.payment.reservationDetail,
    rawRequest:state.payment.rawRequest,
  };
};
export default connect(mapStateToProps, null)(TeleBirrPaymentSummary);
