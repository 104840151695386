import React from "react";
import { Accordion, Card, Row, Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faCheck,
  faEdit,
  faInfoCircle,
  faPlaneArrival,
  faPlaneDeparture,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import Translation from "../../../service/shared/multi-lingual/Translation";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import { manageAncillaryAction } from "../../../redux/actions/manage-my-booking/manageAncillaryAction";
import ManageBaggageService from "../../../service/manage-my-booking/account/ManageBaggageService";
class ManageBaggage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isLoading: false,
    };
    this.minimumPriceFinder();
  }
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  minimumPriceFinder = () => {
    var minmumPrice = 0;
    var currency = null;
    this.props.ancillaries.ancillaries.forEach((ancillary) => {
      ancillary.travelPartOffers.forEach((travelPartOffers) => {
        travelPartOffers.passengerOffers.forEach((passenger) => {
          if (minmumPrice === 0) {
            minmumPrice =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount;
            currency =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].currency;
          } else if (
            passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount <
            minmumPrice
          ) {
            minmumPrice =
              passenger.ancillaryPrice.totalPrice.alternatives[0][0].amount;
          }
        });
      });
    });

    this.minimumPrice = this.numberWithCommas(minmumPrice);
    this.currency = currency;
  };
  addBaggage = (
    ancillaryCode,
    price,
    passengerIdItinerary,
    maxQuantityPerPax,
    quantity
  ) => {
    if (quantity < maxQuantityPerPax) {
      this.props.addBag(ancillaryCode, passengerIdItinerary, price);
    }
  };
  minusBaggage = (
    ancillaryCode,
    price,
    passengerIdItinerary,
    assignedQuantity,
    quantity
  ) => {
    if (quantity > assignedQuantity) {
      this.props.minusBag(ancillaryCode, passengerIdItinerary, price);
    }
  };
  addToCart = () => {
    if (
      this.props.totalPrice > this.props.oldValue
    ) {
      this.setState({ showModal: false, isLoading: true });
      var baggageService = new ManageBaggageService();
      var baggageRequest = baggageService.buildBaggageRequest(
        this.props.itineraryList,
        this.props.baggageModel,
        this.props.cookieSabreDataRequest
      );
      if (baggageRequest.ancillaryOperations.length !== 0) {
        HttpService.ancillaryPostService(
          baggageRequest,
          "/Ancillary/AddAncillary",
          this.props.token
        )
          .then((ancillaryResponse) => {
            this.setState({ isLoading: false });

            if (ancillaryResponse.data.statusAPI === "SUCCESS") {
              this.props.addedToCart(baggageRequest);
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
          });
      }
    }
  };
  getClassNameForButton = (
    quantity = 0,
    maxQuantityPerPax = 0,
    assignedQuantity = 0,
    type = null
  ) => {
    if (type === "plus") {
      if (quantity === maxQuantityPerPax) {
        return "bk-submit-btn btn-ethiopian bag-button btn-not-allowd disabled";
      } else {
        return " bk-submit-btn btn-ethiopian bag-button ";
      }
    } else if (type === "minus") {
      if (quantity === assignedQuantity) {
        return "bk-submit-btn btn-ethiopian bag-button btn-not-allowd disabled";
      } else {
        return " bk-submit-btn btn-ethiopian bag-button ";
      }
    }
  };
  render() {
    return (
      <>
        {this.state.isLoading ? <Loading /> : null}
        <h4>
          <Translation Key="ancillary_header" />
        </h4>
        <h6>
          <Translation Key="ancillary_paragraph" />
        </h6>
        <div className="card bag-card">
          <div className="bag-header m-3">
            <h3 className="my-4">{this.props.ancillaries.name}</h3>
            <img
              alt="Image for Excess Baggage"
              class="bag-img"
              src={this.props.ancillaries.logo}
            />
            <p className="bag-intro">
              {this.props.ancillaries.description !== null
                ? this.props.ancillaries.description.split("\n")[0]
                : null}
            </p>
          </div>
          <div className="bag-content mx-3">
            {this.props.bagIsAdded ? (
              <div>
                <h3 className="added-to-cart">
                  <FontAwesomeIcon icon={faCheck} />
                  <Translation Key="added_to_cart" />
                </h3>
              </div>
            ) : null}
            <p>
              {" "}
              <Translation Key="bags" />
            </p>
            <p>
              <b>
                {this.props.bagIsAdded
                  ? this.numberWithCommas(this.props.finaloverAllTotal)
                  : this.minimumPrice}{" "}
                {this.currency}
              </b>
            </p>
            <p className="bag-text">
              {" "}
              <Translation Key="per_bag" />
              / <Translation Key="per_passenger" />
            </p>
            <p className="bag-axillary">
              <Translation Key="free_bags_included" />
            </p>
          </div>
          <div className="bag-footer">
            <Button
              className="bk-submit-btn btn-ethiopian m-3"
              onClick={() => this.setState({ showModal: true })}
            >
              {this.props.bagIsAdded ? (
                <span>
                  <FontAwesomeIcon icon={faEdit} />
                  <Translation Key="change" />
                </span>
              ) : (
                <Translation Key="add_bags" />
              )}
            </Button>
          </div>
        </div>

        <Modal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <Row>
              <div className="col-12 col-lg-4 d-none d-lg-block">
                <h3 className="my-4">{this.props.ancillaries.name}</h3>

                <img
                  alt="Image for Excess Baggage"
                  className="bag-img"
                  src={this.props.ancillaries.logo}
                />
              </div>
              <div className="col-12 col-lg-5 mt-lg-5">
                <h6 className="mt-4">
                  {this.props.ancillaries.description !== null
                    ? this.props.ancillaries.description.split("\n")[1]
                    : null}
                </h6>
              </div>
              <div className="col-12 col-lg-3 mt-lg-5 d-none d-lg-block">
                <div class="bag-content mt-4">
                  {this.props.bagIsAdded ? (
                    <div>
                      <h5 className="added-to-cart">
                        <FontAwesomeIcon icon={faCheck} />
                        <Translation Key="added_to_cart" />
                      </h5>
                    </div>
                  ) : null}
                  <p>
                    {" "}
                    <Translation Key="bags" />
                  </p>
                  <p>
                    <b>
                      {this.minimumPrice} {this.currency}
                    </b>
                  </p>
                  <p class="bag-text">
                    {" "}
                    <Translation Key="per_bag" />
                    / <Translation Key="per_passenger" />
                  </p>
                </div>
              </div>
            </Row>
          </Modal.Header>

          <Modal.Body>
            {this.props.itinerary.itineraryParts.map(
              (itinerary, itinerayIndex) => {
                var stopAirport = [];
                return (
                  <Accordion defaultActiveKey={'"' + itinerayIndex + '"'}>
                    <Card key={itinerayIndex} className="bag-modal-body">
                      <Accordion.Toggle eventKey={'"' + itinerayIndex + '"'}>
                        <Card.Header className="bag-header">
                          <h4>
                            {this.props.journeytype === "roundTrip" ||
                            this.props.journeytype === "oneWay" ? (
                              itinerayIndex === 0 ? (
                                <span>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                                  <Translation Key="departure_flight" />
                                </span>
                              ) : (
                                <span>
                                  <FontAwesomeIcon icon={faPlaneArrival} />{" "}
                                  <Translation Key="return_flight" />
                                </span>
                              )
                            ) : (
                              <span>
                                <span>
                                  <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
                                  {itinerayIndex + 1}
                                  {"  "}
                                  {itinerary.segments[0].origin}
                                  {"  "}
                                  <FontAwesomeIcon icon={faAngleRight} />{" "}
                                  {
                                    itinerary.segments[
                                      itinerary.segments.length - 1
                                    ].destination
                                  }
                                </span>
                              </span>
                            )}
                          </h4>
                        </Card.Header>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={'"' + itinerayIndex + '"'}>
                        <Card.Body>
                          <div>
                            <h5 className="text-left">
                              {" "}
                              {itinerary.segments.map(
                                (segments, segmentsIndex) => {
                                  stopAirport.push(
                                    segments.flight.airlineCode +
                                      " " +
                                      segments.flight.flightNumber
                                  );
                                }
                              )}
                              {stopAirport.join(",")}
                              {" : "}
                              {AirportFullName.getFullName(
                                itinerary.segments[0].origin
                              )}{" "}
                              <FontAwesomeIcon icon={faAngleRight} />{" "}
                              {AirportFullName.getFullName(
                                itinerary.segments[
                                  itinerary.segments.length - 1
                                ].destination
                              )}
                            </h5>
                            <p className="text-left">
                              <FontAwesomeIcon icon={faInfoCircle} />
                              Select the number of bags, and their respective
                              weight, that you will be carrying.
                            </p>
                          </div>
                          <div>
                            {this.props.ancillaryPassengers.map(
                              (passenger, passengerIndex) => {
                                var passengerItineraryId =
                                  "id_" +
                                  passenger["@id"] +
                                  "_itinerary_" +
                                  itinerary["@id"];
                                var ancillaryForPassenger =
                                  this.props.baggageModel.filter(
                                    (p) =>
                                      p.passengerIdItinerary ===
                                      passengerItineraryId
                                  )[0];
                                if (
                                  ancillaryForPassenger !== null ||
                                  ancillaryForPassenger !== undefined
                                ) {
                                  return (
                                    <Row
                                      key={passengerIndex}
                                      className="mt-lg-5 ml-lg-5"
                                    >
                                      <div className="col-12 col-lg-4 text-center">
                                        <Row className="text-center">
                                          <div className="col-12 text-center">
                                            <h5 className="mb-0 pr-5">
                                              {" "}
                                              {passenger.firstName}{" "}
                                              {passenger.lastName}
                                            </h5>
                                          </div>
                                          <div className="col-12 mt-0 text-center ">
                                            <Row className="">
                                              <div className="col-md-2 m-0 text-right d-none d-md-block">
                                                <FontAwesomeIcon
                                                  icon={faSuitcase}
                                                  size="2x"
                                                />
                                              </div>
                                              <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                                {"  "}
                                                {"  "}
                                                <Translation Key="number_of_bags" />
                                                :{" "}
                                                <span className="mx-2">
                                                  {
                                                    ancillaryForPassenger
                                                      .amount[0].totalQuantity
                                                  }
                                                </span>
                                                <b>
                                                  <span className="mx-1">
                                                    {this.numberWithCommas(ancillaryForPassenger
                                                      .amount[0]
                                                      .totalQuantity !== 0
                                                      ? ancillaryForPassenger
                                                          .amount[0].totalPrice
                                                      : 0)}
                                                  </span>{" "}
                                                  {this.currency}
                                                </b>
                                              </div>
                                              <div className="col-12 col-md-10 m-0 text-center text-lg-left">
                                                <span className="text-center">
                                                  <Translation Key="free_bags" />
                                                </span>
                                              </div>
                                            </Row>
                                          </div>
                                        </Row>
                                      </div>
                                      <div className="col-12 col-lg-8 text-center">
                                        <Row>
                                          {ancillaryForPassenger.bags.map(
                                            (bag, bagIndex) => (
                                              <div
                                                className="col-12 mt-2 mt-lg-5 mt-md-0 col-lg-6 col-xl-6 text-center"
                                                key={bagIndex}
                                              >
                                                {bag.name}
                                                <p>
                                                  <b>
                                                    {this.numberWithCommas(
                                                      bag.price
                                                    )}
                                                  </b>
                                                  {"  "} <b>{this.currency}</b>
                                                </p>

                                                <div className="quantity text-xl-left">
                                                  <span className="bag-quantity-input pt-2">
                                                    {" "}
                                                    {bag.quantity}
                                                  </span>
                                                  <span className="float-xl-right">
                                                    <Button
                                                      className={this.getClassNameForButton(
                                                        bag.quantity,
                                                        bag.maxQuantityPerPax,
                                                        bag.assignedQuantity,
                                                        "minus"
                                                      )}
                                                      onClick={this.minusBaggage.bind(
                                                        this,
                                                        bag.code,
                                                        bag.price,
                                                        passengerItineraryId,
                                                        bag.assignedQuantity,
                                                        bag.quantity
                                                      )}
                                                    >
                                                      -
                                                    </Button>

                                                    <Button
                                                      className={this.getClassNameForButton(
                                                        bag.quantity,
                                                        bag.maxQuantityPerPax,
                                                        bag.assignedQuantity,
                                                        "plus"
                                                      )}
                                                      onClick={this.addBaggage.bind(
                                                        this,
                                                        bag.code,
                                                        bag.price,
                                                        passengerItineraryId,
                                                        bag.maxQuantityPerPax,
                                                        bag.quantity
                                                      )}
                                                    >
                                                      +
                                                    </Button>
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </Row>
                                      </div>
                                    </Row>
                                  );
                                } else {
                                  return (
                                    <Row>
                                      <div className="col-6">
                                        <h5 className="mb-0 pr-5">
                                          {" "}
                                          {passenger.firstName}{" "}
                                          {passenger.lastName}
                                        </h5>
                                      </div>
                                      <div className="col-6">
                                        <h6 className="float-right">
                                          Unavailable for {passenger.type}
                                        </h6>
                                      </div>
                                    </Row>
                                  );
                                }
                              }
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                );
              }
            )}
            <div className="text-right">
              <h5>
                <b>
                  <Translation Key="total" />:{" "}
                  <span id="baggageTotal">
                    {this.numberWithCommas(this.props.totalPrice)}
                  </span>{" "}
                  {this.currency}
                </b>
              </h5>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="float-right">
              <Button
                variant="danger"
                className="bk-submit-btn btn-white btn-ethiopian-danger m-2"
                style={{ borderColor: "red" }}
                onClick={() => {
                  this.setState({ showModal: false });
                }}
              >
                <Translation Key="cancel" />
              </Button>
              <Button
                variant="danger"
                className={
                  this.props.totalPrice > this.props.oldValue
                    ? "bk-submit-btn btn-ethiopian m-2"
                    : "bk-submit-btn btn-ethiopian btn-not-allowd m-2 disabled"
                }
                onClick={this.addToCart}
              >
                {this.props.bagIsAdded ? (
                  <Translation Key="update_cart" />
                ) : (
                  <Translation Key="add_to_cart" />
                )}
              </Button>
            </span>{" "}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    baggageModel: state.manageAncillary.baggageModel,
    totalPrice: state.manageAncillary.overAllTotal,
    finaloverAllTotal: state.manageAncillary.finaloverAllTotal,
    journeytype: state.bookingWiget.journyType,
    bagIsAdded: state.manageAncillary.bagIsAdded,
    oldValue:state.manageAncillary.oldValue,
    cookieSabreDataRequest:
      state.manageAncillary.ancillaryList.cookieSabreDataRequest,
    token: state.searchFlight.token,
    itineraryList: state.manageAncillary.ancillaryList.itinerary.itineraryParts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBag: (ancillaryCode, passengerIdItinerary, price) =>
      dispatch({
        type: manageAncillaryAction.MANAGE_ADDBAG,
        ancillaryCode: ancillaryCode,
        passengerIdItinerary: passengerIdItinerary,
        price: price,
      }),
    minusBag: (passengerId, ancillaryIndex, price, itinerayIndex) =>
      dispatch({
        type: manageAncillaryAction.MANAGE_MINUSBAG,
        passengerId: passengerId,
        ancillaryIndex: ancillaryIndex,
        price: price,
        itinerayIndex: itinerayIndex,
      }),
    addedToCart: (baggageRequest) =>
      dispatch({
        type: manageAncillaryAction.MANAGE_ADDTOCART,
        baggageRequest: baggageRequest,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageBaggage);
