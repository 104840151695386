import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Translation from "i18next";
class ExchangePaymentSummaryRemark extends Component {
  render() {
    return (
      <section className="payment-summary-remark">
        <Row>
          <Col xs={11}>
            <h2 className="d-inline pr-3 warining-check-icon">
              <FontAwesomeIcon icon={faExclamationCircle} />
            </h2>
            <div className="d-inline">
              <p className="d-inline">
                {Translation.t("hold_expire_date_remark")}
              </p>
              <h4>
                Please follow this{" "}
                <a
                  href="https://www.ethiopianairlines.com/et/services/help-and-contact/worldwide-contacts"
                  target="_blank"
                >
                  link
                </a>{" "}
                to check the contact service center for next steps.
              </h4>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}
export default ExchangePaymentSummaryRemark;
