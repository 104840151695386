import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { accountAction } from "../../../../redux/actions/manage-my-booking/accountAction";
import Translation from "i18next";
import UserName from "../../shared/UserName";
const AccountSetting = (props) => {
  const [isPassWordMatch, setIsPasswordMatch] = useState(true);
  const checkPasswordMatch = () => {
    
    var password = document.getElementById("update-password-new-password");
    var retypePassword = document.getElementById(
      "update-password-retype-password"
    );
    if (password.value !== retypePassword.value) {
      setIsPasswordMatch(false);
      retypePassword.classList.add("invalid_input");
      props.changePasswordValidation(false, null, "confirmPassword");
    } else {
      setIsPasswordMatch(true);
      retypePassword.classList.remove("invalid_input");
      props.changePasswordValidation(true, null, "confirmPassword");
    }
  };
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="media">
            {/* <UserName /> */}
            <div className="media-body mx-2">
              <h3 className="frequent-flyer-h3">
                {Translation.t("view_update_account_setting")}
              </h3>

              <p>{Translation.t("all_fields_required")}</p>
            </div>
          </div>
        </Col>
      </Row>
      <p>
        <b>{Translation.t("user_name")} :</b> {props.userName}
      </p>
      <Row>
        <Col xs={12}>
          <input
            className={
              props.isOldPasswordValid
                ? "form-control  widget-input my-1"
                : "form-control  widget-input my-1 invalid_input"
            }
            placeholder={
              props.isOldPasswordValid
                ? Translation.t("old_password")
                : Translation.t("old_password_error")
            }
            type="password"
            defaultValue={props.oldPassword}
            id="update-password-old-password"
            onChange={() => {
              var value = document.getElementById(
                "update-password-old-password"
              ).value;
              props.updatePasswoModel(value, "oldPassword");
            }}
            onFocus={() =>
              props.changePasswordValidation(
                "oldPassword",
                "isOldPasswordValid",
                "onFocus"
              )
            }
            onBlur={() =>
              props.changePasswordValidation(
                "oldPassword",
                "isOldPasswordValid",
                "onBlur"
              )
            }
          />
        </Col>
        <Col xs={12}>
          <input
            className={
              props.isNewPasswordValid
                ? "form-control  widget-input my-1"
                : "form-control  widget-input my-1 invalid_input"
            }
            placeholder={
              props.isNewPasswordValid
                ? Translation.t("new_password")
                : Translation.t("new_password_error")
            }
            type="password"
            defaultValue={props.newPassword}
            id="update-password-new-password"
            onChange={() => {
              var value = document.getElementById(
                "update-password-new-password"
              ).value;
              props.updatePasswoModel(value, "newPassword");
            }}
            onFocus={() =>
              props.changePasswordValidation(
                "newPassword",
                "isNewPasswordValid",
                "onFocus"
              )
            }
            onBlur={() => {
              checkPasswordMatch();
              props.changePasswordValidation(
                "newPassword",
                "isNewPasswordValid",
                "onBlur"
              );
            }}
          />
        </Col>
        <Col xs={12}>
          <input
            className={
              isPassWordMatch
                ? "form-control  widget-input my-1"
                : "form-control  widget-input my-1 invalid_input"
            }
            placeholder={
              isPassWordMatch
                ? Translation.t("retype_password")
                : Translation.t("retype_password_error")
            }
            type="password"
            id="update-password-retype-password"
            onBlur={() => {
              checkPasswordMatch();
            }}
            onFocus={() => {
              var retypePassword = document.getElementById(
                "update-password-retype-password"
              );
              retypePassword.classList.remove("invalid_input");
              retypePassword.placeholder = "Retype Password";
            }}
          />
        </Col>
      </Row>
    </>
  );
};
const mapStateTopProps = (state) => {
  return {
    oldPassword: state.account.updatePassword.oldPassword,
    newPassword: state.account.updatePassword.newPassword,
    isOldPasswordValid:
      state.account.updatePassword.validation.isOldPasswordValid,
    isNewPasswordValid:
      state.account.updatePassword.validation.isNewPasswordValid,
    userName: state.account.profile.username,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
  };
};
const mapDispatchToProps = (dispacth) => {
  return {
    updatePasswoModel: (value, valueParameter) =>
      dispacth({
        type: accountAction.UPDATEPASSWORD,
        value: value,
        valueParameter: valueParameter,
      }),
    changePasswordValidation: (valueParameter, validationParameter, domain) =>
      dispacth({
        type: accountAction.UPDATEPASSWORDVALIDATION,
        valueParameter: valueParameter,
        validationParameter: validationParameter,
        domain: domain,
      }),
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(AccountSetting);
