
export class CreditCardValidatorService
{
    constructor()
    {
        this.isCreditCardTypeValid=true;
        this.isCreditCardNumberValid=true;
        this.isFullNameValid=true;
        this.isExpiredDateValid = true;
        this.isExpireMonthValid = true;
        this.isExpireYearValid = true;
        this.isEmailValid=true;
        this.isCVCValid=true;
        this.isPhoneNumberValid=true;
        this.isPhoneCountryCodeValid=true;
        this.isBillingAddressValid=true;
        this.isAddressValid=true;
        this.isCountryValid=true;
        this.isCityValid=true;
        this.isPostalCodeValid=true;
        this.isAmountValid=true;
        this.isCurrencyValid=true;
        this.creditCardValidLists =[];
    }

  validateCardInfo(creditCardInfo)
    {
        this.creditCardValidLists =[];
        for(let index =0;index<15;index++)
        {
            this.creditCardValidLists.push(true);
        }
        
        this.isCreditCardTypeValid=this.validateCreditCardType(creditCardInfo.CreditCardType,0);
        this.isCreditCardNumberValid=this.validateCreditCardType(creditCardInfo.CreditCardNumber,1);
        this.isFullNameValid=this.validateCreditCardType(creditCardInfo.FullName,2);
        this.isExpireMonthValid = this.validateCreditCardType(creditCardInfo.ExpireMonth, 3);
        this.isExpireYearValid = this.validateCreditCardType(creditCardInfo.ExpireYear, 4);
        this.isEmailValid=this.validateCreditCardType(creditCardInfo.Email,5);
        this.isCVCValid=this.validateCreditCardType(creditCardInfo._CVC,6);
        this.isPhoneNumberValid=this.validateCreditCardType(creditCardInfo.PhoneNumber,7);
        this.isPhoneCountryCodeValid=this.validateCreditCardType(creditCardInfo.PhoneCountryCode,8);
        this.isBillingAddressValid=this.validateCreditCardType(creditCardInfo.BillingAddess,9);
        this.isAddressValid=this.validateCreditCardType(creditCardInfo.Address,10);
        this.isCountryValid=this.validateCreditCardType(creditCardInfo.Country,11);
        this.isCityValid=this.validateCreditCardType(creditCardInfo.City,12);
        this.isPostalCodeValid=this.validateCreditCardType(creditCardInfo.PostalCode,13);
        this.isAmountValid=this.validateCreditCardType(creditCardInfo.Amount,14);
        this.isCurrencyValid=this.validateCreditCardType(creditCardInfo.Currency,15);
         return {"creditCardValidation":this,"creditCardValidLists":  this.creditCardValidLists};
    }
    validateCreditCardType(type,index)
    {
        if(type === "" || type == null)
        {
           this.creditCardValidLists[index]=false;
           return false;
        }
        else
        {
            if (index === 5) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.creditCardValidLists[index] = re.test(String(type).toLowerCase());
                return re.test(String(type).toLowerCase());
            }
            this.creditCardValidLists[index]=true;
            return  true;
        }
    }

}