import {
  faAngleDown,
  faAngleUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Button, Col, Row } from "react-bootstrap";
import "../FlightSelection.css";
import FlightSelectionSummaryDetail from "../FlightSelectionSummaryDetail";
import ItineraryBrand from "./ItineraryPartBrand";
import ItineraryPartOfferMob from "./mob/ItineraryPartOfferMob";
import Translation from "i18next";
class ItineraryPartOffer extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      showItineraryOffer: false,
      isEconomySelected: false,
      isBusinessSelected: false,
      itineraryPartBrand: this.props.itineraryPartBrand,
    };
  }
  thousands_separators = (num) => {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
  };
  parseTime = (minute) => {
    let hours = parseInt(minute / 60);
    let minutes = minute % 60;
    return <span>{hours + "hrs " + minutes + " mins"}</span>;
  };
  parseDate = (dateString) => {
    let options = {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    let date = new Date(dateString);
    let newDate = date.toLocaleString("en-GB", options);
    return <span>{newDate}</span>;
  };

  renderStops(segments, stops) {
    if (stops > 0) {
      if (segments.length > 1) {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <span>
              {stops > 1
                ? stops + " " + Translation.t("stops")
                : stops + " " + Translation.t("stop")}
            </span>
            {segments.map((segment, index) => (
              <span key={index}>
                {segment.flight.stopAirports.map((airport, index2) => (
                  <span key={index2}>{" " + airport.airport}</span>
                ))}
              </span>
            ))}
          </span>
        );
      }
    } else {
      return <span>{Translation.t("non-stop")}</span>;
    }
  }
  render() {
    return (
      <div>
        <div className={"itinerary-part-offer py-4 mt-" + this.props.marginTop}>
          <Row>
            <Col lg={7} md={7}>
              <Row>
                <Col lg={7} md={7}>
                  <Row className="flight-time">
                    <Col>
                      {this.parseDate(this.props.itineraryPartBrand.departure)}
                    </Col>
                    <Col className="pl-4">
                      {this.parseDate(this.props.itineraryPartBrand.arrival)}
                    </Col>
                  </Row>
                  <Row className="flight-route">
                    <Col>
                      {" "}
                      <span className="font-weight-bold">
                        {
                          this.props.itineraryPartBrand.itineraryPart
                            .segments[0].origin
                        }
                      </span>
                    </Col>
                    <Col className="pl-4">
                      {" "}
                      <span className="font-weight-bold">
                        {
                          this.props.itineraryPartBrand.itineraryPart.segments[
                            this.props.itineraryPartBrand.itineraryPart.segments
                              .length - 1
                          ].destination
                        }
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col lg={5} md={5} className="flight-duration">
                  <div>
                    {" "}
                    <span>
                      {this.parseTime(this.props.itineraryPartBrand.duration)}
                    </span>
                  </div>
                  <div>
                    <span>
                      {this.renderStops(
                        this.props.itineraryPartBrand.itineraryPart.segments,
                        this.props.itineraryPartBrand.itineraryPart.stops
                      )}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={5} md={5}>
              <Row>
                <Col lg={6} md={6}>
                  <div>
                    {this.props.itineraryPartBrand.echeepPrice != "N/A" && (
                      <Button
                        className="brand-price-selector eco"
                        onClick={() => {
                          const tesNode = ReactDOM.findDOMNode(this.refs.test);
                          if (!this.state.isEconomySelected)
                            window.scrollTo({
                              top: tesNode.offsetTop - 340,
                              behavior: "smooth",
                            }); //(0, tesNode.offsetTop - 320, );

                          this.setState({
                            isEconomySelected: !this.state.isEconomySelected,
                            isBusinessSelected: false,
                          });
                        }}
                      >
                        <div>{Translation.t("from")}</div>
                        <div>
                          <span>
                            {this.thousands_separators(
                              this.props.itineraryPartBrand.echeepPrice
                            )}
                          </span>{" "}
                          {!this.state.isEconomySelected && (
                            <span className="pl-1">
                              <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                          )}
                          {this.state.isEconomySelected && (
                            <span className="pl-1">
                              <FontAwesomeIcon icon={faAngleUp} />
                            </span>
                          )}
                        </div>
                      </Button>
                    )}
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div>
                    {this.props.itineraryPartBrand.bcheepPrice != "N/A" && (
                      <Button
                        className="brand-price-selector bus"
                        onClick={() => {
                          const tesNode = ReactDOM.findDOMNode(this.refs.test);
                          if (!this.state.isBusinessSelected)
                            window.scrollTo({
                              top: tesNode.offsetTop - 340,
                              behavior: "smooth",
                            });
                          this.setState({
                            isBusinessSelected: !this.state.isBusinessSelected,
                            isEconomySelected: false,
                          });
                        }}
                      >
                        <div>{Translation.t("from")}</div>
                        <div>
                          <span>
                            {this.thousands_separators(
                              this.props.itineraryPartBrand.bcheepPrice
                            )}
                          </span>{" "}
                          {!this.state.isBusinessSelected && (
                            <span className="pl-1">
                              <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                          )}
                          {this.state.isBusinessSelected && (
                            <span className="pl-1">
                              <FontAwesomeIcon icon={faAngleUp} />
                            </span>
                          )}
                        </div>
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col xs={12} className="py-2 aircraft-info">
              {this.props.itineraryPartBrand.itineraryPart.segments.map(
                (segment, index) => (
                  <div className="py-1" key={index}>
                    <span className="px-1">{segment.flight.airlineCode}</span>
                    <span className="pr-2">{segment.flight.flightNumber}</span>
                    <span className="pr-2">{segment.manufacturerName}</span>
                    <span>{segment.equipmentDescription}</span>
                  </div>
                )
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FlightSelectionSummaryDetail
                itineraryPart={this.props.itineraryPartBrand.itineraryPart}
              />
            </Col>
          </Row>
          <div ref="test">
            <ItineraryBrand
              isEconomySelected={this.state.isEconomySelected}
              isBusinessSelected={this.state.isBusinessSelected}
              itineraryPartBrand={this.props.itineraryPartBrand}
              selectFlight={this.props.selectFlight}
            />
          </div>
        </div>
        <div id="itinerary-part-offer-mob">
          <ItineraryPartOfferMob
            selectFlight={this.props.selectFlight}
            marginTop={this.props.marginTop}
            index={this.props.index}
            itineraryPartBrand={this.props.itineraryPartBrand}
          />
        </div>
      </div>
    );
  }
}
export default ItineraryPartOffer;
