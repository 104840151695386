import {
  faFilePdf,
  faPrint,
  faSuitcase,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import AddToCalendar from "react-add-to-calendar";
import { Redirect } from "react-router";
import PaymentSummaryAddCalendar from "./PaymentSummaryAddCalendar";

class PaymentSummaryAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {
        title: "Sample Event",
        description: "This is the sample event provided as an example only",
        location: "Portland, OR",
        startTime: "2016-09-16T20:15:00-04:00",
        endTime: "2016-09-16T21:45:00-04:00",
      },
      isRedirectToPrint: false,
      isRedirectToPdf: false,
    };
  }
  redirectToPrintOrPdf = (type) => {
    if (type === "print") {
      this.setState({ isRedirectToPrint: true, isRedirectToPdf: false });
    }
    if (type === "pdf") {
      this.setState({ isRedirectToPrint: false, isRedirectToPdf: true });
    }
  };
  render() {
    if (this.state.isRedirectToPrint) {
      
      return (
        <Redirect
          to={{
            pathname: "/payment-summary/print",
            state: this.props.state,
          }}
        />
      );
    }
    if (this.state.isRedirectToPdf) {
      
      return (
        <Redirect
          to={{
            pathname: "/payment-summary/pdf",
            state: this.props.state,
          }}
        />
      );
    }
    return (
      <div className="payment-summary-actions pt-3 float-right">
        <div className="add-to-calendar">
          <PaymentSummaryAddCalendar
            itineraryParts={this.props.itineraryParts}
          />
        </div>
        <div
          className="px-3 print-icon text-center"
          onClick={() => this.redirectToPrintOrPdf("print")}
        >
          <FontAwesomeIcon icon={faPrint} style={{ fontSize: "23px" }} />
          <div>Print</div>
        </div>
        <div
          className="px-3 pdf-icon text-center"
          onClick={() => this.redirectToPrintOrPdf("pdf")}
        >
          <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: "23px" }} />
          <div>PDF</div>
        </div>
      </div>
    );
  }
}

export default PaymentSummaryAction;
