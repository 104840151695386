import { faPlaneDeparture } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { AirportFullName } from "../../../service/shared/AirportFullName";
import Login from "../account/Login";
import moment from "moment";
import Loading from "../../booking/seat-selection/Loading";
import HttpService from "../../../service/shared/HttpService";
import { accountAction } from "../../../redux/actions/manage-my-booking/accountAction";
import { manageMyBookingAction } from "../../../redux/actions/manage-my-booking/manageMyBookingAction";
import ForgotPasswordModal from "../account/ForgotPasswordModal";
const ManageTrip = (props) => {
  var history = useHistory();
  const [showLoginPage, setShowLoginPage] = useState(false);
  const [redirectToCreateAccount, setRedirectToCreateAccount] = useState(false);
  const [findBooking, setFindBooking] = useState(false);
  const [isMytripLoad, setIsMytripLoad] = useState(false);
  const [showModal, setShowModla] = useState(false);
  if (redirectToCreateAccount) {
    history.push("create-account");
  }
  const getMyTrips = async () => {
    await HttpService.postAccount(props.profileCookie, "/MyTrips", props.token)
      .then((myTripsResponse) => {
        if (myTripsResponse.data.statusAPI === "SUCCESS") {
          props.getMyTrips(myTripsResponse.data.trips);
        }
        setIsMytripLoad(true);
      })
      .catch((error) => {
        setIsMytripLoad(true);
        console.log(error);
      });
  };
  const setShowModal = () => {
    setShowModla(!showModal);
  };
  const searchMyTrip = async () => {
    var pnr = document.getElementById("manage-booking-pnr");
    var lastName = document.getElementById("manage-booking-last-name");
    if (
      pnr.value !== null &&
      lastName.value !== null &&
      pnr.value !== "" &&
      lastName.value !== ""
    ) {
      props.saveMyManageBooking(pnr.value, lastName.value);
      history.push("manage-trip");
    } else {
      if (pnr.value === null || pnr.value === "") {
        pnr.classList.add("invalid_input");
      }
      if (lastName.value === null || lastName.value === "") {
        lastName.classList.add("invalid_input");
      }
    }
  };
  if (props.isUserSignedIn && !isMytripLoad) {
    getMyTrips();
  }

  if (props.isUserSignedIn && !findBooking) {
    if (isMytripLoad) {
      return (
        <>
          <h3>Upcoming Trips</h3>
          <hr style={{ backgroundColor: "#1c7430" }} />
          {props.trips.length >= 1 ? (
            props.trips.map((trip, index) => (
              <Row key={index} className="my-3">
                <Col className="trip-box">
                  <h3>{trip.pnr}</h3>
                  <h6>
                    {AirportFullName.getFullName(trip.origin)}
                    {"   "}
                    <FontAwesomeIcon icon={faPlaneDeparture} />
                    {"    "}
                    {AirportFullName.getFullName(trip.destination)}
                  </h6>
                  <p>
                    {moment(trip.departureDate)
                      .locale("en")
                      .format("dddd, MMM DD, YYYY")}
                  </p>
                  <Row>
                    <Col xs={12} sm={12} md={3}>
                      <Button
                        variant="danger"
                        className="btn-block bk-submit-btn btn-ethiopian"
                      >
                        Trip Details
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))
          ) : (
            <div>
              <Row>
                <Col xs={12}>
                  <div className="media">
                    <div className="justify-content-center user-name-box">
                      <span className="user-name-text">
                        {props.firstName[0] + props.lastName[0]}
                      </span>
                    </div>
                    <div className="media-body mx-2">
                      <h5>
                        Hi {props.firstName}, it looks like you do not have any
                        upcoming trips. Check out our{" "}
                        <a href="#">inspiration page</a> for ideas and special
                        <a href="#"> membership discount</a>. We hope to see you
                        in the air again soon!
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={9}></Col>
                <Col xs={12} sm={12} md={3}>
                  <Button
                    variant="danger"
                    className="btn-block bk-submit-btn btn-ethiopian"
                    onClick={() => props.redirectToBooking("bookFlight")}
                  >
                    Book A Trip
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          <hr style={{ backgroundColor: "#1c7430" }} />
          <Row>
            <Col xs={12} sm={12} md={9}>
              <p>
                If you do not see your booking above, please click Find a
                booking button to search
              </p>
            </Col>
            <Col xs={12} sm={12} md={3}>
              <Button
                variant="danger"
                className="btn-block bk-submit-btn btn-ethiopian"
                onClick={() => setFindBooking(true)}
              >
                Find Booking
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return <Loading />;
    }
  } else {
    return (
      <>
        {showModal && <ForgotPasswordModal setShowModal={setShowModal} />}
        <Row>
          <Col sm={12} md={7} lg={6}>
            <h2>Search by Trip Details</h2>
            <p>
              <b> Please Note:</b>All fields are required unless marked as
              optional. Use latin characters only.
            </p>
            <input
              className="form-control widget-input my-3"
              placeholder="Confirmation Code(PNR) *"
              id="manage-booking-pnr"
              autoComplete="off"
              onBlur={() => {
                var pnr = document.getElementById("manage-booking-pnr");
                if (pnr.value === null || pnr.value === "") {
                  pnr.classList.add("invalid_input");
                }
              }}
              onFocus={() => {
                var pnr = document.getElementById("manage-booking-pnr");
                pnr.classList.remove("invalid_input");
              }}
            />
            <input
              className="form-control widget-input"
              placeholder="Surname *"
              id="manage-booking-last-name"
              autoComplete="off"
              onBlur={() => {
                var lastName = document.getElementById(
                  "manage-booking-last-name"
                );
                if (lastName.value === null || lastName.value === "") {
                  lastName.classList.add("invalid_input");
                }
              }}
              onFocus={() => {
                var lastName = document.getElementById(
                  "manage-booking-last-name"
                );
                lastName.classList.remove("invalid_input");
              }}
            />
            <Row className="mt-3 mt-md-5">
              <Col xs={12} sm={12} md={7} lg={8}></Col>
              <Col xs={12} sm={12} md={5} lg={4}>
                <Button
                  variant="danger"
                  className="btn-block bk-submit-btn btn-ethiopian"
                  onClick={() => searchMyTrip()}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={5} lg={6}>
            {!findBooking && (
              <>
                {showLoginPage ? (
                  <Login
                    showLoginPageForManageTrip={true}
                    manageTrip={true}
                    modalHandler={setShowModal}
                  />
                ) : (
                  <Row className="mt-5 mb-3 my-md-0">
                    <Col md={12} lg={6} className="col">
                      <Button
                        variant="danger"
                        className="btn-block bk-submit-btn btn-ethiopian"
                        onClick={() => setShowLoginPage(true)}
                      >
                        SIGN IN TO MY ACCOUNT
                      </Button>
                    </Col>
                    <Col md={12} lg={6} className="mt-0 mt-md-3 mt-lg-0 col">
                      <Button
                        variant="danger"
                        className="btn-block bk-submit-btn btn-ethiopian"
                        onClick={() => setRedirectToCreateAccount(true)}
                      >
                        CREATE PROFILE
                      </Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Col>
        </Row>
      </>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    isUserSignedIn: state.global.isUserSignedIn,
    firstName: state.account.firstName,
    lastName: state.account.lastName,
    trips: state.account.trips,
    profileCookie: state.account.profileCookie,
    token: state.searchFlight.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    redirectToBooking: (widgetType) =>
      dispatch({
        type: BookingWidgetAction.CHANGEWIDGETTYPE,
        widgetType: widgetType,
      }),
    getMyTrips: (data) =>
      dispatch({
        type: accountAction.GETMYTRIPS,
        data: data,
      }),
    saveMyManageBooking: (pnr, lastName) =>
      dispatch({
        type: manageMyBookingAction.INITIALIZE,
        pnr: pnr,
        lastName: lastName,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageTrip);
