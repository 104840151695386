import { React, Component } from "react";
import "./seats/Seats.css";

class Loading extends Component {
  
  render() {
    return (
      <div>
        <div id="loader-wrapper">
      <div id="loader"></div>
        </div>
        <div id="log-wrapper">
      <div id="log"></div>
    </div>
      </div>
    );
  }
}

export default Loading;
