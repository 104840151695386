import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Translation from "i18next";
import { Col, Row } from 'react-bootstrap';
class CreditCardHeader extends Component
{

    render()
    {
        return(<div className="credit-card-header">
            <h3>{Translation.t("credit_card")}</h3>
            <div>{Translation.t("surcharge_apply_for")}</div>
            <Row className="m-0">
                {this.props.creditCardOptions.map((creditCardOption) => creditCardOption.paymentInfo.afopDetails != null && <Col xs={2} sm={2} md={2} lg={1} style={{ background: `url("${creditCardOption.paymentInfo.afopDetails[0].logoURI}")` }} className="credit-card-logos"></Col>)}
                <Col xs={2} sm={2} md={2} lg={1} style={{ background: `url("/ax.png")` }} className="credit-card-logos"></Col>
                <Col xs={2} sm={2} md={2} lg={1} style={{ background: `url("/dc.png")` }} className="credit-card-logos"></Col>
                <Col xs={2} sm={2} md={2} lg={1} style={{ background: `url("/ca.png")` }} className="credit-card-logos"></Col>

            </Row>
           <div>
            <FontAwesomeIcon icon={faExclamationTriangle}/>
          <span className="pl-2">{Translation.t("fraud_card_warning")}</span> 
           </div>
           <div>
           {Translation.t("credit_card_form_required_remark")}
           </div>
        </div>);
    }
}
export default CreditCardHeader;