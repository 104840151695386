import React from "react";
import BreadCrumb from "../../shared/bread-crumb/BreadCrumb";
import { connect } from "react-redux";
import ManageBaggage from "./ManageBaggage";
import ShoppingCart from "../../shared/shoppingCart/ShoppingCart";
import { Row, Col, Button, Container } from "react-bootstrap";
import { manageAncillaryAction } from "../../../redux/actions/manage-my-booking/manageAncillaryAction";
import Translation from "i18next";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ManageTripBreadCrumb from "../shared/ManageTripBreadCrumb";
import ManageTripShoppingCart from "../shared/ManageTripShoppingCart";
import HttpService from "../../../service/shared/HttpService";
import Loading from "../../booking/seat-selection/Loading";
import Notification from "../../booking/booking-widget/Notification";
import { manageMyBookingAction } from "../../../redux/actions/manage-my-booking/manageMyBookingAction";

class ManageAncillary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isDataLoaded: true,
      errorOccured: false,
      errorMessage: null,
    };
    this.intializeState();
  }

  intializeState = () => {
    var initializeBaggageRequest = {
      Pnr: this.props.pnr,
      LastName: this.props.lastName,
    };
    HttpService.postManageTrip(
      initializeBaggageRequest,
      "/Initialize/InitializeAncillaries",
      this.props.token
    )
      .then((initializeBaggageResponse) => {
        if (initializeBaggageResponse.data.statusAPI === "SUCCESS") {
          var ancillaryListRequest = {
            Flow: 1,
            cookieSabreDataRequest:
              initializeBaggageResponse.data.cookieSabreDataRequest,
          };
          HttpService.ancillaryPostService(
            ancillaryListRequest,
            "/Ancillary/GetAncillaries",
            this.props.token
          )
            .then((ancillaryListResponse) => {
              if (ancillaryListResponse.data.statusAPI === "SUCCESS") {
                this.props.intializeState(ancillaryListResponse.data);
                this.setState({ isDataLoaded: false });
              } else {
                this.setState({
                  errorOccured: true,
                  errorMessage: ancillaryListResponse.data.messageAPI,
                  isDataLoaded: false,
                });
              }
            })
            .catch((error) => {
              this.setState({
                errorOccured: true,
                errorMessage:
                  "Something went wrong. we are working on getting this fixed as soon as we can. You may able to try again.",
                isDataLoaded: false,
              });
            });
        } else {
          this.setState({
            errorOccured: true,
            errorMessage: initializeBaggageResponse.data.messageAPI,
            isDataLoaded: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorOccured: true,
          errorMessage:
            "Something went wrong. we are working on getting this fixed as soon as we can. You may able to try again.",
          isDataLoaded: false,
        });
      });
  };
  render() {
    if (this.state.isDataLoaded) {
      return <Loading />;
    } else if (this.state.errorOccured) {
      return (
        <>
          <ManageTripBreadCrumb currentPage="editAncillaries" />
          <Container>
            <Notification error={true} body={this.state.errorMessage} />
          </Container>
        </>
      );
    } else {
      return (
        <>
          <ManageTripBreadCrumb currentPage="editAncillaries" />
          <Row className="m-0 flex-column-reverse flex-xl-row w-100 justify-content-center all-passangers-container">
            <div className="col-12 col-xl-7 mx-0">
              {this.props.ancillaryList.ancillaryGroups.map(
                (ancillary, index) => {
                  if (ancillary.code === "BG") {
                    return (
                      <ManageBaggage
                        key={index}
                        ancillaries={ancillary}
                        itinerary={this.props.ancillaryList.itinerary}
                        ancillaryPassengers={
                          this.props.ancillaryList.ancillaryPassengers
                        }
                      />
                    );
                  }
                }
              )}
              <Row className="mt-4">
                <Col sm={12} md={6}></Col>
                <Col sm={12} md={6}>
                  <span className="float-right ">
                    <Button
                      variant="danger"
                      className="bk-submit-btn btn-ethiopian mx-2"
                      onClick={() => this.props.history.push("/manage-trip")}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                    <Button
                      variant="danger"
                      className={
                        this.props.isNewBagAdded
                          ? "bk-submit-btn btn-ethiopian mx-2"
                          : "bk-submit-btn btn-ethiopian mx-2 btn-not-allowd disabled"
                      }
                      onClick={() => {
                        if (this.props.isNewBagAdded) {
                          this.props.history.push("/myb/payment", {
                            type: "baggage",
                          });
                        }
                      }}
                    >
                      {Translation.t("continue")}
                    </Button>
                  </span>
                </Col>
              </Row>
            </div>
            <div className="col-12 col-xl-3">
              {!this.props.isMedium && <ManageTripShoppingCart />}
            </div>
          </Row>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    ancillaryList: state.manageAncillary.ancillaryList,
    bagIsAdded: state.manageAncillary.bagIsAdded,
    isNewBagAdded: state.manageAncillary.isNewBagAdded,
    isMedium: state.global.isMedium,
    token: state.searchFlight.token,
    lastName: state.manageMyBooking.formInput.lastName,
    pnr: state.manageMyBooking.formInput.pnr,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    intializeState: (data) =>
      dispatch({
        type: manageAncillaryAction.MANAGE_SAVEANCILLARYDATA,
        data: data,
      }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageAncillary);
