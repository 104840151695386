import CreateAccount from "../../manage-my-booking/account/CreateAccount";
import AncillaryList from "../../booking/ancillary/AncillaryList";
import BookingWidget from "../../booking/booking-widget/BookingWidget";
import FlightSelection from "../../booking/flight-selection/FlightSelection";
import FlightSelectionSummary from "../../booking/flight-selection/FlightSelectionSummary";
import Matrix from "../../booking/flight-selection/maxtrix/Matrix";
import AllFlightStatusResult from "../../manage-my-booking/flight-status/AllFlightStatusResult";
import AllPassengerForm from "../../booking/passenger-info/AllPassengerForm";
import Payment3DApproved from "../../booking/payment/3D/Approved";
import Payment3DCancel from "../../booking/payment/3D/Cancel";
import Payment3DDecline from "../../booking/payment/3D/Decline";
import Payment3DDefault from "../../booking/payment/3D/Default";
import Payment3DError from "../../booking/payment/3D/Error";
import Payment3DPending from "../../booking/payment/3D/Pending";
import Payment from "../../booking/payment/Payment";
import CreditCardRedirect from "../../booking/payment/payment-options/credit-card/CreditCardRedirect";
import FloCashRedirect from "../../booking/payment/payment-options/flocash/FloCashRedirect";
import RedirectToUnionPay from "../../booking/payment/payment-options/union-pay/RedirectToUnionPay";
import PaymentSummary from "../../booking/payment/payment-summary/PaymentSummary";
import SeatSelection from "../../booking/seat-selection/SeatSelection";
import AllflightScheduleResult from "../../manage-my-booking/flight-schedule/AllFlightScheduleResult";
import CheckIn from "../../check-in/CheckIn";
import CheckInIternary from "../../check-in/CheckInIternary";
import CheckInError from "../../check-in/CheckInError";
import MySeatSelection from "../../manage-my-booking/seat-selection/MySeatSelection";

import CheckingPassenger from "../../check-in/passanger/AllPassangers";
import FareCalendar from "../../booking/flight-selection/fare-calendar/FareCalendar";
import ManageProfile from "../../../component/manage-my-booking/account/manage-profile/ManageProfile";
import EditPassengerInfo from "../../manage-my-booking/edit-passenger-info/EditPassengerInfo";
import ManageAncillaries from "../../manage-my-booking/manage-ancillaries/ManageAncillaries";

import CheckInSeat from "../../check-in/seat-selection/SeatSelection";
import Confirmation from "../../check-in/Confirmation";

import ForgotPasswordLandingPage from "../../manage-my-booking/account/ForgotPasswordLandingPage";
// import ManageProfile from "../../manage-my-booking/account/ManageProfile";
import ManageBookingSummary from "../../manage-my-booking/manage-booking-summary/ManageBookingSummary";
import ManageBookingSummaryPrint from "../../manage-my-booking/manage-booking-summary/ManageBookingSummaryPrint";
import ManageBookingSummaryPdf from "../../manage-my-booking/manage-booking-summary/ManageBookingSummaryPdf";
import ManageBookingSummaryAddCalendar from "../../manage-my-booking/manage-booking-summary/ManageBookingSummaryAddCalendar";
import Exchange from "../../exchange/Exchange";
import ExchangeWidget from "../../exchange/ExchangeWidget";
import ExchangeFlightSelection from "../../exchange/flight-selection/ExchangeFlightSelection";
import ExchangeFlightSelectionSummary from "../../exchange/flight-selection/ExchangeFlightSelectionSummary";
import ExchangePayment from "../../exchange/payment/ExchangePayment";
import ExchangePaymentSummary from "../../exchange/payment/payment-summary/ExchangePaymentSummary";
import ExchangePaymentSummaryPrint from "../../exchange/payment/payment-summary/ExchangePaymentSummaryPrint";
import ExchangePaymentSummaryPdf from "../../exchange/payment/payment-summary/ExchangePaymentSummaryPdf";
import PaymentSummaryPrint from "../../booking/payment/payment-summary/PaymentSummaryPrint";
import PaymentSummaryPdf from "../../booking/payment/payment-summary/PaymentSummaryPdf";
import ManageAncillariesExchange from "../../exchange/manage-ancillaries-exchange/ManageAncillariesExchange";
import ExchangePassenger from "../../exchange/passenger/ExchangePassenger";
import CheckInPaymentOption from "../../check-in/payment/CheckInPaymentOption";
import ExchangeAllPassengers from "../../exchange/passenger/ExchangeAllPassengers";
import CheckInAncillaries from "../../check-in/checkin-ancillaries/CheckInAncillaries";
import ManageTripPayment from "../../manage-my-booking/payment/ManageTripPayment";

import ExchangeSeatSelection from "../../exchange/seat-selection/SeatSelection";
import AccountSetting from "../../manage-my-booking/account/manage-profile/AccountSetting";
import SkipPaymentOption from "../../manage-my-booking/payment/payment-options/SkipPaymentOption";
import TeleBirrPayment from "../../booking/payment/tele-birr-payment/TeleBirrPayment";
import TeleBirrPaymentSummary from "../../booking/payment/tele-birr-payment/TeleBirrPaymentSummary";
import TeleBirrPaymentSummarySuccess from "../../booking/payment/tele-birr-payment/TeleBirrPaymentSummarySuccess";

const RouteList = [
  {
    path: "/",
    component: BookingWidget,
  },
  {
    path: "/flightSelect",
    component: FlightSelection,
  },
  {
    path: "/flightsummary",
    component: FlightSelectionSummary,
  },
  {
    path: "/passenger",
    component: AllPassengerForm,
  },
  {
    path: "/redirectUnionPay",
    component: RedirectToUnionPay,
  },
  {
    path: "/ancillary",
    component: AncillaryList,
  },
  {
    path: "/matrix",
    component: Matrix,
  },
  {
    path: "/fareCalendar",
    component: FareCalendar,
  },
  {
    path: "/seatSelection",
    component: SeatSelection,
  },
  {
    path: "/payment",
    component: Payment,
    // path: "/ancillary",
    // component: AncillaryList,
  },
  {
    path: "/payment-summary",
    component: PaymentSummary,
    // path: "/ancillary",
    // component: AncillaryList,
  },
  {
    path: "/payment-summary/print",
    component: PaymentSummaryPrint,
    // path: "/ancillary",
    // component: AncillaryList,
  },
  {
    path: "/payment-summary/pdf",
    component: PaymentSummaryPdf,
    // path: "/ancillary",
    // component: AncillaryList,
  },
  {
    path: "/redirect-payment",
    component: CreditCardRedirect,
  },
  {
    path: "/flocash-redirect",
    component: FloCashRedirect,
  },
  {
    path: "/payment/3d/default",
    component: Payment3DDefault,
  },
  {
    path: "/payment/3d/pending",
    component: Payment3DPending,
  },
  {
    path: "/payment/3d/approved",
    component: Payment3DApproved,
  },
  {
    path: "/payment/3d/decline",
    component: Payment3DDecline,
  },
  {
    path: "/payment/3d/error",
    component: Payment3DError,
  },
  {
    path: "/payment/3d/cancel",
    component: Payment3DCancel,
  },
  {
    path: "/create-account",
    component: CreateAccount,
  },
  {
    path: "/flight-status-result",
    component: AllFlightStatusResult,
  },
  {
    path: "/flight-schedule-result",
    component: AllflightScheduleResult,
  },
  {
    path: "/check-in",
    component: CheckIn,
  },
  {
    path: "/manage-profile",
    component: ManageProfile,
  },
  {
    path: "/check-in/itinerary",
    component: CheckInIternary,
  },
  {
    path: "/check-in/error",
    component: CheckInError,
  },
  {
    path: "/myb/seat-selection",
    component: MySeatSelection,
  },
  {
    path: "/check-in/passengers",
    component: CheckingPassenger,
  },
  {
    path: "/check-in/seats",
    component: CheckInSeat,
  },
  {
    path: "/check-in/confirmation",
    component: Confirmation,
  },
  {
    path: "/check-in/payment",
    component: CheckInPaymentOption,
  },
  {
    path: "/myb/edit-passenger-info",
    component: EditPassengerInfo,
  },
  {
    path: "/myb/edit-ancillaries",
    component: ManageAncillaries,
  },
  {
    path: "/myb/payment",
    component: ManageTripPayment,
  },
  {
    path: "/myb/nm/payment",
    component: SkipPaymentOption,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordLandingPage,
  },
  {
    path: "/manage-trip",
    component: ManageBookingSummary,
  },
  {
    path: "/manage-trip/payment",
    component: ManageTripPayment,
  },
  {
    path: "/manage-trip/print",
    component: ManageBookingSummaryPrint,
  },
  {
    path: "/manage-trip/pdf",
    component: ManageBookingSummaryPdf,
  },
  {
    path: "/exchange",
    component: Exchange,
  },
  {
    path: "/exchange/widget",
    component: ExchangeWidget,
  },
  {
    path: "/exchange/flight-selection",
    component: ExchangeFlightSelection,
  },
  {
    path: "/exchange/flight-confirmation",
    component: ExchangeFlightSelectionSummary,
  },

  {
    path: "/exchange/payment-option",
    component: ExchangePayment,
  },
  {
    path: "/exchange/payment-summary",
    component: ExchangePaymentSummary,
  },
  {
    path: "/exchange/print",
    component: ExchangePaymentSummaryPrint,
  },
  {
    path: "/exchange/pdf",
    component: ExchangePaymentSummaryPdf,
  },
  {
    path: "/exchange/passenger",
    component: ExchangePassenger,
  },
  {
    path: "/exchange/ancillry",
    component: ManageAncillariesExchange,
  },
  {
    path: "/exchange/passenger",
    component: ExchangeAllPassengers,
  },
  {
    path: "/exchange/seat-selection",
    component: ExchangeSeatSelection,
  },
  {
    path: "/check-in/ancillary",
    component: CheckInAncillaries,
  },
  {
    path: "/test",
    component: AccountSetting,
  },
  {
    path: "/pay-with-telebirr",
    component: TeleBirrPayment,
  },
  {
    path: "/tele-birr-payment-summary",
    component: TeleBirrPaymentSummary,
  },
  {
    path: "/tele-birr-payment-summary-success",
    component: TeleBirrPaymentSummarySuccess,
  },
];

export default RouteList;
