import React, { Component } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { BookingWidgetAction } from "../../../redux/actions/booking/bookingWidgetAction";
import { connect } from "react-redux";
import { searchFlightAction } from "../../../redux/actions/booking/searchFlightAction";
import HttpService from "../../../service/shared/HttpService";
import Translation from "../../../service/shared/multi-lingual/Translation";
import { passengerAction } from "../../../redux/actions/booking/passengerAction";
import ProgressSimpler from "../../shared/progress-simple/ProgressSimpler";
class StartBookingButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmChangeFlight: false,
            isLoading: false,
            isDomesticFlight: false,
            domesticFlightError: false,
        };
    }
    async startBooking() {
        await this.props.validateWidget();
        if (this.props.currentWidget.isFormValid) {
            var selectedFare = null;
            var fareOption = document.getElementsByName('ethiopian');
            for (var i = 0; i < fareOption.length; i++) {
                if (fareOption[i].checked) {
                    selectedFare = fareOption[i].value;
                }
            }
            if (this.state.isDomesticFlight && selectedFare === null) {
                this.setState({ domesticFlightError: true });
            }
            else {
              if(this.state.isDomesticFlight){
                if(selectedFare==="ethiopian-residence"){
                  this.props.AddPromoCode("DOM71");
                }
                else if(selectedFare==="internation-ticket"){
                  this.props.AddPromoCode("DOM81");
                }
                else{
                  if(this.props.promoCode ==="DOM81"||this.props.promoCode ==="DOM71"){
                    this.props.AddPromoCode(null);
                  }
                }
              }
                this.initializedPassengerState(
                    this.props.passengerInfo.adult,
                    this.props.passengerInfo.child,
                    this.props.passengerInfo.infant
                );
                this.setState({ isLoading: true });
                this.props.updateFlightRequest(this.props.currentWidget);
                await this.props.updateRecentSearchAndSearchFlight(
                    this.props.currentWidget
                );

                HttpService.postService(
                    this.props.searchFlight.searchRequest,
                    "/Availability/Search",
                    this.props.searchFlight.token
                )
                    .then((response) => {
                        this.props.saveSearchResult(response);
                        this.setState({ isLoading: false });
                        if (response.data.statusAPI === "SUCCESS") {
                            this.props.changeLoading(true);
                        } else {
                            this.props.changeLoading(false, true);
                        }
                    })
                    .catch((error) => {
                        HttpService.getTokenServive()
                            .then((response) => {
                                this.props.saveToken(response.data.tokenDetail.access_token);
                                HttpService.postService(
                                    this.props.searchFlight.searchRequest,
                                    "/Availability/Search",
                                    response.data.tokenDetail.access_token
                                )
                                    .then((response) => {
                                        this.props.saveSearchResult(response);
                                        this.setState({ isLoading: false });
                                        if (response.data.statusAPI === "SUCCESS") {
                                            this.props.changeLoading(true);
                                        } else {
                                            this.props.changeLoading(false, true);
                                        }
                                    })
                                    .catch((error) => {
                                        this.setState({ isLoading: false });
                                        console.log(error);
                                        this.props.changeLoading(false);
                                    });
                            })
                            .catch((error) => {
                                this.setState({ isLoading: false });
                                this.props.changeLoading(false);
                            });
                    });
                setTimeout(() => {
                    this.props.changeLoading();
                }, 1000);
            }
        }
    }


    async checkDemosticFlight() {
        
        await this.props.validateWidget();
        var isDeomesticFlightTemp = false;
        if (this.props.currentWidget.isFormValid) {
            this.props.currentWidget.itineraryPart.forEach((route) => {
                if (route.params.from.label.toLowerCase().includes("ethiopia") && route.params.to.label.toLowerCase().includes("ethiopia")) {
                    isDeomesticFlightTemp = true;
                }
            });
        }
        if (isDeomesticFlightTemp) {
            this.setState({ isDomesticFlight: isDeomesticFlightTemp });
        }
        else {
            await this.startBooking();
        }
    }

    async initializedPassengerState(adultNo, childNo, infantNo) {
        await this.props.initializedPassengerState(adultNo, childNo, infantNo);
    }

    componentWillUnmount() {
        // window.removeEventListener("resize", this.scrollcenter);
        this.setState = (state, callback) => {
            return;
        };
    }
    render() {
        return (
            <div>
                {/* {this.state.isLoading && <ProgressLoader displayMess="Search Flight"/>} */}

                <Modal
                    show={this.state.isDomesticFlight}
                    onHide={() => {
                        this.setState({ isDomesticFlight: false });
                    }}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Discounted fare is available if:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="row">
                            {this.state.domesticFlightError && <p className="text-danger mx-3">Please select one of the below options</p>}
                            <div class="col-12">
                                <label class="et-checkbox-rounded  px-3">
                                    You are Ethiopian national, or you have a proof of residence in Ethiopia
                                    <input type="radio" value="ethiopian-residence" name="ethiopian" required="required" data-val="true" data-val-required="Please select one of the below options" class="ea-triggers-bound" />
                                    <span class="checkmark mt-1"></span>
                                </label>
                            </div>
                            <div class="col-12">
                                <label class="et-checkbox-rounded px-3">
                                    You have an international flight/ticket with Ethiopian Airlines.
                                    <input type="radio" value="internation-ticket" name="ethiopian" class="ea-triggers-bound" />
                                    <span class="checkmark mt-1"></span>
                                </label>
                            </div>
                            <div class="col-12">
                                <p><b>Note : </b>It is mandatory to present the document upon ticketing (if payment is made at city/airport ticket offices) and, at time of check-in to the airline on demand. Failure to present the required documents may result in denial of passenger from the flight and refund of ticket after applicable penalty deductions.</p>
                            </div>
                            <div class="col-12">
                                <label class="et-checkbox-rounded px-3">
                                    No, I hold none of the documents
                                    <input type="radio" value="" name="ethiopian" class="ea-triggers-bound" />
                                    <span class="checkmark mt-1"></span>
                                </label>
                            </div>
                            <div class="col-12">
                                <p><b>Remark : </b>If you have an infant under the age of two, you will need to provide a birth certificate or any proof of evidence at the airport.</p>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="danger"
                            className="btn-block btn-ethiopian  bk-submit-btn"
                            onClick={() => {
                                this.startBooking();
                            }}
                            id="start-booking-btn"
                        >
                            {this.state.isLoading ? (
                                <ProgressSimpler />
                            ) : "Continue"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.props.editFlight ? (
                    <>
                        <Row>
                            <div className="col-12">
                                <span className="float-right">
                                    <Button
                                        variant="danger"
                                        className="bk-submit-btn btn-white btn-ethiopian-danger m-2"
                                        style={{ borderColor: "red" }}
                                        onClick={() => this.props.hideEditFlight()}
                                    >
                                        <Translation Key="cancel" />
                                    </Button>
                                    <Button
                                        variant="danger"
                                        className={
                                            this.props.isSearchCriteriaChange
                                                ? "bk-submit-btn btn-ethiopian m-2"
                                                : "bk-submit-btn btn-ethiopian btn-not-allowd m-2 disabled"
                                        }
                                        onClick={() => {
                                            if (this.props.isSearchCriteriaChange)
                                                this.setState({ confirmChangeFlight: true });
                                        }}
                                    >
                                        {this.state.isLoading ? (
                                            <ProgressSimpler />
                                        ) : (
                                            <Translation Key="save_edit" />
                                        )}
                                    </Button>
                                </span>
                            </div>
                        </Row>
                        <Modal
                            show={this.state.confirmChangeFlight}
                            onHide={() => {
                                this.setState({ confirmChangeFlight: false });
                            }}
                            size="lg"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <Translation Key="confirm_new_itinerary" />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>
                                    <Translation Key="confirm_new_itinerary_body" />
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <span className="float-right">
                                    <Button
                                        variant="danger"
                                        className="bk-submit-btn btn-white btn-ethiopian-danger m-2"
                                        style={{ borderColor: "red" }}
                                        onClick={() =>
                                            this.setState({ confirmChangeFlight: false })
                                        }
                                    >
                                        <Translation Key="cancel" />
                                    </Button>
                                    <Button
                                        variant="danger"
                                        className={
                                            this.props.isSearchCriteriaChange
                                                ? " btn-ethiopian bk-submit-btn m-2"
                                                : " btn-ethiopian btn-not-allowd  bk-submit-btn m-2"
                                        }
                                        onClick={() => {
                                            this.setState({ confirmChangeFlight: false });
                                            this.startBooking();
                                        }}
                                    >
                                        <Translation Key="save_edit" />
                                    </Button>
                                </span>
                            </Modal.Footer>
                        </Modal>
                    </>
                ) : (
                    <Row>
                        <Col xs={12} sm={12} md={7} lg={8}></Col>
                        <Col xs={12} sm={12} md={5} lg={4}>
                            <Button
                                variant="danger"
                                className="btn-block btn-ethiopian  bk-submit-btn"
                                onClick={() => {
                                    this.checkDemosticFlight();
                                }}
                                id="start-booking-btn"
                            >
                                {this.state.isLoading ? (
                                    <ProgressSimpler />
                                ) : (
                                    <Translation Key="start_booking" />
                                )}
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>
        );
    }
}
const mapStateTopProps = (state) => {
    return {
        currentWidget: state.bookingWiget,
        searchFlight: state.searchFlight,
        passengerInfo: state.bookingWiget.passengerInfo,
        promoCode: state.bookingWiget.PromoCode,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
      AddPromoCode: (input) =>
      dispatch({ type: BookingWidgetAction.ADDPROMOCODE, value: input }),
        validateWidget: () =>
            dispatch({ type: BookingWidgetAction.VALIDATEWIDGET, domain: "all" }),
        updateRecentSearchAndSearchFlight: (currentValue) =>
            dispatch({
                type: searchFlightAction.SAVERECENTSEARCH,
                newValue: currentValue,
            }),
        saveSearchResult: (result) =>
            dispatch({
                type: searchFlightAction.SAVESEARCHRESULT,
                searchResult: result,
            }),
        initializedPassengerState: (adultNo, childNo, infantNo) =>
            dispatch({
                type: passengerAction.ADDPASSENGER,
                adultNo: adultNo,
                childNo: childNo,
                infantNo: infantNo,
            }),
        saveToken: (token) =>
            dispatch({
                type: searchFlightAction.SAVETOKEN,
                token: token,
            }),
        updateFlightRequest: (value) => {
            dispatch({
                type: searchFlightAction.UPDATEFLIGHTREQUEST,
                searchRequest: value,
            });
        },
    };
};
export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(StartBookingButton);
